<template>
    <div>
        <h5>
            {{ $t("Rate this Gallery") }}
        </h5>
        <b-form-rating
            v-model="rating"
            size="lg"
            variant="warning"
        ></b-form-rating>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("gallery", ["creatorGallery"]),
        rating: {
            get() {
                return this.$store.state.gallery.rating;
            },
            async set(newValue) {
                if (newValue) {
                    await this.api.post("/creatorGallery/rate", {
                        creatorGalleryId: this.creatorGallery.id,
                        rating: newValue,
                    });

                    this.$store.commit("gallery/setRating", newValue);
                }
            },
        },
    },
};
</script>