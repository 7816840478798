<template>
    <div class="small text-primary">
        {{ duration }}
    </div>
</template>

<script>
import { calculateDuration } from "@/utility/time";

export default {
    data() {
        return {
            start: new Date(),
            interval: null,
            duration: null,
        };
    },
    mounted() {
        this.refresh();

        this.interval = setInterval(() => {
            this.refresh();
        }, 1000);
    },
    beforeDestroy() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    },
    methods: {
        refresh() {
            this.duration = calculateDuration(this.timestamp || this.start);
        },
    },
    props: ["timestamp"],
};
</script>