<template>
    <generic-page>
        <div v-if="topics?.length">
            <div class="d-flex align-items-center justify-content-between mt-3">
                <h5 class="mb-0">Help Topics</h5>
                <b-btn
                    v-if="false"
                    v-b-toggle.searchHelp
                    variant="light"
                    pill
                    class="border"
                    size="sm"
                >
                    Search Help
                </b-btn>
            </div>

            <b-collapse id="searchHelp">
                <support-search />
            </b-collapse>

            <b-list-group class="mt-2">
                <b-list-group-item
                    @click="
                        $router.push({
                            name: 'supportTopic',
                            params: {
                                id: topic.id,
                            },
                        })
                    "
                    v-for="topic in topics"
                    :key="topic.id"
                    action
                    class="d-flex align-items-center justify-content-between"
                >
                    {{ topic.name }}
                    <font-awesome-icon icon="fa-light fa-chevron-right" />
                </b-list-group-item>
            </b-list-group>
        </div>

        <div v-if="onboardingUser.eligibleForChatSupport">
            <h5 class="mt-3">Still need help?</h5>
            <div class="p-3 border bg-white rounded">
                Chat with our support team, please allow up to 48 hours for a
                reply, we will get back you as soon as possible
                <contact-support />
            </div>
        </div>
    </generic-page>
</template>


<script>
import { mapState } from "vuex";

import ContactSupport from "@/components/utility/ContactSupport";
import SupportSearch from "@/components/support/SupportSearch";

export default {
    data() {
        return {
            topics: null,
            tickets: null,
        };
    },
    created() {
        this.api.post("/support/index").then((response) => {
            this.topics = response.data.topics;
            this.tickets = response.data.tickets;
        });
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        ContactSupport,
        SupportSearch,
    },
};
</script>