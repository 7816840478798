<template>
    <div>
        <div v-if="isInbound">
            <div>
                <call-settings
                    v-if="currentCall.callInProgress"
                ></call-settings>
                <user-call-avatar v-else :standardMap="currentCall.fromUser">
                    <div class="mt-3 text-warning">
                        <coin-badge
                            :amount="currentCall.pricePerMinute"
                            :label="
                                onboardingUser.creator
                                    ? $t('Earnings per minute')
                                    : $t('Charge per minute')
                            "
                        />
                    </div>
                </user-call-avatar>
            </div>

            <div
                class="d-flex align-items-center justify-content-around text-center py-3"
            >
                <div v-if="currentCall.endedAt">
                    <b-btn
                        @click="close"
                        class="call-button call-button-large"
                        variant="light"
                    >
                        <font-awesome-icon icon="fa-light fa-times" size="2x" />
                    </b-btn>
                    <div class="small text-secondary">
                        {{ $t("Close") }}
                    </div>
                </div>
                <div v-if="!currentCall.callInProgress && !currentCall.endedAt">
                    <b-btn
                        @click="answer"
                        class="call-button call-button-large"
                        variant="success"
                    >
                        <font-awesome-icon
                            icon="fa-duotone fa-phone"
                            size="2x"
                        />
                    </b-btn>
                    <div class="small text-secondary">
                        {{ $t("Answer") }}
                    </div>
                </div>
                <div v-if="!currentCall.callInProgress && !currentCall.endedAt">
                    <b-btn
                        @click="decline"
                        class="call-button call-button-large"
                        variant="danger"
                    >
                        <font-awesome-icon
                            icon="fa-duotone fa-phone-hangup"
                            size="2x"
                        />
                    </b-btn>
                    <div class="small text-secondary">
                        {{ $t("Decline") }}
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <user-call-avatar
                v-if="!currentCall.callInProgress"
                :standardMap="currentCall.toUser"
            />

            <div
                class="d-flex align-items-center justify-content-around text-center py-3"
            >
                <div v-if="currentCall.endedAt">
                    <b-btn
                        @click="close"
                        class="call-button call-button-large"
                        variant="light"
                    >
                        <font-awesome-icon icon="fa-light fa-times" size="2x" />
                    </b-btn>
                    <div class="small text-secondary">
                        {{ $t("Close") }}
                    </div>
                </div>
                <div v-else>
                    <b-btn
                        @click="cancel"
                        class="call-button call-button-large"
                        variant="danger"
                    >
                        <font-awesome-icon
                            icon="fa-duotone fa-phone-hangup"
                            size="2x"
                        />
                    </b-btn>
                    <div class="small text-secondary">
                        {{ $t("Cancel") }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import UserCallAvatar from "@/components/user/UserCallAvatar";

export default {
    computed: {
        ...mapState("call", ["currentCall", "localStream", "remoteStream"]),
        isInbound() {
            return this.currentCall.toUser.id === this.onboardingUser.id;
        },
        canAnswer() {
            return !this.currentCall.answeredAt && this.isInbound;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        answer() {
            this.$store.dispatch("call/answer");
        },
        decline() {
            this.$store.dispatch("call/decline");
        },
        cancel() {
            this.$store.dispatch("call/cancel");
        },
        close() {
            this.$store.dispatch("call/close");
        },
    },
    components: {
        UserCallAvatar,
    },
};
</script>