<template>
    <div>
        <div>
            <account-alerts />
        </div>
        <div class="mt-3">
            <account-todo-list />
        </div>
        <div class="mt-3">
            <account-activity />
        </div>
        <div class="mt-3">
            <account-profile />
        </div>
        <div class="mt-3">
            <account-settings />
        </div>
    </div>
</template>

<script>
import AccountAlerts from "@/components/account/AccountAlerts";
import AccountTodoList from "@/components/account/AccountTodoList";
import AccountProfile from "@/components/account/AccountProfile";
import AccountSettings from "@/components/account/AccountSettings";
import AccountActivity from "@/components/account/AccountActivity";

export default {
    components: {
        AccountAlerts,
        AccountTodoList,
        AccountProfile,
        AccountSettings,
        AccountActivity,
    },
};
</script>

