var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bg-light small pl-3 text-secondary sticky-top"},[_vm._v(" "+_vm._s(_vm.$t("My Profile"))+" ")]),_c('b-list-group',{attrs:{"flush":""}},_vm._l((_vm.visibleSteps),function(step){return _c('b-list-group-item',{key:step.slug,staticClass:"border-lighter",attrs:{"button":"","variant":_vm.currentStep.slug === step.slug ? 'primary' : null},on:{"click":function($event){_vm.$store.commit('onboarding/setCurrentStep', step);
                _vm.$store.commit(
                    'interface/setOnboardingSidebarVisible',
                    false
                );}}},[_c('span',{staticClass:"mr-1",class:{ 'text-success': step.complete }},[_c('font-awesome-icon',{attrs:{"icon":step.complete
                            ? 'fa-solid fa-check-circle'
                            : 'fa-solid fa-circle'}})],1),_vm._v(" "+_vm._s(step.name)+" ")])}),1),(!_vm.onboardingUser?.ghostMode)?_c('div',{staticClass:"bg-light p-3"},[_c('div',{staticClass:"small text-secondary"},[_vm._v(" "+_vm._s(_vm.$t( "Want to finish signup on another device? Email yourself a link to login and pick up where you left off" ))+" ")]),_c('div',{staticClass:"my-2"},[(_vm.sentLoginLink)?_c('div',{staticClass:"small text-info"},[_vm._v(" "+_vm._s(_vm.$t("Sent, check your inbox"))+" ")]):_c('div',[_c('b-button',{staticClass:"btn-brand",attrs:{"variant":"light","block":"","pill":""},on:{"click":_vm.loginLink}},[_vm._v(" "+_vm._s(_vm.$t("Email me a login link"))+" ")])],1)]),(false)?_c('div',{staticClass:"small text-secondary mt-3"},[_vm._v(" "+_vm._s(_vm.$t( "Need help with signup or something else we can assist with? Send us an email" ))+" ")]):_vm._e(),(false)?_c('b-button',{staticClass:"btn-brand mt-2",attrs:{"variant":"light","block":"","pill":""},on:{"click":_vm.emailSupport}},[_vm._v(" "+_vm._s(_vm.$t("Email Support"))+" ")]):_vm._e()],1):_vm._e(),_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.more",modifiers:{"more":true}}],staticClass:"text-secondary text-left p-3",attrs:{"variant":"link","size":"sm","block":""}},[_vm._v(" "+_vm._s(_vm.$t("More"))+" ")]),_c('b-collapse',{attrs:{"id":"more"}},[_c('b-btn',{staticClass:"text-secondary",attrs:{"variant":"link","size":"sm"},on:{"click":function($event){return _vm.$store.dispatch('user/logout')}}},[_vm._v(" "+_vm._s(_vm.$t("Logout"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }