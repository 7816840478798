<template>
    <div v-if="hasGifts" class="border bg-white p-2 mt-3">
        <h5>
            {{ creatorPost.gifts.length }}
            {{ $t("gifts") }}
        </h5>

        <div
            @click="viewProfile(gift.standardMap)"
            :key="gift.id"
            v-for="gift in creatorPost.gifts"
            class="border-top border-light p-2"
        >
            <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <user-square-avatar
                        :standardMap="gift.standardMap"
                        size="small"
                        class="mr-2"
                    />

                    <div>
                        <div class="small text-secondary">
                            {{ gift.standardMap.name }}
                        </div>
                        <div>
                            {{ $t(" gifted you a ") }}
                            {{ gift.gift.name }}
                        </div>
                        <div class="small text-warning">
                            {{ $t("You received ") }}
                            {{ gift.amount }}
                            {{ $t(" credits") }}
                        </div>
                    </div>
                </div>

                <div class="text-center bg-light rounded p-1 w-25">
                    <img class="custom-icon-size" :src="gift.gift.url" />
                    <div
                        class="d-flex align-items-center justify-content-center small"
                    >
                        <div class="text-primary mr-1">
                            <font-awesome-icon
                                size="xs"
                                icon="fa-light fa-plus"
                            />
                        </div>
                        <font-awesome-icon
                            icon="fa-duotone fa-coin"
                            class="mr-1 text-warning"
                        />
                        <div class="text-secondary">
                            {{ gift.amount }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import UserSquareAvatar from "@/components/user/UserSquareAvatar";

export default {
    methods: {
        viewProfile(user) {
            this.$store.dispatch("profile/viewProfile", {
                userId: user.id,
                profile: user,
            });
        },
    },
    computed: {
        ...mapState("creatorPost", ["creatorPost"]),
        hasGifts() {
            return this.creatorPost.gifts.length;
        },
    },
    components: {
        UserSquareAvatar,
    },
};
</script>