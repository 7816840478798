<template>
    <b-input
        @focus="commentFocus"
        autocomplete="off"
        :placeholder="$t('Add a comment')"
    ></b-input>
</template>


<script>
export default {
    methods: {
        commentFocus() {
            this.$store.commit("livestream/setCommentInputFocused", true);
        },
    },
};
</script>