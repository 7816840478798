<template>
    <b-btn
        @click="call"
        pill
        variant="light"
        class="d-flex align-items-center bg-white shadow-sm shadow-blue mr-2"
        size="sm"
    >
        <span class="text-primary">
            <font-awesome-icon class="mr-1" icon="fa-duotone fa-circle-phone" />
        </span>
        {{ $t("Call") }}
    </b-btn>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("profile", ["profile"]),
    },
    methods: {
        call() {
            this.$store.dispatch("call/start", {
                userId: this.profile.id,
            });
        },
    },
    components: {},
};
</script>