<template>
    <div>
        <b-btn
            @click="interested"
            variant="light"
            block
            class="rounded-0 mt-0"
            :size="$screen.lg ? 'lg' : 'sm'"
        >
            <div v-if="interestedInUserId(profile.id)">
                <div>
                    <font-awesome-icon
                        size="lg"
                        icon="fa-duotone fa-user-check"
                        class="text-primary"
                    />
                </div>
                {{ $t("Following") }}
            </div>
            <div v-else>
                <div>
                    <font-awesome-icon
                        size="lg"
                        icon="fa-duotone fa-user-plus"
                    />
                </div>
                {{ $t("Follow") }}
            </div>
        </b-btn>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        ...mapState("profile", ["profile"]),
        ...mapState("interest", [
            "interests",
            "outboundUserIds",
            "localInterestedUserIds",
        ]),
        ...mapGetters("interest", ["interestedInUserId"]),
    },
    methods: {
        interested() {
            if (this.interestedInUserId(this.profile.id)) {
                this.$store.dispatch("interest/removeInterest", {
                    userId: this.profile.id,
                });
            } else {
                this.$store.dispatch("interest/sendInterest", {
                    userId: this.profile.id,
                });
            }
        },
    },
    components: {},
};
</script>