<template>
    <div>
        <call-details />

        <div v-if="channel">
            <div v-if="cordova">
                <call-cordova />
            </div>
            <div v-else>
                <call-agora />
            </div>
        </div>

        <div v-if="!currentCall.callInProgress">
            <call-actions />
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

import CallActions from "@/components/always/call/CallActions";
import CallDetails from "@/components/always/call/CallDetails";
import CallAgora from "@/components/always/call/CallAgora";
import CallCordova from "@/components/always/call/CallCordova";

export default {
    computed: {
        ...mapState("call", ["currentCall", "channel"]),
        ...mapState("system", ["cordova"]),
        isInbound() {
            return this.currentCall.toUser.id === this.onboardingUser.id;
        },
        canAnswer() {
            return !this.currentCall.answeredAt && this.isInbound;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {},
    components: {
        CallActions,
        CallDetails,
        CallAgora,
        CallCordova,
    },
};
</script>