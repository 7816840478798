import { SEARCH_SCROLL_POSITION } from "@/constant/localStorage"
import { mapGetters, mapState } from "vuex";

export default {
    data() {
        return {
            currentScrollTimeout: null
        }
    },
    computed: {
        ...mapState("navigation", ["view"]),
        ...mapGetters("search", ["filteredUsers"]),
    },
    mounted() {
        window.addEventListener("scroll", this.globlScrollHandler);
    },
    methods: {
        globlScrollHandler() {
            if (this.currentScrollTimeout) {
                if (this.view) {
                    clearTimeout(this.currentScrollTimeout)
                }
                return;
            }

            if (this.view) {
                // console.log('ignoring view from within timeout is set:', this.view)
                return;
            }

            if (!this.filteredUsers || !this.filteredUsers.length) {
                // console.log('no filtered users ignoring scroll:')
                return;
            }

            this.currentScrollTimeout = setTimeout(() => {
                // console.log("persisting scroll pos", window.scrollY);

                if (window.scrollY > 200) {
                    this.$store.commit('search/setHasScrolled', true)
                } else {
                    this.$store.commit('search/setHasScrolled', false)
                }

                localStorage.setItem(SEARCH_SCROLL_POSITION, window.scrollY);
                this.currentScrollTimeout = null;
            }, 100);
        }
    },
    destroyed() {
        window.removeEventListener("scroll", this.globlScrollHandler);
    }
}