<template>
    <div>
        <img class="img-fluid" :src="photo.search" />
        <div
            v-if="photo.primary"
            class="
                position-absolute
                glass
                d-flex
                align-items-center
                justify-content-center
            "
            style="
                bottom: 0;
                left: 0;
                height: 22px;
                width: 22px;
                border-top-right-radius: 4px;
            "
        >
            <span class="text-warning">
                <font-awesome-icon icon="fa-solid fa-star" />
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: ["photo"],
};
</script>