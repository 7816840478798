<template>
    <div>
        <div class="position-relative">
            <b-alert v-if="!hasSelected" variant="primary" show class="mb-0">
                {{ $t("Select all that apply") }}
            </b-alert>
            <div v-else>
                <b-btn
                    @click="save"
                    :disabled="!complete || saving"
                    variant="primary"
                    size="lg"
                    class="px-4"
                    block
                    pill
                >
                    {{ $t("Done Answering") }}
                </b-btn>
            </div>

            <div class="text-left mt-3">
                <div
                    @click="selectAnswer(answer)"
                    :key="answer.answerId"
                    v-for="answer in currentQuestion.answers"
                    variant="light"
                    class="btn-brand rounded-pill d-inline-block mr-3 my-1 px-3 py-2 border"
                >
                    <div class="d-flex align-items-center">
                        <div class="mr-1">
                            <div
                                v-if="selectedAnswers.includes(answer.answerId)"
                                class="text-primary"
                            >
                                <font-awesome-icon
                                    icon="fa-solid fa-check-circle"
                                />
                            </div>
                            <div v-else class="text-secondary">
                                <font-awesome-icon icon="fa-solid fa-circle" />
                            </div>
                        </div>

                        {{ answer.answerQuestionFormat }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            saving: null,
            selectedAnswers: [],
        };
    },
    created() {
        this.selectedAnswers = this.currentQuestion.answers
            .filter((answer) => answer.selected)
            .map((answer) => answer.answerId);
    },
    computed: {
        hasSelected() {
            return this.selectedAnswers.length > 0;
        },
        ...mapState("questions", ["currentQuestion"]),
        complete() {
            return this.selectedAnswers.length > 0;
        },
    },
    methods: {
        selectAnswer(answer) {
            if (this.selectedAnswers.includes(answer.answerId)) {
                this.selectedAnswers = this.selectedAnswers.filter(
                    (id) => id !== answer.answerId
                );
            } else {
                this.selectedAnswers.push(answer.answerId);
            }
        },
        save() {
            this.saving = true;

            this.$store.dispatch("questions/answerMultiple", {
                question: this.currentQuestion,
                answerIds: this.selectedAnswers,
            });
        },
    },
};
</script>