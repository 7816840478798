<template>
    <div>
        <b-form v-if="!previewGif" @submit.stop.prevent="search" class="mb-3">
            <b-input-group>
                <b-input-group-prepend v-if="keyword">
                    <b-btn
                        @click="clearResults"
                        variant="light"
                        class="border border-light text-danger px-3"
                        v-if="keyword"
                    >
                        <font-awesome-icon icon="fa-light fa-times" />
                    </b-btn>
                </b-input-group-prepend>
                <b-input
                    v-model="keyword"
                    type="text"
                    class="border-light"
                    :placeholder="$t('Search GIFs...')"
                    autocorrect="off"
                    autocomplete="off"
                    autocapitalize="none"
                    size="lg"
                />
                <b-input-group-append>
                    <b-btn variant="primary" class="border border-light px-3">
                        <font-awesome-icon
                            icon="fa-duotone fa-magnifying-glass"
                        />
                    </b-btn>
                </b-input-group-append>
            </b-input-group>
        </b-form>

        <div>
            <div v-if="previewGif">
                <b-btn
                    @click="previewGif = null"
                    variant="link"
                    class="px-0 mb-2"
                >
                    <font-awesome-icon
                        size="lg"
                        icon="fa-light fa-chevron-left"
                    />
                    {{ $t("Back to All GIFs") }}
                </b-btn>
                <giphy-preview :gif="previewGif" />
            </div>
            <div v-else-if="searchGifs">
                <h6>
                    {{ $t("Search Results") }}
                </h6>
                <giphy-index @selectedGif="selectedGif" :gifs="searchGifs" />
            </div>
            <div v-else>
                <h6>
                    {{ $t("Trending GIFs") }}
                </h6>
                <giphy-index @selectedGif="selectedGif" :gifs="trendingGifs" />
            </div>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

import GiphyPreview from "@/components/utility/giphy/GiphyPreview";
import GiphyIndex from "@/components/utility/giphy/GiphyIndex";

export default {
    data() {
        return {
            previewGif: null,
            searchGifs: null,
            keyword: null,
        };
    },
    created() {
        this.$store.dispatch("giphy/loadTrendingOnce");
    },
    computed: {
        ...mapState("giphy", ["trendingGifs"]),
    },
    methods: {
        selectedGif(gif) {
            this.previewGif = gif;
        },
        clearResults() {
            this.keyword = null;
            this.searchGifs = null;
        },
        search() {
            this.api
                .post("/giphy/search", { keyword: this.keyword })
                .then((response) => {
                    if (response?.data?.gifs) {
                        this.searchGifs = response.data.gifs;
                    }
                });
        },
    },
    components: {
        GiphyPreview,
        GiphyIndex,
    },
};
</script>