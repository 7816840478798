<template>
    <div>
        <div v-if="localRating">
            <font-awesome-icon class="text-success" icon="fa-light fa-check" />
        </div>
        <div v-else-if="showRating">
            <b-form-rating v-model="rating" variant="warning"> </b-form-rating>
        </div>
        <div
            @click="showRating = 1"
            v-else
            class="d-flex justify-content-center align-items-center border rounded-pill px-2 py-1 small font-weight-bold"
        >
            {{ $t("Rate this chat") }}
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            showRating: false,
            localRating: null,
        };
    },
    computed: {
        ...mapState("conversation", ["openConversationUserId"]),
        rating: {
            get() {
                return this.localRating;
            },
            async set(newValue) {
                if (newValue) {
                    await this.api.post("/conversation/rating", {
                        userId: this.openConversationUserId,
                        rating: newValue,
                    });

                    this.localRating = newValue;
                }
            },
        },
    },
    components: {},
};
</script>