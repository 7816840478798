<template>
    <div>
        <div :key="landingPage.url" v-for="landingPage in landingPages" class="mt-2">
            {{ landingPage.description }}
            <b-input-group>
                <b-input :value="landingPage.url" size="sm" />
                <b-input-group-append>
                    <b-btn
                        v-if="copiedUrl === landingPage.url"
                        variant="success"
                        class="border"
                        size="sm"
                    >
                        <font-awesome-icon
                            class="mr-1"
                            icon="fa-light fa-check"
                        />
                        {{ $t("Copied") }}
                    </b-btn>
                    <b-btn
                        v-else
                        @click="copyUrl(landingPage)"
                        variant="primary"
                        class="border"
                        size="sm"
                    >
                        <font-awesome-icon
                            class="mr-1"
                            icon="fa-duotone fa-copy"
                        />
                        {{ $t("Copy") }}
                    </b-btn>
                </b-input-group-append>
            </b-input-group>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import copy from "clipboard-copy";

export default {
    computed: {
        ...mapState("affiliate", ["copiedUrl"]),
    },
    methods: {
        copyUrl(landingPage) {
            copy(landingPage.url);

            this.$store.commit("affiliate/setCopiedUrl", landingPage.url);

            setTimeout(() => {
                this.$store.commit("affiliate/setCopiedUrl", null);
            }, 2000);
        },
    },
    props: ["landingPages"],
};
</script>