<template>
    <div>
        <b-alert variant="success" show>
            <div class="my-2">
                <font-awesome-icon icon="fa-light fa-check-circle" size="3x" />
            </div>
            <h5>Verification Submitted</h5>

            <div>
                Your video has been submitted for moderation, please allow up to
                48 hours for us to review
            </div>

            <div class="mt-3">
                We will email you once your account has been fully activated
            </div>
        </b-alert>

        <div v-if="videos?.length">
            <b-alert variant="primary" show>
                Preview your verification video. If the code doesn't match, you
                can delete the video and try again
            </b-alert>

            <div :key="video.id" v-for="video in videos" class="bg-white p-3">
                <div>Verification Code: {{ video.code }}</div>
                <video-player
                    :loop="false"
                    :muted="false"
                    :controls="true"
                    :autoplay="false"
                    :mp4Url="video.mp4Url"
                    :webmUrl="video.webmUrl"
                />
                <div>
                    <b-btn
                        variant="light"
                        @click="deleteVideo(video)"
                        class="text-danger mt-2"
                        pill
                        block
                    >
                        <font-awesome-icon
                            icon="fa-light fa-times"
                            class="mr-1"
                        />
                        Delete Video
                    </b-btn>
                </div>
            </div>
        </div>
        <div v-else-if="failed?.length">
            <div
                :key="video.id"
                v-for="video in failed"
                class="bg-white p-3 text-left"
            >
                <div class="text-danger">
                    Video ID {{ video.id }} failed to process, please delete it
                    and submit a new video
                </div>
                <b-alert
                    v-if="video.rejectReason"
                    variant="danger"
                    show
                    class="my-3"
                >
                    <div v-if="video.rejectReason">
                        <h5>Reason</h5>
                        <font-awesome-icon
                            icon="fa-duotone fa-triangle-exclamation"
                        />
                        {{ video.rejectReason }}
                    </div>
                </b-alert>

                <b-btn
                    variant="light"
                    @click="deleteVideo(video)"
                    class="text-danger"
                    pill
                    block
                >
                    <font-awesome-icon icon="fa-light fa-times" class="mr-1" />
                    Delete Video
                </b-btn>
            </div>
        </div>
        <div v-else-if="rejected?.length">
            <h5 class="text-danger">Rejected Videos</h5>
            <div :key="video.id" v-for="video in rejected">
                <b-alert
                    v-if="video.rejectReason"
                    variant="danger"
                    show
                    class="my-3"
                >
                    <div v-if="video.rejectReason">
                        <h5>Reject Reason</h5>
                        <font-awesome-icon
                            icon="fa-duotone fa-triangle-exclamation"
                        />
                        {{ video.rejectReason }}
                    </div>
                </b-alert>

                <b-btn
                    variant="light"
                    @click="deleteVideo(video)"
                    class="text-danger"
                    pill
                    block
                >
                    <font-awesome-icon icon="fa-light fa-times" class="mr-1" />
                    Delete Video
                </b-btn>
            </div>
        </div>
        <div v-else>
            <generic-loading label="Processing Video" />

            <div class="mt-3 text-center">
                <b-btn
                    v-if="allowManualRefresh"
                    @click="refresh"
                    variant="primary"
                    pill
                >
                    Refresh
                </b-btn>
            </div>
        </div>

        <onboarding-verification-skip class="mt-3">
            <b-btn variant="primary" pill size="lg" block> Continue </b-btn>
        </onboarding-verification-skip>
    </div>
</template>

<script>
import OnboardingVerificationSkip from "@/components/onboarding/verification/OnboardingVerificationSkip";
import VideoPlayer from "@/components/utility/VideoPlayer";

export default {
    data() {
        return {
            allowManualRefresh: false,
            videos: null,
            failed: null,
            rejected: null,
            timeouts: [],
        };
    },
    created() {
        this.refresh();

        const giveUpAfter = 120000;

        this.schedule(5000);
        this.schedule(10000);
        this.schedule(15000);
        this.schedule(30000);
        this.schedule(45000);
        this.schedule(giveUpAfter);

        this.timeouts.push(
            setTimeout(() => {
                this.allowManualRefresh = true;
            }, giveUpAfter + 3000)
        );
    },
    methods: {
        schedule(delay) {
            this.timeouts.push(
                setTimeout(() => {
                    this.refreshUntilHasPending();
                }, delay)
            );
        },
        refreshUntilHasPending() {
            if (this.videos?.length) {
                return;
            }

            this.refresh();
        },
        deleteVideo(video) {
            this.api.post(`/video/${video.id}/delete`).then(() => {
                this.refresh();
                this.$store.dispatch("onboarding/status");
            });
        },
        refresh() {
            this.api.post("/video/pending").then((response) => {
                this.videos = response?.data?.videos;
                this.failed = response?.data?.failed;
                this.rejected = response?.data?.rejected;
            });
        },
    },
    beforeDestroy() {
        this.timeouts.forEach(function (timeout) {
            clearTimeout(timeout);
        });
    },
    components: {
        OnboardingVerificationSkip,
        VideoPlayer,
    },
};
</script>