<template>
    <div class="p-2">
        <div v-if="isiOS">
            <b-alert variant="warning" show>
                {{
                    $t(
                        "Do not use 'Sign in with Apple', if you do, you will have to signup again"
                    )
                }}
                <div class="mt-3">
                    {{
                        $t(
                            "Apple hides your email address, we will have no way to associate it with your existing account"
                        )
                    }}
                </div>
            </b-alert>
            <b-btn
                target="blank"
                href="https://apps.apple.com/us/app/findmate-dating-friends/id1489879960"
                variant="light"
                pill
                class="btn-brand"
                block
                size="lg"
            >
                <font-awesome-icon icon="fa-brands fa-apple" />
                Get the App
            </b-btn>
        </div>

        <div v-if="isAndroidOS">
            <b-alert variant="primary" show class="mt-3">
                Our app is available for direct download. After downloading the
                APK, browse your files on phone and open the file to install it

                <a
                    class="btn btn-primary btn-lg rounded-pill btn-block mt-3"
                    href="https://findmate-app.s3.amazonaws.com/findmate.apk"
                >
                    <i class="fa-duotone fa-download"></i>
                    Download APK File
                </a>

                <h6 class="mt-4">Having trouble installing on Android?</h6>
                <div>
                    <b-btn
                        class="text-left p-0"
                        rel="nofollow"
                        target="_blank"
                        href="https://www.expressvpn.com/support/vpn-setup/enable-apk-installs-android/"
                        variant="link"
                        pill
                        block
                        size="sm"
                    >
                        How-to install APKs Guide
                    </b-btn>
                </div>
            </b-alert>
        </div>

        <h5 class="mt-3">
            {{ $t("How to log back in") }}
        </h5>
        <div class="p-3 bg-light rounded">
            <div>
                {{
                    $t(
                        "Make sure you use the same Google account or email to login to the app:"
                    )
                }}
            </div>
            <h5 class="text-primary mb-0 mt-3">
                {{ onboardingUser.email }}
            </h5>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    created() {
        this.$store.dispatch("mobileDetect/refresh");
    },
    computed: {
        ...mapState("mobileDetect", ["isiOS", "isAndroidOS"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
};
</script>