<template>
    <div>
        <div>
            <font-awesome-icon icon="fa-duotone fa-location-dot" size="3x" />
        </div>
        <div>
            <fitty :options="{ maxSize: 21 }">
                <template v-slot:content>
                    {{ $t("Location Permission Required") }}
                </template>
            </fitty>
        </div>

        <div class="py-3 small">
            <div v-if="refreshingLocation" class="text-secondary">
                {{
                    $t(
                        "Determining location, you can skip this after 5 seconds..."
                    )
                }}
                <b-progress :max="5" class="mt-2" height=".25rem">
                    <b-progress-bar :value="secondsWaited"></b-progress-bar>
                </b-progress>
            </div>
            <div v-else class="text-secondary">
                {{
                    $t(
                        "We will never share your exact location with other users, we use this to show you people near you"
                    )
                }}
            </div>
        </div>

        <div class="py-2">
            <b-btn
                :disabled="refreshingLocation"
                variant="primary"
                @click="gps"
                pill
                size="lg"
                class="px-5"
            >
                <div v-if="refreshingLocation">
                    <b-spinner small variant="light" />
                </div>
                <div v-else>
                    {{ $t("Allow Location") }}
                </div>
            </b-btn>
        </div>

        <div class="py-2" v-if="showSkipOption">
            <b-alert variant="warning" show>
                <div>
                    {{ $t("We were unable to determine your location") }}
                </div>
                <b-btn variant="light" @click="confirmSkip" class="mt-3" pill>
                    {{ $t("Continue") }}
                </b-btn>
            </b-alert>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            hasWaited: null,
            showCalled: false,
            secondsWaited: 0,
        };
    },
    computed: {
        showSkipOption() {
            if (this.hasWaited) {
                return true;
            }

            if (this.geolocationFailed) {
                return true;
            }

            return false;
        },
        ...mapState("user", ["refreshingLocation", "geolocationFailed"]),
    },
    methods: {
        confirmSkip() {
            this.skip();
            this.$store.dispatch("appEvent/create", "onboarding_gps_skipped");
        },
        showSkipOptionAfterWait() {
            if (this.showCalled) {
                return;
            }

            this.showCalled = true;

            this.timer();

            setTimeout(() => {
                this.hasWaited = true;
            }, 6000);
        },
        timer() {
            setTimeout(() => {
                this.secondsWaited = 1;
            }, 1000);

            setTimeout(() => {
                this.secondsWaited = 2;
            }, 2000);

            setTimeout(() => {
                this.secondsWaited = 3;
            }, 3000);

            setTimeout(() => {
                this.secondsWaited = 4;
            }, 4000);

            setTimeout(() => {
                this.secondsWaited = 5;
            }, 5000);
        },
        gps() {
            this.showSkipOptionAfterWait();

            this.$store.dispatch("user/refreshLocation", {
                force: true,
                refresh: true,
            });
        },
        skip() {
            this.api
                .post("/gps/disable")
                .then(() => {})
                .catch(() => {})
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                });
        },
    },
};
</script>