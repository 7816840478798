import api from "@/service/api";

import { getRandomString } from "@/utility/random";

const state = () => ({
    callPreview: null,
    creatingCall: false,
    currentCall: null,
    addingMinutes: false,
    ringingTimeout: null,
    selectedCamera: null,
    channel: null,
    uniqueBillingKey: null,
    hasRemoteVideoTrack: false,
    isCameraOn: false,
    disableFreeTime: false,
})

const CALL_TIMEOUT = 30000;

const mutations = {
    reset(state) {
        state.isCameraOn = false
        state.hasRemoteVideoTrack = false
        state.creatingCall = false
        state.currentCall = null
        state.callPreview = null
        state.uniqueBillingKey = null
        state.channel = null
        state.addingMinutes = false
        state.disableFreeTime = false

        if (state.ringingTimeout) {
            clearTimeout(state.ringingTimeout)
        }

        state.ringingTimeout = null
    },
    setIsCameraOn(state, value) {
        state.isCameraOn = value
    },
    setHasRemoteVideoTrack(state, value) {
        state.hasRemoteVideoTrack = value
    },
    setDisableFreeTime(state, value) {
        state.disableFreeTime = value
    },
    setCallPreview(state, value) {
        state.callPreview = value
    },
    setCurrentCall(state, value) {
        state.currentCall = value
    },
    setAddingMinutes(state, value) {
        state.addingMinutes = value
    },
    setCreatingCall(state, value) {
        state.creatingCall = value
    },
    setRingingTimeout(state, value) {
        state.ringingTimeout = value
    },
    setSelectedCamera(state, value) {
        state.selectedCamera = value
    },
    setChannel(state, value) {
        state.channel = value
    },
    setUniqueBillingKey(state, value) {
        state.uniqueBillingKey = value
    },
}

const actions = {
    refreshPreview({ dispatch, state }) {
        if (state.callPreview) {
            dispatch('preview', {
                userId: state.callPreview.userId
            })
        }
    },
    get({ commit, state, dispatch }, id) {
        return new Promise(resolve => {
            api.post(`/call/${id}/get`).then((response) => {
                if (response?.data?.disableFreeTime) {
                    commit('setDisableFreeTime', true)
                }

                if (response?.data?.call) {
                    // call ended on client, and this is the first we are aware of it
                    // not answered, auto close
                    if (
                        response.data.call.endedAt
                        && !response.data.call.answeredAt
                        && !state.currentCall?.endedAt
                    ) {
                        setTimeout(() => { dispatch('close') }, 3000)
                    }

                    commit('setCurrentCall', response.data.call)
                    resolve();
                }
            })
        })
    },
    refreshCurrentCall({ state, dispatch }) {
        if (!state.currentCall) {
            return
        }

        dispatch('get', state.currentCall.id)
    },
    start({ dispatch, rootState, commit }, { userId } = {}) {
        if (rootState.creatorLivestream?.creatorLivestream?.streamInProgress) {
            alert("Cannot call while livestream is active")
            return;
        }

        // no need for preview for creators, they can always call for free
        if (rootState.onboarding.user.creator) {
            commit('reset')
            commit("interface/setVideoCallModalVisible", true, { root: true });
            dispatch('create', { userId })
        } else {
            dispatch('preview', { userId })
        }
    },
    preview({ commit }, { userId } = {}) {
        commit('reset')
        commit("interface/setVideoCallModalVisible", true, { root: true });

        api.post("/call/preview", {
            userId
        }).then((response) => {
            if (response?.data?.callPreview) {
                commit('setCallPreview', response.data.callPreview)
            }
        });
    },
    missedCall({ state, dispatch }) {
        if (!state.currentCall?.answeredAt) {
            dispatch('missed')
        }
    },
    openCall({ commit, dispatch }, { id, startVideo } = {}) {
        commit("interface/setVideoCallModalVisible", true, { root: true });

        dispatch('get', id).then(() => {
            dispatch('seen')

            if (startVideo) {
                dispatch('startVideo')
            }
        })
    },
    create({ commit, dispatch }, { userId } = {}) {
        commit('setCreatingCall', true)

        api.post("/call/create", {
            userId
        }).then((response) => {
            if (response?.data?.call) {
                commit('setCurrentCall', response.data.call)

                commit('setRingingTimeout', setTimeout(() => {
                    dispatch('missedCall')
                }, CALL_TIMEOUT))
            }
        }).catch(() => { })
            .then(() => {
                commit('setCreatingCall', false)
            })
    },
    handleEvent({ state, dispatch }, data) {
        // call created event can have id mismatch
        // if call ended, just open up new call
        if (data.event === 'created') {
            if (
                state.currentCall
                && state.currentCall.callInProgress
                && state.currentCall.id !== data.id
            ) {
                api.post(`/call/${data.id}/busy`);
                return;
            } else {
                dispatch('openCall', {
                    id: data.id
                })
                return
            }
        }

        // if not call created event, it should already have the current call
        // once call is ended, allow new events to be processed
        if (
            state.currentCall
            && !state.currentCall.endedAt
            && state.currentCall.id !== data.id
        ) {
            console.error("pusher call event, id does not match current call")
            return
        }

        if (data.event === 'answered') {
            dispatch('refreshCurrentCall')
            dispatch('startVideo')
            return
        }

        if (data.event === 'busy') {
            return dispatch('refreshCurrentCall')
        }

        if (data.event === 'declined') {
            return dispatch('refreshCurrentCall')
        }

        if (data.event === 'canceled') {
            return dispatch('refreshCurrentCall')
        }

        // no longer in use
        // identical to call ended
        // except only broadcasted to other user
        // causing duplicate windows to stay open
        if (data.event === 'hangup') {
            dispatch('endCall')
            dispatch('refreshCurrentCall')
            return
        }

        // think not in use now
        if (data.event === 'updated') {
            return dispatch('refreshCurrentCall')
        }

        // update 2024-07-29
        // when user call creator, and creator hangs up, if creator has call open on tabs
        // second tab does not get hangup event
        // going to try using ended instead

        // original note/thought here
        // might just be able to use ended instead of hangup
        // need to test both directions first tho make sure no bugs
        // this broadcasts to both clients
        // hangup only broadcasts to the other person
        if (data.event === 'ended') {
            dispatch('endCall')
            dispatch('refreshCurrentCall')
            return
        }

        if (data.event === 'signal') {
            return dispatch('signal', data)
        }
    },
    answer({ commit, state, dispatch }) {
        api.post(`/call/${state.currentCall.id}/answer`).then(async (response) => {
            if (response?.data?.call) {
                commit('setCurrentCall', response.data.call)
                dispatch('startVideo')
            }
        });
    },
    missed({ commit, state }) {
        api.post(`/call/${state.currentCall.id}/missed`).then((response) => {
            if (response?.data?.call) {
                commit('setCurrentCall', response.data.call)
            }
        });
    },
    charge({ commit, state, rootState }) {
        if (rootState.system.cordova) {
            console.log('charges disabled on cordova')
            return;
        }

        const getUniqueBillingKey = () => {
            const randomString = getRandomString(32);
            commit('setUniqueBillingKey', randomString);
            return randomString;
        }

        const uniqueBillingKey = state.uniqueBillingKey
            ? state.uniqueBillingKey
            : getUniqueBillingKey()

        // generate unique billing key
        // check if call has call.uniqueBillingKey
        // prevent a duplicate video client from double, or even triple billing
        api.post(`/call/${state.currentCall.id}/charge`, {
            uniqueBillingKey
        }).then((response) => {
            if (response?.data?.call) {
                commit('setCurrentCall', response.data.call)
            }
        });
    },
    hangup({ state, dispatch, commit }) {
        api.post(`/call/${state.currentCall.id}/hangup`).then((response) => {
            if (response?.data?.call) {
                commit('setCurrentCall', response.data.call)
            }

            // survey them for call quality if the call actually took place
            if (response?.data?.call?.answeredAt && response?.data?.call?.endedAt) {
                // since we are preventing the close/reset
                // manually reset channel to close agora
                dispatch('endCall')
                return;
            }

            dispatch('close')
        })
    },
    seen({ state }) {
        api.post(`/call/${state.currentCall.id}/seen`)
    },
    close({ commit }) {
        commit('reset')
        commit("interface/setVideoCallModalVisible", false, { root: true });
    },
    decline({ state, dispatch }) {
        api.post(`/call/${state.currentCall.id}/decline`).then(() => {
            dispatch('close')
        });
    },
    cancel({ state, dispatch }) {
        api.post(`/call/${state.currentCall.id}/cancel`)
        dispatch('close')
    },
    async signal({ dispatch }) {
        console.log(dispatch)
    },
    startVideo({ state, commit }) {
        commit('setChannel', state.currentCall.uuid)
    },
    endCall({ commit }) {
        commit('setChannel', null)
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
