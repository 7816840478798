
<template>
    <div class="p-2">
        <div class="text-left">
            <b-btn
                @click="$store.commit('entryFee/reset')"
                variant="link"
                class="px-0"
                size="lg"
                v-if="view"
            >
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
                {{ $t("Back") }}
            </b-btn>
        </div>
        <div v-if="view === 'cash'">
            <entry-fee-cash />
        </div>
        <div v-else>
            <entry-fee-intro />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import EntryFeeIntro from "@/components/creator/entryFee/EntryFeeIntro";
import EntryFeeCash from "@/components/creator/entryFee/EntryFeeCash";

export default {
    computed: {
        ...mapState("entryFee", ["view"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        upgradePremium() {
            this.$store.dispatch("premium/upgrade", {
                source: "conversation-unlock",
            });
        },
        useCredit() {
            this.api
                .post("/conversation/unlock", {
                    userId: this.profile.id,
                })
                .then(() => {
                    this.$store.dispatch("conversation/load", {
                        userId: this.profile.id,
                    });
                });
        },
    },
    components: {
        EntryFeeIntro,
        EntryFeeCash,
    },
};
</script>

