<template>
    <div>
        <div
            :key="creatorRequest.id"
            v-for="creatorRequest in profile.creatorRequests"
            @click="closeProfileAndRedirectToRequestView(creatorRequest)"
            class="p-2 bg-light"
        >
            <creator-request-index-item :creatorRequest="creatorRequest" />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import CreatorRequestIndexItem from "@/components/creatorRequest/CreatorRequestIndexItem";

export default {
    computed: {
        ...mapState("profile", ["profile"]),
    },
    methods: {
        closeProfileAndRedirectToRequestView(creatorRequest) {
            this.$router.push({
                name: "accountCreatorRequestView",
                params: { id: creatorRequest.id },
            });

            this.$store.dispatch("profile/close");
        },
    },
    components: {
        CreatorRequestIndexItem,
    },
};
</script>