<template>
    <div>
        <div v-if="!selectedLocation">
            <div>
                <font-awesome-icon
                    icon="fa-duotone fa-location-dot"
                    size="3x"
                />
            </div>

            <div class="my-3">Search for your location</div>

            <b-form @submit.stop.prevent="search">
                <b-input-group>
                    <b-input-group-prepend v-if="keyword">
                        <b-btn
                            @click="keyword = ''"
                            variant="light"
                            class="border border-light text-danger"
                            v-if="keyword"
                        >
                            <font-awesome-icon icon="fa-light fa-times" />
                        </b-btn>
                    </b-input-group-prepend>
                    <b-input
                        v-model="keyword"
                        type="text"
                        class="border-light"
                        placeholder="Search cities..."
                        autocorrect="off"
                        autocomplete="off"
                        autocapitalize="none"
                        size="lg"
                    />
                    <b-input-group-append>
                        <b-btn
                            @click="search"
                            variant="light"
                            class="border border-light"
                            size="lg"
                        >
                            <font-awesome-icon
                                icon="fa-duotone fa-magnifying-glass"
                            />
                        </b-btn>
                    </b-input-group-append>
                </b-input-group>
            </b-form>
        </div>

        <div v-if="selectedLocation">
            <b-alert variant="primary" show>
                Please confirm your location
            </b-alert>

            <div class="my-3 bg-light py-3">
                <h3>
                    {{ selectedLocation.city.name }}
                </h3>
                <div class="lead text-secondary">
                    <img :src="selectedLocation.country.flagUrl" />
                    {{ selectedLocation.country.name }}
                </div>
            </div>

            <div class="my-3">
                <b-btn
                    @click="setLocation"
                    variant="primary"
                    class="mt-3"
                    size="lg"
                    pill
                    block
                >
                    Set Location
                </b-btn>
                <b-btn
                    @click="selectedLocation = null"
                    variant="link"
                    class="text-danger mt-3"
                >
                    Cancel
                </b-btn>
            </div>
        </div>
        <div v-else class="mt-3">
            <b-btn
                @click="selectedLocation = location"
                :key="location.city.id"
                v-for="location in locations"
                block
                variant="light"
                pill
                class="my-3"
            >
                <h5>
                    {{ location.city.name }}
                </h5>
                <div class="small text-secondary">
                    <img :src="location.country.flagUrl" />
                    {{ location.country.name }}
                </div>
            </b-btn>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            confirmed: false,
            keyword: null,
            locations: null,
            selectedLocation: null,
        };
    },
    computed: {
        ...mapState("user", ["refreshingLocation"]),
    },
    methods: {
        setLocation() {
            this.api
                .post("/location/set", {
                    cityId: this.selectedLocation.city.id,
                })
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                    this.$store.dispatch("setting/closeOnboarding");
                });
        },
        search() {
            this.api
                .post("/location/search", {
                    keyword: this.keyword,
                })
                .then((response) => {
                    this.locations = response.data.locations;
                });
        },
    },
};
</script>