import api from "@/service/api";

const state = () => ({
    reason: null,
    userBeingReported: null,
})

const mutations = {
    setReason(state, reason) {
        state.reason = reason;
    },
    setUserBeingReported(state, userBeingReported) {
        state.userBeingReported = userBeingReported;
    },
}

const actions = {
    user({ commit }, profile) {
        commit('setUserBeingReported', profile)
        commit('interface/setReportUserModalVisible', true, { root: true })
    },
    reason({ commit, state }, reasonId) {
        commit("user/blockUserId", state.userBeingReported.id, { root: true });

        api.post("/report/reason", {
            userId: state.userBeingReported.id,
            reasonId
        });
    },
    full({ commit, state }, reportText) {
        api.post("/report/full", {
            userId: state.userBeingReported.id,
            reportText
        });

        commit('interface/setReportUserModalVisible', false, { root: true })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
