<template>
    <div v-if="hasScrolledToPagination">
        <div v-if="hasScrolled">
            <b-btn
                @click="scrollTop"
                variant="light"
                class="fab fab-right action-button glass glass-light border-0 text-primary"
            >
                <span class="text-dark">
                    <font-awesome-icon
                        size="lg"
                        icon="fa-duotone fa-circle-up"
                    />
                </span>
            </b-btn>
        </div>
        <div v-else>
            <b-btn
                @click="refreshSearch"
                variant="light"
                class="fab fab-right action-button glass glass-light border-0"
            >
                <span class="text-dark">
                    <font-awesome-icon
                        size="lg"
                        icon="fa-light fa-arrows-rotate"
                    />
                </span>
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("search", ["hasScrolled", "hasScrolledToPagination"]),
    },
    methods: {
        scrollTop() {
            this.$store.commit("search/setHasScrolled", false);
            this.$scrollToTop();
        },
        refreshSearch() {
            this.$store.dispatch("search/refresh");
        },
    },
};
</script>
        
        