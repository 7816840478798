<template>
    <span>
        {{ $date(timestamp).from($date(this.now)) }}
    </span>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: ["timestamp"],
    computed: {
        ...mapState("system", ["now"]),
    },
};
</script>
