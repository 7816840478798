<template>
    <div>
        <div v-if="!creatorRequest.refunded">
            <creator-request-media />
        </div>

        <div v-if="creatorRequest.dispute">
            <creator-request-dispute-index />
        </div>

        <div v-if="creatorRequest.fulfilledAt">
            <b-alert variant="success" show class="mb-0">
                <h5>
                    <font-awesome-icon icon="fa-light fa-check-circle" />
                    Completed
                </h5>
                <div v-if="creatorRequest.escrow">
                    <coin-badge :amount="creatorRequest.payout" />
                    will be transferred to your wallet in
                    {{ creatorRequestConfig?.releaseEscrowHours }} hours if the
                    user accepts your content
                </div>
                <div v-else-if="!creatorRequest.refunded">
                    <coin-badge :amount="creatorRequest.payout" />
                    have been deposited to your wallet
                </div>
            </b-alert>
        </div>
        <div v-else-if="creatorRequest.refunded">
            <b-alert variant="warning" show class="mb-0">
                <h3>
                    <font-awesome-icon icon="fa-duotone fa-share" />
                    Refunded
                </h3>
                {{ creatorRequest.price }}
                credits have been refunded to the user
            </b-alert>
        </div>
        <div v-else>
            <b-btn
                :disabled="!canMarkCompleted || saving"
                @click="requestCompleted"
                :variant="canMarkCompleted ? 'primary' : 'secondary'"
                size="lg"
                class="py-3 mt-3"
                pill
                block
            >
                <h4 class="mb-0">Complete Request</h4>
            </b-btn>

            <div
                v-if="creatorRequestConfig"
                class="small mt-2 text-secondary text-center"
            >
                <div v-if="creatorRequest.dispute" class="text-warning">
                    <font-awesome-icon icon="fa-duotone fa-timer" />
                    You have {{ creatorRequestConfig.disputeResponseDueHours }}
                    hours to correct the content, or the request will be
                    automatically refunded
                </div>
                <div v-else>
                    <coin-badge :amount="creatorRequest.payout" />
                    credits are held in escrow. If the user accepts your
                    content, the credits will be instantly be transferred to
                    you. They have
                    {{ creatorRequestConfig?.releaseEscrowHours }} hours to
                    respond, after which it will be automatically accepted
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import CreatorRequestMedia from "@/components/creator/requests/CreatorRequestMedia";
import CreatorRequestDisputeIndex from "@/components/creator/requests/CreatorRequestDisputeIndex";

import { mapGetters, mapState } from "vuex";

import copy from "clipboard-copy";

export default {
    data() {
        return {
            modal: false,
            saving: false,
        };
    },
    created() {
        if (this.$route.params?.id) {
            this.$store.dispatch("creatorRequest/get", this.$route.params?.id);
        }
    },
    watch: {
        $route(to) {
            if (
                to?.params?.id !==
                this.$store.state.creatorRequest.creatorRequest?.id
            ) {
                this.$store.dispatch("creatorRequest/get", to.params.id);
            }
        },
    },
    computed: {
        canMarkCompleted() {
            return (
                !this.unsettledDisputes?.filter(
                    (creatorRequestDispute) =>
                        !this.rectifiedDisputeIds.includes(
                            creatorRequestDispute.id
                        )
                ).length &&
                this.creatorRequest.updatedContent &&
                this.creatorRequest.medias.length > 0
            );
        },
        ...mapState("creatorRequest", [
            "creatorRequest",
            "creatorRequestDisputes",
            "disputeRate",
            "rectifiedDisputeIds",
            "creatorRequestConfig",
        ]),
        ...mapGetters("creatorRequest", ["unsettledDisputes"]),
    },
    methods: {
        copyRequest() {
            copy(this.creatorRequest.request);
        },
        requestCompleted() {
            this.saving = true;

            this.api
                .post("/creatorRequest/complete", {
                    creatorRequestId: this.creatorRequest.id,
                    rectifiedDisputeIds: this.rectifiedDisputeIds,
                })
                .then(() => {
                    this.$store.dispatch("creatorRequest/refresh");
                    this.$store.dispatch("onboarding/status");
                })
                .catch(() => {})
                .then(() => {
                    this.saving = false;
                });
        },
    },
    components: {
        CreatorRequestMedia,
        CreatorRequestDisputeIndex,
    },
};
</script>