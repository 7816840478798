<template>
    <div class="border bg-light rounded p-2">
        <b-badge class="float-right" variant="primary" pill>
            {{ account.details.bankCode }}
        </b-badge>
        <h5>
            {{ account.accountHolderName }}
        </h5>
        <div>
            {{ account.details.accountNumber }}
        </div>
        <div>
            {{ account.details.address.city }},
            {{ account.details.address.country }}
        </div>

        <slot />
    </div>
</template>

<script>
export default {
    props: ["account"],
};
</script>