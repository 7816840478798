<template>
    <div>
        <h5 class="text-primary">{{ $t("How Video Calling Works?") }}</h5>

        {{
            $t(
                "Video calling is a great way to earn for every minute you are on a call. As long as the other user remains on the call they will continue to be charged by the minute until the call ends"
            )
        }}

        <h5 class="text-primary mt-3">
            {{ $t("Start the call") }}
        </h5>

        {{
            $t(
                "When you are viewing another users profile, or you are chatting with the user, notice the Call button, click that to begin the call, and wait for them to answer"
            )
        }}

        <h5 class="text-primary mt-3">
            {{ $t("Paid by the minute") }}
        </h5>

        {{
            $t(
                "At the beginning of each minute, the customer will be billed the amount per minute shown at the start of the call, you will see the amount earned update in real-time so you know how much you're earning"
            )
        }}

        <h5 class="text-primary mt-3">
            {{ $t("Browser-only, not available on the app") }}
        </h5>

        {{
            $t(
                "Our video calling is only available using your web browser. You can start the call in the app, and you will be prompted to open the link in your browser to complete the call"
            )
        }}

        <h5 class="text-primary mt-3">
            {{ $t("15 seconds of free time") }}
        </h5>

        {{
            $t(
                "When the call starts, the first 15 seconds will be free to ensure there is a good connection before the customer is charged"
            )
        }}

        <b-btn
            @click="finished"
            variant="primary"
            pill
            block
            size="lg"
            class="mt-3"
        >
            {{ $t("Ok, got it") }}
        </b-btn>
    </div>
</template>

<script>
export default {
    methods: {
        finished() {
            this.api.post("/userFlag/creatorGuideVideoCall").then(() => {
                this.$store.dispatch("onboarding/status");
                this.$store.dispatch("creatorGuide/refresh");
                this.$store.commit("creatorGuide/reset");
            });
        },
    },
    components: {},
};
</script>


