<template>
    <div class="py-3 text-center">
        <div class="py-3">
            <div>
                <font-awesome-icon
                    class="text-success"
                    icon="fa-light fa-check-circle"
                    size="5x"
                />
            </div>
            <h3 class="mt-3">
                {{ $t("Purchase Successful") }}
            </h3>
        </div>

        <refill-package />

        <b-btn
            @click="closeWallet"
            variant="primary"
            class="mt-3"
            pill
            block
            size="lg"
        >
            {{ $t("Close Wallet") }}
        </b-btn>
    </div>
</template>

<script>
import RefillPackage from "@/components/wallet/refill/RefillPackage";

export default {
    methods: {
        closeWallet() {
            this.$store.dispatch("onboarding/status");
            this.$store.commit("wallet/reset");
            this.$store.commit("interface/setUserWalletModalVisible", false);
        },
    },
    components: {
        RefillPackage,
    },
};
</script>
