<template>
    <div v-if="!hasGifted">
        <b-alert
            show
            variant="primary"
            class="rounded-0 border-0 mb-0 text-center"
        >
            <h5>
                <font-awesome-icon icon="fa-duotone fa-gift" class="mr-1" />
                {{ $t("Get ") }}
                <span class="text-capitalize">
                    {{ onboardingUser.seekingPronoun }}
                </span>
                {{ $t(" Attention with a Gift") }}
            </h5>
            <div class="small my-2">
                {{
                    $t(
                        "Sending her a gift is a small way of showing you appreciate her posts, and would like to see more"
                    )
                }}
            </div>
            <b-row class="text-center my-1">
                <b-col cols="3">
                    <font-awesome-icon icon="fa-light fa-arrow-down" />
                </b-col>
                <b-col cols="3">
                    <font-awesome-icon icon="fa-light fa-arrow-down" />
                </b-col>
                <b-col cols="3">
                    <font-awesome-icon icon="fa-light fa-arrow-down" />
                </b-col>
                <b-col cols="3">
                    <font-awesome-icon icon="fa-light fa-arrow-down" />
                </b-col>
            </b-row>
        </b-alert>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        hasGifted() {
            if (this.userHasGifted) {
                return true;
            }

            if (this.onboardingUser.viewedHasGifted) {
                return true;
            }

            return false;
        },
        ...mapState("creatorGift", ["userHasGifted"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        completeVerification() {
            this.$store.dispatch("setting/editOnboardingStep", "verification");
        },
        addEmail(email) {
            this.$store.dispatch("inviteFriends/addEmail", email);
        },
    },
    components: {},
};
</script>