<template>
    <div>
        <fitty :options="{ maxSize: 21 }">
            <template v-slot:content>
                {{ $t("What age range are you looking for?") }}
            </template>
        </fitty>
        <b-alert v-if="error" show variant="danger">
            {{ error }}
        </b-alert>
        <div class="d-flex align-items-center justify-content-around py-3">
            <div>
                <b-form-select
                    v-model="selectedFrom"
                    :options="filteredFromAges"
                    size="lg"
                ></b-form-select>
            </div>
            <div>
                <font-awesome-icon icon="fa-light fa-dash" />
            </div>
            <div>
                <b-form-select
                    v-model="selectedTo"
                    :options="filteredToAges"
                    size="lg"
                ></b-form-select>
            </div>
        </div>
        <fitty
            v-if="selectedFrom && selectedTo"
            class="pb-3"
            :options="{ maxSize: 21 }"
        >
            <template v-slot:content>
                {{ $t("I want to meet people age ") }}
                <span class="text-underline text-primary">
                    {{ selectedFrom }}
                </span>
                {{ $t(" to ") }}
                <span class="text-underline text-primary">
                    {{ selectedTo }}<span v-if="selectedTo === 65">+</span>
                </span>
            </template>
        </fitty>

        <b-btn
            :disabled="!complete || saving"
            @click="save"
            variant="primary"
            block
            size="lg"
        >
            {{ $t("Save") }}
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            error: null,
            ageRangePadding: 6,
            saving: false,
            ageRange: this.range(18, 65),
            selectedFrom: null,
            selectedTo: null,
        };
    },
    mounted() {
        if (this.user.ageFrom) {
            this.setFrom(this.user.ageFrom);
        }

        if (this.user.ageTo) {
            this.setTo(this.user.ageTo);
        }
    },
    computed: {
        complete() {
            return (
                this.selectedFrom &&
                this.selectedTo &&
                this.selectedFrom < this.selectedTo
            );
        },
        ...mapState("onboarding", ["user"]),
        filteredFromAges() {
            if (this.selectedTo) {
                const filtered = this.ageRange.filter(
                    (age) => age < this.selectedTo - this.ageRangePadding
                );

                if (filtered.length) {
                    return filtered;
                } else {
                    return [[...this.ageRange].shift()];
                }
            }

            return this.ageRange;
        },
        filteredToAges() {
            if (this.selectedFrom) {
                const filtered = this.ageRange.filter(
                    (age) => age > this.selectedFrom + this.ageRangePadding
                );

                if (filtered.length) {
                    return filtered;
                } else {
                    return [[...this.ageRange].pop()];
                }
            }

            return this.ageRange;
        },
    },
    methods: {
        range(start, end) {
            return Array(end - start + 1)
                .fill()
                .map((unused, index) => start + index);
        },
        setFrom(age) {
            this.error = null;
            this.selectedFrom = age;
        },
        setTo(age) {
            if (!this.selectedFrom) {
                this.error = this.$t("Select Age From First");
                return;
            }

            this.selectedTo = age;
        },
        save() {
            this.saving = true;

            this.api
                .post("/filter/setAgeRange", {
                    ageFrom: this.selectedFrom,
                    ageTo: this.selectedTo,
                })
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                    this.$store.dispatch("user/refreshFilteredContent");
                })
                .catch(() => {})
                .then(() => {
                    this.saving = false;
                });
        },
    },
};
</script>