import api from "@/service/api";

const state = () => ({
    finishedInitialLoad: false,
    currentSlidePosition: null,
    currentPhoto: null,
    currentPost: null,
});

const mutations = {
    reset(state) {
        state.finishedInitialLoad = false
        state.currentSlidePosition = null
        state.currentPhoto = null
        state.currentPost = null
    },
    setFinishedInitialLoad(state) {
        state.finishedInitialLoad = true;
    },
    setCurrentSlidePosition(state, value) {
        state.currentSlidePosition = value;
    },
    setCurrentPost(state, value) {
        state.currentPost = value;
    },
    setCurrentPhoto(state, photo) {
        state.currentPhoto = photo;
    },
};

const actions = {
    launch({ commit, state }, creatorPostPhoto) {
        commit("setCurrentPhoto", creatorPostPhoto);

        const photoPosition = state.currentPost.photos.findIndex(photo => photo.id === creatorPostPhoto.id)

        if (photoPosition) {
            commit('setCurrentSlidePosition', photoPosition)
        }

        commit("interface/setPostPhotoModalVisible", true, { root: true });
    },
    setCurrentSlidePosition({ commit, state }, currentSlidePosition) {
        commit("setCurrentSlidePosition", currentSlidePosition);

        // on initial load swiper will almost always load first
        if (state.currentPost?.photos?.[currentSlidePosition]) {
            commit("setCurrentPhoto", state.currentPost?.photos?.[currentSlidePosition]);
        }
    },
    setFinishedInitialLoad({ state, commit }) {
        if (state.currentSlidePosition !== null && state.photosToRate?.[state.currentSlidePosition]) {
            commit("setCurrentPhoto", state.photosToRate?.[state.currentSlidePosition]);
        }

        commit("setFinishedInitialLoad");
    },
    refresh({ dispatch, commit, state }) {
        return new Promise(resolve => {
            api.post("/post/get", {
                creatorPostId: state.currentPost.id
            })
                .then((response) => {
                    if (response?.data?.creatorPost) {
                        commit('setCurrentPost', response?.data?.creatorPost);
                    }
                })
                .catch(error => {
                    console.error(error)
                })
                .then(() => {
                    dispatch("postIndex/refresh", null, { root: true })
                    resolve()
                })
        })
    },
};

const getters = {
    currentPhotoIsMine(state, getters, rootState) {
        if (rootState.onboarding?.user?.id === state.currentPhoto?.userId) {
            return true
        }

        return false
    },
    currentPhotoIsPrimary(state) {
        if (state.currentPhoto?.id === state.currentPost?.primaryPhotoId) {
            return true
        }

        return false
    },
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
