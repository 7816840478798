<template>
    <div>
        <coin-badge :amount="creatorLivestream.totalEarnings" />
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {};
    },
    mounted() {
        //
    },
    computed: {
        ...mapState("creatorLivestream", ["creatorLivestream"]),
    },
    methods: {
        showInviteDialog() {},
    },
};
</script>