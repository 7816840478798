<template>
    <generic-page>
        <b-btn variant="link" size="lg" class="pl-0" @click="$router.back()">
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            Back
        </b-btn>

        <div class="bg-white p-3">
            <h5>My Currency</h5>
            <h1 class="text-primary">
                {{ onboardingUser.currency }}
            </h1>

            <b-button @click="edit = true" variant="light" pill block>
                <font-awesome-icon icon="fa-duotone fa-pencil" />
                Change Currency
            </b-button>

            <div v-if="edit">
                <b-alert variant="warning" show class="mt-3">
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                    />
                    Warning: the currency you select here is the currency you
                    will withdraw. Make sure the currency is the same as the
                    account you will withdraw your money to
                </b-alert>

                <h5>Select Currency</h5>

                <b-btn
                    :key="currency.id"
                    v-for="currency in currencies"
                    @click="setCurrency(currency)"
                    block
                    class="mt-2"
                    variant="light"
                    pill
                >
                    <div
                        class="d-flex align-items-center justify-content-between"
                    >
                        {{ currency.name }}
                        <font-awesome-icon
                            size="lg"
                            icon="fa-light fa-chevron-right"
                        />
                    </div>
                </b-btn>
            </div>
        </div>
    </generic-page>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            edit: null,
            currencies: null,
        };
    },
    created() {
        this.refresh();
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        setCurrency(currency) {
            this.api.post(`/currency/${currency.id}/update`).then(() => {
                this.$store.dispatch("onboarding/status");
                this.edit = false;
            });
        },
        refresh() {
            this.api.post("/currency/index").then((response) => {
                this.currencies = response?.data?.currencies;
            });
        },
    },
};
</script>