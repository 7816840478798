<template>
    <div>
        <div v-if="!canEngage">
            <entry-fee />
        </div>
        <div v-else>
            <profile-conversation-messages />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import ProfileConversationMessages from "@/components/profile/conversation/ProfileConversationMessages";
import EntryFee from "@/components/creator/entryFee/EntryFee";

export default {
    computed: {
        canEngage() {
            if (!this.onboardingUser.creator) {
                return true;
            }

            if (this.onboardingUser.invitedUserIds?.includes(this.profile.id)) {
                return true;
            }

            if (this.onboardingUser.canEngage) {
                return true;
            }

            return false;
        },
        ...mapState("profile", ["profileError", "profile"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        EntryFee,
        ProfileConversationMessages,
    },
};
</script>
