

<template>
    <b-btn-group class="w-100 photo-rating mt-3 px-3">
        <b-btn
            @click="downvote"
            variant="light"
            class="h-100 border text-danger btn-brand"
            :disabled="throttled"
        >
            <font-awesome-icon icon="fa-duotone fa-thumbs-down" size="3x" />
        </b-btn>
        <b-btn
            @click="upvote"
            variant="light"
            class="h-100 border text-primary btn-brand"
            :disabled="throttled"
        >
            <font-awesome-icon icon="fa-duotone fa-thumbs-up" size="3x" />
        </b-btn>
    </b-btn-group>
</template>


<style lang="scss" scoped>
@import "@/scss/variables.scss";
</style>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            throttled: false,
        };
    },
    computed: {
        ...mapState("system", ["touchCapable"]),
        ...mapState("swipe", [
            "photosToRate",
            "currentPhotoToRate",
            "upvotedPhotoIds",
            "downvotedPhotoIds",
        ]),
    },
    methods: {
        upvote() {
            this.throttle(() => {
                this.$store.dispatch("swipe/upvote");
            });
        },
        downvote() {
            this.throttle(() => {
                this.$store.dispatch("swipe/downvote");
            });
        },
        throttle(callback) {
            if (this.throttled) {
                return;
            }

            this.throttled = true;

            callback();

            setTimeout(() => {
                this.throttled = false;
            }, 300);
        },
    },
};
</script>
