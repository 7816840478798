<template>
    <div>
        <div class="d-flex align-items-center mx-auto bg-white p-2">
            <div class="mr-2">
                <img
                    height="64"
                    width="64"
                    class="rounded-circle"
                    :src="brand.homepageLogo"
                />
            </div>
            <div>
                <h3 class="mb-0">
                    {{ brand.name }}
                </h3>
                <h5 class="mb-0">
                    {{ brand.slogan }}
                </h5>
            </div>
        </div>

        <b-row no-gutters>
            <b-col cols="4" :key="user.id" v-for="user in users">
                <img class="img-fluid" :src="user.primaryPhoto.search" />
            </b-col>
        </b-row>

        <div class="p-3 bg-white">
            <h3>Whats in store for me here?</h3>

            <p class="lead mb-0">
                <span class="text-primary">
                    Meet friendly and attractive ladies
                </span>
                from all over the world, exchange photos &amp; videos and live
                chat. Ladies can earn rewards in the app, and love being spoiled
                with gifts from generous men
            </p>

            <p class="lead mt-2">
                <span class="text-primary"
                    >We're a social platform for singles.</span
                >
                You've never seen anything quite like it, because there isn't
                anything else like it, you have to experience it to truly
                appreciate the difference between other platforms
            </p>

            <h3>A bit more about our company and brand</h3>

            <div class="mt-1">
                <font-awesome-icon
                    icon="fa-duotone fa-shield-check"
                    class="text-primary"
                />
                Our company is based in Las Vegas in the United States. Founded
                in 2015, we operate in over 200 countries, and have over 4
                million users who have joined our platform across several brands
            </div>

            <div class="mt-1">
                <font-awesome-icon
                    icon="fa-duotone fa-shield-check"
                    class="text-primary"
                />
                Transparent pricing on all paid features, no hidden fees,
                subscriptions or automated recurring payments
            </div>

            <div class="mt-1">
                <font-awesome-icon
                    icon="fa-duotone fa-shield-check"
                    class="text-primary"
                />
                Our platform uses a pay-as-you-go credit system, only pay for
                what you use and consume in the app, credits never expire
            </div>

            <div class="mt-1">
                <font-awesome-icon
                    icon="fa-duotone fa-shield-check"
                    class="text-primary"
                />
                Completely private and secure, easily hide your profile if you
                don't want to be discovered by other users, only see the users
                you are truly interested in
            </div>

            <div class="mt-1">
                <font-awesome-icon
                    icon="fa-duotone fa-shield-check"
                    class="text-primary"
                />
                Discreet signup - no photo or credit card required for guys to
                join the platform and browse around, video verification required
                for ladies
            </div>

            <div class="mt-1">
                <font-awesome-icon
                    icon="fa-duotone fa-shield-check"
                    class="text-primary"
                />
                Easy email unsubscribe - block a user, or and entire country, or
                unsubscribe from all emails to opt-out for maximum privacy, all
                with one click direct from your inbox
            </div>
        </div>

        <div class="text-center py-3">
            <div class="text-primary mb-3">
                Scan the QR code below on your phone to get started. Signup only
                takes about 30 seconds
            </div>
            <font-awesome-icon
                class="text-primary mx-5"
                icon="fa-light fa-arrow-down"
                size="2x"
            />
            <font-awesome-icon
                class="text-primary mx-5"
                icon="fa-light fa-arrow-down"
                size="2x"
            />
        </div>

        <div style="height: 150px"></div>

        <b-container class="fixed-bottom" style="bottom: 1rem">
            <b-row>
                <b-col cols="10" class="mx-auto">
                    <div
                        class="floating-div rounded d-flex align-items-center justify-content-between px-2"
                    >
                        <div class="text-left">
                            <h5 class="mb-1">Free Signup</h5>
                            <div class="small text-primary font-weight-bold">
                                No Credit Card or Photo Required
                            </div>
                        </div>
                        <div class="mx-2">
                            <font-awesome-icon icon="fa-light fa-arrow-right" />
                        </div>
                        <img
                            height="100"
                            width="100"
                            v-if="qrcode"
                            :src="qrcode"
                        />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>


<style>
.floating-div {
    color: #000;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    bottom: 1rem;
}
</style>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            users: null,
            qrcode: null,
        };
    },
    created() {
        const usersJson = localStorage.getItem("affiliatePromoteUsers");

        if (usersJson) {
            this.users = JSON.parse(usersJson);
        }

        const qrcodeJson = localStorage.getItem("affiliatePromoteQrcode");

        if (qrcodeJson) {
            this.qrcode = JSON.parse(qrcodeJson);
        }

        this.api.post("/affiliate/promote").then((response) => {
            if (response?.data?.users) {
                this.users = response.data.users;

                localStorage.setItem(
                    "affiliatePromoteUsers",
                    JSON.stringify(response.data.users)
                );
            }
        });

        this.api.post("/invite/qrcode").then((response) => {
            if (response?.data?.qrcode) {
                this.qrcode = response.data.qrcode;

                localStorage.setItem(
                    "affiliatePromoteQrcode",
                    JSON.stringify(response.data.qrcode)
                );
            }
        });
    },
    computed: {
        ...mapState("brand", ["brand"]),
    },
    components: {},
};
</script>