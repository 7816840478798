<template>
    <div>
        <div v-if="commentInputFocused">
            <livestream-comment-input />
        </div>
        <div v-else>
            <livestream-comment-focus />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import LivestreamCommentFocus from "@/components/livestream/comment/LivestreamCommentFocus";
import LivestreamCommentInput from "@/components/livestream/comment/LivestreamCommentInput";

export default {
    computed: {
        ...mapState("livestream", ["commentInputFocused"]),
    },
    components: {
        LivestreamCommentInput,

        LivestreamCommentFocus,
    },
};
</script>