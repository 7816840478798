import api from "@/service/api";

const state = () => ({
    hasLoaded: false,
    isiOS: false,
    isAndroidOS: false,
    isMobile: false,
})

const mutations = {
    updateValues(state, mobileDetect) {
        state.hasLoaded = true

        state.isiOS = mobileDetect.isiOS;
        state.isAndroidOS = mobileDetect.isAndroidOS;
        state.isMobile = mobileDetect.isMobile;
    },
}

const actions = {
    refresh({ state, commit }) {

        // for now only load once
        // no reason yet to do multiple times, pass a flag if need to force later
        if (state.hasLoaded) {
            return;
        }

        api
            .post("/mobileDetect/get")
            .then((response) => {
                if (response?.data?.mobileDetect) {
                    commit("updateValues", response.data.mobileDetect);
                }
            })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
