import api from "@/service/api";

const state = () => ({
    lastUpdatedPost: null,
})

const mutations = {
    postUpdated(state, value) {
        state.lastUpdatedPost = value
    },
}

const actions = {
    sendTip({ commit }, creatorPostId) {
        api
            .post("/tip/create", {
                creatorPostId,
                amount: 1,
            })
            .then((response) => {
                if (response?.data?.creatorPost) {
                    commit("postUpdated", response.data.creatorPost);
                }
            })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
