<template>
    <div class="p-4 text-center">
        <img
            class="rounded-circle img-fluid call-avatar"
            :src="standardMap.primaryPhoto.medium"
        />
        <h5 class="mt-3 mb-0">
            {{ standardMap.name }}
        </h5>
        <div class="mt-1">
            <img class="mr-1" :src="standardMap.flagUrl" />
            {{ standardMap.location }}
        </div>
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
.call-avatar {
    max-width: 250px;
}
</style>

<script>
export default {
    computed: {},
    components: {},
    props: ["standardMap"],
};
</script>