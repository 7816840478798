<template>
    <generic-page>
        <back-to-dashboard />

        <h3>
            {{ $t("Leaderboard") }}
        </h3>

        <div v-if="loading">
            <generic-loading />
        </div>
        <div v-else>
            <b-table-simple bordered striped small>
                <thead>
                    <tr>
                        <th>{{ $t("#") }}</th>
                        <th>{{ $t("Country") }}</th>
                        <th
                            v-if="!onboardingUser.disableInvite"
                            class="text-center"
                        >
                            {{ $t("Invites") }}
                        </th>
                        <th class="text-center">{{ $t("Earnings") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr :key="user.id" v-for="user in users">
                        <td style="max-width: 80px" class="small text-truncate">
                            <b-badge v-if="user.me" variant="primary" pill>
                                {{ $t("Me") }}
                            </b-badge>
                            <code v-else class="text-primary">{{
                                user.id
                            }}</code>
                        </td>
                        <td
                            style="max-width: 150px"
                            class="small text-truncate"
                        >
                            <img :src="user.flagUrl" />
                            {{ user.country }}
                        </td>
                        <td
                            v-if="!onboardingUser.disableInvite"
                            class="bg-white text-center small"
                        >
                            {{ user.inviteCount }}
                        </td>
                        <td class="bg-white text-center small">
                            <coin-badge :amount="user.totalCreditsEarned" />
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>
        </div>
    </generic-page>
</template>

<script>
import BackToDashboard from "@/components/utility/BackToDashboard";
import { mapState } from "vuex";

export default {
    data: () => ({
        users: null,
        loading: false,
    }),
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    mounted() {
        this.loading = true;

        this.api.post("/leaderboard/index").then((response) => {
            if (response?.data?.users) {
                this.users = response.data.users;
            }

            this.loading = false;
        });
    },
    components: {
        BackToDashboard,
    },
};
</script>