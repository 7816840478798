<template>
    <div>
        <b-btn
            @click="sendCredits"
            pill
            variant="light"
            class="d-flex align-items-center bg-white shadow-sm shadow-blue mr-2"
            size="sm"
        >
            <span class="text-primary">
                <font-awesome-icon class="mr-1" icon="fa-duotone fa-coin" />
            </span>
            {{ $t("Send") }}
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    methods: {
        sendCredits() {
            this.$store.dispatch("sendCredits/sendToUser", this.profile);
        },
    },
    computed: {
        ...mapState("profile", ["profile"]),
    },
};
</script>