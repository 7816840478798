<template>
    <div>
        <div v-if="canInteract">
            <gift-creator-post :creatorPost="creatorPost" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import GiftCreatorPost from "@/components/gift/GiftCreatorPost";

export default {
    computed: {
        canInteract() {
            return (
                this.isLoggedIn &&
                this.creatorPost.isCreator &&
                this.creatorPost.userId !== this.onboardingUser.id
            );
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapGetters("user", ["isLoggedIn"]),
    },
    components: {
        GiftCreatorPost,
    },
    props: ["creatorPost"],
};
</script>