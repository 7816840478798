<template>
    <div>
        <div v-if="onboardingSeekingOptionsVisible">
            <onboarding-seeking-options />
        </div>
        <div v-else>
            <onboarding-seeking-selector />
        </div>
    </div>
</template>



<script>
import OnboardingSeekingOptions from "@/components/onboarding/seeking/OnboardingSeekingOptions";
import OnboardingSeekingSelector from "@/components/onboarding/seeking/OnboardingSeekingSelector";
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("interface", ["onboardingSeekingOptionsVisible"]),
    },
    components: {
        OnboardingSeekingSelector,
        OnboardingSeekingOptions,
    },
};
</script>