<template>
    <div>
        <b-alert variant="warning" show>
            <h5>Disputes</h5>
            <b-list-group>
                <b-list-group-item
                    action
                    :key="creatorRequestDispute.id"
                    v-for="creatorRequestDispute in creatorRequestDisputes"
                    class="d-flex align-items-center justify-content-between"
                    @click="viewDispute(creatorRequestDispute)"
                >
                    <h5 class="mb-0">
                        Reason:
                        {{ creatorRequestDispute.reason }}
                        <div class="small text-secondary">
                            {{ creatorRequestDispute.createdRelative }}
                        </div>
                    </h5>
                    <div>
                        <creator-request-dispute-status
                            :creatorRequestDispute="creatorRequestDispute"
                        />
                    </div>
                </b-list-group-item>
            </b-list-group>

            <div v-if="creatorRequestConfig" class="small mt-2 text-secondary">
                <font-awesome-icon icon="fa-duotone fa-info-circle" />
                Creators have
                {{ creatorRequestConfig.disputeResponseDueHours }} hours to
                correct the content, if they do not respond, you will
                automatically be refunded
            </div>
        </b-alert>

        <b-modal centered hide-footer v-model="modal" title="Dispute">
            <div v-if="creatorRequestDispute">
                <creator-request-dispute-view
                    @closeModal="closeModal"
                    :creatorRequestDispute="creatorRequestDispute"
                />
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";

import CreatorRequestDisputeStatus from "@/components/account/creatorRequests/CreatorRequestDisputeStatus";
import CreatorRequestDisputeView from "@/components/account/creatorRequests/CreatorRequestDisputeView";

export default {
    data() {
        return {
            modal: false,
            confirmed: false,
            creatorRequestDispute: null,
            creatorDisputeConfig: null,
        };
    },
    computed: {
        ...mapState("request", [
            "creatorRequest",
            "disputeRate",
            "disputeRateAfter",
            "creatorRequestConfig",
            "creatorRequestDisputes",
        ]),
    },
    methods: {
        closeModal() {
            this.modal = false;
        },
        viewDispute(creatorRequestDispute) {
            this.creatorRequestDispute = creatorRequestDispute;
            this.modal = true;
        },
    },
    components: {
        CreatorRequestDisputeView,
        CreatorRequestDisputeStatus,
    },
};
</script>