<template>
    <div>
        <div v-if="showPhotoRules">
            <onboarding-photo-rules />

            <div class="py-3">
                <b-btn
                    pill
                    @click="acceptRules"
                    variant="primary"
                    show
                    size="lg"
                    block
                >
                    {{ $t("Continue") }}
                </b-btn>
            </div>
        </div>
        <div v-else>
            <b-alert
                v-if="hasAnyPhotos && !complete"
                variant="primary"
                show
                class="border-0 rounded-0"
            >
                <div>
                    {{ $t("At least ") }}
                    {{ minimumRequiredPublicPhotos }}
                    {{
                        $t("public photo")
                            | pluralize(minimumRequiredPublicPhotos)
                    }}
                    {{ $t(" are required to proceed.") }}
                </div>
            </b-alert>
            <b-alert
                v-if="
                    hasApprovedPhotos &&
                    complete &&
                    !hasUploadedRecommendedAmount
                "
                variant="warning"
                show
                class="small"
            >
                {{ $t("Uploading at least 5 public photos is recommended") }}
            </b-alert>
            <div v-if="editPhoto">
                <onboarding-photo-edit />
            </div>
            <div v-else-if="hasAnyPhotos && !onboardingPhotoUploadMode">
                <onboarding-photo-index />
            </div>

            <div
                :class="{
                    'd-none': !onboardingPhotoUploadMode,
                }"
            >
                <onboarding-upload-photos />
            </div>

            <div class="my-3">
                <div v-if="showDoneButton">
                    <b-btn
                        @click="finishedWithPhotos"
                        variant="primary"
                        size="lg"
                        pill
                        class="px-5"
                    >
                        {{ $t("Done with Photos") }}
                    </b-btn>
                </div>
                <div v-else>
                    <b-btn
                        v-b-toggle.photoHelp
                        variant="light"
                        size="lg"
                        pill
                        class="px-5"
                    >
                        <font-awesome-icon
                            icon="fa-duotone fa-circle-question"
                        />
                        {{ $t("Photo Help") }}
                    </b-btn>

                    <b-collapse id="photoHelp">
                        <onboarding-photo-rules />
                    </b-collapse>

                    <div>
                        <b-btn
                            @click="openSpaUrl"
                            v-if="cordova"
                            variant="link"
                            class="mt-3 text-secondary"
                            size="sm"
                        >
                            {{
                                $t("Problems uploading? Complete signup on web")
                            }}
                        </b-btn>

                        <b-btn
                            v-b-toggle.anotherDevice
                            variant="link"
                            class="text-secondary mt-2"
                            size="sm"
                            pill
                        >
                            {{ $t("Upload from another device") }}
                        </b-btn>

                        <b-collapse id="anotherDevice">
                            <div class="mt-4 bg-white p-3 border rounded">
                                <div class="py-2 small">
                                    {{
                                        $t(
                                            "Need to upload from another device? No problem, email yourself a link to login and finish on the other device"
                                        )
                                    }}
                                </div>
                                <div v-if="sent" class="text-primary">
                                    <font-awesome-icon
                                        icon="fa-light fa-check"
                                    />
                                    {{
                                        $t(
                                            "We just emailed you a link to login and finish on another device, check your inbox"
                                        )
                                    }}
                                </div>
                                <div v-else>
                                    <b-btn
                                        @click="emailLink"
                                        variant="light"
                                        class="mt-2 px-4"
                                        pill
                                        size="sm"
                                    >
                                        {{ $t("Email me a link") }}
                                    </b-btn>
                                </div>
                            </div>
                        </b-collapse>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="allowedToSkipPhoto">
            <b-btn
                variant="light"
                size="lg"
                block
                class="btn-brand px-4"
                @click="skipPhoto"
                pill
            >
                {{ $t("Skip photo") }}
            </b-btn>
        </div>
    </div>
</template>


<script>
import { mapGetters, mapState } from "vuex";

import OnboardingUploadPhotos from "@/components/onboarding/photos/OnboardingUploadPhotos";
import OnboardingPhotoIndex from "@/components/onboarding/photos/OnboardingPhotoIndex";
import OnboardingPhotoEdit from "@/components/onboarding/photos/OnboardingPhotoEdit";
import OnboardingPhotoRules from "@/components/onboarding/photos/OnboardingPhotoRules";

export default {
    data() {
        return {
            acceptedRules: false,
            sent: false,
        };
    },
    computed: {
        hasUploadedRecommendedAmount() {
            return this.approvedPhotoCount >= 5;
        },
        approvedPhotoCount() {
            return this.approvedPhotos?.length;
        },
        minimumRequiredPublicPhotos() {
            return this.onboardingUser?.minimumRequiredPublicPhotos;
        },
        ...mapGetters("photo", [
            "hasAnyPhotos",
            "hasApprovedPhotos",
            "approvedPhotos",
        ]),
        ...mapState("system", ["cordova"]),
        ...mapGetters("interface", ["isSettingsMode"]),
        ...mapState("interface", ["onboardingPhotoUploadMode"]),
        ...mapState("photo", ["editPhoto"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        allowedToSkipPhoto() {
            if (this.hasApprovedPhotos) {
                return false;
            }

            return this.onboardingUser?.allowedToSkipPhoto;
        },
        showPhotoRules() {
            if (this.hasAnyPhotos) {
                return false;
            }

            if (this.acceptedRules) {
                return false;
            }

            return true;
        },
        showDoneButton() {
            if (this.isSettingsMode) {
                return false;
            }

            if (
                !this.onboardingPhotoUploadMode &&
                this.hasAnyPhotos &&
                this.complete
            ) {
                return true;
            }

            return false;
        },
        complete() {
            const photos = this.$store.state.onboarding?.user?.photos;

            if (!photos || !photos.length) {
                return false;
            }

            if (this.approvedPhotoCount < this.minimumRequiredPublicPhotos) {
                return false;
            }

            return this.hasApprovedPhotos;
        },
    },
    methods: {
        emailLink() {
            this.api.post("/setting/loginLink");
            this.sent = true;
        },
        openSpaUrl() {
            this.$store.dispatch(
                "system/openBrowser",
                this.onboardingUser.spaUrl
            );
        },
        skipPhoto() {
            if (
                confirm(
                    this.$t(
                        "Are you sure? Other users may not want to chat with you if you have no photo"
                    )
                )
            ) {
                this.api.post("/photo/skip").then(() => {
                    this.$store.dispatch("onboarding/status");
                });
            }
        },
        acceptRules() {
            this.acceptedRules = true;

            this.$store.commit(
                "interface/setOnboardingPhotoUploadMode",
                "public"
            );
        },
        finishedWithPhotos() {
            this.api.post("/photo/finishedUploading").then(() => {
                this.$store.dispatch("onboarding/status");
            });
        },
    },
    components: {
        OnboardingUploadPhotos,
        OnboardingPhotoIndex,
        OnboardingPhotoEdit,
        OnboardingPhotoRules,
    },
};
</script>