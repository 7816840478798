<template>
    <div>
        <b-dropdown
            variant="light"
            toggle-class="rounded-pill btn-sm"
            menu-class="pre-scrollable"
            lazy
            @show="refresh"
        >
            <template #button-content>
                <font-awesome-icon icon="fa-duotone fa-users" />
                {{ livestreamViewerUserIds.length }}
            </template>

            <b-dropdown-item
                block
                :key="user.id"
                v-for="user in users"
                link-class="p-0"
            >
                <user-mini-avatar
                    :standardMap="user"
                    :nonPrimaryPhotos="false"
                    :hideBadges="true"
                    :userBetweenStatus="false"
                    size="small"
                    class="border-light border-top"
                />
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
import { mapState } from "vuex";

import UserMiniAvatar from "@/components/user/UserMiniAvatar";

export default {
    data() {
        return {
            users: null,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapState("creatorLivestream", ["creatorLivestream"]),
        ...mapState("pusher", ["livestreamViewerUserIds"]),
    },
    methods: {
        refresh() {
            this.api
                .post(`/pusher/${this.creatorLivestream.id}/livestreamViewers`)
                .then((response) => {
                    this.users = response?.data?.users;
                });
        },
    },
    components: {
        UserMiniAvatar,
    },
};
</script>