<template>
    <div>
        <b-modal
            :title="$t('Wallet')"
            hide-footer
            v-model="modal"
            centered
            no-close-on-esc
            scrollable
            static
        >
            <div v-if="addMoney">
                <b-alert
                    v-if="errorCode"
                    variant="warning"
                    show
                    class="p-2 rounded-0 border-0"
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                        size="lg"
                    />
                    <span v-if="errorCode === 'paymentFailed'">
                        Failed to charge your card
                    </span>
                    <span v-if="errorCode === 'noPaymentMethod'">
                        Not enough credit
                    </span>
                    <span v-if="errorCode === 'notEnoughCredits'">
                        Not enough credit
                    </span>
                    <span v-if="errorCode === 'insufficientBalance'">
                        Not enough credit
                    </span>
                </b-alert>

                <div v-if="balance">
                    <b-btn @click="resetNavigation" variant="link">
                        <font-awesome-icon
                            size="lg"
                            icon="fa-light fa-chevron-left"
                        />
                        {{ $t("Back to Wallet") }}
                    </b-btn>
                </div>

                <div class="p-2">
                    <refill-credit />
                </div>
            </div>
            <div v-else>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <wallet-balance />
                    </div>

                    <div v-if="onboardingUser.allowPayments">
                        <b-btn
                            @click="
                                $store.commit('wallet/setAddingCredits', true)
                            "
                            variant="primary"
                            pill
                        >
                            {{ $t("Add Credits") }}
                        </b-btn>
                    </div>
                </div>
                <div class="mt-3">
                    <transaction-history v-if="modal" />
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";

import TransactionHistory from "@/components/wallet/TransactionHistory";
import RefillCredit from "@/components/wallet/RefillCredit";
import WalletBalance from "@/components/utility/WalletBalance";

export default {
    computed: {
        modal: {
            get() {
                return this.$store.state.interface.userWalletModalVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setUserWalletModalVisible",
                    value
                );
            },
        },
        addMoney() {
            if (this.addingCredits) {
                return true;
            }

            if (this.errorCode) {
                return true;
            }

            return false;
        },
        hasPaymentMethod() {
            if (this.errorCode === "paymentFailed") {
                return false;
            }

            if (this.onboardingUser.hasPaymentMethod) {
                return true;
            }

            return false;
        },
        ...mapState("wallet", ["errorCode", "addingCredits"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        balance() {
            return this.onboardingUser?.wallet?.balance;
        },
    },
    methods: {
        resetNavigation() {
            this.$store.commit("wallet/reset");
        },
    },
    components: {
        WalletBalance,
        RefillCredit,
        TransactionHistory,
    },
};
</script>