<template>
    <div v-if="showPrivatePhotos" class="mt-3">
        <div class="px-3">
            <h5 class="mb-0">
                {{ $t("Private Photos") }}
                <b-badge variant="dark" pill>
                    {{ profile.privatePhotos.length }}
                </b-badge>
            </h5>
        </div>
        <div class="my-2 px-3">
            <b-row no-gutters>
                <b-col
                    cols="3"
                    @click="fullSize"
                    :key="photo.id"
                    v-for="photo in profile.privatePhotos"
                    class="fake-link"
                >
                    <div
                        v-if="!canViewPrivate"
                        class="branded private-wrapper rounded position-absolute absolute-center text-center"
                    >
                        <font-awesome-icon
                            icon="fa-duotone fa-gem"
                            :size="privateIconSize"
                        />
                        <div class="small">{{ $t("Private") }}</div>
                    </div>
                    <div :class="{ 'blur-small': !canViewPrivate }">
                        <img class="img-fluid" :src="photo.search" />
                    </div>
                </b-col>
            </b-row>
        </div>

        <b-modal
            hide-header
            hide-footer
            v-model="modal"
            body-class="p-0"
            modal-class="modal-full-height"
            no-close-on-esc
        >
            <div>
                <div class="d-flex justify-content-between floating-menu-bar">
                    <div></div>
                    <b-btn
                        @click="
                            $store.commit(
                                'interface/setProfilePrivateGalleryVisible',
                                false
                            )
                        "
                        variant="light"
                        class="action-button glass border-0"
                    >
                        <font-awesome-icon size="lg" icon="fa-light fa-times" />
                    </b-btn>
                </div>
                <div class="swiper swiper-fullsize" ref="swiperPrivate">
                    <div v-if="canViewPrivate" class="swiper-wrapper">
                        <div
                            class="swiper-slide"
                            :key="photo.id"
                            v-for="photo in profile.privatePhotos"
                        >
                            <div class="swiper-zoom-container">
                                <img :src="photo.large" />
                            </div>
                        </div>
                    </div>
                    <div v-else class="swiper-wrapper">
                        <div
                            class="swiper-slide blur"
                            :key="photo.id"
                            v-for="photo in profile.privatePhotos"
                        >
                            <div @contextmenu.prevent="disableRightClick">
                                <img :src="photo.large" />
                                <div class="glass premium-required rounded p-3">
                                    <font-awesome-icon
                                        icon="fa-duotone fa-gem"
                                        class="branded"
                                        size="5x"
                                    />
                                    <h5 class="mt-3 mb-0">
                                        {{ $t("Unlock to View") }}
                                    </h5>
                                    <div class="p-3 small opacity-50">
                                        {{
                                            $t(
                                                "One-time payment to get access to private profile photos"
                                            )
                                        }}
                                    </div>
                                    <b-btn
                                        :disabled="purchasing"
                                        @click="purchasePhotos"
                                        variant="primary"
                                        pill
                                        size="lg"
                                        block
                                    >
                                        {{ $t("Purchase Photos") }}
                                        <coin-badge
                                            :amount="profile.privatePhotoPrice"
                                        />
                                    </b-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>
                    <div class="swiper-scrollbar"></div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Swiper, { Navigation, Pagination, Keyboard, Zoom } from "swiper";

import "swiper/swiper-bundle.css";
import { mapGetters, mapState } from "vuex";

Swiper.use([Navigation, Pagination, Keyboard, Zoom]);

export default {
    data() {
        return {
            swiperPrivate: null,
            purchasing: false,
        };
    },
    computed: {
        showPrivatePhotos() {
            return (
                !this.onboardingUser.censored &&
                !this.onboardingUser.nextGeneration &&
                this.profile.creator &&
                this.profile.privatePhotos &&
                this.profile.privatePhotos.length
            );
        },
        ...mapState("profile", ["profile"]),
        ...mapGetters("userSubscription", ["subscribedToCreatorUserId"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        canViewPrivate() {
            if (this.profile.canViewPrivate) {
                return true;
            }

            return false;
        },
        privateIconSize() {
            return "lg";
        },
        modal: {
            get() {
                return this.$store.state.interface.profilePrivateGalleryVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setProfilePrivateGalleryVisible",
                    value
                );
            },
        },
    },
    methods: {
        disableRightClick(e) {
            e.preventDefault();
        },
        purchasePhotos() {
            this.purchasing = true;

            this.api
                .post(`/profile/${this.profile.id}/purchasePrivatePhotos`)
                .then(() => {
                    this.$store.dispatch("profile/refresh");
                })
                .catch(() => {})
                .then(() => {
                    this.purchasing = false;
                });
        },
        fullSize() {
            this.$store.commit(
                "interface/setProfilePrivateGalleryVisible",
                true
            );

            this.$nextTick(() => {
                this.swiperPrivate = new Swiper(this.$refs.swiperPrivate, {
                    keyboard: true,
                    zoom: true,
                    pagination: {
                        el: ".swiper-pagination",
                    },
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    scrollbar: {
                        el: ".swiper-scrollbar",
                    },
                });
            });
        },
    },
};
</script>