<template>
    <div v-if="hasAnyTodos" class="mb-2">
        <div class="bg-light small pl-3 text-secondary mt-2">
            {{ $t("Complete Setup") }}
        </div>

        <b-alert
            v-if="remindMissingNotifications"
            variant="warning"
            show
            class="rounded-0 border-0 mb-0"
        >
            <div>
                <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
                {{
                    $t(
                        "Your notifications are off, you may miss messages from other users"
                    )
                }}
            </div>
            <div class="d-flex align-items-center justify-content-between mt-2">
                <b-btn
                    @click="
                        $store.dispatch('setting/editOnboardingStep', 'push')
                    "
                    variant="primary"
                    pill
                >
                    {{ $t("Enable Notifications") }}
                </b-btn>

                <b-btn
                    @click="dismissOnesignalReminder"
                    variant="link"
                    size="sm"
                    class="mr-2 text-secondary"
                >
                    <font-awesome-icon size="lg" icon="fa-light fa-times" />
                </b-btn>
            </div>
        </b-alert>
        <b-alert
            v-if="offerApp"
            variant="light"
            show
            class="rounded-0 border-0 mb-0 d-flex justify-content-between align-items-center"
        >
            {{ $t("Download the App") }}
            <b-btn
                @click="$store.dispatch('setting/editSetting', 'downloadApp')"
                variant="primary"
                pill
            >
                {{ $t("Get App") }}
            </b-btn>
        </b-alert>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    created() {
        this.$store.dispatch("mobileDetect/refresh");
    },
    computed: {
        hasAnyTodos() {
            return this.remindMissingNotifications || this.offerApp;
        },
        remindMissingNotifications() {
            return this.onboardingUser.remindMissingNotifications;
        },
        offerApp() {
            return this.isMobile && !this.onboardingUser.installedApp;
        },
        ...mapState("mobileDetect", ["isMobile"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        dismissOnesignalReminder() {
            this.api.post("/onesignal/ignoreReminder").then(() => {
                this.$store.dispatch("onboarding/status");
            });
        },
    },
};
</script>