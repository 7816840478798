<template>
    <div>
        <div class="bg-light">
            <div class="d-flex align-items-center justify-content-between p-2">
                <h5 class="mb-0">
                    {{ $t("Manage Photo") }}
                </h5>
                <div>
                    <b-btn variant="light" pill @click="managePhoto(null)">
                        <font-awesome-icon size="lg" icon="fa-light fa-times" />
                        {{ $t("Close") }}
                    </b-btn>
                </div>
            </div>
            <img class="img-fluid" :src="managingPhoto.squareUrl" />
            <div class="p-2 border">
                <div class="text-warning" show v-if="managingPhotoIsPrimary">
                    {{
                        $t(
                            "Primary photo cannot be deleted, set another photo to primary to delete this photo."
                        )
                    }}
                </div>
                <b-btn
                    v-if="!managingPhotoIsPrimary"
                    @click="setPrimary"
                    pill
                    variant="light"
                    class="btn-brand mr-2"
                    size="lg"
                >
                    {{ $t("Set Primary") }}
                </b-btn>
                <b-btn
                    v-if="!managingPhotoIsPrimary"
                    @click="deletePhoto"
                    pill
                    variant="light"
                    class="text-danger btn-brand"
                    size="lg"
                >
                    {{ $t("Delete") }}
                </b-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        managingPhotoIsPrimary() {
            return this.managingPhoto?.id === this.creatorPost?.primary?.id;
        },
        hasPhotos() {
            return this.creatorPost?.photos?.length;
        },
        ...mapState("creatorPostEdit", ["creatorPost", "managingPhoto"]),
    },
    methods: {
        setPrimary() {
            this.api
                .post("/post/setPhotoPrimary", {
                    creatorPostPhotoId: this.managingPhoto.id,
                })
                .then(() => {
                    this.$store.dispatch("creatorPostEdit/refresh");
                    this.$store.dispatch("creatorPostEdit/managePhoto", null);
                });
        },
        deletePhoto() {
            this.api
                .post("/post/deletePhoto", {
                    creatorPostPhotoId: this.managingPhoto.id,
                })
                .then(() => {
                    this.$store.dispatch("creatorPostEdit/refresh");
                    this.$store.dispatch("creatorPostEdit/managePhoto", null);
                });
        },
        managePhoto(photo) {
            this.$store.dispatch("creatorPostEdit/managePhoto", photo);
        },
    },
};
</script>