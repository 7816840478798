<template>
    <div>
        <div v-if="creatorGallery.purchased">
            <b-alert variant="success" show>
                <h5>
                    <font-awesome-icon
                        icon="fa-duotone fa-coin"
                        class="text-warning"
                    />
                    {{ $t("Purchased") }}
                </h5>
                {{
                    $t(
                        "This gallery has been purchased, so it can no longer be edited"
                    )
                }}
            </b-alert>
        </div>
        <div v-if="creatorGallery.publishedAt">
            <b-alert variant="primary" show>
                <h5>
                    <font-awesome-icon icon="fa-light fa-check-circle" />
                    {{ $t("Published") }}
                </h5>
                <div>
                    {{
                        $t(
                            "Your gallery is now published and available for sale, you can now share and invite your gallery"
                        )
                    }}
                </div>

                <div class="mt-2">
                    <div v-if="!confirmedUnpublish">
                        <b-btn
                            variant="link"
                            class="px-0 text-danger"
                            @click="confirmedUnpublish = true"
                        >
                            {{ $t("Unpublish") }}
                        </b-btn>
                    </div>
                </div>
            </b-alert>

            <b-alert v-if="confirmedUnpublish" variant="danger" show>
                <h5>
                    {{ $t("Are you sure?") }}
                </h5>
                {{
                    $t(
                        "You will need to submit your gallery for approval to get it published again"
                    )
                }}
                <div class="d-flex justify-content-between mt-3">
                    <b-btn
                        variant="primary"
                        pill
                        size="lg"
                        @click="confirmedUnpublish = false"
                    >
                        {{ $t("Cancel") }}
                    </b-btn>
                    <b-btn
                        variant="link"
                        class="text-danger px-0"
                        @click="unpublish"
                    >
                        {{ $t("Yes, Unpublish") }}
                    </b-btn>
                </div>
            </b-alert>
        </div>
        <div v-else-if="creatorGallery.submitted">
            <b-alert variant="warning" show>
                <h5>
                    {{ $t("Submitted for review") }}
                </h5>
                <div>
                    {{
                        $t(
                            "Allow up to 48 hours for review, we will email you when its been reviewed"
                        )
                    }}
                </div>

                <b-btn variant="link" class="pt-3 px-0" @click="unsubmit">
                    {{ $t("Make changes") }}
                </b-btn>
            </b-alert>
        </div>
        <div v-else>
            <b-btn
                :disabled="!creatorGallery.submittable"
                @click="submit"
                variant="primary"
                pill
                block
                size="lg"
                class="mb-3"
            >
                {{ $t("Submit for Review") }}
            </b-btn>

            <b-form-invalid-feedback v-if="error">
                {{ error }}
            </b-form-invalid-feedback>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            error: null,
            confirmedUnpublish: false,
        };
    },
    computed: {
        ...mapState("creatorGallery", ["creatorGallery"]),
    },
    methods: {
        unpublish() {
            this.api
                .post("/creatorGallery/unpublish", {
                    creatorGalleryId: this.creatorGallery.id,
                })
                .then((response) => {
                    if (response?.data?.creatorGallery) {
                        this.$store.commit(
                            "creatorGallery/setCreatorGallery",
                            response.data.creatorGallery
                        );
                    }
                });
        },
        unsubmit() {
            this.api
                .post("/creatorGallery/unsubmit", {
                    creatorGalleryId: this.creatorGallery.id,
                })
                .then((response) => {
                    if (response?.data?.creatorGallery) {
                        this.$store.commit(
                            "creatorGallery/setCreatorGallery",
                            response.data.creatorGallery
                        );
                    }
                });
        },
        submit() {
            this.error = null;

            this.api
                .post("/creatorGallery/submit", {
                    creatorGalleryId: this.creatorGallery.id,
                })
                .then((response) => {
                    if (response?.data?.creatorGallery) {
                        this.$store.commit(
                            "creatorGallery/setCreatorGallery",
                            response.data.creatorGallery
                        );
                    }
                })
                .catch((error) => {
                    if (error?.response?.data?.errors?.price?.length) {
                        this.error = error.response.data.errors.price.join(",");
                    }
                });
        },
    },
    components: {},
};
</script>