<template>
    <generic-page>
        <post-index-skeleton class="mt-2">
            <div
                :key="creatorPost.id"
                v-for="creatorPost in filteredPosts"
                class="mb-4"
            >
                <view-post
                    :id="creatorPost.id"
                    v-observe-visibility="visibilityChanged"
                    :creatorPost="creatorPost"
                />
            </div>
        </post-index-skeleton>

        <post-index-pagination />
    </generic-page>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import ViewPost from "@/components/post/view/ViewPost";
import PostIndexPagination from "@/components/post/index/PostIndexPagination";
import PostIndexSkeleton from "@/components/post/index/PostIndexSkeleton";

import Vue from "vue";
import { ObserveVisibility } from "vue-observe-visibility";

Vue.directive("observe-visibility", ObserveVisibility);

export default {
    created() {
        this.$store.dispatch("postIndex/refresh");
    },
    computed: {
        ...mapState("postIndex", ["creatorPosts", "view", "postIndexLoading"]),
        ...mapGetters("postIndex", ["filteredPosts"]),
    },
    methods: {
        visibilityChanged(isVisible, entry) {
            this.$store.dispatch("postIndex/visibilityChanged", {
                isVisible,
                entry,
            });
        },
    },
    components: {
        ViewPost,
        PostIndexPagination,
        PostIndexSkeleton,
    },
    props: ["userId"],
};
</script>