import api from "@/service/api";

const startPage = 1
const defaultView = 'new'

const state = () => ({
    blockedCreatorPostIds: [],
    galleryView: defaultView,
    creatorGalleries: null,
    page: startPage,
    hasMorePages: false,
    galleryIndexLoading: false,
})

const mutations = {
    reset(state) {
        state.page = startPage
        state.creatorGalleries = null
        state.view = defaultView
        state.hasMorePages = false
    },
    setView(state, value) {
        state.galleryView = value
    },
    setCreatorGalleries(state, value) {
        state.creatorGalleries = value
    },
    nextPage(state) {
        state.page++;
    },
    previousPage(state) {
        state.page--;
    },
    setHasMorePages(state, value) {
        state.hasMorePages = value
    },
    setGalleryIndexLoading(state, value) {
        state.galleryIndexLoading = value
    },
}

const actions = {
    setView({ commit, dispatch }, view) {
        commit('reset')
        commit('setView', view)
        dispatch('refresh')
    },
    refresh({ commit, state }) {
        commit('setGalleryIndexLoading', true)

        api.post("/creatorGallery/index", {
            page: state.page,
            view: state.galleryView
        })
            .then((response) => {
                if (response?.data?.creatorGalleries) {
                    commit('setCreatorGalleries', response.data.creatorGalleries)
                }

                commit('setHasMorePages', response.data.hasMorePages)
            })
            .catch(() => { })
            .then(() => {
                commit('setGalleryIndexLoading', false)
            });
    },
    nextPage({ commit, dispatch }) {
        commit('setCreatorGalleries', null)
        commit('nextPage')
        dispatch('refresh')
    },
    previousPage({ commit, dispatch }) {
        commit('setCreatorGalleries', null)
        commit('previousPage')
        dispatch('refresh')
    },
}



const getters = {
    filteredCreatorGalleries(state, getters, rootState) {
        if (!state.creatorGalleries) {
            return []
        }

        const filtered = state.creatorGalleries
            .filter(creatorGallery => !rootState.user.blockedUserIds.includes(creatorGallery.userId))

        return filtered
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
