<template>
    <div class="p-2">
        <h5>
            {{ $t("Invite for Access") }}
        </h5>
        <div>
            {{
                $t(
                    "To access the entire network of men, you must first invite 1 male customer who will buy credits. Once a guy you invites purchases credits, you will be able to access the network, and earn money here forever"
                )
            }}
        </div>
        <div class="mt-2">
            {{
                $t(
                    "Invite guys from USA, Europe, Australia, Japan, and more. You cannot invite someone you know, like a relative, or a close friend. It should be a real customer who will be interested in this platform, and interacting with you here"
                )
            }}
        </div>

        <h5 class="mt-3">
            {{ $t("Why is this required?") }}
        </h5>
        <div>
            {{
                $t(
                    "We have many more women joining our platform than men, to help balance the network, we now require women to bring 1 guy with them. There is no delay, after you invite a guy and they buy credits your account will instantly be activated, and you can start earning money"
                )
            }}
        </div>

        <h5 class="mt-3">
            {{ $t("Keeping things fair") }}
        </h5>
        <div>
            {{
                $t(
                    "All of the guys you see here were invited from other ladies, its not easy to get a paying customer to join the platform, and they did the hard work to help establish the network. If you want to get the benefit of accessing all of these customers, you will need to bring one yourself first to make it fair for the other ladies"
                )
            }}
        </div>

        <h5 class="mt-3">
            {{ $t("Earn Commissions from Invites") }}
        </h5>
        <div>
            {{
                $t(
                    "Any time a guy that you invited purchases credits you will earn a commission for the life of their account. Even if they buy credits a year from now, you will still earn a commission from any guys who came from your invites"
                )
            }}
        </div>

        <div class="mt-4">
            <b-btn
                @click="startInviting"
                variant="primary"
                block
                pill
                size="lg"
            >
                {{ $t("How do I invite them here?") }}
            </b-btn>
        </div>
        <div class="mt-2">
            <b-btn
                v-if="false"
                @click="alternativeOptions"
                variant="light"
                block
                pill
                size="lg"
            >
                {{ $t("Alternative Options") }}
            </b-btn>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        startInviting() {
            this.$store.dispatch("profile/close");
            this.$router.push({ name: "creatorInvite" });
        },
        alternativeOptions() {
            this.$store.dispatch("entryFee/setView", "cash");
        },
    },
    components: {},
};
</script>