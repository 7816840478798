<template>
    <generic-page>
        <div class="mb-2">
            <b-btn variant="link" size="lg" class="pl-0" to="/live">
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
                All Livestreams
            </b-btn>
        </div>

        <div v-if="ready">
            <livestream-dialog />
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import LivestreamDialog from "@/components/livestream/LivestreamDialog";

import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        ready() {
            if (this.isLoggedIn) {
                if (this.initialized) {
                    return true;
                }
            } else {
                return true;
            }

            return false;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("pusher", ["initialized"]),
        ...mapGetters("user", ["isLoggedIn"]),
    },
    components: {
        LivestreamDialog,
    },
};
</script>