<template>
    <div class="p-3 bg-white">
        <div class="d-flex align-items-center justify-content-between mb-3">
            <h5>
                {{ $t("Creating Withdrawal") }}
            </h5>
            <b-btn
                @click="cancelWithdrawal"
                variant="light"
                class="text-danger"
                pill
            >
                {{ $t("Cancel") }}
            </b-btn>
        </div>

        <div v-if="onboardingUser.unpaidFineCount">
            <withdraw-unpaid-fines />
        </div>
        <div v-else-if="withdraw.inviteRequired">
            <withdraw-invite-required />
        </div>
        <div v-else-if="withdraw.type">
            <withdraw-fulfillment />
        </div>
        <div v-else>
            <withdraw-payout />
        </div>

        <b-btn
            v-if="withdraw.userPayee"
            @click="submitWithdraw"
            variant="primary"
            class="mt-3"
        >
            {{ $t("Submit") }}
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

import WithdrawUnpaidFines from "@/components/withdraw/WithdrawUnpaidFines";
import WithdrawInviteRequired from "@/components/withdraw/WithdrawInviteRequired";
import WithdrawFulfillment from "@/components/withdraw/WithdrawFulfillment";
import WithdrawPayout from "@/components/withdraw/WithdrawPayout";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("withdraw", ["withdraw", "payouts"]),
    },
    methods: {
        updatePayout(payout) {
            this.$store.dispatch("withdraw/updatePayout", payout);
        },
        cancelWithdrawal() {
            if (
                confirm(
                    "Are you sure you want to cancel this withdrawal? You will need to start over."
                )
            ) {
                this.$store.dispatch("withdraw/cancel");
            }
        },
        submitWithdraw() {
            this.$store.dispatch("withdraw/submit");
        },
    },
    components: {
        WithdrawUnpaidFines,
        WithdrawInviteRequired,
        WithdrawFulfillment,
        WithdrawPayout,
    },
};
</script>