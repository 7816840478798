<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <h5 class="mb-0">Transaction History</h5>
            <b-btn variant="light" pill @click="refreshTransactions">
                <font-awesome-icon icon="fa-light fa-arrows-rotate" />
            </b-btn>
        </div>
        <b-table-simple striped class="mt-2">
            <thead>
                <th>Summary</th>
                <th class="text-center">Amount</th>
            </thead>
            <tbody>
                <tr :key="transaction.id" v-for="transaction in transactions">
                    <td>
                        <b-badge variant="light" pill>
                            {{ transaction.createdRelative }}
                        </b-badge>
                        {{ transaction.description }}
                    </td>
                    <td
                        :class="`text-${transaction.variant}`"
                        class="text-center"
                    >
                        {{ transaction.amount }}
                    </td>
                </tr>
            </tbody>
        </b-table-simple>

        <b-alert v-if="legacyWallet" variant="dark" show class="small">
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            Our wallet has been overhauled, older transactions can still be
            viewed using our legacy wallet viewer
            <b-btn
                variant="light"
                @click="viewLegacyWallet"
                block
                class="mt-3"
                size="sm"
                pill
            >
                View Older Transactions
            </b-btn>

            <div v-if="legacy">
                <legacy-transaction-index />
            </div>
        </b-alert>
    </div>
</template>

<script>
import { mapState } from "vuex";

import LegacyTransactionIndex from "@/components/wallet/LegacyTransactionIndex";

export default {
    data() {
        return {
            legacy: false,
        };
    },
    mounted() {
        this.refreshTransactions();
    },
    computed: {
        ...mapState("wallet", [
            "purchaseInProgress",
            "transactions",
            "legacyWallet",
        ]),
    },
    methods: {
        viewLegacyWallet() {
            this.legacy = true;
        },
        refreshTransactions() {
            this.$store.dispatch("wallet/refreshTransactionHistory");
            this.$store.dispatch("onboarding/status");
        },
    },
    components: {
        LegacyTransactionIndex,
    },
};
</script>