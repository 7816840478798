<template>
    <generic-page>
        <b-btn variant="link" size="lg" class="pl-0" @click="$router.back()">
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            Back
        </b-btn>

        <h5>Withdrawal Details</h5>

        <div v-if="withdraw">
            <b-table-simple>
                <tbody>
                    <tr>
                        <th>Status</th>
                        <td class="bg-white">
                            {{ withdraw.transferStatus }}
                        </td>
                    </tr>
                    <tr>
                        <th>Fulfillment Time</th>
                        <td class="bg-white">
                            {{ withdraw.fulfilledAtRelative }}
                        </td>
                    </tr>
                    <tr>
                        <th>Credits</th>
                        <td class="bg-white">
                            <coin-badge :amount="withdraw.amount" />
                        </td>
                    </tr>
                    <tr>
                        <th>Local</th>
                        <td class="bg-white">~{{ withdraw.local }}</td>
                    </tr>
                </tbody>
            </b-table-simple>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
export default {
    data() {
        return {
            withdraw: null,
        };
    },
    mounted() {
        const withdrawId = this.$route?.params?.id;

        this.api.post(`/withdraw/view/${withdrawId}`).then((response) => {
            this.withdraw = response.data.withdraw;
        });
    },
};
</script>