<template>
    <div>
        <account-section-title
            class="d-flex align-items-center justify-content-between"
        >
            Activity
            <wallet-balance />
        </account-section-title>

        <div v-if="!onboardingUser.creator">
            <b-list-group flush>
                <b-list-group-item
                    v-if="purchasedGalleryCount"
                    @click="viewPurchasedGalleries"
                    button
                    class="setting-item"
                >
                    <div class="label">Gallery</div>
                    <div class="value">
                        {{ purchasedGalleryCount }}
                    </div>
                </b-list-group-item>

                <b-list-group-item
                    v-if="creatorRequestCount"
                    to="/account/requests"
                    class="setting-item"
                >
                    <div class="label">Requests</div>
                    <div class="value">
                        <b-badge
                            variant="primary"
                            pill
                            v-if="unseenCreatorRequestCount"
                        >
                            {{ unseenCreatorRequestCount }}
                            new
                        </b-badge>
                        <span v-else>
                            {{ creatorRequestCount }}
                        </span>
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    v-if="callCount"
                    to="/account/call/index"
                    class="setting-item"
                >
                    <div class="label">Calls</div>
                    <div class="value">
                        {{ callCount }}
                    </div>
                </b-list-group-item>
            </b-list-group>
        </div>

        <div class="bg-white py-3">
            <div class="">
                <interest-toggle />
                <interest-modal />
            </div>
            <div class="mt-4">
                <visit-summary />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import AccountSectionTitle from "@/components/account/AccountSectionTitle";
import WalletBalance from "@/components/utility/WalletBalance";

import InterestToggle from "@/components/interest/InterestToggle";
import InterestModal from "@/components/interest/InterestModal";
import VisitSummary from "@/components/visit/VisitSummary";

export default {
    data() {
        return {
            creatorRequestCount: null,
            unseenCreatorRequestCount: null,
            purchasedGalleryCount: null,
            callCount: null,
        };
    },
    created() {
        this.refresh();
    },
    computed: {
        ...mapState("system", ["cordova"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        viewPurchasedGalleries() {
            this.$router.push({
                name: "galleryIndex",
            });
            this.$store.dispatch("galleryIndex/setView", "purchased");
        },
        refresh() {
            this.api.post("/account/dashboard").then((response) => {
                this.callCount = response?.data?.callCount;

                this.creatorRequestCount = response?.data?.creatorRequestCount;

                this.purchasedGalleryCount =
                    response?.data?.purchasedGalleryCount;

                this.unseenCreatorRequestCount =
                    response?.data?.unseenCreatorRequestCount;
            });
        },
    },
    components: {
        AccountSectionTitle,
        WalletBalance,
        InterestToggle,
        InterestModal,
        VisitSummary,
    },
};
</script>