<template>
    <div>
        <div class="mt-3">
            <div v-if="currentSlide">
                <div>
                    <h5 class="text-primary">
                        {{ currentSlide.title }}
                    </h5>
                    {{ currentSlide.data }}
                </div>

                <div class="d-flex align-items-center justify-content-between">
                    <b-btn
                        :class="{
                            invisible: !position,
                        }"
                        @click="prevSlide"
                        variant="light"
                        pill
                        size="lg"
                        class="mt-4"
                    >
                        <font-awesome-icon icon="fa-light fa-arrow-left" />
                        {{ $t("Back") }}
                    </b-btn>
                    <b-btn
                        @click="nextSlide"
                        variant="light"
                        pill
                        size="lg"
                        class="mt-4"
                    >
                        {{ $t("Next") }}
                        <font-awesome-icon icon="fa-light fa-arrow-right" />
                    </b-btn>
                </div>
            </div>
            <div v-else>
                <b-btn @click="skip" variant="primary" pill size="lg">
                    {{ $t("Done") }}
                </b-btn>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

import { rules } from "@/constant/rules";

export default {
    data() {
        return {
            position: 0,
            slides: rules,
        };
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        currentSlide() {
            if (this.position > this.slides.length) {
                return false;
            }

            return this.slides[this.position];
        },
    },
    methods: {
        prevSlide() {
            this.position--;
        },
        nextSlide() {
            this.position++;
        },
        skip() {
            this.api
                .post("/userFlag/onboardingFinishedHowitworks", {})
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                });
        },
    },
};
</script>
