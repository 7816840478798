<template>
    <div>
        <b-modal hide-header hide-footer centered scrollable v-model="modal">
            <div v-if="!hasLoadedStore" class="py-5 text-center">
                <b-spinner variant="secondary" />
                <div class="py-3">
                    {{ $t("Loading...") }}
                </div>
            </div>
            <div v-else>
                <div v-if="hasLoadedStore">
                    <div v-if="product.owned" class="text-center">
                        <premium-subscription-active />

                        <div class="mt-3">
                            <b-btn
                                variant="primary"
                                @click="closeModal"
                                block
                                size="lg"
                            >
                                Continue
                            </b-btn>
                        </div>
                    </div>
                    <div
                        v-else-if="subscriptionInProgress"
                        class="py-5 text-center"
                    >
                        <b-spinner variant="secondary" />
                        <div class="py-3">
                            {{ $t("Processing subscription...") }}
                        </div>
                    </div>
                    <div v-else>
                        <div
                            class="d-flex align-items-center justify-content-between"
                        >
                            <div>
                                <h5 class="mb-0">{{ product.title }}</h5>
                            </div>
                            <div>
                                <b-badge variant="primary" pill>
                                    <span>{{ product.price }}</span>
                                    /
                                    <span>{{ billingPeriod }}</span>
                                </b-badge>
                            </div>
                        </div>

                        <div class="mt-3">
                            {{ product.description }}
                        </div>

                        <div class="mt-3"></div>

                        <div class="mt-3 text-secondary small">
                            By tapping "Get Premium", your payment will be
                            charged to your {{ store }} account, and your
                            subscription will automatically renew for the same
                            package length at the same price until you cancel in
                            settings in the {{ store }} at least 24 hours prior
                            to the end of your subscription ending. By tapping
                            "Get Premium", you agree to our
                            <a class="fake-link" @click="privacy">
                                Privacy Policy
                            </a>
                            and
                            <a class="fake-link" @click="terms">
                                Terms and Conditions
                            </a>
                        </div>

                        <div class="mt-3">
                            <b-btn
                                :disabled="
                                    prendingToBeDoingSomethingWhileSubscribeDialogIsProbablyLoading
                                "
                                variant="success"
                                pill
                                size="lg"
                                block
                                @click="purchase"
                            >
                                <div
                                    v-if="
                                        prendingToBeDoingSomethingWhileSubscribeDialogIsProbablyLoading
                                    "
                                >
                                    <b-spinner small variant="light" />
                                </div>
                                <div v-else>
                                    {{ $t("Get Premium") }}
                                </div>
                            </b-btn>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
/* global store, device */

import { mapState } from "vuex";
import OpenBrowserMixin from "@/mixin/OpenBrowserMixin";
import PremiumSubscriptionActive from "@/components/premium/PremiumSubscriptionActive";

import {
    CORDOVA_START_SUBSCRIPTION,
    CORDOVA_CLOSE_SUBSCRIPTION,
} from "@/constant/subscriptionEvents";

export default {
    mixins: [OpenBrowserMixin],
    data() {
        return {
            prendingToBeDoingSomethingWhileSubscribeDialogIsProbablyLoading: false,
            eligible: false,
            store: null,
            modal: false,
            hasLoadedStore: false,
            subscriptionInProgress: false,
            initialized: false,
            product: null,
            subscriptionKeyId: null,
            billingPeriod: null,
        };
    },
    mounted() {
        this.$bus.$on(CORDOVA_START_SUBSCRIPTION, this.openModal);
        this.$bus.$on(CORDOVA_CLOSE_SUBSCRIPTION, this.closeModal);

        if (device.platform === "Android") {
            this.subscriptionKeyId = "findmate_monthly_access999";
            this.billingPeriod = "Month";
            this.store = "Google Play Store";
        } else {
            this.subscriptionKeyId = "findmate_monthly_access999";
            this.billingPeriod = "Month";
            this.store = "Apple App Store";
        }

        // get the new app in the store first
        // then submit this after
        // if (this.user.test) {
        //     this.subscriptionKeyId = "findmate_weekly_access299";
        //     this.billingPeriod = "Week";
        // }
    },
    beforeDestroy() {
        this.$bus.$off(CORDOVA_START_SUBSCRIPTION, this.openModal);
        this.$bus.$off(CORDOVA_CLOSE_SUBSCRIPTION, this.closeModal);
    },
    computed: {
        ...mapState("onboarding", ["user"]),
    },
    methods: {
        closeModal() {
            this.modal = false;
        },
        openModal() {
            this.modal = true;

            if (!this.hasLoadedStore) {
                this.initializeStore();
            }
        },
        async purchase() {
            // even though the store is loaded it can take several seconds to load the dialog to subscribe
            // and there is nothing to go on, so fake it till we make it
            this.prendingToBeDoingSomethingWhileSubscribeDialogIsProbablyLoading = true;

            setTimeout(() => {
                this.prendingToBeDoingSomethingWhileSubscribeDialogIsProbablyLoading = false;
            }, 5000);

            this.initializeStore();
            await new Promise((resolve) => setTimeout(resolve, 1000));

            if (!this.hasLoadedStore) {
                await new Promise((resolve) => setTimeout(resolve, 2000));
            }

            if (!this.hasLoadedStore) {
                await new Promise((resolve) => setTimeout(resolve, 3000));
            }

            if (!this.hasLoadedStore) {
                await new Promise((resolve) => setTimeout(resolve, 4000));
            }

            if (!this.hasLoadedStore) {
                await new Promise((resolve) => setTimeout(resolve, 5000));
            }

            if (!this.hasLoadedStore) {
                this.$store.dispatch(
                    "appEvent/create",
                    "store_attempted_purchase_before_loaded_timeout"
                );

                alert(
                    "We are having some trouble loading the store, please try again in a moment."
                );
                return;
            }

            if (!this.product.canPurchase) {
                this.$store.dispatch(
                    "appEvent/create",
                    "store_unable_to_purchase"
                );

                alert("Unable to purchase");

                return;
            }

            store.order(this.subscriptionKeyId);
        },
        initializeStore() {
            if (this.initialized) {
                return;
            }

            this.initialized = true;

            if (process.env.NODE_ENV == "development") {
                // clear any pending transactions for development only
                // store.autoFinishTransactions = true

                // turn on verbose logging
                store.verbosity = store.DEBUG;
            }

            store.register([
                {
                    id: this.subscriptionKeyId,
                    type: store.PAID_SUBSCRIPTION,
                },
            ]);

            // fovea validator
            store.validator = `https://validator.fovea.cc/v1/validate?appName=app.findmate.dating&apiKey=91aae970-0852-45fa-91ae-d52b79aea53d`;

            store.applicationUsername = this.$store.state.onboarding.user.id;

            // Show errors for 10 seconds.
            store.error((error) => {
                console.error(error);
            });

            store.when("product").approved((product) => {
                console.log("approved", product);
                product.verify();
            });

            store.when("product").verified((product) => {
                console.log("verified", product);
                product.finish();
                this.subscriptionInProgress = false;
            });

            store.when("product").unverified((product) => {
                console.log("unverified", product);
                this.subscriptionInProgress = false;
            });

            store.when("subscription").loaded((product) => {
                this.hasLoadedStore = true;
                this.product = product;

                console.log("loaded", product);
            });

            store.when("subscription").approved((product) => {
                console.log("approved", product);
                this.subscriptionInProgress = true;
            });

            store.when("subscription").verified((product) => {
                console.log("verified", product);
                this.subscriptionInProgress = false;
            });

            store.when("subscription").owned((product) => {
                this.$store.commit("user/enablePremium");

                this.product = product;

                console.log("owned", product);
            });

            store.when("subscription").expired((product) => {
                this.product = product;
                this.subscriptionInProgress = false;
                console.log("expired", product);
            });

            store.when("subscription").cancelled((product) => {
                console.log("cancelled", product);
            });

            store.refresh();
        },
        privacy() {
            this.openBrowser("https://findmate.app/content/privacy/");
        },
        terms() {
            this.openBrowser("https://findmate.app/content/terms/");
        },
    },
    components: {
        PremiumSubscriptionActive,
    },
};
</script>