<template>
    <generic-page>
        <b-btn variant="link" size="lg" class="pl-0" @click="$router.back()">
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            Back
        </b-btn>

        <h5 class="mb-0">Credit Value Calculator</h5>

        <div class="small">
            {{
                $t(
                    "How much are credits worth in your currency? Use this calculator to convert the value"
                )
            }}
        </div>

        <b-row class="text-center mt-2" no-gutters>
            <b-col cols="5">
                <div>
                    <b-input-group>
                        <b-form-input
                            v-model="inputCredits"
                            class="text-center"
                        />
                    </b-input-group>
                </div>
            </b-col>
            <b-col cols="2 lead"> = </b-col>
            <b-col cols="5">
                <b-form-input
                    :value="local"
                    readonly
                    class="text-center text-primary"
                />
            </b-col>
        </b-row>
        <b-row class="text-center small font-weight-bold" no-gutters>
            <b-col cols="5">
                <div>
                    {{ $t("Credits") }}
                </div>
            </b-col>
            <b-col cols="2"> </b-col>
            <b-col cols="5">
                <div>
                    {{ $t("My Currency") }}
                </div>
            </b-col>
        </b-row>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            inputCredits: 100,
            local: null,
            amount: null,
        };
    },
    created() {
        this.convertCredits();
    },
    watch: {
        inputCredits(value) {
            if (value) {
                this.convertCredits();
            }
        },
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        convertCredits() {
            this.local = null;
            this.amount = null;

            this.api
                .post("/credit/convert", {
                    userId: this.onboardingUser.id,
                    amount: this.inputCredits,
                })
                .then((response) => {
                    if (response?.data?.sendAmount?.local) {
                        this.local = response.data.sendAmount.local;
                        this.amount = response.data.sendAmount.amount;
                    }
                });
        },
    },
};
</script>