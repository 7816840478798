<template>
    <b-modal
        hide-footer
        v-model="modal"
        centered
        header-class="d-flex align-items-center justify-content-between"
        :no-close-on-esc="preventAccidentalClose"
        :hide-header-close="preventAccidentalClose"
        :no-close-on-backdrop="preventAccidentalClose"
        body-class="p-2"
    >
        <template #modal-header>
            <h5 class="mb-0 d-flex align-items-center">
                <b-spinner
                    v-if="creatorLivestream?.startedAt"
                    type="grow"
                    variant="danger"
                    small
                    class="mr-1"
                />
                Live
            </h5>

            <b-button
                v-if="false"
                size="sm"
                variant="light"
                pill
                @click="minimize"
            >
                Minimize
            </b-button>
        </template>

        <creator-livestream-dialog />
    </b-modal>
</template>

<script>
import { mapState } from "vuex";

import CreatorLivestreamDialog from "@/components/always/creatorLivestream/CreatorLivestreamDialog";

export default {
    computed: {
        preventAccidentalClose() {
            return this.creatorLivestream ? true : false;
        },
        modal: {
            get() {
                return (
                    this.$store.state.interface.creatorLivestreamModalVisible &&
                    !this.$store.state.interface.creatorLivestreamMinimized
                );
            },
            set(value) {
                this.$store.commit(
                    "interface/setCreatorLivestreamModalVisible",
                    value
                );
            },
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("creatorLivestream", ["creatorLivestream"]),
    },
    methods: {
        minimize() {
            this.$store.commit("interface/setCreatorLivestreamMinimized", true);
        },
    },
    components: {
        CreatorLivestreamDialog,
    },
};
</script>