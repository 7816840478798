<template>
    <div class="p-2">
        <h3 class="mt-">
            {{ $t("Pay for Entry") }}
        </h3>

        <div>
            {{
                $t(
                    "If you don't want to invite guys, you can pay an entry fee. This fee is non-refundable, please take a look around first, read all of the guides to ensure you understand the platform and definitely feel its for you"
                )
            }}
            <div class="mt-3">
                {{
                    $t(
                        "We recommend to start by inviting first, as that is free, and you can also earn money from your invites in the mean time, but for those who really don't want to invite, we make this option available"
                    )
                }}
            </div>
            <b-form-checkbox switch v-model="agree" class="text-warning mt-3">
                {{
                    $t(
                        "I understand that the entry fee is non-refundable, I have read the guides on the platform, and just want to pay the fee to get in"
                    )
                }}
            </b-form-checkbox>

            <div class="mt-3">
                <b-btn
                    @click="payEntryFee"
                    :disabled="!agree"
                    variant="primary"
                    size="lg"
                    pill
                    block
                >
                    {{ $t("View & Pay Entry Fee") }}
                </b-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            agree: false,
        };
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        payEntryFee() {},
    },
};
</script>