<template>
    <div
        class="position-relative"
        :class="{ 'post-highlight': creatorPost.wasJustCreated }"
    >
        <div class="border">
            <user-header :standardMap="creatorPost.standardMap" />
            <view-post-private-label :creatorPost="creatorPost" />
            <view-post-media :creatorPost="creatorPost" />
            <view-post-private :creatorPost="creatorPost" />
            <view-post-body :creatorPost="creatorPost" />
            <view-post-interact :creatorPost="creatorPost" />
        </div>
    </div>
</template>

<script>
import UserHeader from "@/components/userHeader/UserHeader";

import ViewPostInteract from "@/components/post/view/ViewPostInteract";
import ViewPostMedia from "@/components/post/view/ViewPostMedia";
import ViewPostPrivate from "@/components/post/view/ViewPostPrivate";
import ViewPostPrivateLabel from "@/components/post/view/ViewPostPrivateLabel";
import ViewPostBody from "@/components/post/view/ViewPostBody";

export default {
    components: {
        ViewPostInteract,
        ViewPostMedia,
        ViewPostPrivate,
        ViewPostPrivateLabel,
        ViewPostBody,
        UserHeader,
    },
    props: ["creatorPost"],
};
</script>