<template>
    <div>
        <transition-group name="list">
            <div
                :key="creatorLivestreamInteraction.id"
                v-for="creatorLivestreamInteraction in creatorLivestreamInteractions"
            >
                <creator-livestream-interaction-item
                    :creatorLivestreamInteraction="creatorLivestreamInteraction"
                />
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapState } from "vuex";

import CreatorLivestreamInteractionItem from "@/components/always/creatorLivestream/CreatorLivestreamInteractionItem";

export default {
    mounted() {
        this.$store.dispatch("creatorLivestream/refreshInteractions");
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("creatorLivestream", [
            "creatorLivestream",
            "creatorLivestreamInteractions",
            "standardMap",
            "agora",
        ]),
    },
    components: {
        CreatorLivestreamInteractionItem,
    },
};
</script>