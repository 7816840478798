import api from "@/service/api";
import router from "@/router";

const defaultVisibility = 'public';

const state = () => ({
    creatorGalleries: null,
    creatorGallery: null,
    editGallery: false,
    insufficientCredits: false,
    error: null,
    visibility: defaultVisibility,
})

const mutations = {
    reset(state) {
        state.error = null
        state.editGallery = false
        state.insufficientCredits = false
        state.visibility = defaultVisibility
    },
    setInsufficientCredits(state, value) {
        state.insufficientCredits = value;
    },
    setCreatorGalleries(state, value) {
        state.creatorGalleries = value;
    },
    setCreatorGallery(state, value) {
        state.creatorGallery = value;
    },
    setEditGallery(state, value) {
        state.editGallery = value;
    },
    setVisibility(state, value) {
        state.visibility = value;
    },
    setError(state, value) {
        state.error = value
    },
}

const actions = {
    refresh({ commit }) {
        api.post('/creatorGallery/mine').then((response) => {
            if (response?.data?.creatorGalleries) {
                commit('setCreatorGalleries', response.data.creatorGalleries)
            }
        });
    },
    create({ commit, dispatch }) {
        commit('reset');
        commit('setInsufficientCredits', false);

        api.post('/creatorGallery/create').then((response) => {
            if (response?.data?.creatorGallery) {
                dispatch('onboarding/status', null, { root: true })

                router.push({
                    name: 'creatorGalleryEdit',
                    params: {
                        id: response.data.creatorGallery.id,
                    },
                })
            }
        }).catch(error => {
            if (error?.response?.data?.code === 'insufficientCredits') {
                commit('setInsufficientCredits', true);
            }
        })
    },
    removeMedia({ commit, dispatch, state }, userMediaId) {
        commit('reset');

        api.post('/creatorGallery/removeUserMedia', {
            userMediaId,
            creatorGalleryId: state.creatorGallery.id,
        }).then(() => {
            dispatch('refreshGallery')
        });
    },
    refreshGallery({ dispatch, state }) {
        dispatch('get', state.creatorGallery.id);
    },
    get({ commit, dispatch }, creatorGalleryId) {
        commit('reset');

        api.post('/creatorGallery/get', {
            creatorGalleryId
        }).then((response) => {
            if (response?.data?.creatorGallery) {
                dispatch('setCreatorGallery', response.data.creatorGallery)
            }
        });
    },
    setCreatorGallery({ commit }, creatorGallery) {
        commit("reset");
        commit("interface/setEditCreatorGalleryModalVisible", true, { root: true });

        if (creatorGallery.name) {
            commit('setEditGallery', false)
        } else {
            commit('setEditGallery', true)
        }

        commit('setCreatorGallery', creatorGallery)
        commit('setVisibility', creatorGallery.visibility)
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
