<template>
    <div>
        <b-btn
            @click="sendCredits"
            variant="light"
            block
            class="rounded-0 mt-0"
            :size="$screen.lg ? 'lg' : 'sm'"
        >
            <div>
                <font-awesome-icon icon="fa-duotone fa-coin" size="lg" />
            </div>
            {{ $t("Send") }}
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("profile", ["profile"]),
    },
    methods: {
        sendCredits() {
            this.$store.dispatch("sendCredits/sendToUser", this.profile);
        },
    },
};
</script>