<template>
    <div>
        <b-overlay :show="refreshing" spinner>
            <div v-if="withdraw.wiseTransferId">
                <wise-review />
            </div>
            <div v-else-if="selectedType">
                <wise-account />
            </div>
            <div v-else>
                <wise-select-account />
            </div>
        </b-overlay>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import WiseSelectAccount from "@/components/withdraw/payout/wise/WiseSelectAccount";
import WiseAccount from "@/components/withdraw/payout/wise/WiseAccount";
import WiseReview from "@/components/withdraw/payout/wise/WiseReview";

export default {
    mounted() {
        this.$store.dispatch("wise/getAccount");
    },
    computed: {
        ...mapState("withdraw", [
            "withdraw",
            "lastUserPayee",
            "requirements",
            "refreshing",
        ]),
        ...mapState("wise", ["refreshing"]),
        ...mapGetters("wise", ["selectedType"]),
    },
    methods: {},
    components: {
        WiseSelectAccount,
        WiseAccount,
        WiseReview,
    },
};
</script>