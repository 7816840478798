import api from "@/service/api";

const state = () => ({
    unansweredQuestionCount: null,
    hasUnansweredQuestions: null,
    currentQuestion: null,
    questions: null,
})

const mutations = {
    setHasUnansweredQuestions(state, value) {
        state.hasUnansweredQuestions = value

        if (value) {
            state.unansweredQuestionCount = state.questions.filter(question => !question.hasAnswer).length
        } else {
            state.unansweredQuestionCount = 0
        }
    },
    setQuestions(state, questions) {
        state.questions = questions
    },
    setCurrentQuestion(state, question) {
        state.currentQuestion = question
    },
}

const actions = {
    refresh({ commit, dispatch }) {
        return api.post('/question/all').then(response => {
            if (response?.data?.questions) {
                const { questions } = response.data

                commit('setQuestions', questions)

                const unansweredQuestion = questions.find(question => !question.hasAnswer)

                if (unansweredQuestion) {
                    commit('setCurrentQuestion', unansweredQuestion)
                    commit('setHasUnansweredQuestions', true)
                } else {
                    commit('setCurrentQuestion', null)
                    commit('setHasUnansweredQuestions', false)
                    dispatch('onboarding/status', null, { root: true })
                }
            }
        })
    },
    answer({ dispatch }, { question, answer }) {
        api.post('/question/set', {
            questionId: question.questionId,
            answerId: answer.answerId,
        }).then(() => {
            dispatch('refresh')
        })
    },
    answerMultiple({ dispatch }, { question, answerIds }) {
        api.post('/question/setMultiple', {
            questionId: question.questionId,
            answerIds
        }).then(() => {
            dispatch('refresh')
        })
    },
    skip({ dispatch }) {
        api.post('/question/skip').then(() => {
            dispatch('onboarding/status', null, { root: true })
        })
    },
}

const getters = {
    currentQuestions(state) {
        if (!state.questions) {
            return []
        }

        return state.questions.filter(question => question.groupName === 'required')
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
