<template>
    <div>
        <b-btn
            @click="launchTemplates"
            pill
            variant="light"
            class="d-flex align-items-center bg-white shadow-sm shadow-blue mr-2"
            size="sm"
        >
            <span class="text-primary">
                <font-awesome-icon
                    class="mr-1"
                    icon="fa-duotone fa-brackets-curly"
                />
            </span>
            {{ $t("Template") }}
        </b-btn>

        <b-modal
            v-model="modal"
            hide-footer
            hide-header
            centered
            
            body-class="bg-light rounded-lg"
        >
            <conversation-template />
        </b-modal>
    </div>
</template>

<script>
import ConversationTemplate from "@/components/conversation/template/ConversationTemplate";

export default {
    computed: {
        modal: {
            get() {
                return this.$store.state.interface
                    .conversationTemplateModalVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setConversationTemplateModalVisible",
                    value
                );
            },
        },
    },
    methods: {
        launchTemplates() {
            this.$store.commit("conversationTemplate/reset");

            this.$store.commit(
                "interface/setConversationTemplateModalVisible",
                true
            );
        },
    },
    components: {
        ConversationTemplate,
    },
};
</script>