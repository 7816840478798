import api from "@/service/api";

const defaultView = 'profile'

const state = () => ({
    view: defaultView,
    profile: null,
    fullyLoadedProfile: false,
    profileError: false,
    profileUnavailable: false,
    loadedProfiles: [],
})

const mutations = {
    reset(state) {
        state.fullyLoadedProfile = false
        state.profile = null
        state.view = defaultView
    },
    setProfileUnavailable(state, value) {
        state.profileUnavailable = value
    },
    setProfileError(state, value) {
        state.profileError = value
    },
    setView(state, view) {
        state.view = view
    },
    setFullyLoadedProfile(state, value) {
        state.fullyLoadedProfile = value;
    },
    setProfile(state, profile) {
        state.profile = profile;
    },
    appendLoadedProfile(state, profile) {
        state.loadedProfiles = state.loadedProfiles.filter(loadedProfile => loadedProfile.id !== profile.id);
        state.loadedProfiles.push(profile);
    },
}

const actions = {
    refresh({ state, dispatch }) {
        dispatch('viewProfile', {
            userId: state.profile.id,
            profile: state.profile
        })
    },
    get({ dispatch }, userId) {
        dispatch('viewProfile', { userId })
    },
    close({ commit }) {
        commit('reset');
        commit("interface/setProfileModalVisible", false, { root: true });
    },
    viewProfile({ rootState, state, commit }, { userId, profile, view } = {}) {
        commit("interface/setProfileModalVisible", true, { root: true });

        commit('reset')
        commit('setProfileError', false)
        commit('setProfileUnavailable', false)

        if (view) {
            commit('setView', view)
        } else {
            if (profile && rootState.pusher?.liveUserIds?.includes(profile.id)) {
                commit('setView', 'profileLive')
            } else {
                commit('setView', 'profile')
            }
        }

        if (profile) {
            commit('setProfile', profile)
        } else {
            const alreadyLoadedProfile = state.loadedProfiles.find(loadedProfile => loadedProfile.id === userId);

            if (alreadyLoadedProfile) {
                commit('setProfile', alreadyLoadedProfile)
            }
        }

        api
            .post("/profile/load", {
                userId
            })
            .then((response) => {
                if (response?.data?.profile) {
                    commit('setFullyLoadedProfile', true)
                    commit('setProfile', response.data.profile)
                    commit('appendLoadedProfile', response.data.profile)
                }
            }).catch((error) => {
                // client out of sync with server
                if (error?.response?.status === 409) {
                    commit('setProfileUnavailable', true)
                } else {
                    commit('setProfileError', true)
                }

                commit("reset")
            })
            .then(() => {

            });
    },
}

const getters = {
    isMyProfile: (state, getters, rootState) => {
        if (rootState.onboarding.user?.id === state.profile?.id) {
            return true;
        }

        return false;
    },
    showUncensoredCreatorActions: (state, getters, rootState) => {
        return (
            !rootState.onboarding?.user?.censored &&
            !rootState.onboarding?.user?.creator &&
            !state.profile.self &&
            state.profile.creator
        );
    },
    canCallUser: (state, getters, rootState) => {
        return (
            !state.profile.self &&
            !rootState.onboarding?.user?.censored &&
            !(rootState.onboarding.user.creator && state.profile.creator)
        );
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
