<template>
    <div>
        <b-modal
            hide-header
            hide-footer
            v-model="modal"
            body-class="p-0"
            no-close-on-esc
            no-fade
            static
        >
            <div id="profile" class="bg-white">
                <div v-if="profile">
                    <div class="sticky-top">
                        <profile-header />
                        <profile-navigation />
                    </div>

                    <div v-if="view === 'profile'">
                        <profile-main />
                    </div>
                    <div v-if="view === 'profileLive'">
                        <profile-livestream />
                    </div>
                    <div v-if="view === 'conversation'">
                        <profile-conversation />
                    </div>
                    <div v-if="view === 'profilePosts'">
                        <profile-posts />
                    </div>
                    <div v-if="view === 'profileRequests'">
                        <profile-requests />
                    </div>
                    <div v-if="view === 'profileVerification'">
                        <profile-verification />
                    </div>
                    <div v-if="view === 'profileGalleries'">
                        <profile-galleries />
                    </div>
                    <div v-if="view === 'profileVideos'">Coming soon...</div>
                    <div v-if="view === 'profileQuestions'">
                        <profile-answers />
                    </div>
                </div>
                <div v-else-if="profileUnavailable">
                    <profile-unavailable />
                </div>
                <div v-else-if="profileError">
                    <profile-error />
                </div>
                <div v-else>
                    <div class="text-center p-5 text-secondary">
                        <div class="my-2">
                            <b-spinner variant="light" />
                        </div>
                        {{ $t("Loading Profile...") }}
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";

import ProfileHeader from "@/components/profile/ProfileHeader";
import ProfileNavigation from "@/components/profile/ProfileNavigation";
import ProfileUnavailable from "@/components/profile/ProfileUnavailable";
import ProfileError from "@/components/profile/ProfileError";
import ProfileConversation from "@/components/profile/ProfileConversation";

import ProfileMain from "@/components/profile/ProfileMain";
import ProfilePosts from "@/components/profile/ProfilePosts";
import ProfileRequests from "@/components/profile/ProfileRequests";
import ProfileGalleries from "@/components/profile/ProfileGalleries";
import ProfileVerification from "@/components/profile/ProfileVerification";
import ProfileAnswers from "@/components/profile/ProfileAnswers";
import ProfileLivestream from "@/components/profile/ProfileLivestream";

export default {
    computed: {
        modal: {
            get() {
                return this.$store.state.interface.profileModalVisible;
            },
            set(value) {
                if (!value) {
                    this.$store.commit("profile/reset");
                }

                this.$store.commit("interface/setProfileModalVisible", value);
            },
        },
        ...mapState("profile", [
            "profile",
            "profileError",
            "profileUnavailable",
            "view",
        ]),
    },
    components: {
        ProfileHeader,
        ProfileError,
        ProfilePosts,
        ProfileRequests,
        ProfileGalleries,
        ProfileNavigation,
        ProfileMain,
        ProfileAnswers,
        ProfileLivestream,
        ProfileUnavailable,
        ProfileVerification,
        ProfileConversation,
    },
};
</script>
