<template>
    <div>
        <div class="border p-3 bg-white text-left rounded">
            <h5>
                <font-awesome-icon
                    v-if="cardComplete"
                    icon="fa-light fa-check-circle"
                    class="text-success"
                />
                <font-awesome-icon v-else icon="fa-solid fa-circle" />

                Credit/Debit Card
            </h5>
            <div class="my-3">
                Upload a clear photo of your credit or debit card, clearly
                showing the card number and your name (feel free to obscure the
                security code, that is not needed for verification)
            </div>
            <onboarding-document-upload
                v-if="!cardComplete"
                @complete="cardComplete = true"
                type="card"
            >
                <font-awesome-icon icon="fa-duotone fa-cloud-arrow-up" />
                Upload
            </onboarding-document-upload>
        </div>
        <div class="border p-3 bg-white text-left rounded mt-3">
            <h5>
                <font-awesome-icon
                    v-if="idComplete"
                    icon="fa-light fa-check-circle"
                    class="text-success"
                />
                <font-awesome-icon v-else icon="fa-solid fa-circle" />
                Photo ID
            </h5>
            <div class="my-3">
                Upload a clear photo of your ID, with name that matches what is
                on the card
            </div>
            <onboarding-document-upload
                v-if="!idComplete"
                @complete="idComplete = true"
                type="id"
            >
                <font-awesome-icon icon="fa-duotone fa-cloud-arrow-up" />
                Upload
            </onboarding-document-upload>
        </div>

        <b-btn
            :disabled="!complete"
            @click="next"
            variant="primary"
            size="lg"
            block
            pill
            class="mt-3"
        >
            Continue
        </b-btn>
    </div>
</template>


<script>
import OnboardingDocumentUpload from "@/components/onboarding/document/OnboardingDocumentUpload";

export default {
    data() {
        return {
            idComplete: false,
            cardComplete: false,
        };
    },
    computed: {
        complete() {
            return this.idComplete && this.cardComplete;
        },
    },
    methods: {
        next() {
            this.$store.dispatch("onboarding/status");
        },
    },
    components: {
        OnboardingDocumentUpload,
    },
};
</script>