<template>
    <video
        :autoplay="autoplay"
        :muted="muted"
        :controls="controls"
        :loop="loop"
        playsinline
        class="img-fluid"
    >
        <source :src="webmUrl" type="video/webm" />
        <source :src="mp4Url" type="video/mp4" />
    </video>
</template>

<script>
export default {
    props: [
        "userVideo",
        "autoplay",
        "controls",
        "loop",
        "muted",
        "webmUrl",
        "mp4Url",
    ],
};
</script>