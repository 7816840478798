import api from "@/service/api";

const state = () => ({
    currentOffer: null,
})

const mutations = {
    setCurrentOffer(state, value) {
        state.currentOffer = value
    },
}

const actions = {
    getCurrent({ commit, state }) {
        // prevent duplicate checks from multiple onboarding/status calls
        if (state.currentOffer) {
            return
        }

        api.post('/offer/current').then((response) => {
            if (response?.data?.currentOffer) {
                commit('setCurrentOffer', response.data.currentOffer)
                commit("interface/setDisplayOfferModalVisible", true, { root: true });

                if (!response.data.currentOffer.sticky) {
                    api.post("/offer/viewedOffer", {
                        offer: response.data.currentOffer.offer,
                    });
                }
            }
        })
    }
}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}
