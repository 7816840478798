import router from "@/router";
import api from "@/service/api";
import translate from "@/service/translate";

const startPage = 1

const state = () => ({
    inactiveCount: null,
    hasMorePages: false,
    page: startPage,
    invitedUsers: null,
    currentViewKey: "active",
    error: null,
    viewOptions: [
        {
            key: "active",
            label: translate("Active"),
        },
        {
            key: "inactive",
            label: translate("Inactive"),
        },
        {
            key: "creator",
            label: translate("Creators"),
        },
    ],
})

const mutations = {
    reset(state) {
        state.error = null
        state.invitedUsers = null
    },
    setInvitedUsers(state, value) {
        state.invitedUsers = value;
    },
    setCurrentViewKey(state, value) {
        state.currentViewKey = value;
    },
    setError(state, value) {
        state.error = value
    },
    setHasMorePages(state, value) {
        state.hasMorePages = value
    },
    setInactiveCount(state, value) {
        state.inactiveCount = value
    },
    nextPage(state) {
        state.page++;
    },
    previousPage(state) {
        state.page--;
    },

}

const actions = {
    setView({ commit, dispatch }, viewKey) {
        commit('setCurrentViewKey', viewKey)
        dispatch('refresh')
    },
    refresh({ state, commit }) {
        api.post(`/invitedUser/${state.currentViewKey}`, {
            page: state.page,
        }).then((response) => {
            if (response?.data?.users) {
                commit('setInvitedUsers', response.data.users)
            }

            commit('setHasMorePages', response.data.hasMorePages)
        });
    },
    refreshInactive({ commit }) {
        api.post("/invitedUser/inactive").then((response) => {
            // if you check for this value, it can be zero erroneously returning false
            // TS would come in handy here
            commit('setInactiveCount', response.data.inactiveCount)
        });
    },
    sendMessage({ commit }, message) {
        api.post("/invitedUser/inactive", {
            message
        }).then((response) => {
            // if you check for this value, it can be zero erroneously returning false
            // TS would come in handy here
            commit('setInactiveCount', response.data.inactiveCount)

            router.push('/creator/invite/inactive')
        });
    },
    nextPage({ commit, dispatch }) {
        commit('reset')
        commit('nextPage')
        dispatch('refresh')
    },
    previousPage({ commit, dispatch }) {
        commit('reset')
        commit('previousPage')
        dispatch('refresh')
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
