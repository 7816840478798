<template>
    <div>
        <div v-if="currentCall">
            <call-dialog />
        </div>
        <div v-else-if="callPreview">
            <call-preview />
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

import CallDialog from "@/components/always/call/CallDialog";
import CallPreview from "@/components/always/call/CallPreview";

export default {
    computed: {
        ...mapState("call", ["currentCall", "callPreview"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        CallDialog,
        CallPreview,
    },
};
</script>

