<template>
    <generic-page>
        <div v-if="userCreditShare">
            <b-btn
                variant="link"
                size="lg"
                class="pl-0"
                @click="$router.back()"
            >
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
                {{ $t("Back") }}
            </b-btn>

            <div v-if="userCreditShare.consumed">
                <b-alert v-if="userCreditShare.consumed" variant="success" show>
                    <font-awesome-icon
                        class="text-success"
                        icon="fa-light fa-check"
                    />
                    {{
                        $t(
                            "Credits have been transferred, link has been consumed"
                        )
                    }}
                </b-alert>

                <user-standard-avatar
                    :standardMap="claimedByUser"
                    :nonPrimaryPhotos="false"
                    :hideBadges="true"
                    :userBetweenStatus="false"
                    class="border-light border-top"
                    size="small"
                >
                    <coin-badge :amount="userCreditShare.amount" />
                    {{ $t("Claimed credits") }}
                </user-standard-avatar>
            </div>
            <div v-else-if="userCreditShare.amount">
                <b-alert variant="primary" show>
                    <coin-badge :amount="userCreditShare.amount" />
                    {{ $t("credits deposited for this link") }}
                    <div class="small mt-3">
                        {{
                            $t(
                                "Link can only be used for logged in users, and should not be used to invite new users"
                            )
                        }}
                    </div>
                </b-alert>

                <b-input-group size="sm" class="mt-3">
                    <b-form-input :value="userCreditShare.url" readonly />
                    <b-input-group-append>
                        <b-btn @click="copyUrl" variant="primary">
                            {{ $t("Copy URL") }}
                        </b-btn>
                    </b-input-group-append>
                </b-input-group>

                <b-alert
                    v-if="copied"
                    variant="success"
                    show
                    class="small mt-2 mb-0"
                >
                    <h5>
                        <font-awesome-icon icon="fa-light fa-check" />
                        {{ $t("Copied") }}
                    </h5>
                    {{
                        $t(
                            "URL has been copied, now share with any logged in user, and the deposited credits will be transferred to their wallet"
                        )
                    }}
                </b-alert>
            </div>
            <div v-else>
                <b-alert variant="danger" show v-if="error">
                    {{ error }}
                </b-alert>

                {{ $t("Deposit Credits") }}

                <b-form-input
                    size="lg"
                    v-model="amount"
                    :placeholder="$t('Deposit Amount (10 - 1000)')"
                    inputmode="numeric"
                    maxlength="4"
                    min="10"
                    max="1000"
                    autocorrect="off"
                    autocapitalize="none"
                    autocomplete="off"
                ></b-form-input>

                <b-btn
                    :disabled="depositing"
                    @click="depositCredits"
                    variant="primary"
                    pill
                    size="lg"
                    class="mt-3"
                    block
                >
                    {{ $t("Deposit Credits") }}
                </b-btn>
            </div>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import copy from "clipboard-copy";
import UserStandardAvatar from "@/components/user/UserStandardAvatar";

export default {
    data() {
        return {
            claimedByUser: null,
            userCreditShare: null,
            amount: null,
            error: null,
            depositing: false,
            copied: false,
        };
    },
    created() {
        if (this.$route.params?.id) {
            this.refresh();
        }
    },
    watch: {
        $route(to) {
            if (to?.params?.id !== this.userCreditShare?.id) {
                this.refresh();
            }
        },
    },
    methods: {
        copyUrl() {
            copy(this.userCreditShare.url);
            this.copied = true;
        },
        refresh() {
            this.api
                .post(`/userCreditShare/${this.$route.params.id}/get`)
                .then((response) => {
                    if (response?.data?.userCreditShare) {
                        this.userCreditShare = response.data.userCreditShare;
                    }

                    if (response?.data?.claimedByUser) {
                        this.claimedByUser = response.data.claimedByUser;
                    }
                });
        },
        depositCredits() {
            this.depositing = true;

            this.api
                .post(`/userCreditShare/${this.$route.params.id}/deposit`, {
                    amount: this.amount,
                })
                .then((response) => {
                    if (response?.data?.userCreditShare) {
                        this.userCreditShare = response.data.userCreditShare;
                    }
                })
                .catch((error) => {
                    if (error?.response?.data?.errors?.amount?.length) {
                        this.error =
                            error.response.data.errors.amount.join(",");
                    }
                })
                .then(() => {
                    this.depositing = false;
                });
        },
    },
    components: {
        UserStandardAvatar,
    },
};
</script>