<template>
    <div>
        <div v-if="!$route.meta?.engagementMode">
            <div v-if="inactiveCount">
                <b-alert variant="primary" show class="small">
                    <h5>
                        {{ inactiveCount }}
                        {{ $t("Inactive Users") }}
                    </h5>
                    {{
                        $t(
                            "These users started signup, but did not complete the signup process"
                        )
                    }}
                    <div class="mt-2">
                        {{
                            $t(
                                "When you send send them a reminder, we will allow them to skip signup, and jump right back in"
                            )
                        }}
                    </div>
                </b-alert>
                <div
                    class="border-top p-3 d-flex align-items-center justify-content-between"
                >
                    <h5 class="mb-0">
                        {{ $t("Send a Message") }}
                        <font-awesome-icon icon="fa-light fa-arrow-right" />
                    </h5>
                    <div>
                        <b-btn
                            to="/creator/invite/inactive/message"
                            variant="primary"
                            pill
                        >
                            {{ $t("Write Message") }}
                        </b-btn>
                    </div>
                </div>
            </div>
            <div v-else>
                <b-alert variant="warning" show>
                    {{
                        $t(
                            "No inactive left to message, share your unique URL to start inviting guys to join"
                        )
                    }}
                </b-alert>
            </div>
        </div>

        <router-view></router-view>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("invitedUsers", ["inactiveCount"]),
    },
    mounted() {
        this.$store.dispatch("invitedUsers/refreshInactive");
    },
};
</script>