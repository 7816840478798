<template>
    <div>
        <b-dropdown variant="light" toggle-class="btn-brand rounded-pill">
            <template #button-content>
                {{ selectedOption.label }}
            </template>

            <b-dropdown-item
                :key="option.label"
                v-for="option in options"
                @click="setOption(option)"
            >
                {{ option.label }}
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            options: [
                {
                    days: 7,
                    label: this.$t("Last week"),
                },
                {
                    days: 30,
                    label: this.$t("Last 30 days"),
                },
                {
                    days: 90,
                    label: this.$t("Last 3 months"),
                },
                {
                    days: 365,
                    label: this.$t("Last year"),
                },
            ],
        };
    },
    methods: {
        setOption(option) {
            this.$store.commit(
                "creatorDashboard/setDateRangeDays",
                option.days
            );

            this.$store.dispatch("creatorDashboard/refresh");
        },
    },
    computed: {
        selectedOption() {
            return this.options.find(
                (option) => option.days === this.dateRangeDays
            );
        },
        ...mapState("creatorDashboard", ["dashboard", "dateRangeDays"]),
    },
};
</script>