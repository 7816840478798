<template>
    <div>
        <h5 class="mb-0">
            {{ $t("My Invite URL") }}
        </h5>

        <div class="small my-2">
            <b-form-checkbox v-model="showMyName" switch>
                {{ $t("Show My Name and Photo on Invite Page (recommended)") }}
            </b-form-checkbox>
        </div>

        <div v-if="url" class="mt-2">
            <b-input-group>
                <b-form-input :value="url" readonly />
                <b-input-group-append>
                    <b-btn v-if="copiedUrl" variant="success" class="border">
                        <font-awesome-icon
                            class="mr-1"
                            icon="fa-light fa-check"
                        />
                        {{ $t("Copied") }}
                    </b-btn>
                    <b-btn
                        v-else
                        @click="copyUrl"
                        variant="primary"
                        class="border"
                    >
                        <font-awesome-icon
                            class="mr-1"
                            icon="fa-duotone fa-copy"
                        />
                        {{ $t("Copy URL") }}
                    </b-btn>
                </b-input-group-append>
            </b-input-group>
        </div>

        <div class="small text-success">
            ({{ $t("Signups will be credited to your account") }})
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import copy from "clipboard-copy";

export default {
    data() {
        return {
            copiedUrl: false,
            showMyName: true,
        };
    },
    computed: {
        ...mapState("inviteFriends", ["validatedEmails", "url"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    watch: {
        showMyName(value) {
            this.$store.commit("inviteFriends/setRedirect", !value);
            this.$store.dispatch("inviteFriends/refreshUrl");
        },
    },
    methods: {
        copyUrl() {
            copy(this.url);

            this.copiedUrl = true;

            setTimeout(() => {
                this.copiedUrl = false;
            }, 2000);
        },
        addEmail(email) {
            this.$store.dispatch("inviteFriends/addEmail", email);
        },
    },
    components: {},
};
</script>