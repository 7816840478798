const state = () => ({
    blockSounds: null,
    lastSound: null,
})

const mutations = {
    setLastSound(state, value) {
        state.lastSound = value;
    },
    setBlockSounds(state, value) {
        state.blockSounds = value;
    },
}

const actions = {
    chime({ state, commit }, type) {
        if (state.blockSounds) {
            return
        }

        commit('setBlockSounds', true)

        // block any other sounds for 3 seconds to prevent audio spam
        setTimeout(() => {
            commit('setBlockSounds', false)
        }, 3000)

        commit('setLastSound', type)
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
