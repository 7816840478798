import api from "@/service/api";

const state = () => ({
    currentInterestView: null,
    outbound: null,
    inbound: null,
    match: null,
    outboundUserIds: [],
    localInterestedUserIds: [],
})

const mutations = {
    temporarilyUpdateLocalState(state, id) {
        state.outbound = state.outbound.map(interest => id !== interest.id ? interest : { ...interest, seen: true });
        state.inbound = state.inbound.map(interest => id !== interest.id ? interest : { ...interest, seen: true });
        state.match = state.match.map(interest => id !== interest.id ? interest : { ...interest, seen: true });
    },
    close(state) {
        state.currentInterestView = null;
    },
    setView(state, view) {
        state.currentInterestView = view;
    },
    setInterests(state, interests) {
        if (!interests) {
            return;
        }

        state.outbound = interests.outbound;
        state.inbound = interests.inbound;
        state.match = interests.match;
        state.outboundUserIds = interests.outboundUserIds;
    },
    appendTemporaryInterestUserId(state, userId) {
        state.outboundUserIds.push(userId)
        state.localInterestedUserIds.push(userId)
    },
    removeTemporaryInterestUserId(state, userId) {
        state.outboundUserIds = state.outboundUserIds.filter(id => id !== userId)
        state.localInterestedUserIds = state.localInterestedUserIds.filter(id => id !== userId)
    },
}

const actions = {
    async sendInterest({ commit, dispatch }, { userId }) {
        commit('appendTemporaryInterestUserId', userId)
        await api.post("/interest/send", { userId })
        dispatch('refresh')
    },
    async removeInterest({ commit, dispatch }, { userId }) {
        commit('removeTemporaryInterestUserId', userId)
        await api.post("/interest/remove", { userId })
        dispatch('refresh')
        dispatch("onboarding/status", null, { root: true });
    },
    refresh({ commit }) {
        api.post("/interest/get")
            .then((response) => {
                if (response?.data?.interests) {
                    commit('setInterests', response.data.interests)
                }
            });
    },
    markSeen({ commit, dispatch }, id) {
        commit('temporarilyUpdateLocalState', id)

        api.post("/interest/markSeen", { id }).then(() => {
            dispatch('refresh')
        });
    },
    markAllSeen({ dispatch }, type) {
        api.post("/interest/markAllSeen", { type }).then(() => {
            dispatch('refresh')
        });
    },
}

const getters = {
    interestedInUserId: (state, getters, rootState) => (userId) => {
        if (rootState.onboarding.user?.interestedUserIds?.includes(userId)) {
            return true;
        }

        if (state.outboundUserIds.includes(userId)) {
            return true;
        }

        return state.localInterestedUserIds.includes(userId);
    },
    filteredOutbound(state, getters, rootState) {
        return state.outbound ? state.outbound.filter(interest => !rootState.user.blockedUserIds.includes(interest.standardMap.id)) : [];
    },
    filteredInbound(state, getters, rootState) {
        return state.inbound ? state.inbound.filter(interest => !rootState.user.blockedUserIds.includes(interest.standardMap.id)) : [];
    },
    filteredMatch(state, getters, rootState) {
        return state.match ? state.match.filter(interest => !rootState.user.blockedUserIds.includes(interest.standardMap.id)) : [];
    },
    currentInterests(state, getters) {
        if (state.currentInterestView === "match") {
            return getters.filteredMatch;
        }

        if (state.currentInterestView === "inbound") {
            return getters.filteredInbound;
        }

        if (state.currentInterestView === "outbound") {
            return getters.filteredOutbound;
        }

        return [];
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
