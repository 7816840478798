<template>
    <generic-page>
        <b-btn variant="link" size="lg" class="pl-0" to="/creator/call">
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            {{ $t("All Calls") }}
        </b-btn>

        <div v-if="call">
            <div @click="viewProfile">
                <user-standard-avatar
                    :standardMap="standardMap"
                    :nonPrimaryPhotos="false"
                    :hideBadges="true"
                    :userBetweenStatus="false"
                    size="small"
                    class="border-light border-top"
                />
            </div>

            <creator-call-dispute @refresh="refresh" :call="call" />

            <b-alert
                v-if="!call.dispute && call.escrow"
                variant="primary"
                show
                class="mt-2 small"
            >
                <font-awesome-icon icon="fa-duotone fa-info-circle" />
                Credits in escrow will be transferred to you in
                <live-timestamp-future :timestamp="call.canBeDisputedUntil" />
                if the call remains undisputed

                <div class="mt-2">
                    <b-button
                        v-b-toggle.whyDelayed
                        variant="link"
                        class="text-secondary p-0"
                        size="sm"
                    >
                        Why is it delayed?
                    </b-button>
                    <b-collapse id="whyDelayed">
                        <div>
                            To prevent abuse, short calls less than
                            {{ config.creatorCallDisputeMinuteLimit }}
                            minutes can be disputed after the call finishes for
                            up to
                            {{ config.creatorCallReleaseEscrowMinutes }}
                            minutes by the user if they are unsatisfied.
                        </div>
                        <div class="mt-2">
                            Calls longer than
                            {{ config.creatorCallDisputeMinuteLimit }} minutes
                            will instantly transfer credits, there is no waiting
                            period and can never be disputed.
                        </div>
                    </b-collapse>
                </div>
            </b-alert>

            <h5 class="mt-3">Call Details</h5>
            <b-list-group>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    <div>Status</div>
                    <div class="small">
                        {{ call.status }}
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    v-if="call.totalEarnings"
                    class="d-flex align-items-center justify-content-between"
                >
                    <div>Earnings</div>
                    <coin-badge :amount="call.totalEarnings" />
                </b-list-group-item>
                <b-list-group-item
                    v-if="call.escrow"
                    class="d-flex align-items-center justify-content-between"
                >
                    <div>Credits in Escrow</div>
                    <font-awesome-icon
                        class="text-success"
                        icon="fa-light fa-check"
                    />
                </b-list-group-item>
                <b-list-group-item
                    v-if="call.duration"
                    class="d-flex align-items-center justify-content-between"
                >
                    <div>Duration</div>
                    <div class="small">
                        {{ call.duration }}
                    </div>
                </b-list-group-item>
            </b-list-group>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import UserStandardAvatar from "@/components/user/UserStandardAvatar";
import CreatorCallDispute from "@/components/creator/call/CreatorCallDispute";

export default {
    data() {
        return {
            call: false,
            standardMap: false,
        };
    },
    mounted() {
        this.refresh();
    },
    watch: {
        $route(to) {
            if (to?.params?.id !== this.call?.id) {
                this.refresh();
            }
        },
    },
    computed: {
        ...mapState("options", ["config"]),
    },
    methods: {
        viewProfile() {
            this.$store.dispatch("profile/viewProfile", {
                userId: this.standardMap.id,
                profile: this.standardMap,
            });
        },
        refresh() {
            const id = this.$route.params?.id;

            this.api.post(`/call/${id}/get`).then((response) => {
                this.call = response?.data?.call;
                this.standardMap = response?.data?.standardMap;
            });
        },
    },
    components: {
        UserStandardAvatar,
        CreatorCallDispute,
    },
};
</script>