<template>
    <b-btn
        v-if="creatorUser"
        to="/creator/bonus"
        class="bg-white"
        variant="light"
        block
        size="lg"
    >
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="mr-2">
                    <font-awesome-icon
                        class="small-avatar branded"
                        icon="fa-duotone fa-treasure-chest"
                        size="lg"
                    />
                </div>
                Bonus
            </div>
            <div class="small">{{ creatorUser.currentBonusProgress }}%</div>
        </div>
    </b-btn>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("creatorDashboard", ["creatorUser"]),
    },
};
</script>