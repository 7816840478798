<template>
    <div v-if="currentPost">
        <div
            class="swiper swiper-fullsize-with-thumbnails"
            ref="swiperStandard"
        >
            <div class="swiper-wrapper">
                <div
                    class="swiper-slide"
                    :key="photo.id"
                    v-for="photo in currentPost.photos"
                >
                    <media-photo
                        :creatorPost="currentPost"
                        :userPhoto="photo"
                        size="large"
                    />
                </div>
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
            <div class="swiper-scrollbar"></div>
        </div>
        <div class="swiper swiper-thumbnail bg-white" ref="swiperThumbnail">
            <div class="swiper-wrapper">
                <div
                    class="swiper-slide"
                    :key="photo.id"
                    v-for="photo in currentPost.photos"
                >
                    <media-photo
                        :creatorPost="currentPost"
                        :userPhoto="photo"
                        size="thumb"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import MediaPhoto from "@/components/media/MediaPhoto";

import Swiper, { Navigation, Pagination, Keyboard, Zoom, Thumbs } from "swiper";

import "swiper/swiper-bundle.css";

Swiper.use([Navigation, Pagination, Keyboard, Zoom, Thumbs]);

export default {
    data() {
        return {
            swiperLarge: null,
            swiperThumb: null,
            unsubscribeToAction: null,
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.initializeThumbSwiper();
            this.initializeLargeSwiper();
        });

        this.unsubscribeToAction = this.$store.subscribeAction({
            after: (action) => {
                if (action.type === "postPhoto/refresh") {
                    this.swiperLarge.update();
                    this.swiperThumb.update();
                }
            },
        });
    },
    beforeDestroy() {
        if (this.unsubscribeToAction) {
            this.unsubscribeToAction();
        }
    },
    computed: {
        ...mapGetters("postPhoto", [
            "currentPhotoIsMine",
            "currentPhotoIsPrimary",
        ]),
        ...mapState("postPhoto", [
            "currentPost",
            "currentPhoto",
            "currentSlidePosition",
        ]),
    },
    methods: {
        initializeThumbSwiper() {
            this.swiperThumb = new Swiper(this.$refs.swiperThumbnail, {
                freeMode: true,
                watchSlidesProgress: true,
                slidesPerView: 2,
                breakpoints: {
                    "@0.25": {
                        slidesPerView: 3,
                    },
                    "@0.50": {
                        slidesPerView: 5,
                    },
                    "@0.75": {
                        slidesPerView: 8,
                    },
                    "@1.00": {
                        slidesPerView: 12,
                    },
                    "@1.50": {
                        slidesPerView: 16,
                    },
                },
            });
        },
        initializeLargeSwiper() {
            const vueContext = this;

            const options = {
                keyboard: true,
                zoom: true,
                pagination: {
                    el: ".swiper-pagination",
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                scrollbar: {
                    el: ".swiper-scrollbar",
                },
                thumbs: {
                    swiper: this.swiperThumb,
                },
                on: {
                    afterInit() {
                        vueContext.slideChanged(this);
                    },
                    slideChange() {
                        vueContext.slideChanged(this);
                    },
                },
            };

            if (this.currentSlidePosition) {
                options.initialSlide = this.currentSlidePosition;
            }

            this.swiperLarge = new Swiper(this.$refs.swiperStandard, options);
        },
        slideChanged(swiper) {
            this.$store.dispatch(
                "postPhoto/setCurrentSlidePosition",
                swiper.activeIndex
            );
        },
    },
    components: {
        MediaPhoto,
    },
};
</script>
