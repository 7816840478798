<template>
    <div v-if="creatorPost.commentCount">
        <b-btn variant="light" class="btn-brand" pill @click="viewComments">
            <font-awesome-icon icon="fa-duotone fa-comment-dots" />

            <span
                v-if="creatorPost.commentCount"
                class="text-primary font-weight-bold"
            >
                {{ creatorPost.commentCount }}
                {{ $t("Comment") | pluralize(creatorPost.commentCount) }}
            </span>
        </b-btn>

        <b-modal
            v-model="modal"
            hide-footer
            :title="$t('React to Comments')"
            centered
            scrollable
            
            body-class="bg-light rounded-lg"
        >
            <div style="min-height: 400px" v-if="creatorPost.commentCount">
                <div class="mb-2">
                    <view-post-action-creator-reaction
                        :creatorPost="creatorPost"
                        mode="bulk"
                    />
                </div>
                <div
                    :key="comment.id"
                    v-for="comment in comments"
                    class="d-flex align-items-start border-top py-3"
                    :class="{
                        'border-primary border': commentHighlighted(comment),
                    }"
                >
                    <div @click="viewProfile(comment.standardMap)">
                        <img
                            class="rounded mr-2"
                            :src="comment.standardMap.photo"
                        />
                    </div>
                    <div>
                        <div>
                            {{ comment.standardMap.name }}
                            <span class="text-secondary mx-1">&middot;</span>
                            <span class="small text-secondary ml-1">
                                <live-timestamp :timestamp="comment.created" />
                            </span>
                        </div>
                        <div class="text-prominent">
                            {{ comment.comment }}
                        </div>
                    </div>

                    <view-post-action-creator-reaction
                        :creatorPost="creatorPost"
                        :comment="comment"
                        mode="single"
                        class="ml-auto"
                    />
                </div>
            </div>
            <div v-else>
                <h5 class="text-center mb-3">{{ $t("No comments yet") }}</h5>
            </div>
        </b-modal>
    </div>
</template>

<script>
import ViewPostActionCreatorReaction from "@/components/post/view/action/creator/ViewPostActionCreatorReaction";
import { mapState } from "vuex";

export default {
    methods: {
        commentHighlighted(comment) {
            return comment.id === parseInt(this.highlightComment);
        },
        viewProfile(standardMap) {
            this.$store.dispatch("profile/viewProfile", {
                userId: standardMap.id,
                profile: standardMap,
            });
        },
        viewComments() {
            this.$store.dispatch("publicPost/viewComments", this.creatorPost.id);
        },
    },
    computed: {
        ...mapState("creatorPost", ["creatorPost"]),
        modal: {
            get() {
                return this.$store.state.interface.postCommentsModalVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setPostCommentsModalVisible",
                    value
                );
            },
        },
        ...mapState("postComments", ["comments"]),
        ...mapState("publicPost", ["highlightComment"]),
    },
    components: {
        ViewPostActionCreatorReaction,
    },
};
</script>