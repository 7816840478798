<template>
    <div>
        <div v-if="creatorRequestDispute.settled" class="text-success">
            <font-awesome-icon
                icon="fa-light fa-check-circle"
                class="text-success"
            />
            Settled
        </div>
        <div v-else class="text-warning">
            <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
            Unsettled
        </div>
    </div>
</template>

<script>
export default {
    props: ["creatorRequestDispute"],
};
</script>