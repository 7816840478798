import AffiliatePage from '@/page/AffiliatePage'
import AffiliateLinksPage from '@/page/affiliate/AffiliateLinksPage'
import AffiliateHelpPage from '@/page/affiliate/AffiliateHelpPage'
import AffiliateWelcomePage from '@/page/affiliate/AffiliateWelcomePage'
import AffiliatePayoutPage from '@/page/affiliate/AffiliatePayoutPage'
import AffiliateQrcodePage from '@/page/affiliate/AffiliateQrcodePage'
import AffiliatePhotosPage from '@/page/affiliate/AffiliatePhotosPage'
import AffiliateVideosPage from '@/page/affiliate/AffiliateVideosPage'

import AffiliatePromoteIndex from '@/components/affiliate/promote/AffiliatePromoteIndex'
import AffiliateBonusIndex from '@/components/affiliate/bonus/AffiliateBonusIndex'

import AffiliateCustomerIndex from '@/components/affiliate/customer/AffiliateCustomerIndex'
import AffiliateCustomerView from '@/components/affiliate/customer/AffiliateCustomerView'

import AffiliateCreditShareIndex from '@/components/affiliate/userCreditShare/AffiliateCreditShareIndex'
import AffiliateCreditShareView from '@/components/affiliate/userCreditShare/AffiliateCreditShareView'

const routes = [
    {
        path: '/affiliate/customer',
        component: AffiliateCustomerIndex,
        name: 'affiliateCustomers',
        meta: { primaryNavigation: 'customer' },
    },
    {
        path: '/affiliate/customer/:id',
        component: AffiliateCustomerView,
        name: 'affiliateCustomerView',
        meta: { primaryNavigation: 'customer' },
    },
    {
        path: '/affiliate',
        component: AffiliatePage,
        name: 'affiliateDashboard',
        meta: { primaryNavigation: 'affiliate' },
    },
    {
        path: '/affiliate/links',
        component: AffiliateLinksPage,
        name: 'affiliateLinks',
        meta: { primaryNavigation: 'affiliate' },
    },
    {
        path: '/affiliate/promote',
        component: AffiliatePromoteIndex,
        name: 'affiliatePromote',
        meta: { primaryNavigation: 'promote' },
    },
    {
        path: '/affiliate/bonus',
        component: AffiliateBonusIndex,
        name: 'affiliateBonus',
        meta: { primaryNavigation: 'affiliate' },
    },
    {
        path: '/affiliate/userCreditShare',
        component: AffiliateCreditShareIndex,
        name: 'affiliateCreditShare',
        meta: { primaryNavigation: 'affiliate' },
    },
    {
        path: '/affiliate/userCreditShare/:id',
        component: AffiliateCreditShareView,
        name: 'affiliateCreditShareView',
        meta: { primaryNavigation: 'affiliate' },
    },
    {
        path: '/affiliate/help',
        component: AffiliateHelpPage,
        name: 'affiliateHelp',
        meta: { primaryNavigation: 'affiliate' },
    },
    {
        path: '/affiliate/welcome',
        component: AffiliateWelcomePage,
        name: 'affiliateWelcome',
        meta: { primaryNavigation: 'affiliate' },
    },
    {
        path: '/affiliate/payouts',
        component: AffiliatePayoutPage,
        name: 'affiliatePayout',
        meta: { primaryNavigation: 'affiliate' },
    },
    {
        path: '/affiliate/qrcode',
        component: AffiliateQrcodePage,
        name: 'affiliateQrcode',
        meta: { primaryNavigation: 'affiliate' },
    },
    {
        path: '/affiliate/photos',
        component: AffiliatePhotosPage,
        name: 'affiliatePhotos',
        meta: { primaryNavigation: 'affiliate' },
    },
    {
        path: '/affiliate/videos',
        component: AffiliateVideosPage,
        name: 'affiliateVideos',
        meta: { primaryNavigation: 'affiliate' },
    },
];

export default routes;
