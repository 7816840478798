<template>
    <div>
        <div v-if="typing.includes(message.userId)" class="text-success">
            {{ $t("Typing...") }}
        </div>
        <div
            v-else
            class="content text-truncate d-flex align-items-center"
            :class="{ 'text-opaque': !isUnreadFromOtherUser }"
        >
            <small
                v-if="isUnreadFromOtherUser"
                class="d-flex align-items-center mr-1"
            >
                <font-awesome-icon
                    icon="fa-solid fa-circle"
                    class="text-primary"
                />
            </small>

            <div v-if="message.type === 'text'">
                {{ message.content }}
            </div>

            <div v-if="message.type === 'sentCredit'">
                <font-awesome-icon
                    icon="fa-duotone fa-coin"
                    class="text-warning"
                />
                {{ $t("Sent money") }}
            </div>

            <div v-if="message.type === 'call'">
                <font-awesome-icon icon="fa-duotone fa-phone" />
                {{ message.content.status }}
            </div>

            <div
                v-if="message.type === 'photo'"
                class="d-flex align-items-center"
            >
                <font-awesome-icon
                    class="mr-1"
                    icon="fa-duotone fa-image-polaroid-user"
                />

                <div class="small">
                    {{ $t("Photo") }}
                </div>
            </div>

            <div
                v-if="message.type === 'video'"
                class="d-flex align-items-center"
            >
                <font-awesome-icon
                    class="mr-1"
                    icon="fa-duotone fa-camcorder"
                />

                <div class="small">
                    {{ $t("Video") }}
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        isUnreadFromOtherUser() {
            return !this.message.self && !this.message.seen;
        },
        canViewMessage() {
            if (this.premium) {
                return true;
            }

            if (this.message.unlocked) {
                return true;
            }

            return false;
        },
        ...mapState("message", ["typing", "hasMoreMessages", "view"]),
        ...mapState("user", ["premium"]),
    },
    props: ["message"],
};
</script>