<template>
    <div class="bg-white p-2 mt-2">
        <search-refresh />

        <div class="d-flex align-items-center justify-content-between">
            <div>
                <div v-if="showSearchReset">
                    <search-reset />
                </div>
                <div v-else>
                    <h5 class="mb-0">
                        {{ $t("Filters") }}
                    </h5>
                </div>
            </div>

            <div class="d-flex align-items-center justify-content-between">
                <div>
                    <b-dropdown
                        variant="light"
                        :text="$t(searchView)"
                        class="text-capitalize"
                        toggle-class=" text-capitalize rounded-pill mr-2"
                    >
                        <b-dropdown-header id="dropdown-header-label">
                            {{ $t("Show me people") }}
                        </b-dropdown-header>

                        <b-dropdown-item
                            @click="$store.dispatch('search/setView', view)"
                            :key="view"
                            v-for="view in views"
                            class="text-dark my-3"
                        >
                            <b-btn
                                variant="light"
                                pill
                                block
                                class="text-capitalize"
                            >
                                {{ view }}
                            </b-btn>
                        </b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown
                        variant="light"
                        class="text-capitalize"
                        toggle-class="text-capitalize rounded-pill mr-2"
                        menu-class="pre-scrollable"
                        v-if="canFilterCountry"
                        right
                        @show="refreshCountries"
                    >
                        <template #button-content>
                            <span v-if="searchCountry">
                                <img :src="searchCountry.flagUrl" />
                                {{ searchCountry.name }}
                            </span>
                            <span v-else>
                                {{ $t("Country") }}
                            </span>
                        </template>

                        <b-dropdown-header id="dropdown-header-label">
                            {{ $t("Show users in") }}
                        </b-dropdown-header>

                        <div v-if="countries" class="limit-width-dropdown-menu">
                            <b-dropdown-item
                                @click="
                                    $store.dispatch(
                                        'search/setCountryId',
                                        country.id
                                    )
                                "
                                :key="country.id"
                                v-for="country in countries"
                            >
                                <b-btn
                                    variant="light"
                                    pill
                                    block
                                    class="d-flex align-items-center justify-content-between"
                                >
                                    <div class="text-truncate">
                                        <img :src="country.flagUrl" />
                                        {{ country.name }}
                                    </div>
                                    <b-badge variant="light" pill>
                                        {{ country.count }}
                                    </b-badge>
                                </b-btn>
                            </b-dropdown-item>
                        </div>
                        <div v-else>
                            <generic-loading />
                        </div>
                    </b-dropdown>

                    <b-btn v-b-toggle.showFilters variant="light" class="" pill>
                        <font-awesome-icon icon="fa-solid fa-ellipsis" />
                    </b-btn>
                </div>
            </div>
        </div>

        <b-collapse id="showFilters">
            <div class="mt-3">
                <search-user-form />
            </div>
        </b-collapse>
    </div>
</template>

<style lang="scss" scoped>
.limit-width-dropdown-menu {
    width: 90vw;
    max-width: 250px;
}
</style>

<script>
import { mapGetters, mapState } from "vuex";

import SearchUserForm from "@/components/widget/search/SearchUserForm";
import SearchReset from "@/components/widget/search/SearchReset";
import SearchRefresh from "@/components/widget/search/SearchRefresh";

const defaultViews = ["online", "nearby", "new"];

export default {
    data() {
        return {
            countries: null,
            views: defaultViews,
        };
    },
    created() {
        if (!this.onboardingUser.creator) {
            this.views = [...this.views, "popular"];
        }
    },
    computed: {
        ...mapGetters("search", ["filteredUsers", "isDefaultSearchView"]),
        ...mapState("search", [
            "limitedResults",
            "searchLoading",
            "page",
            "hasScrolledToPagination",
            "searchCountryId",
            "searchView",
        ]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        showSearchReset() {
            return (
                this.limitedResults ||
                this.searchCountryId ||
                !this.isDefaultSearchView ||
                this.page > 0
            );
        },

        searchCountry() {
            return this.countries?.find(
                (country) => country.id === this.searchCountryId
            );
        },
        canFilterCountry() {
            if (!this.brand.enableCountryFilter) {
                return false;
            }

            return this.searchView !== "nearby";
        },
        ...mapState("brand", ["brand"]),
        ...mapState("search", ["searchView", "searchCountryId"]),
    },
    methods: {
        refreshCountries() {
            if (this.countries) {
                return;
            }

            this.api.post("/search/filters").then((response) => {
                if (response?.data?.countries) {
                    this.countries = response.data.countries;
                }
            });
        },
    },
    components: {
        SearchUserForm,
        SearchReset,
        SearchRefresh,
    },
};
</script>
