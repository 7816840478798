<template>
    <div :class="{ blurGalleryPreview: this.blur }">
        <div v-if="userMedia.type === 'video'">
            <video
                loop
                playsinline
                muted
                autoplay
                class="img-fluid rounded"
                :src="preview ? userMedia.previewUrl : userMedia.url"
                style="pointer-events: none"
            />
        </div>
        <div v-else>
            <img
                class="img-fluid rounded"
                :src="preview ? userMedia.previewUrl : userMedia.url"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.blurGalleryPreview {
    filter: blur(8px);
    overflow: hidden;
}
</style>


<script>
export default {
    props: ["userMedia", "size", "blur", "preview"],
};
</script>