<template>
    <div class="w-100">
        <b-alert variant="warning" show class="text-left">
            {{
                $t(
                    "It's your turn to invite, bring 1 guy to get access to invites from other ladies"
                )
            }}
            <div class="mt-3 small">
                {{
                    $t(
                        "Your trial period has expired, to make it fair for all ladies, we require at least one invite so everyone does their part to keep the app growing and bring new guys in"
                    )
                }}
            </div>

            <div class="mt-3 small">
                <div class="mt-3">
                    <b-button
                        v-b-toggle.more
                        variant="light"
                        class="border"
                        size="sm"
                        pill
                    >
                        {{ $t("Why is this required?") }}
                    </b-button>
                    <b-collapse id="more" class="my-3">
                        <h5>
                            {{ $t("Invites are shared for all ladies") }}
                        </h5>
                        <div>
                            {{
                                $t(
                                    "Other ladies did the work to invite the guys you've seen in the app, now its your turn. After you bring in 1 guy, you will get to access all of their invites forever. This makes it fair so everyone can share the guys invited"
                                )
                            }}
                        </div>
                        <h5 class="mt-3">
                            {{ $t("Fake invites not accepted") }}
                        </h5>
                        <div>
                            {{
                                $t(
                                    "You can't invite your brother or cousin, you need to bring in a guy who will be interested in the platform and pay for credits. When a guy you invite buys credit, you will be rewarded with a commission for the purchase, and you will instantly be able to get back into the app forever"
                                )
                            }}
                        </div>
                        <h5 class="mt-3">
                            {{ $t("How many times will I have to do this?") }}
                        </h5>
                        <div>
                            {{
                                $t(
                                    "Only once! After you have brought in 1 invite, you will get access to all of the invites from other ladies for life. This is only a one-time requirement to make sure that everyone does their part to make it fair"
                                )
                            }}
                        </div>
                        <h5 class="mt-3">
                            {{ $t("Where can I find a guy to invite?") }}
                        </h5>
                        <div>
                            {{
                                $t(
                                    "Read the guide below by clicking on the 'Invite guys' guide, we have many details and strategy about how to complete this task. If you follow it, you will be able to get back in easily, and also earn commissions for life on any purchases from the guys you invite"
                                )
                            }}
                        </div>
                    </b-collapse>
                </div>
            </div>
        </b-alert>

        <b-table-simple v-if="balance" bordered class="mt-2">
            <tbody>
                <tr>
                    <td class="w-66">
                        {{ $t("Credits") }}
                    </td>
                    <td class="bg-white">
                        {{ balance }}
                    </td>
                </tr>
                <tr>
                    <td>
                        {{ onboardingUser.currency }}
                        {{ $t("Balance") }}
                    </td>
                    <td class="bg-white">
                        <span class="text-success font-weight-bold">
                            {{ balanceLocal }}
                            {{ onboardingUser.currency }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </b-table-simple>

        <creator-invite-url />
    </div>
</template>



<script>
import { mapState } from "vuex";

import CreatorInviteUrl from "@/components/creator/invite/CreatorInviteUrl";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        balance() {
            return this.onboardingUser?.wallet?.balance;
        },
        balanceLocal() {
            return this.onboardingUser?.wallet?.balanceLocal;
        },
    },
    components: {
        CreatorInviteUrl,
    },
};
</script>
