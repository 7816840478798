<template>
    <div>
        <div>
            {{ $t("Please read and agree to our terms before proceeding") }}
        </div>

        <terms-conditions :targetButtonLabel="label" />

        <b-btn
            :disabled="accepted"
            @click="acceptTerms"
            variant="primary"
            size="lg"
            block
            class="mt-3"
        >
            {{ label }}
        </b-btn>
    </div>
</template>


<script>
import OpenBrowserMixin from "@/mixin/OpenBrowserMixin";
import TermsConditions from "@/components/content/TermsConditions";

export default {
    data() {
        return {
            accepted: false,
            label: this.$t("Accept & Continue"),
        };
    },
    mixins: [OpenBrowserMixin],
    methods: {
        acceptTerms() {
            this.accepted = true;

            this.api
                .post("/setting/acceptTerms")
                .then(() => {})
                .catch(() => {})
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                });
        },
    },
    components: {
        TermsConditions,
    },
};
</script>