import axios from "axios";
import store from '@/store'

const api = axios.create({
    method: 'post',
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        "x-brand": process.env.VUE_APP_BRAND,
        "x-target": process.env.VUE_APP_TARGET,
        "Content-Type": "application/json",
    },
});

api.interceptors.response.use(undefined, function (error) {
    if (error?.response?.status === 402) {
        store.dispatch('wallet/handleError', error, { root: true })
    }

    return Promise.reject(error);
})


export default api;