<template>
    <div class="gallery-grid">
        <div
            :key="photo.id"
            v-for="photo in photos"
            @click="$store.commit('photo/setEditPhoto', photo)"
            class="position-relative fake-link"
        >
            <onboarding-photo-item :photo="photo" />
        </div>
    </div>
</template>

<script>
import OnboardingPhotoItem from "@/components/onboarding/photos/OnboardingPhotoItem";

export default {
    components: {
        OnboardingPhotoItem,
    },
    props: ["photos"],
};
</script>