<template>
    <generic-page>
        <b-btn variant="link" size="lg" class="pl-0" to="/creator/post">
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            {{ $t("All Posts") }}
        </b-btn>

        <div v-if="creatorPost">
            <div class="bg-white">
                <div
                    class="p-2 d-flex align-items-center justify-content-between"
                >
                    <create-post-privacy />
                    <create-post-schedule />
                </div>

                <create-post-gif />

                <create-post-video />

                <create-post-primary-photo />

                <create-post-photos />

                <create-post-message />

                <create-post-controls />

                <create-post-actions />
            </div>

            <create-post-preview />
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import CreatePostSchedule from "@/components/post/create/CreatePostSchedule";
import CreatePostPrivacy from "@/components/post/create/CreatePostPrivacy";
import CreatePostMessage from "@/components/post/create/CreatePostMessage";
import CreatePostActions from "@/components/post/create/CreatePostActions";
import CreatePostControls from "@/components/post/create/CreatePostControls";
import CreatePostPreview from "@/components/post/create/CreatePostPreview";
import CreatePostPrimaryPhoto from "@/components/post/create/CreatePostPrimaryPhoto";
import CreatePostVideo from "@/components/post/create/CreatePostVideo";
import CreatePostGif from "@/components/post/create/CreatePostGif";
import CreatePostPhotos from "@/components/post/create/CreatePostPhotos";

export default {
    data() {
        return {
            blur: false,
        };
    },
    created() {
        if (this.$route.params?.id) {
            this.$store.dispatch("creatorPostEdit/edit", this.$route.params?.id);
        }
    },
    watch: {
        $route(to) {
            if (
                to?.params?.id !== this.$store.state.creatorPostEdit.creatorPost?.id
            ) {
                this.$store.dispatch(
                    "creatorPostEdit/edit",
                    this.$route.params?.id
                );
            }
        },
    },
    computed: {
        ...mapState("creatorPostEdit", ["creatorPost"]),
    },
    methods: {
        postHelp() {
            this.$store.commit("interface/setPostHelpModalVisible", true);
        },
    },
    components: {
        CreatePostMessage,
        CreatePostActions,
        CreatePostControls,
        CreatePostPreview,
        CreatePostPrimaryPhoto,
        CreatePostSchedule,
        CreatePostGif,
        CreatePostVideo,
        CreatePostPhotos,
        CreatePostPrivacy,
    },
};
</script>
