<template>
    <div>
        <div class="d-flex align-items-center justify-content-between my-2">
            <div>
                <h5 class="mb-0">
                    {{ $t("Write Message") }}
                </h5>
                <div class="small text-warning">
                    <font-awesome-icon icon="fa-duotone fa-envelope" />

                    {{ $t("Sending email to ") }}
                    {{ inactiveCount }}
                    {{ $t(" users") }}
                </div>
            </div>
            <b-btn
                to="/creator/invite/inactive"
                variant="light"
                class="text-danger"
            >
                <font-awesome-icon icon="fa-light fa-times" class="mr-1" />
                {{ $t("Cancel") }}
            </b-btn>
        </div>

        <b-textarea
            :placeholder="$t('Write your message here...')"
            v-model="message"
            type="text"
            rows="3"
            max-rows="12"
        />

        <b-alert variant="primary" show class="small mb-0 rounded-0 border-0">
            <font-awesome-icon icon="fa-duotone fa-calendar-xmark" />
            {{
                $t(
                    "Delivery will be scheduled for their morning time based on their timezone"
                )
            }}
        </b-alert>

        <div class="my-3">
            <b-btn @click="sendMessage" variant="primary" size="lg" pill block>
                {{ $t("Send Message") }}
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data: () => ({
        message: null,
    }),
    computed: {
        ...mapState("invitedUsers", ["inactiveCount"]),
    },
    methods: {
        sendMessage() {
            this.$store.dispatch("invitedUsers/sendMessage", this.message);
        },
    },
};
</script>