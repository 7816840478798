<template>
    <div>
        <giphy-viewer :gif="gif" />

        <div class="mt-2">
            <b-btn
                @click="selectGif(gif)"
                variant="primary"
                block
                pill
                size="lg"
            >
                {{ $t("Select GIF") }}
            </b-btn>
        </div>
    </div>
</template>



<script>
import GiphyViewer from "@/components/utility/GiphyViewer";

import { mapState } from "vuex";

export default {
    methods: {
        selectGif(gif) {
            this.$store.dispatch("creatorPostEdit/addGif", gif);
        },
    },
    computed: {
        ...mapState("creatorPostEdit", ["creatorPost"]),
    },
    components: {
        GiphyViewer,
    },
    props: ["gif"],
};
</script>