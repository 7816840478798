<template>
    <div>
        <div class="flex-grow-1">
            <div
                :key="video.videoDefaultMap.id"
                v-for="video in filteredVideos"
                class="user-wrapper border-top border-light"
            >
                <div class="p-3">
                    <video-user-avatar :standardMap="video.standardMap" />

                    <video-player
                        :muted="false"
                        :controls="true"
                        :autoplay="false"
                        :mp4Url="video.videoDefaultMap.mp4Url"
                        :webmUrl="video.videoDefaultMap.webmUrl"
                    />
                </div>
            </div>
        </div>

        <search-pagination v-if="false" />
        <video-scroll-top />
    </div>
</template>

<style lang="scss" scoped>
.fake-avatar {
    height: 150px;
    width: 150px;
    background-color: var(--secondary);
}
</style>

<script>
import { mapGetters, mapState } from "vuex";

import SearchPagination from "@/components/widget/search/SearchPagination";
import VideoScrollTop from "@/components/videoIndex/VideoScrollTop";
import VideoUserAvatar from "@/components/videoIndex/VideoUserAvatar";
import VideoPlayer from "@/components/utility/VideoPlayer";

export default {
    created() {
        this.$store.dispatch("videoIndex/loadInitial");
    },
    computed: {
        ...mapGetters("videoIndex", ["filteredVideos"]),
        ...mapState("videoIndex", ["limitedResults", "page"]),
        ...mapState("navigation", ["view"]),
        showSearchReset() {
            return (
                this.limitedResults ||
                !this.isDefaultSearchView ||
                this.page > 0
            );
        },
    },
    methods: {
        viewProfile(video) {
            this.$store.dispatch("profile/viewProfile", {
                userId: video.standardMap.id,
                profile: video.standardMap,
            });
        },
    },
    components: {
        SearchPagination,
        VideoScrollTop,
        VideoUserAvatar,
        VideoPlayer,
    },
};
</script>
        
        