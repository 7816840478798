<template>
    <div v-if="hasQueuedUploads">
        <div class="small mt-3 text-primary p-2 d-flex align-items-center">
            <b-spinner variant="light" small class="mr-2" />
            {{
                $t(
                    "Processing videos, this can take a few minutes depending on the length of the video..."
                )
            }}
        </div>
        <div class="my-3">
            <b-btn variant="primary" pill @click="publishPost">
                {{ $t("Yes, Post when Finished") }}
            </b-btn>
        </div>
        <b-row no-gutters>
            <b-col
                cols="3"
                :key="uploadUrl"
                v-for="uploadUrl in uploadInProgress"
                class="position-relative d-flex align-items-center justify-content-around bg-light py-3"
            >
                <b-spinner variant="dark" class="position-absolute z-index-1" />
                <font-awesome-icon
                    icon="fa-duotone fa-video"
                    class="text-primary"
                    size="3x"
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
.uploading {
    opacity: 0.5;
}
</style>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        hasQueuedUploads() {
            return this.uploadInProgress.length;
        },
        ...mapState("creatorPostEdit", ["creatorPost"]),
        ...mapState("createPostVideo", ["uploadInProgress"]),
    },
    methods: {
        publishPost() {
            this.$store.dispatch("creatorPostEdit/publishQuietly");
        },
    },
};
</script>