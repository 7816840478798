<template>
    <div>
        <universal-uploader
            @uploadSuccess="uploadSuccess"
            class="text-truncate"
            :clientAllowedFormats="['video']"
        >
            <b-btn pill variant="secondary" size="lg" block>
                <font-awesome-icon icon="fa-duotone fa-cloud-arrow-up" />
                Upload Video
            </b-btn>
        </universal-uploader>
    </div>
</template>

<script>
import { mapState } from "vuex";

import UniversalUploader from "@/components/universalUploader/UniversalUploader";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("onboarding", ["user"]),
        ...mapState("brand", ["brand"]),
        ...mapState("system", ["cordova"]),
    },
    methods: {
        uploadSuccess(url) {
            this.api
                .post("/video/uploadVerification", {
                    url,
                    code: this.code,
                })
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                });
        },
    },
    components: {
        UniversalUploader,
    },
    props: ["code"],
};
</script>