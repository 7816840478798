<template>
    <generic-page>
        <div class="mt-2">
            <affiliate-earnings-summary />
            <affiliate-payouts-summary />
            <affiliate-bonus-summary />

            <b-btn
                class="bg-white"
                variant="light"
                block
                size="lg"
                v-b-toggle.moreOptions
            >
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <div class="mr-2">
                            <font-awesome-icon
                                class="small-avatar text-primary"
                                icon="fa-solid fa-ellipsis"
                                size="lg"
                            />
                        </div>
                        <span class="text-secondary"> More options </span>
                    </div>
                </div>
            </b-btn>
            <b-collapse id="moreOptions" class="mt-2">
                <affiliate-links-summary />
                <affiliate-qrcode-summary />
                <affiliate-photos-summary />
                <affiliate-videos-summary />
                <affiliate-help-summary />
            </b-collapse>
        </div>
    </generic-page>
</template>

<script>
import AffiliateBonusSummary from "@/components/affiliate/summary/AffiliateBonusSummary";
import AffiliateEarningsSummary from "@/components/affiliate/summary/AffiliateEarningsSummary";
import AffiliateLinksSummary from "@/components/affiliate/summary/AffiliateLinksSummary";
import AffiliateQrcodeSummary from "@/components/affiliate/summary/AffiliateQrcodeSummary";
import AffiliatePhotosSummary from "@/components/affiliate/summary/AffiliatePhotosSummary";
import AffiliateVideosSummary from "@/components/affiliate/summary/AffiliateVideosSummary";
import AffiliateHelpSummary from "@/components/affiliate/summary/AffiliateHelpSummary";
import AffiliatePayoutsSummary from "@/components/affiliate/summary/AffiliatePayoutsSummary";

export default {
    created() {
        this.$store.dispatch("affiliate/refresh");
    },
    components: {
        AffiliateEarningsSummary,
        AffiliateBonusSummary,
        AffiliateLinksSummary,
        AffiliateQrcodeSummary,
        AffiliatePhotosSummary,
        AffiliateVideosSummary,
        AffiliateHelpSummary,
        AffiliatePayoutsSummary,
    },
};
</script>