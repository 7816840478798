import debounce from "@/utility/debounce";

const handle = () => {
    document.documentElement.style.setProperty(
        "--vh",
        `${document.documentElement.clientHeight * 0.01}px`
    );
};

window.addEventListener(
    "resize",
    debounce(() => {
        handle();
    }, 10)
);

handle();