<template>
    <div
        v-if="!scrolled"
        class="glass position-absolute scroll-label w-100 small text-secondary"
    >
        <div class="d-flex align-items-center justify-content-between">
            <div class="px-1">
                <font-awesome-icon
                    size="lg"
                    icon="fa-light fa-chevron-double-down"
                />
            </div>
            Scroll for More
            <div class="px-1">
                <font-awesome-icon
                    size="lg"
                    icon="fa-light fa-chevron-double-down"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.scroll-label {
    bottom: 0;
}
</style>


<script>
export default {
    props: ["scrolled"],
};
</script>