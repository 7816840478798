const state = () => ({
    recording: false,
    recordingUploading: false,
})

const mutations = {
    setRecording(state, value) {
        state.recording = value;
    },
    setRecordingUploading(state, value) {
        state.recordingUploading = value;
    },
}

const actions = {

}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
