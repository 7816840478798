<template>
    <div>
        <user-header :standardMap="creatorGallery.standardMap" />

        <gallery-details
            :creatorGallery="creatorGallery"
            :purchased="purchased"
        />

        <div class="fake-link border-top">
            <gallery-preview
                @clickedMedia="viewGallery(creatorGallery)"
                :creatorGallery="creatorGallery"
            />

            <b-btn
                @click="viewGallery(creatorGallery)"
                variant="light"
                class="btn-brand rounded-0 border-brand-heavy"
                block
                size="lg"
            >
                <font-awesome-icon icon="fa-duotone fa-eye" class="branded" />
                {{ $t("View Gallery") }}

                <gallery-contents-label :creatorGallery="creatorGallery" />
            </b-btn>
        </div>
    </div>
</template>


<script>
import UserHeader from "@/components/userHeader/UserHeader";
import GalleryPreview from "@/components/gallery/GalleryPreview";
import GalleryDetails from "@/components/gallery/GalleryDetails";
import GalleryContentsLabel from "@/components/gallery/GalleryContentsLabel";

export default {
    methods: {
        viewGallery(gallery) {
            this.$router.push({
                name: "galleryView",
                params: {
                    id: gallery.id,
                },
            });
        },
    },
    components: {
        UserHeader,
        GalleryContentsLabel,
        GalleryPreview,
        GalleryDetails,
    },
    props: ["creatorGallery", "purchased"],
};
</script>
        
        