<template>
    <div class="p-2 mt-2">
        <back-to-dashboard />

        <div v-if="withdraw">
            <withdraw-create />
        </div>
        <div v-else>
            <earnings-dashboard />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import EarningsDashboard from "@/components/earnings/EarningsDashboard";
import WithdrawCreate from "@/components/withdraw/WithdrawCreate";
import BackToDashboard from "@/components/utility/BackToDashboard";

export default {
    computed: {
        ...mapState("withdraw", ["withdraw"]),
    },
    components: {
        EarningsDashboard,
        WithdrawCreate,
        BackToDashboard,
    },
};
</script>