const state = () => ({
    contactedSupport: false
})

const mutations = {
    setContactedSupport(state, value) {
        state.contactedSupport = value
    },
}

const actions = {
    contact({ commit, dispatch }) {
        // useless stub function
        commit('setContactedSupport', true)

        dispatch("profile/viewProfile", {
            userId: 3,
            view: "conversation",
        }, { root: true });
    },
    accountManager({ dispatch }) {
        dispatch("profile/viewProfile", {
            userId: 3955409,
            view: "conversation"
        }, { root: true });
    },
    email({ commit, dispatch, rootState }) {
        // useless stub function
        commit('setContactedSupport', true)

        dispatch(
            "system/openBrowser",
            `mailto:support@findmatenetwork.com?subject=Need help with account (${rootState.onboarding.user.id})`,
            { root: true }
        );
    },
}

const getters = {

}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
