<template>
    <div class="p-3">
        <fitty :options="{ maxSize: 21 }">
            <template v-slot:content>
                {{ $t("Unlock up to 50% more users") }}
            </template>
        </fitty>
        <div class="mt-3">
            {{
                $t(
                    "Enabling the ignore my age feature will disable the age filter of other users allowing you to see all users regardless of their settings."
                )
            }}
        </div>
        <div class="mt-3">
            <div v-if="hasIgnoreMyAge">
                <b-btn
                    @click="disableFeature"
                    variant="light"
                    class="text-danger"
                    size="lg"
                    pill
                    block
                >
                    {{ $t("Disable Ignore My Age") }}
                </b-btn>
            </div>
            <div v-else>
                <b-btn
                    @click="enableFeature"
                    variant="primary"
                    size="lg"
                    pill
                    block
                >
                    {{ $t("Enable Ignore My Age") }}
                </b-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        hasIgnoreMyAge() {
            return this.onboardingUser && this.onboardingUser.ignoreMyAge;
        },
        ...mapState("user", ["premium"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        disableFeature() {
            this.api.post("/setting/disableIgnoreMyAge", {}).then(() => {
                this.$store.dispatch("onboarding/status");
            });
        },
        enableFeature() {
            if (this.premium) {
                this.api.post("/setting/enableIgnoreMyAge", {}).then(() => {
                    this.$store.dispatch("onboarding/status");
                });
            } else {
                this.$store.dispatch("premium/upgrade", {
                    source: "ignore-my-age",
                });
            }
        },
    },
};
</script>