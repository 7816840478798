<template>
    <generic-page>
        <b-btn variant="link" size="lg" class="pl-0" to="/creator/post">
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            {{ $t("All Posts") }}
        </b-btn>

        <div class="d-flex align-items-center justify-content-between mt-3">
            <h5 class="mb-0">
                {{ $t("Post Schedule") }}
            </h5>
        </div>

        <b-alert variant="primary" show v-if="showHelp">
            <b-btn
                @click="dismissHelp"
                variant="link"
                class="float-right pt-0 pr-0"
            >
                <font-awesome-icon size="lg" icon="fa-light fa-times" />
            </b-btn>
            <h5>
                {{ $t("Schedule posts up to 30 days in advance") }}
            </h5>
            <div class="mt-3">
                {{
                    $t(
                        "Click 'Create' to start a post for that date. We suggest creating both a public and private post each day to maximize your earning potential"
                    )
                }}
            </div>
        </b-alert>

        <div class="py-2">
            <b-table-simple v-if="days" striped class="mb-0">
                <thead>
                    <th>
                        {{ $t("Date") }}
                    </th>
                    <th class="text-center">
                        {{ $t("Public") }}
                    </th>
                    <th class="text-center">
                        {{ $t("Private") }}
                    </th>
                </thead>
                <tbody>
                    <tr :key="day.date" v-for="day in days">
                        <td class="align-middle">
                            {{ day.dateTerse }}
                        </td>
                        <td class="text-center">
                            <font-awesome-icon
                                v-if="day.hasPublic"
                                icon="fa-light fa-check"
                                class="text-primary"
                            />
                            <b-btn
                                v-else
                                @click="draftPublicPost(day)"
                                variant="primary"
                                size="sm"
                                pill
                            >
                                {{ $t("Create Post") }}
                            </b-btn>
                        </td>
                        <td class="text-center">
                            <font-awesome-icon
                                v-if="day.hasPrivate"
                                icon="fa-light fa-check"
                                class="text-primary"
                            />
                            <b-btn
                                v-else
                                @click="draftPrivatePost(day)"
                                variant="primary"
                                size="sm"
                                pill
                            >
                                {{ $t("Create Post") }}
                            </b-btn>
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>
            <div v-else>
                <generic-loading />
            </div>
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import { CREATOR_POST_SCHEDULE_HELP } from "@/constant/localStorage";

export default {
    data() {
        return {
            showHelp: false,
        };
    },
    created() {
        this.$store.dispatch("creatorPost/refreshSchedule");

        this.showHelp = !localStorage.getItem(CREATOR_POST_SCHEDULE_HELP);
    },
    computed: {
        ...mapState("creatorPost", ["days"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        dismissHelp() {
            this.showHelp = false;
            localStorage.setItem(CREATOR_POST_SCHEDULE_HELP, 1);
        },
        draftPrivatePost(day) {
            this.$store.dispatch("creatorPost/create", {
                publishedAt: day.timestamp,
                isPrivate: true,
            });
        },
        draftPublicPost(day) {
            this.$store.dispatch("creatorPost/create", {
                publishedAt: day.timestamp,
                isPrivate: false,
            });
        },
    },
    components: {},
};
</script>