<template>
    <div v-if="profile.answers && profile.answers.length" class="mt-3">
        <div class="px-3 py-2">
            <h5 class="mb-0">
                {{ $t("Questions") }}
            </h5>
        </div>
        <b-table-simple>
            <tbody>
                <tr :key="answer.answerId" v-for="answer in profile.answers">
                    <td class="bg-light pl-4">
                        {{ answer.label }}
                    </td>
                    <td class="text-center value">
                        {{ answer.value }}
                    </td>
                </tr>
            </tbody>
        </b-table-simple>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("profile", ["profile"]),
    },
};
</script>