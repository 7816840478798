<template>
    <div>
        <gift-guide />
        <div>
            <b-btn-group class="w-100">
                <b-btn
                    @click="sendGift(giftOption)"
                    class="reaction border-top rounded-0 btn-brand bg-white"
                    :key="giftOption.price"
                    v-for="giftOption in giftOptions"
                    variant="light"
                >
                    <img class="custom-icon-size" :src="giftOption.url" />
                    <div class="small reaction-price">
                        <div v-if="giftedItem(giftOption.id)">
                            <font-awesome-icon
                                icon="fa-light fa-check"
                                class="text-primary"
                            />
                        </div>
                        <div
                            v-else
                            class="d-flex align-items-center justify-content-center"
                        >
                            <font-awesome-icon
                                icon="fa-duotone fa-coin"
                                class="mr-1 text-warning"
                            />
                            <div class="text-secondary">
                                {{ giftOption.price }}
                            </div>
                        </div>
                    </div>
                </b-btn>
            </b-btn-group>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.reaction-price {
    margin-top: -5px;
}
</style>

<script>
import { FEED_TYPE_CREATOR_POST, FEED_TYPE_USER_UPDATE } from "@/constant/feed";
import { mapGetters, mapState } from "vuex";

import GiftGuide from "@/components/gift/GiftGuide";

export default {
    computed: {
        ...mapState("options", ["giftOptions"]),
        ...mapGetters("creatorGift", ["hasGiftedItem"]),
    },
    methods: {
        giftedItem(giftId) {
            if (this.sentGiftIds.includes(giftId)) {
                return true;
            }

            if (
                this.hasGiftedItem({
                    id: this.id,
                    type: this.type,
                    giftId,
                })
            ) {
                return true;
            }

            return false;
        },
        sendGift(giftOption) {
            // prevent sending client side only for now
            if (this.giftedItem(giftOption.id)) {
                return;
            }

            if (this.type === FEED_TYPE_CREATOR_POST) {
                this.$store.dispatch("creatorGift/fromPost", {
                    creatorPostId: this.id,
                    giftId: giftOption.id,
                });
            }

            if (this.type === FEED_TYPE_USER_UPDATE) {
                this.$store.dispatch("creatorGift/fromUpdate", {
                    userUpdateId: this.id,
                    giftId: giftOption.id,
                });
            }
        },
    },
    components: {
        GiftGuide,
    },
    props: ["type", "id", "sentGiftIds"],
};
</script>