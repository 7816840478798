const state = () => ({
    editPhoto: null,
    photosPendingProcessing: [],
})

const mutations = {
    setEditPhoto(state, photo) {
        state.editPhoto = photo
    },
    appendProcessingPhoto(state, photo) {
        state.photosPendingProcessing.push(photo)
    },
    removePendingPhoto(state, instanceId) {
        state.photosPendingProcessing = state.photosPendingProcessing.filter(photo => photo.instanceId !== instanceId)
    },
    appendPhotoError(state, { instanceId, message } = {}) {
        if (instanceId && message) {
            state.photosPendingProcessing = state.photosPendingProcessing.map(photo =>
                photo.instanceId === instanceId
                    ? { ...photo, error: message }
                    : photo)
        }
    },
    finishedUpload(state, instanceId) {
        state.photosPendingProcessing = state.photosPendingProcessing.map(photo =>
            photo.instanceId === instanceId
                ? { ...photo, uploading: false }
                : photo)
    },
}

const actions = {
    appendProcessingPhoto({ commit }, photo) {
        commit('appendProcessingPhoto', photo)
    },
}

const getters = {
    approvedPhotos(state, getters, rootState) {
        return rootState?.onboarding?.user?.photos?.filter((photo) => photo.approved === true);
    },
    hasApprovedPhotos(state, getters, rootState) {
        return rootState?.onboarding?.user?.photos?.filter((photo) => photo.approved === true).length > 0;
    },
    hasAnyPhotos(state, getters, rootState) {
        return rootState?.onboarding?.user?.photos?.length || state?.photosPendingProcessing?.length ? true : false
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
