<template>
    <div>
        <b-btn
            @click="createTemplate"
            variant="light"
            pill
            class="btn-brand shadow-sm"
        >
            {{ $t("Create Template") }}
        </b-btn>
    </div>
</template>

<script>
export default {
    methods: {
        createTemplate() {
            this.$store.dispatch("conversationTemplate/createTemplate");
        },
    },
};
</script>