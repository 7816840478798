<template>
    <div>
        <div v-if="alert">
            <b-alert
                variant="primary"
                v-if="label"
                class="d-flex align-items-center rounded-0 border-0 mb-0"
                show
            >
                <font-awesome-icon
                    v-if="icon"
                    :icon="icon"
                    class="mr-1"
                    :class="iconClass"
                />
                <div class="small">
                    {{ label }}
                </div>
            </b-alert>
        </div>
        <div v-else class="d-flex align-items-center mt-1">
            <font-awesome-icon
                v-if="icon"
                :icon="icon"
                class="mr-1"
                :class="iconClass"
            />
            <div class="small">
                {{ label }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        label() {
            // order sets the precedence
            // first is most important
            if (this.hasUnreadInboundMessage()) {
                return this.$t("Unread Message");
            }
            if (this.hasCalled()) {
                return this.$t("Called");
            }
            if (this.hasSentMessage()) {
                return this.$t("Sent Message");
            }
            if (this.hasMatchInterest()) {
                return this.$t("Following eachother");
            }
            if (this.hasInboundInterest()) {
                return this.$t("Following you");
            }
            if (this.hasOutboundInterest()) {
                return this.$t("You're following them");
            }

            return null;
        },
        iconClass() {
            return "text-primary";
        },
        icon() {
            if (this.hasUnreadInboundMessage()) {
                return "fa-duotone fa-comment-dots";
            }

            if (this.hasCalled()) {
                return "fa-duotone fa-phone";
            }

            if (this.hasSentMessage()) {
                return "fa-light fa-check";
            }

            if (this.hasMatchInterest()) {
                return "fa-duotone fa-check-double";
            }

            if (this.hasOutboundInterest()) {
                return "fa-light fa-check";
            }

            return null;
        },
        ...mapState("interest", ["outbound", "inbound", "match"]),
        ...mapGetters("message", ["filteredMessages", "hasMessagedUserId"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        hasUnreadInboundMessage() {
            return (
                this.filteredMessages &&
                this.filteredMessages.find(
                    (message) =>
                        !message.self &&
                        !message.seen &&
                        message.userId === this.user.id
                )
            );
        },
        hasSentMessage() {
            return this.hasMessagedUserId(this.user.id);
        },
        hasCalled() {
            return this.$store.state.onboarding.user?.calledUserIds?.includes(
                this.user.id
            );
        },
        hasMatchInterest() {
            return (
                this.match &&
                this.match.find(
                    (interest) => interest.standardMap.id === this.user.id
                )
            );
        },
        hasInboundInterest() {
            return (
                this.inbound &&
                this.inbound.find(
                    (interest) => interest.standardMap.id === this.user.id
                )
            );
        },
        hasOutboundInterest() {
            return (
                this.outbound &&
                this.outbound.find(
                    (interest) => interest.standardMap.id === this.user.id
                )
            );
        },
    },
    props: ["user", "alert"],
};
</script>