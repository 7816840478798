<template>
    <span v-if="standardMap.vetted">
        <font-awesome-icon
            class="text-primary"
            icon="fa-duotone fa-shield-check"
        />
    </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters("userSubscription", ["isMySubscriber"]),
    },
    props: ["standardMap"],
};
</script>