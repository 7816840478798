<template>
    <div v-if="displayGif" class="px-2 fake-link position-relative">
        <giphy-viewer :gif="creatorPost.gif.meta" />
    </div>
</template>

<script>
import { mapState } from "vuex";

import GiphyViewer from "@/components/utility/GiphyViewer";

export default {
    computed: {
        displayGif() {
            return this.creatorPost?.gif;
        },
        ...mapState("creatorPostEdit", ["creatorPost", "previewPost", "managingPhoto"]),
    },
    methods: {},
    components: {
        GiphyViewer,
    },
};
</script>