const defaultState = () => ({
    notifications: [],
})

const state = defaultState()

const mutations = {
    append(state, notification) {
        state.notifications.push(notification)
    },
    close(state, instanceId) {
        state.notifications = state.notifications.filter(notification => notification.instanceId !== instanceId)
    },
}

const actions = {
    append({ commit }, notification) {
        const instanceId = this._vm.$uuid();

        notification.instanceId = instanceId;

        commit('append', notification)

        setTimeout(() => {
            commit('close', instanceId)
        }, 3000)
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
