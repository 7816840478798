<template>
    <div>
        <invite-friends-form />
    </div>
</template>

<script>
import { mapState } from "vuex";

import InviteFriendsForm from "@/components/always/inviteFriends/InviteFriendsForm";

export default {
    computed: {
        ...mapState("inviteFriends", ["validatedEmails"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        completeVerification() {
            this.$store.dispatch("setting/editOnboardingStep", "verification");
        },
        addEmail(email) {
            this.$store.dispatch("inviteFriends/addEmail", email);
        },
    },
    components: {
        InviteFriendsForm,
    },
};
</script>