<template>
    <div>
        <post-photo />
        <user-wallet />
        <view-profile />
        <subscribe-creator />
        <manage-subscription />
        <creator-request />
        <display-offer />
        <send-credits />
        <call-modal />
        <invite-gallery />
        <invite-friends />
        <alert-modal />
        <creator-livestream-modal />
        <div v-if="!$screen.lg">
            <messages-modal />
        </div>
        <audio-alert />
    </div>
</template>

<script>
import SendCredits from "@/components/always/SendCredits";
import ViewProfile from "@/components/always/ViewProfile";
import CallModal from "@/components/always/CallModal";
import PostPhoto from "@/components/always/PostPhoto";
import UserWallet from "@/components/always/UserWallet";
import SubscribeCreator from "@/components/always/SubscribeCreator";
import ManageSubscription from "@/components/always/ManageSubscription";
import CreatorRequest from "@/components/always/CreatorRequest";
import DisplayOffer from "@/components/offer/DisplayOffer";
import InviteGallery from "@/components/always/creator/InviteGallery";
import InviteFriends from "@/components/always/InviteFriends";
import MessagesModal from "@/components/always/MessagesModal";
import AlertModal from "@/components/always/AlertModal";
import AudioAlert from "@/components/always/AudioAlert";
import CreatorLivestreamModal from "@/components/always/CreatorLivestreamModal";

export default {
    components: {
        AlertModal,
        PostPhoto,
        AudioAlert,
        UserWallet,
        SendCredits,
        DisplayOffer,
        CallModal,
        SubscribeCreator,
        ManageSubscription,
        CreatorRequest,
        ViewProfile,
        InviteGallery,
        InviteFriends,
        MessagesModal,
        CreatorLivestreamModal,
    },
};
</script>