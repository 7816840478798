<template>
    <div>
        <div v-if="loaded">
            <div v-if="cordova">
                <livestream-cordova />
            </div>
            <div v-else>
                <livestream-details
                    class="position-relative"
                    style="z-index: 2"
                />

                <div class="mt-2" v-if="creatorLivestream.streamInProgress">
                    <div v-if="onboardingUser.balance">
                        <div class="position-relative">
                            <livestream-agora />

                            <div
                                class="livestream-interaction-index hide-scrollbars h-100 p-1"
                            >
                                <livestream-interaction-index />
                            </div>
                        </div>

                        <div class="mt-2">
                            <livestream-interact />
                        </div>
                    </div>
                    <div v-else>
                        <livestream-refill />
                    </div>
                </div>
                <div v-else>
                    <livestream-ended />
                </div>
            </div>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.livestream-interaction-index {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column-reverse;
    z-index: 1;

    // viewer side has controls, so needs padding, just eyeballing it
    bottom: 50px;
}
</style>

<script>
import { mapGetters, mapState } from "vuex";

import LivestreamRefill from "@/components/livestream/LivestreamRefill";
import LivestreamCordova from "@/components/livestream/LivestreamCordova";
import LivestreamDetails from "@/components/livestream/LivestreamDetails";
import LivestreamInteract from "@/components/livestream/LivestreamInteract";
import LivestreamAgora from "@/components/livestream/LivestreamAgora";
import LivestreamEnded from "@/components/livestream/LivestreamEnded";
import LivestreamInteractionIndex from "@/components/livestream/interaction/LivestreamInteractionIndex";

export default {
    data() {
        return {
            // this is shite
            loaded: false,
        };
    },
    mounted() {
        // there was an issue here before
        // where the livestream.creatorLivestream was already set
        // so when it loaded this, it mounted all subcomponents
        // then refreshed, which caused it to load twice
        // the first time it loaded it had all kinds of errors
        // so just using this janky loaded flag
        // and moving on with life

        if (this.$route.params?.id) {
            this.$store.commit("livestream/reset");
            this.getLivestream();
        }
    },
    watch: {
        $route(to) {
            if (
                to?.params?.id !==
                this.$store.state.livestream.creatorLivestream?.id
            ) {
                this.$store.commit("livestream/reset");
                this.getLivestream();
            }
        },
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("system", ["cordova"]),
        ...mapGetters("user", ["isLoggedIn"]),
        ...mapState("livestream", ["creatorLivestream"]),
    },
    methods: {
        async getLivestream() {
            await this.$store.dispatch(
                "livestream/get",
                this.$route.params?.id
            );

            this.loaded = true;
        },
    },
    components: {
        LivestreamAgora,
        LivestreamDetails,
        LivestreamEnded,
        LivestreamCordova,
        LivestreamInteract,
        LivestreamInteractionIndex,
        LivestreamRefill,
    },
};
</script>