<template>
    <div>
        <b-alert variant="primary" show v-if="!creatorGallery.saved">
            <h5>
                {{ $t("Name") }}
            </h5>
            <div>
                {{
                    $t(
                        "Give your gallery an interesting and unique name to get viewers interesting in buying your gallery"
                    )
                }}
            </div>
            <h5 class="mt-3">
                {{ $t("Pricing") }}
            </h5>
            <div>
                {{ $t("Request a sale price up to 100 credits") }}
            </div>
            <div class="small mt-2">
                <font-awesome-icon icon="fa-duotone fa-info-circle" />
                {{ $t("We may adjust the price to be more competitive") }}
            </div>
        </b-alert>

        <div class="">
            {{ $t("Name") }}
        </div>
        <b-form-input
            @input="madeChanges"
            v-model="name"
            :placeholder="$t('Gallery Name')"
            maxlength="255"
            autocomplete="off"
        ></b-form-input>

        <b-row class="mt-2">
            <b-col cols="6">
                <div>
                    {{ $t("Price in Credits") }}
                </div>
                <b-form-input
                    @input="madeChanges"
                    v-model="price"
                    :placeholder="$t('1-100')"
                    type="number"
                    autocomplete="off"
                ></b-form-input>
            </b-col>
            <b-col cols="6">
                <div>
                    {{ $t("My Currency Value") }}
                </div>
                <b-form-input
                    :value="local"
                    readonly
                    class="text-center text-primary"
                />
            </b-col>
        </b-row>

        <b-alert variant="danger" show v-if="error">
            {{ error }}
        </b-alert>

        <b-btn
            v-if="changed"
            @click="save"
            variant="primary"
            pill
            block
            class="mt-2"
        >
            {{ $t("Save") }}
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            changed: false,
            name: null,
            price: null,
            error: null,
            local: null,
            amount: null,
        };
    },
    created() {
        this.name = this.creatorGallery.name;
        this.price = this.creatorGallery.price;

        if (!this.creatorGallery.saved) {
            this.changed = true;
        }

        this.convertCredits();
    },
    computed: {
        ...mapState("creatorGallery", ["creatorGallery"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    watch: {
        price(value) {
            if (value) {
                this.convertCredits();
            }
        },
    },
    methods: {
        madeChanges() {
            this.changed = true;
        },
        mediaUploaded(ano) {
            console.log(ano);
        },
        save() {
            this.error = null;

            this.api
                .post("/creatorGallery/save", {
                    creatorGalleryId: this.creatorGallery.id,
                    name: this.name,
                    price: this.price,
                })
                .then((response) => {
                    this.changed = false;

                    if (response?.data?.creatorGallery) {
                        this.$store.commit(
                            "creatorGallery/setCreatorGallery",
                            response.data.creatorGallery
                        );
                    }
                })
                .catch((error) => {
                    if (error?.response?.data?.errors?.price?.length) {
                        this.error = error.response.data.errors.price.join(",");
                    }

                    if (error?.response?.data?.errors?.name?.length) {
                        this.error = error.response.data.errors.name.join(",");
                    }
                });
        },
        convertCredits() {
            this.local = null;
            this.amount = null;

            this.api
                .post("/credit/convert", {
                    userId: this.onboardingUser.id,
                    amount: this.price,
                })
                .then((response) => {
                    if (response?.data?.sendAmount?.local) {
                        this.local = response.data.sendAmount.local;
                        this.amount = response.data.sendAmount.amount;
                    }
                });
        },
    },
    components: {},
};
</script>