<template>
    <generic-page>
        <back-to-dashboard />

        <div class="d-flex align-items-center justify-content-between">
            <h5 class="mb-0">
                {{ $t("Fines") }}
            </h5>

            <wallet-balance />
        </div>

        <b-alert v-if="error" show variant="danger">
            {{ error }}
        </b-alert>

        <b-list-group class="mt-2">
            <b-list-group-item v-for="userFine in userFines" :key="userFine.id">
                <h5 class="d-flex align-items-center justify-content-between">
                    <span class="text-danger">
                        {{ userFine.reasonObject.title }}
                    </span>
                    <coin-badge :amount="userFine.amount" />
                </h5>

                <div>
                    {{ userFine.reasonObject.description }}
                </div>

                <div
                    class="d-flex align-items-center justify-content-between mt-2"
                >
                    <div class="small">
                        {{ userFine.createdRelative }}
                    </div>

                    <b-btn
                        :disabled="payingFineId === userFine.id"
                        variant="primary"
                        pill
                        @click="payFine(userFine)"
                    >
                        {{ $t("Pay Fine") }}
                    </b-btn>
                </div>
            </b-list-group-item>
        </b-list-group>

        <b-alert variant="warning" show class="mt-3">
            <h5>
                <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
                {{ $t("Don't get banned") }}
            </h5>
            {{
                $t(
                    "We levy fines to correct behavior that leads to negative experiences on our platform. Please read and carefully follow the rules, repeated rule violations can result in an account ban. Once banned, you will not be able to return to the platform or create a new account"
                )
            }}
        </b-alert>
    </generic-page>
</template>

<script>
import BackToDashboard from "@/components/utility/BackToDashboard";
import WalletBalance from "@/components/utility/WalletBalance";

export default {
    data() {
        return {
            payingFineId: null,
            userFines: null,
            error: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        payFine(userFine) {
            this.payingFineId = userFine.id;
            this.error = null;

            this.api
                .post(`/userFine/${userFine.id}/pay`)
                .then(() => {
                    this.refresh();
                    this.$store.dispatch("onboarding/status");
                })
                .catch((error) => {
                    if (error?.response?.data?.code === "insufficientBalance") {
                        this.error =
                            "Not enough credits. If you have a pending withdrawal, you can cancel to transfer the credits back to your wallet";
                    }
                })
                .then(() => {
                    this.payingFineId = null;
                });
        },
        refresh() {
            this.api.post("/userFine/index").then((response) => {
                this.userFines = response.data.userFines;
            });
        },
    },
    components: {
        BackToDashboard,
        WalletBalance,
    },
};
</script>