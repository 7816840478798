<template>
    <div>
        <b-modal
            :title="$t('Subscribe to Creator')"
            hide-footer
            v-model="modal"
            centered
            body-class="p-0"
            no-close-on-esc
            
            static
        >
            <user-subscription-create />
        </b-modal>
    </div>
</template>

<script>
import UserSubscriptionCreate from "@/components/userSubscription/UserSubscriptionCreate";

export default {
    computed: {
        modal: {
            get() {
                return this.$store.state.interface
                    .creatorSubscriptionModalVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setCreatorSubscriptionModalVisible",
                    value
                );
            },
        },
    },
    components: {
        UserSubscriptionCreate,
    },
};
</script>










