<template>
    <div>
        <call-add-minutes />

        <user-standard-avatar
            :standardMap="profile"
            :nonPrimaryPhotos="false"
            :hideBadges="true"
            :userBetweenStatus="false"
            size="small"
            class="border-light border-top"
        >
            <span
                v-if="callPreview?.disputeRate"
                :class="`text-${callPreview?.disputeRateVariant}`"
            >
                Dispute Rate: {{ callPreview?.disputeRate }}%
            </span>
        </user-standard-avatar>

        <b-list-group class="mt-3">
            <b-list-group-item
                class="d-flex align-items-center justify-content-between"
            >
                <div>Minutes Remaining</div>
                <div v-if="callPreview.maxMinutes">
                    {{ callPreview.maxMinutes }}
                </div>
                <div v-else class="text-warning">
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                    />
                    None
                </div>
            </b-list-group-item>
            <b-list-group-item
                class="d-flex align-items-center justify-content-between"
            >
                <div>Price per Minute</div>

                <coin-badge :amount="callPreview.pricePerMinute" />
            </b-list-group-item>
            <b-list-group-item
                class="d-flex align-items-center justify-content-between"
            >
                <div>Wallet Balance</div>
                <coin-badge :amount="balance" />
            </b-list-group-item>
        </b-list-group>

        <b-alert
            v-if="callPreview.disableFreeTime"
            show
            variant="warning"
            class="mt-2 small"
        >
            <div class="d-flex align-items-center justify-content-between">
                Free time is temporarily disabled
                <b-button
                    class="ml-2"
                    v-b-toggle.freeTimeDisabled
                    variant="light"
                    size="sm"
                    pill
                >
                    Why?
                </b-button>
            </div>
            <b-collapse id="freeTimeDisabled" class="my-3">
                <h6>Free time is subject to restriction if overused</h6>
                If too many of your calls use only free time and don't result in
                any charges, the free time feature will be disabled until the
                ratio returns back to normal
            </b-collapse>
        </b-alert>

        <b-btn
            v-if="hasMinutes"
            :disabled="creatingCall"
            @click="createCall"
            variant="primary"
            block
            pill
            class="mt-3"
        >
            <h3 class="mb-0">Start Call</h3>
            <div v-if="!callPreview.disableFreeTime" class="small">
                First {{ freeTimeSeconds }} seconds are free
            </div>
        </b-btn>
    </div>
</template>


<script>
import { mapState } from "vuex";

import { FREE_TIME_SECONDS } from "@/constant/call";

import CallAddMinutes from "@/components/always/call/CallAddMinutes";
import UserStandardAvatar from "@/components/user/UserStandardAvatar";

export default {
    data() {
        return {
            freeTimeSeconds: FREE_TIME_SECONDS,
        };
    },
    computed: {
        ...mapState("profile", ["profile"]),
        ...mapState("call", ["callPreview", "creatingCall"]),
        hasMinutes() {
            return this.callPreview?.maxMinutes > 0;
        },
        balance() {
            return this.onboardingUser?.wallet?.balance;
        },
        balanceClass() {
            if (this.onboardingUser?.creator) {
                return "border border-light";
            }

            if (this.balance >= 100) {
                return "border border-light";
            }

            if (this.balance < 25) {
                return "border border-danger text-danger";
            }

            if (this.balance < 100) {
                return "border border-warning text-warning";
            }

            return "";
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        createCall() {
            this.$store.dispatch("call/create", {
                userId: this.profile.id,
            });
        },
    },
    components: {
        CallAddMinutes,
        UserStandardAvatar,
    },
};
</script>