<template>
    <b-btn-group class="w-100">
        <b-btn
            @click="sendGift(giftOption)"
            class="reaction border-top rounded-0 btn-brand bg-white"
            :key="giftOption.price"
            v-for="giftOption in giftOptions"
            variant="light"
        >
            <img class="custom-icon-size" :src="giftOption.url" />
            <div class="small reaction-price">
                <div class="d-flex align-items-center justify-content-center">
                    <font-awesome-icon
                        icon="fa-duotone fa-coin"
                        class="mr-1 text-warning"
                    />
                    <div class="text-secondary">
                        {{ giftOption.price }}
                    </div>
                </div>
            </div>
        </b-btn>
    </b-btn-group>
</template>

<style lang="scss" scoped>
.reaction-price {
    margin-top: -5px;
}
</style>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("livestream", ["consumedFreePeriod"]),
        ...mapState("options", ["giftOptions"]),
        ...mapState("livestream", ["creatorLivestream"]),
    },
    methods: {
        sendGift(giftOption) {
            this.api
                .post(
                    `/livestream/${this.creatorLivestream.id}/gift/${giftOption.id}`
                )
                .then(() => {
                    this.$store.dispatch("livestream/refreshInteractions");
                });
        },
    },
};
</script>