<template>
    <div>
        <div v-if="editMode" class="text-right text-primary px-3 pb-3">
            {{ $t("Select template to edit") }}
            <font-awesome-icon icon="fa-light fa-arrow-down" />
        </div>
        <b-table-simple striped class="mb-0">
            <tbody>
                <tr :key="template.id" v-for="template in templates">
                    <td class="align-middle">
                        <div v-if="template.name">
                            {{ template.name }}
                        </div>
                        <div v-else>
                            {{ $t("Unnamed template ") }}
                            #{{ template.id }}
                        </div>
                    </td>
                    <td style="width: 1%" class="align-middle">
                        <div v-if="editMode">
                            <b-btn
                                pill
                                variant="light"
                                @click="editTemplate(template)"
                                class="border text-truncate btn-brand"
                            >
                                <font-awesome-icon
                                    icon="fa-duotone fa-pencil"
                                />
                                {{ $t("Edit") }}
                            </b-btn>
                        </div>
                        <div v-else>
                            <div v-if="template.body">
                                <b-btn
                                    variant="primary"
                                    pill
                                    @click="useTemplate(template)"
                                    class="border text-truncate px-3"
                                >
                                    {{ $t("Use Template") }}
                                </b-btn>
                            </div>
                            <div v-else>
                                <b-btn
                                    pill
                                    variant="light"
                                    @click="editTemplate(template)"
                                    class="border text-truncate btn-brand"
                                    block
                                >
                                    <font-awesome-icon
                                        icon="fa-duotone fa-pencil"
                                    />
                                    {{ $t("Finish") }}
                                </b-btn>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </b-table-simple>
        <b-alert
            v-if="showCantUseIntroWarning"
            variant="warning"
            show
            class="small p-3 mb-0 rounded-0 text-center"
        >
            <div>
                {{
                    $t("Templates can only be used for the first message sent")
                }}
            </div>
            <div class="mt-2">
                <b-btn
                    @click="upgradePremium"
                    variant="light"
                    size="sm"
                    pill
                    class="px-3 btn-brand"
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-gem"
                        class="branded"
                    />
                    {{ $t("Upgrade to remove restriction") }}
                </b-btn>
            </div>
        </b-alert>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        showCantUseIntroWarning() {
            return !this.premium && this.hasMessages;
        },
        ...mapState("conversationTemplate", ["templates", "editMode"]),
        ...mapState("user", ["premium"]),
        ...mapGetters("conversation", ["hasMessages"]),
    },
    methods: {
        upgradePremium() {
            this.$store.dispatch("premium/upgrade", {
                source: "intros-anytime-info",
            });
        },
        editTemplate(template) {
            this.$store.commit(
                "conversationTemplate/setEditTemplate",
                template
            );
        },
        useTemplate(template) {
            if (this.hasMessages && !this.premium) {
                this.$store.dispatch("premium/upgrade", {
                    source: "use-intro",
                });

                return;
            }

            this.$store.dispatch("conversationTemplate/useTemplate", template);
        },
    },
};
</script>