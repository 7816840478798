<template>
    <div
        v-if="profile.reputation && profile.reputation.state !== 'clean'"
        class="mt-3"
    >
        <div class="px-3 py-2">
            <h5 class="mb-0">
                {{ $t("Reputation") }}
            </h5>
        </div>
        <b-btn
            @click="viewReputation"
            block
            variant="light"
            class="rounded-0 border-0 p-3"
        >
            <div
                v-if="profile.reputation.state === 'warning'"
                class="d-flex align-items-center text-warning"
            >
                <div class="mr-2">
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                        size="lg"
                    />
                </div>
                <div>
                    {{ $t("User was reported by") }}
                    {{ profile.reputation.count }}
                    {{ $t("other user") | pluralize(profile.reputation.count) }}
                </div>
            </div>
            <div
                v-if="profile.reputation.state === 'danger'"
                class="d-flex align-items-center text-danger"
            >
                <div class="mr-2">
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                        size="lg"
                    />
                </div>
                <div>
                    {{ $t("User was reported by") }}
                    {{ profile.reputation.count }}
                    {{ $t("other user") | pluralize(profile.reputation.count) }}
                </div>
            </div>
        </b-btn>

        <b-modal
            centered
            :title="$t('Reputation')"
            hide-footer
            v-model="modal"
            no-close-on-esc
            body-class="p-0"
        >
            <b-alert
                variant="primary"
                show
                class="small border-0 rounded-0 d-flex align-items-center"
            >
                <div class="mr-2">
                    <font-awesome-icon
                        icon="fa-duotone fa-info-circle"
                        size="lg"
                    />
                </div>
                {{ $t("These are reports other users submitted") }}
            </b-alert>
            <div class="p-3">
                <div
                    :key="index"
                    v-for="(report, index) in reputation"
                    class="
                        d-flex
                        align-items-center
                        justify-content-between
                        striped
                        p-3
                    "
                >
                    <div>
                        {{ report.reason }}
                    </div>
                    <div>
                        {{ report.created }}
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            reputation: null,
            modal: false,
        };
    },
    computed: {
        ...mapState("profile", ["profile"]),
    },
    methods: {
        viewReputation() {
            this.modal = true;

            this.api
                .post("/reputation/get", {
                    userId: this.profile.id,
                })
                .then((response) => {
                    if (response?.data?.reputation) {
                        this.reputation = response.data.reputation;
                    }
                });
        },
    },
};
</script>