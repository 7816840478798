<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <view-post-favorite
                    v-if="!isMyPost"
                    :creatorPost="creatorPost"
                    class="mr-1"
                />
                <div v-if="!isMyPost" class="d-flex align-items-center">
                    <b-btn
                        variant="light"
                        class="mr-1 btn-brand"
                        pill
                        @click="chat"
                    >
                        <font-awesome-icon icon="fa-duotone fa-comment-dots" />
                        {{ $t("Chat") }}
                    </b-btn>
                </div>
            </div>
            <view-post-action-viewer-menu
                v-if="!isMyPost"
                :creatorPost="creatorPost"
            />
        </div>
    </div>
</template>


<script>
import { mapGetters, mapState } from "vuex";

import ViewPostActionViewerMenu from "@/components/post/view/action/viewer/ViewPostActionViewerMenu";
import ViewPostFavorite from "@/components/post/view/ViewPostFavorite";

export default {
    computed: {
        isMyPost() {
            return this.creatorPost.userId === this.onboardingUser.id;
        },
        showPassiveSubscribe() {
            if (
                !this.subscribedToCreatorUserId(this.creatorPost.userId) &&
                this.canSubscribeToUserId(this.creatorPost.userId)
            ) {
                return true;
            }

            return false;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapGetters("userSubscription", [
            "subscribedToCreatorUserId",
            "canSubscribeToUserId",
        ]),
    },
    methods: {
        chat() {
            this.$store.dispatch("profile/viewProfile", {
                userId: this.creatorPost.userId,
                profile: this.creatorPost.standardMap,
                view: "conversation",
            });
        },
        subscribeCreator() {
            this.$store.dispatch("userSubscription/fromPost", this.creatorPost);
        },
    },
    components: {
        ViewPostActionViewerMenu,
        ViewPostFavorite,
    },
    props: ["creatorPost"],
};
</script>