import api from "@/service/api";

import {
    SEARCH_SCROLL_POSITION,
    SEARCH_LAST_VIEW,
    SEARCH_LAST_COUNTRY,
} from "@/constant/localStorage";

const startPage = 0
const defaultView = 'online'

const state = {
    searchView: defaultView,
    searchCountryId: null,
    limitedResults: false,
    probablyHasMoreSearchResults: true,
    finishedInitialLoad: false,
    lastRefresh: null,
    users: null,
    page: startPage,
    dismissed: false,
    keyword: null,
    hasScrolled: false,
    hasScrolledToPagination: false,
    searchLoading: false,
}

const mutations = {
    reset(state) {
        this._vm.$scrollToTop();
        localStorage.removeItem(SEARCH_SCROLL_POSITION)
        localStorage.removeItem(SEARCH_LAST_COUNTRY)

        state.page = startPage
        state.dismissed = false
        state.users = null
        state.limitedResults = false
        state.searchLoading = false
        state.searchView = defaultView
        state.searchCountryId = null
        state.keyword = null
    },
    finishedInitialLoad(state) {
        state.finishedInitialLoad = true
    },
    setView(state, searchView) {
        state.searchView = searchView
    },
    setCountryId(state, countryId) {
        state.searchCountryId = countryId
    },
    setKeyword(state, value) {
        state.keyword = value
    },
    setUsers(state, users) {
        state.users = users
    },
    setDismissed(state, value) {
        state.dismissed = value
    },
    nextPage(state) {
        state.page++;
    },
    previousPage(state) {
        state.page--;
    },
    setLastRefresh(state) {
        state.lastRefresh = new Date()
    },
    setHasScrolled(state, value) {
        state.hasScrolled = value
    },
    setHasScrolledToPagination(state, value) {
        state.hasScrolledToPagination = value
    },
    setProbablyHasMoreSearchResults(state, value) {
        state.probablyHasMoreSearchResults = value

        if (!state.probablyHasMoreSearchResults) {
            state.limitedResults = true
        } else {
            state.limitedResults = false
        }
    },
    setSearchLoading(state, value) {
        state.searchLoading = value
    },
}

const actions = {
    setView({ commit, dispatch }, view) {
        commit('setView', view)
        dispatch('refresh')

        localStorage.setItem(SEARCH_LAST_VIEW, view)
    },
    setCountryId({ commit, dispatch }, countryId) {
        commit('setCountryId', countryId)
        dispatch('refresh')

        localStorage.setItem(SEARCH_LAST_COUNTRY, countryId)
    },
    loadInitial({ commit, state, dispatch }) {
        if (!state.finishedInitialLoad) {
            const previousView = localStorage.getItem(SEARCH_LAST_VIEW)

            if (previousView && state.searchView !== previousView) {
                commit('setView', previousView)
            }

            const previousCountry = parseInt(localStorage.getItem(SEARCH_LAST_COUNTRY))

            if (previousCountry && state.searchCountryId !== previousCountry) {
                commit('setCountryId', previousCountry)
            }

            // reload search with updated view
            dispatch('refresh')
            commit('finishedInitialLoad')
        }
    },
    refresh({ state, commit }) {
        commit('setLastRefresh')
        commit('setDismissed', false)
        commit('setSearchLoading', true)

        api
            .post("/search/index", {
                page: state.page,
                view: state.searchView,
                countryId: state.searchCountryId,
                keyword: state.keyword,
            })
            .then((response) => {
                commit('setUsers', response.data.users)
                commit('setProbablyHasMoreSearchResults', response.data.probablyHasMoreSearchResults)
            })
            .catch(() => { })
            .then(() => {
                commit('setSearchLoading', false)
            });
    },
    nextPage({ commit, dispatch }) {
        commit('setUsers', null)
        commit('nextPage')
        dispatch('refresh')
        commit("setHasScrolled", false);
    },
    previousPage({ commit, dispatch }) {
        commit('setUsers', null)
        commit('previousPage')
        dispatch('refresh')
    },
    dismiss({ commit }) {
        commit('setDismissed', true)

        setTimeout(() => {
            commit('setDismissed', false)
        }, 60000 * 5);
    },
    resetSearch({ commit }) {
        commit('reset');
        localStorage.removeItem(SEARCH_LAST_VIEW);
    },
}

const getters = {
    filteredUsers(state, getters, rootState) {
        if (!state.users) {
            return []
        }

        if (!rootState?.user?.blockedUserIds?.length) {
            return state.users;
        }

        return state.users.filter(user => !rootState.user.blockedUserIds.includes(user?.standardMap?.id))
    },
    isDefaultSearchView(state) {
        return state.searchView === defaultView;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
