// import api from "@/service/api";

const state = () => ({
    view: null,
})

const mutations = {
    reset(state) {
        state.view = null
    },
    setView(state, value) {
        state.view = value
    },
}

const actions = {
    setView({ commit }, view) {
        commit('setView', view);
    },
}

const getters = {

}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
