<template>
    <div>
        <div class="mt-3">
            <refill-credit />
        </div>
    </div>
</template>


<script>
import RefillCredit from "@/components/wallet/RefillCredit";

export default {
    components: {
        RefillCredit,
    },
};
</script>