import api from "@/service/api";

import { getRandomString } from "@/utility/random";

import {
    LIVESTREAM_UNIQUE_KEY
} from "@/constant/localStorage";

const state = () => ({
    creatorLivestream: null,
    creatorLivestreamInteractions: null,
    standardMap: null,
    totalCharges: null,
    consumedFreePeriod: false,
    commentInputFocused: false,
})

const CALL_TIMEOUT = 30000;

const mutations = {
    reset(state) {
        state.creatorLivestream = null
        state.creatorLivestreamInteractions = null
        state.standardMap = null
        state.consumedFreePeriod = false
        state.commentInputFocused = false
    },
    setCreatorLivestream(state, value) {
        state.creatorLivestream = value
    },
    setStandardMap(state, value) {
        state.standardMap = value
    },
    setTotalCharges(state, value) {
        state.totalCharges = value
    },
    // ORIGINAL
    setLivePreview(state, value) {
        state.livePreview = value
    },
    setCurrentLive(state, value) {
        state.currentLive = value
    },
    setAddingMinutes(state, value) {
        state.addingMinutes = value
    },
    setCreatingLive(state, value) {
        state.creatingLive = value
    },
    setRingingTimeout(state, value) {
        state.ringingTimeout = value
    },
    setSelectedCamera(state, value) {
        state.selectedCamera = value
    },
    setCommentInputFocused(state, value) {
        state.commentInputFocused = value
    },
    setCreatorLivestreamInteractions(state, value) {
        state.creatorLivestreamInteractions = value
    },
    setChannel(state, value) {
        state.channel = value
    },
    setConsumedFreePeriod(state, value) {
        state.consumedFreePeriod = value
    },
}

const actions = {
    get({ commit }, id) {
        return new Promise(resolve => {
            api.post(`/livestream/${id}/get`).then((response) => {
                if (response?.data?.creatorLivestream) {
                    commit('setConsumedFreePeriod', response.data.consumedFreePeriod)
                    commit('setCreatorLivestream', response.data.creatorLivestream)
                    commit('setTotalCharges', response.data.totalCharges)
                    commit('setStandardMap', response.data.standardMap)
                    resolve();
                }
            })
        })
    },
    charge({ state, rootState, dispatch }) {
        if (rootState.system.cordova) {
            console.log('charges disabled on cordova')
            return;
        }

        const getUniqueBillingKey = () => {
            const existingKey = localStorage.getItem(LIVESTREAM_UNIQUE_KEY);

            if (existingKey) {
                return existingKey
            }

            const randomString = getRandomString(32);
            localStorage.setItem(LIVESTREAM_UNIQUE_KEY, randomString)
            return randomString;
        }

        // unique billing key per device, to prevent dupe billing if they
        // watch on phone, then laptop for example
        api.post(`/livestream/${state.creatorLivestream.id}/charge`, {
            uniqueBillingKey: getUniqueBillingKey()
        }).then(() => {
            dispatch('refresh')
        }).catch(error => {
            if (error?.response?.data?.code === 'notEnoughCredits') {
                dispatch('onboarding/status', null, { root: true })
            }

            if (error?.response?.data?.code === 'livestreamEnded') {
                dispatch('refresh')
            }
        })
    },
    refreshInteractions({ state, commit }) {
        api.post(`/livestream/${state.creatorLivestream.id}/interactions`).then((response) => {
            if (response?.data?.creatorLivestreamInteractions) {
                commit('setCreatorLivestreamInteractions', response.data.creatorLivestreamInteractions)
            }
        })
    },
    refresh({ state, dispatch }) {
        if (!state.creatorLivestream) {
            return
        }

        dispatch('get', state.creatorLivestream.id)
    },
    pusherEvent({ state, dispatch }, data) {
        // if not live created event, it should already have the current live
        // once live is ended, allow new events to be processed
        if (
            state.creatorLivestream
            && state.creatorLivestream.streamInProgress
            && state.creatorLivestream.id !== data.id
        ) {
            console.error("pusher creatorLivestream event, id does not match current")
            return
        }

        if (data.event === 'refreshInteractions') {
            dispatch('refreshInteractions')
            return
        }

        if (data.event === 'updated') {
            dispatch('refresh')
            return
        }

        if (data.event === 'ended') {
            dispatch('refresh')
            return
        }
    },
    // UNTOUCHED BELOW FROM CALL
    // UNTOUCHED BELOW FROM CALL
    // UNTOUCHED BELOW FROM CALL
    refreshPreview({ dispatch, state }) {
        if (state.livePreview) {
            dispatch('preview', {
                userId: state.livePreview.userId
            })
        }
    },
    start({ dispatch, rootState, commit }, { userId } = {}) {
        // no need for preview for creators, they can always live for free
        if (rootState.onboarding.user.creator) {
            commit('reset')
            commit("interface/setVideoLiveModalVisible", true, { root: true });
            dispatch('create', { userId })
        } else {
            dispatch('preview', { userId })
        }
    },
    preview({ commit }, { userId } = {}) {
        commit('reset')
        commit("interface/setVideoLiveModalVisible", true, { root: true });

        api.post("/live/preview", {
            userId
        }).then((response) => {
            if (response?.data?.livePreview) {
                commit('setLivePreview', response.data.livePreview)
            }
        });
    },
    missedLive({ state, dispatch }) {
        if (!state.currentLive?.answeredAt) {
            dispatch('missed')
        }
    },
    openLive({ commit, dispatch }, { id, startVideo } = {}) {
        commit("interface/setVideoLiveModalVisible", true, { root: true });

        dispatch('get', id).then(() => {
            dispatch('seen')

            if (startVideo) {
                dispatch('startVideo')
            }
        })
    },
    create({ commit, dispatch }, { userId } = {}) {
        commit('setCreatingLive', true)

        api.post("/live/create", {
            userId
        }).then((response) => {
            if (response?.data?.live) {
                commit('setCurrentLive', response.data.live)

                commit('setRingingTimeout', setTimeout(() => {
                    dispatch('missedLive')
                }, CALL_TIMEOUT))
            }
        }).catch(() => { })
            .then(() => {
                commit('setCreatingLive', false)
            })
    },
    answer({ commit, state, dispatch }) {
        api.post(`/live/${state.currentLive.id}/answer`).then(async (response) => {
            if (response?.data?.live) {
                commit('setCurrentLive', response.data.live)
                dispatch('startVideo')
            }
        });
    },
    missed({ commit, state }) {
        api.post(`/live/${state.currentLive.id}/missed`).then((response) => {
            if (response?.data?.live) {
                commit('setCurrentLive', response.data.live)
            }
        });
    },
    hangup({ state, dispatch, commit }) {
        api.post(`/live/${state.currentLive.id}/hangup`).then((response) => {
            if (response?.data?.live) {
                commit('setCurrentLive', response.data.live)
            }

            // survey them for live quality if the live actually took place
            if (response?.data?.live?.answeredAt && response?.data?.live?.endedAt) {
                // since we are preventing the close/reset
                // manually reset channel to close agora
                dispatch('endLive')
                return;
            }

            dispatch('close')
        })
    },
    seen({ state }) {
        api.post(`/live/${state.currentLive.id}/seen`)
    },
    close({ commit }) {
        commit('reset')
        commit("interface/setVideoLiveModalVisible", false, { root: true });
    },
    decline({ state, dispatch }) {
        api.post(`/live/${state.currentLive.id}/decline`).then(() => {
            dispatch('close')
        });
    },
    cancel({ state, dispatch }) {
        api.post(`/live/${state.currentLive.id}/cancel`)
        dispatch('close')
    },
    async signal({ dispatch }) {
        console.log(dispatch)
    },
    startVideo({ state, commit }) {
        commit('setChannel', state.currentLive.uuid)
    },
    endLive({ commit }) {
        commit('setChannel', null)
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
