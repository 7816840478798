var render = function render(){var _vm=this,_c=_vm._self._c;return _c('generic-page',[_c('b-btn',{staticClass:"px-0 mt-2",attrs:{"variant":"link","size":"lg"},on:{"click":function($event){return _vm.$router.push({
                name: 'supportIndex',
            })}}},[_c('font-awesome-icon',{attrs:{"size":"lg","icon":"fa-light fa-chevron-left"}}),_vm._v(" All Topics ")],1),(_vm.topic)?_c('div',[_c('h5',[_vm._v(" "+_vm._s(_vm.topic.name)+" ")]),_c('b-list-group',{staticClass:"mt-2"},_vm._l((_vm.contents),function(content){return _c('b-list-group-item',{key:content.id,staticClass:"d-flex align-items-center justify-content-between",attrs:{"action":""},on:{"click":function($event){return _vm.$router.push({
                        name: 'supportContent',
                        params: {
                            id: content.id,
                        },
                    })}}},[_vm._v(" "+_vm._s(content.title)+" "),_c('font-awesome-icon',{attrs:{"icon":"fa-light fa-chevron-right"}})],1)}),1)],1):_c('div',[_c('generic-loading')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }