<template>
    <div>
        <back-to-dashboard />
        <creator-invite-index />
    </div>
</template>

<script>
import CreatorInviteIndex from "@/components/creator/invite/CreatorInviteIndex";
import BackToDashboard from "@/components/utility/BackToDashboard";

export default {
    components: {
        CreatorInviteIndex,
        BackToDashboard,
    },
};
</script>