<template>
    <div>
        <b-modal
            :title="title"
            hide-footer
            v-model="modal"
            centered
            scrollable
            body-class="p-0"
            no-close-on-esc
            
            static
            ref="messageIndex"
        >
            <div class="text-center p-3">
                <div class="py-3">
                    <font-awesome-icon
                        icon="fa-light fa-check-circle"
                        class="text-success"
                        size="5x"
                    />
                </div>

                <h5>
                    {{ message }}
                </h5>

                <b-btn
                    @click="closeModal"
                    variant="primary"
                    pill
                    block
                    size="lg"
                    class="mt-3"
                >
                    {{ $t("Ok, got it") }}
                </b-btn>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        modal: {
            get() {
                return this.$store.state.alert.title ? true : false;
            },
            set(value) {
                if (!value) {
                    this.$store.commit("alert/reset");
                }
            },
        },
        ...mapState("alert", ["title", "message"]),
    },
    methods: {
        closeModal() {
            this.$store.commit("alert/reset");
        },
    },
};
</script>