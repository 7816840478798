<template>
    <div>
        <user-header :standardMap="creatorGallery.standardMap" />
        <div class="p-3 text-center bg-white rounded border">
            <gallery-details :creatorGallery="creatorGallery" />
            <gallery-preview :creatorGallery="creatorGallery" />

            <div class="my-3">
                <div class="d-flex align-items-center justify-content-center">
                    <font-awesome-icon
                        icon="fa-duotone fa-coin"
                        size="lg"
                        class="small-avatar mr-2 text-warning"
                    />
                    <h1 class="text-warning mb-0">
                        {{ creatorGallery.price }}
                        {{ $t("credits") }}
                    </h1>
                </div>

                <div class="text-secondary">
                    {{ $t("One-time payment to unlock") }}
                </div>
            </div>

            <div class="mt-3">
                <b-btn
                    @click="viewGallery"
                    variant="primary"
                    size="lg"
                    block
                    pill
                >
                    {{ $t("Purchase Gallery") }}

                    <gallery-contents-label :creatorGallery="creatorGallery" />
                </b-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import UserHeader from "@/components/userHeader/UserHeader";

import GalleryContentsLabel from "@/components/gallery/GalleryContentsLabel";
import GalleryPreview from "@/components/gallery/GalleryPreview";
import GalleryDetails from "@/components/gallery/GalleryDetails";

export default {
    computed: {
        ...mapState("gallery", ["creatorGallery"]),
    },
    methods: {
        viewGallery() {
            this.$store.dispatch("gallery/unlock");
        },
    },
    components: {
        UserHeader,
        GalleryContentsLabel,
        GalleryPreview,
        GalleryDetails,
    },
};
</script>