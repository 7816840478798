<template>
    <div>
        <b-modal
            :title="$t('Manage Subscription')"
            hide-footer
            v-model="modal"
            centered
            body-class="p-0"
            no-close-on-esc
            
            static
        >
            <div v-if="userSubscription">
                <div v-if="cancelSubscription">
                    <subscription-cancel />
                </div>
                <div v-else>
                    <subscription-view />
                </div>
            </div>
            <div v-else>
                <generic-loading />
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";

import SubscriptionView from "@/components/subscription/SubscriptionView";
import SubscriptionCancel from "@/components/subscription/SubscriptionCancel";

export default {
    computed: {
        modal: {
            get() {
                return this.$store.state.interface
                    .manageSubscriptionModalVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setManageSubscriptionModalVisible",
                    value
                );
            },
        },
        ...mapState("userSubscription", [
            "userSubscription",
            "cancelSubscription",
        ]),
    },
    components: {
        SubscriptionView,
        SubscriptionCancel,
    },
};
</script>