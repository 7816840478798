<template>
    <div>
        <div
            v-if="spinning"
            class="d-flex align-items-center justify-content-center"
        >
            <daily-giveaway-show />
        </div>
        <div v-else-if="reveal">
            {{ giftaway }}
        </div>
        <div v-else>
            <b-btn
                @click="spinWheel"
                variant="light"
                class="btn-brand px-5"
                pill
                size="lg"
            >
                <font-awesome-icon icon="fa-duotone fa-gift" />
                Claim Free Gift
            </b-btn>
        </div>
    </div>
</template>



<script>
import { mapState } from "vuex";

import DailyGiveawayShow from "@/components/dailyGiveaway/DailyGiveawayShow";

export default {
    data() {
        return {
            spinning: false,
            reveal: false,
            giftaway: null,
        };
    },
    created() {
        this.api.post("/giveaway/daily").then((response) => {
            if (response?.data?.giveaway) {
                this.giftaway = response?.data?.giveaway;
            }
        });
    },
    computed: {
        eligibleForDailyGiveaway() {
            return this.onboardingUser?.eligibleForDailyGiveaway;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        showGift() {
            this.spinning = false;
            this.reveal = true;
        },
        spinWheel() {
            this.spinning = true;

            setTimeout(() => {
                this.showGift();
            }, 3000);
        },
    },
    components: {
        DailyGiveawayShow,
    },
};
</script>