<template>
    <div class="text-left">
        <b-alert variant="warning" show v-if="hasUnapprovedPhotoUpdate">
            <img
                class="img-fluid rounded float-right"
                :src="onboardingUser.photoUpdate.userPhoto.small"
            />

            <h5>Pending Review</h5>
            Please allow up to 48 hours for us to review your photo change
        </b-alert>
        <div class="mb-2 d-flex align-items-center justify-content-between">
            <div>
                <h5 class="mb-0">
                    {{ $t("Public Photos") }}
                </h5>
                <div class="small text-secondary">
                    Visible to users matching your filters
                </div>
            </div>

            <div>
                <b-btn
                    v-if="cordova"
                    variant="light"
                    class="btn-brand d-flex align-items-center"
                    @click="
                        $store.commit(
                            'interface/setOnboardingPhotoUploadMode',
                            'public'
                        )
                    "
                    pill
                >
                    <font-awesome-icon icon="fa-light fa-plus" class="mr-1" />
                    {{ $t("Add") }}
                </b-btn>
                <universal-uploader
                    v-else
                    @uploadSuccess="uploadSuccessPublic"
                    class="text-truncate"
                >
                    <b-btn
                        variant="light"
                        class="btn-brand d-flex align-items-center"
                        pill
                    >
                        <font-awesome-icon
                            icon="fa-light fa-plus"
                            class="mr-1"
                        />
                        {{ $t("Add") }}
                    </b-btn>
                </universal-uploader>
            </div>
        </div>

        <onboarding-photo-index-grid :photos="publicPhotos" />

        <div
            class="mt-4 mb-2 d-flex align-items-center justify-content-between"
        >
            <div>
                <h5 class="mb-0">Private Photos</h5>
            </div>

            <div>
                <b-btn
                    v-if="cordova"
                    variant="light"
                    class="btn-brand d-flex align-items-center"
                    @click="
                        $store.commit(
                            'interface/setOnboardingPhotoUploadMode',
                            'private'
                        )
                    "
                    pill
                >
                    <font-awesome-icon icon="fa-light fa-plus" class="mr-1" />
                    {{ $t("Add") }}
                </b-btn>
                <universal-uploader
                    v-else
                    @uploadSuccess="uploadSuccessPrivate"
                    class="text-truncate"
                >
                    <b-btn
                        variant="light"
                        class="btn-brand d-flex align-items-center"
                        pill
                    >
                        <font-awesome-icon
                            icon="fa-light fa-plus"
                            class="mr-1"
                        />
                        {{ $t("Add") }}
                    </b-btn>
                </universal-uploader>
            </div>
        </div>

        <onboarding-photo-index-grid :photos="privatePhotos" />

        <div v-if="photosPendingProcessing.length">
            <div class="mt-4 mb-2">
                <div>
                    <h5 class="mb-0">
                        {{ $t("Photos Processing") }}
                    </h5>
                    <div class="small text-secondary">
                        {{ $t("Photo moderation can take up to 30 seconds") }}
                    </div>
                </div>
            </div>
            <div>
                <div
                    :key="photo.url"
                    v-for="photo in photosPendingProcessing"
                    class="d-flex align-items-center"
                >
                    <div class="mr-2 position-relative">
                        <img
                            height="100"
                            width="100"
                            class="opacity-50"
                            :src="photo.url"
                        />
                        <div v-if="!photo.error" class="absolute-centered">
                            <b-spinner variant="dark" />
                        </div>
                    </div>
                    <div>
                        <div v-if="photo.error" class="text-danger">
                            {{ photo.error }}
                        </div>
                        <div v-else-if="photo.uploading">
                            {{
                                $t("Uploading, and running preliminary checks")
                            }}
                        </div>
                        <div v-else>
                            {{ $t("Moderating") }}
                        </div>
                        <div class="mt-1">
                            <b-btn
                                @click="removePendingPhoto(photo)"
                                variant="light"
                                size="sm"
                                class="btn-brand"
                                pill
                            >
                                <font-awesome-icon icon="fa-light fa-times" />

                                {{ $t("Remove") }}
                            </b-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.photo-pending {
    height: 100%;
    width: 100%;

    img {
        height: 100%;
        width: 100%;
        object-position: center;
        object-fit: cover;
    }
}
</style>

<script>
import { mapState } from "vuex";

import OnboardingPhotoIndexGrid from "@/components/onboarding/photos/OnboardingPhotoIndexGrid";
import UniversalUploader from "@/components/universalUploader/UniversalUploader";

export default {
    computed: {
        ...mapState("system", ["cordova"]),
        ...mapState("photo", ["photosPendingProcessing"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        photos() {
            return this.onboardingUser?.photos ?? [];
        },
        publicPhotos() {
            return this.photos.filter((photo) => photo.approved);
        },
        privatePhotos() {
            return this.photos.filter((photo) => !photo.approved);
        },
        hasUnapprovedPhotoUpdate() {
            return this.onboardingUser?.photoUpdate?.isPending;
        },
    },
    methods: {
        uploadSuccessPublic(url) {
            this.uploadPhotoByUrl(url, "public");
        },
        uploadSuccessPrivate(url) {
            this.uploadPhotoByUrl(url, "private");
        },
        uploadPhotoByUrl(url, mode) {
            const instanceId = this.$uuid();

            this.$store.dispatch("photo/appendProcessingPhoto", {
                instanceId,
                url,
                uploading: true,
            });

            this.api
                .post("/photo/uploadByUrl", {
                    url,
                    instanceId,
                    mode,
                })
                .then(() => {
                    this.$store.commit("photo/finishedUpload", instanceId);
                })
                .catch((error) => {
                    if (error?.response?.data?.errors) {
                        Object.entries(error.response.data.errors).forEach(
                            ([instanceId, items]) => {
                                this.$store.commit("photo/appendPhotoError", {
                                    instanceId,
                                    message: items.join(", "),
                                });
                            }
                        );
                    }
                });

            this.$store.commit("interface/setOnboardingPhotoUploadMode", false);
        },
        removePendingPhoto(photo) {
            this.$store.commit("photo/removePendingPhoto", photo.instanceId);
        },
    },
    components: {
        OnboardingPhotoIndexGrid,
        UniversalUploader,
    },
};
</script>