<template>
    <div>
        <div v-if="ready">
            <div class="agora-dialog">
                <div class="content-video">
                    <div
                        class="remoteVideoContainer bg-light d-flex align-items-center justify-content-center overflow-hidden blurredPreview"
                    >
                        <video
                            v-show="isVideoSubed"
                            controls
                            controlslist="nodownload"
                            id="remote-video"
                            ref="remoteVideo"
                            :class="{
                                'video-fullscreen': isVideoSubed && !isCameraOn,
                            }"
                            class="w-100"
                        ></video>
                        <div v-if="!isVideoSubed">
                            <font-awesome-icon
                                size="5x"
                                icon="fa-duotone fa-camera-slash"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <b-btn
                @click="joinLivestream"
                variant="primary"
                block
                class="rounded-0 py-3"
            >
                <h3 class="mb-0">Join Livestream</h3>

                <div class="mt-2">
                    First
                    {{ config.creatorLivestreamFreeTimeSeconds }} seconds are
                    free
                </div>

                <div class="mt-2">
                    <coin-badge
                        :amount="pricePerMinute"
                        label="Price per Minute"
                    />
                </div>
            </b-btn>
        </div>
        <div v-else-if="ended">
            <b-alert
                variant="warning"
                show
                class="rounded-0 border-0 mb-0 text-center py-5"
            >
                <h5>Livestream Finished</h5>

                <b-btn
                    @click="closeProfile"
                    pill
                    class="mt-3"
                    variant="primary"
                >
                    Close Profile
                </b-btn>
            </b-alert>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.blurredPreview video {
    filter: blur(12px);
}
</style>

<script>
import { mapGetters, mapState } from "vuex";
import { createClient } from "agora-rtc-sdk-ng/esm";

export default {
    data() {
        return {
            isCameraOn: false,
            isVideoSubed: false,
            isAudioSubed: false,
            creatorLivestream: null,
            pricePerMinute: null,
            ended: false,
            videoTrack: null,
        };
    },
    mounted() {
        this.api
            .post(`/livestream/${this.profile.id}/profile`)
            .then((response) => {
                if (response?.data?.creatorLivestream) {
                    this.creatorLivestream = response.data.creatorLivestream;
                    this.pricePerMinute = response.data.pricePerMinute;
                    this.join(response.data.creatorLivestream.uuid);
                }
            })
            .catch((error) => {
                if (error?.response?.data?.code === "livestreamEnded") {
                    this.$store.commit(
                        "pusher/removeLiveUserId",
                        this.profile.id
                    );

                    this.ended = true;
                }
            });
    },
    computed: {
        ready() {
            return this.creatorLivestream && this.agora;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapGetters("user", ["isLoggedIn"]),
        ...mapState("profile", ["profile"]),
        ...mapState("options", ["agora", "config"]),
    },
    methods: {
        closeProfile() {
            this.$store.dispatch("profile/close");
        },
        joinLivestream() {
            // if they navigated from inbox on mobile, close that layer, otherwise would be left open
            this.$store.commit("interface/setMessageInboxModalVisible", false);
            this.$store.dispatch("profile/close");

            this.$router.push({
                name: "livestreamView",
                params: {
                    id: this.creatorLivestream.id,
                },
            });
        },
        async join(uuid) {
            this.client = await createClient({ mode: "live", codec: "vp8" });

            const uid = await this.client.join(this.agora.appId, uuid, null);

            console.log("unused", uid);

            this.client.on("user-published", this.onPublished);
            this.client.on("user-unpublished", this.onUnPublished);
        },
        async onPublished(user, mediaType) {
            await this.client.subscribe(user, mediaType);

            if (mediaType === "video") {
                this.videoTrack = user.videoTrack;

                if (this.videoTrack) {
                    this.videoTrack.play("remote-video");
                    this.isVideoSubed = true;

                    this.$refs.remoteVideo.controls = true;
                }
            }

            if (mediaType === "audio") {
                const remoteAudioTrack = user.audioTrack;

                if (remoteAudioTrack) {
                    remoteAudioTrack.play();
                    this.isAudioSubed = true;
                }
            }
        },
        async onUnPublished(user, mediaType) {
            await this.client.unsubscribe(user, mediaType);

            if (mediaType === "video") {
                this.isVideoSubed = false;
            }
            if (mediaType === "audio") {
                this.isAudioSubed = false;
            }
        },
    },
    async beforeDestroy() {
        if (this.videoTrack) {
            await this.videoTrack.stop();
            await this.videoTrack.close();
            this.videoTrack = null;
        }

        if (this.client) {
            await this.client.leave();
            this.client = null;
        }
    },
    beforeUnmount() {
        this.client.off("user-published", this.onPublished);
        this.client.off("user-unpublished", this.onUnPublished);
    },
};
</script>