<template>
    <div class="mt-3">
        <h5 class="text-warning">
            <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
            Disputes
        </h5>

        <b-alert
            :key="creatorRequestDispute.id"
            v-for="creatorRequestDispute in unsettledDisputes"
            variant="warning"
            show
        >
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    <div class="small text-uppercase font-weight-bold">
                        Reason
                    </div>
                    {{
                        getCreatorDisputeReason(creatorRequestDispute.reason)
                            ?.explainer
                    }}

                    <div class="small text-uppercase font-weight-bold mt-3">
                        Comment
                    </div>
                    <div>
                        {{ creatorRequestDispute.comment }}
                    </div>
                </div>
            </div>

            <div class="mt-3">
                <div
                    v-if="
                        rectifiedDisputeIds.includes(creatorRequestDispute.id)
                    "
                    class="text-primary"
                >
                    <font-awesome-icon icon="fa-light fa-check-circle" />
                    Marked corrected
                </div>
                <div v-else>
                    <b-btn
                        variant="primary"
                        block
                        pill
                        @click="rectifiedDispute(creatorRequestDispute)"
                    >
                        I have corrected the content for this dispute
                    </b-btn>
                </div>
            </div>
        </b-alert>

        <b-modal centered hide-footer v-model="modal" title="Dispute">
            <div v-if="creatorRequestDispute">
                <creator-request-dispute-view
                    @closeModal="closeModal"
                    :creatorRequestDispute="creatorRequestDispute"
                />
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import CreatorRequestDisputeView from "@/components/creator/requests/CreatorRequestDisputeView";

export default {
    data() {
        return {
            modal: false,
            confirmed: false,
            creatorRequestDispute: null,
            creatorDisputeConfig: null,
        };
    },
    computed: {
        ...mapState("creatorRequest", [
            "creatorRequest",
            "disputeRate",
            "disputeRateAfter",
            "creatorRequestConfig",
            "creatorRequestDisputes",
            "rectifiedDisputeIds",
        ]),
        ...mapGetters("options", ["getCreatorDisputeReason"]),
        ...mapGetters("creatorRequest", ["unsettledDisputes"]),
    },
    methods: {
        rectifiedDispute(creatorRequestDispute) {
            this.$store.commit(
                "creatorRequest/appendRectifiedDisputeId",
                creatorRequestDispute.id
            );
        },
        closeModal() {
            this.modal = false;
        },
        viewDispute(creatorRequestDispute) {
            this.creatorRequestDispute = creatorRequestDispute;
            this.modal = true;
        },
    },
    components: {
        CreatorRequestDisputeView,
    },
};
</script>