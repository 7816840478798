<template>
    <div>
        <universal-uploader
            @uploadSuccess="uploadPhotoSuccess"
            class="text-truncate"
        >
            <b-btn pill variant="primary" size="lg" block>
                <slot />
            </b-btn>
        </universal-uploader>
    </div>
</template>

<script>
import UniversalUploader from "@/components/universalUploader/UniversalUploader";

export default {
    data() {
        return {
            complete: false,
            uploading: false,
            userDocuments: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api.post("/document/index").then((response) => {
                this.userDocuments = response?.data?.userDocuments;

                if (
                    this.userDocuments.filter(
                        (document) => document.type === this.type
                    ).length
                ) {
                    this.$emit("complete");
                }
            });
        },
        uploadPhotoSuccess(url) {
            this.uploading = true;

            this.api
                .post("/document/upload", {
                    url,
                    type: this.type,
                })
                .then(() => {
                    this.refresh();
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
    components: {
        UniversalUploader,
    },
    props: ["type"],
};
</script>