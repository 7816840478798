<template>
    <div>
        <b-table-simple class="mb-0">
            <tbody>
                <tr>
                    <th class="bg-light">ID</th>
                    <td>
                        {{ creatorRequestDispute.id }}
                    </td>
                </tr>
                <tr>
                    <th class="bg-light">Status</th>
                    <td>
                        <creator-request-dispute-status
                            :creatorRequestDispute="creatorRequestDispute"
                        />
                    </td>
                </tr>
                <tr>
                    <th class="bg-light">Reason</th>
                    <td>
                        {{
                            getCreatorDisputeReason(
                                creatorRequestDispute.reason
                            )?.explainer
                        }}
                    </td>
                </tr>
                <tr>
                    <th class="bg-light">Comment</th>
                    <td>
                        {{ creatorRequestDispute.comment }}
                    </td>
                </tr>
            </tbody>
        </b-table-simple>

        <div class="mt-3" v-if="!creatorRequestDispute.settled">
            <div class="text-secondary text-center my-2">
                <font-awesome-icon icon="fa-duotone fa-info-circle" />
                Withdrawing the dispute will also accept the content, completing
                the request and transferring credits to the creator
            </div>
            <b-btn
                :disabled="saving"
                @click="withdrawDispute"
                variant="primary"
                pill
                size="lg"
                block
            >
                Withdraw Dispute
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import CreatorRequestDisputeStatus from "@/components/creator/requests/CreatorRequestDisputeStatus";

export default {
    data() {
        return {
            saving: false,
        };
    },
    computed: {
        ...mapState("request", [
            "creatorRequest",
            "disputeRate",
            "disputeRateAfter",
            "creatorRequestConfig",
        ]),
        ...mapGetters("options", ["getCreatorDisputeReason"]),
    },
    methods: {
        withdrawDispute() {
            this.saving = true;

            this.api
                .post(
                    `/creatorRequestDispute/${this.creatorRequestDispute.id}/withdraw`
                )
                .then(() => {
                    this.$store.dispatch("request/refresh");
                    this.$emit("closeModal");
                })
                .catch(() => {})
                .then(() => {
                    this.saving = false;
                });
        },
    },
    components: {
        CreatorRequestDisputeStatus,
    },
    props: ["creatorRequestDispute"],
};
</script>