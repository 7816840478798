<template>
    <div class="p-3 w-100">
        <b-form v-if="sent" @submit.stop.prevent="verifyCode">
            <b-alert variant="warning" show>
                <div>
                    {{
                        $t(
                            "Email sent, if you don't see it within 30 seconds, please check your spam or junk folder."
                        )
                    }}
                </div>
            </b-alert>
            <b-form-group :label="$t('Login Code')">
                <b-form-input
                    size="lg"
                    v-model="code"
                    @input="edited = true"
                    :placeholder="$t('Enter Login Code')"
                    inputmode="numeric"
                    maxlength="4"
                    autocorrect="off"
                    autocapitalize="none"
                    autocomplete="off"
                    :state="error ? false : null"
                ></b-form-input>
                <b-form-invalid-feedback v-if="error">
                    {{ error }}
                </b-form-invalid-feedback>
            </b-form-group>

            <div class="mt-3">
                <b-btn @click="verifyCode" variant="primary" size="lg" block>
                    {{ $t("Verify Code") }}
                </b-btn>
            </div>
            <div class="mt-3" v-if="!retried">
                <b-btn
                    :disabled="secondsRemaining > 0"
                    @click="resendCode"
                    variant="link"
                    size="sm"
                    block
                    :class="
                        secondsRemaining > 0 ? 'text-secondary' : 'text-primary'
                    "
                >
                    {{ $t("Didn't get the code? Try again") }}
                </b-btn>
            </div>
            <div v-if="secondsRemaining" class="mt-1 text-center small">
                {{ $t("Can try again in:") }}
                <span>
                    {{ secondsRemaining }}
                </span>
                {{ $t("seconds") }}
            </div>
        </b-form>
        <b-form v-if="!sent" @submit.stop.prevent="confirmEmail">
            <b-form-group :label="$t('Double Check Email')">
                <b-form-input
                    size="lg"
                    v-model="email"
                    @input="edited = true"
                    :placeholder="$t('Email Address')"
                    type="email"
                    autocorrect="off"
                    autocomplete="email"
                    autocapitalize="none"
                    :state="error ? false : null"
                ></b-form-input>
                <b-form-invalid-feedback v-if="error">
                    {{ error }}
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
                <b-btn @click="confirmEmail" variant="primary" size="lg" block>
                    {{ $t("Send Login Code") }}
                </b-btn>
            </b-form-group>
        </b-form>
        <b-modal
            v-model="modal"
            hide-header
            hide-footer
            body-class="p-0"
            centered
        >
            <b-alert variant="warning" show class="rounded-0 border-0 mb-0">
                {{ $t("Please confirm your email address is correct:") }}
            </b-alert>

            <div class="py-5 text-center lead">
                {{ email }}
            </div>
            <b-btn-group class="w-100">
                <b-btn
                    @click="modal = false"
                    variant="light"
                    size="lg"
                    class="rounded-0 border-0 text-danger"
                >
                    {{ $t("Cancel") }}
                </b-btn>
                <b-btn
                    @click="sendCode"
                    variant="primary"
                    size="lg"
                    class="rounded-0 border-0"
                >
                    {{ $t("Confirm") }}
                </b-btn>
            </b-btn-group>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";

const WAITING_SECONDS = 60;

export default {
    data() {
        return {
            sendLoginCodeTranslation: this.$t("Send Login Code"),
            modal: false,
            error: null,
            email: null,
            code: null,
            edited: false,
            sent: false,
            secondsRemaining: null,
            retried: false,
        };
    },
    created() {
        if (this.onboardingUser.email) {
            this.email = this.onboardingUser.email;
        }
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        resendCode() {
            this.modal = false;
            this.retried = true;

            this.api.post("/email/resendCode", {
                email: this.email,
            });
        },
        sendCode() {
            this.modal = false;

            this.api
                .post("/signup/verifyEmail", {
                    email: this.email,
                })
                .then(() => {
                    this.error = null;
                    this.edited = false;
                    this.sent = true;

                    this.startCountdown();
                })
                .catch(() => {
                    this.error = this.$t("Please enter a valid email address");
                });
        },
        startCountdown() {
            this.secondsRemaining = WAITING_SECONDS;

            let currentTime = 0;

            [...Array(WAITING_SECONDS)].forEach(() => {
                currentTime += 1000;

                setTimeout(() => {
                    if (this.secondsRemaining === 0) {
                        return;
                    }

                    this.secondsRemaining = this.secondsRemaining - 1;
                }, currentTime);
            });
        },
        verifyCode() {
            this.api
                .post("/signup/verifyCode", {
                    email: this.email,
                    verifyEmailCode: this.code,
                })
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                })
                .catch(() => {
                    this.error = this.$t("Invalid code");
                });
        },
        confirmEmail() {
            this.modal = true;
        },
    },
    components: {},
};
</script>