import { FEED_TYPE_CREATOR_POST } from "@/constant/feed";
import api from "@/service/api";

const state = () => ({
    failedLoading: null,
    creatorPost: null,
    recentPosts: null,
    highlightComment: null,
})

const mutations = {
    setCreatorPost(state, value) {
        state.creatorPost = value
    },
    setRecentPosts(state, value) {
        state.recentPosts = value
    },
    setHighlightComment(state, value) {
        state.highlightComment = value
    },
    setFailedLoading(state, value) {
        state.failedLoading = value
    },
}

const actions = {
    refresh({ state, dispatch }) {
        if (!state.creatorPost) {
            return;
        }

        dispatch('getCreatorPostBySlug', state.creatorPost.slug)
    },
    getCreatorPostBySlug({ commit, dispatch }, slug) {
        commit('setFailedLoading', false);

        api.post("/publicPost/get", {
            slug
        }).then((response) => {
            if (response?.data?.creatorPost) {
                commit('setCreatorPost', response.data.creatorPost)
                commit("attribution/setInvite", response.data.creatorPost.userId, { root: true });
            }

            if (response?.data?.recentPosts) {
                commit('setRecentPosts', response.data.recentPosts)
            }
        }).catch(() => {
            commit('setFailedLoading', true);
            dispatch("appEvent/create", "failedLoadingPost", { root: true });
        })
    },
    viewComments({ commit, dispatch }, creatorPostId) {
        commit("interface/setPostCommentsModalVisible", true, { root: true });
        dispatch("postComments/refresh", {
            type: FEED_TYPE_CREATOR_POST,
            id: creatorPostId
        }, { root: true });
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
