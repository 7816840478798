<template>
    <div>
        <div v-if="offerPurchase">
            <b-btn
                v-if="confirm"
                @click="purchaseCreatorPost"
                variant="primary"
                block
                size="lg"
                class="rounded-0 py-3"
            >
                <h4 class="mb-0">
                    {{ $t("Confirm Purchase") }}
                </h4>
                <div
                    class="d-flex align-items-center justify-content-center small"
                >
                    {{ $t("View post for") }}
                    <span class="mx-1">
                        <coin-badge :amount="creatorPost.price" />
                    </span>
                    {{ $t("credits") }}
                </div>
            </b-btn>
            <b-btn
                v-else
                @click="confirm = true"
                variant="light"
                block
                size="lg"
                class="btn-brand border-brand-heavy rounded-0 py-3"
            >
                <h4 class="mb-0">
                    {{ $t("Remove Blur") }}
                </h4>
                <div
                    class="d-flex align-items-center justify-content-center small text-secondary"
                >
                    {{ $t("Purchase to view") }}
                </div>
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    data() {
        return {
            confirm: false,
        };
    },
    computed: {
        offerPurchase() {
            if (this.onboardingUser?.creator) {
                return false;
            }

            if (!this.canViewPost(this.creatorPost)) {
                return true;
            }

            return false;
        },
        ...mapGetters("userSubscription", [
            "canViewPost",
            "canSubscribeToUserId",
            "subscribedToCreatorUserId",
        ]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        purchaseCreatorPost() {
            this.api
                .post(`/creatorPost/${this.creatorPost.id}/purchase`)
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                });
        },
    },
    props: ["creatorPost"],
};
</script>