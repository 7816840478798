<template>
    <div>
        <h5 class="mt-2">Invite Guides</h5>

        <div>
            Learn the easiest ways to invite people to join the platform and
            maximize your earnings!
        </div>

        <div class="mt-2">
            <b-button
                v-if="refillCommissionPercentage"
                v-b-toggle.inviteGuys
                variant="light"
                class="border"
                block
                pill
            >
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        👨
                        {{ $t("Invite guys - earn") }}
                        {{ refillCommissionPercentage }}%
                        {{ $t("on every payment") }}
                    </div>
                    <font-awesome-icon icon="fa-light fa-plus" />
                </div>
            </b-button>
            <b-collapse id="inviteGuys">
                <h5 class="text-primary mt-3">
                    {{ $t("How to invite guys into the platform") }}
                </h5>
                <b-alert variant="danger" show class="small">
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                        size="lg"
                    />
                    {{
                        $t(
                            "Warning: do not put your invite URL directly in other dating apps, they may ban you for doing so"
                        )
                    }}
                </b-alert>
                <ol>
                    <li>
                        {{
                            $t(
                                "Meet guys on other social/dating apps who are located in USA, Canada, Australia & Europe. Ask them to send you their number for WhatsApp/Telegram"
                            )
                        }}
                    </li>
                    <li>
                        {{
                            $t(
                                "Become friends with them and develop their interest so they want to continue to talk to you, send them some teaser photo/video, don't send your URL right away"
                            )
                        }}
                    </li>
                    <li>
                        Once you have become friends, invite them to continue
                        chatting on {{ brand.name }} and send them your invite
                        URL, let them know they can chat you there and can view
                        more private photos/videos on Findmate
                    </li>
                </ol>

                <h5 class="text-primary mt-3">
                    {{ $t("Popular Apps in your country") }}
                </h5>

                <div
                    :key="popularApp.url"
                    v-for="popularApp in popularApps"
                    class="mt-2"
                >
                    <b-btn
                        block
                        target="_blank"
                        :href="popularApp.url"
                        variant="light"
                        pill
                    >
                        {{ popularApp.title }}
                        <font-awesome-icon
                            icon="fa-duotone fa-arrow-up-right-from-square"
                        />
                    </b-btn>
                </div>

                <h5 class="mt-3 text-primary">
                    {{ $t("Earn ") }}
                    {{ refillCommissionPercentage }}%
                    {{ $t("commission - every payment") }}
                </h5>
                <div>
                    {{
                        $t(
                            "Every time your invite pays for credits you will earn a commission. We will email you each time they buy credits, and let you know how much your reward was"
                        )
                    }}
                </div>
            </b-collapse>
        </div>

        <div class="mt-2">
            <b-button
                v-b-toggle.inviteLadies
                variant="light"
                class="border"
                block
                pill
            >
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        👸
                        {{ $t("Invite ladies - earn 10% of what they earn ") }}
                    </div>
                    <font-awesome-icon icon="fa-light fa-plus" />
                </div>
            </b-button>
            <b-collapse id="inviteLadies">
                <h5 class="mt-3 text-primary">
                    {{ $t("Invite your lady friends - earn 10%") }}
                </h5>
                <div class="mt-2">
                    {{
                        $t(
                            "When your lady friends signup using your invite URL, we will automatically connect your accounts. Every time your friend withdraws their credits to their bank, we'll automatically take 10% of those credits and put them in your wallet instantly, no waiting period"
                        )
                    }}
                </div>
                <div class="mt-2">
                    {{
                        $t(
                            "Train your friends how to use the app so they can be successful and earn plenty of credits, and you can earn credits from their efforts and your training. Also be sure to teach them how to invite their friends as well, and when they earn from their invited friends you will earn from those credits as well"
                        )
                    }}
                </div>
                <div class="mt-2">
                    {{
                        $t(
                            "The ladies who start inviting earliest, and start building their network can become very wealthy and can have unlimited accounts under their account, there is no limit on how many accounts you can have under you, or how much you can earn from this system, you can have thousands of ladies earning you credits for doing nothing other than having invited them"
                        )
                    }}
                </div>

                <div class="mt-3">
                    <h5 class="text-primary">
                        {{ $t("How much can I really make from this?") }}
                    </h5>
                    <div class="mt-2">
                        {{
                            $t(
                                "Lets say you invite 10 ladies, and they all earn 500 credits. That will earn you 50 credits each, meaning you get 500 credits just from your invites, not having to do any work at all"
                            )
                        }}
                    </div>
                    <div class="mt-2">
                        {{
                            $t(
                                "Now if you train those 10 ladies to also invite 10 ladies each on their own, that means you now have 100 ladies earning for you, and under that example they also earn 500 from their invites on top of their existing earnings, now you're earning 1000 credits"
                            )
                        }}
                    </div>
                    <div class="mt-2">
                        {{
                            $t(
                                "But it doesn't just stop there, in fact, there is no limit to how many levels it can go. If those 10 invite another 10, and then another 10, after some time you can have thousands if not millions of people earning you commissions just from your original invites, again, there is no limit to how large your network can grow"
                            )
                        }}
                    </div>
                    <div class="mt-2">
                        {{
                            $t(
                                "Imagine one of your invites 3 levels down happens to be a well known influencer with 100,000 followers and they post their URL and get thousands of signups. You may not know that influencer, but because the invites started at your network, you will get the benefits being the original person to invite all of them, and all of those commissions flow up to you in the end being the first person to invite"
                            )
                        }}
                    </div>
                </div>

                <div class="mt-3">
                    <h5 class="text-primary">
                        {{
                            $t(
                                "What if my friend just creates a new account so they don't have to pay the 10%?"
                            )
                        }}
                    </h5>
                    <div class="mt-2">
                        {{
                            $t(
                                "You should train your friends to also be inviting their friends, that will lock in their position in your network as an inviter account. All of their invites will be tied to your original invite to them, meaning they can't just create a new account at that point. If they create a new account after that they will have to start over and rebuild their network from scratch"
                            )
                        }}
                    </div>
                </div>
            </b-collapse>
        </div>

        <b-btn
            v-if="modal"
            @click="viewInfluencer"
            variant="primary"
            pill
            block
            size="lg"
            class="mt-3"
        >
            {{ $t("Finished Reading") }}
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    created() {
        this.$store.dispatch("creatorGuide/refresh");
    },
    computed: {
        ...mapState("brand", ["brand"]),
        ...mapState("creatorGuide", [
            "currentGuide",
            "guides",
            "popularApps",
            "refillCommissionPercentage",
        ]),
        modal: {
            get() {
                return this.$store.state.creatorGuide.currentGuide
                    ? true
                    : false;
            },
            set(value) {
                if (!value) {
                    this.$store.commit("creatorGuide/reset");
                }
            },
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        viewInfluencer() {
            // swizzle, can safely run both without issue
            this.$store.commit(
                "interface/setInfluencerGuideModalVisible",
                false
            );

            this.api.post("/creatorGuide/finishedInfluencer").then(() => {
                this.$store.dispatch("onboarding/status");
                this.$store.commit("creatorGuide/reset");
                this.$store.dispatch("creatorGuide/refresh");
            });
        },
    },
    components: {},
};
</script>


