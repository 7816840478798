<template>
    <div>
        <div class="border mx-2 px-1 p-2 rounded">
            <div class="d-flex align-items-center justify-content-between">
                <h6 class="mb-0">
                    {{ $t("Add to Post") }}
                </h6>
                <div class="d-flex">
                    <create-post-photo-button class="ml-1" />
                    <create-post-video-button class="ml-1" />
                    <create-post-gif-button v-if="false" class="ml-1" />
                </div>
            </div>

            <create-post-photos-uploading />
            <create-post-videos-uploading />
        </div>
    </div>
</template>

<script>
import CreatePostPhotosUploading from "@/components/post/create/CreatePostPhotosUploading";
import CreatePostVideosUploading from "@/components/post/create/CreatePostVideosUploading";

import CreatePostPhotoButton from "@/components/post/create/CreatePostPhotoButton";
import CreatePostVideoButton from "@/components/post/create/CreatePostVideoButton";
import CreatePostGifButton from "@/components/post/create/CreatePostGifButton";

export default {
    components: {
        CreatePostPhotoButton,
        CreatePostVideoButton,
        CreatePostGifButton,
        CreatePostPhotosUploading,
        CreatePostVideosUploading,
    },
};
</script>