<template>
    <div v-if="hasVisits">
        <sidebar-label>
            {{ $t("Last Visited Profile") }}
        </sidebar-label>

        <div class="px-3 mt-1">
            <b-btn
                variant="light"
                @click="viewProfile"
                v-if="filteredOutbound && filteredOutbound.length"
                class="
                    position-relative
                    fake-link
                    d-flex
                    align-items-center
                    text-left
                    justify-content-between
                "
                pill
                block
            >
                <div class="d-flex align-items-center text-left">
                    <div class="mr-2">
                        <img
                            class="rounded small-avatar"
                            :src="filteredOutbound[0].standardMap.photo"
                        />
                    </div>
                    <div>
                        <div>
                            {{ filteredOutbound[0].standardMap.name }}
                        </div>
                    </div>
                </div>
                <div class="small text-secondary">
                    <live-timestamp
                        :timestamp="filteredOutbound[0].lastVisit"
                    />
                </div>
            </b-btn>
        </div>
        <div v-if="view">
            <div
                class="
                    p-3
                    d-flex
                    align-items-center
                    justify-content-between
                    bg-primary
                    text-light
                "
            >
                <div>
                    <div class="small">{{ $t("Viewing Visits") }}</div>
                    <div v-if="view === 'match'">
                        {{ $t("Matched") }}
                    </div>
                    <div v-if="view === 'inbound'">
                        {{ $t("Received") }}
                    </div>
                    <div v-if="view === 'outbound'">
                        {{ $t("Sent") }}
                    </div>
                </div>
                <div>
                    <b-btn
                        variant="light"
                        @click="$store.commit('visit/close')"
                    >
                        <font-awesome-icon size="lg" icon="fa-light fa-times" />
                    </b-btn>
                </div>
            </div>
            <b-btn
                v-if="currentViewHasUnreadVisits"
                @click="$store.dispatch('visit/markAllSeen', view)"
                variant="light"
                class="rounded-0 border-0 py-3"
                block
                size="sm"
            >
                {{ $t("Mark All as Read") }}
            </b-btn>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.position-absolute {
    right: 0;
    top: 0;
}
</style>

<script>
import { mapState, mapGetters } from "vuex";

import SidebarLabel from "@/components/partial/SidebarLabel";

export default {
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapState("visit", ["view"]),
        ...mapGetters("visit", ["filteredOutbound"]),
        hasVisits() {
            return this.filteredOutbound.length;
        },
    },
    methods: {
        viewProfile() {
            this.$store.commit("interface/setRightSidebarVisible", false);

            this.$store.dispatch("profile/viewProfile", {
                userId: this.filteredOutbound[0].standardMap.id,
                profile: this.filteredOutbound[0].standardMap,
            });
        },
    },
    components: {
        SidebarLabel,
    },
};
</script>