<template>
    <div class="position-relative">
        <div v-if="userMedia.type === 'video'">
            <div v-if="preview && userMedia.videoHasPreviewThumbnail">
                <img
                    :class="{ blurGalleryPreview: this.blur }"
                    class="img-fluid rounded"
                    :src="preview ? userMedia.previewUrl : userMedia.url"
                    loading="lazy"
                />
                <div class="position-absolute absolute-center">
                    <font-awesome-icon
                        size="4x"
                        class="text-light"
                        icon="fa-duotone fa-circle-play"
                    />
                </div>
                <div class="position-absolute" style="bottom: 0; left: 0">
                    <b-badge variant="light" class="glass border" pill>
                        <font-awesome-icon icon="fa-duotone fa-camcorder" />
                        {{ $t("Video") }}
                    </b-badge>
                </div>
            </div>
            <video
                v-else
                loop
                playsinline
                muted
                autoplay
                class="img-fluid rounded"
                :class="{ blurGalleryPreview: this.blur }"
                :src="preview ? userMedia.previewUrl : userMedia.url"
                style="pointer-events: none"
            />
        </div>
        <div v-else>
            <img
                :class="{ blurGalleryPreview: this.blur }"
                class="img-fluid rounded"
                :src="preview ? userMedia.previewUrl : userMedia.url"
            />
            <div
                v-if="blur"
                class="position-absolute"
                style="bottom: 0; left: 0"
            >
                <b-badge variant="light" class="glass border" pill>
                    <font-awesome-icon
                        icon="fa-duotone fa-image-polaroid-user"
                    />
                    {{ $t("Photo") }}
                </b-badge>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.blurGalleryPreview {
    filter: blur(8px);
    overflow: hidden;
}
.absolute-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>


<script>
export default {
    props: ["userMedia", "size", "blur", "preview"],
};
</script>