<template>
    <div class="p-3">
        <div v-if="confirmed">
            <h5>
                {{ $t("Email yourself a link to log back in?") }}
            </h5>

            <b-alert show variant="primary" class="p-0 position-relative">
                <b-badge class="position-absolute" variant="primary">
                    {{ $t("Recommended") }}</b-badge
                >
                <div class="p-3 pt-4">
                    <div>
                        {{
                            $t(
                                "Do you want to email yourself a link that you can use later to automatically log back in?"
                            )
                        }}
                    </div>
                    <div class="mt-3">
                        <div v-if="sentLoginLink">
                            <font-awesome-icon
                                class="mr-1"
                                icon="fa-light fa-check"
                            />
                            {{ $t("Link sent") }}
                        </div>
                        <b-btn
                            v-else
                            variant="primary"
                            @click="loginLink"
                            size="lg"
                            block
                            pill
                        >
                            {{ $t("Yes, email me a link") }}
                        </b-btn>
                    </div>
                </div>
            </b-alert>

            <h5 class="mt-3">
                {{ $t("How to log back in") }}
            </h5>
            <div class="p-3 bg-light rounded">
                <div>
                    {{ $t("The email you used to signup is:") }}
                </div>
                <code>
                    {{ user.email }}
                </code>
            </div>
            <div class="mt-3">
                <b-btn @click="logout" variant="link" class="p-0">
                    {{ $t("Logout Now") }}
                </b-btn>
            </div>
        </div>
        <div v-else class="text-center">
            <div class="text-primary">
                <font-awesome-icon icon="fa-duotone fa-heart-crack" size="5x" />
            </div>

            <h5>Are you sure you want to logout?</h5>

            <div class="mt-4">
                <b-btn
                    @click="$store.dispatch('setting/close')"
                    variant="primary"
                    size="lg"
                    class="px-5"
                    pill
                >
                    {{ $t("Cancel") }}
                </b-btn>
            </div>

            <div class="small mt-3">
                <b-btn
                    @click="confirmed = true"
                    variant="link"
                    size="sm"
                    class="px-0 text-secondary"
                >
                    {{ $t("Yes I'm sure, log me out") }}
                </b-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            confirmed: null,
            sentLoginLink: null,
        };
    },
    computed: {
        ...mapState("onboarding", ["user"]),
    },
    methods: {
        loginLink() {
            this.api.post("/setting/loginLink");
            this.sentLoginLink = true;
        },
        logout() {
            this.$store.dispatch("user/logout");
            this.$store.dispatch("setting/close");
        },
    },
};
</script>

