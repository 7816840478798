<template>
    <div class="p-2">
        <back-to-dashboard />

        <div class="d-flex align-items-center justify-content-between">
            <h5 class="mb-0">
                {{ $t("Paid Sharing") }}
            </h5>
        </div>

        <b-alert variant="primary" show class="mt-2">
            <h5>How this works?</h5>
            <ul>
                <li>Create a new post/share on a social platform</li>
                <li>Send us your public URL for verification</li>
                <li>We verify the share using your URL, then pay you</li>
                <li>
                    Only verified shares from active social accounts can earn
                    rewards
                </li>
                <li>
                    Inactive accounts, or unverified shares will not earn a
                    reward
                </li>
                <li>
                    Verification may take up to 1 week, we will email you to let
                    you know the result
                </li>
            </ul>
        </b-alert>

        <b-list-group class="mt-2">
            <b-list-group-item
                v-for="paidShareType in paidShareTypes"
                :key="paidShareType.type"
                action
                @click="createShare(paidShareType)"
                class="d-flex align-items-center justify-content-between"
            >
                <div class="d-flex align-items-center justify-content-between">
                    <div style="width: 3rem" class="text-center">
                        <font-awesome-icon
                            class="mr-2 branded"
                            :icon="`fa-brands ${paidShareType.icon}`"
                            size="2x"
                        />
                    </div>
                    <div>
                        <h5 class="mb-0">
                            {{ paidShareType.name }}
                        </h5>
                        <div class="small">
                            {{ paidShareType.description }}
                        </div>
                    </div>
                </div>
                <div>
                    <font-awesome-icon
                        v-if="paidShareType?.existing?.url"
                        class="text-success"
                        icon="fa-light fa-check"
                    />
                    <coin-badge v-else :amount="paidShareType.amount" />
                </div>
            </b-list-group-item>
        </b-list-group>
    </div>
</template>

<script>
import BackToDashboard from "@/components/utility/BackToDashboard";

export default {
    data() {
        return {
            paidShareTypes: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        createShare(paidShareType) {
            this.error = null;

            if (paidShareType.existing) {
                this.$router.push({
                    name: "creatorPaidShareView",
                    params: {
                        id: paidShareType.existing.id,
                    },
                });
            } else {
                this.api
                    .post(`/paidShare/${paidShareType.type}/create`)
                    .then((response) => {
                        if (response?.data?.paidShare) {
                            this.$router.push({
                                name: "creatorPaidShareView",
                                params: {
                                    id: response.data.paidShare.id,
                                },
                            });
                        }
                    });
            }
        },
        refresh() {
            this.api.post("/paidShare/types").then((response) => {
                this.paidShareTypes = response.data.paidShareTypes;
            });
        },
    },
    components: {
        BackToDashboard,
    },
};
</script>