import api from "@/service/api";

const startPage = 1
// non, aka popular
const defaultView = null

const state = () => ({
    view: defaultView,
    feedItems: null,
    lowestSort: null,
    page: startPage,
    viewerDraftedCommentForCreatorPostId: [],
    hasMorePages: false,
    feedLoading: false,
})

const mutations = {
    reset(state) {
        state.page = startPage
        state.feedItems = null
        state.view = defaultView
        state.hasMorePages = false
        state.lowestSort = null
    },
    setView(state, value) {
        state.view = value
    },
    setFeedItems(state, value) {
        state.feedItems = value
    },
    updateSingleItemLocally(state, item) {
        if (!state.feedItems) {
            return;
        }

        state.feedItems = state.feedItems.map(feedItem => feedItem.id === item.id ? item : feedItem)
    },
    appendDraftedCommentCreatorPostId(state, creatorPostId) {
        state.viewerDraftedCommentForCreatorPostId.push(creatorPostId)
    },
    removeDraftedCommentCreatorPostId(state, creatorPostId) {
        state.viewerDraftedCommentForCreatorPostId = state.viewerDraftedCommentForCreatorPostId.filter(searchCreatorPostId => searchCreatorPostId !== creatorPostId)
    },
    nextPage(state) {
        state.page++;
    },
    previousPage(state) {
        state.page--;
    },
    setHasMorePages(state, value) {
        state.hasMorePages = value
    },
    setLowestSort(state, value) {
        state.lowestSort = value
    },
    setFeedLoading(state, value) {
        state.feedLoading = value
    },
}

const actions = {
    setView({ commit, dispatch }, view) {
        commit('reset')
        commit('setView', view)
        dispatch('refresh')
    },
    refresh({ commit, state }) {
        commit('setFeedLoading', true)

        api.post("/feed/index", {
            page: state.page,
            view: state.view,
            lowestSort: state.lowestSort
        })
            .then((response) => {
                if (response?.data?.feedItems) {
                    commit('setFeedItems', response.data.feedItems)

                    if (response.data.feedItems.length) {
                        commit('setLowestSort', response.data.feedItems[response.data.feedItems.length - 1].sort)
                    }
                }

                commit('setHasMorePages', response.data.hasMorePages)
            })
            .catch(() => { })
            .then(() => {
                commit('setFeedLoading', false)
            });
    },
    nextPage({ commit, dispatch }) {
        commit('setFeedItems', null)
        commit('nextPage')
        dispatch('refresh')
    },
    previousPage({ commit, dispatch }) {
        commit('setFeedItems', null)
        commit('previousPage')
        dispatch('refresh')
    },
}



const getters = {
    filteredItems(state, getters, rootState) {
        if (!state.feedItems) {
            return []
        }

        const unblockedPosts = state.feedItems
            .filter(function (item) {
                if (rootState.user.blockedUserIds.includes(item.userId)) {
                    return false;
                }

                if (item.frontendHandler === 'creatorPost'
                    && rootState.postIndex.blockedCreatorPostIds.includes(item.item.id)) {
                    return false;
                }

                return true;
            })

        return unblockedPosts
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
