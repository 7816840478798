<template>
    <div class="shadow-sm d-flex flex-column full-height-modal bg-light">
        <conversation-messages />
        <conversation-actions />
    </div>
</template>

<script>
import { mapState } from "vuex";

import ConversationMessages from "@/components/conversation/ConversationMessages";
import ConversationActions from "@/components/conversation/ConversationActions";

export default {
    created() {
        if (this.profile) {
            this.$store.dispatch("conversation/load", {
                userId: this.profile.id,
            });
        }
    },
    beforeDestroy() {
        this.$store.commit("conversation/reset");
    },
    watch: {
        profile(toProfile) {
            if (toProfile?.id !== this.$store.state.profile.profile?.id) {
                this.$store.dispatch("conversation/load", {
                    userId: toProfile.id,
                });
            }
        },
        visible(isVisible) {
            if (isVisible) {
                if (
                    this.$store.state.conversation
                        .currentConverstionPendingMarkSeen
                ) {
                    this.$store.dispatch("conversation/seen");
                }
            }
        },
    },
    computed: {
        ...mapState("conversation", [
            "messages",
            "loadingMessages",
            "unlocked",
        ]),
        ...mapState("profile", ["profileError", "profile"]),
        ...mapState("system", ["visible"]),
        ...mapState("user", ["premium"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        completeVerification() {
            this.$store.dispatch("setting/editOnboardingStep", "verification");
        },
        upgradePremium() {
            this.$store.dispatch("premium/upgrade", {
                source: "premium-required-for-interaction",
            });
        },
    },
    components: {
        ConversationMessages,
        ConversationActions,
    },
};
</script>
