<template>
    <div class="mt-2">
        <h5 class="m-0">
            {{ $t("Can we try to solve your problem?") }}
        </h5>
        <div class="mt-3">
            {{
                $t(
                    "We're not perfect, but we try our best to help ensure everyone has the best possible experience. We'd love to try and help troubleshoot your issue if you can provide some details about the problem, screenshots are a big help."
                )
            }}
        </div>
        <div class="mt-3">
            {{
                $t(
                    "We'll take a look into your issue and see if we can go ahead and just solve it. If we need more information, we may send you a message in the app to follow up."
                )
            }}
        </div>
        <div class="mt-2">
            <b-btn
                @click="$store.dispatch('delete/saveFailed')"
                variant="link"
                size="sm"
                class="text-secondary px-0"
            >
                {{ $t("No thanks, just delete") }}
            </b-btn>
        </div>
        <div class="mt-3">
            <b-btn
                @click="savedDelete"
                variant="primary"
                block
                size="lg"
                class="py-4"
            >
                {{ $t("Chat with Support") }}
            </b-btn>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        savedDelete() {
            this.$store.dispatch("delete/saved");
            this.$store.dispatch("support/contact");
        },
    },
};
</script>