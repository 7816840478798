<template>
    <div class="p-3">
        <div v-if="managingSubscription">
            <div>
                <b-btn
                    variant="link"
                    class="pl-0"
                    @click="manageSubscription(null)"
                >
                    <font-awesome-icon
                        size="lg"
                        icon="fa-light fa-chevron-left"
                    />
                    {{ $t("Back to All Subscriptions") }}
                </b-btn>
            </div>
            <div v-if="confirmCancel">
                <div class="py-3 lead">
                    {{
                        $t(
                            "Are you sure? You will no longer be able to view their private content"
                        )
                    }}
                </div>
                <div class="mt-3">
                    <b-btn
                        @click="confirmCancel = false"
                        variant="primary"
                        pill
                        size="lg"
                        block
                    >
                        {{ $t("No, I changed my mind") }}
                    </b-btn>
                </div>
                <div class="my-3">
                    <b-btn
                        @click="cancelSubscription"
                        variant="link"
                        class="p-0 text-secondary"
                        block
                    >
                        {{ $t("Yes I'm sure, cancel it") }}
                    </b-btn>
                </div>
            </div>
            <div v-else>
                <b-btn @click="confirmCancel = true" variant="light">
                    {{ $t("Cancel Subscription") }}
                </b-btn>
            </div>
        </div>
        <div v-else>
            <b-alert show variant="warning">
                <h5>Phasing out credit-based subscriptions</h5>
                <div class="small">
                    {{
                        $t(
                            "Old subscriptions will remain active as long as you have credits in your wallet to continue to keep the subscription current. Our new subscription process will be used for all new subscriptions going forward"
                        )
                    }}
                </div>
            </b-alert>
            <div
                @click="manageSubscription(subscription)"
                :key="subscription.id"
                v-for="subscription in subscriptions"
            >
                <user-standard-avatar
                    :nonPrimaryPhotos="true"
                    :userBetweenStatus="true"
                    :timestamp="subscription.created"
                    :standardMap="subscription.standardMap"
                    class="border-light border-top"
                    size="small"
                >
                    <div
                        class="d-flex align-items-center justify-content-center"
                    >
                        <font-awesome-icon
                            icon="fa-duotone fa-coin"
                            class="mr-1 text-warning"
                            size="lg"
                        />
                        <div class="text-warning">
                            {{ subscription.price }}
                            {{ $t("credits") }}
                        </div>
                    </div>
                </user-standard-avatar>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import UserStandardAvatar from "@/components/user/UserStandardAvatar";

export default {
    data() {
        return {
            confirmCancel: false,
        };
    },
    created() {
        this.$store.dispatch("manageSubscriptions/refresh");
    },
    computed: {
        ...mapState("onboarding", ["user"]),
        ...mapState("manageSubscriptions", [
            "subscriptions",
            "managingSubscription",
        ]),
    },
    methods: {
        manageSubscription(subscription) {
            this.$store.dispatch("manageSubscriptions/manage", subscription);
        },
        cancelSubscription() {
            this.$store.dispatch("manageSubscriptions/cancel");
            this.$store.dispatch("onboarding/status");
        },
    },
    components: {
        UserStandardAvatar,
    },
};
</script>

