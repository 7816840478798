<template>
    <b-btn
        v-if="hasIncompleteGuides"
        to="/creator/guides"
        class="bg-white"
        variant="light"
        block
        size="lg"
    >
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="mr-2">
                    <font-awesome-icon
                        class="branded"
                        icon="fa-duotone fa-info-circle"
                        size="lg"
                    />
                </div>
                New User Guides
            </div>
            <div class="text-primary">
                <b-badge variant="danger" pill>
                    {{ incompleteGuides.length }}
                </b-badge>
            </div>
        </div>
    </b-btn>
</template>


<script>
import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        ...mapGetters("creatorGuide", [
            "hasIncompleteGuides",
            "incompleteGuides",
        ]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
};
</script>
