<template>
    <div>
        <afa-users-asian />
    </div>
</template>

<script>
import AfaUsersAsian from "@/components/ads/searchBottom/AfaUsersAsian";

export default {
    components: {
        AfaUsersAsian,
    },
};
</script>


