<template>
    <div>
        <b-alert
            v-if="videoModerationInProgress"
            show
            variant="primary"
            class="small mx-2"
        >
            <b-spinner small />
            <div class="mt-2">
                {{
                    $t(
                        "Video moderation is still in progress, this may take several minutes"
                    )
                }}
            </div>
            <div class="mt-2">
                {{
                    $t("Would you like to post it automatically once approved")
                }}
            </div>
            <div class="mt-3">
                <b-btn variant="primary" pill @click="publishPost">
                    {{ $t("Yes, Post when Finished") }}
                </b-btn>
            </div>
        </b-alert>
        <div
            v-if="displayVideo"
            class="px-2 fake-link position-relative"
            @click="manageVideo(creatorPost.video)"
        >
            <div
                v-if="videoModerationInProgress"
                class="fake-link private-wrapper text-primary rounded position-absolute absolute-center text-center"
            >
                <b-spinner variant="primary" />
                <div class="private-wrapper-label">{{ $t("Moderating") }}</div>
            </div>
            <span
                v-else-if="!creatorPost.video.approved"
                class="private-wrapper position-absolute absolute-center text-warning text-center small rounded p-4"
            >
                <font-awesome-icon icon="fa-duotone fa-lock" size="5x" />
                <h3 class="my-3">
                    {{ $t("Private") }}
                </h3>
                <div>
                    {{ $t("Purchase to view") }}
                </div>
            </span>

            <video-player
                :muted="true"
                :controls="true"
                :autoplay="true"
                :loop="true"
                :mp4Url="creatorPost.video.mp4Url"
                :webmUrl="creatorPost.video.webmUrl"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import VideoPlayer from "@/components/utility/VideoPlayer";

export default {
    computed: {
        displayVideo() {
            return (
                !this.previewPost &&
                !this.creatorPost?.gif &&
                this.creatorPost?.video &&
                !this.managingPhoto
            );
        },
        ...mapState("creatorPostEdit", [
            "creatorPost",
            "previewPost",
            "managingPhoto",
        ]),
        ...mapState("createPostVideo", ["videoModerationInProgress"]),
    },
    methods: {
        publishPost() {
            this.$store.dispatch("creatorPostEdit/publishQuietly");
        },
        manageVideo(video) {
            console.log(video);
            // this.$store.dispatch("creatorPostEdit/managePhoto", photo);
        },
    },
    components: {
        VideoPlayer,
    },
};
</script>
