<template>
    <generic-page>
        <b-btn variant="link" size="lg" class="pl-0" to="/creator/paidShare">
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            {{ $t("Paid Sharing") }}
        </b-btn>

        <div v-if="paidShare">
            <div class="d-flex align-items-center">
                <div>
                    <h5 class="mb-0"></h5>
                    <div class="small">
                        {{ paidShare.typeDetails.description }}
                    </div>
                </div>
            </div>

            <b-list-group>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    Type
                    <div>
                        <font-awesome-icon
                            class="branded"
                            :icon="`fa-brands ${paidShare.typeDetails.icon}`"
                        />
                        {{ paidShare.typeDetails.name }}
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    Reward Amount
                    <coin-badge :amount="paidShare.typeDetails.amount" />
                </b-list-group-item>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    Status
                    <div v-if="paidShare.verified === 1">
                        <font-awesome-icon
                            class="mr-1 text-success"
                            icon="fa-light fa-check"
                        />
                        Rewarded
                    </div>
                    <div v-else-if="paidShare.verified === 0">
                        Link not found
                    </div>
                    <div v-else>Pending verification</div>
                </b-list-group-item>
            </b-list-group>

            <div v-if="creatorUser" class="mt-3">
                <div class="d-flex align-items-center">
                    <div class="circle-list mr-2">1</div>
                    <h5 class="mb-0">Share Your Link</h5>
                </div>

                <div class="small text-primary mt-2">
                    Share your URL with a message (recommended)
                </div>
                <b-input-group size="sm">
                    <b-textarea
                        :value="shareMessage"
                        no-resize
                        readonly
                        rows="3"
                    >
                    </b-textarea>

                    <b-input-group-append>
                        <b-btn v-if="copiedMessage" variant="success">
                            <font-awesome-icon icon="fa-light fa-check" />
                        </b-btn>
                        <b-btn v-else @click="copyMessage" variant="primary">
                            Copy
                        </b-btn>
                    </b-input-group-append>
                </b-input-group>

                <div class="small text-primary mt-2">Copy URL only</div>
                <b-input-group size="sm">
                    <b-form-input
                        no-resize
                        :value="creatorUser.inviteUrlVanity"
                        readonly
                    />
                    <b-input-group-append>
                        <b-btn v-if="copiedUrl" variant="success">
                            <font-awesome-icon icon="fa-light fa-check" />
                        </b-btn>
                        <b-btn v-else @click="copyUrl" variant="primary">
                            Copy
                        </b-btn>
                    </b-input-group-append>
                </b-input-group>

                <b-alert
                    v-if="hasCopied"
                    variant="success"
                    show
                    class="small mt-2 mb-0"
                >
                    <h5>
                        <font-awesome-icon icon="fa-light fa-check" />
                        {{ $t("Copied") }}
                    </h5>
                    {{ $t("URL has been copied, now share your private link") }}
                </b-alert>
            </div>

            <div>
                <div class="d-flex align-items-center mt-3">
                    <div class="circle-list mr-2">2</div>
                    <h5 class="mb-0">Provide Verification</h5>
                </div>

                <div class="mt-2">
                    <div v-if="paidShare.url">
                        <div class="bg-white p-2 rounded">
                            <font-awesome-icon
                                class="mr-1 text-success"
                                icon="fa-light fa-check-circle"
                            />
                            {{ paidShare.url }}
                        </div>
                    </div>
                    <div v-else>
                        <b-alert variant="primary" show class="small">
                            <font-awesome-icon
                                icon="fa-duotone fa-info-circle"
                            />
                            This can be the URL of new post, or it can be the
                            URL of your social profile, as long as we can follow
                            your provided link and verify your URL was shared,
                            it will be accepted
                        </b-alert>

                        <b-alert variant="danger" show v-if="error">
                            {{ error }}
                        </b-alert>

                        <b-input-group>
                            <b-form-input
                                placeholder="URL where we can verify the post"
                                v-model="url"
                            />
                            <b-input-group-append>
                                <b-btn @click="submitUrl" variant="primary">
                                    Submit
                                </b-btn>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";
import copy from "clipboard-copy";

export default {
    data() {
        return {
            url: null,
            error: null,
            paidShare: null,
            copiedMessage: false,
            copiedUrl: false,
        };
    },
    created() {
        this.$store.dispatch("creatorDashboard/refresh");

        if (this.$route.params?.id) {
            this.get(this.$route.params.id);
        }
    },
    watch: {
        $route(to) {
            if (to?.params?.id !== this.paidShare?.id) {
                this.get(to.params.id);
            }
        },
    },
    computed: {
        shareMessage() {
            const url = this.creatorUser.inviteUrlVanity;
            return `Hey everyone 👋 I just signed up for ${this.brand.name}, join me here: ${url}`;
        },
        ...mapState("brand", ["brand"]),
        ...mapState("creatorDashboard", ["creatorUser"]),
        ...mapState("creatorInvite", [
            "inviteUrl",
            "inviteUrlVanity",
            "hasCopied",
        ]),
    },
    methods: {
        copyMessage() {
            copy(this.shareMessage);

            this.copiedMessage = true;

            setTimeout(() => {
                this.copiedMessage = false;
            }, 2000);
        },
        copyUrl() {
            copy(this.creatorUser.inviteUrl);

            this.copiedUrl = true;

            setTimeout(() => {
                this.copiedUrl = false;
            }, 2000);
        },
        get(id) {
            this.api.post(`/paidShare/${id}/get`).then((response) => {
                this.paidShare = response?.data?.paidShare;
            });
        },
        submitReview() {
            this.api
                .post(`/paidShare/${this.paidShare.id}/review`)
                .then((response) => {
                    this.paidShare = response?.data?.paidShare;
                });
        },
        submitUrl() {
            this.error = null;

            this.api
                .post(`/paidShare/${this.paidShare.id}/url`, {
                    url: this.url,
                })
                .then((response) => {
                    this.paidShare = response?.data?.paidShare;
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error = error.response.data.message;
                    }
                });
        },
    },
};
</script>

