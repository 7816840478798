<template>
    <div>
        <b-alert show v-if="withdrawalError" variant="warning">
            {{ withdrawalError }}
        </b-alert>

        <div v-if="hasSubmittedWithdraw">
            <b-alert show variant="primary">
                <h5 class="d-flex align-items-center">
                    {{ $t("Your money is on the way") }}
                </h5>

                <div class="mt-3">
                    <font-awesome-icon
                        icon="fa-light fa-check"
                        class="text-success"
                    />
                    {{
                        $t(
                            "Credits have been withdrawn from your wallet, and are now pending payout"
                        )
                    }}
                </div>
                <div class="mt-3">
                    {{
                        $t(
                            "We have been notified of the request, please allow up to 48 hours for processing. We will email you once your withdrawal has been processed"
                        )
                    }}
                </div>

                <div class="small text-secondary mt-3">
                    {{
                        $t(
                            "If you don't see an email after 48 hours be sure to check your spam folder, and be sure to mark the email 'Not Spam' so they are not flagged in the future"
                        )
                    }}
                </div>
            </b-alert>
        </div>
        <div v-else-if="hasDraftWithdraw">
            <b-alert variant="warning" show>
                <h5>Complete Your Withdrawal</h5>
                <div class="small">
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                    />
                    Your withdrawal has not been submitted yet, please complete
                    all fields and submit it to withdraw your money
                </div>

                <div>
                    <b-btn
                        @click="editDraft"
                        variant="primary"
                        pill
                        class="mt-3"
                        size="lg"
                        block
                    >
                        Finish Withdrawal
                    </b-btn>
                </div>

                <div>
                    <b-btn
                        @click="cancelWithdraw"
                        variant="light"
                        pill
                        class="mt-3"
                        size="sm"
                        block
                    >
                        Cancel and return
                        <coin-badge :amount="currentWithdraw.amount" /> to
                        wallet
                    </b-btn>
                </div>
            </b-alert>
        </div>
        <div v-else-if="canWithdraw">
            <b-alert show variant="primary">
                <font-awesome-icon
                    icon="fa-light fa-check"
                    class="text-success"
                />
                You're able to withdraw funds now

                <div class="mt-3">
                    <b-btn @click="withdrawMoney" variant="primary" pill>
                        Withdraw Money
                    </b-btn>
                </div>
            </b-alert>
        </div>
        <div v-else>
            <b-alert variant="primary" show>
                <div class="font-weight-bold">
                    Credits required to withdraw: {{ progress }}%
                </div>
                <b-progress :max="100">
                    <b-progress-bar :value="progress"> </b-progress-bar>
                </b-progress>
                <div
                    v-if="!onboardingUser.guideHowDoIGetMyMoneyComplete"
                    class="mt-3"
                >
                    <div
                        class="d-flex alignn-items-center justify-content-between"
                    >
                        <h5 class="mb-0">How do I get my money?</h5>
                        <div>
                            <b-btn
                                @click="dismissMessage"
                                variant="link"
                                size="lg"
                                class="p-0"
                            >
                                <font-awesome-icon
                                    icon="fa-light fa-times"
                                    class="mr-1"
                                />
                            </b-btn>
                        </div>
                    </div>
                    <div>
                        Once the progress bar below reaches 100% you will be
                        able to submit your withdrawal and select how you want
                        to get paid
                    </div>
                </div>
            </b-alert>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    created() {
        this.$store.dispatch("withdraw/getCurrent");
    },
    computed: {
        hasSubmittedWithdraw() {
            return this.currentWithdraw?.submitted;
        },
        hasDraftWithdraw() {
            return this.currentWithdraw?.id ? true : false;
        },
        canWithdraw() {
            return this.balance >= this.onboardingUser.minimumWithdrawCredits;
        },
        progress() {
            const rawProgress =
                this.balance / this.onboardingUser.minimumWithdrawCredits;

            return Math.round(rawProgress * 100);
        },
        ...mapState("withdraw", [
            "withdrawalError",
            "successMessage",
            "currentWithdraw",
        ]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        balance() {
            return this.onboardingUser?.wallet?.balance;
        },
        balanceLocal() {
            return this.onboardingUser?.wallet?.balanceLocal;
        },
    },
    methods: {
        cancelWithdraw() {
            if (
                confirm(
                    "Are you sure you want to cancel this withdrawal? You will need to start over."
                )
            ) {
                this.$store.commit(
                    "withdraw/setWithdraw",
                    this.currentWithdraw
                );
                this.$store.dispatch("withdraw/cancel");
            }
        },
        dismissMessage() {
            this.api
                .post("/userFlag/guideHowDoIGetMyMoneyComplete")
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                });
        },
        editDraft() {
            this.$store.dispatch("withdraw/setWithdraw", this.currentWithdraw);
        },
        withdrawMoney() {
            this.$store.dispatch("withdraw/create");
        },
    },
    components: {},
};
</script>