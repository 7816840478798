<template>
    <b-btn
        v-if="currentJackpot"
        to="/creator/jackpot"
        class="bg-white"
        variant="light"
        block
        size="lg"
    >
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="mr-2">
                    <font-awesome-icon
                        class="small-avatar branded"
                        icon="fa-duotone fa-sack-dollar"
                        size="lg"
                    />
                </div>
                Jackpot
            </div>
            <div class="small">
                {{ currentJackpot.balanceLocal }}
            </div>
        </div>
    </b-btn>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("creatorDashboard", ["currentJackpot"]),
    },
};
</script>
