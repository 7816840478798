import api from "@/service/api";

const state = () => ({
    view: null,
    outbound: null,
    inbound: null,
    match: null,
    outboundUserIds: [],
})

const mutations = {
    setVisits(state, visits) {
        if (!visits) {
            return;
        }

        state.outbound = visits.outbound;
        state.inbound = visits.inbound;
        state.match = visits.match;
        state.outboundUserIds = visits.outboundUserIds;
    },
    append(state, data) {
        state.outbound = state.outbound ? state.outbound : [];

        state.outbound = [
            data.mapForFromUser,
            ...state.outbound
        ];
    },
}

const actions = {
    refresh({ commit }) {
        api.post("/visit/get")
            .then((response) => {
                if (response?.data?.visits) {
                    commit('setVisits', response.data.visits)
                }
            });
    },
}

const getters = {
    filteredOutbound(state, getters, rootState) {
        return state.outbound ? state.outbound.filter(visit => !rootState.user.blockedUserIds.includes(visit.standardMap.id)) : [];
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
