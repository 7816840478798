<template>
    <div class="floating-menu-bar">
        <div v-if="reported">
            <b-alert show variant="primary" class="border-0">
                {{
                    $t(
                        "Thank you for reporting the post, we will take a look and take any appropriate actions"
                    )
                }}
            </b-alert>

            <b-btn variant="primary" block size="lg" @click="closePhoto">
                {{ $t("Close") }}
            </b-btn>
        </div>

        <div class="d-flex align-items-center justify-content-between p-2">
            <div>
                <b-dropdown
                    v-if="currentPhoto"
                    variant="light"
                    toggle-class="btn-brand rounded-pill btn-lg"
                >
                    <template #button-content>
                        <font-awesome-icon icon="fa-light fa-bars" />
                    </template>

                    <b-dropdown-item
                        v-if="currentPhotoIsMine"
                        @click="deletePhoto"
                        >{{ $t("Delete") }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        v-if="!currentPhotoIsMine"
                        @click="reportPost"
                    >
                        <div>{{ $t("Report Photo") }}</div>
                        <div class="small text-secondary">
                            {{
                                $t(
                                    "Let us know if you think this post is inappropriate"
                                )
                            }}
                        </div>
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="currentPhotoIsMine && !currentPhotoIsPrimary"
                        @click="setPrimary"
                    >
                        <div>{{ $t("Set Primary") }}</div>

                        <div class="small text-secondary">
                            {{ $t("Use this photo as the primary photo") }}
                        </div>
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="currentPhotoIsMine && currentPhotoIsPrimary"
                        @click="removePrimary"
                    >
                        <div>{{ $t("Remove as Primary") }}</div>
                        <div class="small text-secondary">
                            {{
                                $t(
                                    "Will not delete photo, just removes it as the primary"
                                )
                            }}
                        </div>
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <div>
                <b-btn
                    @click="closePhoto"
                    variant="light"
                    class="action-button glass border-0"
                >
                    <font-awesome-icon size="lg" icon="fa-light fa-times" />
                </b-btn>
            </div>
        </div>
    </div>
</template>


<script>
import { mapGetters, mapState } from "vuex";

export default {
    data() {
        return {
            reported: false,
        };
    },
    computed: {
        ...mapGetters("postPhoto", [
            "currentPhotoIsMine",
            "currentPhotoIsPrimary",
        ]),
        ...mapState("postPhoto", ["currentPhoto", "currentPost"]),
    },
    methods: {
        deletePhoto() {
            this.api
                .post("/post/deletePhoto", {
                    creatorPostPhotoId: this.currentPhoto.id,
                })
                .then(() => {
                    this.$store.dispatch("postPhoto/refresh");
                    this.$store.dispatch("creatorPostEdit/refresh");
                });
        },
        reportPost() {
            this.api
                .post("/post/report", {
                    creatorPostId: this.currentPost.id,
                })
                .then(() => {
                    this.$store.commit(
                        "postIndex/blockCreatorPostId",
                        this.currentPost.id
                    );

                    this.$store.commit(
                        "interface/setPostPhotoModalVisible",
                        false
                    );

                    this.$store.commit("postPhoto/reset");
                });
        },
        closePhoto() {
            this.$store.commit("postPhoto/reset");
            this.$store.commit("interface/setPostPhotoModalVisible", false);
        },
        setPrimary() {
            this.api
                .post("/post/setPhotoPrimary", {
                    creatorPostPhotoId: this.currentPhoto.id,
                })
                .then(() => {
                    this.$store.dispatch("postPhoto/refresh");
                    this.$store.dispatch("creatorPostEdit/refresh");
                });
        },
        removePrimary() {
            this.api
                .post("/post/removePhotoPrimary", {
                    creatorPostPhotoId: this.currentPhoto.id,
                })
                .then(() => {
                    this.$store.dispatch("postPhoto/refresh");
                    this.$store.dispatch("creatorPostEdit/refresh");
                });
        },
    },
    components: {},
};
</script>