<template>
    <div>
        <fitty class="my-3" :options="{ maxSize: 21 }">
            <template v-slot:content>
                {{ $t("Where do you want to meet people?") }}
            </template>
        </fitty>

        <div v-if="chooseCountries" class="text-left">
            <b-form-group>
                <b-btn
                    variant="link"
                    @click="chooseCountries = false"
                    class="px-0"
                >
                    <font-awesome-icon
                        size="lg"
                        icon="fa-light fa-chevron-left"
                    />
                    {{ $t("Cancel Selecting Countries") }}
                </b-btn>
            </b-form-group>

            <setting-blocked-countries />
        </div>
        <div v-else>
            <b-btn
                @click="selectOption(option)"
                variant="light"
                class="btn-brand p-3"
                :key="option.title"
                v-for="option in options"
                block
            >
                <div
                    class="
                        d-flex
                        align-items-center
                        justify-content-between
                        text-left
                    "
                >
                    <div class="mr-3">
                        <span v-if="option.selected" class="text-primary">
                            <font-awesome-icon
                                class="mr-1"
                                icon="fa-solid fa-check-circle"
                            />
                        </span>
                        <span v-else>
                            <font-awesome-icon
                                class="mr-1"
                                icon="fa-solid fa-circle"
                            />
                        </span>
                    </div>
                    <div class="flex-grow-1">
                        <h5 class="mb-0">
                            {{ option.title }}
                        </h5>
                        <div class="text-secondary">
                            {{ option.description }}
                        </div>

                        <div v-if="!option.value" class="text-success small">
                            ({{ $t("Recommended") }})
                        </div>
                    </div>
                </div>
            </b-btn>
        </div>

        <div class="mt-3">
            <b-btn @click="save" variant="primary" block size="lg">
                {{ $t("Save") }}
            </b-btn>
        </div>

        <b-form-group
            v-if="
                !chooseCountries && (!selectedOption || !selectedOption.value)
            "
        >
            <b-btn
                @click="chooseCountries = true"
                variant="link"
                class="mt-3"
                block
            >
                {{ $t("Choose Specific Countries") }}
            </b-btn>
        </b-form-group>
    </div>
</template>


<script>
import { mapState } from "vuex";

import SettingBlockedCountries from "@/components/setting/SettingBlockedCountries";

export default {
    data() {
        return {
            excludedCountries: null,
            chooseCountries: false,
            selectedOption: null,
            options: [
                {
                    selected: true,
                    value: null,
                    title: this.$t("Worldwide"),
                    description: this.$t("I want to meet people anywhere"),
                },
                {
                    selected: false,
                    value: 1,
                    title: this.$t("My Country"),
                    description: this.$t(
                        "I only want to meet people in my country"
                    ),
                },
            ],
        };
    },
    mounted() {
        if (this.user.countryScope) {
            this.selectOption(
                this.options.find(
                    (option) => option.value === this.user.countryScope
                )
            );
        }
    },
    computed: {
        ...mapState("onboarding", ["user"]),
    },
    methods: {
        updatedExcludedCountries(excludedCountries) {
            this.excludedCountries = excludedCountries;
        },
        selectOption(option) {
            this.options = this.options.map((searchOption) =>
                searchOption.value === option.value
                    ? { ...searchOption, selected: true }
                    : { ...searchOption, selected: false }
            );

            this.selectedOption = option;
        },
        async save() {
            this.api
                .post("/setting/countryScope", {
                    countryScope: this.selectedOption?.value,
                })
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                });
        },
    },
    components: {
        SettingBlockedCountries,
    },
};
</script>