var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"small text-secondary mt-3"},[_vm._v(" "+_vm._s(_vm.$t("By pressing "))+" \""+_vm._s(_vm.targetButtonLabel)+"\" "+_vm._s(_vm.$t(" you hereby certify that you have read and agree to our"))+" "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.$store.dispatch(
                    'system/openBrowser',
                    'https://findmate.app/content/terms'
                )}}},[_vm._v(" "+_vm._s(_vm.$t("Terms and Conditions"))+" ")]),_vm._v(" "+_vm._s(_vm.$t(" and "))+" "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.$store.dispatch(
                    'system/openBrowser',
                    'https://findmate.app/content/privacy'
                )}}},[_vm._v(" "+_vm._s(_vm.$t("Privacy Policy"))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }