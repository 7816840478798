<template>
    <div class="vh-50">
        <div
            :key="user.standardMap.id"
            v-for="user in blockedUsers"
            class="
                striped
                p-3
                d-flex
                align-items-center
                justify-content-between
            "
        >
            <div
                class="d-flex align-items-center"
                @click="viewProfile(user.standardMap)"
            >
                <img class="mr-1" :src="user.standardMap.photo" />
                {{ user.standardMap.name }}
            </div>
            <b-btn variant="light" @click="unblockUser(user.standardMap.id)">
                <div v-if="currentlyUnblocking === user.standardMap.id">
                    <b-spinner small />
                </div>
                <div v-else>
                    {{ $t("Unblock") }}
                </div>
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            currentlyUnblocking: null,
            blockedUsers: null,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapState("onboarding", ["user"]),
    },
    methods: {
        viewProfile(standardMap) {
            this.$store.dispatch("profile/viewProfile", {
                userId: standardMap.id,
                profile: standardMap,
            });
        },
        async refresh() {
            await this.api.post("/block/getUsers", {}).then((response) => {
                if (response?.data?.users) {
                    this.blockedUsers = response.data.users;
                }
            });

            this.currentlyUnblocking = null;
        },
        async unblockUser(userId) {
            this.currentlyUnblocking = userId;

            await this.api.post("/block/unblockUser", { userId });
            this.refresh();
            this.$store.dispatch("onboarding/status");
            this.$store.dispatch("user/unblock", userId);
        },
    },
};
</script>