<template>
    <b-table-simple bordered class="mt-2">
        <tbody>
            <tr>
                <td class="w-66">Credits</td>
                <td class="bg-white">
                    {{ balance }}
                </td>
            </tr>
            <tr>
                <td>Withdrawal Minimum</td>
                <td class="bg-white">
                    <span>
                        {{ onboardingUser.minimumWithdrawCredits }}
                    </span>
                    credits
                </td>
            </tr>
            <tr>
                <td>
                    {{ onboardingUser.currency }}
                    Balance
                </td>
                <td class="bg-white">
                    <span class="text-success font-weight-bold">
                        ~{{ balanceLocal }}
                        {{ onboardingUser.currency }}
                    </span>
                </td>
            </tr>
        </tbody>
    </b-table-simple>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        hasSubmittedWithdraw() {
            return this.currentWithdraw?.submitted;
        },
        hasDraftWithdraw() {
            return this.currentWithdraw?.id ? true : false;
        },
        canWithdraw() {
            return this.balance >= this.onboardingUser.minimumWithdrawCredits;
        },
        progress() {
            const rawProgress =
                this.balance / this.onboardingUser.minimumWithdrawCredits;

            return Math.round(rawProgress * 100);
        },
        ...mapState("withdraw", [
            "withdrawalError",
            "successMessage",
            "currentWithdraw",
        ]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        balance() {
            return this.onboardingUser?.wallet?.balance;
        },
        balanceLocal() {
            return this.onboardingUser?.wallet?.balanceLocal;
        },
    },
    components: {},
};
</script>