<template>
    <div>
        <b-btn
            @click="showQrcode"
            variant="light"
            block
            pill
            size="sm"
            class="text-truncate"
        >
            <font-awesome-icon icon="fa-light fa-qrcode" />
            {{ $t("QR Code") }}
        </b-btn>

        <b-modal
            v-model="modal"
            hide-footer
            hide-header
            centered
            
            body-class="bg-light rounded-lg"
            modal-class="modal-height-100"
        >
            <div class="col-12 col-md-3 mx-auto text-center">
                <h3>
                    {{ $t("Chat with") }}
                    {{ onboardingUser.name }}
                </h3>

                <img
                    v-if="onboardingUser.photoMedium"
                    :src="onboardingUser.photoMedium"
                    class="w-100 rounded thumbnail"
                />

                <div class="mt-3">
                    {{ onboardingUser.name }}
                    {{
                        $t(
                            "has invited you to chat with them, scan the code below to join them"
                        )
                    }}
                </div>

                <div class="text-center">
                    <img v-if="qrcode" :src="qrcode" />
                </div>

                <b-btn @click="closeModal" variant="light" pill size="sm">
                    {{ $t("Close Screen") }}
                </b-btn>
            </div>
        </b-modal>
    </div>
</template>



<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            qrcode: null,
        };
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        modal: {
            get() {
                return this.$store.state.interface
                    .creatorShareQrcodeModalVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setCreatorShareQrcodeModalVisible",
                    value
                );
            },
        },
    },
    methods: {
        closeModal() {
            this.$store.commit(
                "interface/setCreatorShareQrcodeModalVisible",
                false
            );
        },
        showQrcode() {
            this.$store.commit(
                "interface/setCreatorShareQrcodeModalVisible",
                true
            );

            this.api.post("/invite/qrcode").then((response) => {
                if (response?.data?.qrcode) {
                    this.qrcode = response.data.qrcode;
                }
            });
        },
    },
};
</script>