import api from "@/service/api";

const state = () => ({
    page: 1,
    refreshing: false,
    hasMorePages: false,
    messagePhotos: [],
    sentPhotoKeys: [],
    errors: [],
})

const mutations = {
    reset(state) {
        state.sentPhotoKeys = []
        state.messagePhotos = []
        state.refreshing = false
        state.hasMorePages = false
        state.page = 1
        state.errors = []
    },
    appendError(state, value) {
        state.errors.push(value)
    },
    removeErrorByInstanceId(state, value) {
        state.errors = state.errors.filter(error => error.instanceId !== value)
    },
    setRefreshing(state, value) {
        state.refreshing = value
    },
    setSentPhotoKeys(state, value) {
        state.sentPhotoKeys = value
    },
    setHasMorePages(state, value) {
        state.hasMorePages = value
    },
    setPage(state, value) {
        state.page = value
    },
    setMessagePhotos(state, value) {
        state.messagePhotos = value
    },
}

const actions = {
    refresh({ commit, state, rootState }) {
        commit('setRefreshing', true)

        api
            .post("/media/index", {
                page: state.page,
                userId: rootState.profile.profile.id
            })
            .then((response) => {
                commit('setMessagePhotos', response.data.messagePhotos)
                commit('setHasMorePages', response.data.hasMorePages)
                commit('setSentPhotoKeys', response.data.sentPhotoKeys)
            })
            .catch(() => { })
            .then(() => {
                commit('setRefreshing', false)
            });
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}