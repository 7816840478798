<template>
    <div @click="google" class="login-button bg-primary text-white">
        <div class="icon-block bg-white text-dark">
            <font-awesome-icon icon="fa-brands fa-google" />
        </div>
        {{ actionLabel }}
        {{ $t("with Google") }}
        <div class="invisible icon-block">
            <font-awesome-icon icon="fa-duotone fa-envelope" />
        </div>
    </div>
</template>

<script>
/* global FirebasePlugin */

import { mapGetters, mapState } from "vuex";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import { LAST_LOGIN_METHOD } from "@/constant/localStorage";

export default {
    created() {},
    computed: {
        currentMode() {
            return this.$route.meta.mode;
        },
        actionLabel() {
            if (this.currentMode === "signup") {
                return this.$t("Signup");
            }

            return this.$t("Login");
        },
        ...mapState("login", ["method"]),
        ...mapState("system", ["cordova"]),
        ...mapGetters("user", ["isLoggedIn"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        defaultPage() {
            this.$router.push({ name: "defaultPage" });
        },
        setLastLoginMethod(method) {
            localStorage.setItem(LAST_LOGIN_METHOD, method);
        },
        google() {
            if (this.cordova) {
                // production
                const googleOauthId =
                    "25569898344-ca0du3hbvo45kqrknmuikt9q4lcrnt0p.apps.googleusercontent.com";

                FirebasePlugin.authenticateUserWithGoogle(
                    googleOauthId,
                    (credential) => {
                        FirebasePlugin.signInWithCredential(
                            credential,
                            () => {
                                this.$store.dispatch(
                                    "appEvent/create",
                                    "login_success_google"
                                );

                                this.loadCurrentUser();

                                this.setLastLoginMethod("google");
                            },
                            (error) => {
                                console.log(1);
                                console.log(error);
                                this.$store.dispatch(
                                    "appEvent/create",
                                    "login_failure_google"
                                );
                            }
                        );
                    },
                    (error) => {
                        console.log(2);
                        console.log(error);
                        this.$store.dispatch(
                            "appEvent/create",
                            "login_failure_google"
                        );
                    }
                );
            } else {
                const provider = new GoogleAuthProvider();
                const auth = getAuth();

                signInWithPopup(auth, provider)
                    .then((result) => {
                        result.user.getIdToken().then((idToken) => {
                            this.setLastLoginMethod("google");
                            this.loginFromIdToken(idToken);
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },
        loadCurrentUser() {
            FirebasePlugin.reloadCurrentUser(
                (firebaseUser) => {
                    this.loginFromIdToken(firebaseUser.idToken);
                },
                (error) => {
                    this.$store.dispatch(
                        "appEvent/create",
                        "login_reload_user_failure"
                    );
                    console.error(error);
                }
            );
        },
        loginFromIdToken(idToken) {
            this.api
                .post("/firebase/signinWithIdToken", {
                    idToken,
                })
                .then((response) => {
                    if (response?.data?.authToken) {
                        this.$store
                            .dispatch("user/login", response.data.authToken)
                            .then(() => {
                                this.$router.push({ name: "defaultPage" });
                            });
                    }
                });
        },
    },
    components: {},
};
</script>