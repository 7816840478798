<template>
    <div>
        <div
            :key="question.questionId"
            v-for="question in questions"
            class="
                py-2
                mb-2
                px-3
                d-flex
                align-items-center
                justify-content-between
                btn btn-light btn-brand
                rounded-pill
            "
            @click="$store.commit('questions/setCurrentQuestion', question)"
        >
            <div>{{ question.questionTitle }}</div>
            <div class="text-primary">
                <div v-if="question.hasAnswer">
                    {{ question.answerTitle }}
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapState("questions", ["questions"]),
    },
};
</script>