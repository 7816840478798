<template>
    <div>
        <b-alert variant="warning" show>
            <h5>
                {{ $t("1 Invite Reward Required") }}
            </h5>
            <div>
                {{
                    $t(
                        "* You only need to complete this one time to unlock withdrawals forever"
                    )
                }}
            </div>

            <div class="mt-3">
                <b-button
                    v-b-toggle.howToGetInviteReward
                    variant="light"
                    class="border"
                    size="sm"
                    pill
                >
                    {{ $t("How to get an invite reward?") }}
                </b-button>
                <b-collapse id="howToGetInviteReward" class="my-3">
                    <h5>
                        {{ $t("Invite reward - guys (Fastest option)") }}
                    </h5>
                    <div>
                        {{
                            $t(
                                "When you invite a guy and he buys credits within the app, you will earn a reward and commission for each purchase"
                            )
                        }}
                    </div>
                    <h5 class="mt-3">
                        {{ $t("Invite reward - ladies") }}
                    </h5>
                    <div>
                        {{
                            $t(
                                "When you invite a lady and she is able to withdraw credits, you will earn a reward and a commission for each withdrawal"
                            )
                        }}
                    </div>
                </b-collapse>
            </div>

            <div class="mt-3">
                <b-button
                    v-b-toggle.more
                    variant="light"
                    class="border"
                    size="sm"
                    pill
                >
                    {{ $t("Why is this required?") }}
                </b-button>
                <b-collapse id="more" class="my-3">
                    <h5>
                        {{ $t("Invites are shared for all ladies") }}
                    </h5>
                    <div>
                        {{
                            $t(
                                "Other ladies did the work to invite the guys you've seen in the app, now its your turn. After you bring in 1 guy, you will get to access all of their invites forever. This makes it fair so everyone can share the guys invited"
                            )
                        }}
                    </div>
                    <h5 class="mt-3">
                        {{ $t("Fake invites not accepted") }}
                    </h5>
                    <div>
                        {{
                            $t(
                                "You can't invite a relative, or someone from your own country, state or province. You can't invite an existing user, inviting fake users can result in fines a ban"
                            )
                        }}
                    </div>
                    <h5 class="mt-3">
                        {{ $t("How many times will I have to do this?") }}
                    </h5>
                    <div>
                        {{
                            $t(
                                "Only once! After you have brought in 1 invite, you will be able to withdraw your money in the future forever"
                            )
                        }}
                    </div>
                    <h5 class="mt-3">
                        {{ $t("Where can I find a guy to invite?") }}
                    </h5>
                    <div>
                        {{
                            $t(
                                "Read the guide below by clicking on the 'Invite guys' guide, we have many details and strategy about how to complete this task. If you follow it, you will be able to get back in easily, and also earn commissions for life on any purchases from the guys you invite"
                            )
                        }}
                    </div>
                    <h5 class="mt-3">
                        {{ $t("Will I be paid for these invites?") }}
                    </h5>
                    <div>
                        {{
                            $t(
                                "Yes! Every time a guy you invite buys credits on the platform you will earn a commission, and every time a female invite withdraws money you will earn a commission as well as long as their accounts remain active"
                            )
                        }}
                    </div>
                </b-collapse>
            </div>
        </b-alert>

        <creator-invite-url />
    </div>
</template>

<script>
// import { mapState } from "vuex";

import CreatorInviteUrl from "@/components/creator/invite/CreatorInviteUrl";

export default {
    computed: {},
    components: {
        CreatorInviteUrl,
    },
};
</script>