<template>
    <div>
        <b-alert
            v-if="showMinutesWarning"
            show
            :variant="minutesRemainingVariant"
            class="border-0"
        >
            <div class="d-flex align-items-center justify-content-between">
                <div v-if="minutesRemaining">
                    <div v-if="currentCall">
                        {{ $t("You have ") }}
                        {{ minutesRemaining }}
                        {{ $t("minutes remaining") }}
                    </div>
                    <div v-else>
                        {{ $t("Your call will be limited to") }}
                        {{ minutesRemaining }}
                        {{ $t("minutes") }}
                    </div>
                </div>
                <div v-else>
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                    />
                    {{ $t("Please add minutes to enable calling") }}
                </div>
                <b-btn
                    @click="$store.commit('call/setAddingMinutes', true)"
                    variant="primary"
                    pill
                    class="text-nowrap ml-2"
                >
                    {{ $t("Add Minutes") }}
                </b-btn>
            </div>
        </b-alert>

        <table
            v-if="addingMinutes"
            class="table table-lg table-striped border mt-2"
        >
            <thead>
                <th>
                    {{ $t("Package") }}
                </th>
                <th class="text-center align-middle">
                    {{ $t("Checkout") }}
                </th>
            </thead>
            <tbody>
                <tr :key="price.id" v-for="price in creditRefillPricesMinutes">
                    <td class="text-left">
                        <div>
                            <h5 class="mb-0">
                                <font-awesome-icon
                                    icon="fa-duotone fa-coin"
                                    class="text-warning"
                                />
                                {{ price.credits }}
                                {{ $t("Credits") }}
                            </h5>
                            <div class="text-secondary">
                                +{{ price.minutes }}
                                {{ $t("minutes") }}
                            </div>
                        </div>
                    </td>
                    <td class="text-center align-middle">
                        <b-btn
                            @click="selectPackage(price)"
                            pill
                            block
                            variant="primary"
                        >
                            {{ $t("Buy") }}
                            ${{ price.amount }}
                            <div class="small text-opaque">
                                {{ price.localPrice }}
                            </div>
                        </b-btn>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            unsubscribeToMutations: null,
        };
    },
    mounted() {
        this.unsubscribeToMutations = this.$store.subscribe((mutation) => {
            if (
                mutation.type === "wallet/setPurchaseComplete" ||
                mutation.type === "userSubscription/setPurchaseSuccessful"
            ) {
                if (!this.currentCall?.callInProgress) {
                    this.$store.dispatch("call/refreshPreview");
                }

                this.$store.dispatch("call/refreshCurrentCall");
                this.$store.commit("call/setAddingMinutes", false);
            }
        });
    },
    beforeDestroy() {
        if (this.unsubscribeToMutations) {
            this.unsubscribeToMutations();
        }
    },
    computed: {
        showMinutesWarning() {
            if (this.onboardingUser.creator) {
                return false;
            }

            if (this.addingMinutes) {
                return false;
            }

            if (this.currentCall && this.minutesRemaining <= 5) {
                return true;
            }

            if (
                !this.currentCall &&
                this.callPreview &&
                this.minutesRemaining < 30
            ) {
                return true;
            }

            return false;
        },
        minutesRemaining() {
            if (this.currentCall?.minutesRemaining) {
                return this.currentCall?.minutesRemaining;
            }

            if (this.callPreview?.maxMinutes) {
                return this.callPreview?.maxMinutes;
            }

            return 0;
        },
        minutesRemainingVariant() {
            if (this.minutesRemaining <= 10) {
                return "danger";
            }

            if (this.minutesRemaining <= 30) {
                return "warning";
            }

            return "secondary";
        },
        ...mapState("profile", ["profile"]),
        ...mapState("options", ["creditRefillPricesMinutes"]),
        ...mapState("call", ["callPreview", "addingMinutes", "currentCall"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        selectPackage(price) {
            // reset purchase complete in case of multiple purchases
            this.$store.commit("wallet/setPurchaseComplete", false);
            this.$store.commit("interface/setUserWalletModalVisible", true);
            this.$store.commit("wallet/setPrice", price);
            this.$store.commit("wallet/setAddingCredits", true);
        },
    },
    components: {},
};
</script>