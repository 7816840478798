<template>
    <div>
        <h5 class="text-primary">{{ $t("How to Recieve Money") }}</h5>

        {{
            $t(
                "When a guy is viewing your profile, they can click on the 'Send Credit' button and send you up to 1000 credits at a time"
            )
        }}

        <h5 class="text-primary mt-3 text-danger">
            <font-awesome-icon icon="fa-solid fa-ban" />
            {{ $t("Don't ask for PayPal") }}
        </h5>

        {{
            $t(
                "Asking for payment outside the app on PayPal, Venmo, CashApp, Gcash, may result in getting fines, penalties or having your account banned"
            )
        }}

        <h5 class="text-primary mt-3">
            {{ $t("Use the calculator") }}
        </h5>

        {{
            $t(
                "To know how many credits to ask for, use the calculator on the My Money tab to know how much credits are worth in your currency"
            )
        }}

        <b-btn
            @click="finished"
            variant="primary"
            pill
            block
            size="lg"
            class="mt-3"
        >
            {{ $t("Ok, got it") }}
        </b-btn>
    </div>
</template>

<script>
export default {
    methods: {
        finished() {
            this.api.post("/userFlag/creatorGuideRecieveMoney").then(() => {
                this.$store.dispatch("onboarding/status");
                this.$store.dispatch("creatorGuide/refresh");
                this.$store.commit("creatorGuide/reset");
            });
        },
    },
    components: {},
};
</script>


