<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <div>
                {{ $t("Content") }}
            </div>
            <b-badge v-if="hasMediaItems" variant="primary" pill>
                {{ creatorGallery.medias.length }}
                {{ $t("Item") | pluralize(creatorGallery.medias.length) }}
            </b-badge>
        </div>

        <div class="border bg-black p-1">
            <div v-if="hasMediaItems" class="mt-2">
                <b-row no-gutters>
                    <b-col
                        cols="4"
                        :key="userMedia.id"
                        v-for="userMedia in creatorGallery.medias"
                        class="p-1"
                    >
                        <div class="position-relative">
                            <gallery-media-item
                                :preview="preview"
                                :blur="preview"
                                :userMedia="userMedia"
                            />
                            <div
                                v-if="creatorGallery.editable"
                                class="position-absolute"
                                style="top: 0; left: 0"
                            >
                                <b-btn
                                    variant="danger"
                                    size="sm"
                                    @click="removeMedia(userMedia)"
                                    block
                                >
                                    <font-awesome-icon
                                        icon="fa-light fa-times"
                                    />
                                </b-btn>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div v-else class="py-3 text-center">
                <div class="mt-3">
                    <font-awesome-icon icon="fa-light fa-empty-set" size="3x" />
                </div>
                <h5 class="mt-3">
                    {{ $t("Gallery is Empty") }}
                </h5>
            </div>
        </div>
    </div>
</template>



<script>
import { mapState } from "vuex";

import GalleryMediaItem from "@/components/gallery/GalleryMediaItem";

export default {
    data() {
        return {
            preview: false,
        };
    },
    methods: {
        removeMedia(userMedia) {
            // MAKE REUSABLE
            // this is a one off
            this.$store.dispatch("creatorGallery/removeMedia", userMedia.id);
        },
    },
    computed: {
        hasMediaItems() {
            return this.creatorGallery.medias.length > 0;
        },
        ...mapState("creatorGallery", ["creatorGallery"]),
    },
    components: {
        GalleryMediaItem,
    },
};
</script>
