<template>
    <div>
        <universal-uploader
            @uploadSuccess="uploadSuccess"
            class="text-truncate"
        >
            <b-btn
                variant="light"
                class="d-flex align-items-center"
                pill
                size="sm"
            >
                <font-awesome-icon class="mr-1" icon="fa-duotone fa-camera" />
                {{ $t("Photo") }}
            </b-btn>
        </universal-uploader>
    </div>
</template>

<script>
import UniversalUploader from "@/components/universalUploader/UniversalUploader";

export default {
    methods: {
        uploadSuccess(url) {
            this.$store.commit("createPostPhoto/queueUpload", url);
            this.$store.dispatch("createPostPhoto/scheduleQueueProcessing");
        },
    },
    components: {
        UniversalUploader,
    },
};
</script>