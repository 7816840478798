import api from "@/service/api";

const state = () => ({
    unlocked: false,
    subscribeRequired: false,
    creatorGallery: null,
    rating: null,
})

const mutations = {
    reset(state) {
        state.unlocked = false
        state.subscribeRequired = false
        state.creatorGallery = null
        state.rating = null
    },
    setCreatorGallery(state, value) {
        state.creatorGallery = value;
    },
    setUnlocked(state, value) {
        state.unlocked = value;
    },
    setSubscribeRequired(state, value) {
        state.subscribeRequired = value;
    },
    setRating(state, value) {
        state.rating = value;
    },
}

const actions = {
    unlock({ state, dispatch }) {
        api.post('/creatorGallery/unlock', {
            creatorGalleryId: state.creatorGallery.id
        }).then(() => {
            dispatch('get', state.creatorGallery.id)
        });
    },
    refresh({ state, dispatch }) {
        dispatch('get', state.creatorGallery.id);
    },
    get({ commit }, creatorGalleryId) {
        commit('reset');

        api.post('/creatorGallery/get', {
            creatorGalleryId
        }).then((response) => {
            if (response?.data?.creatorGallery) {
                commit('setCreatorGallery', response.data.creatorGallery)
            }

            if (response?.data?.rating) {
                commit('setRating', response.data.rating)
            }

            if (response?.data?.unlocked) {
                commit('setUnlocked', true)
            }
        });
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
