import { render, staticRenderFns } from "./OnboardingPhotoIndexGrid.vue?vue&type=template&id=de0e97b8&"
import script from "./OnboardingPhotoIndexGrid.vue?vue&type=script&lang=js&"
export * from "./OnboardingPhotoIndexGrid.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports