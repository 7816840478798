<template>
    <div class="mt-2">
        <h5 class="m-0">
            {{ $t("Did you know you can block entire countries?") }}
        </h5>
        <div class="mt-3">
            {{
                $t(
                    "When viewing a users profile, press the menu button on the location section and press Block all users. You can always unblock it later if you change your mind."
                )
            }}
        </div>
        <div class="mt-3">
            {{
                $t(
                    "You can also block countries from your settings, and just pick which specific countries you want to meet people in."
                )
            }}
        </div>
        <div class="mt-2">
            <b-btn
                @click="$store.dispatch('delete/saveFailed')"
                variant="link"
                size="sm"
                class="text-secondary px-0"
            >
                {{ $t("No thanks, just delete") }}
            </b-btn>
        </div>
        <div class="mt-3">
            <b-btn
                @click="savedDelete"
                variant="primary"
                block
                size="lg"
                class="py-4"
            >
                {{ $t("Manage Blocked Countries") }}
            </b-btn>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        savedDelete() {
            this.$store.dispatch("delete/saved");
            this.$store.dispatch("setting/editSetting", "blockedCountries");
        },
    },
};
</script>