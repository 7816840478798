<template>
    <generic-page>
        <div class="text-left">
            <b-btn variant="link" size="lg" class="pl-2" @click="backToIndex">
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
                {{ $t("All Galleries") }}
            </b-btn>
        </div>
        <div v-if="creatorGallery">
            <div v-if="unlocked">
                <gallery-view />
            </div>
            <div v-else>
                <gallery-brochure />
            </div>
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import GalleryBrochure from "@/components/gallery/GalleryBrochure";
import GalleryView from "@/components/gallery/GalleryView";

export default {
    created() {
        if (this.$route.params?.id) {
            this.$store.dispatch("gallery/get", this.$route.params.id);
        }
    },
    watch: {
        $route(to) {
            if (
                to?.params?.id !== this.$store.state.gallery.creatorGallery?.id
            ) {
                this.$store.dispatch("gallery/get", to.params.id);
            }
        },
    },
    computed: {
        ...mapState("gallery", [
            "creatorGallery",
            "unlocked",
            "subscribeRequired",
        ]),
    },
    methods: {
        backToIndex() {
            this.$router.push({ name: "galleryIndex" });
        },
    },
    components: {
        GalleryBrochure,
        GalleryView,
    },
};
</script>
        
        