<template>
    <div class="p-2">
        <back-to-dashboard />

        <div class="d-flex align-items-center justify-content-between">
            <h3 class="mb-0">Bonus Payouts</h3>
            <div>
                <b-badge variant="dark" pill class="border">
                    Total Buyer Count
                    {{ totalBuyerCount }}
                </b-badge>
            </div>
        </div>

        <b-table-simple striped class="mb-0 mt-3">
            <thead>
                <th>Progress</th>
                <th>Buyers Required</th>
                <th>Bonus Payout</th>
            </thead>
            <tbody>
                <tr :key="bonus.totalBuyerCount" v-for="bonus in bonuses">
                    <th class="bg-white">{{ bonus.progress }}%</th>
                    <td>
                        {{ bonus.totalBuyerCount }}
                    </td>
                    <td class="text-primary">
                        {{ bonus.payoutLocal }}
                    </td>
                </tr>
            </tbody>
        </b-table-simple>

        <b-alert variant="primary" show class="small mt-3">
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            Upon reaching your bonus amounts you'll automatically be eligible
            for a lump sump payout immediately. Contact support once you are
            eligible for payout, and we'll arrange for the bonus payment
            transfer to the account of your choosing
            <div class="mt-3">
                Bonus payouts will be conducted via wire transfer, you must have
                a bank that can can receive payments from Wise (most banks can).
                If you are in the United States, you can also get paid out via
                paper check or ACH.
            </div>
        </b-alert>
    </div>
</template>


<script>
import { mapState } from "vuex";

import BackToDashboard from "@/components/utility/BackToDashboard";

export default {
    data() {
        return {
            bonuses: null,
            totalBuyerCount: null,
        };
    },
    created() {
        this.$store.dispatch("affiliate/refresh");

        this.api.post("/affiliate/bonus").then((response) => {
            if (response?.data?.bonuses) {
                this.bonuses = response.data.bonuses;
                this.totalBuyerCount = response.data.totalBuyerCount;
            }
        });
    },
    computed: {
        ...mapState("affiliate", ["refillCommissionPercentage"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        BackToDashboard,
    },
};
</script>