<template>
    <div>
        <creator-subscribers />
    </div>
</template>

<script>
import CreatorSubscribers from "@/components/creator/subscriber/CreatorSubscribers";

export default {
    components: {
        CreatorSubscribers,
    },
};
</script>
        
        