<template>
    <div>
        <div v-if="!currentQuestion">
            <onboarding-question-index />
        </div>

        <div v-if="currentQuestion">
            <onboarding-question-edit :embedded="embedded" />
        </div>

        <div
            v-if="!embedded && !hasUnansweredQuestions && !currentQuestion"
            class="mt-4"
        >
            <b-btn @click="finished" variant="primary" show size="lg">
                {{ $t("Done with Questions") }}
            </b-btn>
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
import { mapState } from "vuex";

import OnboardingQuestionEdit from "@/components/onboarding/questions/OnboardingQuestionEdit";
import OnboardingQuestionIndex from "@/components/onboarding/questions/OnboardingQuestionIndex";

export default {
    created() {
        this.$store.dispatch("questions/refresh");
    },
    computed: {
        ...mapState("questions", [
            "currentQuestion",
            "currentQuestions",
            "hasUnansweredQuestions",
        ]),
    },
    methods: {
        finished() {
            this.$store.dispatch("onboarding/status");
        },
    },
    components: {
        OnboardingQuestionEdit,
        OnboardingQuestionIndex,
    },
    props: ["embedded"],
};
</script>