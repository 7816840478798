<template>
    <b-btn
        to="/affiliate/bonus"
        class="bg-white"
        variant="light"
        block
        size="lg"
    >
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="mr-2">
                    <font-awesome-icon
                        class="small-avatar text-primary"
                        icon="fa-duotone fa-sack-dollar"
                        size="lg"
                    />
                </div>
                Bonus
            </div>
            <div class="small">{{ currentBonusPercentage }}%</div>
        </div>
    </b-btn>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("affiliate", ["currentBonusPercentage"]),
    },
};
</script>