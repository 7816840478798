import api from "@/service/api";
import router from "@/router";

import axios from "axios"

const state = () => ({
    previewPost: false,
    creatorPost: null,
    message: null,
    isPrivate: false,
    primaryPhotoUrl: null,
    createInProgress: false,
    updateInProgress: false,
    publishInProgress: false,
    refreshInProgress: false,
    currentUpdateCancelTokenSource: null,
    error: null,
    hasPosted: false,
    managingPhoto: null,
    loadedDrafts: false,
    draftCreatorPosts: null,
    delayedHours: null,
})

const mutations = {
    reset(state) {
        state.url = false
        state.message = null
        state.managingPhoto = null
        state.isPrivate = false
        state.creatorPost = null
        state.delayedHours = null
        state.error = null
        state.primaryPhotoUrl = null
        state.createInProgress = false
        state.updateInProgress = false
        state.refreshInProgress = false
        state.publishInProgress = false
        state.previewPost = false
    },
    setMessage(state, value) {
        state.message = value

        if (state.creatorPost) {
            state.creatorPost.message = value
        }
    },
    setCreatorPost(state, value) {
        state.creatorPost = value
    },
    setLoadedDrafts(state, value) {
        state.loadedDrafts = value
    },
    setPreviewPost(state, value) {
        state.previewPost = value
    },
    setDelayedHours(state, value) {
        state.delayedHours = value
    },
    togglePrivate(state) {
        state.isPrivate = !state.isPrivate
    },
    setIsPrivate(state, value) {
        state.isPrivate = value
    },
    addTemporaryPhoto(state, url) {
        state.primaryPhotoUrl = url
    },
    setCreateInProgress(state, value) {
        state.createInProgress = value
    },
    setUpdateInProgress(state, value) {
        state.updateInProgress = value
    },
    setPublishInProgress(state, value) {
        state.publishInProgress = value
    },
    setRefreshInProgress(state, value) {
        state.refreshInProgress = value
    },
    setCurrentUpdateCancelTokenSource(state, value) {
        state.currentUpdateCancelTokenSource = value
    },
    setManagingPhoto(state, value) {
        state.managingPhoto = value
    },
    setError(state, value) {
        state.error = value
    },
    setDraftCreatorPosts(state, value) {
        state.draftCreatorPosts = value
    },
    setHasPosted(state) {
        state.hasPosted = true
    },
}

const actions = {
    updateMessage({ dispatch, state }) {
        if (state.creatorPost || state.createInProgress || state.publishInProgress || state.updateInProgress) {
            return;
        }

        dispatch('create').then(() => {
            dispatch('update')
        })
    },
    publishQuietly({ state }) {
        api.post("/post/publishQuietly", {
            creatorPostId: state.creatorPost.id,
        })
    },
    publish({ state, commit }) {
        commit('setError', null)
        commit('setPublishInProgress', true)

        // race condition protection
        if (!state.creatorPost) {
            commit('reset')
            return;
        }

        if (state.currentUpdateCancelTokenSource) {
            state.currentUpdateCancelTokenSource.cancel()
            commit('setCurrentUpdateCancelTokenSource', null)
        }

        api.post("/post/publish", {
            creatorPostId: state.creatorPost.id,
            message: state.message,
            private: state.isPrivate,
            delayedHours: state.delayedHours,
        }).then(() => {
            router.push({
                name: "creatorPostView",
                params: {
                    id: state.creatorPost.id
                },
            });

            commit('reset')
        }).catch(error => {
            if (error?.response?.data?.errors?.post?.length) {
                commit('setError', error.response.data.errors.post.join(","))
            }
        }).then(() => {
            commit('setPublishInProgress', false)
        })
    },
    create({ commit }) {
        return new Promise(resolve => {
            api.post("/post/create").then((response) => {
                if (response?.data?.creatorPost) {
                    commit('setCreatorPost', response.data.creatorPost)
                    commit('setCreateInProgress', false)
                    resolve()
                }
            });
        })
    },
    edit({ commit }, creatorPostId) {
        api.post("/creatorPost/getMine", {
            creatorPostId
        }).then((response) => {
            if (response?.data?.creatorPost) {
                commit('setCreatorPost', response.data.creatorPost)
                commit('setIsPrivate', response.data.creatorPost.private)
                commit('setMessage', response.data.creatorPost.message)
            }
        });
    },
    setIsPrivate({ commit, dispatch }, isPrivate) {
        commit('setIsPrivate', isPrivate)
        dispatch('update')
    },
    refresh({ state, commit }) {
        if (!state.creatorPost || state.refreshInProgress) {
            return;
        }

        commit('setRefreshInProgress', true)

        return new Promise(resolve => {
            api.post("/post/get", {
                creatorPostId: state.creatorPost.id
            }).then((response) => {
                if (response?.data?.creatorPost) {
                    commit('setRefreshInProgress', false)
                    commit('setCreatorPost', response.data.creatorPost)
                    commit('setIsPrivate', response.data.creatorPost.private)
                    commit('createPostVideo/setVideoModerationInProgress', response?.data?.creatorPost?.video?.moderationInProgress, { root: true })
                    resolve()
                }
            });
        })
    },
    update({ state, commit }) {
        if (!state.creatorPost || state.createInProgress || state.publishInProgress || state.updateInProgress) {
            return;
        }

        commit('setUpdateInProgress', true)
        commit('setError', null)

        const cancelTokenSource = axios.CancelToken.source();

        commit('setCurrentUpdateCancelTokenSource', cancelTokenSource)

        api.post("/post/update", {
            creatorPostId: state.creatorPost.id,
            message: state.message,
            private: state.isPrivate,
        }, {
            cancelToken: cancelTokenSource.token,
        })
            .then((response) => {
                if (response?.data?.creatorPost) {
                    commit('setCreatorPost', response.data.creatorPost)
                }
            }).catch(error => {
                if (error?.response?.data?.errors?.message?.length) {
                    commit('setError', error.response.data.errors.message.join(","))
                }
            })
            .then(() => {
                commit('setCurrentUpdateCancelTokenSource', null)
                commit('setUpdateInProgress', false)
            })
    },
    loadDrafts({ state, commit }, force) {
        if (state.loadedDrafts && !force) {
            return;
        }

        commit('setLoadedDrafts', true)

        api.post("/post/drafts")
            .then((response) => {
                if (response?.data?.creatorPosts) {
                    commit('setDraftCreatorPosts', response.data.creatorPosts)
                }
            })
    },
    managePhoto({ commit }, creatorPostPhoto) {
        commit('setManagingPhoto', creatorPostPhoto)
    },
    async addGif({ commit, state, dispatch }, gif) {
        if (!state.creatorPost) {
            await dispatch("create")
        }

        api
            .post("/post/gif", {
                creatorPostId: state.creatorPost.id,
                gif,
            })
            .then(() => {
                dispatch("refresh");
                commit("interface/setGiphySelectorModalVisible", false, { root: true });
            });
    },
}



const getters = {
    isDraftingPost(state) {
        return state.creatorPost ? true : false
    },
    photosExcludingPrimary(state) {
        return state.creatorPost?.photos?.filter(
            (photo) => photo.id !== state.creatorPost?.primaryPhotoId
        );
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
