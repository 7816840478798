<template>
    <div v-if="subscriptionPrice">
        <div class="border rounded">
            <user-standard-avatar
                :standardMap="creatorStandardMap"
                :nonPrimaryPhotos="false"
                :hideBadges="true"
                :userBetweenStatus="false"
                size="small"
            />

            <b-alert variant="primary" show class="rounded-0 mb-0 border-0">
                <div class="d-flex align-items-center justify-content-between">
                    <h5>
                        {{ $t("Subscriber Benefits") }}
                    </h5>
                    <div></div>
                </div>
                <div>
                    <font-awesome-icon icon="fa-duotone fa-message-dots" />
                    {{ $t("Send unlimited messages for free") }}
                </div>
                <div class="mt-2">
                    <font-awesome-icon icon="fa-duotone fa-lock-open" />
                    {{ $t("View all of their private content") }}
                </div>
            </b-alert>

            <div class="bg-light rounded-bottom text-center">
                <div class="py-4">
                    <h1 v-if="subscriptionPrice" class="text-primary mb-0">
                        ${{ subscriptionPrice }}
                    </h1>
                    <div
                        v-if="subscriptionLocalPrice"
                        class="font-weight-bold text-primary"
                    >
                        {{ subscriptionLocalPrice }}
                    </div>

                    <div class="text-secondary">
                        {{ $t("per week") }}
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-2 text-left small">
            <div class="text-secondary my-1">
                <font-awesome-icon
                    icon="fa-light fa-check"
                    class="text-success"
                />
                {{ $t("Your card will be charged automaticaly weekly") }}
            </div>

            <div class="text-secondary my-1">
                <font-awesome-icon
                    icon="fa-light fa-check"
                    class="text-success"
                />
                {{ $t("We'll email you each renewal") }}
            </div>

            <div class="text-secondary my-1">
                <font-awesome-icon
                    icon="fa-light fa-check"
                    class="text-success"
                />
                {{ $t("Easily cancel anytime") }}
            </div>
        </div>

        <div class="branded my-3 text-center">
            <font-awesome-icon icon="fa-light fa-arrow-down" size="2x" />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import UserStandardAvatar from "@/components/user/UserStandardAvatar";

export default {
    computed: {
        modal: {
            get() {
                return this.$store.state.interface
                    .creatorSubscriptionModalVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setCreatorSubscriptionModalVisible",
                    value
                );
            },
        },
        ...mapState("payment", ["createCard"]),
        ...mapState("userSubscription", [
            "subscriptionPrice",
            "creatorUserId",
            "subscriptionLocalPrice",
            "creatorStandardMap",
            "subscribing",
            "purchaseSuccessful",
        ]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        UserStandardAvatar,
    },
};
</script>