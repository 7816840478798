<template>
    <div>
        <div v-if="temporaryTokenUrl" class="mt-2">
            <h5 class="mb-0">
                {{ $t("Invite to Gallery") }}
            </h5>

            <div>
                {{ creatorGallery.name }}
            </div>

            <b-alert variant="primary" show class="mt-2">
                <div>
                    <font-awesome-icon
                        class="branded"
                        icon="fa-duotone fa-chart-network"
                    />
                    Invite users from outside {{ brand.name }} to purchase your
                    gallery
                </div>
                <div class="mt-2">
                    <font-awesome-icon
                        icon="fa-duotone fa-coin"
                        class="text-warning"
                    />
                    {{ $t("Earn ") }}
                    {{ refillCommissionPercentage }}%
                    {{
                        $t(" commission from any payments they make - forever")
                    }}
                </div>
                <div class="mt-2">
                    <font-awesome-icon icon="fa-duotone fa-lock" />
                    {{
                        $t(
                            "They can only see a blurred preview of your gallery until they pay"
                        )
                    }}
                </div>
            </b-alert>

            <b-input-group>
                <b-form-input :value="temporaryTokenUrl" readonly />
                <b-input-group-append>
                    <b-btn @click="copyUrl" variant="primary">
                        {{ $t("Copy URL") }}
                    </b-btn>
                </b-input-group-append>
            </b-input-group>

            <b-alert
                v-if="hasCopied"
                variant="success"
                show
                class="small mt-2 mb-0"
            >
                <h5>
                    <font-awesome-icon icon="fa-light fa-check" />
                    {{ $t("Copied") }}
                </h5>
                {{
                    $t(
                        "URL has been copied, now paste the URL in another chat app like WhatsApp or Messenger. Make sure they click the link before signup to get credit"
                    )
                }}
            </b-alert>

            <div class="mt-2 small text-secondary">
                <font-awesome-icon icon="fa-duotone fa-lock" />
                {{ $t("This URL is secret, only people with URL can view") }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import copy from "clipboard-copy";

export default {
    data() {
        return {
            temporaryTokenUrl: null,
            temporaryTokenExpires: null,
            hasCopied: false,
        };
    },
    created() {
        this.$store.dispatch("creatorGuide/refresh");

        this.api
            .post("/creatorGallery/temporaryTokenUrl", {
                creatorGalleryId: this.creatorGallery.id,
            })
            .then((response) => {
                if (response?.data?.temporaryTokenExpires) {
                    this.temporaryTokenUrl = response.data.temporaryTokenUrl;
                }
                if (response?.data?.temporaryTokenExpires) {
                    this.temporaryTokenExpires =
                        response.data.temporaryTokenExpires;
                }
            });
    },
    computed: {
        ...mapState("brand", ["brand"]),
        ...mapState("creatorGallery", ["creatorGallery"]),
        ...mapState("creatorGuide", ["refillCommissionPercentage"]),
    },
    methods: {
        copyUrl() {
            copy(this.temporaryTokenUrl);
            this.hasCopied = true;
        },
    },
    components: {},
};
</script>