<template>
    <div class="d-flex livestream-interaction-item">
        <div v-if="creatorLivestreamInteraction.type === 'comment'">
            <div
                class="glass rounded-pill livestream-interaction-comment d-flex align-items-center"
            >
                <img
                    class="rounded-circle livestream-interaction-avatar"
                    :src="onboardingUser.avatar"
                />
                <div class="livestream-interaction-text">
                    {{ creatorLivestreamInteraction.contentRendered }}
                </div>
            </div>
        </div>
        <div v-if="creatorLivestreamInteraction.type === 'gift'">
            <div
                class="glass rounded-pill livestream-interaction-comment d-flex align-items-center"
            >
                <img
                    class="rounded-circle livestream-interaction-avatar"
                    :src="onboardingUser.avatar"
                />
                <div class="livestream-interaction-text">
                    <img
                        class="openmoji-xs"
                        :src="creatorLivestreamInteraction.contentRendered.url"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

.livestream-interaction-item {
    margin-top: 0.5rem;

    .livestream-interaction-avatar {
        max-height: 18px;
        max-width: 18px;
        margin-left: 0.15rem;
        margin-right: 0.25rem;
    }
    .livestream-interaction-text {
        padding-top: 0.05rem;
        padding-bottom: 0.05rem;
        padding-right: 0.5rem;
        padding-left: 0.15rem;
        font-size: 75%;
    }
}
</style>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("livestream", [
            "creatorLivestream",
            "standardMap",
            "agora",
        ]),
    },
    components: {},
    props: ["creatorLivestreamInteraction"],
};
</script>