<template>
    <div>
        <div class="small text-secondary mt-3">
            {{ $t("By pressing ") }}
            "{{ targetButtonLabel }}"
            {{ $t(" you hereby certify that you have read and agree to our") }}

            <a
                @click="
                    $store.dispatch(
                        'system/openBrowser',
                        'https://findmate.app/content/terms'
                    )
                "
                href="#"
            >
                {{ $t("Terms and Conditions") }}
            </a>
            {{ $t(" and ") }}
            <a
                @click="
                    $store.dispatch(
                        'system/openBrowser',
                        'https://findmate.app/content/privacy'
                    )
                "
                href="#"
            >
                {{ $t("Privacy Policy") }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: ["targetButtonLabel"],
};
</script>