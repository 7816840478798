<template>
    <div>
        <div v-if="photos.length" class="text-left mb-3">
            <b-btn
                variant="link"
                size="lg"
                class="pl-0"
                @click="
                    $store.commit(
                        'interface/setOnboardingPhotoUploadMode',
                        false
                    )
                "
            >
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
                {{ $t("Back to All Photos") }}
            </b-btn>
        </div>

        <div v-if="photos.length" class="pb-3 text-left">
            <div v-if="onboardingPhotoUploadMode === 'public'">
                <h5 class="mb-0">
                    {{ $t("Public") }}
                </h5>
                <div class="mr-2 small">
                    {{ $t("Anyone can view") }}
                </div>
            </div>
            <div v-if="onboardingPhotoUploadMode === 'private'">
                <h5 class="mb-0">
                    {{ $t("Private") }}
                </h5>
                <div class="mr-2 small">
                    {{ $t("Only your subscribers can view") }}
                </div>
            </div>
        </div>

        <b-btn
            v-if="cordova"
            @click="launchCameraUpload"
            variant="light"
            class="d-flex align-items-center justify-content-between bg-white"
            pill
            size="lg"
            block
        >
            <div class="">
                <font-awesome-icon icon="fa-duotone fa-camera" />
            </div>
            <div>Camera</div>
            <div class="">
                <font-awesome-icon
                    icon="fa-duotone fa-camera"
                    class="invisible"
                />
            </div>
        </b-btn>

        <b-btn
            v-if="cordova"
            @click="launchFileUpload"
            variant="light"
            class="d-flex align-items-center justify-content-between bg-white"
            pill
            size="lg"
            block
        >
            <div>
                <font-awesome-icon icon="fa-duotone fa-photo-film" />
            </div>
            <div>Gallery</div>
            <div>
                <font-awesome-icon
                    icon="fa-duotone fa-photo-film"
                    class="invisible"
                />
            </div>
        </b-btn>

        <universal-uploader
            @uploadSuccess="uploadSuccess"
            class="text-truncate mt-2"
        >
            <b-btn
                variant="light"
                class="d-flex align-items-center justify-content-between bg-white"
                pill
                size="lg"
                block
            >
                <div>
                    <font-awesome-icon icon="fa-duotone fa-cloud-arrow-up" />
                </div>
                <div>Cloud Uploader</div>
                <div>
                    <font-awesome-icon
                        icon="fa-brands fa-facebook"
                        class="invisible"
                    />
                </div>
            </b-btn>
        </universal-uploader>
    </div>
</template>


<script>
import { mapState } from "vuex";

import axios from "axios";

import { PHOTO_UPLOAD_READY } from "@/constant/photoUploadEvents";

import PhotoUploadingMixin from "@/mixin/PhotoUploadingMixin";
import UniversalUploader from "@/components/universalUploader/UniversalUploader";

export default {
    mixins: [PhotoUploadingMixin],
    data() {
        return {
            uploadcarePublicKey: process.env.VUE_APP_UPLOADCARE_PUBLIC_KEY,
            errors: null,
            modal: false,
        };
    },
    created() {
        this.$on(PHOTO_UPLOAD_READY, this.photoUploadReady);
    },
    beforeDestroy() {
        this.$off(PHOTO_UPLOAD_READY, this.photoUploadReady);
    },
    methods: {
        // these methods copied directly from photo index, should abstract and reuse these
        // but lets see if it works first
        uploadSuccess(url) {
            this.uploadPhotoByUrl(url, this.onboardingPhotoUploadMode);
        },
        uploadPhotoByUrl(url, mode) {
            const instanceId = this.$uuid();

            this.$store.dispatch("photo/appendProcessingPhoto", {
                instanceId,
                url,
                uploading: true,
            });

            this.api
                .post("/photo/uploadByUrl", {
                    url,
                    instanceId,
                    mode,
                })
                .then(() => {
                    this.$store.commit("photo/finishedUpload", instanceId);
                })
                .catch((error) => {
                    if (error?.response?.data?.errors) {
                        Object.entries(error.response.data.errors).forEach(
                            ([instanceId, items]) => {
                                this.$store.commit("photo/appendPhotoError", {
                                    instanceId,
                                    message: items.join(", "),
                                });
                            }
                        );
                    }
                });

            this.$store.commit("interface/setOnboardingPhotoUploadMode", false);
        },
        removePendingPhoto(photo) {
            this.$store.commit("photo/removePendingPhoto", photo.instanceId);
        },
        photoUploadReady({ file, instanceId } = {}) {
            this.uploadByFile({ file, instanceId });
        },
        launchCameraUpload() {
            if (this.cordova) {
                this.cordovaPhotoUpload("camera");
            } else {
                this.$refs.cameraUpload.launch();
            }
        },
        launchFileUpload() {
            if (this.cordova) {
                this.cordovaPhotoUpload("gallery");
            } else {
                this.$refs.fileUpload.launch();
            }
        },
        launchSocialUpload() {
            if (this.cordova) {
                this.$refs.socialUpload.launch();
            } else {
                this.$refs.socialUpload.launch();
            }
        },
        uploadByFile({ file } = {}) {
            let formData = new FormData();

            formData.append("file", file);
            formData.append("upload_preset", "w9g8lnht");

            const originalMode = this.onboardingPhotoUploadMode;

            axios
                .post(
                    "https://api.cloudinary.com/v1_1/findmateapp/image/upload",
                    formData
                )
                .then((response) => {
                    if (response?.data?.url) {
                        this.uploadPhotoByUrl(
                            response?.data?.url,
                            originalMode
                        );
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.error(error.response);
                    }
                });

            this.$store.commit("interface/setOnboardingPhotoUploadMode", false);
        },
        uploadError(error) {
            console.error("upload error", error);
        },
    },
    computed: {
        ...mapState("system", ["cordova"]),
        ...mapState("interface", ["onboardingPhotoUploadMode"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        photos() {
            return this.onboardingUser?.photos ?? null;
        },
    },
    components: {
        UniversalUploader,
    },
};
</script>