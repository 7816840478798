import api from "@/service/api";

const state = () => ({
    account: null,
    requirements: null,
    error: null,
    createAccountType: null,
    refreshing: false,
})

const mutations = {
    reset(state) {
        state.requirements = null
        state.createAccountType = null
        state.refreshing = false
        state.error = null
    },
    setAccount(state, value) {
        state.account = value
    },
    setCreateAccountType(state, value) {
        state.createAccountType = value
    },
    setRequirements(state, value) {
        state.requirements = value
    },
    setError(state, value) {
        state.error = value
    },
    setRefreshing(state, value) {
        state.refreshing = value
    },
}

const actions = {
    getAccount({ commit }) {
        api
            .post("/wise/getAccount")
            .then((response) => {
                if (response?.data?.account) {
                    commit("setAccount", response.data.account);
                }
            })
    },
    refreshRequirements({ commit, rootState }, payload = {}) {
        commit("setRefreshing", true);

        api
            .post("/wise/requirements", {
                withdrawId: rootState.withdraw.withdraw.id,
                payload
            })
            .then((response) => {
                if (response?.data?.requirements) {
                    commit("setRequirements", response.data.requirements);
                }
            })
            .catch(() => { })
            .then(() => {
                commit("setRefreshing", false);
            })
    },
    saveAccount({ rootState, commit, dispatch }, payload) {
        commit('setError', null)
        commit("setRefreshing", true);

        api
            .post("/wise/saveAccount", {
                withdrawId: rootState.withdraw.withdraw.id,
                payload
            })
            .then(() => {
                dispatch('getAccount')
                dispatch('withdraw/refresh', null, { root: true })
            })
            .catch((error) => {
                if (error?.response?.data?.errors?.wise?.length) {
                    commit('setError', error.response.data.errors.wise.join("\n"))
                } else {
                    commit('setError', "Error saving account")
                }
            })
            .then(() => {
                commit("setRefreshing", false);
            })
    },
    useExistingAccount({ rootState, commit, dispatch }) {
        commit("setRefreshing", true);

        api
            .post("/wise/useExistingAccount", {
                withdrawId: rootState.withdraw.withdraw.id,
            })
            .then(() => {
                dispatch('withdraw/refresh', null, { root: true })
            })
            .catch((error) => {
                if (error?.response?.data?.errors?.wise?.length) {
                    commit('setError', error.response.data.errors.wise.join("\n"))
                } else {
                    commit('setError', "Error selecting account")
                }
            })
            .then(() => {
                commit("setRefreshing", false);
            })
    },
}

const getters = {
    selectedType(state) {
        return state.requirements?.find((req) => req.type === state.createAccountType);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
