<template>
    <div>
        <div
            :key="upload.instanceId"
            v-for="upload in uploading"
            class="d-flex align-items-center my-2"
        >
            <div class="mr-2">
                <img height="100" width="100" :src="upload.url" />
            </div>

            <div v-if="hasError(upload)">
                <b-alert variant="danger" show class="small">
                    <b-btn
                        variant="danger"
                        @click="removeErrorByInstanceId(upload.instanceId)"
                        pill
                        size="sm"
                    >
                        <font-awesome-icon size="lg" icon="fa-light fa-times" />
                        Remove
                    </b-btn>
                    {{ hasError(upload).message }}
                </b-alert>
            </div>
            <div v-else>
                <div class="d-flex align-items-center">
                    <b-spinner class="mr-2" variant="light" />
                    Uploading
                </div>
            </div>
        </div>
        <universal-uploader
            @uploadSuccess="uploadPhotoSuccess"
            class="text-truncate"
        >
            <b-btn pill variant="light" size="lg" block>
                <span class="text-primary">
                    <font-awesome-icon
                        class="mr-1"
                        icon="fa-duotone fa-image-polaroid-user"
                    />
                </span>
                Upload New Photo
            </b-btn>
        </universal-uploader>
    </div>
</template>

<script>
import { mapState } from "vuex";

import UniversalUploader from "@/components/universalUploader/UniversalUploader";

export default {
    data() {
        return {
            uploading: [],
        };
    },
    computed: {
        ...mapState("profile", ["profile"]),
        ...mapState("conversationMedia", ["errors"]),
    },
    methods: {
        removeErrorByInstanceId(instanceId) {
            this.$store.commit(
                "conversationMedia/removeErrorByInstanceId",
                instanceId
            );

            this.uploading = this.uploading.filter(
                (uploading) => uploading.instanceId !== instanceId
            );
        },
        hasError(upload) {
            return this.errors.find(
                (error) => error.instanceId === upload.instanceId
            );
        },
        uploadPhotoSuccess(url) {
            const instanceId = this.$uuid();

            this.uploading.push({ url, instanceId });

            this.api
                .post("/media/upload", { url })
                .then(() => {
                    this.uploading = this.uploading.filter(
                        (uploading) => uploading.instanceId !== instanceId
                    );

                    this.$store.dispatch("conversationMedia/refresh");
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.$store.commit("conversationMedia/appendError", {
                            instanceId,
                            message: error?.response?.data?.message,
                        });
                    }
                });
        },
    },
    components: {
        UniversalUploader,
    },
};
</script>w