<template>
    <div>
        <b-btn
            @click="
                $store.commit(
                    'interface/setConversationTemplateModalVisible',
                    true
                )
            "
            pill
            variant="light"
            class="d-flex align-items-center bg-white shadow-sm shadow-blue mr-2"
            size="sm"
        >
            <font-awesome-icon
                class="mr-1"
                icon="fa-duotone fa-brackets-curly"
            />
            {{ $t("Template") }}
        </b-btn>

        <b-modal
            v-model="modal"
            hide-footer
            hide-header
            centered
            
            no-close-on-esc
            body-class="bg-light rounded-lg p-0"
        >
            <div v-if="templates && templates.length">
                <div v-if="editTemplate" class="p-3">
                    <conversation-template-edit />
                </div>
                <div v-else>
                    <div
                        class="p-3 d-flex align-items-center justify-content-between"
                    >
                        <conversation-template-create />
                        <conversation-template-edit-toggle />
                    </div>
                    <div>
                        <conversation-template-index />
                    </div>
                </div>
            </div>
            <div v-else-if="finishedInitialLoad">
                <div class="text-center p-3">
                    <div class="branded py-3">
                        <font-awesome-icon
                            size="5x"
                            icon="fa-duotone fa-brackets-curly"
                        />
                    </div>
                    <h3>
                        {{ $t("Templates") }}
                    </h3>
                    <div class="mt-3">
                        {{
                            $t(
                                "Templates allow you to save time by creating a customized message with variables that you can send by just the tap of a button"
                            )
                        }}
                    </div>
                    <div class="mt-4 px-3 branded">
                        {{ $t("Create your first template") }}
                    </div>
                    <div class="mt-2 branded">
                        <font-awesome-icon icon="fa-light fa-arrow-down" />
                    </div>
                    <div class="mt-2">
                        <conversation-template-create />
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="text-center p-3">
                    <b-spinner small variant="dark" />
                    {{ $t("Loading...") }}
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";

import ConversationTemplateIndex from "@/components/conversation/template/ConversationTemplateIndex";
import ConversationTemplateEdit from "@/components/conversation/template/ConversationTemplateEdit";
import ConversationTemplateEditToggle from "@/components/conversation/template/ConversationTemplateEditToggle";
import ConversationTemplateCreate from "@/components/conversation/template/ConversationTemplateCreate";

export default {
    created() {
        this.$store.dispatch("conversationTemplate/initialLoad");
    },
    computed: {
        modal: {
            get() {
                return this.$store.state.interface
                    .conversationTemplateModalVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setConversationTemplateModalVisible",
                    value
                );
            },
        },
        ...mapState("conversationTemplate", [
            "editTemplate",
            "templates",
            "finishedInitialLoad",
        ]),
    },
    methods: {},
    components: {
        ConversationTemplateIndex,
        ConversationTemplateEdit,
        ConversationTemplateEditToggle,
        ConversationTemplateCreate,
    },
};
</script>