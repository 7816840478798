<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <div class="">
                <h5 class="mb-0">{{ $t("Price Reduction") }}</h5>
            </div>
            <div>
                <h5 class="mb-0">
                    <b-badge variant="primary" class="float-right" pill>
                        {{ $t("Update") }}
                    </b-badge>
                </h5>
            </div>
        </div>

        <h3 class="text-center mt-4">
            {{ $t("We've heard you!") }}
        </h3>

        <div class="text-center text-primary">
            {{ $t("New Chat Pricing is Live") }}
        </div>

        <div
            class="d-flex align-items-center justify-content-center text-center my-3"
        >
            <div>
                <h1 class="mb-0">2</h1>
                <font-awesome-icon
                    icon="fa-duotone fa-coin"
                    size="2x"
                    class="text-warning"
                />
            </div>
            <div class="mx-4">
                <font-awesome-icon icon="fa-light fa-arrow-right" size="2x" />
            </div>
            <div>
                <h1 class="mb-0">1</h1>
                <font-awesome-icon
                    icon="fa-duotone fa-coin"
                    size="2x"
                    class="text-warning"
                />
            </div>
        </div>

        <b-alert variant="warning" show class="mt-3">
            {{
                $t(
                    "Effective immediately, pay to chat will be reduced to 1 credit per message"
                )
            }}
            <div class="mt-3">
                {{
                    $t(
                        "As always, 100% of credits you give to ladies in gifts, requests, subscriptions, etc. go to her, no hidden fees ever"
                    )
                }}
            </div>
        </b-alert>

        <b-row class="mt-4">
            <b-col cols="12" md="6" class="my-2">
                <b-btn
                    variant="primary"
                    @click="closeModal"
                    pill
                    block
                    size="lg"
                >
                    {{ $t("Ok, got it") }}
                </b-btn>
            </b-col>
            <b-col cols="12" md="6" class="my-2">
                <b-btn
                    variant="secondary"
                    @click="maybeLater"
                    pill
                    block
                    size="lg"
                >
                    {{ $t("Remind me later") }}
                </b-btn>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        currentOffer() {
            return this.$store.state.displayOffer?.currentOffer;
        },
        ...mapState("user", ["premium"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        closeModal() {
            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
        maybeLater() {
            this.api.post("/offer/removeViewedOffer", {
                offer: this.currentOffer.offer,
            });

            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
    },
};
</script>

