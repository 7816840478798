<template>
    <b-form @submit.stop.prevent="executeSearch">
        <b-input-group>
            <b-input
                v-model="keyword"
                type="text"
                class="border"
                :placeholder="$t('Search for user by name...')"
                autocorrect="off"
                autocomplete="off"
                autocapitalize="none"
            />
            <b-input-group-append v-if="keyword">
                <b-btn
                    @click="keyword = ''"
                    variant="light"
                    class="border text-danger"
                    v-if="keyword"
                >
                    {{ $t("Reset") }}
                </b-btn>
            </b-input-group-append>
            <b-input-group-append>
                <b-btn
                    @click="executeSearch"
                    variant="light"
                    class="border-left-0 border"
                >
                    <font-awesome-icon icon="fa-duotone fa-magnifying-glass" />
                </b-btn>
            </b-input-group-append>
        </b-input-group>
    </b-form>
</template>

<script>
// import { mapState } from "vuex";

export default {
    mounted() {},
    methods: {
        executeSearch() {
            this.$store.dispatch("search/refresh");
        },
    },
    computed: {
        keyword: {
            get() {
                return this.$store.state.search.keyword;
            },
            set(value) {
                this.$store.commit("search/setKeyword", value);
            },
        },
    },
    components: {},
};
</script>