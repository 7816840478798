import api from "@/service/api";

const state = () => ({
    brand: null,
})

const mutations = {
    setBrand(state, value) {
        state.brand = value
    },
}

const actions = {
    get({ commit }) {
        return new Promise((resolve) => {
            api.post(`/brand/${process.env.VUE_APP_BRAND}/get`).then((response) => {
                commit('setBrand', response.data.brand)
                resolve();
            })
        });
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
