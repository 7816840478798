<template>
    <b-btn to="/creator/post" class="bg-white" variant="light" block size="lg">
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="mr-2">
                    <font-awesome-icon
                        class="small-avatar branded"
                        icon="fa-duotone fa-globe"
                        size="lg"
                    />
                </div>
                {{ $t("Posts") }}
            </div>
            <div v-if="noRecentPostWarning" class="text-warning">
                <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
                {{ $t("No recent post") }}
            </div>
        </div>
    </b-btn>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        noRecentPostWarning() {
            return this.dashboard && !this.dashboard.hasRecentPost;
        },
        ...mapState("creatorDashboard", ["dashboard"]),
    },
};
</script>