<template>
    <div
        v-if="creatorLivestream"
        class="d-flex align-items-center justify-content-between"
    >
        <creator-livestream-viewers />
        <div v-if="creatorLivestream.startedAt" class="small text-primary">
            <duration-timer :timestamp="creatorLivestream.startedAt" />
        </div>
        <creator-livestream-earnings />
    </div>
</template>

<script>
import { mapState } from "vuex";

import DurationTimer from "@/components/utility/DurationTimer";

import CreatorLivestreamViewers from "@/components/always/creatorLivestream/CreatorLivestreamViewers";
import CreatorLivestreamEarnings from "@/components/always/creatorLivestream/CreatorLivestreamEarnings";

export default {
    computed: {
        ...mapState("creatorLivestream", ["creatorLivestream"]),
    },
    components: {
        DurationTimer,
        CreatorLivestreamViewers,
        CreatorLivestreamEarnings,
    },
};
</script>