<template>
    <div>
        <back-to-dashboard />
        <div v-if="dashboard">
            <div class="d-flex align-items-center justify-content-between my-2">
                <h5 class="mb-0">
                    {{ $t("Post Stats") }}
                </h5>
                <div>
                    <creator-dashboard-date-range />
                </div>
            </div>

            <b-table-simple bordered>
                <tbody>
                    <tr :key="stat.label" v-for="stat in dashboard.stats">
                        <td class="w-66">
                            {{ stat.label }}
                        </td>
                        <td class="bg-white">
                            {{ stat.value }}
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import CreatorDashboardDateRange from "@/components/creatorDashboard/stats/CreatorDashboardDateRange";
import BackToDashboard from "@/components/utility/BackToDashboard";

export default {
    methods: {
        createSubscription() {},
    },
    computed: {
        ...mapState("creatorDashboard", ["dashboard"]),
    },
    components: {
        CreatorDashboardDateRange,
        BackToDashboard,
    },
};
</script>