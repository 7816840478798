<template>
    <div ref="profilePosts">
        <div
            :key="creatorPost.id"
            v-for="creatorPost in creatorPosts"
            class="p-2 bg-light"
        >
            <view-post :id="creatorPost.id" :creatorPost="creatorPost" />
        </div>

        <div
            v-if="showPagination"
            class="d-flex align-items-center justify-content-between p-3"
        >
            <div>
                <b-btn
                    @click="previous"
                    variant="light"
                    size="lg"
                    block
                    pill
                    class="p-3 border-0 d-flex align-items-center"
                    :disabled="page === 1"
                >
                    <font-awesome-icon icon="fa-light fa-arrow-left" />
                    <div class="ml-2 d-none d-md-block">
                        {{ $t("Previous") }}
                    </div>
                </b-btn>
            </div>
            <div class="text-center text-secondary">Page {{ page }}</div>
            <div>
                <b-btn
                    @click="next"
                    variant="light"
                    size="lg"
                    block
                    pill
                    class="p-3 border-0 d-flex align-items-center"
                    :disabled="!hasMorePages"
                >
                    <div class="mr-2 d-none d-md-block">
                        {{ $t("Next") }}
                    </div>
                    <font-awesome-icon icon="fa-light fa-arrow-right" />
                </b-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import ViewPost from "@/components/post/view/ViewPost";

export default {
    data() {
        return {
            creatorPosts: null,
            unsubscribeToMutations: null,
            hasMorePages: false,
            page: 1,
        };
    },
    computed: {
        showPagination() {
            if (!this.hasMorePages && this.page === 1) {
                return false;
            }

            return true;
        },
        ...mapState("profile", ["profile"]),
    },
    mounted() {
        this.refresh();

        // this is hacky
        // to avoid loading the wrong feed into
        // postIndex module

        // just use local component
        // but use module events to know when to refresh
        // not much danger of post jumping on refresh since its just one user at a time
        this.unsubscribeToMutations = this.$store.subscribe((mutation) => {
            if (mutation.type === "post/postUpdated") {
                this.refresh();
            }
        });
    },
    beforeDestroy() {
        if (this.unsubscribeToMutations) {
            this.unsubscribeToMutations();
        }
    },
    methods: {
        refresh() {
            this.creatorPosts = null;

            this.api
                .post("/postIndex/user", {
                    userId: this.profile.id,
                    page: this.page,
                })
                .then((response) => {
                    if (response?.data?.creatorPosts) {
                        this.creatorPosts = response.data.creatorPosts;
                    }

                    this.hasMorePages = response.data.hasMorePages;
                });
        },
        next() {
            this.page++;
            this.refresh();
        },
        previous() {
            this.page--;
            this.refresh();
        },
    },
    components: {
        ViewPost,
    },
};
</script>