<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <h5>My Money</h5>
        </div>

        <earnings-withdraw />
        <earnings-stats />
        <earnings-navigation />
    </div>
</template>

<script>
import EarningsWithdraw from "@/components/earnings/EarningsWithdraw";
import EarningsNavigation from "@/components/earnings/EarningsNavigation";
import EarningsStats from "@/components/earnings/EarningsStats";

export default {
    components: {
        EarningsWithdraw,
        EarningsNavigation,
        EarningsStats,
    },
};
</script>