import api from "@/service/api";

const state = () => ({
    subscriptions: null,
    managingSubscription: null,
})

const mutations = {
    setSubscriptions(state, value) {
        state.subscriptions = value;
    },
    setManagingSubscription(state, value) {
        state.managingSubscription = value;
    },
}

const actions = {
    view({ commit }, userId) {
        commit('setCreatorUserId', userId)
        commit('interface/setCreatorSubscriptionModalVisible', true, { root: true })
    },
    refresh({ commit }) {
        api.post('/legacySubscription/index').then((response) => {
            if (response?.data?.subscriptions) {
                commit('setSubscriptions', response.data.subscriptions)
            }
        })
    },
    manage({ commit }, subscription) {
        commit('setManagingSubscription', subscription)
    },
    cancel({ commit, dispatch, state }) {
        api.post('/legacySubscription/cancel', {
            subscriptionId: state.managingSubscription.id
        }).then(() => {
            dispatch('refresh')
            commit('setManagingSubscription', null)
        })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
