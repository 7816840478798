<template>
    <div>
        <div v-if="option" class="text-left">
            <div>
                <b-btn
                    class="text-primary text-decoration-none p-0"
                    @click="backButton"
                    variant="link"
                >
                    <font-awesome-icon icon="fa-light fa-arrow-left" />
                    {{ $t("All Contact Options") }}
                </b-btn>
            </div>
            <div class="d-flex align-items-center justify-content-between mt-3">
                <h5 class="d-flex align-items-center">
                    <font-awesome-icon
                        class="mr-2 branded"
                        :icon="`fa-brands ${option.icon}`"
                        size="2x"
                    />
                    {{ option.name }}
                </h5>

                <b-dropdown
                    variant="light"
                    right
                    size="lg"
                    toggle-class="mr-2 action-button"
                    v-if="hasContactType(option.type)"
                >
                    <b-dropdown-item
                        @click="removeContact(option.type)"
                        variant="danger"
                    >
                        {{ $t("Remove this Contact") }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <b-form-group class="mt-3" v-if="offerPhoneField">
                <div class="small text-secondary text-left">
                    {{ $t("Phone Number") }}
                </div>

                <b-input-group size="lg">
                    <b-input-group-prepend>
                        <b-dropdown
                            variant="light"
                            toggle-class="border"
                            menu-class="pre-scrollable"
                        >
                            <template #button-content>
                                <span v-if="selectedCountry">
                                    {{ selectedCountry.name }}

                                    ({{ selectedCountry.phonePrefix }})
                                </span>
                                <span v-else>
                                    {{ $t("Select country") }}
                                </span>
                            </template>
                            <b-dropdown-item
                                v-for="country in matchingCountries"
                                block
                                @click="selectCountry(country)"
                                :key="country.id"
                                class="btn-brand d-flex align-items-center"
                            >
                                <img :src="country.flagUrl" class="mr-2" />
                                {{ country.name }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-input-group-prepend>
                    <b-input
                        @update="updatePhone"
                        v-model="phone"
                        type="text"
                        size="lg"
                        autocorrect="off"
                        autocapitalize="none"
                        autocomplete="tel"
                    />
                </b-input-group>
                <b-alert variant="danger" v-if="error" show>
                    {{ $t("Please enter a valid number") }}
                </b-alert>
            </b-form-group>

            <b-form-group class="mt-3" v-if="offerUsernameField">
                <div class="small text-secondary text-left">
                    <div v-if="usernameLabel">
                        {{ usernameLabel }}
                    </div>
                    <div v-else>
                        {{ $t("Username or ID") }}
                    </div>
                </div>
                <b-input-group size="lg">
                    <b-input
                        v-model="username"
                        type="text"
                        size="lg"
                        autocorrect="off"
                        autocapitalize="none"
                    />
                </b-input-group>
            </b-form-group>

            <b-form-group class="mt-3" v-if="offerQrCode">
                <div v-if="qrCodeUrl" class="my-3">
                    <img class="img-fluid img-thumbnail" :src="qrCodeUrl" />
                </div>

                <b-alert variant="danger" v-if="photoError" show>
                    {{
                        $t(
                            "This photo could not be accepted, please try a different photo"
                        )
                    }}
                </b-alert>

                <universal-uploader
                    @uploadSuccess="uploadSuccess"
                    class="text-truncate"
                >
                    <b-btn pill variant="dark" size="lg" block>
                        <div>
                            <font-awesome-icon icon="fa-light fa-plus" />
                            {{ $t("Upload QR Code") }}
                        </div>
                    </b-btn>
                </universal-uploader>
            </b-form-group>

            <div class="mt-3">
                <b-btn
                    :disabled="!complete || saving"
                    @click="save"
                    variant="primary"
                    block
                    size="lg"
                >
                    {{ $t("Save") }}
                </b-btn>
            </div>
        </div>
        <div v-else class="mt-3">
            <fitty class="my-3" :options="{ maxSize: 21 }">
                <template v-slot:content>
                    {{
                        $t("(Optional) share your contact details upon request")
                    }}
                </template>
            </fitty>
            <div class="my-3 small text-secondary">
                <span class="branded">
                    <font-awesome-icon icon="fa-duotone fa-shield-check" />
                </span>
                {{
                    $t(
                        "Your contact will only be shared when you give another user access to it"
                    )
                }}
            </div>
            <div v-if="!hasContacts" class="my-3 text-primary">
                <span>
                    <font-awesome-icon icon="fa-light fa-arrow-down" />
                </span>
                {{ $t("Select a messaging app") }}
                <span>
                    <font-awesome-icon icon="fa-light fa-arrow-down" />
                </span>
            </div>
            <b-row>
                <b-col
                    :key="contactOption.type"
                    v-for="contactOption in contactOptions"
                    cols="4"
                >
                    <b-btn
                        @click="setOption(contactOption)"
                        variant="light"
                        block
                        class="p-3 border my-2"
                        :class="{
                            'border-primary': hasContactType(
                                contactOption.type
                            ),
                        }"
                    >
                        <font-awesome-icon
                            class="mr-1"
                            :icon="`fa-brands ${contactOption.icon}`"
                            size="2x"
                        />
                        <div class="small">
                            {{ contactOption.name }}
                        </div>
                        <div class="mt-1">
                            <font-awesome-icon
                                v-if="hasContactType(contactOption.type)"
                                icon="fa-solid fa-check-circle"
                                class="text-primary"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else
                                icon="fa-solid fa-circle"
                            ></font-awesome-icon>
                        </div>
                    </b-btn>
                </b-col>
            </b-row>

            <div class="mt-3">
                <b-btn
                    variant="light"
                    class="text-secondary"
                    size="sm"
                    @click="confirmDone"
                >
                    {{ $t("Done with Contact") }}
                </b-btn>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";
import UniversalUploader from "@/components/universalUploader/UniversalUploader";

export default {
    data() {
        return {
            photoError: null,
            selectedCountry: null,
            option: null,
            phone: null,
            username: null,
            qrCodeUrl: null,
            error: null,
            saving: false,
            contacts: null,
        };
    },
    mounted() {
        this.index();

        if (this.user.countryId) {
            const country = this.countries.find(
                (country) => country.id === this.user.countryId
            );

            if (country) {
                this.selectCountry(country);
            }
        }
    },
    computed: {
        offerQrCode() {
            return this.option?.fields?.includes("qr");
        },
        offerPhoneField() {
            return this.option?.fields?.includes("phone");
        },
        offerUsernameField() {
            return this.option?.fields?.includes("username");
        },
        usernameLabel() {
            return this.option?.usernameLabel;
        },
        ...mapState("onboarding", ["user"]),
        ...mapState("options", ["contactOptions", "countries"]),
        complete() {
            return this.phone || this.username || this.qrCodeUrl;
        },
        hasContacts() {
            return this.contacts?.length ? true : false;
        },
        matchingCountries() {
            if (this.search) {
                return this.countries.filter((country) =>
                    country.name
                        .toLowerCase()
                        .includes(this.search.toLowerCase())
                );
            }
            return this.countries;
        },
    },
    methods: {
        backButton() {
            this.index();
            this.setOption(null);
        },
        uploadSuccess(url) {
            this.error = null;

            this.api
                .post("/contact/upload", {
                    url,
                    type: this.option.type,
                })
                .then((response) => {
                    if (response?.data?.qrCodeUrl) {
                        this.qrCodeUrl = response.data.qrCodeUrl;
                    }
                })
                .catch((error) => {
                    if (error?.response?.data?.errors?.length) {
                        this.photoError = error.response.data.errors.join(",");
                    }
                });
        },
        uploadError(error) {
            console.error("upload error", error);
        },
        launchFileUpload() {
            if (this.cordova) {
                this.cordovaPhotoUpload("gallery");
            } else {
                this.$refs.fileUpload.launch();
            }
        },
        done() {
            this.api.post("/contact/done").then(() => {
                this.$store.dispatch("onboarding/status");
            });
        },
        confirmDone() {
            if (this.contacts?.length) {
                this.done();
            } else {
                if (
                    confirm(
                        this.$t(
                            "Are you sure you want to continue without adding contact info?"
                        )
                    )
                ) {
                    this.done();
                }
            }
        },
        removeContact(type) {
            this.api
                .post("/contact/remove", {
                    type,
                })
                .then(() => {
                    this.index();
                    this.setOption(null);
                });
        },
        index() {
            this.api
                .post("/contact/index")
                .then((response) => {
                    if (response?.data?.contacts) {
                        this.contacts = response.data.contacts;
                    }
                })
                .catch((error) => {
                    if (error?.response?.data?.errors?.message?.length) {
                        this.error =
                            error.response.data.errors.message.join(",");
                    }
                })
                .then(() => {
                    this.saving = false;
                });
        },
        save() {
            this.saving = true;

            this.api
                .post("/contact/save", {
                    type: this.option.type,
                    countryCode: this.selectedCountry?.iso_alpha2,
                    phone: this.phone,
                    username: this.username,
                })
                .then(() => {
                    this.index();
                    this.setOption(null);
                })
                .catch((error) => {
                    if (error?.response?.data?.errors?.phone?.length) {
                        this.error = error.response.data.errors.phone.join(",");
                    }
                })
                .then(() => {
                    this.saving = false;
                });
        },
        selectCountry(country) {
            this.selectedCountry = country;
        },
        updatePhone() {},
        setOption(option) {
            this.option = option;
            this.error = null;

            const contact = this.contacts?.find(
                (contact) => contact?.type === this.option?.type
            );

            this.phone = contact?.phone;
            this.username = contact?.username;
            this.qrCodeUrl = contact?.qrCodeUrl;
        },
        hasContactType(type) {
            return this.contacts?.map((contact) => contact.type).includes(type);
        },
    },
    components: {
        UniversalUploader,
    },
};
</script>
