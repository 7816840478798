<template>
    <div>
        {{ formattedTime }}
    </div>
</template>

<script>
export default {
    data() {
        return {
            timer: 0,
            formattedTime: "00:00",
        };
    },
    methods: {
        updateTimer() {
            this.timer++;
            this.formatTime();
        },
        formatTime() {
            const minutes = Math.floor(this.timer / 60);
            const seconds = this.timer % 60;

            this.formattedTime = `${this.pad(minutes)}:${this.pad(seconds)}`;
        },
        pad(value) {
            return value < 10 ? `0${value}` : value;
        },
    },
    mounted() {
        // Update the timer every second
        this.interval = setInterval(this.updateTimer, 1000);
    },
    beforeDestroy() {
        // Clear the interval when the component is destroyed
        clearInterval(this.interval);
    },
};
</script>
