<template>
    <div>
        <b-button
            @click="showModal"
            variant="light"
            class="text-truncate"
            block
            size="sm"
            pill
        >
            <font-awesome-icon icon="fa-duotone fa-cog" />
            {{ $t("Settings") }}
        </b-button>

        <b-modal
            :title="$t('Invite Page Settings')"
            v-model="modal"
            hide-footer
            centered
            
        >
            <h5 class="text-primary">
                {{ $t("") }}
            </h5>

            <div class="border p-2 rounded">
                <b-form-checkbox v-model="privateInvitePage" switch>
                    Private
                    <div class="small text-warning">Not recommended</div>
                </b-form-checkbox>
            </div>

            <div class="small mt-2">
                <font-awesome-icon icon="fa-duotone fa-info-circle" />
                When you turn on private, your invite page will no longer show
                your name and photo, which may result in less signups
            </div>
        </b-modal>
    </div>
</template>



<script>
import { mapState } from "vuex";

export default {
    created() {
        if (this.onboardingUser.creatorInvitePagePrivate) {
            this.privateInvitePage = true;
        }
    },
    data() {
        return {
            privateInvitePage: false,
        };
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        modal: {
            get() {
                return this.$store.state.interface
                    .creatorInvitePageSettingsModalVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setCreatorInvitePageSettingsModalVisible",
                    value
                );
            },
        },
    },
    watch: {
        privateInvitePage(value) {
            this.api
                .post("/creatorSetting/privateInvitePage", {
                    enable: value,
                })
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                });
        },
    },
    methods: {
        closeModal() {
            this.$store.commit(
                "interface/setCreatorInvitePageSettingsModalVisible",
                false
            );
        },
        showModal() {
            this.$store.commit(
                "interface/setCreatorInvitePageSettingsModalVisible",
                true
            );
        },
    },
};
</script>