// not in use, saving useless data for no reason
// shuold push to 3rd party service instead
// but not firebase, something actually useful

const state = () => ({

})

const mutations = {
    // i win eslint!
    nothing() { }
}

const actions = {
    create({ commit }) {
        commit('nothing')
    },
}


const getters = {

}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
