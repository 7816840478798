import api from "@/service/api";
import router from "@/router";

import { getRandomString } from "@/utility/random";
import agoraStorage from "@/utility/agoraStorage";

import { createClient } from "agora-rtc-sdk-ng/esm";

import {
    createCameraVideoTrack,
    createMicrophoneAudioTrack,
} from "agora-rtc-sdk-ng/esm";

import {
    CALL_AUDIO_ENABLED,
    // CALL_AUDIO_DEVICE_ID,
    // CALL_VIDEO_DEVICE_ID,
    // CALL_VIDEO_ENABLED,
    // CALL_FIRST_LAUNCH,
} from "@/constant/localStorage";

const state = () => ({
    creatorLivestream: null,
    creatorLivestreamInteractions: null,
    starting: false,
    joined: false,
    stopping: false,
    hasVideoTrack: false,
    hasAudioTrack: false,
    isMicrophoneOn: false,
    pusherLiveChannel: null,
    heartbeatInterval: null,
})

const client = createClient({ mode: "live", codec: "vp8", role: "host" });

const mutations = {
    reset(state) {
        state.joined = false
        state.creatorLivestream = null
        state.creatorLivestreamInteractions = null
        state.hasVideoTrack = false
        state.hasAudioTrack = false
        state.starting = false
        state.stopping = false
        state.isMicrophoneOn = false
    },
    setIsMicrophoneOn(state, value) {
        state.isMicrophoneOn = value
    },
    setStarting(state, value) {
        state.starting = value
    },
    setStopping(state, value) {
        state.stopping = value
    },
    setHasVideoTrack(state, value) {
        state.hasVideoTrack = value
    },
    setHasAudioTrack(state, value) {
        state.hasAudioTrack = value
    },
    setHeartbeatInterval(state, value) {
        state.heartbeatInterval = value
    },
    setPusherLiveChannel(state, value) {
        state.pusherLiveChannel = value
    },
    setJoined(state, value) {
        state.joined = value
    },
    setCreatorLivestream(state, value) {
        state.creatorLivestream = value
    },
    setCreatorLivestreamInteractions(state, value) {
        state.creatorLivestreamInteractions = value
    },
}

const actions = {
    async toggleMicrophone({ state, commit }) {
        if (!agoraStorage.audioTrack) {
            agoraStorage.audioTrack = await createMicrophoneAudioTrack({
                microphoneId: this.audioDeviceId,
            });

            await client.publish(agoraStorage.audioTrack);
        }

        if (!state.isMicrophoneOn) {
            await agoraStorage.audioTrack.setMuted(false);
        } else {
            await agoraStorage.audioTrack.setMuted(true);
        }

        if (state.isMicrophoneOn) {
            localStorage.removeItem(CALL_AUDIO_ENABLED);
        } else {
            localStorage.setItem(CALL_AUDIO_ENABLED, true);
        }

        commit('setIsMicrophoneOn', !state.isMicrophoneOn);

    },
    async publishVideo({ commit }) {
        agoraStorage.videoTrack = await createCameraVideoTrack({
            facingMode: "user",
            cameraId: this.videoDeviceId,
        });

        await client.publish(agoraStorage.videoTrack);
        commit('setHasVideoTrack', true);

        return agoraStorage.videoTrack;
    },
    async join({ rootState, state, commit }) {
        await client.join(
            rootState.options.agora.appId,
            state.creatorLivestream.uuid,
            null,
            rootState.onboarding.user.id
        );

        commit('setJoined', true)
    },
    async stop({ state, commit, dispatch }) {
        if (state.heartbeatInterval) {
            clearInterval(state.heartbeatInterval);
        }

        if (!state.creatorLivestream) {
            console.error("state.creatorLivestream is empty")
            return
        }

        commit('setStopping', true)
        dispatch('pusher/unsubscribeLiveChannel', null, { root: true })
        dispatch('pusher/unsubscribeLivestreamChannel', state.creatorLivestream.id, { root: true })


        if (agoraStorage.videoTrack) {
            await agoraStorage.videoTrack.stop();
            await agoraStorage.videoTrack.close();
            agoraStorage.videoTrack = null;
        }

        if (agoraStorage.audioTrack) {
            await agoraStorage.audioTrack.stop();
            await agoraStorage.audioTrack.close();
            agoraStorage.audioTrack = null;
        }

        await client.leave();

        api
            .post(`/creatorLivestream/${state.creatorLivestream.id}/stop`).then(() => {
                commit('reset')
                commit("interface/setCreatorLivestreamModalVisible", false, { root: true });
            })
            .catch(() => { })
            .then(() => {
                commit('setStopping', false)
            })
    },
    async start({ commit, dispatch, state }, id) {
        commit('setStarting', true)

        try {
            const response = await api.post(`/creatorLivestream/${id}/start`)

            if (response?.data?.creatorLivestream) {
                commit('setCreatorLivestream', response.data.creatorLivestream)
                commit('setHeartbeatInterval', setInterval(() => { dispatch('heartbeat') }, 15000))

                dispatch('pusher/subscribeLiveChannel', null, { root: true })
                dispatch('pusher/subscribeLivestreamChannel', state.creatorLivestream.id, { root: true })
            }
        } catch (error) {
            console.error(error)
        }

        commit('setStarting', false)
    },
    heartbeat({ state }) {
        api.post(`/creatorLivestream/${state.creatorLivestream.id}/heartbeat`)
    },
    refresh({ state, dispatch }) {
        if (!state.creatorLivestream) {
            return
        }

        dispatch('get', state.creatorLivestream.id)
    },
    refreshInteractions({ state, commit }) {
        api.post(`/creatorLivestream/${state.creatorLivestream.id}/interactions`).then((response) => {
            if (response?.data?.creatorLivestreamInteractions) {
                commit('setCreatorLivestreamInteractions', response.data.creatorLivestreamInteractions)
            }
        })
    },
    get({ commit }, id) {
        api.post(`/creatorLivestream/${id}/get`).then((response) => {
            if (response?.data?.creatorLivestream) {
                commit('setCreatorLivestream', response.data.creatorLivestream)
            }
        })
    },
    pusherEvent({ state, dispatch }, data) {
        // if not live created event, it should already have the current live
        // once live is ended, allow new events to be processed
        if (
            state.creatorLivestream
            && state.creatorLivestream.streamInProgress
            && state.creatorLivestream.id !== data.id
        ) {
            console.error("pusher creatorLivestream event, id does not match current")
            return
        }

        if (data.event === 'refreshInteractions') {
            dispatch("audioAlert/chime", "new", { root: true });
            dispatch('refreshInteractions')
            return
        }

        if (data.event === 'updated') {
            dispatch('refresh')
            return
        }
    },
    create({ commit }) {
        api.post("/creatorLivestream/create")
            .then((response) => {
                if (response?.data?.creatorLivestream) {
                    router.push({
                        name: "creatorLivestreamView",
                        params: {
                            id: response.data.creatorLivestream.id,
                        },
                    });
                }
            })
            .catch(error => {
                if (error?.response?.data?.code === 'livestreamInProgress') {
                    commit('alert/setCurrentAlert', {
                        title: "Livestream in Progress",
                        message: "You already have another live stream in progress",
                    }, { root: true })
                }
            })
    },
    /////////////////////////////////////////////////////////////////
    // from call.js
    // below
    /////////////////////////////////////////////////////////////////
    refreshPreview({ dispatch, state }) {
        if (state.livePreview) {
            dispatch('preview', {
                userId: state.livePreview.userId
            })
        }
    },
    refreshCurrentLive({ state, dispatch }) {
        if (!state.currentLive) {
            return
        }

        dispatch('get', state.currentLive.id)
    },
    preview({ commit }, { userId } = {}) {
        commit('reset')
        commit("interface/setVideoLiveModalVisible", true, { root: true });

        api.post("/live/preview", {
            userId
        }).then((response) => {
            if (response?.data?.livePreview) {
                commit('setLivePreview', response.data.livePreview)
            }
        });
    },
    missedLive({ state, dispatch }) {
        if (!state.currentLive?.answeredAt) {
            dispatch('missed')
        }
    },
    openLive({ commit, dispatch }, { id, startVideo } = {}) {
        commit("interface/setVideoLiveModalVisible", true, { root: true });

        dispatch('get', id).then(() => {
            dispatch('seen')

            if (startVideo) {
                dispatch('startVideo')
            }
        })
    },
    answer({ commit, state, dispatch }) {
        api.post(`/live/${state.currentLive.id}/answer`).then(async (response) => {
            if (response?.data?.live) {
                commit('setCurrentLive', response.data.live)
                dispatch('startVideo')
            }
        });
    },
    missed({ commit, state }) {
        api.post(`/live/${state.currentLive.id}/missed`).then((response) => {
            if (response?.data?.live) {
                commit('setCurrentLive', response.data.live)
            }
        });
    },
    charge({ commit, state, rootState }) {
        if (rootState.system.cordova) {
            console.log('charges disabled on cordova')
            return;
        }

        const getUniqueBillingKey = () => {
            const randomString = getRandomString(32);
            commit('setUniqueBillingKey', randomString);
            return randomString;
        }

        const uniqueBillingKey = state.uniqueBillingKey
            ? state.uniqueBillingKey
            : getUniqueBillingKey()

        // generate unique billing key
        // check if live has live.uniqueBillingKey
        // prevent a duplicate video client from double, or even triple billing
        api.post(`/live/${state.currentLive.id}/charge`, {
            uniqueBillingKey
        }).then((response) => {
            if (response?.data?.live) {
                commit('setCurrentLive', response.data.live)
            }
        });
    },
    hangup({ state, dispatch, commit }) {
        api.post(`/live/${state.currentLive.id}/hangup`).then((response) => {
            if (response?.data?.live) {
                commit('setCurrentLive', response.data.live)
            }

            // survey them for live quality if the live actually took place
            if (response?.data?.live?.answeredAt && response?.data?.live?.endedAt) {
                // since we are preventing the close/reset
                // manually reset channel to close agora
                dispatch('endLive')
                return;
            }

            dispatch('close')
        })
    },
    seen({ state }) {
        api.post(`/live/${state.currentLive.id}/seen`)
    },
    close({ commit }) {
        commit('reset')
        commit("interface/setVideoLiveModalVisible", false, { root: true });
    },
    decline({ state, dispatch }) {
        api.post(`/live/${state.currentLive.id}/decline`).then(() => {
            dispatch('close')
        });
    },
    cancel({ state, dispatch }) {
        api.post(`/live/${state.currentLive.id}/cancel`)
        dispatch('close')
    },
    async signal({ dispatch }) {
        console.log(dispatch)
    },
    startVideo({ state, commit }) {
        commit('setChannel', state.currentLive.uuid)
    },
    endLive({ commit }) {
        commit('setChannel', null)
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
