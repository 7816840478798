import api from "@/service/api";

const state = () => ({
    error: null,
    uploadInProgress: [],
    uploadQueue: [],
    uploadQueueProcessing: false,
})

const mutations = {
    reset(state) {
        state.error = null
        state.previewPost = false
        state.uploadQueue = []
        state.uploadQueueProcessing = false
    },
    setError(state, value) {
        state.error = value
    },
    pushUploadInProgress(state, value) {
        state.uploadInProgress.push(value)
    },
    removeUploadInProgress(state, value) {
        state.uploadInProgress = state.uploadInProgress.filter(url => url !== value)
    },
    queueUpload(state, url) {
        state.uploadQueue.push(url)
    },
    clearUploadQueue(state) {
        state.uploadQueue = []
    },
    setUploadQueueProcessing(state, value) {
        state.uploadQueueProcessing = value
    },
}

const actions = {
    async scheduleQueueProcessing({ commit, state, rootState, dispatch }) {
        if (state.uploadQueueProcessing) {
            return
        }

        commit('setUploadQueueProcessing', true)

        if (!rootState.creatorPostEdit.creatorPost) {
            await dispatch("creatorPostEdit/create", null, { root: true })
        }

        commit("setError", null);

        state.uploadQueue.forEach(async (url) => {
            commit('pushUploadInProgress', url)

            await api
                .post("/post/uploadPhotoByUrl", {
                    creatorPostId: rootState.creatorPostEdit.creatorPost.id,
                    url
                })
                .then((response) => {
                    if (response?.data?.creatorPostPhoto) {
                        commit('removeUploadInProgress', url)
                        dispatch("creatorPostEdit/refresh", null, { root: true });
                    }
                })
                .catch((error) => {
                    if (error?.response?.data?.errors?.length) {
                        commit(
                            "setError",
                            error.response.data.errors.join(",")
                        );
                    }
                });
        })

        commit('clearUploadQueue')
        commit('setUploadQueueProcessing', false)
    },
}


const getters = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
