/* global IonicDeeplink  */
import { mapState } from "vuex";


// @FAIL
// this plugin seems to cause instability at least on the 6S for now
// since this morning the app is unstable, and also randomly not working
// punting on deeplinks for now, not worth instability

// @TODO
// this should be using the composition api
// once migrated to v3, good time to do it

export default {
    computed: {
        ...mapState("system", ["cordova"]),
    },
    created() {
        if (!this.cordova) {
            return;
        }

        this.$store.subscribeAction((action) => {
            if (action.type === "system/deviceReady") {
                IonicDeeplink.onDeepLink((data) => {
                    // data contents example
                    // {"path":"/app","queryString":"action=profile&userId=3807406","fragment":"","host":"findmate.app","url":"https://findmate.app/app?action=profile&userId=3807406","scheme":"https"}]

                    this.$store.dispatch('startup/handleUrl', { url: data?.queryString })
                });
            }
        });
    }
}