<template>
    <div class="p-3">
        <div v-if="onboardingUser.private">
            <b-alert variant="warning" show class="rounded-0 mb-0 border-0">
                <div class="d-flex align-items-center justify-content-between">
                    <div>{{ $t("Your profile is hidden") }}</div>

                    <b-btn
                        @click="$store.dispatch('setting/editPrivacy')"
                        variant="light"
                        size="sm"
                    >
                        {{ $t("Turn Off") }}
                    </b-btn>
                </div>
            </b-alert>
        </div>
        <div v-else>
            <div v-if="privacy">
                <b-alert variant="warning" show class="mt-2">
                    {{
                        $t(
                            "Are you sure? New users will no longer be able to discover your profile"
                        )
                    }}
                </b-alert>
                <div class="d-flex justify-content-between align-items-center">
                    <b-btn
                        class="mt-2 p-3 px-5"
                        @click="privacy = null"
                        variant="primary"
                        pill
                        size="lg"
                    >
                        {{ $t("Cancel") }}
                    </b-btn>

                    <b-btn
                        class="mt-2 px-0 text-secondary"
                        @click="confirmPrivate"
                        variant="link"
                    >
                        {{ $t("Yes, I'm sure. Hide my profile") }}
                    </b-btn>
                </div>
            </div>
            <div v-else>
                <div
                    class="
                        bg-light
                        p-3
                        d-flex
                        align-items-center
                        justify-content-between
                        rounded
                    "
                >
                    <div>
                        {{ $t("Hide my profile") }}
                    </div>
                    <b-form-checkbox v-model="privacy" switch size="lg">
                    </b-form-checkbox>
                </div>
                <div class="py-2">
                    <font-awesome-icon
                        icon="fa-light fa-check"
                        class="text-success"
                    />
                    {{
                        $t(
                            "Users will not be able to see your profile unless you already have a conversation with them"
                        )
                    }}
                </div>
                <div class="py-2">
                    <font-awesome-icon
                        icon="fa-light fa-check"
                        class="text-success"
                    />
                    {{
                        $t(
                            "You will still be able to chat with anyone you already have a conversation with, but no new people can discover your profile"
                        )
                    }}
                </div>
                <div class="py-2">
                    <font-awesome-icon
                        icon="fa-light fa-check"
                        class="text-success"
                    />
                    {{
                        $t(
                            "We will still notify you of any new messages from people you already started a conversation with"
                        )
                    }}
                </div>
                <b-btn
                    class="mt-2"
                    @click="
                        $store.dispatch('setting/editSetting', 'notification')
                    "
                    variant="light"
                    pill
                >
                    {{ $t("Manage Notifications") }}
                </b-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            privacy: false,
        };
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        confirmPrivate() {
            this.$store.dispatch("setting/editPrivacy", true);
        },
    },
};
</script>