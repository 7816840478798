<template>
    <div v-if="creatorUser">
        <div v-if="creatorUser.slug">
            <creator-invite-url />
        </div>
        <div v-else>
            <b-alert variant="primary" show>
                <h5>
                    <font-awesome-icon icon="fa-duotone fa-pencil" />
                    {{ $t("Claim your username") }}
                </h5>

                <div class="small">
                    {{
                        $t(
                            "Choose a unique username to personalize your profile page and make it your own"
                        )
                    }}
                </div>

                <b-input-group class="mt-2">
                    <template #prepend>
                        <b-input-group-text> @ </b-input-group-text>
                    </template>
                    <b-form-input
                        type="text"
                        v-model="slug"
                        :placeholder="$t('Username')"
                        autocomplete="off"
                        maxlength="16"
                    ></b-form-input>
                </b-input-group>

                <b-alert variant="danger" show v-if="error" class="small">
                    {{ error }}
                </b-alert>

                <b-button
                    @click="save"
                    variant="primary"
                    v-if="slug"
                    pill
                    block
                    class="mt-2"
                >
                    {{ $t("Claim Username") }}
                </b-button>
            </b-alert>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import copy from "clipboard-copy";

import CreatorInviteUrl from "@/components/creator/invite/CreatorInviteUrl";

export default {
    data() {
        return {
            hasCopied: false,
            slug: null,
            error: null,
        };
    },
    created() {},
    computed: {
        ...mapState("creatorDashboard", ["creatorUser"]),
    },
    methods: {
        copyUrl() {
            copy(this.creatorUser.inviteUrl);
            this.hasCopied = true;
        },
        save() {
            this.api
                .post("/invite/slug", {
                    username: this.slug,
                })
                .then(() => {
                    this.$store.dispatch("creatorDashboard/refresh");
                })
                .catch((error) => {
                    if (error?.response?.data?.errors?.username?.length) {
                        this.error =
                            error.response.data.errors.username.join(",");
                    }
                });
        },
    },
    components: {
        CreatorInviteUrl,
    },
};
</script>