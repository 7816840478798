<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <h3>{{ $t("More Options") }}</h3>
            <div>
                <b-btn variant="light" @click="closeOptions" block>
                    <font-awesome-icon size="lg" icon="fa-light fa-times" />
                </b-btn>
            </div>
        </div>
        <div class="my-3">
            <b-btn
                @click="enableTransgender"
                variant="light"
                block
                class="btn-brand"
                pill
            >
                {{ $t("I'm interested in meeting transgender users") }}
            </b-btn>
        </div>
    </div>
</template>


<script>
export default {
    methods: {
        enableTransgender() {
            this.api.post("/setting/transgenderInterest").then(() => {
                this.$store
                    .dispatch("options/refresh", null, {
                        root: true,
                    })
                    .then(() => {
                        this.$store.commit(
                            "interface/setOnboardingSeekingOptionsVisible",
                            false
                        );
                    });
            });
        },
        closeOptions() {
            this.$store.commit(
                "interface/setOnboardingSeekingOptionsVisible",
                false
            );
        },
    },
};
</script>