<template>
    <div class="p-3">
        <div v-if="understood">
            <delete-flow />
        </div>
        <div v-else>
            <b-btn
                @click="$store.dispatch('delete/changeReason')"
                variant="link"
                class="px-0"
            >
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
                {{ $t("Back") }}
            </b-btn>
            <div>
                {{ $t("Selected Reason") }}
            </div>
            <div>
                {{ selectedReason.label }}
            </div>

            <b-alert variant="danger" show class="mt-3">
                {{
                    $t(
                        "This action is permanent. Once you delete your account you will lose all history and will not be able to contact any users further."
                    )
                }}
            </b-alert>

            <div v-if="!understood" class="mt-3">
                <div>
                    <b-btn
                        variant="link"
                        @click="$store.dispatch('delete/changedMyMind')"
                        class="text-secondary"
                    >
                        {{ $t("I changed my mind, cancel") }}
                    </b-btn>
                </div>
                <div>
                    <b-btn
                        variant="link"
                        @click="understood = true"
                        class="text-danger"
                    >
                        {{ $t("I understand, proceed") }}
                    </b-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import DeleteFlow from "./DeleteFlow.vue";

export default {
    components: { DeleteFlow },
    data() {
        return {
            understood: null,
        };
    },
    computed: {
        ...mapState("delete", ["selectedReason"]),
    },
};
</script>