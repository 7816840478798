<template>
    <generic-page>
        <div v-if="creatorGallery">
            <b-btn
                variant="link"
                size="lg"
                class="pl-0"
                @click="
                    $router.push({
                        name: 'creatorGalleryView',
                        params: {
                            id: creatorGallery.id,
                        },
                    })
                "
            >
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
                {{ $t("Back") }}
            </b-btn>

            <div v-if="creatorGallery" :key="creatorGallery.id">
                <creator-gallery-edit-submit />

                <div v-if="creatorGallery.editable">
                    <div
                        class="my-1 d-flex align-items-center justify-content-between"
                    >
                        <h5 class="mb-0">
                            {{ $t("Edit Gallery") }}
                        </h5>
                        <div>
                            <b-dropdown
                                variant="light"
                                toggle-class="rounded-pill"
                                right
                            >
                                <template #button-content>
                                    <font-awesome-icon
                                        icon="fa-solid fa-ellipsis"
                                    />
                                </template>

                                <b-dropdown-header>
                                    {{ $t("Gallery Options") }}
                                </b-dropdown-header>
                                <b-dropdown-item
                                    v-if="!creatorGallery.purchased"
                                    @click="deleteGallery"
                                >
                                    {{ $t("Delete Gallery") }}
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>

                    <creator-gallery-edit-details />
                </div>
            </div>

            <creator-gallery-edit-media />
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import CreatorGalleryEditSubmit from "@/components/creator/gallery/edit/CreatorGalleryEditSubmit";
import CreatorGalleryEditDetails from "@/components/creator/gallery/edit/CreatorGalleryEditDetails";
import CreatorGalleryEditMedia from "@/components/creator/gallery/edit/CreatorGalleryEditMedia";

export default {
    created() {
        if (this.$route.params?.id) {
            this.$store.dispatch("creatorGallery/get", this.$route.params?.id);
        }
    },
    watch: {
        $route(to) {
            if (
                to?.params?.id !== this.$store.state.gallery.creatorGallery?.id
            ) {
                this.$store.dispatch(
                    "creatorGallery/get",
                    this.$route.params?.id
                );
            }
        },
    },
    computed: {
        ...mapState("creatorGallery", ["creatorGallery"]),
    },
    methods: {
        deleteGallery() {
            this.api
                .post("/creatorGallery/delete", {
                    creatorGalleryId: this.creatorGallery.id,
                })
                .then(() => {
                    this.$router.push({
                        name: "creatorGallery",
                    });
                });
        },
    },
    components: {
        CreatorGalleryEditDetails,
        CreatorGalleryEditSubmit,
        CreatorGalleryEditMedia,
    },
};
</script>