<template>
    <div>
        <div v-if="withdraw.type === 'wise'">
            <payout-wise />
        </div>
        <div v-if="withdraw.type === 'btc'">
            <payout-bitcoin />
        </div>
        <div v-if="withdraw.type === 'paypal'">
            <payout-paypal />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import PayoutWise from "@/components/withdraw/payout/PayoutWise";
import PayoutBitcoin from "@/components/withdraw/payout/PayoutBitcoin";
import PayoutPaypal from "@/components/withdraw/payout/PayoutPaypal";

export default {
    computed: {
        ...mapState("withdraw", ["withdraw"]),
    },
    components: {
        PayoutWise,
        PayoutBitcoin,
        PayoutPaypal,
    },
};
</script>