<template>
    <div v-if="creatorLivestream">
        <div v-if="isDismissed" class="text-center">
            <b-btn @click="showInviteDialog" variant="light" pill size="sm">
                Invite
            </b-btn>
        </div>
        <b-alert v-else variant="primary" show class="mb-0 p-2">
            <div>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <h5 class="mb-0">Invite Viewers</h5>
                        <div class="small">
                            Invite people to watch your livestream to maximize
                            earnings
                        </div>
                    </div>
                    <b-btn
                        v-if="false"
                        @click="dismissInviteDialog"
                        variant="light"
                        pill
                        size="sm"
                    >
                        Hide
                    </b-btn>
                </div>
            </div>
            <div class="mt-2">
                <b-input-group size="sm" class="">
                    <b-form-input
                        :value="creatorLivestream.urlVanity"
                        readonly
                    />
                    <b-input-group-append>
                        <b-btn v-if="copied" variant="primary" class="border">
                            <font-awesome-icon
                                class="mr-1"
                                icon="fa-light fa-check"
                            />
                            {{ $t("Copied") }}
                        </b-btn>
                        <b-btn
                            v-else
                            @click="copyUrl"
                            variant="primary"
                            class="border"
                        >
                            {{ $t("Copy") }}
                        </b-btn>
                    </b-input-group-append>
                </b-input-group>
            </div>
        </b-alert>
    </div>
</template>

<script>
import copy from "clipboard-copy";
import { mapState } from "vuex";

export default {
    data() {
        return {
            isDismissed: false,
            copied: false,
        };
    },
    mounted() {
        if (
            localStorage.getItem(
                `dissmissInviteDialog-${this.creatorLivestream.id}`
            )
        ) {
            this.isDismissed = true;
        }
    },
    computed: {
        ...mapState("creatorLivestream", ["creatorLivestream"]),
        ...mapState("options", ["prices"]),
    },
    methods: {
        showInviteDialog() {
            localStorage.removeItem(
                `dissmissInviteDialog-${this.creatorLivestream.id}`
            );

            this.isDismissed = false;
        },
        dismissInviteDialog() {
            localStorage.setItem(
                `dissmissInviteDialog-${this.creatorLivestream.id}`,
                1
            );

            this.isDismissed = true;
        },
        copyUrl() {
            copy(this.creatorLivestream.url);
            this.copied = true;
            setTimeout(() => (this.copied = false), 1500);
        },
    },
};
</script>