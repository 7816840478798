import api from "@/service/api";

const defaultState = () => ({
    contactType: null,
    contact: null,
    contactPrivacyEnabled: null,
    vettingRequired: null,
    premiumRequired: null,
    requestRejected: null,
})

const state = defaultState()

const mutations = {
    reset(state) {
        Object.assign(state, defaultState())
    },
    setContactType(state, value) {
        state.contactType = value;
    },
    setContact(state, value) {
        state.contact = value;
    },
    setContactPrivacyEnabled(state) {
        state.contactPrivacyEnabled = true;
    },
    setVettingRequired(state) {
        state.vettingRequired = true;
    },
    setPremiumRequired(state) {
        state.premiumRequired = true;
    },
    setRequestRejected(state) {
        state.requestRejected = true;
    },
}

const actions = {
    getContact({ rootState, commit }, type) {
        commit('reset')

        api
            .post("/contact/get", {
                userId: rootState.profile.profile.id,
                type
            })
            .then((response) => {
                if (response?.data?.contact && response?.data?.type) {
                    commit('setContactType', response.data.type)
                    commit('setContact', response.data.contact)
                } else if (response?.data?.requestRejected) {
                    commit('setRequestRejected')
                } else if (response?.data?.contactPrivacyEnabled) {
                    commit('setContactPrivacyEnabled')
                } else if (response?.data?.premiumRequired) {
                    commit('setPremiumRequired')
                } else if (response?.data?.vettingRequired) {
                    commit('setVettingRequired')
                }
            });
    },
    requestContact({ rootState, dispatch }) {
        api
            .post("/contact/request", {
                userId: rootState.profile.profile.id,
            })
            .then(() => {
                dispatch('profile/refresh', null, { root: true });
            });
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
