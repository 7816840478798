<template>
    <div class="overflow-auto hide-scrollbars bg-light">
        <div class="border-bottom border-light p-2 d-flex">
            <b-btn
                class="px-4 mr-2 btn-brand"
                :class="{
                    'border-primary border-heavy': isCurrentSubpage(item.path),
                }"
                :key="item.label"
                v-for="item in subMenuItems"
                @click="setPath(item)"
                variant="light"
                pill
            >
                {{ item.label }}
            </b-btn>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    created() {
        if (this.onboardingUser.showGalleryIndex) {
            this.subMenuItems.push({
                path: "/gallery",
                label: this.$t("Gallery"),
            });
        }
    },
    data() {
        return {
            subpage: "search",
            subMenuItems: [
                {
                    path: "/browse/search",
                    label: this.$t("Search"),
                },
                {
                    path: "/browse/swipe",
                    label: this.$t("Swipe"),
                },
                // {
                //     path: "/browse/videos",
                //     label: this.$t("Videos"),
                // },
            ],
        };
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        isCurrentSubpage(path) {
            return this.$route.path === path;
        },
        setPath(item) {
            this.$router.push(item.path);
        },
    },
    components: {},
};
</script>
        
        