import api from "@/service/api";


const state = () => ({
    source: null,
})

const mutations = {
    setSource(state, source) {
        state.source = source;
    },
}

const actions = {
    upgrade({ commit }, { source, noModal } = {}) {
        commit('setSource', source)

        if (!noModal) {
            commit('interface/setWebSubscriptionModalVisible', true, { root: true })
        }

        api.post('/premium/view', { source })

        // if (rootState.system.cordova) {
        //     this._vm.$bus.$emit(CORDOVA_START_SUBSCRIPTION);
        // } else {
        //     commit('interface/setWebSubscriptionModalVisible', true, { root: true })
        // }
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
