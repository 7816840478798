<template>
    <span>{{ localTime }}</span>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState("profile", ["profile"]),
        ...mapState("system", ["now"]),
        localTime() {
            if (!this.profile?.timezone) {
                return;
            }

            let date;

            try {
                date = new Intl.DateTimeFormat("default", {
                    hour: "numeric",
                    minute: "numeric",
                    timeZone: this.profile?.timezone,
                }).format(this.now);
            } catch (e) {
                return;
            }

            if (!date) {
                return;
            }

            return date;
        },
    },
};
</script>