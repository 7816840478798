<template>
    <div>
        <div class="d-flex align-items-center justify-content-between my-2">
            <div>
                <h5 class="mb-0">
                    {{ $t("Invite to Gallery") }}
                </h5>
                <div class="small text-warning">
                    <font-awesome-icon icon="fa-duotone fa-envelope" />

                    {{ $t("Sending email to ") }}
                    {{ inactiveCount }}
                    {{ $t(" users") }}
                </div>
            </div>
            <b-btn
                to="/creator/invite/inactive"
                variant="light"
                class="text-danger"
            >
                <font-awesome-icon icon="fa-light fa-times" class="mr-1" />
                {{ $t("Cancel") }}
            </b-btn>
        </div>

        <div class="my-3">
            <b-dropdown variant="primary" right toggle-class="rounded-pill">
                <template #button-content>
                    <span v-if="selectedGallery">
                        {{ selectedGallery.name }}
                    </span>
                    <span v-else>
                        {{ $t("Select Gallery") }}
                    </span>
                </template>

                <b-dropdown-item
                    @click="selectGallery(creatorGallery)"
                    :key="creatorGallery.id"
                    v-for="creatorGallery in invitableCreatorGalleries"
                >
                    {{ creatorGallery.name }}
                </b-dropdown-item>
            </b-dropdown>
        </div>

        <b-textarea
            :placeholder="$t('Write your message here...')"
            v-model="message"
            type="text"
            rows="3"
            max-rows="12"
        />

        <b-alert variant="primary" show class="small mb-0 rounded-0 border-0">
            <font-awesome-icon icon="fa-duotone fa-calendar-xmark" />
            {{
                $t(
                    "Delivery will be scheduled for their morning time based on their timezone"
                )
            }}
        </b-alert>

        <div class="my-3">
            <b-btn
                :disabled="!selectedGallery"
                @click="sendMessage"
                variant="primary"
                size="lg"
                pill
                block
            >
                {{ $t("Send Message") }}
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data: () => ({
        message: null,
        selectedGallery: null,
    }),
    mounted() {
        this.$store.dispatch("creatorGalleryInvite/refresh");
    },
    computed: {
        ...mapState("invitedUsers", ["inactiveCount"]),
        ...mapState("creatorGalleryInvite", ["invitableCreatorGalleries"]),
    },
    methods: {
        selectGallery(creatorGallery) {
            this.selectedGallery = creatorGallery;
        },
        async sendMessage() {
            try {
                await this.api.post("/invitedUser/gallery", {
                    creatorGalleryId: this.selectedGallery.id,
                    message: this.message,
                });
            } catch (e) {
                console.log(e);
            }

            this.$store.dispatch("invitedUsers/refreshInactive");
            this.$router.push("/creator/invite/inactive");
        },
    },
};
</script>