<template>
    <div>
        <div v-if="previewPost && createPostCreatorPost" class="p-3 border">
            <h5 class="d-flex align-items-center">
                <b-spinner type="grow" variant="danger" small class="mr-2" />
                {{ $t("Live Preview") }}
            </h5>
            <view-post :creatorPost="createPostCreatorPost" />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import ViewPost from "@/components/post/view/ViewPost";

export default {
    computed: {
        createPostCreatorPost() {
            return this.$store.state.creatorPostEdit?.creatorPost;
        },
        ...mapState("creatorPostEdit", ["previewPost"]),
    },
    components: {
        ViewPost,
    },
};
</script>