import api from "@/service/api";
import copy from "clipboard-copy";

const state = () => ({
    inviteUrl: false,
    hasCopied: false,
})

const mutations = {
    reset(state) {
        state.hasCopied = false;
    },
    setInviteUrl(state, value) {
        state.inviteUrl = value;
    },
    setHasCopied(state, value) {
        state.hasCopied = value;
    },
}

const actions = {
    copyUrl({ state, commit }) {
        if (state.inviteUrl) {
            copy(state.inviteUrl)
            commit('setHasCopied', true)
        }
    },
    refreshUrl({ commit }) {
        api
            .post("/invite/url")
            .then((response) => {
                if (response?.data?.url) {
                    commit('setInviteUrl', response.data.url);
                }
            });
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
