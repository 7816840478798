<template>
    <div class="p-3">
        <div v-if="cancelSuccessful">
            <div class="py-3 text-center">
                <div>
                    <font-awesome-icon
                        class="text-success"
                        icon="fa-light fa-check-circle"
                        size="5x"
                    />
                </div>
                <h3 class="mt-3">
                    {{ $t("Subscription Canceled") }}
                </h3>
                <div>
                    {{
                        $t(
                            "You will no longer be charged for this subscription"
                        )
                    }}
                </div>

                <b-btn
                    @click="closeWindow"
                    class="mt-3"
                    variant="primary"
                    pill
                    block
                >
                    {{ $t("Close Window") }}
                </b-btn>
            </div>
        </div>
        <div v-else-if="reasonId">
            <h5 class="mb-0">
                {{ $t("Cancelation Reason") }}
            </h5>

            {{ selectedReason.title }}

            <h5 class="mt-3">
                {{ $t("Do you have any other comments?") }}
            </h5>

            <b-form-textarea
                v-model="comment"
                maxlength="255"
                :placeholder="
                    $t('Add any other comments about why you are canceling')
                "
                rows="1"
                max-rows="20"
                tabindex="1"
            ></b-form-textarea>

            <div class="d-flex justify-content-between align-items-center">
                <b-button
                    variant="light"
                    class="text-primary mt-3"
                    @click="abortCancelation"
                    pill
                >
                    {{ $t("Abort Cancelation") }}
                </b-button>
                <b-button
                    variant="light"
                    class="text-danger mt-3"
                    @click="cancelSubscription"
                    pill
                >
                    {{ $t("Cancel Subscription") }}
                </b-button>
            </div>
        </div>
        <div v-else>
            <h5>
                {{ $t("Why do you want to cancel?") }}
            </h5>
            <b-list-group>
                <b-list-group-item
                    @click="selectReason(subscriptionCancelReason.id)"
                    :key="subscriptionCancelReason.id"
                    v-for="subscriptionCancelReason in subscriptionCancelReasons"
                    class="d-flex align-items-center justify-content-between"
                >
                    {{ subscriptionCancelReason.title }}
                    <font-awesome-icon
                        size="lg"
                        icon="fa-light fa-chevron-right"
                    />
                </b-list-group-item>
            </b-list-group>

            <b-button
                variant="light"
                class="text-primary mt-3"
                @click="abortCancelation"
                pill
            >
                {{ $t("Abort Cancelation") }}
            </b-button>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            reasonId: null,
            comment: null,
        };
    },
    computed: {
        ...mapState("userSubscription", [
            "userSubscription",
            "cancelSuccessful",
        ]),
        selectedReason() {
            return this.subscriptionCancelReasons?.find(
                (reason) => reason.id === this.reasonId
            );
        },
        ...mapState("options", ["subscriptionCancelReasons"]),
    },
    methods: {
        abortCancelation() {
            this.reasonId = null;

            this.$store.commit("userSubscription/setCancelSubscription", false);
        },
        confirm() {
            this.cancelSubscription(this.reasonId);
        },
        selectReason(reasonId) {
            this.reasonId = reasonId;
        },
        cancelSubscription() {
            this.api
                .post("/subscription/cancel", {
                    userSubscriptionId: this.userSubscription.id,
                    reasonId: this.reasonId,
                    comment: this.comment,
                })
                .then(() => {
                    this.$store.commit(
                        "userSubscription/setCancelSuccessful",
                        true
                    );

                    this.$store.dispatch("onboarding/status");
                });
        },
        closeWindow() {
            this.$store.commit("userSubscription/reset");

            this.$store.commit(
                "interface/setManageSubscriptionModalVisible",
                false
            );
        },
    },
};
</script>