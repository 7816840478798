<template>
    <div>
        <div class="bg-light small pl-3 text-secondary sticky-top">
            {{ $t("My Profile") }}
        </div>

        <b-list-group flush>
            <b-list-group-item
                button
                @click="
                    $store.commit('onboarding/setCurrentStep', step);
                    $store.commit(
                        'interface/setOnboardingSidebarVisible',
                        false
                    );
                "
                :key="step.slug"
                v-for="step in visibleSteps"
                :variant="currentStep.slug === step.slug ? 'primary' : null"
                class="border-lighter"
            >
                <span class="mr-1" :class="{ 'text-success': step.complete }">
                    <font-awesome-icon
                        :icon="
                            step.complete
                                ? 'fa-solid fa-check-circle'
                                : 'fa-solid fa-circle'
                        "
                    />
                </span>
                {{ step.name }}
            </b-list-group-item>
        </b-list-group>

        <div v-if="!onboardingUser?.ghostMode" class="bg-light p-3">
            <div class="small text-secondary">
                {{
                    $t(
                        "Want to finish signup on another device? Email yourself a link to login and pick up where you left off"
                    )
                }}
            </div>

            <div class="my-2">
                <div v-if="sentLoginLink" class="small text-info">
                    {{ $t("Sent, check your inbox") }}
                </div>
                <div v-else>
                    <b-button
                        @click="loginLink"
                        variant="light"
                        block
                        class="btn-brand"
                        pill
                    >
                        {{ $t("Email me a login link") }}
                    </b-button>
                </div>
            </div>

            <div v-if="false" class="small text-secondary mt-3">
                {{
                    $t(
                        "Need help with signup or something else we can assist with? Send us an email"
                    )
                }}
            </div>
            <b-button
                v-if="false"
                @click="emailSupport"
                variant="light"
                block
                class="btn-brand mt-2"
                pill
            >
                {{ $t("Email Support") }}
            </b-button>
        </div>

        <b-button
            class="text-secondary text-left p-3"
            v-b-toggle.more
            variant="link"
            size="sm"
            block
        >
            {{ $t("More") }}
        </b-button>
        <b-collapse id="more">
            <b-btn
                @click="$store.dispatch('user/logout')"
                variant="link"
                class="text-secondary"
                size="sm"
            >
                {{ $t("Logout") }}
            </b-btn>
        </b-collapse>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            sentLoginLink: false,
        };
    },
    computed: {
        ...mapState("onboarding", ["steps", "currentStep", "user"]),
        visibleSteps() {
            if (!this.steps) {
                return [];
            }

            return this.steps.filter((step) => !step.hide);
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        emailSupport() {
            this.$store.dispatch("support/email");
        },
        loginLink() {
            this.api.post("/setting/loginLink");
            this.sentLoginLink = true;
        },
    },
};
</script>