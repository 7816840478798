<template>
    <div id="onboarding-step-phone">
        <div v-if="selectedCountry">
            <div class="small text-secondary text-left">
                {{ $t("Phone Country") }}
            </div>
            <div class="text-left d-flex align-items-center">
                <img :src="selectedCountry.flagUrl" class="mr-2" />
                <h5 class="mb-0">{{ selectedCountry.name }}</h5>
                <b-btn
                    variant="light"
                    size="sm"
                    class="text-danger"
                    @click="selectedCountry = null"
                >
                    <font-awesome-icon size="lg" icon="fa-light fa-times" />
                </b-btn>
            </div>

            <b-form-group class="mt-3">
                <div class="small text-secondary text-left">
                    {{ $t("Phone Number") }}
                </div>
                <b-input-group size="lg" :prepend="selectedCountry.phonePrefix">
                    <b-input
                        @update="updatePhone"
                        v-model="phone"
                        type="text"
                        size="lg"
                        inputmode="numeric"
                        autocorrect="off"
                        autocapitalize="none"
                        autocomplete="tel-national"
                        :state="phoneValid"
                    />
                </b-input-group>

                <b-form-invalid-feedback v-if="error">
                    {{ $t("Please enter a valid number") }}
                </b-form-invalid-feedback>
            </b-form-group>

            <b-alert variant="primary" show class="small text-left">
                <div class="d-flex align-items-center">
                    <div class="mr-2">
                        <font-awesome-icon
                            icon="fa-duotone fa-shield-check"
                            size="2x"
                        />
                    </div>
                    {{
                        $t(
                            "Will not be shared with other users, for account and security purposes only"
                        )
                    }}
                </div>
            </b-alert>

            <div v-if="sendingCode">
                <b-spinner variant="secondary" />
                <div class="py-3">
                    {{ $t("Sending code...") }}
                </div>
            </div>
            <div v-else>
                <b-btn
                    ref="verifyPhoneNumberButton"
                    :disabled="!e164"
                    size="lg"
                    variant="primary"
                    class="px-5"
                    pill
                    @click="save"
                >
                    {{ $t("Continue") }}
                </b-btn>
            </div>
            <div class="mt-3">
                <b-btn
                    size="sm"
                    variant="link"
                    class="text-secondary"
                    @click="confirmSkip"
                >
                    {{ $t("Skip phone number") }}
                </b-btn>
            </div>
        </div>
        <div v-else class="position-relative">
            <fitty class="my-3" :options="{ maxSize: 21 }">
                <template v-slot:content>
                    {{ $t("Verify Mobile Phone") }}
                </template>
            </fitty>

            <div class="d-flex align-items-center text-primary small">
                <div class="mr-2">
                    <font-awesome-icon icon="fa-light fa-arrow-down" />
                </div>
                {{ $t("Select your phone country") }}
            </div>

            <div class="my-2">
                <b-form-input
                    type="text"
                    v-model="search"
                    size="lg"
                    :placeholder="$t('Filter by Name')"
                ></b-form-input>
            </div>

            <div
                class="scrollable-answers"
                v-on:scroll.passive="scrolled = true"
            >
                <b-btn
                    variant="light"
                    block
                    @click="selectCountry(country)"
                    :key="country.id"
                    v-for="country in matchingCountries"
                    class="btn-brand d-flex align-items-center"
                    size="lg"
                >
                    <img :src="country.flagUrl" class="mr-2" />
                    {{ country.name }}
                </b-btn>
                <scroll-for-more :scrolled="scrolled" />
            </div>
        </div>
    </div>
</template>

<style lang="scss">
#onboarding-step-phone {
    .input-tel__input,
    .country-selector__list__item {
        color: var(--dark);
    }
}
</style>

<script>
import { mapState } from "vuex";
import ScrollForMore from "@/components/utility/ScrollForMore";

export default {
    data() {
        return {
            sendingCode: false,
            search: null,
            selectedCountry: null,
            phone: null,
            phoneValid: null,
            error: null,
            e164: null,
            scrolled: null,
            submitting: null,
            confirmationResult: null,
        };
    },
    mounted() {
        if (this.user.countryId) {
            const country = this.countries.find(
                (country) => country.id === this.user.countryId
            );

            this.selectCountry(country);
        }
    },
    computed: {
        ...mapState("options", ["countries"]),
        ...mapState("onboarding", ["user"]),
        ...mapState("system", ["cordova"]),
        matchingCountries() {
            if (this.search) {
                return this.countries.filter((country) =>
                    country.name
                        .toLowerCase()
                        .includes(this.search.toLowerCase())
                );
            }
            return this.countries;
        },
    },
    methods: {
        confirmSkip() {
            const confirmation = confirm(
                this.$t(
                    "Are you sure? If you have trouble logging into your account in the future you may not be able to access it without your phone number."
                )
            );

            if (confirmation) {
                this.skip();
                this.$store.dispatch(
                    "appEvent/create",
                    "onboarding_phone_skipped"
                );
            } else {
                this.$store.dispatch(
                    "appEvent/create",
                    "onboarding_phone_prevent_skip_success"
                );
            }
        },
        async updatePhone(phone) {
            try {
                const { data } = await this.api.post("/phone/validate", {
                    phone,
                    country: this.selectedCountry.iso_alpha2,
                });

                this.phoneValid = data.valid ? true : false;

                if (this.phoneValid) {
                    this.e164 = data.e164;
                }
            } catch (e) {
                console.error(e);
            }
        },
        save() {
            this.api
                .post("/phone/save", {
                    phone: this.e164,
                    country: this.selectedCountry.iso_alpha2,
                })
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                });
        },
        selectCountry(country) {
            this.selectedCountry = country;
        },
        skip() {
            this.api
                .post("/phone/skip")
                .then(() => {})
                .catch(() => {})
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                });
        },
    },
    components: { ScrollForMore },
};
</script>