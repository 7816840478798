<template>
    <generic-page>
        <back-to-settings />
        <h5>
            {{ $t("Subscriptions") }}
        </h5>
        <div v-if="subscriptions">
            <div
                :key="subscription.defaultMap.id"
                v-for="subscription in subscriptions"
                @click="
                    manageSubscription(subscription.defaultMap.standardMap.id)
                "
            >
                <user-standard-avatar
                    :timestamp="subscription.defaultMap.created"
                    :standardMap="subscription.defaultMap.standardMap"
                    :nonPrimaryPhotos="false"
                    :hideBadges="true"
                    :userBetweenStatus="false"
                    size="small"
                    class="border-light border-top"
                >
                    <div class="text-secondary small">
                        {{ subscription.price }}/{{ $t("week") }}
                    </div>
                </user-standard-avatar>
            </div>
        </div>
    </generic-page>
</template>

<script>
import BackToSettings from "@/components/setting/utility/BackToSettings";
import UserStandardAvatar from "@/components/user/UserStandardAvatar";

export default {
    data() {
        return {
            subscriptions: null,
            unsubscribeToMutations: null,
        };
    },
    mounted() {
        this.refresh();

        const refreshEvents = [
            "userSubscription/setPurchaseSuccessful",
            "userSubscription/setCancelSuccessful",
        ];

        this.unsubscribeToMutations = this.$store.subscribe((mutation) => {
            if (refreshEvents.includes(mutation.type)) {
                this.refresh();
            }
        });
    },
    beforeDestroy() {
        if (this.unsubscribeToMutations) {
            this.unsubscribeToMutations();
        }
    },
    methods: {
        manageSubscription(userId) {
            this.$store.dispatch("userSubscription/get", userId);
        },
        refresh() {
            this.api.post("/subscription/index").then((response) => {
                if (response?.data?.subscriptions) {
                    this.subscriptions = response.data.subscriptions;
                }
            });
        },
    },
    components: {
        BackToSettings,
        UserStandardAvatar,
    },
};
</script>