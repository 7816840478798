<template>
    <div>
        <b-btn
            @click="call"
            variant="light"
            block
            class="rounded-0 mt-0"
            :size="$screen.lg ? 'lg' : 'sm'"
        >
            <font-awesome-icon icon="fa-duotone fa-circle-phone" size="lg" />
            <div>
                {{ $t("Call") }}
            </div>
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("profile", ["profile"]),
    },
    methods: {
        call() {
            this.$store.dispatch("call/start", {
                userId: this.profile.id,
            });
        },
    },
};
</script>