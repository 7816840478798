<template>
    <generic-page>
        <b-btn variant="link" size="lg" class="pl-0" @click="$router.back()">
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            Back
        </b-btn>

        <h5>My Withdrawals</h5>

        <div v-if="withdrawals">
            <b-table-simple striped>
                <thead>
                    <th>Credits</th>
                    <th>Local</th>
                    <th>When</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr :key="withdrawal.id" v-for="withdrawal in withdrawals">
                        <td>
                            <coin-badge :amount="withdrawal.amount" />
                        </td>
                        <td class="small">~{{ withdrawal.local }}</td>
                        <td class="small">
                            {{ withdrawal.fulfilledAtRelative }}
                        </td>
                        <td class="small">
                            <b-btn
                                size="sm"
                                @click="
                                    $router.push({
                                        name: 'earningsWithdrawView',
                                        params: {
                                            id: withdrawal.id,
                                        },
                                    })
                                "
                                variant="primary"
                                pill
                            >
                                <font-awesome-icon
                                    icon="fa-light fa-arrow-right"
                                />
                            </b-btn>
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            withdrawals: null,
        };
    },
    mounted() {
        this.api.post("/withdraw/index").then((response) => {
            this.withdrawals = response.data.withdrawals;
        });
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {},
};
</script>