<template>
    <video loop :controls="false" playsinline autoplay class="img-fluid w-100">
        <source :src="gif.images.looping.mp4" type="video/mp4" />
    </video>
</template>

<script>
export default {
    props: ["gif"],
};
</script>