<template>
    <div>
        <swipe-user />
    </div>
</template>


<script>
import SwipeUser from "@/components/swipe/SwipeUser";

export default {
    components: {
        SwipeUser,
    },
};
</script>
        
        