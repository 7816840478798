<template>
    <div>
        <back-to-dashboard />
        <div v-if="viewingSubscriber">viewing...</div>
        <div v-else>            
            <creator-subscriber-index />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import CreatorSubscriberIndex from "@/components/creator/subscriber/CreatorSubscriberIndex";

import BackToDashboard from "@/components/utility/BackToDashboard";

export default {
    computed: {
        creatorHasCreatedGallery() {
            return this.onboardingUser?.creatorHasCreatedGallery;
        },
        ...mapState("creatorSubscriber", ["viewingSubscriber"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {},
    components: {
        CreatorSubscriberIndex,
        BackToDashboard,
    },
};
</script>