// import api from "@/service/api";

const state = () => ({
    errorCode: null,
    purchaseInProgress: null,
    transactionView: 'default',
    transactions: null,
    price: null,
    purchaseComplete: false,
    createCard: false,
})

const mutations = {
    reset(state) {
        state.price = null
        state.errorCode = null
        state.purchaseComplete = false
        state.createCard = false
    },
    setErrorCode(state, value) {
        state.errorCode = value
    },
    setPurchaseInProgress(state, value) {
        state.purchaseInProgress = value
    },
    setTransactions(state, value) {
        state.transactions = value
    },
    setPurchaseComplete(state, value) {
        state.purchaseComplete = value
    },
    setPrice(state, value) {
        state.price = value
    },
    setCreateCard(state, value) {
        state.createCard = value
    },
}

const actions = {

}

const getters = {
    hasCreditCard(state, getters, rootState) {
        if (rootState.onboarding?.user?.primaryCreditCard) {
            return true
        }

        return false
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
