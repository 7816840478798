<template>
    <div>
        <creator-invite-guide-modal />

        <b-alert variant="primary" show v-if="!hasViewedInvite" class="mt-3">
            <h3>{{ $t("How Invites Work") }}</h3>
            <div class="mt-3">
                <font-awesome-icon
                    class="mr-1 text-success"
                    icon="fa-light fa-check"
                />
                {{
                    $t(
                        "Earn credits from inviting users to using your unique invite URL seen below, simply copy and paste or share"
                    )
                }}
            </div>
            <div class="mt-3">
                <font-awesome-icon
                    class="mr-1 text-success"
                    icon="fa-light fa-check"
                />
                {{
                    $t(
                        "Share it on apps like WhatsApp, Messenger with your friends in USA, Canada, Australia, Europe, etc."
                    )
                }}
            </div>
            <b-btn
                @click="influencerGuide"
                variant="primary"
                pill
                block
                class="mt-3"
            >
                {{ $t("Invite Users - Full Guide") }}
            </b-btn>
        </b-alert>

        <div v-if="!$route.meta?.engagementMode">
            <div class="d-flex align-items-center justify-content-between my-2">
                <div>
                    <h5 class="mb-0">
                        {{ $t("Invites") }}
                    </h5>
                </div>
                <b-btn
                    @click="influencerGuide"
                    variant="light"
                    class="border"
                    pill
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-info-circle"
                        size="lg"
                    />

                    {{ $t("Guide") }}
                </b-btn>
            </div>

            <creator-invite-url />

            <div class="d-flex align-items-center justify-content-between mt-4">
                <h5 class="mb-0">
                    {{ $t("Invited Users") }}
                </h5>
                <b-btn-group class="">
                    <b-btn
                        :variant="
                            inviteStatus === viewOptions.key
                                ? 'primary'
                                : 'light'
                        "
                        :class="
                            inviteStatus === viewOptions.key ? '' : 'bg-white'
                        "
                        :to="`/creator/invite/${viewOptions.key}`"
                        :key="viewOptions.key"
                        v-for="viewOptions in viewOptions"
                    >
                        {{ viewOptions.label }}
                    </b-btn>
                </b-btn-group>
            </div>
        </div>

        <div class="mt-2">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import CreatorInviteUrl from "@/components/creator/invite/CreatorInviteUrl";
import CreatorInviteGuideModal from "@/components/creator/invite/CreatorInviteGuideModal";

export default {
    data() {
        return {
            viewOptions: [
                {
                    key: "active",
                    label: this.$t("Active"),
                },
                {
                    key: "inactive",
                    label: this.$t("Inactive"),
                },
                {
                    key: "creator",
                    label: this.$t("Creators"),
                },
            ],
        };
    },
    computed: {
        hasViewedInvite() {
            return this.onboardingUser.influencerViewedInvite;
        },
        inviteStatus() {
            return this.$route.meta?.inviteStatus;
        },
        currentViewLabel() {
            return this.viewOptions.find(
                (option) => option.key === this.inviteStatus
            )?.label;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        influencerGuide() {
            this.$store.commit(
                "interface/setInfluencerGuideModalVisible",
                true
            );
        },
        setView(viewKey) {
            this.$store.dispatch("invitedUsers/setView", viewKey);
        },
    },
    components: {
        CreatorInviteUrl,
        CreatorInviteGuideModal,
    },
};
</script>
