<template>
    <b-alert v-if="!brand.isFindmate" variant="primary" class="small mt-3" show>
        <font-awesome-icon icon="fa-duotone fa-info-circle" />
        Charges on your card will appear as: <code>FINDMATE, LLC</code>
    </b-alert>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("brand", ["brand"]),
    },
};
</script>