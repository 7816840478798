<template>
    <div>
        <div v-if="managingPhoto" class="p-4">
            <create-post-manage-photo />
        </div>
        <div v-if="hasPhotos" class="mx-2">
            <b-row no-gutters>
                <b-col
                    cols="3"
                    :key="photo.id"
                    v-for="photo in photosExcludingPrimary"
                    @click="managePhoto(photo)"
                    class="fake-link position-relative"
                >
                    <span
                        v-if="!photo.approved"
                        class="
                            private-wrapper
                            position-absolute
                            absolute-center
                            text-warning text-center
                            small
                            rounded
                        "
                    >
                        <font-awesome-icon icon="fa-duotone fa-lock" />
                        {{ $t("Private") }}
                    </span>

                    <img class="img-fluid" :src="photo.thumbUrl" />
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import CreatePostManagePhoto from "@/components/post/create/CreatePostManagePhoto";

export default {
    computed: {
        hasPhotos() {
            return this.creatorPost?.photos?.length;
        },
        ...mapState("creatorPostEdit", ["creatorPost", "managingPhoto"]),
        ...mapGetters("creatorPostEdit", ["photosExcludingPrimary"]),
    },
    methods: {
        managePhoto(photo) {
            this.$store.dispatch("creatorPostEdit/managePhoto", photo);
        },
    },
    components: {
        CreatePostManagePhoto,
    },
};
</script>