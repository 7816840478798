<template>
    <div>
        <user-header :standardMap="creatorGallery.standardMap" />
        <div class="p-3 text-center bg-white rounded border">
            <h3>
                {{ creatorGallery.name }}
            </h3>

            <b-row no-gutters>
                <b-col
                    cols="4"
                    :key="userMedia.id"
                    v-for="userMedia in creatorGallery.medias"
                    class="p-2"
                >
                    <gallery-media-item :userMedia="userMedia" />
                    <b-btn variant="light" @click="viewMedia(userMedia)" block>
                        {{ $t("View") }}
                    </b-btn>
                </b-col>
            </b-row>

            <div class="mt-3">
                <gallery-rating />
            </div>
        </div>

        <div v-if="hasMoreGalleries" class="p-2 bg-light mt-3">
            <h5 class="mb-0">
                {{ $t("More Galleries from ") }}
                {{ creatorGallery.standardMap.name }}
            </h5>
            <div
                :key="creatorGallery.gallery.id"
                v-for="creatorGallery in galleriesFromThisCreatorExceptCurrent"
            >
                <gallery-index-item
                    class="mt-2 rounded border"
                    :creatorGallery="creatorGallery.gallery"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import GalleryMediaItem from "@/components/gallery/GalleryMediaItem";
import GalleryIndexItem from "@/components/galleryIndex/GalleryIndexItem";
import GalleryRating from "@/components/gallery/GalleryRating";

import UserHeader from "@/components/userHeader/UserHeader";

export default {
    data() {
        return {
            creatorGalleries: [],
        };
    },
    mounted() {
        this.api
            .post("/creatorGallery/index", {
                userId: this.creatorGallery.userId,
            })
            .then((response) => {
                if (response?.data?.creatorGalleries) {
                    this.creatorGalleries = response.data.creatorGalleries;
                }
            });
    },
    computed: {
        hasMoreGalleries() {
            return this.galleriesFromThisCreatorExceptCurrent.length > 0;
        },
        galleriesFromThisCreatorExceptCurrent() {
            return this.creatorGalleries.filter(
                (creatorGallery) =>
                    creatorGallery.gallery.id !== this.creatorGallery.id
            );
        },
        ...mapState("gallery", ["creatorGallery"]),
    },
    methods: {
        viewMedia(userMedia) {
            this.$store.dispatch("system/openBrowser", userMedia.url);
        },
    },
    components: {
        GalleryMediaItem,
        GalleryIndexItem,
        GalleryRating,
        UserHeader,
    },
};
</script>