
<template>
    <div>
        <b-badge
            variant="light"
            v-b-tooltip.hover
            class="border text-primary"
            pill
            v-if="isFreeTime"
            :title="$t(`The first ${freeSeconds} seconds are free`)"
        >
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            Free Time
            {{ countdown }}
        </b-badge>
        <div v-else class="d-flex align-items-center">
            <div v-if="creatorLivestream.streamInProgress">
                <duration-timer class="mr-2" />
            </div>
            <coin-badge
                v-if="totalCharges"
                :amount="totalCharges"
                label="Charges"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import { FREE_TIME_SECONDS } from "@/constant/call";

import DurationTimer from "@/components/utility/DurationTimer";

export default {
    data() {
        return {
            isFreeTime: true,
            timer: 0,
            billingInterval: null,
            startBillingTimeout: null,
            countdownInterval: null,
            freeSeconds: FREE_TIME_SECONDS,
            countdown: FREE_TIME_SECONDS,
        };
    },
    mounted() {
        if (this.consumedFreePeriod) {
            this.startBilling();
        } else {
            this.api.post(
                `/livestream/${this.creatorLivestream.id}/consumeFreePeriod`
            );

            this.startBillingTimeout = setTimeout(() => {
                this.startBilling();
            }, this.freeSeconds * 1000);

            this.countdownInterval = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                } else {
                    clearInterval(this.countdownInterval);
                }
            }, 1000);
        }
    },
    computed: {
        ...mapState("livestream", [
            "creatorLivestream",
            "totalCharges",
            "consumedFreePeriod",
        ]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        startBilling() {
            this.isFreeTime = false;

            // initial charge for first minute
            this.$store.dispatch("livestream/charge");

            this.billingInterval = setInterval(() => {
                this.timer++;

                if (this.timer === 60) {
                    this.timer = 0;
                    this.$store.dispatch("livestream/charge");
                }
            }, 1000);
        },
    },
    beforeDestroy() {
        if (this.startBillingTimeout) {
            clearTimeout(this.startBillingTimeout);
        }

        if (this.billingInterval) {
            clearInterval(this.billingInterval);
        }
    },
    components: {
        DurationTimer,
    },
};
</script>