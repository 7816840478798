<template>
    <div v-if="displayPrivateLabel" class="p-2 bg-white border-top">
        <div class="d-flex align-items-center justify-content-between">
            <h5 class="text-warning mb-0">
                <font-awesome-icon icon="fa-duotone fa-lock-open" />
                <span>
                    {{ $t("Private") }}
                </span>
            </h5>

            <b-badge
                pill
                variant="light"
                class="d-flex align-items-center justify-content-center"
            >
                <font-awesome-icon
                    class="mr-1 text-success"
                    icon="fa-light fa-check"
                />
                {{ $t("Subscribed") }}
            </b-badge>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        mySubscriptionPrice() {
            return this.onboardingUser.subscriptionPrices.find(
                (sub) => sub.creatorUserId === this.creatorPost.userId
            ).price;
        },
        displayPrivateLabel() {
            if (
                this.creatorPost.private &&
                this.subscribedToCreatorUserId(this.creatorPost.userId)
            ) {
                return true;
            }

            return false;
        },
        ...mapGetters("userSubscription", [
            "canViewPost",
            "canSubscribe",
            "subscribedToCreatorUserId",
        ]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    props: ["creatorPost"],
};
</script>