<template>
    <div>
        <user-standard-avatar
            :standardMap="standardMap"
            :nonPrimaryPhotos="false"
            :hideBadges="true"
            :userBetweenStatus="false"
            size="small"
            class="border-light border-top"
        >
            <livestream-billing />
        </user-standard-avatar>
    </div>
</template>

<script>
import UserStandardAvatar from "@/components/user/UserStandardAvatar";
import { mapState } from "vuex";

import LivestreamBilling from "@/components/livestream/LivestreamBilling";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("livestream", [
            "creatorLivestream",
            "standardMap",
            "agora",
        ]),
    },
    components: {
        UserStandardAvatar,
        LivestreamBilling,
    },
};
</script>