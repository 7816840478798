<template>
    <div>
        <b-form-group class="text-left">
            <b-textarea
                @update="validate"
                @focus="hasFocused = true"
                :placeholder="
                    $t(
                        'Make a great first impression and let other people know what you\'re all about'
                    )
                "
                v-model="story"
                type="text"
                rows="3"
                max-rows="12"
                :state="valid"
            />

            <b-form-invalid-feedback v-if="forbiddenCharactersInUse.length">
                {{ $t("The following characters are not allowed") }}
                {{ forbiddenCharactersInUse.join(" ") }}
            </b-form-invalid-feedback>
        </b-form-group>

        <b-btn
            :disabled="!valid"
            @click="save"
            variant="primary"
            block
            size="lg"
        >
            {{ $t("Save") }}
        </b-btn>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            forbiddenCharactersInUse: [],
            story: null,
            valid: null,
        };
    },
    mounted() {
        if (this.user?.story) {
            this.story = this.user.story;
        }
    },
    computed: {
        ...mapState("onboarding", ["user"]),
        ...mapState("options", ["prohibitedCharacters"]),
    },
    methods: {
        validate() {
            if (!this.story) {
                this.valid = null;
                return;
            }

            this.forbiddenCharactersInUse = [];

            this.prohibitedCharacters.forEach((character) => {
                if (this.story.indexOf(character) > -1) {
                    this.forbiddenCharactersInUse.push(character);
                }
            });

            if (this.forbiddenCharactersInUse.length) {
                this.valid = false;
                return;
            }

            this.valid = true;
            return;
        },
        save() {
            this.api
                .post("/bio/story", {
                    story: this.story,
                })
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                });
        },
    },
};
</script>