import api from "@/service/api";

const state = () => ({
    withdrawalError: null,
    withdraw: null,
    currentWithdraw: null,
    types: null,
    successMessage: null,
    lastUserPayee: null,
    submitting: null,
})

const mutations = {
    reset(state) {
        state.currentWithdraw = null
        state.withdraw = null
        state.successMessage = null
        state.withdrawalError = null
        state.lastUserPayee = null
    },
    setError(state, value) {
        state.withdrawalError = value
    },
    setSuccessMessage(state, value) {
        state.successMessage = value
    },
    setWithdraw(state, value) {
        state.withdraw = value
    },
    setCurrentWithdraw(state, value) {
        state.currentWithdraw = value
    },
    setSubmitting(state, value) {
        state.submitting = value
    },
    setTypes(state, value) {
        state.types = value
    },
    setLastUserPayee(state, value) {
        state.lastUserPayee = value
    },
}

const trackConversionWithdrawal = (amount) => {
    try {
        gtag('event', 'conversion', {
            'send_to': 'AW-1044313876/CmhSCJ3ZtaAZEJTu-_ED',
            'value': amount,
            'currency': 'USD'
        });
    } catch (e) {
        // ignore
    }
}

/* global gtag */
const actions = {
    submit({ commit, state, dispatch }) {
        commit("setSubmitting", true);

        api
            .post("/withdraw/submit", {
                withdrawId: state.withdraw.id
            })
            .then(() => {
                trackConversionWithdrawal(state.withdraw.amountUsd)

                dispatch("onboarding/status", null, { root: true });
                commit("reset");
            })
            .catch(() => { })
            .then(() => {
                commit("setSubmitting", false);
            })
    },
    updateType({ state, dispatch }, type) {
        api
            .post("/withdraw/type", {
                withdrawId: state.withdraw.id,
                type: type.key
            })
            .then((response) => {
                if (response?.data?.withdraw) {
                    dispatch("setWithdraw", response.data.withdraw);
                }
            })
    },
    cancel({ commit, dispatch, state }) {
        if (!state.withdraw) {
            return;
        }

        api
            .post("/withdraw/cancel", {
                withdrawId: state.withdraw.id
            })
            .then(() => {
                commit('reset')
                dispatch("onboarding/status", null, { root: true });
            })
    },
    refresh({ dispatch, state }) {
        api
            .post("/withdraw/get", {
                withdrawId: state.withdraw.id
            })
            .then((response) => {
                if (response?.data?.withdraw) {
                    dispatch("setWithdraw", response.data.withdraw);
                }
            })
    },
    getCurrent({ commit }) {
        api
            .post("/withdraw/current")
            .then((response) => {
                if (response?.data?.withdraw) {
                    commit("setCurrentWithdraw", response.data.withdraw);
                }
            })
    },
    setWithdraw({ state, commit }, withdraw) {
        commit('setWithdraw', withdraw)

        if (!state.types) {
            api
                .post("/withdraw/types")
                .then((response) => {
                    if (response?.data?.types) {
                        commit("setTypes", response.data.types);
                    }
                })
        }
    },
    create({ commit, dispatch }) {
        api
            .post("/withdraw/create")
            .then((response) => {
                if (response?.data?.withdraw) {
                    dispatch("setWithdraw", response.data.withdraw);
                }
            }).catch(error => {
                if (error?.response?.data?.code === 'insufficientFunds') {
                    commit('setError', error.response.data.message)
                }
            })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
