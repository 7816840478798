<template>
    <div>
        <b-alert
            v-if="onboardingUser.creator"
            variant="warning"
            show
            class="small text-left"
        >
            <h5 class="text-center">
                <font-awesome-icon class="text-danger" icon="fa-solid fa-ban" />
                No Fake Photos
            </h5>
            <div>
                We have a zero tolerance policy towards any fake photos, do not
                upload any photos that do not belong to you
            </div>
        </b-alert>
        <div class="text-left mt-4">
            <h5 class="text-center">
                <font-awesome-icon
                    icon="fa-duotone fa-globe"
                    class="text-primary"
                />
                Photo Rules
            </h5>
            <b-list-group>
                <b-list-group-item>
                    <font-awesome-icon
                        icon="fa-duotone fa-face-awesome"
                        class="text-warning"
                    />
                    {{ $t("Must show your face") }}
                </b-list-group-item>
                <b-list-group-item>
                    <font-awesome-icon
                        icon="fa-light fa-check"
                        class="text-primary"
                    ></font-awesome-icon>
                    {{ $t("Will be shown to users who match your filters") }}
                </b-list-group-item>
                <b-list-group-item>
                    <font-awesome-icon
                        icon="fa-solid fa-ban"
                        class="text-danger"
                    />
                    {{ $t("No explicit photos") }}
                </b-list-group-item>
            </b-list-group>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
};
</script>