<template>
    <div>
        <b-btn
            @click="scrollTop"
            variant="light"
            class="
                fab fab-right
                action-button
                glass glass-light
                border-0
                text-primary
            "
        >
            <span class="text-dark">
                <font-awesome-icon size="lg" icon="fa-duotone fa-circle-up" />
            </span>
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("search", ["hasScrolled"]),
    },
    methods: {
        scrollTop() {
            this.$scrollToTop();
        },
    },
};
</script>
        
        