import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import social from './social'
import affiliate from './affiliate'
import system from './system'

Vue.use(VueRouter);

const routes = [
    ...social,
    ...affiliate,
    ...system,
];

const router = new VueRouter({
    mode: process.env.VUE_APP_TARGET === 'cordova' ? 'hash' : 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

// @QUIRK
// this hook can be fired on mobile before appReady is true
// so prevent any navigation/... calls until appReady has been tired
router.beforeEach(async (to, from, next) => {
    if (
        store.state.system.cordova &&
        !store.state.system.appReadyToStart
    ) {
        next();
        return;
    }

    // this is a hack
    // profile/close is fired by navigation/closeOpenLayers
    // because the layer is technically open
    // but the initial navigation from DefaultPage
    // does not need to close any layers, so hence this miserable cancerous hack
    // that "fixes" the circular glitch of opening a profile closing a profile
    // inside = dead
    if (to?.query?.dontCloseLayers) {
        next();
        return;
    }

    const closedAllLayers = await store.dispatch(
        "navigation/closeOpenLayers"
    );

    if (closedAllLayers) {
        next();
    } else {
        next(false);
    }
});

export default router