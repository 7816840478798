<template>
    <b-alert variant="primary" show class="border-0 rounded-0 text-center">
        <h5>Free Daily Giveaway</h5>
        Every day you can claim your free gift which can be given to creators
        any time and never expire
        <div class="mt-2">
            <daily-giveaway-wheel />
        </div>
    </b-alert>
</template>



<script>
import { mapState } from "vuex";

import DailyGiveawayWheel from "@/components/dailyGiveaway/DailyGiveawayWheel";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {},
    components: {
        DailyGiveawayWheel,
    },
};
</script>