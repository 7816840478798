<template>
    <div v-if="stale">
        <div class="mb-3 d-flex align-items-center">
            <b-btn variant="light" @click="refresh" pill class="mr-2">
                <font-awesome-icon icon="fa-light fa-arrows-rotate" />
                {{ $t("Refresh") }}
            </b-btn>
            <div class="small text-warning">
                {{ $t("Search results are out of date") }}
            </div>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            refreshAfterMinutes: 5,
        };
    },
    computed: {
        ...mapState("system", ["now"]),
        ...mapState("search", ["lastRefresh", "dismissed"]),
        stale() {
            if (!this.lastRefresh || !this.now) {
                return false;
            }

            const now = this.$date(this.now);
            const lastRefresh = this.$date(this.lastRefresh);

            const differenceInMinutes = now.diff(lastRefresh, "minute");

            if (differenceInMinutes > this.refreshAfterMinutes) {
                return true;
            }

            return false;
        },
    },
    methods: {
        refresh() {
            this.$store.dispatch("search/resetSearch");
            this.$store.dispatch("search/refresh");
        },
    },
};
</script>