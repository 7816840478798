<template>
    <div>
        <div v-if="suggestWise">
            <b-alert variant="warning" show class="rounded-0 border-0 mb-0">
                <h5>
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                    />
                    {{ $t("Bitcoin Not Recommended") }}
                </h5>
                <div>
                    <font-awesome-icon
                        class="mr-1 text-success"
                        icon="fa-light fa-check"
                    />
                    {{ $t("Your country supports bank and mobile wallet") }}
                </div>
                <div class="small mt-2">
                    {{
                        $t(
                            "We recommend to use bank transfer for lowest fees and fastest payment. Bitcoin has much higher fees, and you will get more money by choosing bank transfer"
                        )
                    }}
                </div>

                <b-btn
                    @click="cancelWithdraw"
                    variant="primary"
                    size="lg"
                    class="mt-4"
                    pill
                    block
                >
                    {{ $t("Change payout method") }}
                </b-btn>
                <b-btn
                    @click="proceedWithBitcoin"
                    variant="link"
                    class="mt-3 text-secondary"
                    block
                >
                    {{ $t("Continue with Bitcoin") }}
                </b-btn>
            </b-alert>
        </div>
        <div v-else-if="withdraw.btcAddress" class="my-3">
            <b-alert variant="dark" show>
                <h5 class="mb-0">
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                    />
                    {{ $t("Confirm Address") }}
                </h5>

                <div class="mt-3">
                    <code class="lead">
                        {{ withdraw.btcAddress }}
                    </code>
                </div>
                <div class="small mt-2">
                    {{
                        $t(
                            "If you need to make a correction, press cancel to start over"
                        )
                    }}
                </div>
                <div class="small mt-2 text-warning">
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                    />
                    {{
                        $t(
                            "Bitcoin transfers cannot be reversed, if sent to the wrong address you will not get your money"
                        )
                    }}
                </div>
            </b-alert>

            <b-btn
                @click="submit"
                :disabled="submitting"
                variant="primary"
                size="lg"
                class="mt-3"
                pill
                block
            >
                {{ $t("Submit Withdrawal") }}
            </b-btn>
        </div>
        <div v-else>
            <div
                v-if="onboardingUser.btcAddress"
                class="bg-light rounded p-3 mb-3"
            >
                <div>
                    {{ $t("Previous address") }}
                </div>
                <code class="">
                    {{ onboardingUser.btcAddress }}
                </code>
                <div class="mt-3">
                    <b-btn
                        @click="usePreviousAddress"
                        variant="primary"
                        pill
                        block
                    >
                        {{ $t("Use this address") }}
                    </b-btn>
                </div>
            </div>

            <div class="mt-3">
                {{ $t("Bitcoin (BTC) Wallet Address") }}
            </div>

            <b-alert variant="danger" show v-if="error">
                {{ error }}
            </b-alert>

            <b-input-group>
                <b-form-input
                    v-model="btcAddress"
                    tabindex="1"
                    maxlength="252"
                    class="border-light"
                    size="lg"
                    autocomplete="off"
                    :placeholder="$t('BTC Address')"
                ></b-form-input>
                <b-input-group-append>
                    <b-btn @click="save" variant="primary" size="lg">
                        {{ $t("Save") }}
                    </b-btn>
                </b-input-group-append>
            </b-input-group>

            <b-button v-b-toggle.btcHelp variant="light" pill class="mt-3">
                <font-awesome-icon icon="fa-duotone fa-info-circle" size="lg" />
                {{ $t("BTC Help") }}
            </b-button>
            <b-collapse id="btcHelp">
                <div class="mt-3">
                    <div>
                        {{
                            $t(
                                "Copy your wallet address from your crypto provider. Often you need to click 'Receive' to reveal the address to send to."
                            )
                        }}
                    </div>
                    <div class="mt-3">
                        {{
                            $t(
                                "The address will be a bunch of random letters and numbers like this example:"
                            )
                        }}
                    </div>
                    <code class="mt-3">1BvBMSEYstWetqTFn5Au4m4GFg7xJaNVN2</code>
                    <div class="mt-3">
                        {{
                            $t(
                                "If you don't have a BTC wallet, we recommend Coinbase or Binance to securely store your Bitcoin"
                            )
                        }}
                    </div>
                    <div class="mt-3">
                        <a
                            class="btn btn-link"
                            target="_blank"
                            href="https://coinbase.com"
                            >Coinbase</a
                        >
                        <a
                            class="btn btn-link"
                            target="_blank"
                            href="https://binance.com"
                            >Binance</a
                        >
                    </div>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            dismissedWiseSuggestion: false,
            btcAddress: null,
            error: null,
        };
    },
    computed: {
        suggestWise() {
            return (
                this.onboardingUser.countryHasWise &&
                !this.dismissedWiseSuggestion
            );
        },
        ...mapState("withdraw", ["withdraw", "lastUserPayee", "submitting"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        proceedWithBitcoin() {
            this.dismissedWiseSuggestion = true;
        },
        cancelWithdraw() {
            this.$store.dispatch("withdraw/cancel");
        },
        usePreviousAddress() {
            this.btcAddress = this.onboardingUser.btcAddress;
            this.save();
        },
        save() {
            this.api
                .post("/withdraw/bitcoin", {
                    withdrawId: this.withdraw.id,
                    btcAddress: this.btcAddress,
                })
                .then((response) => {
                    if (response?.data?.withdraw) {
                        this.$store.dispatch(
                            "withdraw/setWithdraw",
                            response.data.withdraw
                        );
                    }
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error = error?.response?.data?.message;
                    }
                });
        },
        submit() {
            this.$store.dispatch("withdraw/submit");
        },
        reuseLastUserPayee() {
            this.paypal = this.lastUserPayee.meta.paypal;
            this.save();
        },
        locallyRemoveLastUserPayee() {
            // remote will overwrite this upon refresh, as intended
            this.$store.commit("withdraw/setLastUserPayee", null);
        },
    },
    components: {},
};
</script>