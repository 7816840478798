<template>
    <b-alert
        show
        v-if="blockedUserIds.includes(profile.id)"
        variant="danger"
        class="rounded-0 border-0 mb-0"
    >
        <h1>
            {{ $t("Blocked") }}
        </h1>
        {{
            $t(
                "This user is blocked, they can no longer view your profile or send you messages"
            )
        }}
        <div class="my-3">
            <b-btn
                @click="$store.dispatch('user/unblock', profile.id)"
                variant="light"
            >
                {{ $t("Unblock User") }}
            </b-btn>
        </div>
    </b-alert>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("profile", ["profile"]),
        ...mapState("user", ["blockedUserIds"]),
    },
    components: {},
};
</script>