<template>
    <div>
        <b-dropdown
            size="sm"
            variant="light"
            toggle-class="rounded-pill border-0 opacity-50"
            no-caret
            dropup
            right
        >
            <template #button-content>
                <font-awesome-icon icon="fa-solid fa-ellipsis" />
            </template>

            <b-dropdown-item v-if="!isMine" @click="reportPost">
                {{ $t("Report Post") }}
            </b-dropdown-item>
            <b-dropdown-item v-if="!isMine" @click="blockUser">
                {{ $t("Block User") }}
            </b-dropdown-item>
            <b-dropdown-divider v-if="!isSingle"> </b-dropdown-divider>
            <b-dropdown-item v-if="!isSingle" @click="directLink">
                {{ $t("View Post Directly") }}
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>


<script>
import { mapState } from "vuex";
export default {
    computed: {
        isSingle() {
            return this.$route.name === "publicPost";
        },
        isMine() {
            return this.onboardingUser?.id === this.creatorPost.userId;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        directLink() {
            this.$router.push({
                name: "publicPost",
                params: {
                    slug: this.creatorPost.slug,
                },
            });
        },
        deletePost() {
            this.$store.commit(
                "postIndex/blockCreatorPostId",
                this.creatorPost.id
            );

            this.api.post("/post/delete", {
                creatorPostId: this.creatorPost.id,
            });
        },
        editPost() {
            this.$store.dispatch("creatorPostEdit/edit", this.creatorPost.id);
        },
        reportPost() {
            this.$store.commit(
                "postIndex/blockCreatorPostId",
                this.creatorPost.id
            );

            this.api.post("/post/report", {
                creatorPostId: this.creatorPost.id,
            });
        },
        blockUser() {
            this.$store.dispatch("user/block", this.creatorPost.userId);
        },
    },
    props: ["creatorPost"],
};
</script>