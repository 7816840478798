<template>
    <div>
        <b-alert variant="primary" show>
            <h5>You're Eligible for Idle Pay</h5>

            To help promote our live streaming system, we're offering a select
            few creators the ability to earn credits while going live even when
            you have no viewers

            <b-list-group class="my-3">
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    Earnings per minute
                    <coin-badge
                        :amount="config.creatorLivestreamIdlePayPerMinute"
                    />
                </b-list-group-item>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    Daily limit
                    <div>
                        {{ config.creatorLivestreamIdleDailyLimit }} minutes per
                        day
                    </div>
                </b-list-group-item>
            </b-list-group>

            <div class="mt-2">
                <b-button v-b-toggle.howIdlePayWorks variant="primary" pill>
                    How it works?
                </b-button>
                <b-collapse id="howIdlePayWorks">
                    <h5 class="mt-3">How Idle Pay Works</h5>

                    <div>
                        When your live stream has no viewers, you will still
                        continue to earn credits up to your daily limit. We
                        recommend going live several times per day for 5-10
                        minute sessions to try and attract viewers to your
                        stream, which will earn more than idle pay itself
                    </div>

                    <h5 class="mt-3">What happens when viewers join?</h5>

                    <div>
                        Idle pay is only active when the stream is empty. When
                        viewers join the stream the idle pay will be paused.
                        When viewers leave the stream, idle pay will
                        automatically resume
                    </div>

                    <h5 class="mt-3">
                        What is expected of me while I'm streaming?
                    </h5>

                    <div>
                        You just have to be in the video frame for the entire
                        stream. You can be doing your hair, doing your makeup,
                        working on things, browsing the internet, or anything
                        really. You just have to be ready in case viewers join
                        and make sure your face is always showing live
                    </div>

                    <h5 class="mt-3">What if I need to take a break?</h5>

                    <div>
                        You can take a break anytime, just make sure to stop the
                        stream. You can stop and start a new stream anytime, you
                        don't have to use all of your allowed time per day at
                        once, you can go live several times per day to break it
                        up into shorters sessions, and try to attract more
                        viewers to your stream
                    </div>

                    <h5 class="mt-3">
                        <font-awesome-icon
                            class="text-warning"
                            icon="fa-duotone fa-triangle-exclamation"
                        />
                        Make sure your face is visible
                    </h5>

                    <div>
                        Our system will occasionally scan the stream to ensure
                        your face is visible. We will also manually review
                        streams to prevent abuse. Remember, idle pay is a
                        privilege, not a right. If we discover any abuse, we
                        will remove access to this feature
                    </div>
                </b-collapse>
            </div>
        </b-alert>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("options", ["config"]),
    },
};
</script>