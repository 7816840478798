<template>
    <div >
        <div v-if="hasActiveSubscriptions">
            <b-alert variant="warning" show>
                <h5>
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                    />
                    {{ $t("Active Subscriptions") }}
                </h5>
                <div>
                    {{
                        $t(
                            "To prevent continued billing, please cancel all active subscriptions first"
                        )
                    }}
                </div>
                <b-btn
                    @click="manageSubscriptions"
                    variant="light"
                    pill
                    class="mt-3"
                >
                    {{ $t("Manage Subscriptions") }}
                </b-btn>
            </b-alert>
        </div>
        <div v-else>
            <div class="alert-primary d-flex align-items-center p-3">
                <div>
                    {{ $t("Sorry to see you go...") }}
                    <div class="small text-secondary">
                        {{ $t("Why were you looking to delete your account?") }}
                    </div>
                </div>
            </div>
            <b-list-group v-if="!selectedReason" flush>
                <b-list-group-item
                    @click="$store.dispatch('delete/selectReason', reason)"
                    :key="reason.reasonId"
                    v-for="reason in deleteReasons"
                    button
                    class="d-flex align-items-center justify-content-between"
                >
                    <div>
                        {{ reason.label }}
                    </div>
                    <div>
                        <font-awesome-icon
                            size="lg"
                            icon="fa-light fa-chevron-right"
                        />
                    </div>
                </b-list-group-item>
            </b-list-group>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        hasActiveSubscriptions() {
            return (
                this.$store.state.onboarding?.user?.subscribedUserIds?.length >
                0
            );
        },
        ...mapState("delete", ["selectedReason", "scheduled", "undeleteable"]),
        ...mapState("options", ["deleteReasons"]),
    },
    methods: {
        manageSubscriptions() {
            this.$store.commit("interface/setSettingEditing", false);
            this.$router.push("/account/subscriptions");
        },
    },
};
</script>