<template>
    <div v-if="false">
        <daily-giveaway-index />
    </div>
</template>



<script>
import { mapState } from "vuex";

import DailyGiveawayIndex from "@/components/dailyGiveaway/DailyGiveawayIndex";

export default {
    computed: {
        eligibleForDailyGiveaway() {
            return this.onboardingUser?.eligibleForDailyGiveaway;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        DailyGiveawayIndex,
    },
};
</script>