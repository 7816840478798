<template>
    <generic-page>
        <div class="my-2 d-flex align-items-center justify-content-between">
            <h5 class="mb-0">
                {{ $t("Share Credits") }}
            </h5>
            <b-btn @click="createCredit" pill variant="primary">
                {{ $t("Create") }}
            </b-btn>
        </div>

        <div v-if="loading">
            <generic-loading />
        </div>
        <div v-else-if="userCreditShares?.length">
            <b-list-group>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                    button
                    :key="userCreditShare.defaultMap.id"
                    v-for="userCreditShare in userCreditShares"
                    @click="
                        $router.push({
                            name: 'affiliateCreditShareView',
                            params: {
                                id: userCreditShare.defaultMap.id,
                            },
                        })
                    "
                >
                    {{ userCreditShare.defaultMap.createdRelative }}
                    <div
                        class="d-flex align-items-center justify-content-between"
                    >
                        <font-awesome-icon
                            v-if="userCreditShare.defaultMap.consumed"
                            class="mr-1 text-success"
                            icon="fa-light fa-check"
                        />
                        <div v-if="userCreditShare.defaultMap.amount">
                            <coin-badge
                                :amount="userCreditShare.defaultMap.amount"
                            />
                        </div>
                        <div v-else class="text-warning small">
                            {{ $t("No deposit") }}
                        </div>
                    </div>
                </b-list-group-item>
            </b-list-group>
        </div>
        <div v-else>
            <b-alert variant="primary" show>
                <h5>
                    <font-awesome-icon
                        class="ml-3 mr-1 float-right"
                        icon="fa-light fa-arrow-up"
                    />
                    {{ $t("Share credits by link") }}
                </h5>

                <font-awesome-icon icon="fa-duotone fa-info-circle" />
                {{
                    $t(
                        "When a logged in user clicks on the link they will receive the deposited credits"
                    )
                }}
            </b-alert>
        </div>

        <div
            v-if="showPagination"
            class="d-flex align-items-center justify-content-between p-3"
        >
            <div>
                <b-btn
                    @click="previous"
                    variant="light"
                    size="lg"
                    block
                    pill
                    class="p-3 border-0 d-flex align-items-center"
                    :disabled="page === 1"
                >
                    <font-awesome-icon icon="fa-light fa-arrow-left" />
                    <div class="ml-2 d-none d-md-block">
                        {{ $t("Previous") }}
                    </div>
                </b-btn>
            </div>
            <div class="text-center text-secondary">Page {{ page }}</div>
            <div>
                <b-btn
                    @click="next"
                    variant="light"
                    size="lg"
                    block
                    pill
                    class="p-3 border-0 d-flex align-items-center"
                    :disabled="!hasMorePages"
                >
                    <div class="mr-2 d-none d-md-block">
                        {{ $t("Next") }}
                    </div>
                    <font-awesome-icon icon="fa-light fa-arrow-right" />
                </b-btn>
            </div>
        </div>
    </generic-page>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            userCreditShares: false,
            hasMorePages: false,
            page: 1,
        };
    },
    created() {
        this.refresh();
    },
    computed: {
        showPagination() {
            if (!this.hasMorePages && this.page === 1) {
                return false;
            }

            return true;
        },
    },
    methods: {
        refresh() {
            this.loading = true;

            this.api
                .post("/userCreditShare/index", {
                    page: this.page,
                })
                .then((response) => {
                    if (response?.data?.userCreditShares) {
                        this.userCreditShares = response.data.userCreditShares;
                    }

                    this.hasMorePages = response.data.hasMorePages;
                })
                .catch(() => {})
                .then(() => {
                    this.loading = false;
                });
        },
        createCredit() {
            this.api.post("/userCreditShare/create").then((response) => {
                if (response?.data?.userCreditShare) {
                    this.$router.push({
                        name: "affiliateCreditShareView",
                        params: {
                            id: response.data.userCreditShare.id,
                        },
                    });
                }
            });
        },
        next() {
            this.page++;
            this.$scrollToTop();
            this.refresh();
        },
        previous() {
            this.page--;
            this.$scrollToTop();
            this.refresh();
        },
    },
    components: {},
};
</script>