<template>
    <generic-page>
        <back-to-dashboard />

        <div class="d-flex align-items-center justify-content-between mt-3">
            <div class="d-flex align-items-center">
                <h5 class="mb-0 mr-2">Calls</h5>
                <b-badge variant="light" pill class="bg-white border">
                    Dispute Rate: {{ currentDisputeRate }}%
                </b-badge>
            </div>
            <camera-preview />
        </div>

        <div class="py-2">
            <div v-if="loading">
                <generic-loading />
            </div>
            <div v-else>
                <div
                    :key="call.id"
                    v-for="call in calls"
                    @click="
                        $router.push({
                            name: 'creatorCallView',
                            params: {
                                id: call.defaultMap.id,
                            },
                        })
                    "
                >
                    <user-standard-avatar
                        :standardMap="call.standardMap"
                        :timestamp="call.defaultMap.created"
                        :nonPrimaryPhotos="false"
                        :hideBadges="true"
                        :userBetweenStatus="false"
                        size="small"
                        class="border-light border-top"
                    >
                        <div class="small">
                            {{ call.defaultMap.status }}
                        </div>

                        <div class="small text-secondary">
                            <coin-badge
                                class="mr-1"
                                v-if="call.defaultMap.totalEarnings"
                                :amount="call.defaultMap.totalEarnings"
                            />
                            <span>
                                {{ call.defaultMap.duration }}
                            </span>
                        </div>
                    </user-standard-avatar>
                </div>
            </div>
        </div>

        <generic-pagination
            @next="next"
            @previous="previous"
            :hasMorePages="hasMorePages"
            :page="page"
        />
    </generic-page>
</template>

<script>
import BackToDashboard from "@/components/utility/BackToDashboard";
import GenericPagination from "@/components/utility/GenericPagination";
import UserStandardAvatar from "@/components/user/UserStandardAvatar";
import CameraPreview from "@/components/agora/CameraPreview";

export default {
    data() {
        return {
            page: 1,
            hasMorePages: false,
            calls: null,
            loading: false,
            currentDisputeRate: null,
        };
    },
    created() {
        this.refresh();
    },
    methods: {
        next() {
            this.page++;
            this.refresh();
        },
        previous() {
            this.page--;
            this.refresh();
        },
        refresh() {
            this.loading = true;

            this.api
                .post("/call/index", {
                    page: this.page,
                })
                .then((response) => {
                    this.calls = response?.data?.calls;
                    this.hasMorePages = response?.data?.hasMorePages;
                })
                .catch(() => {})
                .then(() => {
                    this.loading = false;
                });

            this.api.post("/call/disputeRate").then((response) => {
                this.currentDisputeRate = response?.data?.currentDisputeRate;
            });
        },
    },
    components: {
        BackToDashboard,
        GenericPagination,
        UserStandardAvatar,
        CameraPreview,
    },
};
</script>