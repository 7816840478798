<template>
    <div>
        <div class="agora-dialog">
            <div class="content-video">
                <div
                    class="remoteVideoContainer bg-light rounded d-flex align-items-center justify-content-center"
                >
                    <video
                        v-show="isCameraOn"
                        id="remote-video"
                        :class="{
                            'video-fullscreen': !isVideoSubed,
                        }"
                    ></video>
                    <div v-if="!isCameraOn">
                        <font-awesome-icon
                            size="5x"
                            icon="fa-duotone fa-camera-slash"
                        />
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-center py-3">
                <b-btn
                    class="call-button"
                    :class="{ 'text-danger': !isCameraOn }"
                    @click="toggleCamera"
                    variant="secondary"
                >
                    <font-awesome-icon
                        v-if="isCameraOn"
                        icon="fa-duotone fa-camera"
                    />
                    <font-awesome-icon
                        v-else
                        icon="fa-duotone fa-camera-slash"
                    />
                </b-btn>

                <b-btn
                    class="call-button"
                    @click="toggleMicrophone"
                    :class="{ 'text-danger': !isMicrophoneOn }"
                    variant="secondary"
                >
                    <font-awesome-icon
                        v-if="isMicrophoneOn"
                        icon="fa-duotone fa-microphone"
                    />
                    <font-awesome-icon
                        v-else
                        icon="fa-duotone fa-microphone-slash"
                    />
                </b-btn>

                <b-dropdown
                    variant="secondary"
                    right
                    toggle-class="rounded-pill call-button"
                >
                    <template #button-content>
                        <font-awesome-icon icon="fa-solid fa-ellipsis" />
                    </template>

                    <b-dropdown-header v-if="videoDevices?.length">
                        Select Camera
                    </b-dropdown-header>
                    <b-dropdown-item
                        @click="selectVideoDevice(videoDevice)"
                        :key="videoDevice.deviceId"
                        v-for="videoDevice in videoDevices"
                    >
                        <font-awesome-icon
                            v-if="videoDeviceId === videoDevice.deviceId"
                            class="mr-1 text-primary"
                            icon="fa-light fa-check"
                        />
                        {{ videoDevice.label }}
                    </b-dropdown-item>

                    <b-dropdown-header v-if="audioDevices?.length">
                        Select Microphone
                    </b-dropdown-header>
                    <b-dropdown-item
                        @click="selectAudioDevice(audioDevice)"
                        :key="audioDevice.deviceId"
                        v-for="audioDevice in audioDevices"
                    >
                        <font-awesome-icon
                            v-if="audioDeviceId === audioDevice.deviceId"
                            class="mr-1 text-primary"
                            icon="fa-light fa-check"
                        />
                        {{ audioDevice.label }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
    </div>
</template>


<script>
import {
    getDevices,
    createCameraVideoTrack,
    createMicrophoneAudioTrack,
} from "agora-rtc-sdk-ng/esm";

import { createClient } from "agora-rtc-sdk-ng/esm";
import { mapState } from "vuex";

import {
    CALL_AUDIO_DEVICE_ID,
    CALL_VIDEO_DEVICE_ID,
    CALL_AUDIO_ENABLED,
    CALL_VIDEO_ENABLED,
    CALL_FIRST_LAUNCH,
} from "@/constant/localStorage";

export default {
    data() {
        return {
            isCameraOn: false,
            isMicrophoneOn: false,
            isVideoSubed: false,
            isAudioSubed: false,
            videoTrack: null,
            audioTrack: null,
            client: null,
            audioDevices: null,
            videoDevices: null,
            audioDeviceId: localStorage.getItem(CALL_AUDIO_DEVICE_ID),
            videoDeviceId: localStorage.getItem(CALL_VIDEO_DEVICE_ID),
        };
    },
    async mounted() {
        this.client = await createClient({ mode: "rtc", codec: "vp8" });

        const channel = `test-${this.onboardingUser.id}`;

        await this.client.join(
            this.agora.appId,
            channel,
            null,
            this.onboardingUser.id
        );

        this.refreshDevices();

        if (localStorage.getItem(CALL_AUDIO_ENABLED)) {
            this.toggleMicrophone();
        }

        if (localStorage.getItem(CALL_VIDEO_ENABLED)) {
            this.toggleCamera();
        }

        // very first launch, turn both on by default
        if (!localStorage.getItem(CALL_FIRST_LAUNCH)) {
            this.toggleMicrophone();
            this.toggleCamera();
            localStorage.setItem(CALL_FIRST_LAUNCH, true);
        }
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("options", ["agora"]),
    },
    methods: {
        selectVideoDevice(device) {
            this.videoDeviceId = device.deviceId;

            if (this.videoTrack) {
                this.videoTrack.setDevice(this.videoDeviceId);
            }

            localStorage.setItem(CALL_VIDEO_DEVICE_ID, this.videoDeviceId);
        },
        selectAudioDevice(device) {
            this.audioDeviceId = device.deviceId;

            if (this.audioTrack) {
                this.audioTrack.setDevice(this.audioDeviceId);
            }

            localStorage.setItem(CALL_AUDIO_DEVICE_ID, this.audioDeviceId);
        },
        async refreshDevices() {
            const devices = await getDevices();

            this.audioDevices = devices.filter(function (device) {
                return device.kind === "audioinput";
            });

            this.videoDevices = devices.filter(function (device) {
                return device.kind === "videoinput";
            });
        },
        async toggleCamera() {
            if (!this.videoTrack) {
                this.videoTrack = await createCameraVideoTrack({
                    facingMode: "user",
                    cameraId: this.videoDeviceId,
                });

                this.videoTrack.play("remote-video");
            }

            if (!this.isCameraOn) {
                this.videoTrack.setMuted(false);
            } else {
                this.videoTrack.setMuted(true);
            }

            if (this.isCameraOn) {
                localStorage.removeItem(CALL_VIDEO_ENABLED);
            } else {
                localStorage.setItem(CALL_VIDEO_ENABLED, true);
            }

            this.isCameraOn = !this.isCameraOn;
        },
        async toggleMicrophone() {
            if (!this.audioTrack) {
                const audioTrack = await createMicrophoneAudioTrack({
                    microphoneId: this.audioDeviceId,
                });

                this.audioTrack = audioTrack;
            }

            if (!this.isMicrophoneOn) {
                await this.audioTrack.setMuted(false);
                await this.audioTrack.play();
            } else {
                await this.audioTrack.setMuted(true);
                await this.audioTrack.stop();
            }

            if (this.isMicrophoneOn) {
                localStorage.removeItem(CALL_AUDIO_ENABLED);
            } else {
                localStorage.setItem(CALL_AUDIO_ENABLED, true);
            }

            this.isMicrophoneOn = !this.isMicrophoneOn;
        },
    },
    async beforeDestroy() {
        if (this.videoTrack) {
            await this.videoTrack.stop();
            await this.videoTrack.close();
            this.videoTrack = null;
        }

        if (this.audioTrack) {
            await this.audioTrack.stop();
            await this.audioTrack.close();
            this.audioTrack = null;
        }

        if (this.client) {
            await this.client.leave();
            this.client = null;
        }
    },
};
</script>
