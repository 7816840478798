<template>
    <div>
        <b-alert
            v-if="onboardingUser.scheduledDelete"
            variant="danger"
            show
            class="rounded-0 mb-0 border-0"
        >
            <h5 class="mb-0 d-flex align-items-center">
                {{ $t("Deletion Scheduled") }}
            </h5>

            <div class="small text-secondary mt-2">
                {{
                    $t(
                        "Your account is scheduled to be deleted. In case you change your mind, you can reactivate your account anytime."
                    )
                }}
            </div>
            <div class="mt-3 mb-2">
                <b-btn
                    @click="$store.dispatch('delete/cancel')"
                    variant="primary"
                    block
                    pill
                >
                    {{ $t("Reactivate Account") }}
                </b-btn>
            </div>
        </b-alert>
        <b-alert
            v-if="onboardingUser.private"
            variant="warning"
            show
            class="rounded-0 mb-0 border-0"
        >
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    <font-awesome-icon
                        icon="fa-duotone fa-eye-slash"
                        class="text-warning"
                    />
                    {{ $t("Your profile is hidden") }}
                </div>

                <b-btn
                    @click="$store.dispatch('setting/editPrivacy')"
                    variant="light"
                    size="sm"
                    pill
                >
                    Turn Off
                </b-btn>
            </div>
        </b-alert>
        <b-alert
            v-if="onboardingUser.bypassedOnboarding"
            variant="dark"
            show
            class="rounded-0 mb-0 border-0"
        >
            <div class="small">
                <font-awesome-icon icon="fa-duotone fa-info-circle" size="lg" />

                {{
                    $t(
                        "Your profile is incomplete. Other users may not want to interact with you until you complete it"
                    )
                }}
            </div>

            <b-btn
                @click="$store.dispatch('onboarding/resetFlags')"
                variant="primary"
                pill
                block
                class="mt-3"
            >
                {{ $t("Complete Profile") }}
            </b-btn>
        </b-alert>
        <b-alert
            v-if="onboardingUser.suggestVerification"
            variant="primary"
            show
        >
            <h5>
                <font-awesome-icon icon="fa-duotone fa-shield-check" />
                Profile Verification
            </h5>

            <div class="">
                Get verified so other users know you are legit. Until you get
                verified, you may not earn as much. Other users might not
                interact with you until you get verified
            </div>

            <b-btn
                @click="
                    $store.dispatch(
                        'setting/editOnboardingStep',
                        'verification'
                    )
                "
                variant="primary"
                pill
                block
                class="mt-3"
            >
                Get Verified
            </b-btn>
        </b-alert>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
};
</script>