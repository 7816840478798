<template>
    <div>
        <h5>What is your first name?</h5>
        <div class="small text-secondary">Letters only</div>

        <b-alert v-if="error" show variant="danger">
            {{ error }}
        </b-alert>

        <b-form-group class="text-left mt-4">
            <b-input
                @update="validate"
                v-model="name"
                :placeholder="$t('First Name')"
                type="text"
                size="lg"
                :state="valid"
            />
            <b-form-invalid-feedback v-if="forbiddenCharactersInUse.length">
                {{ $t("The following characters are not allowed") }}
                {{ forbiddenCharactersInUse.join(" ") }}
            </b-form-invalid-feedback>
        </b-form-group>

        <b-btn
            :disabled="!valid"
            @click="save"
            variant="primary"
            block
            size="lg"
        >
            {{ $t("Save") }}
        </b-btn>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            forbiddenCharactersInUse: [],
            name: null,
            error: null,
            valid: null,
        };
    },
    mounted() {
        if (this.user?.name) {
            this.name = this.user.name;
        }
    },
    computed: {
        ...mapState("onboarding", ["user"]),
        ...mapState("options", ["prohibitedCharacters"]),
    },
    methods: {
        validate() {
            if (!this.name) {
                this.valid = null;
                return;
            }

            this.forbiddenCharactersInUse = [];

            this.prohibitedCharacters.forEach((character) => {
                if (this.name.indexOf(character) > -1) {
                    this.forbiddenCharactersInUse.push(character);
                }
            });

            if (this.forbiddenCharactersInUse.length) {
                this.valid = false;
                return;
            }

            this.valid = true;
            return;
        },
        save() {
            this.error = null;

            this.api
                .post("/bio/name", {
                    name: this.name,
                })
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error = error.response.data.message;
                    } else {
                        this.error = "Error saving";
                    }
                });
        },
    },
};
</script>