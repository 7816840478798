<template>
    <generic-page>
        <back-to-dashboard />

        <div
            class="d-flex align-items-center justify-content-between mt-2 mb-1"
        >
            <h5>
                {{ $t("Jackpot") }}
            </h5>

            <div>
                <creator-jackpot-help />
            </div>
        </div>

        <div v-if="currentJackpot">
            <b-list-group>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    <div>Jackpot Value</div>
                    <coin-badge :amount="currentJackpot.defaultMap.balance" />
                </b-list-group-item>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    <div>Local Currency</div>
                    {{ currentJackpot.balanceLocal }}
                </b-list-group-item>
                <b-list-group-item>
                    <div
                        class="d-flex align-items-center justify-content-between"
                    >
                        <div>Days Remaining</div>
                        {{ currentJackpot.defaultMap.daysRemaining }}
                    </div>
                    <b-progress
                        height=".25rem"
                        class="mt-2"
                        variant="primary"
                        :value="
                            currentJackpot.defaultMap.percentageOfMonthPassed
                        "
                        max="100"
                    ></b-progress>
                </b-list-group-item>
            </b-list-group>

            <creator-jackpot-leaderboard :jackpot="currentJackpot" />
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import BackToDashboard from "@/components/utility/BackToDashboard";
import CreatorJackpotHelp from "@/components/creator/jackpot/CreatorJackpotHelp";
import CreatorJackpotLeaderboard from "@/components/creator/jackpot/CreatorJackpotLeaderboard";
import { mapState } from "vuex";

export default {
    mounted() {
        this.$store.dispatch("creatorDashboard/refreshJackpot");
    },
    computed: {
        ...mapState("creatorDashboard", ["currentJackpot"]),
    },
    components: {
        BackToDashboard,
        CreatorJackpotHelp,
        CreatorJackpotLeaderboard,
    },
};
</script>