<template>
    <div
        id="widget-swipe-user"
        class="d-flex flex-column full-height-page-including-submenu"
    >
        <div class="bg-light small pl-3 text-secondary"></div>

        <div class="flex-grow-1 d-flex align-items-center px-3">
            <div class="swiper shadow" ref="widgetSwipeUser">
                <div class="swiper-wrapper">
                    <div
                        class="swiper-slide"
                        :key="currentPhotoToRate.photoDefaultMap.id"
                        v-for="currentPhotoToRate in photosToRate"
                    >
                        <swipe-photo :photo="currentPhotoToRate" />
                    </div>
                    <div class="swiper-slide">
                        <div
                            class="loading d-flex align-items-center justify-content-center"
                        >
                            <div v-if="reachedTheEnd" class="px-3">
                                {{
                                    $t(
                                        "You've reached the end of the list, check back later to see new people, or change your filters to show more users"
                                    )
                                }}
                            </div>
                            <div v-else>
                                <div class="mb-2">
                                    <b-spinner variant="secondary" />
                                </div>
                                {{ $t("Loading") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
                <div class="swiper-scrollbar"></div>
            </div>
        </div>
        <swipe-actions />
    </div>
</template>

<script>
import Swiper, { Navigation } from "swiper";

import "swiper/swiper-bundle.css";

Swiper.use([Navigation]);

import { mapState } from "vuex";

import SwipeActions from "@/components/swipe/SwipeActions";
import SwipePhoto from "@/components/swipe/SwipePhoto";

export default {
    data() {
        return {
            unsubscribeToAction: null,
            unsubscribeToMutations: null,
            swiper: null,
            initialized: false,
        };
    },
    computed: {
        ...mapState("swipe", [
            "finishedInitialLoad",
            "photosToRate",
            "currentPhotoToRate",
            "upvotedPhotoIds",
            "downvotedPhotoIds",
            "reachedTheEnd",
            "currentSlidePosition",
        ]),
    },
    async mounted() {
        let vueContext = this;

        if (!this.finishedInitialLoad) {
            await this.$store.dispatch("swipe/refresh");
        }

        this.$nextTick(() => {
            let options = {
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                scrollbar: {
                    el: ".swiper-scrollbar",
                },
                on: {
                    afterInit() {
                        vueContext.slideChanged(this);
                    },
                    slideChange() {
                        vueContext.slideChanged(this);
                    },
                    reachEnd() {
                        vueContext.endOfResults(this);
                    },
                },
            };

            if (this.currentSlidePosition) {
                options.initialSlide = this.currentSlidePosition;
            }

            this.swiper = new Swiper(this.$refs.widgetSwipeUser, options);
        });

        this.unsubscribeToAction = this.$store.subscribeAction({
            after: (action) => {
                if (["swipe/upvote", "swipe/downvote"].includes(action.type)) {
                    setTimeout(() => {
                        this.swiper.slideNext();
                    }, 50);
                }
            },
        });

        this.unsubscribeToMutations = this.$store.subscribe(
            (mutation, state) => {
                if (mutation.type === "swipe/setCurrentPhotoToRate") {
                    this.api.post("/photoRating/viewed", {
                        userPhotoId:
                            state.swipe.currentPhotoToRate.photoDefaultMap.id,
                        rating: null,
                    });
                }
            }
        );
    },
    beforeDestroy() {
        if (this.swiper) {
            this.swiper.destroy();
        }

        if (this.unsubscribeToAction) {
            this.unsubscribeToAction();
        }

        if (this.unsubscribeToMutations) {
            this.unsubscribeToMutations();
        }
    },
    methods: {
        slideChanged(swiper) {
            this.$store.dispatch(
                "swipe/setCurrentSlidePosition",
                swiper.activeIndex
            );
        },
        async endOfResults() {
            this.$store.dispatch(
                "appEvent/create",
                "widget_swipe_end_of_results"
            );

            await this.$store.dispatch("swipe/refresh");

            this.swiper.update();
            this.swiper.slideTo(0, 0);
        },
    },
    components: {
        SwipeActions,
        SwipePhoto,
    },
};
</script>
