<template>
    <div>
        <div class="px-3 mt-1">
            <wallet-balance />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import WalletBalance from "@/components/utility/WalletBalance";

export default {
    methods: {
        viewWallet() {},
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        balance() {
            return this.onboardingUser?.wallet?.balance;
        },
    },
    components: {
        WalletBalance,
    },
};
</script>