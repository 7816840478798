<template>
    <b-alert variant="warning" show class="rounded-0 border-0 py-4">
        <div class="d-flex justify-content-between">
            <h5 class="mb-0">Refill Credits</h5>
        </div>
        <div class="my-3">
            You're out of credits. Add credits to continue viewing livestream
        </div>
        <div class="my-3">
            <wallet-balance />
        </div>
        <b-btn
            class="mt-3"
            variant="primary"
            pill
            size="lg"
            block
            @click="$store.dispatch('wallet/view')"
        >
            Add Credits
        </b-btn>
    </b-alert>
</template>

<script>
import WalletBalance from "@/components/utility/WalletBalance";

export default {
    components: {
        WalletBalance,
    },
};
</script>