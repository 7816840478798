<template>
    <div>
        <b-table-simple striped class="mb-0">
            <thead>
                <tr>
                    <th>Alert</th>
                    <th>Sample</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="align-middle">
                        <b-form-checkbox v-model="soundNewMessage" switch>
                            New Message
                        </b-form-checkbox>
                    </td>
                    <td class="align-middle">
                        <b-btn
                            @click="playChime('new')"
                            size="sm"
                            pill
                            variant="primary"
                        >
                            <font-awesome-icon
                                icon="fa-duotone fa-circle-play"
                            />
                            Play
                        </b-btn>
                    </td>
                </tr>
            </tbody>
        </b-table-simple>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        soundNewMessage: {
            get() {
                return this.onboardingUser.soundNewMessage;
            },
            set(enable) {
                this.updateNewMessage(enable);
            },
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        playChime(chime) {
            this.$store.dispatch("audioAlert/chime", chime);
        },
        updateNewMessage(enable) {
            this.api.post("/userFlag/soundNewMessage", { enable }).then(() => {
                this.$store.dispatch("onboarding/status");
            });
        },
    },
};
</script>

