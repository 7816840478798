<template>
    <div v-if="displayAd" class="my-2">
        <div class="p-2 d-flex align-items-center">
            <b-badge variant="primary" class="mr-1">
                {{ $t("Ad") }}
            </b-badge>
            {{ $t("More Ladies from A Foreign Affair") }}
        </div>

        <b-row no-gutters>
            <b-col
                class="fake-link"
                @click="viewAfaUser(afaUser)"
                cols="2"
                :key="afaUser.url"
                v-for="afaUser in afaUsersAsian"
            >
                <img class="img-fluid" :src="afaUser.photo" />
            </b-col>
        </b-row>

        <div class="d-flex align-items-center justify-content-between p-2">
            <b-btn
                @click="viewModal"
                variant="link"
                class="text-secondary p-0"
                size="sm"
            >
                {{ $t("What is A Foreign Affair?") }}
            </b-btn>
            <div>
                <b-btn
                    v-if="false"
                    @click="upgradePremium"
                    variant="light"
                    class="btn-brand"
                    size="sm"
                    pill
                >
                    {{ $t("Remove Ads") }}
                </b-btn>
            </div>
        </div>

        <b-modal
            centered
            v-model="modal"
            :title="$t('Meet Ladies in Person')"
            hide-footer
        >
            <div>
                {{
                    $t(
                        "Our friends over at A Foreign Affair provide in-person tours for men seeking attractive ladies in many countries all over the world like: "
                    )
                }}
                <img
                    src="https://d1lil0eivvt84o.cloudfront.net/flag/svg/CO.svg"
                />
                Colombia,
                <img
                    src="https://d1lil0eivvt84o.cloudfront.net/flag/svg/TH.svg"
                />
                Thailand,
                <img
                    src="https://d1lil0eivvt84o.cloudfront.net/flag/svg/MX.svg"
                />
                Mexico,
                <img
                    src="https://d1lil0eivvt84o.cloudfront.net/flag/svg/PH.svg"
                />
                Philippines,
                <img
                    src="https://d1lil0eivvt84o.cloudfront.net/flag/svg/UA.svg"
                />
                Ukraine and more.
            </div>
            <b-row no-gutters class="mt-3">
                <b-col
                    class="fake-link"
                    @click="viewAfaUser(afaUser)"
                    cols="2"
                    :key="afaUser.url"
                    v-for="afaUser in afaUsersAsian"
                >
                    <img class="img-fluid" :src="afaUser.photo" />
                </b-col>
            </b-row>
            <div class="mt-3 text-primary small">
                <font-awesome-icon icon="fa-duotone fa-info-circle" />
                {{
                    $t(
                        "Click on any profile to head to AFA's website (opens in new window)"
                    )
                }}
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            modal: false,
        };
    },
    computed: {
        displayAd() {
            return (
                this.onboardingUser.enableAfaAds &&
                this.hasUsers &&
                !this.premium
            );
        },
        hasUsers() {
            return this.afaUsersAsian.length > 0;
        },
        ...mapState("user", ["premium"]),
        ...mapState("options", ["afaUsersAsian"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        viewModal() {
            this.modal = true;
        },
        viewAfaUser(afaUser) {
            this.$store.dispatch("system/openBrowser", afaUser.url);
        },
        upgradePremium() {
            this.$store.dispatch("premium/upgrade", {
                source: "afa-native-remove-ads",
            });
        },
    },
};
</script>


