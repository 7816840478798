<template>
    <div class="overflow-auto hide-scrollbars bg-light">
        <div class="border-bottom border-light p-2 d-flex align-items-center">
            <b-btn
                class="px-3 mr-1 btn-brand text-nowrap"
                :class="{
                    'border-primary border-heavy': isCurrentSubpage(item),
                }"
                :key="item.label"
                v-for="item in subMenuItems"
                @click="setView(item)"
                variant="light"
                pill
                size="sm"
            >
                <b-spinner
                    v-if="item.live"
                    type="grow"
                    small
                    variant="danger"
                />
                {{ item.label }}
                <b-badge v-if="item.count" variant="light" pill>
                    {{ item.count }}
                </b-badge>
            </b-btn>
            <div v-if="!fullyLoadedProfile" class="ml-auto">
                <b-spinner type="grow" small variant="secondary" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        subMenuItems() {
            const items = [];

            if (
                !this.onboardingUser.censored &&
                this.userIsLive(this.profile.id)
            ) {
                items.push({
                    label: this.$t("Live"),
                    view: "profileLive",
                    live: true,
                });
            }

            items.push({
                label: this.$t("Profile"),
                view: "profile",
            });

            if (
                !this.onboardingUser.censored &&
                this.profile?.creatorRequests?.length
            ) {
                items.push({
                    label: this.$t("Requests"),
                    view: "profileRequests",
                    count: this.profile?.creatorRequests?.length,
                });
            }

            if (this.profile?.settings?.canInteract) {
                items.push({
                    label: this.$t("Message"),
                    view: "conversation",
                });
            }

            if (
                !this.onboardingUser.censored &&
                !this.onboardingUser.nextGeneration &&
                this.profile?.galleryCount
            ) {
                items.push({
                    label: this.$t(`Gallery`),
                    view: "profileGalleries",
                    count: this.profile.galleryCount,
                });
            }

            if (
                !this.onboardingUser.censored &&
                !this.onboardingUser.nextGeneration &&
                this.profile?.postCount
            ) {
                items.push({
                    label: this.$t("Posts"),
                    view: "profilePosts",
                    count: this.profile.postCount,
                });
            }

            // if (this.profile.videos?.length) {
            //     items.push({
            //         label: this.$t("Videos"),
            //         view: "profileVideos",
            //     });
            // }

            if (this.profile?.videos?.length) {
                items.push({
                    label: "Verification",
                    view: "profileVerification",
                });
            }

            if (this.profile?.answers?.length) {
                items.push({
                    label: this.$t("Questions"),
                    view: "profileQuestions",
                });
            }

            return items;
        },
        ...mapState("profile", ["profile", "view", "fullyLoadedProfile"]),
        ...mapGetters("pusher", ["userIsLive"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        isCurrentSubpage(item) {
            return this.view === item.view;
        },
        setView(item) {
            this.$store.commit("profile/setView", item.view);
        },
    },
    components: {},
};
</script>
        
        