<template>
    <div class="text-center py-2">
        <h3 class="mb-0">
            <div v-if="currentCall.status">
                {{ currentCall.status }}
            </div>
        </h3>

        <div v-if="currentCall.callInProgress">
            <div v-if="!hasRemoteVideoTrack && !onboardingUser.creator">
                <b-alert variant="primary" show class="mb-0 mt-2">
                    <h5
                        class="d-flex align-items-center justify-content-center"
                    >
                        <b-spinner type="grow" small class="mr-1" />Waiting for
                        video stream
                    </h5>
                    <div class="small">
                        You will not be charged until their video stream is
                        active
                    </div>
                </b-alert>
            </div>
            <div v-else-if="!isCameraOn && onboardingUser.creator">
                <b-alert variant="warning" show class="mb-0 mt-2">
                    <h5
                        class="d-flex align-items-center justify-content-center"
                    >
                        <b-spinner type="grow" small class="mr-1" />
                        Waiting for video stream
                    </h5>
                    <div class="small">
                        You will not earn any credits until your video stream is
                        active
                    </div>
                </b-alert>
            </div>
            <div v-else>
                <div
                    class="d-flex align-items-center justify-content-between text-center"
                >
                    <second-timer class="text-secondary" />
                    <call-billing />
                </div>
            </div>

            <call-add-minutes class="mt-2" />
        </div>

        <div v-if="currentCall.completed" class="mt-3">
            <div v-if="onboardingUser.creator">
                <call-creator-summary />
            </div>
            <div v-else>
                <call-dispute
                    @refresh="$store.dispatch('call/refreshCurrentCall')"
                    :call="currentCall"
                />
            </div>
        </div>
    </div>
</template>



<script>
import { mapState } from "vuex";

import SecondTimer from "@/components/utility/SecondTimer";
import CallDispute from "@/components/always/call/CallDispute";
import CallCreatorSummary from "@/components/always/call/CallCreatorSummary";
import CallBilling from "@/components/always/call/CallBilling";
import CallAddMinutes from "@/components/always/call/CallAddMinutes";

export default {
    computed: {
        ...mapState("call", [
            "currentCall",
            "hasRemoteVideoTrack",
            "isCameraOn",
        ]),
        canAnswer() {
            return !this.currentCall.answeredAt && this.isInbound;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {},
    components: {
        SecondTimer,
        CallDispute,
        CallBilling,
        CallCreatorSummary,
        CallAddMinutes,
    },
};
</script>