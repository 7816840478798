<template>
    <div>
        <b-btn
            @click="showGifModal"
            variant="light"
            class="d-flex align-items-center"
            pill
            size="sm"
        >
            <font-awesome-icon
                icon="fa-duotone fa-face-laugh-squint"
                class="mr-1"
            />
            {{ $t("GIF") }}
        </b-btn>

        <b-modal
            :title="$t('Choose GIF')"
            hide-footer
            centered
            scrollable
            v-model="modal"
        >
            <giphy-selector @selectedGif="selectedGif" />
        </b-modal>
    </div>
</template>


<script>
import GiphySelector from "@/components/utility/GiphySelector";

export default {
    data() {
        return {};
    },
    computed: {
        modal: {
            get() {
                return this.$store.state.interface.giphySelectorModalVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setGiphySelectorModalVisible",
                    value
                );
            },
        },
    },
    methods: {
        selectedGif() {},
        showGifModal() {
            this.$store.commit("interface/setGiphySelectorModalVisible", true);
        },
    },
    components: {
        GiphySelector,
    },
};
</script>