<template>
    <div class="position-relative">
        <div class="scrollable-answers" v-on:scroll.passive="scrolled = true">
            <b-btn
                @click="selectAnswer(answer)"
                :key="answer.answerId"
                v-for="answer in currentQuestion.answers"
                block
                variant="light"
                class="btn-brand"
                pill
            >
                <div v-if="saving === answer.answerId">
                    <b-spinner class="text-secondary" small />
                </div>
                <div
                    v-else
                    class="d-flex align-items-center justify-content-between"
                >
                    <div class="text-primary w-25 text-left">
                        <div v-if="answer.selected">
                            <font-awesome-icon
                                icon="fa-solid fa-check-circle"
                            />
                        </div>
                    </div>
                    {{ answer.answerQuestionFormat }}
                    <div class="text-primary w-25 text-left invisible">
                        <div v-if="answer.selected">
                            <font-awesome-icon
                                icon="fa-solid fa-check-circle"
                            />
                        </div>
                    </div>
                </div>
            </b-btn>
        </div>
        <scroll-for-more v-if="scrollingRequired" :scrolled="scrolled" />
    </div>
</template>

<script>
import { mapState } from "vuex";
import ScrollForMore from "@/components/utility/ScrollForMore";

export default {
    data() {
        return {
            scrolled: false,
            saving: null,
        };
    },
    computed: {
        ...mapState("questions", ["currentQuestion"]),
        scrollingRequired() {
            return this.currentQuestion.answers.length > 8;
        },
    },
    methods: {
        selectAnswer(answer) {
            this.scrolled = false;
            this.saving = answer.answerId;

            this.$store.dispatch("questions/answer", {
                question: this.currentQuestion,
                answer,
            });
        },
    },
    components: { ScrollForMore },
};
</script>