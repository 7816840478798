<template>
    <div>
        <b-alert v-if="error" show variant="danger">
            <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
            {{ error }}
        </b-alert>

        <h5>My gender is...</h5>

        <div class="d-flex align-items-center justify-content-around py-2">
            <div
                class="gender-option border"
                :class="{
                    selected: selectedGender && selectedGender.id === gender.id,
                }"
                @click="selectGender(gender)"
                :key="gender.id"
                v-for="gender in genderOptions"
            >
                <div class="selector">
                    <img :src="gender.icon" />
                    <div class="small">
                        {{ gender.label }}
                    </div>
                </div>
            </div>
        </div>

        <h5 class="mt-3">I want to meet a...</h5>

        <div class="d-flex align-items-center justify-content-around py-2">
            <div
                class="gender-option border"
                :class="{
                    selected:
                        selectedSeeking && selectedSeeking.id === gender.id,
                }"
                @click="selectSeeking(gender)"
                :key="gender.id"
                v-for="gender in genderOptions"
            >
                <div class="selector">
                    <img :src="gender.icon" />
                    <div class="small">
                        {{ gender.label }}
                    </div>
                </div>
            </div>
        </div>

        <div v-if="selectedGender && selectedSeeking">
            <fitty class="mt-3" :options="{ maxSize: 21 }">
                <template v-slot:content>
                    I am a
                    <span class="text-underline text-primary">
                        {{ selectedGender.label }}
                    </span>
                    {{ $t("interested in meeting a") }}
                    <span class="text-underline text-primary">
                        {{ selectedSeeking.label }}
                    </span>
                </template>
            </fitty>

            <div class="mt-3">
                <b-btn @click="save" variant="primary" block size="lg" pill>
                    Continue
                </b-btn>
            </div>
        </div>

        <b-btn @click="viewOptions" variant="link" class="text-secondary mt-3">
            More Options
        </b-btn>
    </div>
</template>

<style lang="scss" scoped>
.gender-option {
    width: 90px;
    height: 90px;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #fff;
    color: #333;

    border-radius: 50%;
    border: 4px solid transparent;

    img {
        max-height: 38px;
    }

    .small {
        font-weight: bold;
        font-size: 0.7rem;
    }

    &.selected {
        border: 4px solid var(--primary) !important;
    }
}
</style>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            error: null,
            genderOptions: null,
            selectedGender: null,
            selectedSeeking: null,
        };
    },
    mounted() {
        this.genderOptions = this.genders;

        if (this.user.gender) {
            this.selectGender(
                this.genderOptions.find(
                    (gender) => gender.id === this.user.gender
                )
            );

            this.$nextTick(() => {
                this.selectSeeking(
                    this.genderOptions.find(
                        (gender) => gender.id === this.user.seeking
                    )
                );
            });
        }
    },
    computed: {
        ...mapState("options", ["genders", "setting"]),
        ...mapState("onboarding", ["user"]),
    },
    methods: {
        viewOptions() {
            this.$store.commit(
                "interface/setOnboardingSeekingOptionsVisible",
                true
            );
        },
        save() {
            this.error = null;

            this.api
                .post("/seeking/set", {
                    gender: this.selectedGender.id,
                    seeking: this.selectedSeeking.id,
                })
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                    this.$store.dispatch("user/refreshFilteredContent");
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error = error?.response?.data?.message;
                    }
                });
        },
        selectGender(gender) {
            this.selectedGender = gender;

            if (gender.defaultSeeking) {
                this.selectedSeeking = this.genderOptions.find(
                    (searchGender) => searchGender.id === gender.defaultSeeking
                );
            }
        },
        selectSeeking(seeking) {
            this.selectedSeeking = seeking;
        },
    },
};
</script>