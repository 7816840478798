<template>
    <generic-page>
        <b-btn variant="link" size="lg" class="pl-0" to="/creator/post">
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            {{ $t("All Posts") }}
        </b-btn>

        <div v-if="creatorPost">
            <view-post
                :key="creatorPost.id"
                v-if="creatorPost.publishedAt"
                :creatorPost="creatorPost"
            />

            <div class="mt-2">
                <creator-post-share />
            </div>

            <div
                class="d-flex align-items-center justify-content-between bg-white"
            >
                <b-btn
                    :disabled="!creatorPost.publishedAt"
                    @click="
                        $router.push({
                            name: 'publicPost',
                            params: {
                                slug: creatorPost.slug,
                            },
                        })
                    "
                    variant="light"
                    class="rounded-0 mt-0"
                    size="lg"
                    block
                >
                    <font-awesome-icon icon="fa-duotone fa-globe" size="lg" />
                    <div>
                        {{ $t("View") }}
                    </div>
                </b-btn>
                <b-btn
                    @click="
                        $router.push({
                            name: 'creatorPostEdit',
                            params: {
                                id: creatorPost.id,
                            },
                        })
                    "
                    variant="light"
                    class="rounded-0 mt-0"
                    size="lg"
                    block
                >
                    <font-awesome-icon icon="fa-duotone fa-pencil" size="lg" />
                    <div>
                        {{ $t("Edit") }}
                    </div>
                </b-btn>
            </div>

            <div class="py-2">
                <b-list-group>
                    <creator-post-likes />
                    <b-list-group-item
                        class="d-flex justify-content-between align-items-center"
                    >
                        <div>
                            {{ $t("Status") }}
                        </div>
                        <div>
                            <div :class="`text-${creatorPost.variant}`">
                                <font-awesome-icon
                                    icon="fa-solid fa-circle"
                                    size="xs"
                                />
                                {{ creatorPost.status }}
                            </div>
                        </div>
                    </b-list-group-item>
                    <b-list-group-item
                        v-if="creatorPost.totalEarnings"
                        class="d-flex justify-content-between align-items-center"
                    >
                        <div>
                            {{ $t("Total Earnings") }}
                        </div>
                        <coin-badge
                            class="border"
                            :amount="creatorPost.totalEarnings"
                        />
                    </b-list-group-item>
                    <b-list-group-item
                        class="d-flex justify-content-between align-items-center"
                    >
                        <div>
                            {{ $t("Created") }}
                        </div>
                        <div>
                            {{ creatorPost.createdRelative }}
                        </div>
                    </b-list-group-item>
                </b-list-group>

                <creator-post-comments />
                <creator-post-gifts />
            </div>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import ViewPost from "@/components/post/view/ViewPost";

import CreatorPostComments from "@/components/creator/post/CreatorPostComments";
import CreatorPostGifts from "@/components/creator/post/CreatorPostGifts";
import CreatorPostLikes from "@/components/creator/post/CreatorPostLikes";
import CreatorPostShare from "@/components/creator/post/CreatorPostShare";

export default {
    data() {
        return {
            blur: false,
        };
    },
    created() {
        if (this.$route.params?.id) {
            this.$store.dispatch("creatorPost/get", this.$route.params?.id);
        }
    },
    watch: {
        $route(to) {
            if (
                to?.params?.id !== this.$store.state.creatorPost.creatorPost?.id
            ) {
                this.$store.dispatch("creatorPost/get", this.$route.params?.id);
            }
        },
    },
    computed: {
        ...mapState("creatorPost", [
            "creatorPost",
            "editGallery",
            "visibility",
            "error",
        ]),
    },
    methods: {},
    components: {
        ViewPost,
        CreatorPostComments,
        CreatorPostLikes,
        CreatorPostGifts,
        CreatorPostShare,
    },
};
</script>