<template>
    <b-modal
        :title="$t('Followers')"
        hide-footer
        v-model="modal"
        centered
        scrollable
        body-class="p-0"
        no-close-on-esc
        
    >
        <interest-summary />
        <interest-list />
    </b-modal>
</template>

<script>
import InterestSummary from "@/components/interest/InterestSummary";
import InterestList from "@/components/interest/InterestList";

export default {
    computed: {
        modal: {
            get() {
                return this.$store.state.interface.followModalVisible;
            },
            set(value) {
                this.$store.commit("interface/setFollowModalVisible", value);
            },
        },
    },
    methods: {},
    components: {
        InterestSummary,
        InterestList,
    },
};
</script>