<template>
    <div
        v-if="!onboardingUser.disableInvite"
        class="rounded p-3 bg-white text-left"
    >
        <div class="d-flex align-items-center justify-content-between">
            <h5 class="mb-0">
                <font-awesome-icon
                    icon="fa-duotone fa-comment-dollar"
                    class="text-success"
                    size="lg"
                />
                Invite to Earn
            </h5>
            <b-btn
                to="/creator/invite"
                pill
                size="sm"
                variant="link"
                class="text-secondary"
            >
                Learn More
            </b-btn>
        </div>

        <div class="mt-2 small text-success">
            Share your private URL to earn cash rewards from both men and women
            who signup using your unique link
            <font-awesome-icon icon="fa-light fa-arrow-down" />
        </div>

        <div v-if="creatorUser" class="mt-2">
            <b-input-group>
                <b-form-input :value="creatorUser.inviteUrlVanity" readonly />
                <b-input-group-append>
                    <b-btn @click="copyUrl" variant="primary">
                        {{ $t("Copy") }}
                    </b-btn>
                </b-input-group-append>
            </b-input-group>

            <b-alert
                v-if="hasCopied"
                variant="success"
                show
                class="small mt-2 mb-0"
            >
                <h5>
                    <font-awesome-icon icon="fa-light fa-check" />
                    {{ $t("Copied") }}
                </h5>
                {{
                    $t(
                        "URL has been copied, now paste the URL in another chat app like WhatsApp or Messenger. Make sure they click the link before signup to get credit"
                    )
                }}
            </b-alert>
        </div>

        <b-row class="mt-3" no-gutters>
            <b-col>
                <creator-invite-email />
            </b-col>
            <b-col class="px-1">
                <creator-invite-qrcode />
            </b-col>
            <b-col class="px-1">
                <creator-invite-settings />
            </b-col>
            <b-col>
                <b-button
                    v-b-toggle.howToInviteGuides
                    variant="light"
                    pill
                    size="sm"
                    class="text-truncate"
                    block
                >
                    <font-awesome-icon icon="fa-duotone fa-info-circle" />
                    Help
                </b-button>
            </b-col>
        </b-row>

        <b-collapse id="howToInviteGuides">
            <creator-invite-guides />
        </b-collapse>
    </div>
</template>

<script>
import { mapState } from "vuex";

import CreatorInviteGuides from "@/components/creator/guide/view/CreatorInviteGuides";
import CreatorInviteSettings from "@/components/creator/invite/CreatorInviteSettings";
import CreatorInviteEmail from "@/components/creator/invite/CreatorInviteEmail";
import CreatorInviteQrcode from "@/components/creator/invite/CreatorInviteQrcode";

import copy from "clipboard-copy";

export default {
    created() {
        this.$store.dispatch("creatorDashboard/refresh");
    },
    computed: {
        ...mapState("creatorDashboard", ["creatorUser"]),
        ...mapState("creatorInvite", ["inviteUrl", "hasCopied"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        copyUrl() {
            copy(this.creatorUser.inviteUrl);
            this.$store.commit("creatorInvite/setHasCopied", true);
        },
    },
    components: {
        CreatorInviteGuides,
        CreatorInviteQrcode,
        CreatorInviteSettings,
        CreatorInviteEmail,
    },
};
</script>