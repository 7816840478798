<template>
    <div>
        <div class="p-2">
            <b-btn
                class="px-4 mr-2 btn-brand"
                :class="{
                    'border-primary border-heavy': currentViewKey === view.key,
                }"
                :key="view.label"
                v-for="view in views"
                @click="setView(view)"
                variant="light"
                pill
            >
                {{ view.label }}
            </b-btn>
        </div>

        <div :key="activity.id" v-for="activity in filteredActivities">
            <activity-view :activity="activity" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ActivityView from "@/components/activity/ActivityView";

export default {
    data() {
        return {
            views: [
                {
                    key: "unread",
                    label: this.$t("Unread"),
                },
                {
                    key: "all",
                    label: this.$t("All"),
                },
            ],
        };
    },
    methods: {
        setView(view) {
            this.$store.dispatch("activity/setView", view);
        },
    },
    computed: {
        currentViewKey() {
            return this.view;
        },
        ...mapGetters("activity", ["filteredActivities"]),
        ...mapState("activity", ["activities", "view"]),
    },
    components: {
        ActivityView,
    },
};
</script>