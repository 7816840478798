<template>
    <div>
        <b-alert v-if="call.canBeRefunded" variant="warning" show>
            <h5>This call was disputed</h5>

            <div class="p-3 bg-white">
                <div class="small font-weight-bold">Dispute Reason</div>
                <preserve-content-spacing :content="call.dispute" />
            </div>

            <div class="mt-2">
                You have {{ config.creatorCallAutoReleaseAfterHours }} hours to
                respond, if you don't take any actions the credits will be
                transferred to you, but it will negatively impact your dispute
                rate
            </div>
            <div class="mt-2">
                If you refund the call, it will reduce your dispute rate, as
                shown below
            </div>

            <b-row class="mt-3 text-center">
                <b-col cols="6">
                    <div class="small">Current Dispute Rate</div>

                    <h1>{{ currentDisputeRate }}%</h1>
                </b-col>
                <b-col cols="6">
                    <div class="small">Dispute Rate if Refunded</div>
                    <h1>{{ disputeRateAfter }}%</h1>
                </b-col>
            </b-row>

            <div class="mt-3">
                <b-btn
                    :disabled="refunding"
                    @click="refundCall"
                    variant="primary"
                    size="lg"
                    pill
                    block
                >
                    Refund Call
                </b-btn>
            </div>

            <b-button
                v-b-toggle.howItWorks
                variant="light"
                class="mt-3"
                block
                pill
            >
                How this works?
            </b-button>
            <b-collapse id="howItWorks" class="my-3">
                <div>
                    <h5>Should I accept the dispute?</h5>
                    <ul>
                        <li>
                            If you do not accept the dispute and refund the
                            user, it will negatively impact your dispute rate
                        </li>
                        <li>
                            If you accept the dispute and refund the user, there
                            will be no impact to your dispute rate
                        </li>
                        <li>
                            Your dispute rate is shown to other users before
                            they call you
                        </li>
                        <li>
                            Keeping your dispute rate low is important to give
                            other users confidence they will have an enjoyable
                            call
                        </li>
                    </ul>

                    <h5>Why was this call disputed?</h5>
                    <ul>
                        <li>
                            Short calls less than
                            {{ config.creatorCallDisputeMinuteLimit }}
                            minutes can be disputed after the call finishes for
                            up to
                            {{ config.creatorCallReleaseEscrowMinutes }}
                            minutes by the user if they are unsatisfied
                        </li>
                        <li>
                            Longer calls, more than
                            {{ config.creatorCallDisputeMinuteLimit }} minutes
                            can never be disputed, and the credits will be
                            instantly transferred to your wallet after the call
                        </li>
                    </ul>
                </div>
            </b-collapse>
        </b-alert>
    </div>
</template>

<script>
import { mapState } from "vuex";

import PreserveContentSpacing from "@/components/utility/PreserveContentSpacing";

export default {
    data() {
        return {
            refunding: false,
            currentDisputeRate: null,
            disputeRateAfter: null,
        };
    },
    created() {
        this.refresh();
    },
    computed: {
        ...mapState("options", ["config"]),
    },
    methods: {
        refresh() {
            this.api.post("/call/disputeRate").then((response) => {
                this.currentDisputeRate = response?.data?.currentDisputeRate;
                this.disputeRateAfter = response?.data?.disputeRateAfter;
            });
        },
        refundCall() {
            this.refunding = true;

            this.api
                .post(`/call/${this.call.id}/refund`)
                .then(() => {
                    this.$emit("refresh");
                })
                .catch(() => {})
                .then(() => {
                    this.refunding = false;
                });
        },
    },
    components: {
        PreserveContentSpacing,
    },
    props: ["call"],
};
</script>
