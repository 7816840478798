<template>
    <div>
        <div class="d-flex justify-content-between align-items-center">
            <div class="text-primary small">
                <font-awesome-icon icon="fa-duotone fa-eye" />
                Only visible to {{ standardMap.name }}
            </div>
            <b-btn
                @click="cancelComment"
                variant="light"
                class="text-danger"
                pill
                size="sm"
            >
                Cancel
            </b-btn>
        </div>

        <b-input-group class="mt-1">
            <b-form-textarea
                ref="focus"
                rows="1"
                max-rows="20"
                tabindex="1"
                v-model="comment"
                maxlength="252"
                autocomplete="off"
                placeholder="Add a comment"
            ></b-form-textarea>

            <template v-if="comment && comment.length" #append>
                <b-btn
                    :disabled="saving"
                    @click="postComment"
                    variant="primary"
                    block
                    size="lg"
                    tabindex="2"
                >
                    Post
                </b-btn>
            </template>
        </b-input-group>
    </div>
</template>


<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            saving: false,
            comment: null,
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.focus.focus();
        });
    },
    computed: {
        ...mapState("livestream", ["creatorLivestream", "standardMap"]),
    },
    methods: {
        cancelComment() {
            this.$store.commit("livestream/setCommentInputFocused", false);
        },
        postComment() {
            this.saving = true;

            this.api
                .post(`/livestream/${this.creatorLivestream.id}/comment`, {
                    comment: this.comment,
                })
                .then(() => {
                    this.comment = null;
                    this.cancelComment();
                    this.$store.dispatch("livestream/refreshInteractions");
                })
                .catch(() => {})
                .then(() => {
                    this.saving = false;
                });
        },
    },
};
</script>