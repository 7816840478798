<template>
    <div class="p-2">
        <back-to-dashboard />

        <div class="mt-3">
            <creator-guide />
        </div>
    </div>
</template>

<script>
import BackToDashboard from "@/components/utility/BackToDashboard";
import CreatorGuide from "@/components/creator/guide/CreatorGuide";

export default {
    components: {
        BackToDashboard,
        CreatorGuide
    },
};
</script>