<template>
    <generic-page>
        <back-to-dashboard />

        <div v-if="schedulePostCount !== null">
            <b-alert
                v-if="schedulePostCount"
                variant="primary"
                show
                class="mb-0 d-flex align-items-center justify-content-between"
            >
                <div>
                    {{ schedulePostCount }}
                    {{ $t("Scheduled Posts") }}
                </div>

                <b-btn
                    to="/creator/post/schedule"
                    variant="light"
                    pill
                    size="sm"
                >
                    {{ $t("View Schedule") }}
                </b-btn>
            </b-alert>
            <b-alert
                v-else
                variant="warning"
                show
                class="mb-0 d-flex align-items-center justify-content-between"
            >
                <div>
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                    />
                    {{ $t("No posts scheduled") }}
                </div>

                <b-btn
                    to="/creator/post/schedule"
                    variant="light"
                    pill
                    size="sm"
                >
                    {{ $t("View Schedule") }}
                </b-btn>
            </b-alert>
        </div>

        <div class="d-flex align-items-center justify-content-between mt-3">
            <h5 class="mb-0">
                {{ $t("Posts") }}
            </h5>

            <div>
                <b-btn @click="createPost" variant="primary" pill>
                    <font-awesome-icon icon="fa-light fa-plus" />
                    {{ $t("Create") }}
                    <coin-badge :amount="prices.postDeposit" />
                </b-btn>
            </div>
        </div>

        <b-alert
            variant="danger"
            show
            v-if="insufficientCredits"
            class="small mt-3"
        >
            <div class="d-flex justify-content-between align-items-center">
                <h5 class="mb-0">Not enough credits</h5>
                <wallet-balance />
            </div>
            <div class="mt-2">
                <coin-badge :amount="prices.postDeposit" /> is required to
                create a post
            </div>
        </b-alert>

        <div class="py-2">
            <b-list-group v-if="creatorPosts">
                <b-list-group-item
                    :key="creatorPost.id"
                    v-for="creatorPost in creatorPosts"
                    @click="
                        $router.push({
                            name: 'creatorPostView',
                            params: {
                                id: creatorPost.id,
                            },
                        })
                    "
                    action
                    class="d-flex align-items-center"
                >
                    <div class="text-truncate flex-grow-1">
                        <div class="small text-secondary">
                            <font-awesome-icon
                                icon="fa-solid fa-circle"
                                :class="`text-${creatorPost.variant}`"
                                size="xs"
                            />

                            <span v-if="creatorPost.publishDateTerse">
                                {{ creatorPost.publishDateTerse }}
                            </span>
                            <span v-else>
                                {{ $t("Draft") }}
                            </span>

                            <span class="mx-1"> &middot; </span>

                            <span v-if="creatorPost.private">
                                <font-awesome-icon
                                    icon="fa-duotone fa-lock"
                                    class="text-warning"
                                />
                                {{ $t("Private") }}
                            </span>
                            <span v-else>
                                <font-awesome-icon
                                    icon="fa-duotone fa-globe"
                                    class="text-primary"
                                />
                                {{ $t("Public") }}
                            </span>
                        </div>

                        <div class="">
                            {{ creatorPost.message }}
                        </div>

                        <div class="d-flex align-items-center mt-2">
                            <coin-badge
                                class="mr-1"
                                v-if="creatorPost.totalEarnings"
                                :amount="creatorPost.totalEarnings"
                            />
                            <div
                                :key="gift.id"
                                v-for="gift in creatorPost.gifts"
                            >
                                <img
                                    class="custom-icon-size-small"
                                    :src="gift.gift.url"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="ml-3" style="max-width: 50px">
                        <div v-if="creatorPost.video">
                            <font-awesome-icon
                                class="mr-1 text-primary"
                                icon="fa-duotone fa-video"
                                size="3x"
                            />
                        </div>
                        <div v-else-if="creatorPost.primary">
                            <img
                                class="img-fluid rounded"
                                :src="creatorPost.primary.thumbUrl"
                            />
                        </div>
                    </div>
                </b-list-group-item>
            </b-list-group>
            <div v-else>
                <generic-loading />
            </div>
        </div>

        <creator-post-index-pagination />
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import BackToDashboard from "@/components/utility/BackToDashboard";
import WalletBalance from "@/components/utility/WalletBalance";
import CreatorPostIndexPagination from "@/components/creator/post/index/CreatorPostIndexPagination";

export default {
    created() {
        this.$store.dispatch("creatorPost/refresh");
    },
    computed: {
        ...mapState("creatorPost", [
            "creatorPosts",
            "schedulePostCount",
            "insufficientCredits",
        ]),
        ...mapState("options", ["prices"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        createPost() {
            this.$store.dispatch("creatorPost/create");
        },
    },
    components: {
        BackToDashboard,
        WalletBalance,
        CreatorPostIndexPagination,
    },
};
</script>