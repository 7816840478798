import api from "@/service/api";

const state = () => ({
    activities: [],
    seenActivityIds: [],
    view: 'unread',
})

const mutations = {
    setView(state, value) {
        state.view = value
    },
    setActivities(state, value) {
        state.activities = value
    },
    markActivitySeen(state, id) {
        state.seenActivityIds.push(id)
    },
}

const actions = {
    setView({ commit, dispatch }, view) {
        commit('setView', view.key);
        dispatch('refresh')
    },
    refresh({ state, commit }) {
        api.post("/activity/index", {
            view: state.view
        }).then((response) => {
            if (response?.data?.activities) {
                commit('setActivities', response.data.activities);
            }
        });
    },
    async markAllSeen({ dispatch }) {
        await api.post("/activity/markAllSeen")
        dispatch('refresh')
    },
    markActivitySeen({ commit }, id) {
        commit('markActivitySeen', id)
        api.post("/activity/seen", { id })
    },
}

const getters = {
    filteredActivities(state, getters, rootState) {
        if (!state.activities) {
            return []
        }

        const unblockedActivities = state.activities
            .filter(activity => !rootState.user.blockedUserIds.includes(activity.fromUserId))

        if (state.view === 'unread') {
            return unblockedActivities.filter(activity => !state.seenActivityIds.includes(activity.id))
        }

        return unblockedActivities
    },
    unreadActivityCount(state) {
        if (!state.activities) {
            return 0;
        }

        return state.activities
            .filter(activity => !state.seenActivityIds.includes(activity.id))
            .filter(activity => !activity.seen).length
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
