<template>
    <div>
        <b-btn
            @click="$store.dispatch('galleryIndex/setView', view.key)"
            :key="view.key"
            v-for="view in views"
            variant="light"
            size="sm"
            pill
            :class="{ 'border-primary': galleryView === view.key }"
            class="text-capitalize mr-2"
        >
            {{ view.label }}
        </b-btn>
    </div>
</template>

<style lang="scss" scoped>
.limit-width-dropdown-menu {
    width: 90vw;
    max-width: 250px;
}
</style>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            views: [
                {
                    key: "new",
                    label: "New",
                },
                {
                    key: "popular",
                    label: "Popular",
                },
                {
                    key: "views",
                    label: "Most Viewed",
                },
                {
                    key: "purchased",
                    label: "Purchased",
                },
            ],
        };
    },
    computed: {
        ...mapState("galleryIndex", ["galleryView"]),
    },
};
</script>
