<template>
    <div>
        <div class="mt-2">
            <creator-gallery-media />
        </div>

        <div class="mt-2 text-center" v-if="creatorGallery.editable">
            <creator-gallery-upload>
                <b-btn variant="primary" pill size="lg" class="px-4">
                    <font-awesome-icon class="mr-1" icon="fa-light fa-plus" />
                    {{ $t("Upload") }}
                </b-btn>
            </creator-gallery-upload>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import CreatorGalleryMedia from "@/components/creator/gallery/CreatorGalleryMedia";
import CreatorGalleryUpload from "@/components/creator/gallery/CreatorGalleryUpload";

export default {
    computed: {
        hasMediaItems() {
            return this.creatorGallery?.medias?.length > 0;
        },
        ...mapState("creatorGallery", ["creatorGallery"]),
    },
    components: {
        CreatorGalleryUpload,
        CreatorGalleryMedia,
    },
};
</script>