<template>
    <div v-if="consumedFreePeriod">
        <div>
            <livestream-gift />
        </div>
        <div class="mt-2">
            <livestream-comment />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import LivestreamComment from "@/components/livestream/LivestreamComment";
import LivestreamGift from "@/components/livestream/LivestreamGift";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("livestream", [
            "creatorLivestream",
            "consumedFreePeriod",
            "standardMap",
            "agora",
        ]),
    },
    components: {
        LivestreamComment,
        LivestreamGift,
    },
};
</script>