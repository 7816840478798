<template>
    <div>
        <b-btn
            @click="createRequest"
            variant="light"
            block
            class="rounded-0 mt-0"
            :size="$screen.lg ? 'lg' : 'sm'"
        >
            <div>
                <font-awesome-icon
                    icon="fa-duotone fa-hand-holding-dollar"
                    size="lg"
                />
            </div>
            {{ $t("Request") }}
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("profile", ["profile"]),
    },
    methods: {
        createRequest() {
            this.$store.dispatch("creatorRequest/start", this.profile);
        },
    },
};
</script>