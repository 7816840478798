<template>
    <div>
        <div class="sticky-top">
            <message-navigation />
        </div>
        <message-index />
    </div>
</template>


<script>
import MessageIndex from "@/components/messages/MessageIndex";
import MessageNavigation from "@/components/messages/MessageNavigation";

export default {
    components: {
        MessageIndex,
        MessageNavigation,
    },
};
</script>