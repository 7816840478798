<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <div class="text-primary">
                <h3 class="mb-0">{{ $t("Guaranteed Replies") }}</h3>
            </div>
            <div>
                <h3 class="mb-0">
                    <b-badge variant="primary" class="float-right" pill>
                        {{ $t("New") }}
                    </b-badge>
                </h3>
            </div>
        </div>

        <b-badge variant="light" class="border mt-1" pill>
            <div class="d-flex align-items-center justify-content-center">
                <font-awesome-icon
                    icon="fa-duotone fa-coin"
                    class="mr-1 text-warning"
                    size="lg"
                />
                <div class="text-warning">
                    {{ $t("2 credits per message") }}
                </div>
            </div>
        </b-badge>

        <h5 class="mb-0 mt-3 text-primary">
            {{ $t("24 Hours to Respond") }}
        </h5>
        {{
            $t(
                "Ladies now have 24 hours to respond to messages, after that you're automatically eligible for a refund on messages. Never pay for an unread message again. Simply click the message when indicated to instantly get a refund back to your wallet"
            )
        }}

        <h5 class="mb-0 mt-3 text-primary">
            {{ $t("Chat Ratings") }}
        </h5>
        {{
            $t(
                "Creators are now rated for their chats, so if you're not enjoying your experience you always have the option to rate the chat accordingly. The most enjoyable chatters will be at the top of the rankings. Be sure to give 5 star ratings if you're enjoying your chat!"
            )
        }}

        <h5 class="mb-0 mt-3 text-primary">
            {{ $t("Incentives for Ladies") }}
        </h5>
        {{
            $t(
                "Ladies are now rewarded for their replies, but only when you've sent them a message first. They only get paid when you reply back to them, so you never pay unless you're enjoying the experience"
            )
        }}

        <b-row class="mt-4">
            <b-col cols="12" md="6" class="my-2">
                <b-btn
                    variant="primary"
                    @click="closeModal"
                    pill
                    block
                    size="lg"
                >
                    {{ $t("Ok, got it") }}
                </b-btn>
            </b-col>
            <b-col cols="12" md="6" class="my-2">
                <b-btn
                    variant="secondary"
                    @click="maybeLater"
                    pill
                    block
                    size="lg"
                >
                    {{ $t("Remind me later") }}
                </b-btn>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        currentOffer() {
            return this.$store.state.displayOffer?.currentOffer;
        },
        ...mapState("user", ["premium"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        closeModal() {
            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
        maybeLater() {
            this.api.post("/offer/removeViewedOffer", {
                offer: this.currentOffer.offer,
            });

            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
    },
};
</script>

