<template>
    <div>
        <b-alert v-if="call.dispute && !call.refunded" variant="warning" show>
            <h5>You disputed this call</h5>

            <div class="mt-2">
                The creator has been notified of the dispute and has
                {{ config.creatorCallAutoReleaseAfterHours }} hours to respond
            </div>
            <div class="mt-2">
                If they do not refund your call, it will negatively impact their
                dispute rate, and show a warning to other users in the future
            </div>
            <div class="mt-2">
                If you changed your mind you can with withdraw the dispute at
                any time
            </div>

            <div class="mt-3">
                <b-btn
                    :disabled="withdrawing"
                    @click="withdrawDispute"
                    variant="primary"
                    size="lg"
                    pill
                    block
                >
                    Withdraw Dispute
                </b-btn>
            </div>
        </b-alert>
        <b-alert v-else-if="canDispute" variant="light" show>
            <h5>How was your call?</h5>

            <b-btn-group class="w-100">
                <b-btn
                    @click="rateCall(5)"
                    variant="light"
                    pill
                    size="lg"
                    class="text-primary"
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-thumbs-up"
                        size="lg"
                    />
                </b-btn>
                <b-btn
                    @click="rateCall(1)"
                    variant="light"
                    pill
                    size="lg"
                    class="text-danger"
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-thumbs-down"
                        size="lg"
                    />
                </b-btn>
            </b-btn-group>

            <div class="mt-3">
                <b-alert v-if="positive" variant="primary" show>
                    Got it. Thanks for rating
                </b-alert>

                <div v-if="negative">
                    <b-alert
                        v-if="canDispute"
                        variant="warning"
                        show
                        class="text-left"
                    >
                        <h5>Dispute Call</h5>
                        <font-awesome-icon icon="fa-duotone fa-info-circle" />
                        If you're not satisfied with the quality of your call
                        you can dispute it. If the creator accepts your dispute
                        you will be refunded, if they do not accept, it will
                        negatively impact their dispute rate
                        <b-button
                            v-b-toggle.createDispute
                            variant="light"
                            class="border mt-3"
                            block
                            pill
                        >
                            Create Dispute
                        </b-button>
                        <b-collapse id="createDispute">
                            <b-textarea
                                class="mt-3"
                                placeholder="Details of your dispute, will be shared with creator for their consideration"
                                v-model="dispute"
                                type="text"
                                rows="3"
                                max-rows="12"
                            />

                            <div class="my-3">
                                <b-btn
                                    :disabled="!dispute || creating"
                                    @click="createDispute"
                                    variant="primary"
                                    size="lg"
                                    pill
                                    block
                                >
                                    Submit Dispute
                                </b-btn>
                            </div>
                        </b-collapse>
                    </b-alert>
                </div>
            </div>

            <div v-if="!positive && canDispute" class="mt-2 text-secondary">
                Call can be disputed for another
                <live-timestamp-future :timestamp="call.canBeDisputedUntil" />
            </div>
        </b-alert>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            dispute: null,
            positive: false,
            negative: false,
            withdrawing: false,
            creating: false,
        };
    },
    computed: {
        canDispute() {
            if (!this.call.canBeDisputed) {
                return false;
            }

            return this.$date(this.now).isBefore(
                this.$date(this.call.canBeDisputedUntil)
            );
        },
        ...mapState("options", ["config"]),
        ...mapState("system", ["now"]),
    },
    methods: {
        withdrawDispute() {
            this.withdrawing = true;

            this.api
                .post(`/call/${this.call.id}/withdraw`)
                .then(() => {
                    this.negative = false;
                    this.$emit("refresh");
                })
                .catch(() => {})
                .then(() => {
                    this.withdrawing = false;
                });
        },
        createDispute() {
            this.creating = true;

            this.api
                .post(`/call/${this.call.id}/dispute`, {
                    dispute: this.dispute,
                })
                .then(() => {
                    this.$store.dispatch("call/close");
                    this.$store.dispatch("profile/close");

                    this.$router.push({
                        name: "accountCallView",
                        params: {
                            id: this.call.id,
                        },
                        query: {
                            forceRefresh: Date.now(),
                        },
                    });
                })
                .catch(() => {})
                .then(() => {
                    this.creating = false;
                });
        },
        rateCall(rating) {
            if (rating === 5) {
                this.negative = false;
                this.positive = true;
            }

            if (rating === 1) {
                this.negative = true;
                this.positive = false;
            }

            this.api.post(`/call/${this.call.id}/rate`, {
                rating,
            });
        },
    },
    props: ["call"],
};
</script>