<template>
    <div>
        <div class="branded">
            <font-awesome-icon icon="fa-duotone fa-info-circle" size="lg" />
            {{ $t("How we protect your privacy") }}
        </div>
        <div class="mt-3">
            <b-list-group class="text-left">
                <b-list-group-item>
                    <font-awesome-icon
                        class="mr-1 branded"
                        icon="fa-duotone fa-shield-check"
                        size="lg"
                    />
                    {{ $t("Only users matching your filters can see you") }}
                </b-list-group-item>
                <b-list-group-item>
                    <font-awesome-icon
                        class="mr-1 branded"
                        icon="fa-duotone fa-shield-check"
                        size="lg"
                    />
                    {{
                        $t(
                            "Your location is hidden, only your country is shown"
                        )
                    }}
                </b-list-group-item>
                <b-list-group-item>
                    <font-awesome-icon
                        class="mr-1 branded"
                        icon="fa-duotone fa-shield-check"
                        size="lg"
                    />
                    {{
                        $t(
                            "Your private photos will not be shown anywhere publicly on our website"
                        )
                    }}
                </b-list-group-item>
                <b-list-group-item v-if="myCountryBlocked">
                    <font-awesome-icon
                        class="mr-1 branded"
                        icon="fa-duotone fa-shield-check"
                        size="lg"
                    />
                    {{
                        $t(
                            "Your country is blocked unless you want to unblock it"
                        )
                    }}
                    <div class="mt-2">
                        <b-btn
                            @click="unblockMyCountry"
                            variant="link"
                            size="sm"
                            class="px-0"
                        >
                            {{ $t("Unblock") }}
                            {{ onboardingUser.countryName }}
                        </b-btn>
                    </div>
                </b-list-group-item>
            </b-list-group>
            <div class="mt-3">
                <b-btn @click="skip" variant="primary" pill size="lg">
                    {{ $t("Continue") }}
                </b-btn>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            option: null,
            phone: null,
            username: null,
            error: null,
        };
    },
    computed: {
        myCountryBlocked() {
            return this.onboardingUser.blockedCountries
                ?.map((country) => country.countryId)
                ?.includes(this.onboardingUser.countryId);
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        unblockMyCountry() {
            this.api
                .post("/block/unblockCountry", {
                    countryId: this.onboardingUser.countryId,
                })
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                });
        },
        skip() {
            this.api
                .post("/userFlag/onboardingFinishedPrivacy", {})
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                });
        },
    },
};
</script>
