// import api from "@/service/api";

import {
    ATTRIBUTION_LANDING,
    ATTRIBUTION_SOURCE,
    ATTRIBUTION_CLICK,
    ATTRIBUTION_REFERER,
    ATTRIBUTION_INVITE,
    ATTRIBUTION_ACCOUNT_TYPE,
} from "@/constant/localStorage";


const state = () => ({
    loadedRecaptcha: null,
    hideAppDownload: false,
    expatMode: false,
})

const mutations = {
    // these really dont need to be in vuex at all, but no other model currently
    setLandingPage(state, value) {
        if (!localStorage.getItem(ATTRIBUTION_LANDING)) {
            localStorage.setItem(ATTRIBUTION_LANDING, value)
        }
    },
    setSource(state, value) {
        if (!localStorage.getItem(ATTRIBUTION_SOURCE)) {
            localStorage.setItem(ATTRIBUTION_SOURCE, value)
        }

        state.hideAppDownload = true
    },
    setInvite(state, value) {
        if (!localStorage.getItem(ATTRIBUTION_INVITE)) {
            localStorage.setItem(ATTRIBUTION_INVITE, value)
        }

        state.hideAppDownload = true
    },
    setClick(state, value) {
        if (!localStorage.getItem(ATTRIBUTION_CLICK)) {
            localStorage.setItem(ATTRIBUTION_CLICK, value)
        }
    },
    setReferer(state, value) {
        if (!localStorage.getItem(ATTRIBUTION_REFERER)) {
            localStorage.setItem(ATTRIBUTION_REFERER, value)
        }
    },
    setAccountType(state, value) {
        if (!localStorage.getItem(ATTRIBUTION_ACCOUNT_TYPE)) {
            localStorage.setItem(ATTRIBUTION_ACCOUNT_TYPE, value)
        }
    },
    setExpatMode(state, value) {
        state.expatMode = value
    },
    setLoadedRecaptcha(state, value) {
        state.loadedRecaptcha = value
    },
    setHideAppDownload(state, value) {
        state.hideAppDownload = value
    },
}

const actions = {
    loadRecaptchaOnce({ state, commit }) {
        if (state.loadedRecaptcha) {
            return;
        }

        commit("setLoadedRecaptcha", true);

        return new Promise((resolve) => {
            let script = document.createElement("script");

            script.onload = () => {
                resolve();
            };

            script.src = "https://www.google.com/recaptcha/api.js"

            document.head.appendChild(script);
        });
    },
}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}
