<template>
    <div>
        <div
            v-if="onboardingUser.inviteFriendsIncentive === 'inviteForPremium'"
        >
            <b-alert variant="primary" show>
                <h6>
                    {{ $t("Get Premium for Free") }}
                </h6>
                <div class="mt-2 small">
                    {{
                        $t(
                            "When your friends signup from your invite URL and subscribe to premium, you will automatically get upgraded for free"
                        )
                    }}
                </div>
                <div class="mt-2 small">
                    {{
                        $t(
                            "Encourage your friends to subscribe and you won't have to 😎"
                        )
                    }}
                </div>
            </b-alert>
        </div>
        <div v-else>
            <b-alert variant="primary" show>
                <h6>
                    {{ $t("Invite to Earn Money - 50% Commission") }}
                </h6>
                <div class="mt-2 small">
                    {{
                        $t(
                            "Invite users and earn from their purchases in the service. If a user subscribes, you will earn 50% commission on their payments as long as they continue to be a paying customer. A single signup can earn you money for years if they continue to enjoy the service"
                        )
                    }}
                </div>
                <div class="mt-2 small font-weight-bold">
                    {{ $t("Where to share this URL?") }}
                </div>
                <div>
                    <font-awesome-icon
                        class="mr-1 text-success"
                        icon="fa-light fa-check"
                    />
                    <small>{{ $t("WhatsApp, Facebook, Instagram") }}</small>
                </div>
                <div>
                    <font-awesome-icon
                        class="mr-1 text-success"
                        icon="fa-light fa-check"
                    />
                    <small>{{ $t("Reddit, Quora, social forums") }}</small>
                </div>
                <div>
                    <font-awesome-icon
                        class="mr-1 text-success"
                        icon="fa-light fa-check"
                    />
                    <small>{{
                        $t("Friends, family, chatmates, acquaintances")
                    }}</small>
                </div>
                <div>
                    <font-awesome-icon
                        class="text-danger mr-1"
                        icon="fa-solid fa-ban"
                    />
                    <small>{{
                        $t(
                            "Other Dating Platforms - do not share directly on other dating apps, only in WhatsApp, FB, other chat apps"
                        )
                    }}</small>
                </div>
            </b-alert>
        </div>

        <b-card no-body>
            <b-tabs card>
                <b-tab v-if="false" :title="$t('Send Email')">
                    <invite-friends-email />
                </b-tab>
                <b-tab :title="$t('Invite URL')" active>
                    <invite-friends-url />
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import { mapState } from "vuex";

import InviteFriendsEmail from "@/components/always/inviteFriends/InviteFriendsEmail";
import InviteFriendsUrl from "@/components/always/inviteFriends/InviteFriendsUrl";

export default {
    computed: {
        ...mapState("inviteFriends", ["validatedEmails", "url"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    watch: {},
    methods: {},
    components: {
        InviteFriendsEmail,
        InviteFriendsUrl,
    },
};
</script>