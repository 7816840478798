<template>
    <div>
        <b-btn @click="resetSearch" variant="light" class="text-danger" pill>
            {{ $t("Reset") }}
        </b-btn>
    </div>
</template>



<script>
export default {
    methods: {
        resetSearch() {
            this.$store.dispatch("search/resetSearch");
            this.$store.dispatch("search/refresh");
        },
    },
};
</script>
