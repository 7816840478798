<template>
    <div>
        <div class="p-2 bg-light rounded border">
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    {{ $t("Package") }}
                </div>

                <b-btn variant="link" size="sm" pill @click="changePackage">
                    {{ $t("Edit") }}
                </b-btn>
            </div>

            <refill-package />
        </div>

        <div class="mt-2">
            <div v-if="existingCard">
                <refill-existing-card />
            </div>
            <div v-else>
                <refill-create-card />
            </div>

            <div v-if="onboardingUser.alternativePaymentUrl" class="mt-3">
                <b-btn
                    :href="onboardingUser.alternativePaymentUrl"
                    target="_blank"
                    variant="link"
                    pill
                    block
                >
                    Use alternative payment service
                </b-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import RefillCreateCard from "@/components/wallet/refill/RefillCreateCard";
import RefillExistingCard from "@/components/wallet/refill/RefillExistingCard";
import RefillPackage from "@/components/wallet/refill/RefillPackage";

export default {
    computed: {
        existingCard() {
            return this.onboardingUser.primaryCreditCard && !this.createCard;
        },
        ...mapState("wallet", ["createCard"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        changePackage() {
            this.$store.commit("wallet/setPrice", null);
        },
    },
    components: {
        RefillCreateCard,
        RefillExistingCard,
        RefillPackage,
    },
};
</script>