<template>
    <generic-page>
        <b-btn variant="link" size="lg" class="pl-0" to="/creator/gallery">
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            {{ $t("All Galleries") }}
        </b-btn>

        <div v-if="creatorGallery">
            <div class="d-flex align-items-center justify-content-between">
                <h5 class="mb-0">
                    {{ creatorGallery.name }}
                </h5>

                <div class="text-center">
                    <b-form-checkbox v-model="blur" switch>
                        {{ $t("Preview") }}
                    </b-form-checkbox>
                </div>
            </div>

            <div class="mt-2">
                <b-alert
                    v-if="blur"
                    show
                    variant="primary"
                    class="border-0 rounded-0 mb-0 small"
                >
                    <font-awesome-icon icon="fa-duotone fa-eye-slash" />
                    {{ $t("How content appears to viewer before buying") }}
                </b-alert>
                <b-row no-gutters class="bg-black">
                    <b-col
                        cols="4"
                        :key="userMedia.id"
                        v-for="userMedia in creatorGallery.medias"
                        class="p-2 overflow-hidden"
                    >
                        <gallery-media-item
                            :preview="true"
                            :blur="blur"
                            :userMedia="userMedia"
                        />
                    </b-col>
                </b-row>
            </div>

            <div
                class="d-flex align-items-center justify-content-between bg-white"
            >
                <b-btn
                    @click="
                        $router.push({
                            name: 'creatorGalleryEdit',
                            params: {
                                id: creatorGallery.id,
                            },
                        })
                    "
                    variant="light"
                    class="rounded-0 mt-0"
                    size="lg"
                    block
                >
                    <font-awesome-icon icon="fa-duotone fa-pencil" size="lg" />
                    <div>
                        {{ $t("Edit") }}
                    </div>
                </b-btn>
                <b-btn
                    v-if="false"
                    :disabled="!creatorGallery.publishedAt"
                    @click="
                        $router.push({
                            name: 'creatorGalleryInvite',
                            params: {
                                id: creatorGallery.id,
                            },
                        })
                    "
                    variant="light"
                    class="rounded-0 mt-0"
                    size="lg"
                    block
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-chart-network"
                        size="lg"
                    />
                    <div>
                        {{ $t("Invite") }}
                    </div>
                </b-btn>
                <b-btn
                    v-if="false"
                    :disabled="!creatorGallery.publishedAt"
                    @click="
                        $router.push({
                            name: 'creatorGalleryShare',
                            params: {
                                id: creatorGallery.id,
                            },
                        })
                    "
                    variant="light"
                    class="rounded-0 mt-0"
                    size="lg"
                    block
                >
                    <font-awesome-icon icon="fa-duotone fa-share" size="lg" />
                    <div>
                        {{ $t("Share") }}
                    </div>
                </b-btn>
            </div>

            <div class="py-2">
                <b-list-group>
                    <b-list-group-item
                        class="d-flex justify-content-between align-items-center"
                    >
                        <div>
                            {{ $t("Status") }}
                        </div>
                        <div>
                            <div :class="`text-${creatorGallery.variant}`">
                                <font-awesome-icon
                                    icon="fa-solid fa-circle"
                                    size="xs"
                                />
                                {{ creatorGallery.status }}
                            </div>
                        </div>
                    </b-list-group-item>
                    <b-list-group-item
                        class="d-flex justify-content-between align-items-center"
                    >
                        <div>
                            {{ $t("Price") }}
                        </div>
                        <gallery-price :creatorGallery="creatorGallery" />
                    </b-list-group-item>
                    <b-list-group-item
                        class="d-flex justify-content-between align-items-center"
                    >
                        <div>
                            {{ $t("Total Earnings") }}
                        </div>
                        <coin-badge
                            v-if="creatorGallery.totalEarnings"
                            class="border"
                            :amount="creatorGallery.totalEarnings"
                        />
                    </b-list-group-item>
                    <b-list-group-item
                        class="d-flex justify-content-between align-items-center"
                    >
                        <div>
                            {{ $t("Created") }}
                        </div>
                        <div>
                            {{ creatorGallery.createdRelative }}
                        </div>
                    </b-list-group-item>
                </b-list-group>
            </div>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import GalleryMediaItem from "@/components/gallery/GalleryMediaItem";
import GalleryPrice from "@/components/utility/gallery/GalleryPrice";

export default {
    data() {
        return {
            blur: false,
        };
    },
    created() {
        if (this.$route.params?.id) {
            this.$store.dispatch("creatorGallery/get", this.$route.params?.id);
        }
    },
    watch: {
        $route(to) {
            if (
                to?.params?.id !== this.$store.state.gallery.creatorGallery?.id
            ) {
                this.$store.dispatch(
                    "creatorGallery/get",
                    this.$route.params?.id
                );
            }
        },
    },
    computed: {
        ...mapState("creatorGallery", [
            "creatorGallery",
            "editGallery",
            "visibility",
            "error",
        ]),
    },
    methods: {},
    components: {
        GalleryMediaItem,
        GalleryPrice,
    },
};
</script>