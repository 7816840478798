import api from "@/service/api";

const state = () => ({
    error: null,
    sendAmount: null,
    sendAmountOptions: null,
    sending: false,
})

const mutations = {
    reset(state) {
        state.error = null
        state.sendAmount = null
        state.sendAmountOptions = null
        state.sending = false
    },
    setSendAmount(state, value) {
        state.sendAmount = value
    },
    setSending(state, value) {
        state.sending = value
    },
    setError(state, value) {
        state.error = value
    },
    setSendAmountOptions(state, value) {
        state.sendAmountOptions = value
    },
    setStandardMap(state, value) {
        state.standardMap = value
    },
}

const actions = {
    cancel({ commit }) {
        commit('setSendAmount', null)
        commit('setError', null)
    },
    sendToUser({ commit, state }, standardMap) {
        commit('reset')
        commit('setStandardMap', standardMap)
        commit("interface/setSendCreditsModalVisible", true, { root: true });

        api.post("/credit/sendAmountOptions", {
            userId: state.standardMap.id
        }).then((response) => {
            if (response?.data?.sendAmountOptions) {
                commit('setSendAmountOptions', response.data.sendAmountOptions)
            }
        })
    },
    convertAmount({ commit, state }, amount) {
        api.post("/credit/convert", {
            userId: state.standardMap.id,
            amount
        }).then((response) => {
            if (response?.data?.sendAmount) {
                commit('setSendAmount', response.data.sendAmount)
            }
        })
    },
    confirmSend({ commit, state, dispatch }, message = null) {
        commit('setSending', true)
        commit('setError', null)

        api.post("/credit/send", {
            userId: state.standardMap.id,
            amount: state.sendAmount.amount,
            message
        })
            .then(() => {
                commit("interface/setSendCreditsModalVisible", false, { root: true });
                commit('reset')

                dispatch("profile/viewProfile", {
                    userId: state.standardMap.id,
                    profile: state.standardMap,
                    view: "conversation",
                }, { root: true });
            })
            .catch(error => {
                if (error?.response?.data?.errors?.amount?.length) {
                    commit('setError', error.response.data.errors.amount.join(","))
                }
            })
            .then(() => {
                commit('setSending', false)
            })
    },
}

const getters = {

}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
