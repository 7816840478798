<template>
    <div>
        <b-btn
            @click="routeActivityDestination(activity)"
            class="d-flex align-items-center justify-content-between p-2 bg-light border-top m-0 rounded-0"
            variant="light"
            block
        >
            <div class="d-flex align-items-center text-truncate">
                <img
                    class="small-avatar img-fluid rounded mr-2"
                    :src="activity.avatar"
                />
                <div
                    class="text-truncate small"
                    :class="{ 'text-secondary': activity.seen }"
                >
                    {{ activity.title }}
                </div>
            </div>
            <div class="small text-secondary ml-2">
                <live-timestamp :timestamp="activity.created" />
            </div>
        </b-btn>
    </div>
</template>

<script>
export default {
    methods: {
        routeActivityDestination(activity) {
            this.$store.commit("interface/setRightSidebarVisible", false);

            this.$store.dispatch("activity/markActivitySeen", activity.id);

            if (activity.type === "message" || activity.type === "sentCredit") {
                this.$store.dispatch("profile/viewProfile", {
                    userId: activity.fromUserId,
                    view: "conversation",
                });
            }

            if (activity.type === "follow") {
                this.$store.dispatch("profile/viewProfile", {
                    userId: activity.fromUserId,
                });
            }

            if (activity.type === "postComment") {
                this.$router.push(activity.relativeUrl);
            }

            if (activity.type === "commentReaction") {
                this.$router.push(activity.relativeUrl);
            }

            if (activity.type === "inviteSecretGallery") {
                this.$router.push(activity.relativeUrl);
            }

            if (activity.type === "live") {
                this.$router.push(activity.relativeUrl);
            }

            if (activity.type === "liveRequest") {
                this.$router.push(activity.relativeUrl);
            }

            if (activity.type === "creatorRequest") {
                this.$router.push(activity.relativeUrl);
            }
        },
    },
    props: ["activity"],
};
</script>