<template>
    <div v-if="hasInterests">
        <div class="p-3 d-flex">
            <b-btn
                variant="light"
                @click="$store.commit('interest/setView', 'inbound')"
                v-if="filteredInbound && filteredInbound.length"
                class="d-flex align-items-center justify-content-between"
                pill
            >
                <div class="d-flex align-items-center">
                    <img
                        class="rounded small-avatar mr-2"
                        :src="filteredInbound[0].standardMap.photo"
                    />
                    <div
                        :class="{
                            'text-primary': currentInterestView === 'inbound',
                        }"
                    >
                        {{ $t("Following Me") }}
                    </div>
                </div>
                <b-badge v-if="inboundCount" variant="danger" pill>
                    {{ inboundCount }}
                </b-badge>
            </b-btn>
            <b-btn
                variant="light"
                @click="$store.commit('interest/setView', 'outbound')"
                v-if="filteredOutbound && filteredOutbound.length"
                class="d-flex align-items-center justify-content-between ml-2"
                pill
            >
                <div class="d-flex align-items-center">
                    <img
                        class="rounded small-avatar mr-2"
                        :src="filteredOutbound[0].standardMap.photo"
                    />
                    <div>
                        <div
                            :class="{
                                'text-primary':
                                    currentInterestView === 'outbound',
                            }"
                        >
                            {{ $t("People I'm following") }}
                        </div>
                    </div>
                </div>
            </b-btn>
        </div>
        <div v-if="currentInterestView">
            <b-btn
                v-if="currentViewHasUnreadInterests"
                @click="
                    $store.dispatch('interest/markAllSeen', currentInterestView)
                "
                variant="light"
                class="rounded-0 border-0 py-3"
                block
                size="sm"
            >
                {{ $t("Mark All as Read") }}
            </b-btn>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.position-absolute {
    right: 0;
    top: 0;
}
</style>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapState("interest", ["currentInterestView"]),
        ...mapGetters("interest", [
            "filteredInbound",
            "filteredOutbound",
            "filteredMatch",
            "currentInterests",
        ]),
        inboundCount() {
            return this.getCountForType(this.filteredInbound);
        },
        matchCount() {
            return this.getCountForType(this.filteredMatch);
        },
        currentViewHasUnreadInterests() {
            return this.getCountForType(this.currentInterests);
        },
        hasInterests() {
            return (
                this.filteredInbound.length ||
                this.filteredOutbound.length ||
                this.filteredMatch.length
            );
        },
    },
    methods: {
        getCountForType(interestType) {
            if (!interestType) {
                return null;
            }

            const count = interestType.filter(
                (interest) => !interest.seen
            ).length;

            return count ? count : null;
        },
    },
    components: {},
};
</script>