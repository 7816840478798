import api from "@/service/api";

// LEGACY subscribtion module
// delete once finished

const state = () => ({
    source: null,
    creatorUserId: null,
    creatorPostId: null,
    creatorGalleryId: null,
    subscriptionPrice: null,
})

const mutations = {
    reset(state) {
        state.source = null;
        state.creatorUserId = null;
        state.creatorGalleryId = null;
        state.creatorPostId = null;
        state.subscriptionPrice = null;
    },
    setSource(state, source) {
        state.source = source;
    },
    setCreatorUserId(state, source) {
        state.creatorUserId = source;
    },
    setSubscriptionPrice(state, source) {
        state.subscriptionPrice = source;
    },
    setCreatorGalleryId(state, source) {
        state.creatorGalleryId = source;
    },
    setCreatorPostId(state, value) {
        state.creatorPostId = value;
    },
}

const actions = {
    setCreatorUserId({ commit }, creatorUserId) {
        commit('setCreatorUserId', creatorUserId)

        api.post('/legacySubscription/price', { creatorUserId }).then(response => {
            if (response?.data?.subscriptionPrice) {
                commit('setSubscriptionPrice', response.data.subscriptionPrice)
            }
        })
    },
    fromGallery({ commit, dispatch }, creatorGallery) {
        commit('reset')
        dispatch('setCreatorUserId', creatorGallery.standardMap.id)
        commit('setCreatorGalleryId', creatorGallery.id)
    },
    fromProfile({ commit, dispatch }, standardMap) {
        commit('reset')
        dispatch('setCreatorUserId', standardMap.id)

        commit('interface/setCreatorSubscriptionModalVisible', true, { root: true })
    },
    fromPost({ commit, dispatch }, creatorPost) {
        commit('reset')

        commit('setCreatorPostId', creatorPost.id)
        dispatch('setCreatorUserId', creatorPost.userId)

        commit('interface/setCreatorSubscriptionModalVisible', true, { root: true })
    },
    async subscribe({ state, dispatch, commit }) {
        await api.post('/legacySubscription/create', {
            creatorPostId: state.creatorPostId,
            // these are both optional, kinda swizzle-esque
            creatorUserId: state.creatorUserId,
            creatorGalleryId: state.creatorGalleryId,
        }).then(() => {
            dispatch("onboarding/status", null, { root: true })
            commit('interface/setCreatorSubscriptionModalVisible', false, { root: true })
        });
    },
}


const getters = {
    canSubscribeToUserId: (state, getters, rootState) => (userId) => {
        if (rootState.onboarding.user?.id === userId) {
            return false;
        }

        return true;
    },
    canViewPost: (state, getters) => (creatorPost) => {
        if (getters.subscribedToCreatorUserId(creatorPost.userId)) {
            return true;
        }

        if (creatorPost.private) {
            return false;
        }

        return true;
    },
    subscribedToCreatorUserId: (state, getters, rootState) => (userId) => {
        return rootState.onboarding?.user?.subscribedUserIds?.includes(userId)
    },
    offerSubscribeForCreatorPost: (state, getters) => (creatorPost) => {
        if (getters.subscribedToCreatorUserId(creatorPost.userId)) {
            return false
        }

        return creatorPost.private;
    },
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
