<template>
    <div v-if="showPostGifts">
        <gift-multiple
            type="creatorPost"
            :id="creatorPost.id"
            :sentGiftIds="creatorPost.sentGiftIds"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import GiftMultiple from "@/components/gift/GiftMultiple";

export default {
    computed: {
        showPostGifts() {
            if (!this.canViewPost(this.creatorPost)) {
                return false;
            }

            if (this.postFocused(this.creatorPost)) {
                return false;
            }

            return true;
        },
        ...mapGetters("comment", ["postFocused"]),
        ...mapGetters("userSubscription", ["canViewPost"]),
    },
    components: {
        GiftMultiple,
    },
    props: ["creatorPost"],
};
</script>