<template>
    <div v-if="creatorLivestream">
        <div
            v-if="
                !livestreamViewerUserIds.length &&
                creatorLivestream.startedAt &&
                onboardingUser.creatorIdlePay
            "
        >
            <creator-livestream-idle />
        </div>

        <b-alert
            variant="warning"
            v-if="!creatorLivestream?.startedAt"
            show
            class="rounded-0 border-0"
        >
            <h5>
                <b-spinner small />
                Waiting for video...
            </h5>
            Your livestream will start as soon as your video loads, this can
            take a few seconds...
        </b-alert>
        <div>
            <creator-livestream-details />
        </div>

        <div class="">
            <creator-livestream-agora />
        </div>

        <div class="pt-2">
            <creator-livestream-share />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import CreatorLivestreamShare from "@/components/always/creatorLivestream/CreatorLivestreamShare";
import CreatorLivestreamDetails from "@/components/always/creatorLivestream/CreatorLivestreamDetails";
import CreatorLivestreamAgora from "@/components/always/creatorLivestream/CreatorLivestreamAgora";
import CreatorLivestreamIdle from "@/components/always/creatorLivestream/CreatorLivestreamIdle";

export default {
    computed: {
        ...mapState("creatorLivestream", ["creatorLivestream"]),
        ...mapState("pusher", ["livestreamViewerUserIds"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        CreatorLivestreamShare,
        CreatorLivestreamDetails,
        CreatorLivestreamAgora,
        CreatorLivestreamIdle,
    },
};
</script>
