import api from "@/service/api";

const getErrorMessage = (error) => {
    if (
        error?.response?.data?.code === 'chargeFailed'
        && error?.response?.data?.message
    ) {
        return error.response.data.message;
    }

    return "There was an error charging your card, check your information and try again"
}

const state = () => ({
    source: null,
    creatorUserId: null,
    creatorPostId: null,
    creatorGalleryId: null,
    subscriptionPrice: null,
    subscriptionLocalPrice: null,
    creatorStandardMap: null,
    subscribing: false,
    purchaseSuccessful: false,
    cancelSuccessful: false,
    cancelSubscription: false,
    userSubscription: null,
    postCount: null,
    priceForUser: null,
    error: null,
})

const mutations = {
    reset(state) {
        state.source = null;
        state.error = null;
        state.creatorUserId = null;
        state.creatorGalleryId = null;
        state.creatorPostId = null;
        state.subscriptionPrice = null;
        state.subscriptionLocalPrice = null;
        state.creatorStandardMap = null;
        state.subscribing = false;
        state.purchaseSuccessful = false;
        state.cancelSuccessful = false;
        state.cancelSubscription = false;
        state.userSubscription = null;
        state.postCount = null;
        state.priceForUser = null;
    },
    setSource(state, source) {
        state.source = source;
    },
    setError(state, value) {
        state.error = value;
    },
    setCreatorUserId(state, source) {
        state.creatorUserId = source;
    },
    setSubscriptionPrice(state, source) {
        state.subscriptionPrice = source;
    },
    setSubscriptionLocalPrice(state, source) {
        state.subscriptionLocalPrice = source;
    },
    setCreatorStandardMap(state, source) {
        state.creatorStandardMap = source;
    },
    setCreatorGalleryId(state, source) {
        state.creatorGalleryId = source;
    },
    setSubscribing(state, source) {
        state.subscribing = source;
    },
    setPurchaseSuccessful(state, source) {
        state.purchaseSuccessful = source;
    },
    setCancelSuccessful(state, source) {
        state.cancelSuccessful = source;
    },
    setPriceForUser(state, source) {
        state.priceForUser = source;
    },
    setCreatorPostId(state, value) {
        state.creatorPostId = value;
    },
    setUserSubscription(state, value) {
        state.userSubscription = value;
    },
    setPostCount(state, value) {
        state.postCount = value;
    },
    setCancelSubscription(state, value) {
        state.cancelSubscription = value;
    },
}

const actions = {
    setCreatorUserId({ commit }, creatorUserId) {
        commit('setCreatorUserId', creatorUserId)

        api.post('/subscription/price', { creatorUserId }).then(response => {
            if (response?.data?.subscriptionPrice) {
                commit('setSubscriptionPrice', response.data.subscriptionPrice)
            }

            if (response?.data?.subscriptionLocalPrice) {
                commit('setSubscriptionLocalPrice', response.data.subscriptionLocalPrice)
            }

            if (response?.data?.creatorStandardMap) {
                commit('setCreatorStandardMap', response.data.creatorStandardMap)
            }
        })
    },
    fromGallery({ commit, dispatch }, creatorGallery) {
        commit('reset')
        dispatch('setCreatorUserId', creatorGallery.standardMap.id)
        commit('setCreatorGalleryId', creatorGallery.id)
    },
    fromProfile({ commit, dispatch }, standardMap) {
        commit('reset')
        dispatch('setCreatorUserId', standardMap.id)

        commit('interface/setCreatorSubscriptionModalVisible', true, { root: true })
    },
    fromPost({ commit, dispatch }, creatorPost) {
        commit('reset')

        commit('setCreatorPostId', creatorPost.id)
        dispatch('setCreatorUserId', creatorPost.userId)

        commit('interface/setCreatorSubscriptionModalVisible', true, { root: true })
    },
    createCardAndSubscribe({ dispatch, commit, state }, { card, year, month, code, postalCode }) {
        commit('setSubscribing', true)

        api.post('/subscription/createCardAndSubscribe', {
            creatorUserId: state.creatorUserId,
            creatorPostId: state.creatorPostId,
            card,
            year,
            month,
            code,
            postalCode
        }).then(() => {
            dispatch("onboarding/status", null, { root: true })
            commit('setPurchaseSuccessful', true)
            commit("payment/setCreateCard", false, { root: true });
        }).catch((error) => {
            commit("setError", getErrorMessage(error));
        })
            .then(() => {
                commit('setSubscribing', false)
            })

    },
    subscribe({ state, dispatch, commit }) {
        commit('setSubscribing', true)

        api.post('/subscription/create', {
            creatorUserId: state.creatorUserId,
            creatorPostId: state.creatorPostId,
        }).then(() => {
            dispatch("onboarding/status", null, { root: true })
            commit('setPurchaseSuccessful', true)
        }).catch(() => { })
            .then(() => {
                commit('setSubscribing', false)
            })
    },
    get({ commit }, creatorUserId) {
        commit('reset')
        commit("interface/setManageSubscriptionModalVisible", true, { root: true });

        api.post('/subscription/get', {
            creatorUserId
        }).then((response) => {
            if (response?.data?.userSubscription) {
                commit('setUserSubscription', response.data.userSubscription)
            }

            if (response?.data?.postCount) {
                commit('setPostCount', response.data.postCount)
            }

            if (response?.data?.priceForUser) {
                commit('setPriceForUser', response.data.priceForUser)
            }
        })
    },
}


const getters = {
    isMySubscriber: (state, getters, rootState) => (userId) => {
        return rootState.onboarding.user?.mySubscriberUserIds?.includes(userId);
    },
    canSubscribeToUserId: (state, getters, rootState) => (userId) => {
        if (rootState.onboarding.user?.id === userId) {
            return false;
        }

        return true;
    },
    canViewPost: (state, getters, rootState) => (creatorPost) => {
        // being phased out
        if (getters.subscribedToCreatorUserId(creatorPost.userId)) {
            return true;
        }

        if (rootState.onboarding.user?.creatorPostIds?.includes(creatorPost.id)) {
            return true;
        }

        if (creatorPost.private) {
            return false;
        }

        return true;
    },
    subscribedToCreatorUserId: (state, getters, rootState) => (userId) => {
        return rootState.onboarding?.user?.subscribedUserIds?.includes(userId) || rootState.onboarding?.user?.legacySubscribedUserIds?.includes(userId)
    },
    isLegacySubscription: (state, getters, rootState) => (userId) => {
        return rootState.onboarding?.user?.legacySubscribedUserIds?.includes(userId)
    },
    offerSubscribeForCreatorPost: (state, getters) => (creatorPost) => {
        if (getters.subscribedToCreatorUserId(creatorPost.userId)) {
            return false
        }

        return creatorPost.private;
    },
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
