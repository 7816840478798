<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5 class="mb-0">
                    Content

                    <b-badge v-if="hasMediaItems" variant="primary" pill>
                        {{ creatorRequest.medias.length }}
                    </b-badge>
                </h5>
            </div>
            <creator-request-upload v-if="hasMediaItems" class="text-truncate">
                <b-btn variant="primary" pill>
                    <font-awesome-icon class="mr-1" icon="fa-light fa-plus" />
                    {{ $t("Add") }}
                </b-btn>
            </creator-request-upload>
        </div>

        <div class="p-3 mb-0 border rounded bg-white">
            <div v-if="hasMediaItems">
                <b-row no-gutters>
                    <b-col
                        cols="4"
                        :key="userMedia.id"
                        v-for="userMedia in creatorRequest.medias"
                        class="p-1"
                    >
                        <media-item :userMedia="userMedia" />
                        <b-btn
                            v-if="!creatorRequest.fulfilledAt"
                            variant="light"
                            @click="removeMedia(userMedia)"
                            block
                        >
                            <font-awesome-icon
                                icon="fa-light fa-times"
                                class="mr-1"
                            />
                            <span v-if="$screen.md"> Remove </span>
                        </b-btn>
                    </b-col>
                </b-row>
            </div>
            <div v-else class="py-4 text-center text-secondary">
                <div class="text-primary">
                    Complete the request and collect credits
                </div>

                <div class="my-2 branded">
                    <font-awesome-icon icon="fa-light fa-arrow-down" />
                </div>

                <creator-request-upload>
                    <b-btn variant="primary" pill size="lg">
                        <font-awesome-icon
                            class="mr-1"
                            icon="fa-light fa-plus"
                        />
                        Add Photo/Video
                    </b-btn>
                </creator-request-upload>

                <div class="mt-3 text-warning small">
                    Make sure to follow instructions carefully
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import { mapState } from "vuex";

import MediaItem from "@/components/media/MediaItem";
import CreatorRequestUpload from "@/components/creator/requests/CreatorRequestUpload";

export default {
    methods: {
        removeMedia(userMedia) {
            // MAKE REUSABLE
            // this is a one off
            this.$store.dispatch("creatorRequest/removeMedia", userMedia.id);
        },
    },
    //
    computed: {
        hasMediaItems() {
            return this.creatorRequest.medias?.length > 0;
        },
        ...mapState("creatorRequest", ["creatorRequest"]),
    },
    components: {
        MediaItem,
        CreatorRequestUpload,
    },
};
</script>
