const state = () => ({
    cloudinaryLoaded: false,
})

const mutations = {
    setCloudinaryLoaded(state, value) {
        state.cloudinaryLoaded = value;
    },
}

const actions = {
    loadCloudinaryOnce({ state, commit }) {
        if (state.cloudinaryLoaded) {
            return
        }

        commit('setCloudinaryLoaded', true);

        return new Promise((resolve) => {
            let script = document.createElement("script");

            script.onload = () => {
                resolve();
            };

            script.src = "https://upload-widget.cloudinary.com/global/all.js";

            document.head.appendChild(script);
        });
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
