<template>
    <b-list-group-item
        v-if="creatorPost.favoriteCount"
        @click="viewFavorites"
        class="rounded-0 mt-0"
        size="lg"
        block
        action
    >
        <div class="d-flex align-items-center justify-content-between">
            <div>
                {{ $t("Likes") }}
            </div>

            <div>
                <font-awesome-icon
                    icon="fa-duotone fa-thumbs-up"
                    class="text-primary"
                />
                {{ creatorPost.favoriteCount }}
            </div>
        </div>

        <b-modal
            v-model="modal"
            hide-footer
            :title="$t('Who liked my post')"
            centered
            scrollable
            
            body-class="bg-light rounded-lg"
        >
            <div v-if="creatorPost.favoriteCount">
                <div
                    :key="creatorPostFavorite.standardMap.id"
                    v-for="creatorPostFavorite in creatorPostFavorites"
                    class="d-flex align-items-center border-top py-2"
                >
                    <div @click="viewProfile(creatorPostFavorite.standardMap)">
                        <img
                            class="rounded mr-2"
                            :src="creatorPostFavorite.standardMap.photo"
                        />
                    </div>
                    <div>
                        {{ creatorPostFavorite.standardMap.name }}
                        <div class="small text-secondary">
                            {{ creatorPostFavorite.createdRelative }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <h5 class="text-center mb-3">{{ $t("No likes yet") }}</h5>
            </div>
        </b-modal>
    </b-list-group-item>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            modal: false,
            creatorPostFavorites: null,
        };
    },
    computed: {
        ...mapState("creatorPost", ["creatorPost"]),
    },
    methods: {
        viewProfile(standardMap) {
            this.$store.dispatch("profile/viewProfile", {
                userId: standardMap.id,
                profile: standardMap,
            });
        },
        viewFavorites() {
            this.modal = true;

            this.api
                .post("/favorite/index", {
                    creatorPostId: this.creatorPost.id,
                })
                .then((response) => {
                    if (response?.data?.favorites) {
                        this.creatorPostFavorites = response.data.favorites;
                    }
                });
        },
    },
};
</script>