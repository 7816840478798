<template>
    <div>
        <div
            @click="viewInterest(interest)"
            :key="interest.id"
            class="interest p-3"
            v-for="interest in currentInterests"
        >
            <div class="d-flex align-items-center w-100 text-truncate">
                <div class="mr-2">
                    <img
                        class="rounded"
                        :src="interest.standardMap.photo"
                    />
                </div>
                <div class="width-relative text-left flex-grow-1">
                    <div
                        class="
                            d-flex
                            align-items-center
                            justify-content-between
                        "
                    >
                        <div class="d-flex align-items-center">
                            <small
                                v-if="!interest.seen"
                                class="d-flex align-items-center mr-1"
                            >
                                <b-badge variant="primary">
                                    {{ $t("New") }}
                                </b-badge>
                            </small>
                            <h6 class="mb-0">
                                {{ interest.standardMap.name }}
                            </h6>
                        </div>
                        <div class="small text-secondary">
                            {{ interest.relative }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center small mt-1">
                        <img class="mr-1" :src="interest.standardMap.flagUrl" />
                        <div class="text-truncate">
                            {{ interest.standardMap.location }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.interest {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--dark);
    border-right: 2px solid transparent;

    &.active {
        background-color: var(--light);
        border-right: 2px solid var(--blue);
    }

    &:hover {
        background-color: var(--light);
        cursor: pointer;
    }

    .photo {
        height: 50px;
        width: 50px;
    }

    .content {
        max-width: 200px;
    }
}
</style>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapGetters("interest", [
            "filteredInbound",
            "filteredOutbound",
            "filteredMatch",
            "currentInterests",
        ]),
        ...mapState("interest", ["currentInterestView"]),
    },
    methods: {
        viewInterest(interest) {
            this.$store.dispatch("profile/viewProfile", {
                userId: interest.standardMap.id,
                profile: interest.standardMap,
            });

            this.$store.dispatch("interest/markSeen", interest.id);
            this.$store.commit("interface/setLeftSidebarVisible", false);
        },
    },
};
</script>