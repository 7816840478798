<template>
    <div>
        <b-dropdown
            v-if="creatorRequest.canDispute"
            variant="light"
            right
            class="rounded-pill"
        >
            <template #button-content> Options </template>
            <b-dropdown-item @click="startDispute">
                Create Dispute
            </b-dropdown-item>
        </b-dropdown>

        <b-modal centered hide-footer v-model="modal" title="Create Dispute">
            <div v-if="reason">
                <h5 class="text-center my-3">Review your dispute</h5>

                <div class="font-weight-bold">
                    Reason: {{ getCreatorDisputeReason(reason)?.reason }}
                </div>
                {{ getCreatorDisputeReason(reason)?.explainer }}

                <div class="font-weight-bold mt-3">Comment</div>
                <b-form-group>
                    <b-textarea
                        v-model="comment"
                        rows="4"
                        placeholder="Please add any details that can help explain how to correct the content"
                        autocorrect="off"
                        autocapitalize="none"
                    ></b-textarea>
                </b-form-group>

                <b-form-group>
                    <b-btn
                        :disabled="saving"
                        @click="createDispute"
                        variant="primary"
                        size="lg"
                        pill
                        block
                    >
                        Create Dispute
                    </b-btn>
                </b-form-group>
            </div>
            <div v-else-if="existingPending">
                <b-alert variant="danger" show>
                    An existing dispute already exists for this request. You can
                    only have one dispute per request at a time.
                </b-alert>
            </div>
            <div v-else-if="exceededLimit">
                <b-alert variant="danger" show>
                    The limit of disputes has been exceeded for this request,
                    you can cancel the request for a refund
                </b-alert>

                <b-btn
                    :disabled="cancelling"
                    variant="primary"
                    @click="cancelRequest"
                    pill
                    block
                >
                    Cancel Request
                </b-btn>
            </div>
            <div v-else-if="confirmed">
                <h5 class="text-center my-3">Please select reason</h5>
                <b-list-group>
                    <b-list-group-item
                        action
                        :key="reason.reason"
                        v-for="reason in creatorRequestDisputeReasons"
                        @click="selectReason(reason)"
                        class="d-flex align-items-center justify-content-between"
                    >
                        {{ reason.explainer }}
                        <font-awesome-icon
                            size="lg"
                            icon="fa-light fa-chevron-right"
                        />
                    </b-list-group-item>
                </b-list-group>
            </div>
            <div v-else>
                <div v-if="loading">
                    <generic-loading />
                </div>
                <div v-else>
                    <b-alert variant="warning" show>
                        <font-awesome-icon
                            icon="fa-duotone fa-triangle-exclamation"
                        />

                        Creating disputes will increase your dispute rate, which
                        will be presented to all creators when submitting a
                        request

                        <b-row class="mt-3 text-center">
                            <b-col cols="6">
                                <div class="small">Current Dispute Rate</div>

                                <h1>{{ disputeRate }}%</h1>
                            </b-col>
                            <b-col cols="6">
                                <div class="small">Dispute Rate After</div>
                                <h1>{{ disputeRateAfter }}%</h1>
                            </b-col>
                        </b-row>

                        <div class="mt-3">
                            <b-btn
                                size="lg"
                                variant="primary"
                                pill
                                block
                                @click="startCreateDispute"
                            >
                                Create Dispute
                            </b-btn>
                        </div>
                    </b-alert>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    data() {
        return {
            cancelling: false,
            saving: false,
            loading: false,
            modal: false,
            confirmed: false,
            existingPending: false,
            exceededLimit: false,
            reason: null,
            comment: null,
        };
    },
    computed: {
        showDisputeRateIncreaseWarning() {
            return !this.creatorRequest.wasDisputed && !this.confirmed;
        },
        ...mapState("options", ["creatorRequestDisputeReasons"]),
        ...mapState("request", [
            "creatorRequest",
            "disputeRate",
            "disputeRateAfter",
            "creatorRequestConfig",
        ]),
        ...mapGetters("options", ["getCreatorDisputeReason"]),
    },
    methods: {
        cancelRequest() {
            this.cancelling = true;

            this.api
                .post(`/creatorRequestDispute/${this.creatorRequest.id}/cancel`)
                .then(() => {
                    this.$store.dispatch("request/refresh");
                    this.modal = false;
                })
                .catch(() => {})
                .then(() => {
                    this.cancelling = false;
                });
        },
        startCreateDispute() {
            this.confirmed = true;
        },
        selectReason(reason) {
            this.reason = reason.reason;
        },
        async startDispute() {
            this.modal = true;

            const response = await this.api.post(
                `/creatorRequestDispute/${this.creatorRequest.id}/canDispute`
            );

            if (response?.data?.existingPending) {
                this.existingPending = true;
            }

            if (response?.data?.exceededLimit) {
                this.exceededLimit = true;
            }

            this.loading = false;

            if (this.creatorRequest.wasDisputed) {
                this.startCreateDispute();
            }
        },
        createDispute() {
            this.saving = true;

            this.api
                .post(
                    `/creatorRequestDispute/${this.creatorRequest.id}/create`,
                    {
                        reason: this.reason,
                        comment: this.comment,
                    }
                )
                .then(() => {
                    this.$store.dispatch("request/refresh");
                    this.modal = false;
                })
                .catch((error) => {
                    if (error?.response?.data?.code === "existingPending") {
                        this.existingPending = true;
                    }

                    if (error?.response?.data?.code === "exceededLimit") {
                        this.exceededLimit = true;
                    }
                })
                .then(() => {
                    this.saving = false;
                });
        },
    },
};
</script>