import api from "@/service/api";

import {
    getFeedKey,
    FEED_TYPE_CREATOR_POST,
    FEED_TYPE_USER_UPDATE
} from "@/constant/feed";

const getGiftKey = ({ feedKey, giftId }) => `${feedKey}-${giftId}`

const state = () => ({
    userHasGifted: false,
    giftedItems: [],
})

const mutations = {
    appendGiftedItem(state, value) {
        state.giftedItems.push(value)
        state.userHasGifted = true
    },
    removeGiftedItem(state, item) {
        state.giftedItems = state.giftedItems.filter(giftedItem => giftedItem !== item)
    },
}

const actions = {
    fromPost({ commit }, { creatorPostId, giftId } = {}) {
        // prevent double click by immediately populating state as "sent"
        const feedKey = getFeedKey({
            id: creatorPostId,
            type: FEED_TYPE_CREATOR_POST
        });

        commit('appendGiftedItem', getGiftKey({ feedKey, giftId }))

        api.post("/gift/fromPost", {
            creatorPostId,
            giftId
        })
    },
    fromUpdate({ commit }, { userUpdateId, giftId } = {}) {
        // prevent double click by immediately populating state as "sent"
        const feedKey = getFeedKey({
            id: userUpdateId,
            type: FEED_TYPE_USER_UPDATE
        });

        commit('appendGiftedItem', getGiftKey({ feedKey, giftId }))

        api.post("/gift/fromUpdate", {
            userUpdateId,
            giftId
        })
    },
}

const getters = {
    hasGiftedItem: (state) => ({ id, type, giftId }) => {
        const feedKey = getFeedKey({
            id, type
        });

        return state.giftedItems.includes(getGiftKey({ feedKey, giftId }))
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
