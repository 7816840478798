<template>
    <public-page>
        <div v-if="creatorPost" class="mt-3">
            <view-post :creatorPost="creatorPost" />
        </div>

        <div v-if="recentPosts" class="mt-3">
            <h5>
                {{ $t("More recent posts from") }}
                {{ creatorPost.standardMap.name }}
            </h5>
            <div
                :key="recentPost.id"
                v-for="recentPost in recentPosts"
                class="my-3"
            >
                <view-post :creatorPost="recentPost" />
            </div>
        </div>

        <div v-if="failedLoading" class="mt-3">
            <b-alert variant="danger" show>
                <h3>
                    {{ $t("Not Found") }}
                </h3>
                {{
                    $t(
                        "There was an error loading this post right now, the user who posted it may be inactive or have removed it"
                    )
                }}
                <b-btn
                    :to="{ name: 'feed' }"
                    variant="primary"
                    pill
                    block
                    class="mt-3"
                >
                    {{ $t("Back to Feed") }}
                </b-btn>
            </b-alert>
        </div>
    </public-page>
</template>


<script>
import { mapState } from "vuex";

import ViewPost from "@/components/post/view/ViewPost";
import PublicPage from "@/components/utility/PublicPage";

export default {
    data() {
        return {
            unsubscribeToMutations: null,
        };
    },
    created() {
        this.refresh();

        this.unsubscribeToMutations = this.$store.subscribe((mutation) => {
            // not wild about listening to events from a different module
            // should be a more generic way to fire an event that both modules can listen to

            // or perhaps prop-drill single/index mode for viewing posts, meck
            if (mutation.type === "post/postUpdated") {
                this.refresh();
            }
        });
    },
    beforeDestroy() {
        if (this.unsubscribeToMutations) {
            this.unsubscribeToMutations();
        }
    },
    watch: {
        $route(to) {
            if (
                to?.params?.id !== this.$store.state.publicPost.creatorPost?.id
            ) {
                this.$store.dispatch(
                    "publicPost/getCreatorPostBySlug",
                    to.params.slug
                );
            }
        },
    },
    computed: {
        ...mapState("publicPost", [
            "creatorPost",
            "recentPosts",
            "failedLoading",
        ]),
    },
    methods: {
        refresh() {
            if (this.$route.params?.slug) {
                this.$store.dispatch(
                    "publicPost/getCreatorPostBySlug",
                    this.$route.params?.slug
                );
            }

            // if (
            //     this.$route.query?.showComments &&
            //     this.$route.query?.commentId &&
            //     this.$route.params?.id
            // ) {
            //     this.$store.dispatch(
            //         "publicPost/viewComments",
            //         this.$route.params.id
            //     );

            //     this.$store.commit(
            //         "publicPost/setHighlightComment",
            //         this.$route.query.commentId
            //     );
            // }
        },
    },
    components: {
        ViewPost,
        PublicPage,
    },
};
</script>
        
        