<template>
    <div class="agora-dialog mt-2">
        <b-alert
            v-if="!isMicrophoneOn"
            show
            variant="danger"
            class="mb-0 rounded-0 border-0"
        >
            <font-awesome-icon icon="fa-duotone fa-microphone-slash" />
            Your microphone is off
        </b-alert>
        <div class="content-video">
            <div class="position-relative">
                <div
                    class="remoteVideoContainer bg-light rounded d-flex align-items-center justify-content-center"
                >
                    <video
                        v-show="hasVideoTrack"
                        controls
                        controlslist="nodownload"
                        id="remote-video"
                        ref="remoteVideo"
                    ></video>
                    <div v-if="!hasVideoTrack">
                        <font-awesome-icon
                            size="5x"
                            icon="fa-duotone fa-camera-slash"
                        />
                    </div>
                </div>
                <div
                    class="livestream-interaction-index hide-scrollbars h-100 p-1"
                >
                    <creator-livestream-interaction-index />
                </div>
            </div>
        </div>

        <div class="d-flex align-items-center justify-content-center py-3">
            <b-btn
                class="call-button"
                @click="toggleMicrophone"
                :class="{ 'text-danger': !isMicrophoneOn }"
                variant="secondary"
            >
                <font-awesome-icon
                    v-if="isMicrophoneOn"
                    icon="fa-duotone fa-microphone"
                />
                <font-awesome-icon
                    v-else
                    icon="fa-duotone fa-microphone-slash"
                />
            </b-btn>

            <b-dropdown
                variant="secondary"
                right
                toggle-class="rounded-pill call-button"
            >
                <template #button-content>
                    <font-awesome-icon icon="fa-solid fa-ellipsis" />
                </template>

                <b-dropdown-header v-if="videoDevices?.length">
                    {{ $t("Select Camera") }}
                </b-dropdown-header>
                <b-dropdown-item
                    @click="selectVideoDevice(videoDevice)"
                    :key="videoDevice.deviceId"
                    v-for="videoDevice in videoDevices"
                >
                    <font-awesome-icon
                        v-if="videoDeviceId === videoDevice.deviceId"
                        class="mr-1 text-primary"
                        icon="fa-light fa-check"
                    />
                    {{ videoDevice.label }}
                </b-dropdown-item>

                <b-dropdown-header v-if="audioDevices?.length">
                    {{ $t("Select Microphone") }}
                </b-dropdown-header>
                <b-dropdown-item
                    @click="selectAudioDevice(audioDevice)"
                    :key="audioDevice.deviceId"
                    v-for="audioDevice in audioDevices"
                >
                    <font-awesome-icon
                        v-if="audioDeviceId === audioDevice.deviceId"
                        class="mr-1 text-primary"
                        icon="fa-light fa-check"
                    />
                    {{ audioDevice.label }}
                </b-dropdown-item>
            </b-dropdown>

            <b-btn
                :disabled="stopping"
                @click="stopLivestream"
                class="call-button"
                variant="danger"
            >
                <font-awesome-icon icon="fa-solid fa-stop" size="lg" />
            </b-btn>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.livestream-interaction-index {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column-reverse;
    z-index: 1;
}
</style>

<script>
import { mapState } from "vuex";

import { getDevices } from "agora-rtc-sdk-ng/esm";

import CreatorLivestreamInteractionIndex from "@/components/always/creatorLivestream/CreatorLivestreamInteractionIndex";

import {
    CALL_AUDIO_DEVICE_ID,
    CALL_VIDEO_DEVICE_ID,
    CALL_AUDIO_ENABLED,
    CALL_FIRST_LAUNCH,
} from "@/constant/localStorage";

export default {
    data() {
        return {
            client: null,
            audioTrack: null,
            videoTrack: null,
            audioDevices: null,
            videoDevices: null,
            audioDeviceId: localStorage.getItem(CALL_AUDIO_DEVICE_ID),
            videoDeviceId: localStorage.getItem(CALL_VIDEO_DEVICE_ID),
        };
    },
    async mounted() {
        if (!this.joined) {
            await this.$store.dispatch("creatorLivestream/join");

            this.refreshDevices();

            if (localStorage.getItem(CALL_AUDIO_ENABLED)) {
                this.toggleMicrophone();
            }

            // video always on for live
            this.startVideo();

            // very first launch, turn on by default
            if (!localStorage.getItem(CALL_FIRST_LAUNCH)) {
                this.toggleMicrophone();
                localStorage.setItem(CALL_FIRST_LAUNCH, true);
            }
        }
    },
    computed: {
        ...mapState("options", ["agora"]),
        ...mapState("creatorLivestream", [
            "creatorLivestream",
            "joined",
            "hasVideoTrack",
            "isMicrophoneOn",
            "stopping",
        ]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        stopLivestream() {
            this.$store.dispatch("creatorLivestream/stop");
        },
        async refreshDevices() {
            const devices = await getDevices();

            this.audioDevices = devices.filter(function (device) {
                return device.kind === "audioinput";
            });

            this.videoDevices = devices.filter(function (device) {
                return device.kind === "videoinput";
            });
        },
        async startVideo() {
            this.videoTrack = await this.$store.dispatch(
                "creatorLivestream/publishVideo"
            );

            await this.videoTrack.play("remote-video");

            await this.$store.dispatch(
                "creatorLivestream/start",
                this.creatorLivestream.id
            );
        },
        async toggleMicrophone() {
            this.$store.dispatch("creatorLivestream/toggleMicrophone");
        },
    },
    components: {
        CreatorLivestreamInteractionIndex,
    },
};
</script>
