<template>
    <div>
        <h5 class="mb-0">
            <font-awesome-icon icon="fa-duotone fa-coin" class="text-warning" />
            {{ price.credits }}
            {{ $t("Credits") }}
        </h5>
        <div class="text-secondary">
            {{ price.label }}
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("wallet", ["price"]),
    },
};
</script>