<template>
    <generic-page>
        <div v-if="content">
            <b-btn
                class="px-0 mt-2"
                variant="link"
                size="lg"
                @click="
                    $router.push({
                        name: 'supportTopic',
                        params: {
                            id: topic.id,
                        },
                    })
                "
            >
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
                {{ topic.name }}
            </b-btn>

            <h5>
                {{ content.title }}
            </h5>

            <preserve-whitespace :content="content.description" />
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            topic: null,
            content: null,
        };
    },
    created() {
        const contentId = this.$route?.params?.id;

        this.api.post(`/support/content/${contentId}`).then((response) => {
            this.topic = response.data.topic;
            this.content = response.data.content;
        });
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {},
};
</script>