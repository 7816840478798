<template>
    <div @click="openUploadWidget">
        <slot />
    </div>
</template>

<script>
export default {
    created() {
        this.$store.dispatch("cloudinary/loadCloudinaryOnce");
    },
    methods: {
        openUploadWidget() {
            const options = {
                cloud_name: "findmateapp",
                upload_preset: "w9g8lnht",
                // cropping: true, //add a cropping step
                // showAdvancedOptions: true,  //add advanced options (public_id and tag)
                sources: [
                    "local",
                    "url",
                    "camera",
                    "dropbox",
                    "facebook",
                    "instagram",
                    "google_drive",
                ],
                showPoweredBy: false,
                // moar options...

                // multiple: false,  //restrict upload to a single file
                // folder: "user_images", //upload files to the specified folder
                // tags: ["users", "profile"], //add the given tags to the uploaded files
                // context: {alt: "user_uploaded"}, //add the given context data to the uploaded files
                // clientAllowedFormats: ["images"], //restrict uploading to image files only
                // maxImageFileSize: 2000000,  //restrict file size to less than 2MB
                // maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
                // theme: "purple", //change to a purple theme
            };

            if (this.clientAllowedFormats) {
                options.clientAllowedFormats = this.clientAllowedFormats;
            } else {
                options.clientAllowedFormats = ["image"];

                // when ready....
                // options.clientAllowedFormats = ["video"];
            }

            window.cloudinary
                .openUploadWidget(options, (error, result) => {
                    if (!error && result?.event === "success") {
                        this.$emit("uploadSuccess", result.info.secure_url, result.info);
                    }
                })
                .open();
        },
    },
    props: ["clientAllowedFormats"],
};
</script>