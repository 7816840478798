<template>
    <div>
        <div class="d-flex align-items-center justify-content-between mt-3">
            <h5 class="mb-0">Legacy Transactions</h5>
        </div>
        <b-table-simple striped class="mt-2">
            <thead>
                <th>Summary</th>
                <th>Amount</th>
                <th>When</th>
            </thead>
            <tbody>
                <tr :key="transaction.id" v-for="transaction in transactions">
                    <td class="bg-light w-66">
                        {{ transaction.meta.description || "General" }}
                    </td>
                    <td class="">
                        {{ transaction.amount }}
                    </td>
                    <td class="">
                        <live-timestamp :timestamp="transaction.created" />
                    </td>
                </tr>
            </tbody>
        </b-table-simple>
    </div>
</template>

<script>
export default {
    data() {
        return {
            transactions: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api.post("/transaction/legacy").then((response) => {
                if (response?.data?.transactions) {
                    this.transactions = response.data.transactions;
                }
            });
        },
    },
};
</script>