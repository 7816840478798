<template>
    <div class="position-relative" @contextmenu.prevent="disableRightClick">
        <div v-if="showPrivateLabel" class="size-square">
            <div
                class="fake-link text-warning private-wrapper rounded position-absolute absolute-center text-center"
            >
                <font-awesome-icon icon="fa-duotone fa-lock" size="3x" />
                <div class="private-wrapper-label">{{ $t("Private") }}</div>
            </div>
        </div>
        <div
            v-if="creatorPost.video"
            class="position-relative fake-link overflow-hidden"
        >
            <media-video
                :creatorPost="creatorPost"
                :creatorPostVideo="creatorPost.video"
                size="square"
            />
        </div>
        <div v-else-if="creatorPost.gif">
            <giphy-viewer :gif="creatorPost.gif.meta" />
        </div>
        <div
            @click="clickedMedia(creatorPost, creatorPost.primary)"
            v-else-if="creatorPost.primary"
            class="position-relative fake-link overflow-hidden"
        >
            <media-photo
                :creatorPost="creatorPost"
                :userPhoto="creatorPost.primary"
                size="square"
            />

            <b-btn
                v-if="morePhotosCount"
                class="position-absolute"
                style="bottom: 1rem; left: 1rem"
                variant="light"
                pill
            >
                {{ morePhotosCount }}
                {{ $t("more photos") }}
            </b-btn>
        </div>

        <b-row no-gutters class="border-light border-bottom overflow-hidden">
            <b-col
                :key="photo.id"
                v-for="photo in firstFourPhotosExcludingPrimary"
                cols="3"
                class="overflow-hidden fake-link"
            >
                <media-photo
                    :creatorPost="creatorPost"
                    :userPhoto="photo"
                    size="thumb"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import MediaVideo from "@/components/media/MediaVideo";
import MediaPhoto from "@/components/media/MediaPhoto";
import GiphyViewer from "@/components/utility/GiphyViewer";
import { mapGetters } from "vuex";

export default {
    computed: {
        showPrivateLabel() {
            return !this.canViewPost(this.creatorPost);
        },
        morePhotosCount() {
            const photoCount = this.creatorPost.photos.length;
            return photoCount > 5 ? photoCount - 5 : false;
        },
        // once videos are done, mix them in by sort order
        firstFourPhotosExcludingPrimary() {
            return this.creatorPost.photos
                .filter((photo) => photo.id !== this.creatorPost.primaryPhotoId)
                .slice(0, 4);
        },
        ...mapGetters("userSubscription", ["canViewPost"]),
    },
    methods: {
        disableRightClick(e) {
            e.preventDefault();
        },
        clickedMedia(creatorPost, creatorPostPhoto) {
            if (this.canViewPost(creatorPost)) {
                this.viewPhoto(creatorPost, creatorPostPhoto);
            }
        },
        viewPhoto(creatorPost, creatorPostPhoto) {
            this.$store.commit("postPhoto/setCurrentPost", creatorPost);
            this.$store.dispatch("postPhoto/launch", creatorPostPhoto);
        },
    },
    components: {
        MediaPhoto,
        MediaVideo,
        GiphyViewer,
    },
    props: ["creatorPost"],
};
</script>