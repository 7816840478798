<template>
    <universal-uploader
        class="text-truncate"
        @uploadSuccess="uploadSuccess"
        :clientAllowedFormats="['image', 'video']"
    >
        <slot />
    </universal-uploader>
</template>

<script>
import { mapState } from "vuex";
import UniversalUploader from "@/components/universalUploader/UniversalUploader";

export default {
    methods: {
        async uploadSuccess(url, info) {
            // show payload details
            // console.log(info);

            // map cloudinary values to internal
            // they use image, we use photo for example
            const type = info.resource_type === "video" ? "video" : "photo";

            await this.api.post("/creatorGallery/addUserMedia", {
                creatorGalleryId: this.creatorGallery.id,
                source: "creatorGallery",
                type,
                publicId: info.public_id,
            });

            this.$store.dispatch("creatorGallery/refreshGallery");
        },
    },
    computed: {
        ...mapState("creatorGallery", ["creatorGallery"]),
    },
    components: {
        UniversalUploader,
    },
};
</script>