<template>
    <div>
        <b-alert
            variant="success"
            class="rounded-0 border-0 mb-0 text-center"
            v-if="enableRequestLive"
            show
        >
            <div v-if="requestedLive">
                <font-awesome-icon icon="fa-light fa-check" />
                Request sent
                <div class="small my-2">
                    You're following the user so you'll get notified when they
                    go live
                </div>
            </div>
            <div v-else>
                <font-awesome-icon icon="fa-solid fa-circle" />
                User is online right now

                <div class="my-2 small">
                    You can send them a request to go live
                </div>

                <b-btn
                    :disabled="sending"
                    @click="requestLive"
                    variant="success"
                    block
                    class="my-2"
                    pill
                >
                    Request User to Go Live
                </b-btn>
            </div>
        </b-alert>
        <user-between-status v-else :user="profile" :alert="true" />
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import UserBetweenStatus from "@/components/user/UserBetweenStatus";

export default {
    data() {
        return {
            sending: false,
            requestedLive: false,
        };
    },
    computed: {
        enableRequestLive() {
            return (
                !this.onboardingUser.censored &&
                this.profile.creator &&
                this.onlineUserIds.includes(this.profile.id) &&
                !this.userIsLive(this.profile.id)
            );
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("pusher", ["onlineUserIds"]),
        ...mapGetters("pusher", ["userIsLive"]),
        ...mapState("profile", ["profile"]),
    },
    methods: {
        requestLive() {
            this.sending = true;

            this.api
                .post(`/profile/${this.profile.id}/requestLive`)
                .then(() => {
                    this.requestedLive = true;
                })
                .catch(() => {})
                .then(() => {
                    this.sending = false;
                });
        },
    },
    components: {
        UserBetweenStatus,
    },
};
</script>