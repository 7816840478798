<template>
    <div class="d-flex align-items-center">
        <b-btn
            @click="maximizeLivestream"
            v-if="creatorLivestreamMinimized"
            variant="light"
            class="border-danger border-heavy"
            pill
            size="sm"
        >
            <b-spinner type="grow" small variant="danger" />
            Back to Livestream

            <b-badge
                variant="danger"
                pill
                v-if="livestreamViewerUserIds.length"
            >
                <font-awesome-icon icon="fa-duotone fa-users" />
                {{ livestreamViewerUserIds.length }}
            </b-badge>
        </b-btn>
        <h5
            v-else
            @click="home"
            class="mb-0 fake-link d-flex align-items-center justify-content-center"
        >
            <img class="small-avatar rounded mr-1" :src="brand.logo100" />
            {{ brand.name }}
        </h5>
        <b-btn
            @click="viewLiveUsers"
            v-if="showLiveUsers"
            variant="light"
            pill
            class="btn-brand ml-2 custom-live-button"
            size="sm"
        >
            <span class="font-weight-bold"> LIVE </span>

            <b-badge variant="danger" pill>
                {{ liveUserIds.length }}
            </b-badge>
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        //off temporarily
        showLiveUsers() {
            return false;
            // if (
            //     this.onboardingUser?.livestreamViewer &&
            //     this.liveUserIds.length
            // ) {
            //     return true;
            // }

            // return false;
        },
        ...mapState("brand", ["brand"]),
        ...mapState("interface", ["creatorLivestreamMinimized"]),
        ...mapState("pusher", ["livestreamViewerUserIds", "liveUserIds"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        maximizeLivestream() {
            this.$store.commit(
                "interface/setCreatorLivestreamMinimized",
                false
            );
            this.$store.commit(
                "interface/setCreatorLivestreamModalVisible",
                true
            );
        },
        home() {
            if (this.$screen.lg) {
                this.$store.dispatch("navigation/home");
            } else {
                if (window.scrollY === 0) {
                    this.$store.dispatch("navigation/home");
                } else {
                    this.$scrollToTop("smooth");
                }
            }
        },
        viewLiveUsers() {
            // temporary just refresh search
            // since thats what the modal would be anyway
            // and prevent partial signups from showing live users
            this.$store.dispatch("search/refresh");
            this.$router.push("/browse/search");
        },
    },
};
</script>


