<template>
    <div>
        <h5 class="mt-3">Jackpot Leaderboard</h5>
        <b-table-simple bordered striped class="mb-0">
            <thead>
                <tr>
                    <th>Place</th>
                    <th>Payout</th>
                    <th>Rewards</th>
                    <th>Contender</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    :key="leaderboardUser.user.id"
                    v-for="(leaderboardUser, index) in jackpot.leaderboardUsers"
                >
                    <td>
                        <div v-if="index === 0">
                            <font-awesome-icon
                                icon="fa-duotone fa-medal"
                                class="gold"
                            />
                            1st
                        </div>
                        <div v-else-if="index === 1">
                            <font-awesome-icon
                                icon="fa-duotone fa-medal"
                                class="silver"
                            />
                            2nd
                        </div>
                        <div v-else-if="index === 2">
                            <font-awesome-icon
                                icon="fa-duotone fa-medal"
                                class="bronze"
                            />
                            3rd
                        </div>
                        <div v-else class="">Runner up</div>
                    </td>
                    <td>
                        <div v-if="index === 0">
                            50%
                            <coin-badge :amount="jackpot.payouts[index]" />
                        </div>
                        <div v-else-if="index === 1">
                            30%
                            <coin-badge :amount="jackpot.payouts[index]" />
                        </div>
                        <div v-else-if="index === 2">
                            20%
                            <coin-badge :amount="jackpot.payouts[index]" />
                        </div>
                    </td>
                    <td>{{ leaderboardUser.count }}</td>
                    <td>
                        <img class="mr-1" :src="leaderboardUser.user.flagUrl" />
                        <b-badge
                            v-if="onboardingUser.id === leaderboardUser.user.id"
                            variant="primary"
                            pill
                        >
                            Me
                        </b-badge>
                        <b-badge v-else variant="light" pill class="border">
                            {{ leaderboardUser.user.codename }}
                        </b-badge>
                    </td>
                </tr>
            </tbody>
        </b-table-simple>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    props: ["jackpot"],
};
</script>