<template>
    <div>
        <div class="text-right">
            <b-btn
                @click="closeModal"
                variant="light"
                class="action-button glass border-0"
            >
                <font-awesome-icon size="lg" icon="fa-light fa-times" />
            </b-btn>
        </div>
        <div class="text-center py-3">
            <div class="text-primary py-3">
                <font-awesome-icon size="5x" icon="fa-duotone fa-bell" />
            </div>

            <h3 class="mb-0">Enable Notifications?</h3>

            <div class="mt-3">
                Do you want to get notifications for new messages and users
                interested in you?
            </div>

            <b-row class="mt-4">
                <b-col cols="12" md="6" class="my-2">
                    <b-btn
                        variant="primary"
                        @click="enableNotifications"
                        pill
                        block
                        size="lg"
                    >
                        Enable Notifications
                    </b-btn>
                </b-col>
                <b-col cols="12" md="6" class="my-2">
                    <b-btn
                        variant="secondary"
                        @click="remindMeLater"
                        pill
                        block
                        size="lg"
                    >
                        {{ $t("Remind me later") }}
                    </b-btn>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        currentOffer() {
            return this.$store.state.displayOffer?.currentOffer;
        },
    },
    methods: {
        enableNotifications() {
            this.$store.dispatch("setting/editOnboardingStep", "push");
            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
        closeModal() {
            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
        remindMeLater() {
            this.$store.commit("interface/setDisplayOfferModalVisible", false);

            this.api.post("/offer/removeViewedOffer", {
                offer: this.currentOffer.offer,
            });
        },
    },
};
</script>