<template>
    <div>
        <b-btn
            @click="inviteUser"
            variant="light"
            block
            class="rounded-0 mt-0"
            :size="$screen.lg ? 'lg' : 'sm'"
        >
            <div>
                <font-awesome-icon
                    icon="fa-duotone fa-share"
                    size="lg"
                />
            </div>
            {{ $t("Share") }}
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("profile", ["profile"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        inviteUser() {
            this.$store.dispatch(
                "creatorGalleryInvite/fromProfile",
                this.profile
            );
        },
    },
};
</script>