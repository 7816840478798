<template>
    <div class="photo">
        <div
            class="bio py-2 px-3 d-flex align-items-center fake-link text-left"
            @click="viewProfile"
        >
            <div class="mr-2">
                <img :src="photo.standardMap.flagUrl" />
            </div>
            <div>
                <div>
                    {{ photo.standardMap.name }}
                    <span class="opacity-50 small ml-1">
                        {{ photo.standardMap.age }}
                    </span>
                </div>
                <div class="small opacity-75">
                    {{ photo.standardMap.country }}
                </div>
            </div>
        </div>

        <div
            class="rating-indicator upvote"
            v-if="upvotedPhotoIds.includes(photo.photoDefaultMap.id)"
        >
            <font-awesome-icon icon="fa-duotone fa-thumbs-up" size="5x" />
        </div>

        <div
            class="rating-indicator downvote"
            v-if="downvotedPhotoIds.includes(photo.photoDefaultMap.id)"
        >
            <font-awesome-icon icon="fa-duotone fa-thumbs-down" size="5x" />
        </div>

        <img class="rateable" :src="photo.photoDefaultMap.medium" />
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("swipe", [
            "finishedInitialLoad",
            "photosToRate",
            "currentPhotoToRate",
            "upvotedPhotoIds",
            "downvotedPhotoIds",
            "currentSlidePosition",
        ]),
    },
    methods: {
        viewProfile() {
            this.$store.commit("interface/setRightSidebarVisible", false);

            this.$store.dispatch("profile/viewProfile", {
                userId: this.photo.standardMap.id,
                profile: this.photo.standardMap,
            });
        },
    },
    props: ["photo"],
};
</script>