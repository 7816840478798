import { render, staticRenderFns } from "./DeleteReasonIndex.vue?vue&type=template&id=37e8ee4b&"
import script from "./DeleteReasonIndex.vue?vue&type=script&lang=js&"
export * from "./DeleteReasonIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports