var render = function render(){var _vm=this,_c=_vm._self._c;return _c('generic-page',[_c('b-btn',{staticClass:"pl-0",attrs:{"variant":"link","size":"lg","to":"/creator/gallery"}},[_c('font-awesome-icon',{attrs:{"size":"lg","icon":"fa-light fa-chevron-left"}}),_vm._v(" "+_vm._s(_vm.$t("All Galleries"))+" ")],1),(_vm.creatorGallery)?_c('div',[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.creatorGallery.name)+" ")]),_c('div',{staticClass:"text-center"},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.blur),callback:function ($$v) {_vm.blur=$$v},expression:"blur"}},[_vm._v(" "+_vm._s(_vm.$t("Preview"))+" ")])],1)]),_c('div',{staticClass:"mt-2"},[(_vm.blur)?_c('b-alert',{staticClass:"border-0 rounded-0 mb-0 small",attrs:{"show":"","variant":"primary"}},[_c('font-awesome-icon',{attrs:{"icon":"fa-duotone fa-eye-slash"}}),_vm._v(" "+_vm._s(_vm.$t("How content appears to viewer before buying"))+" ")],1):_vm._e(),_c('b-row',{staticClass:"bg-black",attrs:{"no-gutters":""}},_vm._l((_vm.creatorGallery.medias),function(userMedia){return _c('b-col',{key:userMedia.id,staticClass:"p-2 overflow-hidden",attrs:{"cols":"4"}},[_c('gallery-media-item',{attrs:{"preview":true,"blur":_vm.blur,"userMedia":userMedia}})],1)}),1)],1),_c('div',{staticClass:"d-flex align-items-center justify-content-between bg-white"},[_c('b-btn',{staticClass:"rounded-0 mt-0",attrs:{"variant":"light","size":"lg","block":""},on:{"click":function($event){return _vm.$router.push({
                        name: 'creatorGalleryEdit',
                        params: {
                            id: _vm.creatorGallery.id,
                        },
                    })}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-duotone fa-pencil","size":"lg"}}),_c('div',[_vm._v(" "+_vm._s(_vm.$t("Edit"))+" ")])],1),(false)?_c('b-btn',{staticClass:"rounded-0 mt-0",attrs:{"disabled":!_vm.creatorGallery.publishedAt,"variant":"light","size":"lg","block":""},on:{"click":function($event){return _vm.$router.push({
                        name: 'creatorGalleryInvite',
                        params: {
                            id: _vm.creatorGallery.id,
                        },
                    })}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-duotone fa-chart-network","size":"lg"}}),_c('div',[_vm._v(" "+_vm._s(_vm.$t("Invite"))+" ")])],1):_vm._e(),(false)?_c('b-btn',{staticClass:"rounded-0 mt-0",attrs:{"disabled":!_vm.creatorGallery.publishedAt,"variant":"light","size":"lg","block":""},on:{"click":function($event){return _vm.$router.push({
                        name: 'creatorGalleryShare',
                        params: {
                            id: _vm.creatorGallery.id,
                        },
                    })}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-duotone fa-share","size":"lg"}}),_c('div',[_vm._v(" "+_vm._s(_vm.$t("Share"))+" ")])],1):_vm._e()],1),_c('div',{staticClass:"py-2"},[_c('b-list-group',[_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("Status"))+" ")]),_c('div',[_c('div',{class:`text-${_vm.creatorGallery.variant}`},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-circle","size":"xs"}}),_vm._v(" "+_vm._s(_vm.creatorGallery.status)+" ")],1)])]),_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("Price"))+" ")]),_c('gallery-price',{attrs:{"creatorGallery":_vm.creatorGallery}})],1),_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("Total Earnings"))+" ")]),(_vm.creatorGallery.totalEarnings)?_c('coin-badge',{staticClass:"border",attrs:{"amount":_vm.creatorGallery.totalEarnings}}):_vm._e()],1),_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("Created"))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.creatorGallery.createdRelative)+" ")])])],1)],1)]):_c('div',[_c('generic-loading')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }