import api from "@/service/api";

const startPage = 1
const defaultView = 'unread'

const state = () => ({
    blockedCreatorPostIds: [],
    view: defaultView,
    creatorPosts: null,
    page: startPage,
    viewerDraftedCommentForCreatorPostId: [],
    wasVisible: [],
    pendingIds: [],
    markedSeenIds: [],
    hasMorePages: false,
    viewedRequestInProgress: false,
    postIndexLoading: false,
})

const mutations = {
    reset(state) {
        state.page = startPage
        state.creatorPosts = null
        state.view = defaultView
        state.hasMorePages = false
        state.postIndexLoading = false
        state.viewedRequestInProgress = false
        state.wasVisible = []
        state.pendingIds = []
    },
    setView(state, value) {
        state.view = value
    },
    setCreatorPosts(state, value) {
        state.creatorPosts = value
    },
    updateSinglePostLocally(state, creatorPost) {
        if (!state.creatorPosts) {
            return;
        }

        state.creatorPosts = state.creatorPosts.map(stateCreatorPost => stateCreatorPost.id === creatorPost.id ? creatorPost : stateCreatorPost)
    },
    appendDraftedCommentCreatorPostId(state, creatorPostId) {
        state.viewerDraftedCommentForCreatorPostId.push(creatorPostId)
    },
    removeDraftedCommentCreatorPostId(state, creatorPostId) {
        state.viewerDraftedCommentForCreatorPostId = state.viewerDraftedCommentForCreatorPostId.filter(searchCreatorPostId => searchCreatorPostId !== creatorPostId)
    },
    nextPage(state) {
        state.page++;
    },
    previousPage(state) {
        state.page--;
    },
    setHasMorePages(state, value) {
        state.hasMorePages = value
    },
    setViewedRequestInProgress(state, value) {
        state.viewedRequestInProgress = value
    },
    appendWasVisible(state, value) {
        state.wasVisible.push(value)
    },
    appendPendingIds(state, value) {
        state.pendingIds.push(value)
    },
    appendMarkedSeenIds(state, value) {
        state.markedSeenIds.push(value)
    },
    clearPendingIds(state) {
        state.pendingIds = []
    },
    blockCreatorPostId(state, value) {
        state.blockedCreatorPostIds.push(value);
    },
    setPostIndexLoading(state, value) {
        state.postIndexLoading = value
    },
}

const actions = {
    setView({ commit, dispatch }, view) {
        commit('reset')
        commit('setView', view)
        dispatch('refresh')
    },
    refresh({ commit, state }) {
        commit('setPostIndexLoading', true)

        api.post("/postIndex/index", {
            page: state.page,
            view: state.view
        })
            .then((response) => {
                if (response?.data?.creatorPosts) {
                    commit('setCreatorPosts', response.data.creatorPosts)
                }

                commit('setHasMorePages', response.data.hasMorePages)
            })
            .catch(() => { })
            .then(() => {
                commit('setPostIndexLoading', false)
            });
    },
    nextPage({ commit, dispatch }) {
        commit('setCreatorPosts', null)
        commit('nextPage')
        dispatch('refresh')
    },
    previousPage({ commit, dispatch }) {
        commit('setCreatorPosts', null)
        commit('previousPage')
        dispatch('refresh')
    },
    async markPostViewed({ state, commit }, creatorPostId) {
        if (state.markedSeenIds.includes(creatorPostId)) {
            return
        }

        commit('appendMarkedSeenIds', creatorPostId)

        if (state.viewedRequestInProgress) {
            commit('appendPendingIds', creatorPostId)
            return;
        }

        commit('setViewedRequestInProgress', true)

        const creatorPostIds = state.pendingIds;

        commit('clearPendingIds')

        creatorPostIds.push(creatorPostId);

        await api.post("/post/seen", {
            creatorPostIds,
        });

        commit('setViewedRequestInProgress', false)
    },
    visibilityChanged({ dispatch, commit, state }, { isVisible, entry } = {}) {
        if (state.view === 'mine') {
            return;
        }

        if (isVisible) {
            commit('appendWasVisible', entry.target.id);
        } else {
            if (state.wasVisible.includes(entry.target.id)) {
                dispatch('markPostViewed', entry.target.id);
            }
        }
    },
}



const getters = {
    filteredPosts(state, getters, rootState) {
        if (!state.creatorPosts) {
            return []
        }

        const unblockedPosts = state.creatorPosts
            .filter(creatorPost => !rootState.user.blockedUserIds.includes(creatorPost.userId)
                && !rootState.postIndex.blockedCreatorPostIds?.includes(creatorPost.id))

        return unblockedPosts
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
