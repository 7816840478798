import api from "@/service/api";

const state = () => ({
    validatedEmails: [],
    redirect: false,
    url: null,
})

const mutations = {
    reset(state) {
        state.url = false
        state.redirect = false
        state.validatedEmails = []
    },
    setRedirect(state, value) {
        state.redirect = value
    },
    setUrl(state, value) {
        state.url = value
    },
}


const actions = {
    refreshUrl({ state, commit, dispatch }) {
        api
            .post("/invite/url", {
                redirect: state.redirect
            })
            .then((response) => {
                if (response?.data?.url) {
                    commit('setUrl', response.data.url)
                    dispatch('onboarding/status', null, { root: true })
                }
            })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
