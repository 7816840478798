<template>
    <div>
        <div v-if="hasSubscriptions">
            <b-alert variant="danger" show class="small">
                <h5>
                    <font-awesome-icon icon="fa-solid fa-ban" class="mr-1" />
                    {{ $t("Subscriptions Active") }}
                </h5>
                {{
                    $t(
                        "You have active subscriptions. Please cancel all active subscriptions to prevent any charges once you have deleted your account"
                    )
                }}
            </b-alert>
        </div>
        <div v-else>
            <b-btn
                class="mt-3"
                variant="danger"
                block
                pill
                @click="$store.dispatch('delete/confirm')"
            >
                {{ $t("Permanently Delete My Account") }}
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        // if they dont care about content
        // unlikely to care about credits
        // they just want out, badly
        hasSubscriptions() {
            return this.onboardingUser.subscribedUserIds?.length > 0;
        },
        hasCredits() {
            return this.onboardingUser?.wallet?.balance > 0;
        },
        hasContent() {
            return this.onboardingUser?.deleteContents?.photos?.length > 0;
        },
        ...mapState("delete", [
            "selectedReason",
            "willingToWait",
            "dontClaimContent",
            "eligibleForInstantDeletion",
        ]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {},
};
</script>