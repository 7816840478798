const rules = [
    {
        title: "Earn money by chatting",
        data: "Guys pay you for every message they send. Your time here is never wasted, and as long as you reply within 24 hours, you earn 1 credit per message you reply to",
    },
    {
        title: "Don't ask for PayPal, Venmo, Cash App, etc",
        data: "Asking for money outside of the app will result in an account ban. You can ask for money within the app, which you can withdraw to your bank or mobile wallet",
    },
    {
        title: "Focus on him...",
        data: "Ask about his day, about his life, get to know him, and you will earn from the credits you get from every message. Be patient, try to keep the conversation going, it's your role to entertain, not his",
    },
    {
        title: "This is not Tinder",
        data: "You may get many messages on free apps, but you will not be flooded with messages here, guys pay you for every single message, so they are more selective about who they talk to. Saying 'Hi' won't get you many replies, but thoughtful messages will",
    },
    {
        title: "Zero tolerance for Fake/AI",
        data: "Posting any fake photos or AI generated photos, videos or pretending to be someone else will result in an account ban. Do not post anything except yourself, light filters are OK, but PhotoLab photos are not",
    },
    {
        title: "Don't ask guys for help",
        data: "If you are not clear about how something works in the app, ask us for support, do not ask guys to help you with issues, they are not your tech support team, we are",
    },
    {
        title: "Don't talk about getting paid to chat",
        data: "Keep in mind WE are not paying you to chat, the GUY is paying you to chat. We are just the wallet that holds your credits, and protects people's privacy and security. It's exciting of course, but focus on his enjoyment first",
    },
    {
        title: "Guys may ask for your number",
        data: "If a guy immediately asks for your number, it's mostly because he doesn't want to pay to chat, and only wants to chat for free. If you want to donate your time, it's up to you if you want to give it out, but you will not be paid for messages outside of the app, only within the app",
    },
    {
        title: "Don't get scammed!",
        data: "If a guy promises to pay you outside of the platform, it could be a scam. If you agree move the conversation to another app, there is no guarantee you will be paid for anything, its safer to keep all activity here",
    },
    {
        title: "When do I get paid?",
        data: "Once you reach your minimum, as shown under My Money, you can submit your withdrawal and we'll transfer money to your bank, mobile wallet or PayPal depending on your country",
    },
];

export {
    rules,
};
