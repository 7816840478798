import api from "@/service/api";

const state = () => ({
    creatorStandardMap: null,
    creatorRequest: null,
    sentRequest: null,
    disputeRate: null,
    disputeRateAfter: null,
    creatorRequestConfig: null,
    creatorRequestDisputes: null,
})

const mutations = {
    reset(state) {
        state.creatorStandardMap = null
        state.sentRequest = null
        state.disputeRate = null
        state.disputeRateAfter = null
        state.creatorRequestConfig = null
        state.creatorRequestDisputes = null
    },
    setCreatorStandardMap(state, value) {
        state.creatorStandardMap = value;
    },
    setCreatorRequest(state, value) {
        state.creatorRequest = value;
    },
    setCreatorRequestDisputes(state, value) {
        state.creatorRequestDisputes = value;
    },
    setSentRequest(state, value) {
        state.sentRequest = value;
    },
    setDisputeRate(state, value) {
        state.disputeRate = value;
    },
    setDisputeRateAfter(state, value) {
        state.disputeRateAfter = value;
    },
    setCreatorRequestConfig(state, value) {
        state.creatorRequestConfig = value;
    },
}

const actions = {
    start({ commit }, creatorStandardMap) {
        commit('reset')
        commit('setCreatorStandardMap', creatorStandardMap)
        commit("interface/setCreatorRequestModalVisible", true, { root: true });
    },
    get({ commit }, creatorRequestId) {
        commit('reset')

        api.post("/creatorRequest/get", { creatorRequestId }).then(response => {
            commit('setCreatorRequest', response?.data?.creatorRequest)
            commit('setDisputeRate', response?.data?.disputeRate)
            commit('setDisputeRateAfter', response?.data?.disputeRateAfter)
            commit('setCreatorRequestConfig', response?.data?.creatorRequestConfig)
            commit('setCreatorRequestDisputes', response?.data?.creatorRequestDisputes)
        })
    },
    complete({ dispatch, state }) {
        api.post("/creatorRequest/complete", {
            creatorRequestId: state.creatorRequest.id,
        }).then(function () {
            dispatch('refresh');
            dispatch('onboarding/status', null, { root: true });
        })
    },
    decline({ dispatch, state }) {
        api.post("/creatorRequest/decline", {
            creatorRequestId: state.creatorRequest.id,
        }).then(function () {
            dispatch('refresh');
            dispatch('onboarding/status', null, { root: true });
        })
    },
    removeMedia({ dispatch, state }, userMediaId) {
        api.post("/creatorRequest/removeUserMedia", {
            creatorRequestId: state.creatorRequest.id,
            userMediaId
        }).then(() => dispatch('refresh'))
    },
    refresh({ state, dispatch }) {
        dispatch('get', state.creatorRequest.id)
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
