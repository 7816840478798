<template>
    <div class="text-left">
        <div class="mb-2">
            <b-btn
                variant="link"
                size="lg"
                class="pl-0"
                @click="$store.commit('photo/setEditPhoto')"
            >
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
                {{ $t("Back to All Photos") }}
            </b-btn>
        </div>

        <div class="py-3">
            <div v-if="editPhoto.approved === true">
                <b-alert variant="primary" show class="mb-0 rounded-0 border-0">
                    {{ $t("Approved") }}
                </b-alert>
            </div>
            <div v-else-if="editPhoto.approved === false">
                {{ $t("Rejected") }}
                <b-alert
                    show
                    v-if="editPhoto.rejectFeedback"
                    variant="danger"
                    class="rounded-0 border-0"
                >
                    {{ editPhoto.rejectFeedback }}
                </b-alert>
            </div>
            <div v-else>
                {{ $t("Pending") }}
            </div>
        </div>

        <div class="bg-light p-2 mb-2">
            <b-btn
                v-if="!editPhoto.primary && editPhoto.approved"
                @click="setPrimary"
                variant="light"
                class="btn-brand mr-2"
                pill
            >
                {{ $t("Make Primary") }}
            </b-btn>
            <b-btn
                v-if="!editPhoto.primary"
                @click="deletePhoto"
                variant="light"
                class="btn-brand text-danger"
                pill
            >
                {{ $t("Delete") }}
            </b-btn>
        </div>

        <div>
            <div class="small text-secondary">{{ $t("Large") }}</div>
            <img class="img-fluid" :src="editPhoto.large" />
        </div>
        <div class="d-flex mt-3">
            <div class="mr-3">
                <div class="small text-secondary">{{ $t("Search") }}</div>
                <img class="img-fluid" :src="editPhoto.search" />
            </div>
            <div>
                <div class="small text-secondary">{{ $t("Small") }}</div>
                <img class="img-fluid" :src="editPhoto.small" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapState("photo", ["editPhoto"]),
    },
    methods: {
        setPrimary() {
            this.api
                .post("/photo/setPrimary", {
                    id: this.editPhoto.id,
                })
                .then(async () => {
                    await this.$store.dispatch("onboarding/status");
                    this.$store.commit("photo/setEditPhoto");
                });
        },
        deletePhoto() {
            this.api
                .post("/photo/deletePhoto", {
                    id: this.editPhoto.id,
                })
                .then(async () => {
                    await this.$store.dispatch("onboarding/status");
                    this.$store.commit("photo/setEditPhoto");
                });
        },
    },
};
</script>