<template>
    <div>
        <h5 v-if="currentCall.totalEarnings">
            {{ $t("Credits Earned") }}
            <coin-badge :amount="currentCall.totalEarnings" />
        </h5>
        <b-alert
            v-if="!currentCall.dispute && currentCall.escrow"
            variant="primary"
            show
            class="mt-2 small"
        >
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            Credits will be transferred to you in
            <live-timestamp-future
                :timestamp="currentCall.canBeDisputedUntil"
            />
        </b-alert>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("call", ["currentCall"]),
    },
};
</script>