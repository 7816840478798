<template>
    <div>
        <div
            v-if="!hasUnansweredQuestions"
            class="text-primary fake-link"
            @click="$store.commit('questions/setCurrentQuestion', null)"
        >
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            {{ $t("Back to all Questions") }}
        </div>

        <div class="py-3 text-center">
            <fitty :options="{ maxSize: 21 }">
                <template v-slot:content>
                    {{ currentQuestion.questionQuestionFormat }}
                </template>
            </fitty>
        </div>

        <div v-if="currentQuestion.displayFormat === 'multiple'">
            <onboarding-question-edit-multiple />
        </div>
        <div v-else>
            <onboarding-question-edit-single />
        </div>

        <div v-if="!embedded && !currentQuestion.required" class="mt-3">
            <b-btn
                @click="skip"
                block
                variant="link"
                class="text-secondary"
                size="sm"
            >
                {{ $t("I'll answer these later") }}
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import OnboardingQuestionEditSingle from "@/components/onboarding/questions/OnboardingQuestionEditSingle";
import OnboardingQuestionEditMultiple from "@/components/onboarding/questions/OnboardingQuestionEditMultiple";

export default {
    computed: {
        ...mapState("questions", ["currentQuestion", "hasUnansweredQuestions"]),
    },
    methods: {
        skip() {
            this.$store.dispatch("questions/skip");
        },
    },
    components: {
        OnboardingQuestionEditSingle,
        OnboardingQuestionEditMultiple,
    },
    props: ["embedded"],
};
</script>