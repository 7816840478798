<template>
    <div>
        <div v-if="creatorPost.allowPublic">
            <b-dropdown variant="light" toggle-class="rounded-pill">
                <template #button-content>
                    <span v-if="isPrivate">
                        <font-awesome-icon
                            icon="fa-duotone fa-lock"
                            class="text-warning"
                        />
                        {{ $t("Private") }}
                    </span>
                    <span v-else>
                        <font-awesome-icon
                            icon="fa-duotone fa-globe"
                            class="text-primary"
                        />
                        {{ $t("Public") }}
                    </span>
                </template>

                <b-dropdown-header>
                    {{ $t("Post Privacy") }}
                </b-dropdown-header>
                <b-dropdown-item
                    link-class="py-3"
                    :class="{ 'border-primary': !isPrivate }"
                    @click="
                        $store.dispatch('creatorPostEdit/setIsPrivate', false)
                    "
                >
                    <div>
                        <span class="text-primary">
                            <font-awesome-icon icon="fa-duotone fa-globe" />
                        </span>
                        {{ $t("Public") }}
                    </div>
                    <div class="small text-secondary">
                        {{ $t("Visible to everyone") }}
                    </div>
                </b-dropdown-item>
                <b-dropdown-item
                    link-class="py-3"
                    :class="{ 'border-primary': isPrivate }"
                    @click="
                        $store.dispatch('creatorPostEdit/setIsPrivate', true)
                    "
                >
                    <div>
                        <span class="branded">
                            <font-awesome-icon
                                icon="fa-duotone fa-lock"
                                class="text-warning"
                            />
                        </span>
                        {{ $t("Private") }}
                    </div>
                    <div class="small text-secondary">
                        {{ $t("Users pay to view your post") }}
                    </div>
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <div v-else>
            <b-btn pill variant="light" disabled>
                <font-awesome-icon
                    icon="fa-duotone fa-lock"
                    class="text-warning"
                />
                {{ $t("Private") }}
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        postDisabled() {
            if (
                this.publishInProgress ||
                this.updateInProgress ||
                this.createInProgress
            ) {
                return true;
            }

            if (!this.creatorPost) {
                return true;
            }

            return false;
        },
        ...mapState("creatorPostEdit", [
            "creatorPost",
            "isPrivate",
            "publishInProgress",
            "updateInProgress",
            "createInProgress",
        ]),
    },
};
</script>