<template>
    <b-row no-gutters>
        <b-col
            cols="6"
            :key="gif.id"
            v-for="gif in gifs"
            class="d-flex align-items-center my-2 p-2"
        >
            <img
                @click="$emit('selectedGif', gif)"
                :src="gif.images.fixed_width.url"
                class="img-fluid rounded"
            />
        </b-col>
    </b-row>
</template>

<script>
export default {
    props: ["gifs"],
};
</script>