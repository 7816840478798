import $t from "@/service/translate";

const options = [
    $t("Looking good today? Post a photo!"),
    $t("Say something nice..."),
    $t("Add a message..."),
    $t("Send out some good vibes..."),
    $t("Let people know what you're thinking..."),
];

export default () => options[Math.floor(Math.random() * options.length)];