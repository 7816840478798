<template>
    <div>
        <div v-if="creatorLivestream" class="pt-3">
            <div class="agora-dialog">
                <div class="content-video">
                    <div
                        class="remoteVideoContainer bg-light rounded d-flex align-items-center justify-content-center"
                    >
                        <video
                            v-show="isVideoSubed"
                            controls
                            controlslist="nodownload"
                            id="remote-video"
                            ref="remoteVideo"
                        ></video>
                        <div v-if="!isVideoSubed">
                            <font-awesome-icon
                                size="5x"
                                icon="fa-duotone fa-camera-slash"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { createClient } from "agora-rtc-sdk-ng/esm";

export default {
    data() {
        return {
            isVideoSubed: false,
            isAudioSubed: false,
        };
    },
    mounted() {
        this.join(this.creatorLivestream.uuid);
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapGetters("user", ["isLoggedIn"]),
        ...mapState("options", ["agora"]),
        ...mapState("livestream", ["creatorLivestream", "standardMap"]),
    },
    methods: {
        async join(uuid) {
            this.client = await createClient({ mode: "live", codec: "vp8" });

            const uid = await this.client.join(this.agora.appId, uuid, null);

            console.log("unused uid", uid);

            if (this.isLoggedIn) {
                this.$store.dispatch(
                    "pusher/subscribeLivestreamChannel",
                    this.creatorLivestream.id
                );
            }

            this.client.on("user-published", this.onPublished);
            this.client.on("user-unpublished", this.onUnPublished);
        },
        async onPublished(user, mediaType) {
            await this.client.subscribe(user, mediaType);

            if (mediaType === "video") {
                const remoteVideoTrack = user.videoTrack;

                if (remoteVideoTrack) {
                    remoteVideoTrack.play("remote-video");
                    this.isVideoSubed = true;

                    this.$refs.remoteVideo.controls = true;
                }
            }

            if (mediaType === "audio") {
                const remoteAudioTrack = user.audioTrack;

                if (remoteAudioTrack) {
                    remoteAudioTrack.play();
                    this.isAudioSubed = true;
                }
            }
        },
        async onUnPublished(user, mediaType) {
            await this.client.unsubscribe(user, mediaType);

            if (mediaType === "video") {
                this.isVideoSubed = false;
            }
            if (mediaType === "audio") {
                this.isAudioSubed = false;
            }
        },
    },
    async beforeDestroy() {
        this.videoTrack && this.videoTrack.stop();
        this.audioTrack && this.audioTrack.stop();

        if (this.isLoggedIn) {
            this.$store.dispatch(
                "pusher/unsubscribeLivestreamChannel",
                this.creatorLivestream.id
            );
        }

        await this.client.leave();
    },
    beforeUnmount() {
        this.client.off("user-published", this.onPublished);
        this.client.off("user-unpublished", this.onUnPublished);
    },
};
</script>