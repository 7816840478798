import api from "@/service/api";

const actions = {
    restore({ commit, dispatch }, { id, uuid } = {}) {
        api.post(`/userStash/restore`, {
            id, uuid,
        }).then((response) => {
            if (response?.data?.amount) {
                commit('alert/setCurrentAlert', {
                    title: "Credits Restored",
                    message: `${response.data.amount} credits have been restored to your wallet`,
                }, { root: true })

                dispatch('onboarding/status', null, { root: true });
            }
        })
    },
}

export default {
    namespaced: true,
    actions,
}
