<template>
    <div>
        <b-alert
            v-if="interval && !idleExceededDailyLimit"
            variant="success"
            show
        >
            <div class="d-flex align-items-center justify-content-between">
                <div>Idle Pay Enabled</div>
                <b-badge pill variant="light">
                    Earning
                    <font-awesome-icon
                        icon="fa-duotone fa-coin"
                        class="text-warning ml-1"
                        size="lg"
                    />
                    {{ config.creatorLivestreamIdlePayPerMinute }}
                    <span> / </span>minute
                </b-badge>
            </div>
            <div class="small">
                You're earning even without viewers, make sure to stay visible
                in the video stream and be ready to have viewers join
            </div>
        </b-alert>
        <b-alert v-else variant="warning" show class="small">
            Idle pay limit consumed, will reset after 24 hours
        </b-alert>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            timer: 0,
            interval: null,
            idleExceededDailyLimit: false,
        };
    },
    mounted() {
        this.api
            .post(
                `/creatorLivestream/${this.creatorLivestream.id}/eligibleForIdlePay`
            )
            .then((response) => {
                if (response.data.eligibleForIdlePay) {
                    this.startIdlePay();
                } else {
                    this.idleExceededDailyLimit = true;
                }
            });
    },
    computed: {
        ...mapState("options", ["config"]),
        ...mapState("creatorLivestream", ["creatorLivestream"]),
    },
    methods: {
        startIdlePay() {
            this.interval = setInterval(() => {
                this.timer++;

                if (this.timer === 60) {
                    this.timer = 0;

                    this.api
                        .post(
                            `/creatorLivestream/${this.creatorLivestream.id}/idle`
                        )
                        .then(() => {})
                        .catch((error) => {
                            if (
                                error?.response?.data?.code ===
                                "idleExceededDailyLimit"
                            ) {
                                this.idleExceededDailyLimit = true;
                                clearInterval(this.interval);
                            }
                        });
                }
            }, 1000);
        },
    },
    beforeDestroy() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    },
};
</script>