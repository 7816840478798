var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left"},[(_vm.hasUnapprovedPhotoUpdate)?_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('img',{staticClass:"img-fluid rounded float-right",attrs:{"src":_vm.onboardingUser.photoUpdate.userPhoto.small}}),_c('h5',[_vm._v("Pending Review")]),_vm._v(" Please allow up to 48 hours for us to review your photo change ")]):_vm._e(),_c('div',{staticClass:"mb-2 d-flex align-items-center justify-content-between"},[_c('div',[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("Public Photos"))+" ")]),_c('div',{staticClass:"small text-secondary"},[_vm._v(" Visible to users matching your filters ")])]),_c('div',[(_vm.cordova)?_c('b-btn',{staticClass:"btn-brand d-flex align-items-center",attrs:{"variant":"light","pill":""},on:{"click":function($event){return _vm.$store.commit(
                        'interface/setOnboardingPhotoUploadMode',
                        'public'
                    )}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"fa-light fa-plus"}}),_vm._v(" "+_vm._s(_vm.$t("Add"))+" ")],1):_c('universal-uploader',{staticClass:"text-truncate",on:{"uploadSuccess":_vm.uploadSuccessPublic}},[_c('b-btn',{staticClass:"btn-brand d-flex align-items-center",attrs:{"variant":"light","pill":""}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"fa-light fa-plus"}}),_vm._v(" "+_vm._s(_vm.$t("Add"))+" ")],1)],1)],1)]),_c('onboarding-photo-index-grid',{attrs:{"photos":_vm.publicPhotos}}),_c('div',{staticClass:"mt-4 mb-2 d-flex align-items-center justify-content-between"},[_vm._m(0),_c('div',[(_vm.cordova)?_c('b-btn',{staticClass:"btn-brand d-flex align-items-center",attrs:{"variant":"light","pill":""},on:{"click":function($event){return _vm.$store.commit(
                        'interface/setOnboardingPhotoUploadMode',
                        'private'
                    )}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"fa-light fa-plus"}}),_vm._v(" "+_vm._s(_vm.$t("Add"))+" ")],1):_c('universal-uploader',{staticClass:"text-truncate",on:{"uploadSuccess":_vm.uploadSuccessPrivate}},[_c('b-btn',{staticClass:"btn-brand d-flex align-items-center",attrs:{"variant":"light","pill":""}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"fa-light fa-plus"}}),_vm._v(" "+_vm._s(_vm.$t("Add"))+" ")],1)],1)],1)]),_c('onboarding-photo-index-grid',{attrs:{"photos":_vm.privatePhotos}}),(_vm.photosPendingProcessing.length)?_c('div',[_c('div',{staticClass:"mt-4 mb-2"},[_c('div',[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("Photos Processing"))+" ")]),_c('div',{staticClass:"small text-secondary"},[_vm._v(" "+_vm._s(_vm.$t("Photo moderation can take up to 30 seconds"))+" ")])])]),_c('div',_vm._l((_vm.photosPendingProcessing),function(photo){return _c('div',{key:photo.url,staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-2 position-relative"},[_c('img',{staticClass:"opacity-50",attrs:{"height":"100","width":"100","src":photo.url}}),(!photo.error)?_c('div',{staticClass:"absolute-centered"},[_c('b-spinner',{attrs:{"variant":"dark"}})],1):_vm._e()]),_c('div',[(photo.error)?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(photo.error)+" ")]):(photo.uploading)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("Uploading, and running preliminary checks"))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("Moderating"))+" ")]),_c('div',{staticClass:"mt-1"},[_c('b-btn',{staticClass:"btn-brand",attrs:{"variant":"light","size":"sm","pill":""},on:{"click":function($event){return _vm.removePendingPhoto(photo)}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-light fa-times"}}),_vm._v(" "+_vm._s(_vm.$t("Remove"))+" ")],1)],1)])])}),0)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h5',{staticClass:"mb-0"},[_vm._v("Private Photos")])])
}]

export { render, staticRenderFns }