import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
    faArrowDown,
    faArrowUp,
    faBars,
    faTimes,
    faCheck,
    faPlus,
    faHeart as falHeart,
    faStar as falStar,
    faChevronRight,
    faChevronLeft,
    faArrowsMinimize,
    faArrowsMaximize,
    faCheckCircle as falCheckCircle,
    faDash,
    faChevronDoubleDown,
    faArrowRight,
    faArrowLeft,
    faQrcode,
    faEmptySet,
    faArrowsRotate,
    faZap,
    faArrowUpFromSquare,
    faSignalStream,
} from '@fortawesome/pro-light-svg-icons'

import {
    faBan,
    faHeart as fasHeart,
    faStar as fasStar,
    faCheckCircle as fasCheckCircle,
    faCircle,
    faEllipsis,
    faHeartCircleCheck as fasFaHeartCircleCheck,
    faFlag as faFlag,
    faStop,
} from '@fortawesome/pro-solid-svg-icons'

import {
    faFacebookMessenger,
    faLine,
    faViber,
    faTelegram,
    faWhatsapp,
    faSnapchat,
    faFacebook,
    faGoogle,
    faSkype,
    faPaypal,
    faApplePay,
    faApple,
    faTwitter,
    faGooglePay,
    faInstagram,
    faLinkedin,
    faTumblr,
    faQuora,
    faPinterest,
    faReddit,
} from '@fortawesome/free-brands-svg-icons'


import {
    faHeart as fadHeart,
    faBracketsCurly,
    faPencil,
    faMagnifyingGlass,
    faLock,
    faLockOpen,
    faShieldCheck,
    faCircleUp,
    faVideoPlus,
    faImagePolaroidUser,
    faCamcorder,
    faGif,
    faThumbsDown,
    faThumbsUp,
    faTriangleExclamation,
    faInfoCircle,
    faEye,
    faCommentDots,
    faHeartBroken,
    faCheckDouble,
    faCopy,
    faAddressCard,
    faArrowUpRightFromSquare,
    faSparkles,
    faBolt,
    faCog,
    faFire,
    faCamera,
    faPhotoFilm,
    faEnvelope,
    faGem,
    faCircleDollarToSlot,
    faUserSecret,
    faCommentQuestion,
    faMessageLines,
    faCreditCard,
    faVideo,
    faMessageDots,
    faHeartCircleCheck as fadFaHeartCircleCheck,
    faLocationDot,
    faCircleBolt,
    faRss,
    faUser,
    faGlobe,
    faTreasureChest,
    faUserPlus,
    faCircleQuestion,
    faBell,
    faUserMagnifyingGlass,
    faUserCheck,
    faCalendarXmark,
    faLocationPen,
    faFaceLaughSquint,
    faCoin,
    faWallet,
    faClapperboardPlay,
    faRobotAstromech,
    faEyeSlash,
    faHourglass,
    faMoneyCheckDollarPen,
    faPassport,
    faEnvelopeOpenText,
    faGift,
    faChessQueen,
    faDisplayChartUpCircleDollar,
    faRectangleVerticalHistory,
    faMedal,
    faUserGroupCrown,
    faCirclePlay,
    faPartyHorn,
    faTags,
    faGalleryThumbnails,
    faCommentsDollar,
    faTimer,
    faCrown,
    faCalendarAlt,
    faHandHoldingDollar,
    faChartNetwork,
    faShare,
    faCloudArrowUp,
    faFaceAwesome,
    faCirclePhone,
    faCameraSlash,
    faSignalStream as fadSignalStream,
    faMessagesDollar,
    faMicrophone,
    faMicrophoneSlash,
    faClock,
    faStar as fadStar,
    faHome,
    faSlotMachine,
    faSackDollar,
    faCoins,
    faCommentDollar,
    faBullhorn,
    faSliders,
    faPhone,
    faBookOpen,
    faVolume,
    faVolumeSlash,
    faPhoneHangup,
    faUsers,
    faPool8Ball,
} from '@fortawesome/pro-duotone-svg-icons'


library.add(
    faPool8Ball,
    fadStar,
    faStop,
    faBookOpen,
    faVolume,
    faBullhorn,
    faVolumeSlash,
    faEllipsis,
    faUsers,
    faHome,
    faCommentDollar,
    faSlotMachine,
    faSackDollar,
    faMicrophone,
    fadSignalStream,
    faSignalStream,
    faMicrophoneSlash,
    faMedal,
    faSliders,
    faArrowsRotate,
    faTwitter,
    faHourglass,
    faCirclePlay,
    faCloudArrowUp,
    faArrowsMinimize,
    faShare,
    faCameraSlash,
    faMessagesDollar,
    faClock,
    faPhone,
    faPhoneHangup,
    faCalendarAlt,
    faFaceAwesome,
    faChartNetwork,
    faHandHoldingDollar,
    faTimer,
    faPhotoFilm,
    faCommentsDollar,
    faApplePay,
    faGalleryThumbnails,
    faGooglePay,
    faPartyHorn,
    faUserGroupCrown,
    faTags,
    faApple,
    faQuora,
    faPaypal,
    faRectangleVerticalHistory,
    faDisplayChartUpCircleDollar,
    faChessQueen,
    faGift,
    faZap,
    faCirclePhone,
    faEnvelopeOpenText,
    faMoneyCheckDollarPen,
    faEyeSlash,
    faFire,
    faPassport,
    faRobotAstromech,
    faClapperboardPlay,
    faWallet,
    faSkype,
    faCoin,
    faCoins,
    faFaceLaughSquint,
    faLocationPen,
    faTreasureChest,
    faCalendarXmark,
    faUserCheck,
    faUserMagnifyingGlass,
    faBell,
    faCircleQuestion,
    faUserPlus,
    faCircleDollarToSlot,
    faUser,
    faGlobe,
    faRss,
    faCircleBolt,
    faLocationDot,
    faBan,
    fadFaHeartCircleCheck,
    fasFaHeartCircleCheck,
    faMessageDots,
    faVideo,
    faCreditCard,
    faMessageLines,
    faCommentQuestion,
    faUserSecret,
    faGem,
    faGoogle,
    faEnvelope,
    faFacebook,
    faInstagram,
    faPhotoFilm,
    faCog,
    faCamera,
    faBracketsCurly,
    faPencil,
    faArrowDown,
    faArrowUp,
    faRectangleVerticalHistory,
    faMagnifyingGlass,
    faBars,
    faLock,
    faLockOpen,
    fadHeart,
    falHeart,
    fasHeart,
    faTimes,
    faShieldCheck,
    faCheck,
    faCircleUp,
    faVideoPlus,
    faImagePolaroidUser,
    faInstagram,
    faLinkedin,
    faTumblr,
    faPinterest,
    faReddit,
    faCamcorder,
    faGif,
    faPlus,
    faChevronRight,
    faArrowUpFromSquare,
    faCrown,
    faChevronLeft,
    faArrowsMaximize,
    falCheckCircle,
    fasCheckCircle,
    faDash,
    faCircle,
    faThumbsDown,
    faThumbsUp,
    falStar,
    fasStar,
    faEllipsis,
    faTriangleExclamation,
    faInfoCircle,
    faChevronDoubleDown,
    faArrowRight,
    faArrowLeft,
    faEye,
    faCommentDots,
    faCheckDouble,
    faHeartBroken,
    faFacebookMessenger,
    faLine,
    faViber,
    faTelegram,
    faWhatsapp,
    faSnapchat,
    faCopy,
    faAddressCard,
    faArrowUpRightFromSquare,
    faSparkles,
    faEmptySet,
    faQrcode,
    faBolt,
    faFlag
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
