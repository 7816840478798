<template>
    <div>
        <div v-if="!clickedPush" class="my-3 small">
            <font-awesome-icon icon="fa-duotone fa-user-secret" size="lg" />
            Signup with your device instead of email
        </div>

        <b-alert
            v-if="clickedPush"
            show
            variant="primary"
            class="rounded-0 px-0"
        >
            <h3 class="mb-0">Click "Allow" to proceed</h3>
        </b-alert>

        <b-alert v-if="showIos" show variant="info" class="text-left">
            <h5>iOS - Extra Step</h5>
            <div class="mt-2">
                If you press Enable, but nothing happens, there is a required
                extra step...
            </div>
            <div class="mt-2">
                Please tap Share
                <font-awesome-icon icon="fa-light fa-arrow-up-from-square" />
                then tap "Add to home screen"
            </div>
            <div class="mt-2">
                Once you've added to home screen, refresh and you will be able
                to enable notifications. iOS 16.4+ required
            </div>
        </b-alert>

        <div class="mt-3">
            <b-btn
                :disabled="!onesignalInitialized"
                @click="push"
                variant="primary"
                block
                size="lg"
                pill
            >
                <div v-if="onesignalInitialized">
                    {{ $t("Signup Anonymously") }}
                </div>
                <div v-else>
                    <b-spinner />
                </div>
            </b-btn>
        </div>
        <div class="mt-3">
            <b-btn @click="removeAnonymous" variant="light" pill>
                Login with Email
            </b-btn>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            clickedPush: false,
            showIos: false,
            onesignalInitialized: false,
            ios:
                [
                    "iPad Simulator",
                    "iPhone Simulator",
                    "iPod Simulator",
                    "iPad",
                    "iPhone",
                    "iPod",
                ].includes(navigator.platform) ||
                // iPad on iOS 13 detection
                (navigator.userAgent.includes("Mac") &&
                    "ontouchend" in document),
        };
    },
    mounted() {
        this.$OneSignal.on("subscriptionChange", this.subscriptionChange);

        this.getToken();
        this.initializeOnesignal();
    },
    beforeDestroy() {
        this.$OneSignal.off("subscriptionChange", this.subscriptionChange);
    },
    computed: {
        ...mapState("onboarding", ["user"]),
        ...mapState("options", ["onesignal"]),
    },
    methods: {
        subscriptionChange(isSubscribed) {
            if (isSubscribed) {
                this.getToken();
            }
        },
        confirmSkip() {
            const confirmation = confirm(
                this.$t("Are you sure? You may miss messages from other users.")
            );

            if (confirmation) {
                this.skip();
                this.$store.dispatch(
                    "appEvent/create",
                    "onboarding_push_skipped"
                );
            } else {
                this.$store.dispatch(
                    "appEvent/create",
                    "onboarding_push_prevent_skip_success"
                );
            }
        },
        getToken() {
            this.$OneSignal.getUserId((token) => {
                if (token) {
                    this.api
                        .post("/onesignal/saveUserToken", {
                            token,
                        })
                        .then(() => {
                            this.$store.dispatch("onboarding/status");
                        });
                }
            });
        },
        removeAnonymous() {
            this.api.post("/signup/removeAnonymous").then(() => {
                this.$store.dispatch("onboarding/status");
            });
        },
        initializeOnesignal() {
            this.$OneSignal
                .init({
                    appId: this.onesignal.appId,
                    safari_web_id: this.onesignal.safariWebId,
                    notifyButton: {
                        enable: false,
                    },
                })
                .then(() => {
                    this.onesignalInitialized = true;

                    this.$OneSignal.isPushNotificationsEnabled((isEnabled) => {
                        if (isEnabled) {
                            this.getToken();
                        }
                    });
                });
        },
        push() {
            this.clickedPush = true;

            if (!this.onesignalInitialized) {
                console.error("not initialized yet");
                return;
            }

            // show the info dialog AFTER they click
            // too much info until they click
            // they will wonder why, this will clear it up
            if (this.ios) {
                this.showIos = true;
            }

            this.$OneSignal.showSlidedownPrompt();
        },
    },
};
</script>
