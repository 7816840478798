<template>
    <div>
        <div v-if="prices">
            <div>
                {{ $t("Select a membership option to continue") }}
            </div>
            <table class="table table-lg table-striped border mt-2">
                <thead>
                    <th class="text-left">
                        {{ $t("Length") }}
                    </th>
                    <th class="text-center align-middle">
                        {{ $t("Price") }}
                    </th>
                </thead>
                <tbody>
                    <tr :key="price.stripePriceId" v-for="price in prices">
                        <td class="text-left">
                            <div>
                                <h5 class="mb-0">
                                    {{ price.name }}
                                </h5>
                                <div class="text-success small">
                                    {{ price.description }}
                                </div>
                            </div>
                        </td>
                        <td class="text-center align-middle">
                            <b-btn
                                @click="selectPackage(price)"
                                pill
                                block
                                variant="primary"
                            >
                                Pay ${{ price.price }}
                            </b-btn>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            <div class="text-center py-3">
                <b-spinner variant="secondary" />

                <div class="mt-3">
                    {{ $t("Loading packages, just a moment...") }}
                </div>

                <b-btn
                    @click="getPrices"
                    variant="link"
                    size="sm"
                    class="p-0 mt-2 text-secondary"
                >
                    {{ $t("Refresh Prices") }}
                </b-btn>
            </div>
        </div>

        <div class="alert alert-primary border-0 rounded-0 text-left">
            <h5 class="">
                {{ $t("Simple &amp; Transparent Billing") }}
            </h5>
            <div class="mt-1">
                <font-awesome-icon class="mr-1" icon="fa-light fa-check" />
                {{ $t("One-time payment, no subscription") }}
            </div>
            <div class="mt-1">
                <font-awesome-icon class="mr-1" icon="fa-light fa-check" />
                {{ $t("No hidden fees or recurring charges") }}
            </div>
            <div class="mt-1">
                <font-awesome-icon class="mr-1" icon="fa-light fa-check" />
                {{ $t("Unlimited messaging") }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            prices: null,
        };
    },
    mounted() {
        this.getPrices();
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        closeWallet() {
            this.$store.commit("interface/setUserWalletModalVisible", false);
        },
        selectPackage(price) {
            this.$store.dispatch("system/openBrowser", price.checkoutUrl);
        },
        getPrices() {
            this.api.post("/payment/membershipPrices").then((response) => {
                if (response?.data?.prices) {
                    this.prices = response.data.prices;
                }
            });
        },
        backupPayments() {},
    },
};
</script>

