<template>
    <div class="p-3 mt-3">
        <div class="d-flex align-items-center justify-content-center">
            <b-btn
                @click="$store.dispatch('user/block', profile.id)"
                variant="light"
                pill
                class="mx-2"
            >
                <font-awesome-icon icon="fa-solid fa-ban" />
                {{ $t("Block User") }}
            </b-btn>
            <b-btn
                @click="$store.dispatch('report/user', profile)"
                variant="light"
                pill
                class="mx-2"
            >
                <font-awesome-icon icon="fa-solid fa-flag" />
                {{ $t("Report User") }}
            </b-btn>
        </div>
        <div class="mt-3 text-center">
            <b-btn @click="confirmBlockCountry" variant="light" pill>
                {{ $t("Block All Users in ") }}
                {{ profile.country }}
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    methods: {
        confirmBlockCountry() {
            if (
                confirm(
                    this.$t(
                        "Are you sure you want to block this country? This will hide all users from this country"
                    )
                )
            ) {
                this.blockCountry();
            }
        },
        blockCountry() {
            this.api
                .post("/block/country", {
                    countryId: this.profile.countryId,
                })
                .then(() => {
                    this.modal = false;

                    this.$nextTick(() => {
                        this.$store.dispatch("profile/close");
                        this.$store.dispatch("onboarding/status");
                        this.$store.dispatch("search/refresh");
                    });
                });
        },
    },
    computed: {
        ...mapState("profile", ["profile"]),
    },
};
</script>