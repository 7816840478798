<template>
    <generic-page>
        <div class="d-flex align-items-center justify-content-between mt-3">
            <h5 class="mb-0">Live Users</h5>
            <b-badge variant="danger" v-if="liveUserIds.length" pill>
                {{ liveUserIds.length }}
            </b-badge>
        </div>

        <div class="py-2">
            <div v-if="liveUserIds.length">
                <div :key="userId" v-for="userId in liveUserIds" class="mb-4">
                    <livestream-preview :userId="userId" />
                </div>
            </div>
            <div v-else class="text-center">
                <div class="my-5">
                    <b-spinner small type="grow" variant="danger" />
                    Waiting for new streams...
                </div>
            </div>
        </div>

        <div v-if="users?.length">
            <h5>Recently Live</h5>
            <div
                @click="viewProfile(user.standardMap)"
                :key="user.standardMap.id"
                v-for="user in users"
            >
                <user-standard-avatar
                    :standardMap="user.standardMap"
                    :nonPrimaryPhotos="false"
                    :hideBadges="true"
                    :userBetweenStatus="false"
                    class="border-light border-top"
                    size="small"
                >
                    {{ user.currentCreatorLivestream.createdRelative }}
                </user-standard-avatar>
            </div>
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import LivestreamPreview from "@/components/livestream/LivestreamPreview";
import UserStandardAvatar from "@/components/user/UserStandardAvatar";

export default {
    data() {
        return {
            users: null,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapState("pusher", ["liveUserIds"]),
    },
    methods: {
        viewProfile(standardMap) {
            this.$store.dispatch("profile/viewProfile", {
                userId: standardMap.id,
                profile: standardMap,
            });
        },
        refresh() {
            this.api.post("/livestream/history").then((response) => {
                this.users = response?.data?.users;
            });
        },
    },
    components: {
        LivestreamPreview,
        UserStandardAvatar,
    },
};
</script>