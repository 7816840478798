<template>
    <div>
        <div class="mt-3 text-center">
            <b-btn
                variant="primary"
                @click="startEarning"
                pill
                size="lg"
                class="py-3"
                block
            >
                {{ $t("Creator Dashboard") }}
            </b-btn>

            <b-btn variant="light" @click="closeOffer" pill class="mt-3">
                {{ $t("Maybe Later") }}
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        isPhilippines() {
            return this.onboardingUser.countryId === 1;
        },
        currentOffer() {
            return this.$store.state.displayOffer?.currentOffer;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        startEarning() {
            this.$router.push({ name: "creatorDashboard" });
            this.closeOffer();
        },
        closeOffer() {
            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
    },
    components: {},
};
</script>