
<template>
    <div>
        <b-badge
            variant="light"
            v-b-tooltip.hover
            class="border text-primary"
            pill
            v-if="isFreeTime"
            :title="$t(`The first ${freeSeconds} seconds are free`)"
        >
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            Free Time
            {{ countdown }}
        </b-badge>
        <coin-badge
            v-else-if="currentCall.totalEarnings"
            :amount="currentCall.totalEarnings"
            :label="onboardingUser.creator ? $t('Earnings') : $t('Charges')"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";

import { FREE_TIME_SECONDS } from "@/constant/call";

export default {
    data() {
        return {
            isFreeTime: true,
            timer: 0,
            billingInterval: null,
            startBillingTimeout: null,
            countdownInterval: null,
            freeSeconds: FREE_TIME_SECONDS,
            countdown: FREE_TIME_SECONDS,
        };
    },
    mounted() {
        if (this.disableFreeTime) {
            this.startBilling();
        } else {
            this.startBillingTimeout = setTimeout(() => {
                this.startBilling();
            }, this.freeSeconds * 1000);

            this.countdownInterval = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                } else {
                    clearInterval(this.countdownInterval);
                }
            }, 1000);
        }
    },
    computed: {
        ...mapState("call", ["currentCall", "disableFreeTime"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        startBilling() {
            this.isFreeTime = false;

            if (!this.onboardingUser.creator) {
                // initial charge for first minute
                this.$store.dispatch("call/charge");

                this.billingInterval = setInterval(() => {
                    this.timer++;

                    if (this.timer === 60) {
                        this.timer = 0;
                        this.$store.dispatch("call/charge");
                    }
                }, 1000);
            }
        },
        startCountdown() {},
    },
    beforeDestroy() {
        if (this.startBillingTimeout) {
            clearTimeout(this.startBillingTimeout);
        }

        if (this.billingInterval) {
            clearInterval(this.billingInterval);
        }
    },
};
</script>




