<template>
    <div>
        <div class="p-2 mt-2">
            <creator-invite />
        </div>
    </div>
</template>

<script>
import CreatorInvite from "@/components/creator/invite/CreatorInvite";

export default {
    components: {
        CreatorInvite,
    },
};
</script>