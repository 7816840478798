
<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <div class="lead mb-0">
                {{ $t("Amount to send") }}
            </div>
            <div>
                <wallet-balance />
            </div>
        </div>

        <div class="mt-3">
            <div v-if="sendCustomAmount">
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        {{ $t("Send custom amount") }}
                    </div>
                    <b-btn
                        variant="light"
                        class="text-danger"
                        @click="sendCustomAmount = false"
                        pill
                    >
                        {{ $t("Cancel") }}
                    </b-btn>
                </div>

                <b-form-input
                    class="mt-1"
                    size="lg"
                    v-model="customAmount"
                    :placeholder="$t('Enter amount')"
                />

                <div class="small text-primary">
                    {{ $t("Send any amount, up to 1000 credits") }}
                </div>

                <b-btn
                    @click="convertAmount"
                    size="lg"
                    variant="primary"
                    class="mt-3"
                    pill
                    block
                >
                    {{ $t("Preview Send") }}
                </b-btn>
            </div>
            <div v-else>
                <b-btn
                    :key="sendAmountOption.amount"
                    v-for="sendAmountOption in sendAmountOptions"
                    @click="setSendAmount(sendAmountOption)"
                    pill
                    variant="light"
                    block
                >
                    <h5 class="mb-0">
                        <font-awesome-icon
                            icon="fa-duotone fa-coin"
                            class="text-warning"
                            size="lg"
                        />
                        {{ sendAmountOption.amount }}
                        {{ $t("credits") }}
                    </h5>
                    <div class="text-secondary small">
                        {{ $t("They receive") }}
                        {{ sendAmountOption.local }}
                    </div>
                </b-btn>

                <div class="mt-2">
                    <b-btn
                        @click="sendCustomAmount = true"
                        pill
                        variant="light"
                        size="lg"
                        block
                    >
                        {{ $t("Send custom amount") }}
                    </b-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import WalletBalance from "@/components/utility/WalletBalance";
import { mapState } from "vuex";

export default {
    data() {
        return {
            sendCustomAmount: false,
            customAmount: null,
        };
    },
    computed: {
        ...mapState("sendCredits", ["sendAmountOptions"]),
    },
    methods: {
        convertAmount() {
            this.$store.dispatch(
                "sendCredits/convertAmount",
                this.customAmount
            );
        },
        setSendAmount(option) {
            this.$store.commit("sendCredits/setSendAmount", option);
        },
    },
    components: {
        WalletBalance,
    },
};
</script>