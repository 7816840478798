import api from "@/service/api";

const state = () => ({
    trendingGifs: null,
})

const mutations = {
    setTrendingGifs(state, value) {
        state.trendingGifs = value
    },
}

const actions = {
    loadTrendingOnce({ state, commit }) {

        if (state.trending?.length) {
            return;
        }

        api.post('/giphy/trending')
            .then(response => {
                if (response?.data?.gifs) {
                    commit('setTrendingGifs', response.data.gifs)
                }
            })
    },
}


const getters = {
    appReadyToStart(state) {
        if (state.cordova) {
            return state.deviceReady ? true : false
        } else {
            return true;
        }
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
