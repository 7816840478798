<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <h5>
                {{ $t("Edit Template") }}
            </h5>
            <div>
                <div v-if="confirmDelete">
                    {{ $t("Are you sure?") }}
                    <b-btn
                        @click="$store.commit('conversationTemplate/reset')"
                        variant="light"
                        class="text-secondary ml-1"
                        small
                    >
                        {{ $t("Cancel") }}
                    </b-btn>
                    <b-btn
                        class="ml-1"
                        @click="deleteTemplate"
                        variant="danger"
                        small
                    >
                        {{ $t("Delete") }}
                    </b-btn>
                </div>
                <div v-else>
                    <b-btn
                        @click="$store.commit('conversationTemplate/reset')"
                        variant="light"
                        class="text-secondary"
                        small
                    >
                        {{ $t("Cancel") }}
                    </b-btn>
                    <b-btn
                        @click="confirmDelete = true"
                        variant="light"
                        class="text-danger"
                        small
                    >
                        {{ $t("Delete") }}
                    </b-btn>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <label>
                {{ $t("Template name (for your reference)") }}
                <span class="text-danger"> * </span>
            </label>
            <b-input
                maxlength="100"
                :placeholder="$t('Name your template for your reference')"
                v-model="name"
                autocorrect="off"
                autocapitalize="none"
                autocomplete="off"
            />
        </div>

        <div class="mt-3">
            <div class="d-flex align-items-center justify-content-between">
                <label>
                    {{ $t("Message content") }}
                    <span class="text-danger"> * </span>
                </label>
                <div>
                    <b-btn
                        @click="showExample"
                        variant="link"
                        class="p-0"
                        size="sm"
                    >
                        {{ $t("Show Example") }}
                    </b-btn>
                </div>
            </div>
            <b-textarea
                ref="body"
                rows="6"
                maxlength="1000"
                :placeholder="messageContentPlaceholder"
                v-model="body"
                autocorrect="off"
                autocapitalize="none"
                autocomplete="off"
            />
        </div>

        <div class="mt-3">
            <div>
                {{ $t("Optional Variables") }}
                <span class="text-secondary small">
                    {{ $t("(press to insert at cursor)") }}
                </span>
            </div>
            <div class="mt-2">
                <b-btn
                    variant="light"
                    pill
                    size="lg"
                    class="btn-brand mr-2"
                    @click="addAge"
                >
                    {{ $t("Age") }}
                </b-btn>
                <b-btn
                    variant="light"
                    pill
                    size="lg"
                    class="btn-brand mr-2"
                    @click="addCity"
                >
                    {{ $t("City") }}
                </b-btn>
                <b-btn
                    pill
                    size="lg"
                    variant="light"
                    class="btn-brand mr-2"
                    @click="addCountry"
                >
                    {{ $t("Country") }}
                </b-btn>
                <b-btn
                    pill
                    size="lg"
                    variant="light"
                    class="btn-brand mr-2"
                    @click="addName"
                >
                    {{ $t("Name") }}
                </b-btn>
            </div>
            <div class="small text-secondary mt-2">
                {{
                    $t(
                        "When you use the template, these variables will be replaced with the user's values"
                    )
                }}
            </div>
        </div>
        <div class="mt-3">
            <b-btn
                :disabled="!complete || saving"
                @click="save"
                variant="primary"
                block
                size="lg"
            >
                {{ $t("Save") }}
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import insertTextAtCursor from "insert-text-at-cursor";

export default {
    data() {
        return {
            messageContentPlaceholder: this.$t(
                "This is the message we'll send the other user, you'll always be able to preview the final message before sending"
            ),
            confirmed: false,
            confirmDelete: false,
            name: null,
            body: null,
            saving: null,
        };
    },
    mounted() {
        this.name = this.editTemplate.name;
        this.body = this.editTemplate.body;
    },
    computed: {
        complete() {
            if (this.name && this.body) {
                return true;
            }

            return false;
        },
        ...mapState("conversationTemplate", ["editTemplate"]),
        ...mapState("profile", ["profile"]),
    },
    methods: {
        showExample() {
            if (!this.body) {
                this.body =
                    "Hey {name}, nice to meet you. I am interested to meet people from {country}.";
            }
        },
        save() {
            this.saving = true;

            this.api
                .post("/template/save", {
                    id: this.editTemplate.id,
                    name: this.name,
                    body: this.body,
                })
                .then(() => {
                    this.$store
                        .dispatch("conversationTemplate/refresh")
                        .then(() => {
                            this.$store.commit(
                                "conversationTemplate/setEditTemplate",
                                null
                            );
                        });
                });
        },
        deleteTemplate() {
            this.saving = true;

            this.api
                .post("/template/delete", {
                    id: this.editTemplate.id,
                })
                .then(() => {
                    this.$store
                        .dispatch("conversationTemplate/refresh")
                        .then(() => {
                            this.$store.commit(
                                "conversationTemplate/setEditTemplate",
                                null
                            );
                        });
                });
        },
        addAge() {
            this.appendVariable("{age}");
        },
        addCity() {
            this.appendVariable("{city}");
        },
        addCountry() {
            this.appendVariable("{country}");
        },
        addName() {
            this.appendVariable("{name}");
        },
        appendVariable(variable) {
            insertTextAtCursor(this.$refs.body, variable);
            this.$refs.body.focus();
        },
        addLocation() {},
    },
};
</script>