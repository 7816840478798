/* global FirebasePlugin */
import Fingerprint2 from "fingerprintjs2"
import api from "@/service/api";
import { getRandomString } from "@/utility/random";
import router from "@/router";

import {
    FINDMATE_AUTH_TOKEN,
    FINDMATE_TRACKER_ID,
    ATTRIBUTION_LANDING,
    ATTRIBUTION_REFERER,
    ATTRIBUTION_SOURCE,
    ATTRIBUTION_INVITE,
    ATTRIBUTION_CLICK,
} from "@/constant/localStorage";

const state = () => ({
    blockedUserIds: [],
    authToken: null,
    user: null,
    refreshingLocation: false,
    premium: false,
    hasActiveSubscription: false,
    geolocationFailed: false,
    finishedOneTimeInitialization: false,
})

const mutations = {
    hasAuthToken(state, authToken) {
        state.authToken = authToken;
        api.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
        localStorage.setItem(FINDMATE_AUTH_TOKEN, authToken);
    },
    removeAuthToken(state) {
        state.authToken = null;
        api.defaults.headers.common['Authorization'] = null;
        localStorage.removeItem(FINDMATE_AUTH_TOKEN);
    },
    enablePremium(state) {
        state.premium = true;
    },
    setUser(state, user) {
        state.user = user;
    },
    blockUserId(state, userId) {
        state.blockedUserIds.push(userId);
    },
    unblockUserId(state, userId) {
        state.blockedUserIds = state.blockedUserIds.filter(blockedUserId => blockedUserId !== userId);
    },
    setRefreshingLocation(state, value) {
        state.refreshingLocation = value
    },
    setGeolocationFailed(state, value) {
        state.geolocationFailed = value
    },
    setFinishedOneTimeInitialization(state, value) {
        state.finishedOneTimeInitialization = value
    },
    enableHasActiveSubscription(state) {
        state.hasActiveSubscription = true
        state.premium = true;
    },
}

const actions = {
    async login({ commit, dispatch }, authToken) {
        commit('hasAuthToken', authToken)

        await dispatch('options/refresh', null, { root: true })
            .catch(error => {
                if (error?.response?.status === 401) {
                    commit('removeAuthToken')
                }
            });

        dispatch('onboarding/status', null, { root: true }).then(() => {
            // the second this resolves, it will call /me and load all that
            // give those requests some time to finish first
            requestIdleCallback(() => dispatch('pusher/initialize', null, { root: true }))

            dispatch('message/refresh', null, { root: true })
            requestIdleCallback(() => dispatch('refreshLocation'))
            requestIdleCallback(() => dispatch('device'))

            setTimeout(() => {
                requestIdleCallback(() => {
                    try {
                        api.post('/locale/save', {
                            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                            language: navigator.language,
                            languages: navigator.languages
                        })
                    } catch (e) { console.error(e) }
                })
                requestIdleCallback(() => {
                    if (process.env.VUE_APP_GIT_HASH) {
                        api.post('/release/save', {
                            release: process.env.VUE_APP_GIT_HASH,
                        })
                    }
                })

                requestIdleCallback(() => {
                    Fingerprint2.get((components) => {
                        api.post('/fingerprint/save', {
                            payload: JSON.stringify(components)
                        })
                    })
                })
            }, 3000)
        })
    },
    async logout({ rootState, commit }) {
        if (rootState.system.cordova) {
            // restart app
            // need the thing from 
            // this is going to mess with IAP/cordova plugin if anyone else logsout
            await new Promise(resolve => {
                try {
                    FirebasePlugin.signOutUser(function () {
                    }, function (error) {
                        console.error("Failed to sign out user: " + error);
                    });

                    FirebasePlugin.setAutoInitEnabled(false, function () {
                        FirebasePlugin.unregister();
                        resolve()
                    });
                } catch (e) {
                    console.error(e)
                }

                // failsafe
                setTimeout(() => { resolve() }, 3000)
            })
        }

        commit('onboarding/removeUser', null, { root: true })
        commit('removeAuthToken')

        router.push('/')
    },
    refreshFilteredContent({ commit, dispatch }) {
        commit('search/reset', null, { root: true });
        dispatch('search/refresh', null, { root: true });
        dispatch('swipe/refresh', null, { root: true });
    },
    async initialize({ commit, dispatch, rootState }) {
        if (rootState?.onboarding?.user?.hasActiveSubscription) {
            commit('enableHasActiveSubscription')
        }

        dispatch('oneTimeUserSetup')
    },
    oneTimeUserSetup({ dispatch, state, commit, rootState }) {
        if (state.finishedOneTimeInitialization) {
            return;
        }

        // options/refresh already called to determine if user is logged in
        // i think before it used a different endpoint to determine that
        // but also i think logic depends on this being here
        // so kind of clunky, but this flag prevents double initial load
        // might be able to remove this in the future if checked carefully
        if (!rootState.options.completedInitialLoad) {
            dispatch('options/refresh', null, { root: true });
        }

        dispatch('system/dispatchPostLaunchActions', null, { root: true });
        dispatch('interest/refresh', null, { root: true });
        dispatch('visit/refresh', null, { root: true });

        if (rootState.system.cordova) {
            dispatch('firebase/initialize', null, { root: true });
        }

        commit('setFinishedOneTimeInitialization', true)
    },
    block({ commit, dispatch }, userId) {
        commit("blockUserId", userId);
        api.post("/block/user", { userId });
        dispatch("profile/close", null, { root: true });
    },
    unblock({ commit }, userId) {
        commit("unblockUserId", userId);
        api.post("/block/unblockUser", { userId });
    },
    device({ rootState, commit }) {
        if (rootState.system.cordova) {
            /* global device */
            api.post('/device/save', {
                cordova: device.cordova,
                model: device.model,
                platform: device.platform,
                uuid: device.uuid,
                version: device.version,
                manufacturer: device.manufacturer,
                isVirtual: device.isVirtual,
                serial: device.serial
            })
        } else {
            api.post('/browser/save')
        }

        if (!localStorage.getItem(FINDMATE_TRACKER_ID)) {
            localStorage.setItem(FINDMATE_TRACKER_ID, getRandomString(32))
        }

        api.post('/tracker/save', { id: localStorage.getItem(FINDMATE_TRACKER_ID) })

        if (
            localStorage.getItem(ATTRIBUTION_LANDING)
            || localStorage.getItem(ATTRIBUTION_REFERER)
            || localStorage.getItem(ATTRIBUTION_SOURCE)
            || localStorage.getItem(ATTRIBUTION_INVITE)
            || localStorage.getItem(ATTRIBUTION_CLICK)
        ) {
            api.post('/attribution/save', {
                landing: localStorage.getItem(ATTRIBUTION_LANDING),
                referer: localStorage.getItem(ATTRIBUTION_REFERER),
                source: localStorage.getItem(ATTRIBUTION_SOURCE),
                invite: localStorage.getItem(ATTRIBUTION_INVITE),
                click: localStorage.getItem(ATTRIBUTION_CLICK),
            }).then(() => {
                localStorage.removeItem(ATTRIBUTION_LANDING)
                localStorage.removeItem(ATTRIBUTION_REFERER)
                localStorage.removeItem(ATTRIBUTION_SOURCE)
                localStorage.removeItem(ATTRIBUTION_INVITE)
                localStorage.removeItem(ATTRIBUTION_CLICK)
            })
        }

        if (rootState.attribution.expatMode) {
            api.post('/userFlag/expatMode').then(() => {
                commit('attribution/setExpatMode', false, { root: true })
            })
        }
    },
    refreshLocation({ dispatch, rootState, commit }, { force, refresh } = {}) {
        if (!force && rootState.onboarding.hasIncompleteSteps) {
            return
        }

        commit('setRefreshingLocation', true)

        const geolocationFailed = () => {
            commit('setGeolocationFailed', true)
            commit('setRefreshingLocation', false)
        }

        try {
            if ('geolocation' in navigator && navigator.geolocation) {
                const options = {
                    timeout: 30000
                }

                navigator.geolocation.getCurrentPosition((position) => {
                    api
                        .post('/gps/save', {
                            lat: position.coords.latitude,
                            lon: position.coords.longitude
                        })
                        .then(() => {
                            if (refresh) {
                                dispatch("onboarding/status", null, { root: true });
                            }
                        })
                        .catch(() => { })
                        .then(() => {
                            commit('setRefreshingLocation', false)
                        })
                }, (error) => {
                    geolocationFailed()

                    if (error?.code) {
                        if (error.code === 1) {
                            dispatch('appEvent/create', 'geolocation_get_failure_permission_denied', { root: true })
                        }
                        if (error.code === 2) {
                            dispatch('appEvent/create', 'geolocation_get_failure_unavailable', { root: true })
                        }
                        if (error.code === 3) {
                            dispatch('appEvent/create', 'geolocation_get_failure_timeout', { root: true })
                        }
                    } else {
                        dispatch('appEvent/create', 'geolocation_get_failure_unknown_reason', { root: true })
                    }
                }, options);
            } else {
                dispatch('appEvent/create', 'geolocation_get_failure_missing_geolocation_prop', { root: true })
                geolocationFailed()
            }
        } catch (e) {
            dispatch('appEvent/create', 'geolocation_get_failure_2', { root: true })
            geolocationFailed()
        }
    },
}

const getters = {
    isLoggedIn(state, getters, rootState) {
        return rootState.onboarding.user ? true : false
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
