<template>
    <div id="notification-center" class="position-fixed fixed-top">
        <transition-group name="fade" mode="out-in">
            <div
                :key="notification.instanceId"
                v-for="notification in notifications"
                class="
                    notification
                    d-flex
                    align-items-center
                    justify-content-between
                    glass
                    shadow
                "
            >
                <div
                    class="d-flex align-items-center flex-grow-1 fake-link"
                    :class="notification.class"
                    @click="handleClick(notification)"
                >
                    <div v-if="notification.photo">
                        <img
                            height="60"
                            width="60"
                            class="img-fluid"
                            :src="notification.photo"
                        />
                    </div>
                    <div class="mr-2"></div>
                    <div>
                        <div class="small">{{ notification.title }}</div>
                        <div>{{ notification.message }}</div>
                    </div>
                </div>
                <div>
                    <b-btn
                        variant="light"
                        @click="
                            $store.commit(
                                'notification/close',
                                notification.instanceId
                            )
                        "
                        class="action-button mr-1"
                    >
                        <font-awesome-icon size="lg" icon="fa-light fa-times" />
                    </b-btn>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<style lang="scss" scoped>
.notification {
    height: 60px;
}
</style>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("notification", ["notifications"]),
    },
    methods: {
        handleClick(notification) {
            this.$store.commit("notification/close", notification.instanceId);

            if (notification.click) {
                notification.click();
            }
        },
    },
};
</script>
