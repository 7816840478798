<template>
    <div :class="`size-${size}`">
        <img
            :class="{ blur: !canViewPost(creatorPost) }"
            class="img-fluid"
            :src="src"
            loading="lazy"
        />
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        src() {
            if (this.size === "large") {
                return this.userPhoto.largeUrl;
            }

            if (this.size === "square") {
                return this.userPhoto.squareUrl;
            }

            return this.userPhoto.thumbUrl;
        },
        iconSize() {
            if (this.size === "large") {
                return "5x";
            }

            if (this.size === "square") {
                return "5x";
            }

            return "2x";
        },
        ...mapState("user", ["premium"]),
        ...mapGetters("userSubscription", ["subscribedToCreatorUserId"]),
        ...mapGetters("userSubscription", ["canViewPost"]),
    },
    props: ["creatorPost", "userPhoto", "size"],
};
</script>