<template>
    <div>
        <b-alert
            v-if="subscribedToCreatorUserId(profile.id)"
            variant="primary"
            show
            class="p-3 rounded-0 border-0 mb-0"
        >
            <b-btn @click="manageSubscription" variant="light" pill size="sm">
                <font-awesome-icon icon="fa-duotone fa-pencil" />
                {{ $t("Manage Subscription") }}
            </b-btn>
        </b-alert>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        offerSubscribe() {
            return (
                this.showUncensoredCreatorActions &&
                !this.subscribedToCreatorUserId(this.profile.id)
            );
        },
        ...mapState("profile", ["profile"]),
        ...mapGetters("profile", ["showUncensoredCreatorActions"]),
        ...mapGetters("userSubscription", [
            "subscribedToCreatorUserId",
            "isLegacySubscription",
        ]),
    },
    methods: {
        manageSubscription() {
            if (this.isLegacySubscription(this.profile.id)) {
                this.$store.dispatch("setting/editSetting", "subscriptions");
            } else {
                this.$store.dispatch("userSubscription/get", this.profile.id);
            }
        },
        subscribeCreator() {
            this.$store.dispatch("userSubscription/fromProfile", this.profile);
        },
    },
};
</script>