const state = () => ({
    editVideo: null,
    videosPendingProcessing: [],
})

const mutations = {
    setEditVideo(state, video) {
        state.editVideo = video
    },
    appendProcessingVideo(state, video) {
        state.videosPendingProcessing.push(video)
    },
    removePendingVideo(state, instanceId) {
        state.videosPendingProcessing = state.videosPendingProcessing.filter(video => video.instanceId !== instanceId)
    },
}

const actions = {
    appendProcessingVideo({ commit }, video) {
        commit('appendProcessingVideo', video)
    },
}

const getters = {
    hasAnyVideos(state, getters, rootState) {
        return rootState?.onboarding?.user?.videos?.length || state?.videosPendingProcessing?.length ? true : false
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
