<template>
    <div>
        <div v-if="creatorRequest.refunded">Refunded</div>
        <div v-else-if="creatorRequest.dispute">
            <div class="text-warning">
                <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
                Dispute
            </div>
        </div>
        <div v-else-if="creatorRequest.escrow">
            <div v-if="creatorRequest.fulfilledAt">
                <font-awesome-icon icon="fa-light fa-check" />
                Submitted
            </div>
            <div v-else>
                <font-awesome-icon icon="fa-duotone fa-hourglass" />
                Pending
            </div>
        </div>
        <div v-else>
            <div class="text-primary">
                <font-awesome-icon icon="fa-light fa-check-circle" />
                Completed
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["creatorRequest"],
};
</script>