<template>
    <div v-if="hasIncompleteGuides">
        <div>
            <creator-guide-view />
        </div>
        <b-list-group>
            <b-list-group-item variant="primary">
                <div>
                    <h5
                        class="d-flex align-items-center justify-content-between"
                    >
                        New User Guide
                        <b-badge variant="danger" pill>
                            {{ incompleteGuides.length }}
                        </b-badge>
                    </h5>
                </div>
                <div class="small">
                    Complete the guides below at your own pace to setup your
                    account to start earning money
                </div>
            </b-list-group-item>
            <b-list-group-item
                class="d-flex justify-content-between align-items-center fake-link"
                :key="guide.key"
                v-for="guide in incompleteGuides"
                action
                @click="setCurrentGuide(guide)"
            >
                <div>
                    <div class="text-dark">
                        {{ guide.title }}
                    </div>
                    <div class="small text-secondary">
                        {{ guide.description }}
                    </div>
                </div>
                <div>
                    <b-badge pill variant="light" class="mr-1">
                        <font-awesome-icon icon="fa-duotone fa-timer" />
                        {{ guide.duration }}
                        min
                    </b-badge>
                </div>
            </b-list-group-item>
        </b-list-group>
    </div>
</template>

<script>
import CreatorGuideView from "@/components/creator/guide/CreatorGuideView";

import { mapGetters, mapState } from "vuex";

export default {
    created() {
        this.$store.dispatch("creatorGuide/refresh");
    },
    computed: {
        ...mapState("creatorGuide", ["currentGuide", "guides"]),
        ...mapGetters("creatorGuide", [
            "hasIncompleteGuides",
            "incompleteGuides",
        ]),
    },
    methods: {
        setCurrentGuide(guide) {
            this.$store.dispatch("creatorGuide/setCurrentGuide", guide);
        },
    },
    components: {
        CreatorGuideView,
    },
};
</script>