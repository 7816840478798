<template>
    <b-modal v-model="modal" :title="$t('Report User')" hide-footer centered>
        <div v-if="!reason">
            <b-btn
                @click="selectReason(reasonOption)"
                :key="reasonOption.id"
                v-for="reasonOption in reasons"
                variant="light"
                block
            >
                {{ reasonOption.description }}
            </b-btn>
        </div>

        <b-alert variant="success" show v-if="reason">
            {{
                $t(
                    "Thanks, we got your report. Do you want to add any other details to your report?"
                )
            }}
        </b-alert>

        <b-form v-if="reason">
            <b-form-group>
                <b-textarea
                    v-model="reportText"
                    rows="4"
                    @input="edited = true"
                    :placeholder="
                        $t(
                            'Please add any details that can help us understand what happened'
                        )
                    "
                    autocorrect="off"
                    autocapitalize="none"
                ></b-textarea>
            </b-form-group>

            <b-form-group>
                <b-btn
                    @click="$store.dispatch('report/full', reportText)"
                    variant="primary"
                    size="lg"
                    block
                >
                    {{ $t("Add Details") }}
                </b-btn>
            </b-form-group>
        </b-form>
    </b-modal>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            reportText: null,
        };
    },
    computed: {
        ...mapState("profile", ["profile"]),
        ...mapState("options", ["reasons"]),
        ...mapState("report", ["reason"]),
        modal: {
            get() {
                return this.$store.state.interface.reportUserModalVisible;
            },
            set(value) {
                if (!value) {
                    this.$store.commit("report/setReason", null);
                }

                this.$store.commit(
                    "interface/setReportUserModalVisible",
                    value
                );
            },
        },
    },
    methods: {
        selectReason(reason) {
            this.$store.commit("report/setReason", reason);
            this.$store.dispatch("report/reason", reason.id);
        },
    },
};
</script>