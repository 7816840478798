<template>
    <div>
        <div v-if="editMode">
            <b-btn
                @click="doneEditing"
                variant="light"
                class="btn-brand shadow-sm"
                pill
            >
                {{ $t("Done Editing") }}
            </b-btn>
        </div>
        <div v-else>
            <b-btn
                @click="setEditMode"
                variant="light"
                class="btn-brand shadow-sm"
                pill
            >
                <font-awesome-icon icon="fa-duotone fa-pencil" />
                {{ $t("Edit") }}
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState("conversationTemplate", ["editMode"]),
    },
    methods: {
        setEditMode() {
            this.$store.commit("conversationTemplate/setEditMode", true);
        },
        doneEditing() {
            this.$store.commit("conversationTemplate/setEditMode", false);
        },
    },
};
</script>

