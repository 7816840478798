<template>
    <b-modal
        :title="$t('Video Call')"
        hide-footer
        v-model="modal"
        centered
        :no-close-on-esc="preventAccidentalClose"
        :hide-header-close="preventAccidentalClose"
        :no-close-on-backdrop="preventAccidentalClose"
        
    >
        <call-manage />
    </b-modal>
</template>

<script>
import { mapState } from "vuex";

// import CallTerms from "@/components/always/call/CallTerms";
import CallManage from "@/components/always/call/CallManage";

export default {
    computed: {
        preventAccidentalClose() {
            return this.currentCall ? true : false;
        },
        modal: {
            get() {
                return this.$store.state.interface.videoCallModalVisible;
            },
            set(value) {
                this.$store.commit("interface/setVideoCallModalVisible", value);
            },
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("call", ["currentCall"]),
    },
    components: {
        // CallTerms,
        CallManage,
    },
};
</script>