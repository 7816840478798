<template>
    <div>
        <b-alert v-if="error" show variant="danger" class="mt-3">
            <h3>Error</h3>
            <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
            {{ error }}

            <div class="mt-3 small text-secondary">
                If you continue to have issues saving account information,
                please contact support for assistance
            </div>
        </b-alert>

        <div v-if="account" class="mb-4">
            <h5>
                {{ $t("Use existing account") }}
            </h5>
            <wise-account-preview :account="account">
                <b-btn
                    @click="useExistingAccount"
                    variant="primary"
                    pill
                    block
                    size="lg"
                    class="d-flex align-items-center justify-content-between mt-2"
                >
                    <div>
                        {{ $t("Pay to this account") }}
                    </div>
                    <div>
                        <font-awesome-icon
                            size="lg"
                            icon="fa-light fa-chevron-right"
                        />
                    </div>
                </b-btn>
            </wise-account-preview>
        </div>

        <h5>
            {{ $t("Select account type") }}
        </h5>
        <b-btn
            @click="setType(requirement.type)"
            :key="requirement.type"
            v-for="requirement in requirements"
            variant="light"
            pill
            block
            size="lg"
            class="d-flex align-items-center justify-content-between"
        >
            <div>
                {{ requirement.title }}
            </div>
            <div>
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-right" />
            </div>
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

import WiseAccountPreview from "@/components/withdraw/payout/wise/WiseAccountPreview";

export default {
    mounted() {
        this.$store.dispatch("wise/refreshRequirements");
    },
    computed: {
        ...mapState("withdraw", ["withdraw"]),
        ...mapState("wise", ["account", "requirements", "error"]),
    },
    methods: {
        useExistingAccount() {
            this.$store.dispatch("wise/useExistingAccount");
        },
        setType(type) {
            this.$store.commit("wise/setCreateAccountType", type);
        },
    },
    components: {
        WiseAccountPreview,
    },
};
</script>