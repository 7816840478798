<template>
    <div>
        <div class="py-2 text-center">
            <div>
                <font-awesome-icon
                    class="text-success"
                    icon="fa-light fa-check-circle"
                    size="5x"
                />
            </div>
            <h3 class="mt-3">
                {{ $t("Subscription Created") }}
            </h3>
        </div>

        <div class="p-2 bg-light rounded border small">
            <div class="text-secondary my-1">
                <font-awesome-icon
                    icon="fa-light fa-check"
                    class="text-success"
                />
                {{ $t("Your purchase of") }}
                ${{ subscriptionPrice }}
                {{ $t("was successful") }}
            </div>
            <div class="text-secondary my-1">
                <font-awesome-icon
                    icon="fa-light fa-check"
                    class="text-success"
                />
                {{ $t("You will be charged weekly until canceled") }}
            </div>
            <div class="text-secondary my-1">
                <font-awesome-icon
                    icon="fa-light fa-check"
                    class="text-success"
                />
                {{ $t("You can now view all of their private content") }}
            </div>
        </div>

        <div class="mt-3">
            <b-btn @click="closeModal" variant="primary" block pill>
                {{ $t("Close Window") }}
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        ...mapState("payment", ["createCard"]),
        ...mapState("userSubscription", [
            "subscriptionPrice",
            "creatorUserId",
            "subscriptionLocalPrice",
            "creatorStandardMap",
            "subscribing",
            "purchaseSuccessful",
        ]),
        ...mapGetters("payment", ["hasCreditCard"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        canSubscribe() {
            return (
                !this.createCard &&
                this.creatorUserId !== this.onboardingUser.id
            );
        },
        createDisabled() {
            return (
                this.subscribing ||
                !this.subscriptionPrice ||
                !this.hasCreditCard
            );
        },
    },
    methods: {
        createSubscription() {
            this.$store.dispatch("userSubscription/subscribe");
        },
        closeModal() {
            this.$store.commit(
                "interface/setCreatorSubscriptionModalVisible",
                false
            );
        },
    },
    components: {},
};
</script>