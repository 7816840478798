<template>
    <div>
        <div class="text-center py-3">
            <h3>
                {{ $t("You're Invited") }}
            </h3>

            <div v-if="creatorGallery">
                <b-alert variant="primary" show>
                    <font-awesome-icon icon="fa-duotone fa-info-circle" />

                    {{ creatorGallery.standardMap.name }}
                    {{ $t("invited you to view their gallery") }}
                </b-alert>

                <gallery-details :creatorGallery="creatorGallery" />
                <gallery-preview :creatorGallery="creatorGallery" />
            </div>
            <div v-else>
                <generic-loading />
            </div>

            <b-row class="mt-4">
                <b-col cols="12" md="6" class="my-2">
                    <b-btn
                        variant="primary"
                        @click="viewGallery"
                        pill
                        block
                        size="lg"
                    >
                        {{ $t("View Gallery") }}
                    </b-btn>
                </b-col>
                <b-col cols="12" md="6" class="my-2">
                    <b-btn
                        variant="light"
                        @click="closeModal"
                        pill
                        block
                        size="lg"
                    >
                        {{ $t("Maybe later") }}
                    </b-btn>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import GalleryDetails from "@/components/gallery/GalleryDetails";
import GalleryPreview from "@/components/gallery/GalleryPreview";

export default {
    data() {
        return {
            creatorGallery: null,
        };
    },
    created() {
        this.api.post("/offer/currentGallery").then((response) => {
            if (response?.data?.creatorGallery) {
                this.creatorGallery = response.data.creatorGallery;
            }
        });
    },
    computed: {
        currentOffer() {
            return this.$store.state.displayOffer?.currentOffer;
        },
    },
    methods: {
        closeModal() {
            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
        viewGallery() {
            this.$store.commit("interface/setDisplayOfferModalVisible", false);

            // sticky offer
            // manually mark viewed
            this.api.post("/offer/removeCurrentGallery");

            this.$router.push({
                name: "galleryView",
                params: {
                    id: this.creatorGallery.id,
                },
            });
        },
    },
    components: {
        GalleryPreview,
        GalleryDetails,
    },
};
</script>