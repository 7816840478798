<template>
    <div>
        <b-btn
            @click="createRequest"
            pill
            variant="light"
            class="d-flex align-items-center bg-white shadow-sm shadow-blue mr-2"
            size="sm"
        >
            <span class="text-primary">
                <font-awesome-icon
                    class="mr-1"
                    icon="fa-duotone fa-hand-holding-dollar"
                />
            </span>
            {{ $t("Request") }}
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    methods: {
        createRequest() {
            this.$store.dispatch("creatorRequest/start", this.profile);
        },
    },
    computed: {
        ...mapState("profile", ["profile"]),
    },
};
</script>