<template>
    <div>
        <b-modal
            :title="$t('Share Gallery')"
            hide-footer
            v-model="modal"
            centered
            body-class="p-0"
            no-close-on-esc
            
            static
        >
            <user-standard-avatar
                v-if="standardMap"
                :timestamp="standardMap.lastActive"
                :standardMap="standardMap"
                size="small"
            >
                <div>
                    {{ $t("User to share to") }}
                </div>
            </user-standard-avatar>

            <div class="d-flex align-items-center justify-content-between p-2">
                <div class="small text-warning">
                    {{ $t("Costs 1 credit per share") }}
                </div>
                <wallet-balance />
            </div>

            <div>
                <invite-gallery-index />
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";

import UserStandardAvatar from "@/components/user/UserStandardAvatar";
import InviteGalleryIndex from "@/components/always/creator/InviteGalleryIndex";
import WalletBalance from "@/components/utility/WalletBalance";

export default {
    computed: {
        modal: {
            get() {
                return this.$store.state.interface.creatorInviteModalVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setCreatorInviteModalVisible",
                    value
                );
            },
        },
        ...mapState("creatorGalleryInvite", ["standardMap"]),
    },
    methods: {
        createSubscription() {
            this.$store.dispatch("creatorGalleryInvite/create");
        },
    },
    components: {
        UserStandardAvatar,
        WalletBalance,
        InviteGalleryIndex,
    },
};
</script>