<template>
    <b-badge variant="light" pill class="border">
        <font-awesome-icon icon="fa-duotone fa-coin" class="text-warning" />
        {{ creatorGallery.price }}
    </b-badge>
</template>

<script>
export default {
    props: ["creatorGallery"],
};
</script>