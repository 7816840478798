<template>
    <div>
        <img
            :height="primaryPhotoSize"
            :width="primaryPhotoSize"
            class="img-fluid rounded border"
            :src="standardMap.primaryPhoto.search"
        />
    </div>
</template>

<script>
export default {
    computed: {
        primaryPhotoSize() {
            if (this.size === "small") {
                return 50;
            }

            return 150;
        },
    },

    props: ["standardMap", "size"],
};
</script>