import FallbackPage from '@/page/FallbackPage'

const routes = [
    {
        path: '/app',
        redirect: '/'
    },
    {
        path: '*',
        component: FallbackPage,
        name: 'fallback',
    },
];

export default routes;
