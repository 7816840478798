<template>
    <div v-if="hasQueuedUploads">
        <div class="small mt-3 text-primary p-2 d-flex align-items-center">
            <b-spinner variant="light" small class="mr-2" />
            {{ $t("Processing photos, just a moment...") }}
        </div>
        <b-row no-gutters>
            <b-col
                cols="3"
                :key="uploadUrl"
                v-for="uploadUrl in uploadInProgress"
                class="
                    position-relative
                    d-flex
                    align-items-center
                    justify-content-around
                    bg-light
                "
            >
                <b-spinner variant="dark" class="position-absolute z-index-1" />
                <img class="img-fluid uploading" :src="uploadUrl" />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
.uploading {
    opacity: 0.5;
}
</style>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        hasQueuedUploads() {
            return this.uploadInProgress.length;
        },
        ...mapState("creatorPostEdit", ["creatorPost"]),
        ...mapState("createPostPhoto", ["uploadInProgress"]),
    },
};
</script>