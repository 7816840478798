<template>
    <div>
        <profile-blocked v-if="!profile.self" />

        <photo-swiper-small :key="`small-${profile.id}`" />

        <profile-status v-if="!profile.self" />

        <profile-actions v-if="!profile.self" />

        <profile-subscribe />

        <profile-reputation v-if="!profile.self" />

        <profile-private-photos />

        <profile-stats />

        <profile-block-report v-if="!profile.self" />
    </div>
</template>

<script>
import { mapState } from "vuex";
import PhotoSwiperSmall from "@/components/profile/PhotoSwiperSmall";
import ProfileActions from "@/components/profile/ProfileActions";
import ProfileBlocked from "@/components/profile/ProfileBlocked";
import ProfileStatus from "@/components/profile/ProfileStatus";
import ProfileSubscribe from "@/components/profile/ProfileSubscribe";
import ProfileStats from "@/components/profile/ProfileStats";
import ProfileReputation from "@/components/profile/ProfileReputation";
import ProfileBlockReport from "@/components/profile/ProfileBlockReport";
import ProfilePrivatePhotos from "@/components/profile/ProfilePrivatePhotos";

export default {
    computed: {
        isLive() {
            return this.liveUserIds.includes(this.profile.id);
        },
        ...mapState("profile", ["profile", "profileError"]),
        ...mapState("pusher", ["onlineUserIds", "liveUserIds"]),
    },
    components: {
        PhotoSwiperSmall,
        ProfileActions,
        ProfileReputation,
        ProfileStats,
        ProfilePrivatePhotos,
        ProfileBlockReport,
        ProfileBlocked,
        ProfileSubscribe,
        ProfileStatus,
    },
};
</script>