<template>
    <div
        class="d-flex align-items-center fake-link bg-white"
        @click="viewProfile"
    >
        <img
            class="border border-light rounded mr-2"
            :src="standardMap.photo"
        />
        <div class="">
            <h6 class="text-dark mb-0">
                {{ standardMap.name }}
            </h6>
            <div class="small text-secondary d-flex align-items-center">
                <img class="mr-1" :src="standardMap.flagUrl" />
                {{ standardMap.location }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        viewProfile() {
            this.$store.dispatch("profile/viewProfile", {
                userId: this.standardMap.id,
                profile: this.standardMap,
            });
        },
    },
    props: ["standardMap"],
};
</script>