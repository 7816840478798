import AccountCallIndex from '@/components/account/call/AccountCallIndex'
import AccountCallView from '@/components/account/call/AccountCallView'

import EarningsCurrency from '@/components/earnings/EarningsCurrency'
import EarningsCalculator from '@/components/earnings/EarningsCalculator'
import EarningsHistory from '@/components/earnings/EarningsHistory'
import EarningsWithdrawals from '@/components/earnings/EarningsWithdrawals'
import EarningsWithdrawView from '@/components/earnings/EarningsWithdrawView'

import SupportIndex from '@/components/support/SupportIndex'
import SupportTopic from '@/components/support/SupportTopic'
import SupportContent from '@/components/support/SupportContent'

import CreatorBonusIndex from '@/components/creator/bonus/CreatorBonusIndex'

import LivestreamIndex from '@/components/livestream/LivestreamIndex'
import LivestreamView from '@/components/livestream/LivestreamView'

import CreatorPaidShareIndex from '@/components/creator/paidShare/CreatorPaidShareIndex'
import CreatorPaidShareView from '@/components/creator/paidShare/CreatorPaidShareView'

import CreatorFineIndex from '@/components/creator/fine/CreatorFineIndex'
import CreatorGuideIndex from '@/components/creator/guide/CreatorGuideIndex'

import CreatorPendingCreditIndex from '@/components/creator/pendingCredit/CreatorPendingCreditIndex'
import CreatorRequestIndex from '@/components/creator/requests/CreatorRequestIndex'
import CreatorRequestManage from '@/components/creator/requests/CreatorRequestManage'

// need better deliniation between creator from guy or girl perspective
import CreatorRequests from '@/components/account/creatorRequests/CreatorRequests'
import CreatorRequestView from '@/components/account/creatorRequests/CreatorRequestView'

import SubscriptionIndex from '@/components/subscription/SubscriptionIndex'

import PostIndex from '@/components/post/index/PostIndex'

import CreatorInviteCreator from "@/components/creator/invite/CreatorInviteCreator";
import CreatorInviteActive from "@/components/creator/invite/CreatorInviteActive";
import CreatorInviteInactive from "@/components/creator/invite/CreatorInviteInactive";
import CreatorInviteInactiveMessage from "@/components/creator/invite/CreatorInviteInactiveMessage";
import CreatorInviteInactiveGallery from "@/components/creator/invite/CreatorInviteInactiveGallery";

import CreatorGalleryIndex from '@/components/creator/gallery/CreatorGalleryIndex'
import CreatorGalleryView from '@/components/creator/gallery/CreatorGalleryView'
import CreatorGalleryEdit from '@/components/creator/gallery/CreatorGalleryEdit'
import CreatorGalleryInvite from '@/components/creator/gallery/CreatorGalleryInvite'
import CreatorGalleryShare from '@/components/creator/gallery/CreatorGalleryShare'

import CreatorCallIndex from '@/components/creator/call/CreatorCallIndex'
import CreatorCallView from '@/components/creator/call/CreatorCallView'

import CreatorLivestreamIndex from '@/components/creator/livestream/CreatorLivestreamIndex'
import CreatorLivestreamView from '@/components/creator/livestream/CreatorLivestreamView'

import CreatorPostIndex from '@/components/creator/post/CreatorPostIndex'
import CreatorPostSchedule from '@/components/creator/post/CreatorPostSchedule'
import CreatorPostView from '@/components/creator/post/CreatorPostView'
import CreatorPostEdit from '@/components/creator/post/CreatorPostEdit'

import CreatorJackpotIndex from '@/components/creator/jackpot/CreatorJackpotIndex'


// @DEPRECATED components below
// modernize as you go
// page model is deprecated, just use direct component now
// extra component that does nothing
import CreatorStatsPage from '@/page/creator/CreatorStatsPage'
import CreatorSubscribersPage from '@/page/creator/CreatorSubscribersPage'
import CreatorLeadboardPage from '@/page/creator/CreatorLeadboardPage'
import CreatorPage from '@/page/CreatorPage'
import CreatorInvitePage from '@/page/creator/CreatorInvitePage'

// shared between affiliate and social
import EarningsPage from '@/page/EarningsPage'

import SearchIndex from '@/components/widget/search/SearchIndex'
import SwipeWidget from '@/components/swipe/SwipeWidget'

import PublicPostPage from '@/page/PublicPostPage'
import DefaultPage from '@/page/DefaultPage'
import VideoPage from '@/page/VideoPage'

import LoginApp from '@/app/LoginApp'

import AccountPage from '@/page/AccountPage'
import AccountMore from '@/page/AccountMore'
import GalleryPage from '@/page/GalleryPage'
import SandboxPage from '@/page/SandboxPage'
import GalleryIndex from '@/components/galleryIndex/GalleryIndex'


const routes = [
    {
        path: '/',
        component: DefaultPage,
        name: 'defaultPage',
        meta: { primaryActionLogin: true }
    },
    {
        path: '/feed',
        component: PostIndex,
        name: 'feed',
        meta: { primaryNavigation: 'feed', view: 'popular' },
    },
    {
        path: '/sandbox',
        component: SandboxPage,
    },
    {
        path: '/browse/search',
        component: SearchIndex,
        name: 'search',
        meta: { primaryNavigation: 'browse' },
    },
    {
        path: '/browse/gallery',
        component: GalleryIndex,
        name: 'galleries',
        meta: { primaryNavigation: 'gallery' },
    },
    {
        path: '/browse/swipe',
        component: SwipeWidget,
        name: 'swipe',
        meta: { primaryNavigation: 'swipe' }
    },
    {
        path: '/browse/videos',
        component: VideoPage,
        name: 'videos',
        meta: { primaryNavigation: 'browse' }
    },
    {
        path: '/live',
        component: LivestreamIndex,
        name: 'livestreamIndex',
        meta: { primaryNavigation: 'live' }
    },
    {
        path: '/livestream/:id',
        component: LivestreamView,
        name: 'livestreamView',
        meta: { primaryNavigation: 'live' }
    },
    {
        path: '/post/:slug',
        component: PublicPostPage,
        name: 'publicPost',
        meta: { primaryNavigation: 'post' }
    },
    {
        path: '/gallery/:id',
        component: GalleryPage,
        name: 'galleryView',
        meta: { primaryNavigation: 'gallery' }
    },
    {
        path: '/gallery',
        component: GalleryIndex,
        name: 'galleryIndex',
        meta: { primaryNavigation: 'gallery' }
    },
    {
        path: '/support',
        component: SupportIndex,
        name: 'supportIndex',
    },
    {
        path: '/support/topic/:id',
        component: SupportTopic,
        name: 'supportTopic',
    },
    {
        path: '/support/content/:id',
        component: SupportContent,
        name: 'supportContent',
    },
    {
        path: '/login',
        component: LoginApp,
        name: 'login',
        meta: { mode: 'login' },
    },
    {
        path: '/signup',
        component: LoginApp,
        name: 'signup',
        meta: { mode: 'signup' },
    },
    {
        path: '/creator',
        component: CreatorPage,
        name: 'creatorDashboard',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/invite',
        component: CreatorInvitePage,
        name: 'creatorInvite',
        meta: { primaryNavigation: 'creator' },
        children: [
            {
                path: 'active',
                component: CreatorInviteActive,
                meta: { inviteStatus: 'active', primaryNavigation: 'creator' },
            },
            {
                path: 'inactive',
                component: CreatorInviteInactive,
                meta: { inviteStatus: 'inactive', primaryNavigation: 'creator' },
                children: [
                    {
                        path: 'message',
                        component: CreatorInviteInactiveMessage,
                        meta: {
                            engagementMode: 'message',
                            inviteStatus: 'inactive',
                            primaryNavigation: 'creator'
                        },
                    },
                    {
                        path: 'gallery',
                        component: CreatorInviteInactiveGallery,
                        meta: {
                            engagementMode: 'gallery',
                            inviteStatus: 'inactive',
                            primaryNavigation: 'creator'
                        },
                    },
                ]
            },
            {
                path: 'creator',
                component: CreatorInviteCreator,
                meta: { inviteStatus: 'creator', primaryNavigation: 'creator' },
            },
        ]
    },
    {
        path: '/creator/jackpot',
        component: CreatorJackpotIndex,
        name: 'creatorJackpot',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/stats',
        component: CreatorStatsPage,
        name: 'creatorStats',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/leaderboard',
        component: CreatorLeadboardPage,
        name: 'creatorLeaderboard',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/call',
        component: CreatorCallIndex,
        name: 'creatorCall',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/call/:id',
        component: CreatorCallView,
        name: 'creatorCallView',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/livestream',
        component: CreatorLivestreamIndex,
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/livestream/:id',
        component: CreatorLivestreamView,
        name: 'creatorLivestreamView',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/post',
        component: CreatorPostIndex,
        name: 'creatorPost',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/post/schedule',
        component: CreatorPostSchedule,
        name: 'creatorPostSchedule',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/post/:id',
        component: CreatorPostView,
        name: 'creatorPostView',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/post/:id/edit',
        component: CreatorPostEdit,
        name: 'creatorPostEdit',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/bonus',
        component: CreatorBonusIndex,
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/gallery',
        component: CreatorGalleryIndex,
        name: 'creatorGallery',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/gallery/:id',
        component: CreatorGalleryView,
        name: 'creatorGalleryView',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/gallery/:id/edit',
        component: CreatorGalleryEdit,
        name: 'creatorGalleryEdit',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/gallery/:id/invite',
        component: CreatorGalleryInvite,
        name: 'creatorGalleryInvite',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/gallery/:id/share',
        component: CreatorGalleryShare,
        name: 'creatorGalleryShare',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/pendingCredit',
        component: CreatorPendingCreditIndex,
        name: 'creatorPendingCredit',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/paidShare',
        component: CreatorPaidShareIndex,
        name: 'creatorPaidShare',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/paidShare/:id',
        component: CreatorPaidShareView,
        name: 'creatorPaidShareView',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/fines',
        component: CreatorFineIndex,
        name: 'creatorFines',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/guides',
        component: CreatorGuideIndex,
        name: 'creatorGuides',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/requests',
        component: CreatorRequestIndex,
        name: 'creatorRequests',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/requests/:id',
        component: CreatorRequestManage,
        name: 'creatorRequestManage',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/subscribers',
        component: CreatorSubscribersPage,
        name: 'creatorSubscribers',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/earnings',
        component: EarningsPage,
        name: 'earnings',
    },
    {
        path: '/earnings/calculator',
        component: EarningsCalculator,
        name: 'earningsCalculator',
    },
    {
        path: '/earnings/currency',
        component: EarningsCurrency,
        name: 'earningsCurrency',
    },
    {
        path: '/earnings/history',
        component: EarningsHistory,
        name: 'earningsHistory',
    },
    {
        path: '/earnings/withdrawals',
        component: EarningsWithdrawals,
        name: 'earningsWithdrawals',
    },
    {
        path: '/earnings/withdrawal/:id',
        component: EarningsWithdrawView,
        name: 'earningsWithdrawView',
    },
    {
        path: '/account',
        component: AccountPage,
        name: 'account',
        meta: { primaryNavigation: 'account' },
    },
    {
        path: '/account/more',
        component: AccountMore,
        name: 'accountMore',
        meta: { primaryNavigation: 'account' },
    },
    {
        path: '/account/call/index',
        component: AccountCallIndex,
        name: 'accountCallIndex',
        meta: { primaryNavigation: 'account' },
    },
    {
        path: '/account/call/view/:id',
        component: AccountCallView,
        name: 'accountCallView',
        meta: { primaryNavigation: 'account' },
    },
    {
        path: '/account/requests',
        component: CreatorRequests,
        name: 'accountCreatorRequests',
        meta: { primaryNavigation: 'account' },
    },
    {
        path: '/account/subscriptions',
        component: SubscriptionIndex,
        name: 'accountSubscriptions',
        meta: { primaryNavigation: 'account' },
    },
    {
        path: '/account/requests/:id',
        component: CreatorRequestView,
        name: 'accountCreatorRequestView',
        meta: { primaryNavigation: 'account' },
    },
];

export default routes;
