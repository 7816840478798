export default {
    mounted() {
        if (typeof document.hidden === 'undefined') {
            return;
        }

        document.addEventListener('visibilitychange', this.handleVisibilityChange, false)
    },
    methods: {
        handleVisibilityChange() {
            this.$store.commit('system/setVisible', !document.hidden)
        }
    },
    destroyed() {
        if (typeof document.hidden === 'undefined') {
            return;
        }

        document.removeEventListener('visibilitychange', this.handleVisibilityChange, false)
    }
}