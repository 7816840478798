<template>
    <universal-uploader
        class="text-truncate"
        @uploadSuccess="uploadSuccess"
        :clientAllowedFormats="['image', 'video']"
    >
        <slot />
    </universal-uploader>
</template>

<script>
import { mapState } from "vuex";
import UniversalUploader from "@/components/universalUploader/UniversalUploader";

export default {
    methods: {
        async uploadSuccess(url, info) {
            const type = info.resource_type === "video" ? "video" : "photo";

            await this.api.post("/creatorRequest/addUserMedia", {
                creatorRequestId: this.creatorRequest.id,
                type,
                publicId: info.public_id,
            });

            this.$store.dispatch("creatorRequest/refresh");
        },
    },
    computed: {
        ...mapState("creatorRequest", ["creatorRequest"]),
    },
    components: {
        UniversalUploader,
    },
};
</script>