<template>
    <div>
        <b-skeleton-wrapper
            :loading="messageIndexLoading || !hasLoadedMessages"
        >
            <template #loading>
                <div :key="n" v-for="n in Array(20)">
                    <div class="user-wrapper border-top border-light">
                        <div class="user bg-white">
                            <div class="d-flex align-items-center">
                                <div class="mr-3">
                                    <b-skeleton-img
                                        height="75px"
                                        width="75px"
                                    ></b-skeleton-img>
                                </div>
                                <div class="flex-grow-1">
                                    <h5>
                                        <b-skeleton width="25%"></b-skeleton>
                                    </h5>
                                    <div class="small mt-2">
                                        <div class="text-truncate">
                                            <b-skeleton
                                                width="85%"
                                            ></b-skeleton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <div v-if="filteredMessages">
                <div v-if="!filteredMessages.length">
                    <div class="text-center py-3 text-secondary alert-primary">
                        {{ $t("No messages") }}
                    </div>
                </div>

                <div
                    :key="message.id"
                    v-for="message in filteredMessages"
                    @click="viewConversation(message)"
                >
                    <user-standard-avatar
                        size="small"
                        :timestamp="message.created"
                        :minimal="true"
                        :nonPrimaryPhotos="false"
                        :hideBadges="true"
                        :hideFlag="true"
                        :userBetweenStatus="false"
                        :standardMap="message.standardMap"
                        class="border-light border-top"
                        :class="{
                            active:
                                view === 'conversation' &&
                                message.userId === openConversationUserId,
                        }"
                    >
                        <message-index-content :message="message" />
                    </user-standard-avatar>
                </div>
                <b-btn
                    v-if="hasMoreMessages"
                    @click="$store.dispatch('message/next')"
                    block
                    class="rounded-0 border-0 py-5"
                    variant="light"
                >
                    {{ $t("Load More") }}
                </b-btn>
            </div>
        </b-skeleton-wrapper>
    </div>
</template>

<style lang="scss" scoped>
.preview {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--dark);
    border-right: 2px solid transparent;
    border-top: 1px solid var(--light);

    &.active {
        background-color: var(--light);
        border-right: 2px solid var(--blue);
    }

    &:hover {
        background-color: var(--light);
        cursor: pointer;
    }

    .photo {
        height: 50px;
        width: 50px;
    }

    .content {
        max-width: 200px;
    }

    .online-status-wrapper {
        bottom: 2px;
        right: 2px;
    }
}
</style>

<script>
import { mapGetters, mapState } from "vuex";

import UserStandardAvatar from "@/components/user/UserStandardAvatar";
import MessageIndexContent from "@/components/messages/MessageIndexContent";

export default {
    created() {},

    computed: {
        ...mapState("message", [
            "hasMoreMessages",
            "view",
            "messageIndexLoading",
            "hasLoadedMessages",
        ]),
        ...mapState("conversation", ["openConversationUserId"]),
        ...mapGetters("message", ["filteredMessages"]),
    },
    methods: {
        viewConversation(message) {
            this.$store.dispatch("profile/viewProfile", {
                userId: message.userId,
                profile: message.standardMap,
                view: "conversation",
            });

            // if there are other unread messages, somehow need to re-open this if they close the conversation
            this.$store.commit("interface/setLeftSidebarVisible", false);
        },
    },
    components: {
        UserStandardAvatar,
        MessageIndexContent,
    },
};
</script>