<template>
    <div>
        <div class="bg-white p-2">
            <gallery-index-filters />
        </div>

        <div class="px-2 p-md-0">
            <div v-if="galleryRefreshing">
                <generic-loading />
            </div>
            <div v-else>
                <div
                    :key="creatorGallery.gallery.id"
                    v-for="creatorGallery in filteredCreatorGalleries"
                >
                    <gallery-index-item
                        class="mt-3 rounded border"
                        :purchased="creatorGallery.purchased"
                        :creatorGallery="creatorGallery.gallery"
                    />
                </div>
                <gallery-index-pagination />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import GalleryIndexItem from "@/components/galleryIndex/GalleryIndexItem";
import GalleryIndexPagination from "@/components/galleryIndex/GalleryIndexPagination";
import GalleryIndexFilters from "@/components/galleryIndex/GalleryIndexFilters";

export default {
    mounted() {
        this.$store.dispatch("galleryIndex/refresh");
    },
    computed: {
        galleryRefreshing() {
            return (
                this.galleryIndexLoading &&
                !this.filteredCreatorGalleries?.length
            );
        },
        ...mapGetters("galleryIndex", ["filteredCreatorGalleries", "page"]),
        ...mapState("galleryIndex", ["galleryIndexLoading"]),
        showReset() {
            return this.page > 0;
        },
    },
    methods: {},
    components: {
        GalleryIndexPagination,
        GalleryIndexItem,
        GalleryIndexFilters,
    },
};
</script>
        
        