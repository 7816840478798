<template>
    <div :class="`size-${size}`">
        <video-player
            :class="{ blur: !canViewVideo }"
            :muted="true"
            :controls="canViewVideo"
            :autoplay="true"
            :loop="true"
            :mp4Url="creatorPost.video.mp4Url"
            :webmUrl="creatorPost.video.webmUrl"
        />
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import VideoPlayer from "@/components/utility/VideoPlayer";

export default {
    computed: {
        iconSize() {
            if (this.size === "large") {
                return "5x";
            }

            return "2x";
        },
        canViewVideo() {            
            if (this.canViewPost(this.creatorPost)) {
                return true;
            }

            if (this.creatorPost.private) {
                return false;
            }

            return true;
        },
        ...mapState("user", ["premium"]),
        ...mapGetters("userSubscription", ["canViewPost"]),
    },

    components: {
        VideoPlayer,
    },
    props: ["creatorPost", "creatorPostVideo", "size"],
};
</script>