import { mapState } from "vuex";

export default {
    computed: {
        ...mapState('system', ['cordova'])
    },
    mounted() {
        this.$mousetrap.bind("esc", () => {
            this.$store.dispatch('navigation/closeOpenLayers')

        });

        if (this.cordova) {
            document.addEventListener("backbutton", () => {
                this.$store.dispatch('navigation/closeOpenLayers')
            }, false);
        }
    },
}