<template>
    <div>
        <b-btn
            @click="modal = true"
            pill
            variant="light"
            class="d-flex align-items-center bg-white shadow-sm shadow-blue mr-2"
            size="sm"
        >
            <span class="text-primary">
                <font-awesome-icon
                    class="mr-1"
                    icon="fa-duotone fa-image-polaroid-user"
                />
            </span>
            Photo
        </b-btn>

        <b-modal
            title="Send Photo Message"
            v-model="modal"
            hide-footer
            centered
        >
            <conversation-media-upload />
            <conversation-media-index @sent="modal = false" />
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";

import ConversationMediaIndex from "@/components/conversation/media/ConversationMediaIndex";
import ConversationMediaUpload from "@/components/conversation/media/ConversationMediaUpload";

export default {
    data() {
        return {
            modal: false,
        };
    },
    computed: {
        ...mapState("profile", ["profile"]),
    },
    components: {
        ConversationMediaUpload,
        ConversationMediaIndex,
    },
};
</script>