<template>
    <div class="bg-white p-2">
        <div class="d-flex align-items-center justify-content-between">
            <div class="text-left">
                <h5 class="mb-0 branded">
                    {{ creatorGallery.idealName }}
                </h5>
            </div>
            <h5 class="mb-0 ml-2">
                <b-badge v-if="purchased" pill variant="light" class="border">
                    <font-awesome-icon
                        class="mr-1 text-success"
                        icon="fa-light fa-check"
                    />
                    {{ $t("Purchased") }}
                </b-badge>
                <gallery-price v-else :creatorGallery="creatorGallery" />
            </h5>
        </div>
        <div class="d-flex align-items-center justify-content-center mt-2">
            <b-form-rating
                inline
                variant="warning"
                no-border
                v-if="creatorGallery.reviewAverage"
                v-model="creatorGallery.reviewAverage"
                readonly
                class="px-0"
            ></b-form-rating>
        </div>
    </div>
</template>

<script>
import GalleryPrice from "@/components/utility/gallery/GalleryPrice";

export default {
    components: {
        GalleryPrice,
    },
    props: ["creatorGallery", "purchased"],
};
</script>