<template>
    <user-standard-avatar
        :timestamp="creatorRequest.created"
        :standardMap="creatorRequest.creator"
        :nonPrimaryPhotos="false"
        :hideBadges="true"
        :userBetweenStatus="false"
        size="small"
        class="border-light border-top"
    >
        <div class="d-flex align-items-center mt-1">
            <b-badge pill variant="light" class="mr-2">
                <font-awesome-icon
                    icon="fa-duotone fa-coin"
                    class="text-warning"
                    size="lg"
                />
                {{ creatorRequest.price }}
            </b-badge>

            <creator-request-status :creatorRequest="creatorRequest" />
        </div>
    </user-standard-avatar>
</template>

<script>
import UserStandardAvatar from "@/components/user/UserStandardAvatar";
import CreatorRequestStatus from "@/components/creatorRequest/CreatorRequestStatus";

export default {
    components: {
        UserStandardAvatar,
        CreatorRequestStatus,
    },
    props: ["creatorRequest"],
};
</script>