/* global Camera */

import {
    PHOTO_UPLOAD_READY,
    PHOTO_READ_COMPLETE
} from "@/constant/photoUploadEvents"

export default {
    methods: {
        cordovaPhotoUpload(method) {
            const cameraSource =
                method == "camera"
                    ? Camera.PictureSourceType.CAMERA
                    : Camera.PictureSourceType.PHOTOLIBRARY;

            const captureOptions = {
                quality: 100,
                destinationType: Camera.DestinationType.FILE_URI,
                sourceType: cameraSource,
                encodingType: Camera.EncodingType.JPEG,
                mediaType: Camera.MediaType.PICTURE,
                allowEdit: false,
                correctOrientation: true,
                cameraDirection: Camera.Direction.FRONT,
            };

            const genericUploadErrorHandler = (error = null) => {
                const genericMessage = this.$t("Photo upload failed");

                this.$store.dispatch("appEvent/create", "cordova_filereader_errror");

                if (error === null) {
                    console.error(genericMessage);
                    return;
                }
            };

            try {
                navigator.camera.getPicture(
                    (imageUri) => {
                        window.resolveLocalFileSystemURL(
                            imageUri,
                            (fileEntry) => {
                                fileEntry.file((file) => {
                                    this.handleFileSelectMobile({
                                        imageUri,
                                        file,
                                    });
                                }, genericUploadErrorHandler);
                            }
                        );
                    },
                    genericUploadErrorHandler,
                    captureOptions
                );
            } catch (e) {
                genericUploadErrorHandler();
            }
        },
        handleFileSelectMobile({ imageUri, file } = {}) {
            const instanceId = this.$uuid()

            this.$emit(PHOTO_READ_COMPLETE, {
                small: imageUri,
                instanceId,
            });

            let reader = new FileReader();

            reader.onerror = () => {
                this.$store.dispatch("appEvent/create", "cordova_photo_upload_failure");
            };

            reader.onloadend = () => {
                const file = new Blob([new Uint8Array(reader.result)], {
                    type: "image/jpeg",
                });

                this.$emit(PHOTO_UPLOAD_READY, { file, instanceId, });
            };

            reader.readAsArrayBuffer(file);
        },
    },
}