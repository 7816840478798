<template>
    <b-row no-gutters class="bg-black" @click="$emit('clickedMedia')">
        <b-col
            cols="4"
            :key="userMedia.id"
            v-for="userMedia in creatorGallery.medias"
            class="p-2 overflow-hidden"
        >
            <gallery-media-item
                :preview="true"
                :blur="true"
                :userMedia="userMedia"
            />
        </b-col>
    </b-row>
</template>


<script>
import GalleryMediaItem from "@/components/gallery/GalleryMediaItem";

export default {
    components: {
        GalleryMediaItem,
    },
    props: ["creatorGallery"],
};
</script>