<template>
    <div>
        <b-modal
            :title="$t('Invite Friends')"
            hide-footer
            v-model="modal"
            centered
            no-close-on-esc
            
            static
        >
            <invite-friends-modal />
        </b-modal>
    </div>
</template>

<script>
// import { mapState } from "vuex";

import InviteFriendsModal from "@/components/always/inviteFriends/InviteFriendsModal";

export default {
    data() {
        return {
            unsubscribeToMutations: null,
        };
    },
    mounted() {
        this.unsubscribeToMutations = this.$store.subscribe((mutation) => {
            if (
                mutation.type === "interface/setInviteFriendsModalVisible" &&
                mutation.payload === true
            ) {
                this.$store.dispatch("inviteFriends/refreshUrl");
            }
        });
    },
    beforeDestroy() {
        if (this.unsubscribeToMutations) {
            this.unsubscribeToMutations();
        }
    },
    computed: {
        modal: {
            get() {
                return this.$store.state.interface.inviteFriendsModalVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setInviteFriendsModalVisible",
                    value
                );
            },
        },
    },
    components: {
        InviteFriendsModal,
    },
};
</script>