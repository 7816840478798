export const ATTRIBUTION_LANDING = "attribution:landingPage"
export const ATTRIBUTION_REFERER = "attribution:referer"
export const ATTRIBUTION_SOURCE = "attribution:source"
export const ATTRIBUTION_INVITE = "attribution:invite"
export const ATTRIBUTION_CLICK = "attribution:click"
export const ATTRIBUTION_ACCOUNT_TYPE = "attribution:accountType"

export const SEARCH_SCROLL_POSITION = "findmate:search-scroll-position"
export const SEARCH_LAST_VIEW = "findmate:search-last-view"
export const SEARCH_LAST_COUNTRY = "findmate:search-last-country"
export const LAST_LOGIN_METHOD = "findmate:last-login-method"
export const FINDMATE_AUTH_TOKEN = "findmate:authToken"
export const FINDMATE_TRACKER_ID = "findmate-tracker-idx"
export const FINDMATE_LAST_WIDGET = "findmate:lastWidget"

export const POST_NAVIGTION_LAST = "browse:navigationLastPost"
export const MESSAGE_NAVIGTION_LAST = "browse:navigationLastMessage"

export const CREATOR_POST_SCHEDULE_HELP = "creator:postScheduleHelp"

export const CALL_CAMERA_ENABLED = "call:cameraEnabled"
export const CALL_MICROPHONE_ENABLED = "call:microphoneEnabled"
export const CALL_AUDIO_DEVICE_ID = "call:audioDeviceId"
export const CALL_VIDEO_DEVICE_ID = "call:videoDeviceId"
export const CALL_AUDIO_ENABLED = "call:audioEnabled"
export const CALL_VIDEO_ENABLED = "call:videoEnable"
export const CALL_FIRST_LAUNCH = "call:firstLaunch"

export const LIVESTREAM_UNIQUE_KEY = "livestream:uniqueKey"