<template>
    <div>
        <h3>The Rules</h3>

        Violating the rules can result in your account being fined or banned
        without any warning

        <h5 class="text-danger mt-3">
            <font-awesome-icon class="text-danger" icon="fa-solid fa-ban" />
            Asking for payment outside of the app
        </h5>

        If you ask for a payment outside of the app your account will be banned.
        You are free to ask for credits within the app that you can withdraw
        immediately

        <h5 class="text-danger mt-3">
            <font-awesome-icon class="text-danger" icon="fa-solid fa-ban" />
            Trying to move conversation
        </h5>

        You are not allowed to try and move the conversation to any other app.
        Only guys are allowed, if they ask for your contact you can give it, but
        you will not be paid for anything on other apps.
        <span class="text-primary">
            Keep your activity here on the platform, and you won't have any
            problems
        </span>

        <h5 class="text-danger mt-3">
            <font-awesome-icon class="text-danger" icon="fa-solid fa-ban" />
            Fake, A.i., heavily filtered content
        </h5>

        Any kind of fake content, especially pretending to be someone you are
        not, can result in fines or a ban. You can use light filters, but not AI
        generators like PhotoLab that put your head onto a different body

        <h5 class="text-danger mt-3">
            <font-awesome-icon class="text-danger" icon="fa-solid fa-ban" />
            Fake invites
        </h5>

        We're really good at catching fake invites, in fact, nobody has ever
        managed to get one through, so please don't waste your time, only invite
        real customers who would be interested in the platform

        <h5 class="text-danger mt-3">
            <font-awesome-icon class="text-danger" icon="fa-solid fa-ban" />
            Giving out contact information
        </h5>

        You can only give out your contact information upon request from a guy.
        Posting your contact anywhere in the platform like in your profile or in
        a post is forbidden

        <b-btn
            @click="acceptRules"
            variant="primary"
            pill
            block
            size="lg"
            class="mt-3"
        >
            {{ $t("I agree to follow the rules") }}
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("creatorGuide", ["currentGuide", "guides"]),
        modal: {
            get() {
                return this.$store.state.creatorGuide.currentGuide
                    ? true
                    : false;
            },
            set(value) {
                if (!value) {
                    this.$store.commit("creatorGuide/reset");
                }
            },
        },
    },
    methods: {
        acceptRules() {
            this.api.post("/creatorGuide/acceptRules").then(() => {
                this.$store.commit("creatorGuide/reset");
                this.$store.dispatch("onboarding/status");
                this.$store.dispatch("creatorGuide/refresh");
            });
        },
    },
    components: {},
};
</script>


