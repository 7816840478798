var render = function render(){var _vm=this,_c=_vm._self._c;return _c('generic-page',[_c('b-btn',{staticClass:"pl-0",attrs:{"variant":"link","size":"lg","to":"/creator/post"}},[_c('font-awesome-icon',{attrs:{"size":"lg","icon":"fa-light fa-chevron-left"}}),_vm._v(" "+_vm._s(_vm.$t("All Posts"))+" ")],1),(_vm.creatorPost)?_c('div',[(_vm.creatorPost.publishedAt)?_c('view-post',{key:_vm.creatorPost.id,attrs:{"creatorPost":_vm.creatorPost}}):_vm._e(),_c('div',{staticClass:"mt-2"},[_c('creator-post-share')],1),_c('div',{staticClass:"d-flex align-items-center justify-content-between bg-white"},[_c('b-btn',{staticClass:"rounded-0 mt-0",attrs:{"disabled":!_vm.creatorPost.publishedAt,"variant":"light","size":"lg","block":""},on:{"click":function($event){return _vm.$router.push({
                        name: 'publicPost',
                        params: {
                            slug: _vm.creatorPost.slug,
                        },
                    })}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-duotone fa-globe","size":"lg"}}),_c('div',[_vm._v(" "+_vm._s(_vm.$t("View"))+" ")])],1),_c('b-btn',{staticClass:"rounded-0 mt-0",attrs:{"variant":"light","size":"lg","block":""},on:{"click":function($event){return _vm.$router.push({
                        name: 'creatorPostEdit',
                        params: {
                            id: _vm.creatorPost.id,
                        },
                    })}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-duotone fa-pencil","size":"lg"}}),_c('div',[_vm._v(" "+_vm._s(_vm.$t("Edit"))+" ")])],1)],1),_c('div',{staticClass:"py-2"},[_c('b-list-group',[_c('creator-post-likes'),_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("Status"))+" ")]),_c('div',[_c('div',{class:`text-${_vm.creatorPost.variant}`},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-circle","size":"xs"}}),_vm._v(" "+_vm._s(_vm.creatorPost.status)+" ")],1)])]),(_vm.creatorPost.totalEarnings)?_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("Total Earnings"))+" ")]),_c('coin-badge',{staticClass:"border",attrs:{"amount":_vm.creatorPost.totalEarnings}})],1):_vm._e(),_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("Created"))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.creatorPost.createdRelative)+" ")])])],1),_c('creator-post-comments'),_c('creator-post-gifts')],1)],1):_c('div',[_c('generic-loading')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }