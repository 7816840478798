<template>
    <div>
        <div @click="modal = true">
            <slot>
                <b-btn variant="light" size="sm" pill class="bg-white">
                    <font-awesome-icon icon="fa-duotone fa-camcorder" />
                    Test Camera
                </b-btn>
            </slot>
        </div>

        <b-modal centered hide-footer v-model="modal" title="Test Camera">
            <camera-preview-modal />
        </b-modal>
    </div>
</template>

<script>
import CameraPreviewModal from "@/components/agora/CameraPreviewModal";

export default {
    data() {
        return {
            modal: false,
        };
    },
    components: {
        CameraPreviewModal,
    },
};
</script>

