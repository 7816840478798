import api from "@/service/api";

const state = () => ({
    currentPost: null,
})

const mutations = {
    reset(state) {
        state.currentPost = null
    },
    setCurrentPost(state, value) {
        state.currentPost = value
    },
}

const actions = {
    refresh({ state, commit }) {
        if (!state.creatorPost || state.refreshInProgress) {
            return;
        }

        commit('setRefreshInProgress', true)

        return new Promise(resolve => {
            api.post("/post/get", {
                creatorPostId: state.creatorPost.id
            }).then((response) => {
                if (response?.data?.creatorPost) {
                    commit('setRefreshInProgress', false)
                    commit('setCreatorPost', response.data.creatorPost)
                    resolve()
                }
            });
        })
    },
}


const getters = {
    isMine(state, getters, rootState) {
        if (rootState.onboarding?.user?.id === state.currentPost?.userId) {
            return true
        }

        return false
    }
}


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
