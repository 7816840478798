<template>
    <div>
        <b-button
            @click="showModal"
            variant="light"
            class="text-truncate"
            block
            size="sm"
            pill
        >
            <font-awesome-icon icon="fa-duotone fa-envelope" />
            {{ $t("Email") }}
        </b-button>

        <b-modal
            :title="$t('Email Invite')"
            v-model="modal"
            hide-footer
            centered
            
        >
            <b-alert show v-if="successMessage" variant="success">
                {{ successMessage }}
            </b-alert>
            <b-alert show v-else variant="primary">
                <font-awesome-icon icon="fa-duotone fa-envelope" />
                {{
                    $t(
                        "We'll send them an email with your invitation, and automatically link your accounts for rewards, no need to share the URL when inviting over email"
                    )
                }}
            </b-alert>
            <b-form @submit.stop.prevent="sendEmail" class="mt-2">
                <b-form-group>
                    <b-form-input
                        size="lg"
                        v-model="email"
                        @input="edited = true"
                        :placeholder="$t('Email Address')"
                        type="email"
                        autocorrect="off"
                        autocomplete="email"
                        autocapitalize="none"
                        :state="error ? false : null"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="error">
                        {{ error }}
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-btn
                    @click="sendEmail"
                    :disabled="saving"
                    variant="primary"
                    size="lg"
                    block
                    pill
                >
                    {{ $t("Send Invite Email") }}
                </b-btn>
            </b-form>
        </b-modal>
    </div>
</template>



<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            saving: false,
            error: null,
            email: null,
            successMessage: null,
        };
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        modal: {
            get() {
                return this.$store.state.interface.inviteEmailModalVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setInviteEmailModalVisible",
                    value
                );
            },
        },
    },
    methods: {
        sendEmail() {
            this.saving = true;
            this.error = null;
            this.successMessage = null;

            this.api
                .post("/invite/email", {
                    email: this.email,
                })
                .then(() => {
                    this.successMessage = `Email sent to ${this.email}`;
                    this.email = null;
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error = error.response.data.message;
                    }
                })
                .then(() => {
                    this.saving = false;
                });
        },
        closeModal() {
            this.$store.commit("interface/setInviteEmailModalVisible", false);
        },
        showModal() {
            this.$store.commit("interface/setInviteEmailModalVisible", true);
        },
    },
};
</script>