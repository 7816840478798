<template>
    <div>
        <b-modal
            hide-footer
            hide-header
            centered
            scrollable
            v-model="modal"
            size="sm"
            body-class="p-0"
        >
            <div class="text-center px-3 py-4">
                <h5 class="mb-0">
                    {{ $t(`Are you enjoying using ${brand.name}?`) }}
                </h5>
            </div>
            <b-btn-group class="w-100">
                <b-btn
                    variant="light"
                    @click="answer(true)"
                    size="lg"
                    class="border-right border-top rounded-0"
                >
                    {{ $t("Yes") }}
                </b-btn>
                <b-btn
                    variant="light"
                    @click="answer(false)"
                    size="lg"
                    class="border-left border-top rounded-0"
                >
                    {{ $t("No") }}
                </b-btn>
            </b-btn-group>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";
/* global LaunchReview */

export default {
    data() {
        return {
            modal: false,
            sentInterestCount: 0,
            surveyed: false,
        };
    },
    computed: {
        ...mapState("system", ["cordova"]),
        ...mapState("options", ["brand"]),
    },
    created() {
        this.$store.subscribeAction((action, state) => {
            if (action.type === "interest/sendInterest") {
                this.sentInterestCount = this.sentInterestCount + 1;

                if (
                    this.sentInterestCount > 3 &&
                    state.onboarding.user.promptReview
                ) {
                    this.localSurvey();
                }
            }
        });
    },
    methods: {
        localSurvey() {
            if (this.surveyed) {
                return;
            }

            this.api.post("/setting/enable", { key: "prompt-review-shown" });

            this.surveyed = true;
            this.modal = true;
        },
        answer(value) {
            const key = value ? "enjoying-yes" : "enjoying-no";
            this.api.post("/setting/enable", { key });

            if (key) {
                this.launchReview();
            }

            this.modal = false;
        },
        launchReview() {
            if (!this.cordova) {
                return;
            }

            if (LaunchReview.isRatingSupported()) {
                LaunchReview.rating(
                    () => {
                        this.$store.dispatch("appEvent/create", "prompt_review_shown");
                    },
                    () => {
                        this.$store.dispatch("appEvent/create", "prompt_review_error");
                    }
                );
            }
        },
    },
};
</script>