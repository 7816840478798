<template>
    <generic-page>
        <b-btn variant="link" size="lg" class="pl-0" to="/creator/livestream">
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            All Livestreams
        </b-btn>

        <b-alert v-if="firstStream" variant="primary" show>
            <h5>Your First Livestream</h5>

            <div>
                Before you launch your stream make sure you have your
                permissions setup for video/audio, go ahead and test your camera
                now
            </div>

            <camera-preview class="my-3">
                <b-btn variant="primary" pill block>
                    <font-awesome-icon icon="fa-duotone fa-camcorder" />
                    Test Camera
                </b-btn>
            </camera-preview>

            <b-button @click="modal = true" variant="secondary" block pill>
                How this works?
            </b-button>
        </b-alert>

        <div v-if="creatorLivestream">
            <div v-if="cordova">
                <creator-livestream-cordova />
            </div>

            <div v-if="canStart" class="my-3">
                <b-alert v-if="followerCount" variant="primary" show>
                    Your {{ followerCount }} followers will be notified as soon
                    as you go live
                </b-alert>

                <div v-if="onboardingUser.private">
                    <b-alert
                        variant="warning"
                        show
                        class="rounded-0 mb-0 border-0"
                    >
                        <h5>
                            <font-awesome-icon
                                icon="fa-duotone fa-eye-slash"
                                class="text-warning"
                            />
                            Profile Hidden
                        </h5>

                        <div>
                            Your profile is hidden, you have to make your
                            profile public again to go live
                        </div>

                        <b-btn
                            @click="$store.dispatch('setting/editPrivacy')"
                            variant="primary"
                            size="lg"
                            pill
                            block
                            class="mt-3"
                        >
                            Stop hiding my profile
                        </b-btn>
                    </b-alert>
                </div>
                <div v-else>
                    <b-btn
                        @click="openModal"
                        variant="primary"
                        pill
                        size="lg"
                        block
                    >
                        <font-awesome-icon
                            class="mr-1"
                            icon="fa-duotone fa-camcorder"
                        />
                        Start Livestream
                    </b-btn>
                </div>
            </div>

            <b-modal
                v-model="modal"
                hide-footer
                title="Livestream Guide"
                centered
            >
                <creator-live :modal="true" />
            </b-modal>

            <div class="d-flex align-items-center justify-content-between">
                <h5 class="mb-0">Livestream Details</h5>
                <camera-preview />
            </div>

            <b-list-group>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    <div>Status</div>
                    <div class="small">
                        {{ creatorLivestream.status }}
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    v-if="creatorLivestream.totalEarnings"
                    class="d-flex align-items-center justify-content-between"
                >
                    <div>Earnings</div>
                    <coin-badge :amount="creatorLivestream.totalEarnings" />
                </b-list-group-item>
                <b-list-group-item
                    v-if="creatorLivestream.duration"
                    class="d-flex align-items-center justify-content-between"
                >
                    <div>Duration</div>
                    <div class="small">
                        {{ creatorLivestream.duration }}
                    </div>
                </b-list-group-item>
            </b-list-group>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import CreatorLivestreamCordova from "@/components/creator/livestream/CreatorLivestreamCordova";
import CameraPreview from "@/components/agora/CameraPreview";
import CreatorLive from "@/components/creator/guide/view/CreatorLive";

import { mapState } from "vuex";
export default {
    data() {
        return {
            modal: false,
            followerCount: null,
            firstStream: false,
            creatorLivestream: null,
            unsubscribeToAction: null,
            unsubscribeToMutations: null,
        };
    },
    mounted() {
        this.refresh();

        this.unsubscribeToAction = this.$store.subscribeAction({
            after: (action) => {
                if (action.type === "creatorLivestream/stop") {
                    this.refresh();
                }
            },
        });

        this.unsubscribeToMutations = this.$store.subscribe((mutation) => {
            if (mutation.type === "pusher/addLivestreamViewerUserId") {
                this.$store.dispatch("audioAlert/chime", "on");
            }
        });
    },
    beforeDestroy() {
        if (this.unsubscribeToAction) {
            this.unsubscribeToAction();
        }

        if (this.unsubscribeToMutations) {
            this.unsubscribeToMutations();
        }
    },
    watch: {
        $route(to) {
            if (to?.params?.id !== this.creatorLivestream?.id) {
                this.refresh();
            }
        },
    },
    computed: {
        canStart() {
            return (
                !this.cordova &&
                !this.creatorLivestream?.startedAt &&
                !this.creatorLivestream?.endedAt
            );
        },
        ...mapState("creatorLivestream", ["starting"]),
        ...mapState("system", ["cordova"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        openModal() {
            this.$store.commit("creatorLivestream/reset");

            // copy local instance to vuex
            this.$store.commit(
                "creatorLivestream/setCreatorLivestream",
                this.creatorLivestream
            );

            this.$store.commit(
                "interface/setCreatorLivestreamModalVisible",
                true,
                { root: true }
            );
        },
        refresh() {
            const id = this.$route.params?.id;

            this.api.post(`/creatorLivestream/${id}/get`).then((response) => {
                this.creatorLivestream = response?.data?.creatorLivestream;
                this.firstStream = response?.data?.firstStream;
                this.followerCount = response?.data?.followerCount;
            });
        },
    },
    components: {
        CreatorLivestreamCordova,
        CameraPreview,
        CreatorLive,
    },
};
</script>