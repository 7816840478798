<template>
    <div>
        <b-overlay :show="refreshing" spinner>
            <div class="small">
                {{ $t("Account Type") }}
            </div>
            <div>
                {{ selectedType.title }}
                <b-btn
                    variant="light"
                    @click="changeType"
                    small
                    pill
                    rounded
                    class="text-danger ml-1"
                >
                    {{ $t("Change") }}
                </b-btn>
            </div>

            <b-alert v-if="error" show variant="danger" class="mt-3">
                <h3>
                    {{ $t("Error") }}
                </h3>
                <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
                {{ error }}

                <div class="mt-3 small text-secondary">
                    {{
                        $t(
                            "If you continue to have issues saving account information, please contact support for assistance"
                        )
                    }}
                </div>
            </b-alert>

            <div
                :key="field.name"
                v-for="field in selectedType.fields"
                class="mt-2"
            >
                <div class="small">
                    {{ field.name }}
                </div>

                <div :key="group.key" v-for="group in field.group">
                    <b-form-input
                        v-if="group.type === 'text'"
                        v-model="payload[group.key]"
                        class="border-light"
                        autocomplete="off"
                        :placeholder="group.name"
                        @change="checkRefresh(group)"
                    ></b-form-input>
                    <b-form-select
                        v-else-if="
                            group.type === 'select' || group.type === 'radio'
                        "
                        v-model="payload[group.key]"
                        class="border-light"
                        :options="mapValuesAllowedToBsVueOptions(group)"
                        @change="checkRefresh(group)"
                    ></b-form-select>
                </div>
            </div>

            <b-btn @click="save" variant="primary" block pill class="mt-3">
                {{ $t("Save") }}
            </b-btn>
        </b-overlay>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    mounted() {
        this.$store.dispatch("wise/refreshRequirements");
    },
    data() {
        return {
            payload: {},
        };
    },
    computed: {
        ...mapState("withdraw", ["withdraw", "refreshing"]),
        ...mapState("wise", ["account", "createAccountType", "error"]),
        ...mapGetters("wise", ["selectedType"]),
    },
    methods: {
        changeType() {
            if (confirm(this.$t("Are you sure?"))) {
                this.$store.commit("wise/reset");
            }
        },
        checkRefresh(group) {
            if (group.refreshRequirementsOnChange) {
                this.$store.dispatch(
                    "wise/refreshRequirements",
                    this.buildPayload()
                );
            }
        },
        mapValuesAllowedToBsVueOptions(group) {
            if (!group?.valuesAllowed) {
                console.error(`missing valuesAllowed from field`, group);
                return [];
            }

            return group.valuesAllowed.map((v) => ({
                value: v.key,
                text: v.name,
            }));
        },
        save() {
            this.$store.dispatch("wise/saveAccount", this.buildPayload());
        },
        buildPayload() {
            // structure payload as expected by Wise...
            // using spread operator for payload to prevent it from copying hooks to Vuex
            // seems to have been causing updates outside of Vuex

            return {
                type: this.createAccountType,
                details: { ...this.payload },
            };
        },
    },
    components: {},
};
</script>