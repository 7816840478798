<template>
    <div>
        {{ searchUser.summary }}
    </div>
</template>

<script>
export default {
    props: ["searchUser"],
};
</script>