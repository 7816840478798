<template>
    <div>
        <audio ref="chime-on">
            <source
                src="https://d1lil0eivvt84o.cloudfront.net/sounds/on.mp3"
                type="audio/mp3"
            />
        </audio>
        <audio ref="chime-off">
            <source
                src="https://d1lil0eivvt84o.cloudfront.net/sounds/off.mp3"
                type="audio/mp3"
            />
        </audio>
        <audio ref="chime-new">
            <source
                src="https://d1lil0eivvt84o.cloudfront.net/sounds/new.mp3"
                type="audio/mp3"
            />
        </audio>
    </div>
</template>

<script>
export default {
    data() {
        return {
            unsubscribeToAction: null,
        };
    },
    mounted() {
        this.unsubscribeToAction = this.$store.subscribeAction({
            after: (action) => {
                if (action.type === "audioAlert/chime") {
                    this.playAlert(action.payload);
                }
            },
        });
    },
    methods: {
        playAlert(type) {
            const typeString = `chime-${type}`;

            if (typeString in this.$refs) {
                this.$refs[typeString].play();
            }
        },
    },
    beforeDestroy() {
        if (this.unsubscribeToAction) {
            this.unsubscribeToAction();
        }
    },
};
</script>