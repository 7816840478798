<template>
    <div>
        <div
            @click="viewProfile(user.standardMap)"
            :key="user.id"
            v-for="user in users"
            class="user-wrapper border-top border-light"
        >
            <user-standard-avatar
                :nonPrimaryPhotos="false"
                :hideBadges="true"
                :userBetweenStatus="true"
                :timestamp="user.standardMap.lastActive"
                :standardMap="user.standardMap"
                size="small"
            >
                <div class="d-flex align-items-center">
                    <div
                        v-if="user.balance"
                        class="d-flex align-items-center justify-content-center"
                    >
                        <font-awesome-icon
                            icon="fa-duotone fa-coin"
                            class="mr-1 text-warning"
                            size="lg"
                        />
                        <div class="text-warning">
                            {{ user.balance }}
                        </div>
                    </div>
                    <div v-if="user.hasActiveSubscription" class="ml-2">
                        <div class="branded">
                            <font-awesome-icon icon="fa-duotone fa-gem" />
                            {{ $t("Premium") }}
                        </div>
                    </div>
                </div>
            </user-standard-avatar>
        </div>

        <div v-if="loading">
            <div class="text-center py-5">
                <b-spinner variant="secondary" />
            </div>
        </div>
        <div
            v-else
            class="d-flex align-items-center justify-content-between my-3"
        >
            <div>
                <b-btn
                    @click="previousPage"
                    variant="light"
                    size="lg"
                    block
                    pill
                    class="p-3 border-0 d-flex align-items-center"
                    :disabled="page === 1"
                >
                    <font-awesome-icon icon="fa-light fa-arrow-left" />
                    <div class="ml-2 d-none d-md-block">
                        {{ $t("Previous") }}
                    </div>
                </b-btn>
            </div>
            <div class="text-center text-secondary">Page {{ page }}</div>
            <div>
                <b-btn
                    @click="nextPage"
                    variant="light"
                    size="lg"
                    block
                    pill
                    class="p-3 border-0 d-flex align-items-center"
                    :disabled="!hasMorePages"
                >
                    <div class="mr-2 d-none d-md-block">
                        {{ $t("Next") }}
                    </div>
                    <font-awesome-icon icon="fa-light fa-arrow-right" />
                </b-btn>
            </div>
        </div>
    </div>
</template>

<script>
import UserStandardAvatar from "@/components/user/UserStandardAvatar";

export default {
    data() {
        return {
            loading: false,
            users: null,
            page: 1,
            hasMorePages: false,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.loading = true;

            this.api
                .post("/invitedUser/active", {
                    page: this.page,
                })
                .then((response) => {
                    if (response?.data?.users) {
                        this.users = response.data.users;
                    }
                    if (response?.data?.hasMorePages) {
                        this.hasMorePages = response.data.hasMorePages;
                    }
                })
                .catch(() => {})
                .then(() => {
                    this.loading = false;
                });
        },
        nextPage() {
            this.hasMorePages = false;
            this.users = null;
            this.page++;
            this.refresh();
        },
        previousPage() {
            this.hasMorePages = false;
            this.users = null;
            this.page--;
            this.refresh();
        },
        setView(viewKey) {
            this.$store.dispatch("invitedUsers/setView", viewKey);
        },
        viewProfile(user) {
            this.$store.dispatch("profile/viewProfile", {
                userId: user.id,
                profile: user,
            });
        },
    },
    components: {
        UserStandardAvatar,
    },
};
</script>