<template>
    <generic-page>
        <back-to-settings />
        <h5>
            {{ $t("Creator Requests") }}
        </h5>
        <div v-if="creatorRequests">
            <div
                :key="creatorRequest.id"
                v-for="creatorRequest in creatorRequests"
                @click="
                    $router.push({
                        name: 'accountCreatorRequestView',
                        params: { id: creatorRequest.id },
                    })
                "
            >
                <creator-request-index-item :creatorRequest="creatorRequest" />
            </div>
        </div>
        <div v-else>
            <generic-loading />
        </div>

        <div
            v-if="showPagination"
            class="d-flex align-items-center justify-content-between p-3"
        >
            <div>
                <b-btn
                    @click="previous"
                    variant="light"
                    size="lg"
                    block
                    pill
                    class="p-3 border-0 d-flex align-items-center"
                    :disabled="page === 1"
                >
                    <font-awesome-icon icon="fa-light fa-arrow-left" />
                    <div class="ml-2 d-none d-md-block">
                        {{ $t("Previous") }}
                    </div>
                </b-btn>
            </div>
            <div class="text-center text-secondary">Page {{ page }}</div>
            <div>
                <b-btn
                    @click="next"
                    variant="light"
                    size="lg"
                    block
                    pill
                    class="p-3 border-0 d-flex align-items-center"
                    :disabled="!hasMorePages"
                >
                    <div class="mr-2 d-none d-md-block">
                        {{ $t("Next") }}
                    </div>
                    <font-awesome-icon icon="fa-light fa-arrow-right" />
                </b-btn>
            </div>
        </div>
    </generic-page>
</template>

<script>
import BackToSettings from "@/components/setting/utility/BackToSettings";
import CreatorRequestIndexItem from "@/components/creatorRequest/CreatorRequestIndexItem";

export default {
    data() {
        return {
            creatorRequests: null,
            hasMorePages: false,
            page: 1,
        };
    },
    created() {
        this.refresh();
    },
    computed: {
        showPagination() {
            if (!this.hasMorePages && this.page === 1) {
                return false;
            }

            return true;
        },
    },
    methods: {
        refresh() {
            this.creatorRequests = null;

            this.api
                .post("/creatorRequest/index", {
                    page: this.page,
                })
                .then((response) => {
                    this.creatorRequests = response.data.creatorRequests;
                    this.hasMorePages = response.data.hasMorePages;
                });
        },
        next() {
            this.page++;
            this.$scrollToTop();
            this.refresh();
        },
        previous() {
            this.page--;
            this.$scrollToTop();
            this.refresh();
        },
    },
    components: {
        BackToSettings,

        CreatorRequestIndexItem,
    },
};
</script>