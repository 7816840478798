<template>
    <div class="preserve">{{ content }}</div>
</template>

<style lang="scss" scoped>
.preserve {
    border: none;
    white-space: pre-wrap !important;
    word-break: break-word !important;
}
</style>

<script>
export default {
    props: ["content"],
};
</script>