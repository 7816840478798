/* global device  */

import router from "@/router";

const state = () => ({
    previousRoute: null,
    justPressedBackButton: false,
})

const mutations = {
    setPreviousRoute(state, value) {
        state.previousRoute = value
    },
    setJustPressedBackButton(state, value) {
        state.justPressedBackButton = value
    },
}

const actions = {
    closeOpenLayers({ rootState, commit, dispatch, state }) {
        // first condition is top-most layer
        if (rootState.interface.sendCreditsModalVisible) {
            commit('interface/setSendCreditsModalVisible', false, { root: true })
            return false;
        }

        if (rootState.interface.conversationTemplateModalVisible) {
            commit('interface/setConversationTemplateModalVisible', false, { root: true })
            return false;
        }

        if (rootState.interface.conversationFullSizeGalleryVisible) {
            commit('interface/setConversationFullSizeGalleryVisible', false, { root: true })
            return false;
        }

        if (rootState.interface.profileFullSizeGalleryVisible) {
            commit('interface/setProfileFullSizeGalleryVisible', false, { root: true })
            return false;
        }

        if (rootState.interface.profilePrivateGalleryVisible) {
            commit('interface/setProfilePrivateGalleryVisible', false, { root: true })
            return false;
        }

        if (rootState.interface.postPhotoModalVisible) {
            commit('interface/setPostPhotoModalVisible', false, { root: true })
            return false;
        }

        // MUST BE LAST
        // since it returns true, this signals that its safe to navigate
        // temporary until profile is a real URL somehow
        if (rootState.interface.profileModalVisible) {
            dispatch('profile/close', null, { root: true })
            return true;
        }


        if (rootState.system.cordova && device.platform === 'Android') {
            if (state.justPressedBackButton) {
                if (navigator.app) {
                    navigator.app.exitApp();
                } else if (navigator.device) {
                    navigator.device.exitApp();
                }
            }

            setTimeout(() => {
                commit('setJustPressedBackButton', false)
            }, 1000)

            commit('setJustPressedBackButton', true)
        }

        return true
    },
    home() {
        router.push('/')
    },
}

const getters = {

}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
