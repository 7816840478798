<template>
    <div>
        <search-navigation />

        <div v-if="canViewVideos">
            <video-index />
        </div>
        <div v-else class="p-3">
            <b-alert variant="warning" show>
                {{
                    $t(
                        "You need at least one approved public video to view other videos, or upgrade to premium to view now."
                    )
                }}
            </b-alert>

            <b-btn @click="uploadVideo" variant="primary" size="lg" block pill>
                {{ $t("Upload a Video") }}
            </b-btn>

            <div class="py-3 text-center">
                {{ $t("or") }}
            </div>

            <b-btn
                @click="subscriptionStart"
                variant="light"
                class="btn-brand p-3 border-primary"
                block
            >
                <div class="branded">
                    <font-awesome-icon icon="fa-duotone fa-gem" size="3x" />
                    <h5>
                        {{ $t("Upgrade to View") }}
                    </h5>
                </div>
                <div class="small">
                    {{
                        $t(
                            "You can remove this limitating by upgrading to premium"
                        )
                    }}
                </div>
            </b-btn>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

import SearchNavigation from "@/components/widget/search/SearchNavigation";
import VideoIndex from "@/components/videoIndex/VideoIndex";

export default {
    methods: {
        uploadVideo() {
            this.$store.dispatch("setting/editOnboardingStep", "video");
        },
        subscriptionStart() {
            this.$store.dispatch("premium/upgrade", {
                source: "upload-video-bypass",
            });
        },
    },
    computed: {
        canViewVideos() {
            if (this.premium) {
                return true;
            }

            if (
                this.onboardingUser.videos.filter((video) => video.approved)
                    .length > 0
            ) {
                return true;
            }

            return false;
        },
        ...mapState("user", ["premium"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        VideoIndex,
        SearchNavigation,
    },
};
</script>
        
        