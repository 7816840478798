<template>
    <div
        v-if="displayPrimary"
        class="px-2 fake-link position-relative"
        @click="managePhoto(creatorPost.primary)"
    >
        <span
            v-if="!creatorPost.primary.approved"
            class="private-wrapper position-absolute absolute-center text-warning text-center small rounded p-4"
        >
            <font-awesome-icon icon="fa-duotone fa-lock" size="5x" />
            <h3 class="my-3">
                {{ $t("Private") }}
            </h3>
            <div>
                {{ $t("Purchase to view") }}
            </div>
        </span>

        <img class="img-fluid" :src="creatorPost.primary.squareUrl" />
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        displayPrimary() {
            return (
                !this.previewPost &&
                !this.creatorPost?.gif &&
                this.creatorPost?.primary &&
                !this.managingPhoto
            );
        },
        ...mapState("creatorPostEdit", [
            "creatorPost",
            "previewPost",
            "managingPhoto",
        ]),
    },
    methods: {
        managePhoto(photo) {
            this.$store.dispatch("creatorPostEdit/managePhoto", photo);
        },
    },
    components: {},
};
</script>