<template>
    <div>
        <div class="text-right">
            <b-btn
                @click="closeModal"
                variant="light"
                class="action-button glass border-0"
            >
                <font-awesome-icon size="lg" icon="fa-light fa-times" />
            </b-btn>
        </div>
        <div class="text-center py-3">
            <div class="text-primary py-3">
                <font-awesome-icon size="5x" icon="fa-duotone fa-video-plus" />
            </div>

            <h3 class="mb-0">
                {{ $t("Add a Profile Video") }}
            </h3>

            <div class="mt-3">
                {{
                    $t(
                        "Add a video to your profile to show people you are legit, and entice them to make the first move."
                    )
                }}
            </div>

            <b-row class="mt-4">
                <b-col cols="12" md="6" class="my-2">
                    <b-btn
                        variant="primary"
                        @click="addVideo"
                        pill
                        block
                        size="lg"
                    >
                        {{ $t("Add a video") }}
                    </b-btn>
                </b-col>
                <b-col cols="12" md="6" class="my-2">
                    <b-btn
                        variant="secondary"
                        @click="remindMeLater"
                        pill
                        block
                        size="lg"
                    >
                        {{ $t("Remind me later") }}
                    </b-btn>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        currentOffer() {
            return this.$store.state.displayOffer?.currentOffer;
        },
    },
    methods: {
        addVideo() {
            this.$store.dispatch("setting/editOnboardingStep", "video");
            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
        closeModal() {
            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
        remindMeLater() {
            this.$store.commit("interface/setDisplayOfferModalVisible", false);

            this.api.post("/offer/removeViewedOffer", {
                offer: this.currentOffer.offer,
            });
        },
    },
};
</script>