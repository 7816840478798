<template>
    <div>
        <h5 class="mt-3 text-primary">
            <font-awesome-icon icon="fa-brands fa-paypal" />
            {{ $t("PayPal") }}
        </h5>

        <div v-if="withdraw.paypalSent" class="my-3">
            <b-alert variant="warning" show>
                <h5>
                    {{ $t("Please confirm") }}
                </h5>
                <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
                {{ $t("The amount must be exactly") }}
                {{ withdraw.paypalRequestAmount }}
                {{ $t("or your withdraw may be delayed") }}
            </b-alert>

            <b-btn
                @click="submit"
                :disabled="submitting"
                variant="primary"
                size="lg"
                class="mt-3"
                pill
                block
            >
                {{ $t("Submit Withdrawal") }}
            </b-btn>
        </div>
        <div v-else>
            <b-alert variant="primary" show class="small">
                <font-awesome-icon icon="fa-duotone fa-info-circle" />
                {{
                    $t(
                        "PayPal does not allow businesses like ours to create an account, but uou can send us a request from the PayPal app to the email below, and we can pay it as a guest"
                    )
                }}
            </b-alert>

            <div class="d-flex align-items-center mt-3">
                <div class="circle-list mr-2">1</div>
                <h5 class="mb-0">Open PayPal App</h5>
            </div>

            <div class="d-flex align-items-center mt-3">
                <div class="circle-list mr-2">2</div>
                <h5 class="mb-0">Create a Payment Request</h5>
            </div>
            <div class="small text-primary">
                Look for the Request Money option under "Money" tab
            </div>

            <div class="d-flex align-items-center mt-3">
                <div class="circle-list mr-2">3</div>
                <h5 class="mb-0">Set the Amount</h5>
            </div>

            <div class="bg-light p-3 border rounded">
                <h1 class="text-primary mb-0">
                    {{ withdraw.paypalRequestAmount }}
                </h1>
                <div class="small text-primary">
                    Make sure to use US Dollars (USD)
                </div>
            </div>

            <div class="d-flex align-items-center mt-3">
                <div class="circle-list mr-2">4</div>
                <h5 class="mb-0">Set the Email Address</h5>
            </div>
            <div class="bg-light p-3 border rounded">
                <h4 class="text-primary">
                    {{ email }}
                </h4>
                <div>
                    <b-btn
                        v-if="copied"
                        variant="light"
                        class="border"
                        pill
                        size="sm"
                    >
                        <font-awesome-icon
                            class="mr-1"
                            icon="fa-light fa-check"
                        />
                        {{ $t("Copied") }}
                    </b-btn>
                    <b-btn
                        v-else
                        @click="copyEmail"
                        variant="primary"
                        pill
                        class="border"
                        size="sm"
                    >
                        <font-awesome-icon
                            class="mr-1"
                            icon="fa-duotone fa-copy"
                        />
                        {{ $t("Copy Email") }}
                    </b-btn>
                </div>
            </div>

            <div class="d-flex align-items-center mt-3">
                <div class="circle-list mr-2">5</div>
                <h5 class="mb-0">Confirm you have sent the request</h5>
            </div>

            <b-btn variant="link" class="p-0 mt-3" @click="sentRequest">
                {{ $t("I have sent the request for ") }}
                {{ withdraw.paypalRequestAmount }}
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import copy from "clipboard-copy";

export default {
    data() {
        return {
            copied: null,
            error: null,
            email: "support@findmatenetwork.com",
        };
    },
    computed: {
        ...mapState("withdraw", ["withdraw", "submitting"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        copyEmail() {
            this.copied = true;
            copy(this.email);
        },
        cancelWithdraw() {
            this.$store.dispatch("withdraw/cancel");
        },
        usePreviousAddress() {
            this.btcAddress = this.onboardingUser.btcAddress;
            this.save();
        },
        sentRequest() {
            this.api
                .post("/withdraw/paypalSent", {
                    withdrawId: this.withdraw.id,
                })
                .then((response) => {
                    if (response?.data?.withdraw) {
                        this.$store.dispatch(
                            "withdraw/setWithdraw",
                            response.data.withdraw
                        );
                    }
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error = error?.response?.data?.message;
                    }
                });
        },
        submit() {
            this.$store.dispatch("withdraw/submit");
        },
    },
    components: {},
};
</script>