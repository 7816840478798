<template>
    <div>
        <b-dropdown
            v-if="creatorPost.editable"
            variant="light"
            toggle-class="rounded-pill"
            right
            ref="dropdown"
        >
            <template #button-content>
                <font-awesome-icon icon="fa-duotone fa-calendar-alt" />
                <span v-if="creatorPost.publishDateTerse">
                    {{ creatorPost.publishDateTerse }}
                </span>
                <span v-else>
                    {{ $t("Now") }}
                </span>
            </template>

            <b-dropdown-header>
                {{ $t("Schedule Post") }}
            </b-dropdown-header>
            <b-dropdown-form>
                <b-calendar
                    @selected="setDate"
                    v-model="publishedAt"
                    value-as-date
                    :min="minDate"
                    :max="maxDate"
                ></b-calendar>
            </b-dropdown-form>
        </b-dropdown>
        <b-btn v-else disabled variant="light" pill>
            <font-awesome-icon icon="fa-duotone fa-calendar-alt" />
            {{ creatorPost.publishDateTerse }}
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            publishedAt: null,
        };
    },
    computed: {
        minDate() {
            return new Date();
        },
        maxDate() {
            const now = new Date();

            const today = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate()
            );

            const maxDate = new Date(today);

            maxDate.setMonth(maxDate.getMonth() + 1);

            return maxDate;
        },
        ...mapState("creatorPostEdit", ["creatorPost"]),
    },
    methods: {
        setDate() {
            this.api
                .post("/creatorPost/updatePublishedAt", {
                    creatorPostId: this.creatorPost.id,
                    publishedAt: this.publishedAt,
                })
                .then((response) => {
                    if (response?.data?.creatorPost) {
                        this.$store.commit(
                            "creatorPostEdit/setCreatorPost",
                            response.data.creatorPost
                        );
                    }
                });

            this.$refs.dropdown.hide(true);
        },
    },
};
</script>