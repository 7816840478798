import api from "@/service/api";

const state = () => ({
    viewingSubscriber: null,
    creatorSubscribers: null,
})

const mutations = {
    reset(state) {
        state.error = null
        state.creatorSubscribers = null
        state.viewingSubscriber = null
    },
    setCreatorSubscribers(state, value) {
        state.creatorSubscribers = value;
    },
    setViewingSubscriber(state, value) {
        state.viewingSubscriber = value;
    },
    setError(state, value) {
        state.error = value
    },
}

const actions = {
    refresh({ commit }) {
        api.post('/creatorSubscriber/index').then((response) => {
            if (response?.data?.creatorSubscribers) {
                commit('setCreatorSubscribers', response.data.creatorSubscribers)
            }
        });
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
