import api from "@/service/api";

const state = () => ({
    photosToRate: null,
    currentPhotoToRate: null,
    upvotedPhotoIds: [],
    downvotedPhotoIds: [],
    finishedInitialLoad: false,
    currentSlidePosition: null,
    reachedTheEnd: false,
})


const mutations = {
    setCurrentPhotoToRate(state, photo) {
        state.currentPhotoToRate = photo
    },
    setPhotosToRate(state, photos) {
        state.photosToRate = photos
    },
    setReachedTheEnd(state, value) {
        state.reachedTheEnd = value
    },
    appendUpvotedPhotoId(state, userPhotoId) {
        state.upvotedPhotoIds.push(userPhotoId)
        state.downvotedPhotoIds = state.downvotedPhotoIds.filter(id => id !== userPhotoId)
    },
    appendDownvotedPhotoId(state, userPhotoId) {
        state.downvotedPhotoIds.push(userPhotoId)
        state.upvotedPhotoIds = state.upvotedPhotoIds.filter(id => id !== userPhotoId)
    },
    setFinishedInitialLoad(state) {
        state.finishedInitialLoad = true
    },
    setCurrentSlidePosition(state, value) {
        state.currentSlidePosition = value
    },
}

const actions = {
    refresh({ commit, dispatch }) {
        return new Promise(resolve => {
            api.post("/photoIndex/swipe").then((response) => {
                if (response?.data?.photos?.length) {
                    commit('setPhotosToRate', response.data.photos)
                    dispatch('setFinishedInitialLoad')
                } else {
                    commit('setPhotosToRate', response.data.photos)
                    commit('setReachedTheEnd', true)
                    dispatch('setFinishedInitialLoad')
                }

                resolve()
            });
        })
    },
    upvote({ state, commit }) {
        if (!state.currentPhotoToRate) {
            console.error('no current photo, bailing')
            return;
        }

        commit('appendUpvotedPhotoId', state.currentPhotoToRate.photoDefaultMap.id)

        this._vm.$nextTick(() => {
            api.post("/photoRating/rate", {
                userPhotoId: state.currentPhotoToRate.photoDefaultMap.id,
                rating: 1
            });
        });
    },
    downvote({ state, commit }) {
        if (!state.currentPhotoToRate) {
            console.error('no current photo, bailing')
            return;
        }

        commit('appendDownvotedPhotoId', state.currentPhotoToRate.photoDefaultMap.id)

        this._vm.$nextTick(() => {
            api.post("/photoRating/rate", {
                userPhotoId: state.currentPhotoToRate.photoDefaultMap.id,
                rating: 0
            });
        });
    },
    setCurrentSlidePosition({ commit, state }, currentSlidePosition) {
        commit('setCurrentSlidePosition', currentSlidePosition)

        // on initial load swiper will almost always load first
        if (state.photosToRate?.[currentSlidePosition]) {
            commit('setCurrentPhotoToRate', state.photosToRate?.[currentSlidePosition])
        }
    },
    setFinishedInitialLoad({ state, commit }) {
        if (state.currentSlidePosition !== null && state.photosToRate?.[state.currentSlidePosition]) {
            commit('setCurrentPhotoToRate', state.photosToRate?.[state.currentSlidePosition])
        }

        commit('setFinishedInitialLoad')
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
