/* global cordova */

export default {
    methods: {
        openBrowser(url) {
            if (this.$store.state.system.cordova) {
                cordova.InAppBrowser.open(
                    url,
                    "_system",
                    "hidden=yes,location=no"
                );
            } else {
                window.open(url, "_blank");
            }
        }
    }
}