<template>
    <div>
        <b-alert variant="light" show class="p-2">
            <h5>
                {{ $t("Share your post") }}
            </h5>

            <b-input-group class="my-1">
                <b-form-input
                    size="sm"
                    :value="creatorPost.urlVanity"
                    readonly
                />
                <b-input-group-append>
                    <b-btn @click="copyUrl" variant="primary" size="sm">
                        {{ $t("Copy") }}
                    </b-btn>
                </b-input-group-append>
            </b-input-group>

            <div class="small">
                <div v-if="hasCopied" class="text-primary">
                    <font-awesome-icon icon="fa-light fa-check" />
                    {{
                        $t(
                            "URL has been copied, now share your URL on social media, chat apps to earn rewards!"
                        )
                    }}
                </div>
            </div>
        </b-alert>
    </div>
</template>

<script>
import { mapState } from "vuex";

import copy from "clipboard-copy";

export default {
    data() {
        return {
            hasCopied: false,
        };
    },
    created() {
        this.$store.dispatch("creatorDashboard/refresh");
    },
    computed: {
        ...mapState("creatorPost", ["creatorPost"]),
        ...mapState("creatorDashboard", ["creatorUser"]),
    },
    methods: {
        copyUrl() {
            copy(this.creatorPost.url);
            this.hasCopied = true;
        },
    },
    components: {},
};
</script>