<template>
    <div class="text-right">
        <b-dropdown
            v-if="random"
            variant="light"
            menu-class="pre-scrollable"
            toggle-class="btn-brand rounded-pill"
            ref="dropdown"
        >
            <template #button-content>
                <span v-if="comment && comment.reactionCssClass">
                    <i class="oma oma-2x" :class="comment.reactionCssClass"></i>
                </span>
                <span v-else>
                    {{ buttonLabel }}
                </span>
            </template>
            <div class="reactions">
                <div
                    @click="selectReaction(reaction.id)"
                    class="reaction fake-link"
                    :key="reaction.id"
                    v-for="reaction in creatorReactions"
                >
                    <i class="oma oma-2x" :class="reaction.cssClass"></i>
                </div>
            </div>
        </b-dropdown>
    </div>
</template>

<style lang="scss" scoped>
.randomoji {
    height: 32px;
    width: 32px;
}
.reactions {
    width: 90vw;
    max-width: 400px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10%, 1fr));

    .reaction {
        height: 40px;
        width: 40px;
        padding: 2px;

        &:hover {
            box-shadow: 0 0 0.5rem rgba(0, 123, 255, 0.333) !important;
        }
    }
}
</style>

<script>
import { mapState } from "vuex";

import { getRandomArbitrary } from "@/utility/random";

export default {
    data() {
        return {
            random: null,
            buttonLabel:
                this.mode === "bulk"
                    ? this.$t("React to All")
                    : this.$t("React"),
        };
    },
    mounted() {
        this.random = this.randomoji();
    },
    computed: {
        ...mapState("options", ["creatorReactions"]),
        showReactionPrompt() {
            if (!this.hasReacted) {
                return true;
            }

            return false;
        },
        hasReacted() {
            return false;
        },
    },
    methods: {
        selectReaction(reactionId) {
            this.$refs.dropdown.hide(true);

            if (this.mode === "bulk") {
                this.$store.dispatch("postComments/reactToAll", {
                    creatorPostId: this.creatorPost.id,
                    reactionId,
                });
            } else {
                this.$store.dispatch("postComments/react", {
                    commentId: this.comment.id,
                    reactionId,
                });
            }
        },
        randomoji() {
            const random = getRandomArbitrary(
                0,
                this.creatorReactions.length - 1
            );
            return this.creatorReactions[random];
        },
    },
    props: ["mode", "creatorPost", "comment"],
};
</script>
