<template>
    <div>
        <h3>Getting Paid to Share</h3>

        Earn cash rewards by sharing your link on your public social accounts

        <h5 class="text-primary mt-3">Share your invite link</h5>

        Start your paid sharing by sharing a link to your social page, for
        example your Instagram page

        <h5 class="text-primary mt-3">Provide us verification</h5>

        After you share your link, let us know the public URL where you posted
        it so our team can verify your invite link has been shared

        <h5 class="text-primary mt-3">Wait for verification</h5>

        We will verify the link, as long as we are able to get to the
        verification URL you send to us and we can see the post is up you will
        be paid for your share, you can see the status in your paid sharing tab
        anytime

        <h5 class="text-primary mt-3">Get a bonus reward</h5>

        In some cases, we will provide you a bonus reward if your link is up for
        a certain period of time and you will get an extra reward in credits
        depending on the link. The higher quality the page its posted to, the
        higher chance of getting a bonus

        <h5 class="text-danger mt-3">
            <font-awesome-icon class="text-danger" icon="fa-solid fa-ban" />
            No fake, dead, or inactive accounts
        </h5>

        Only active social accounts will earn the reward. If you create a brand
        new page to share it on, or the page seems inactive, you will not be
        eligible for the reward

        <b-btn
            @click="finishedPaidShare"
            variant="primary"
            pill
            block
            size="lg"
            class="mt-3"
        >
            Go to Paid Sharing
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("creatorGuide", ["currentGuide", "guides"]),
        modal: {
            get() {
                return this.$store.state.creatorGuide.currentGuide
                    ? true
                    : false;
            },
            set(value) {
                if (!value) {
                    this.$store.commit("creatorGuide/reset");
                }
            },
        },
    },
    methods: {
        finishedPaidShare() {
            this.api.post("/creatorGuide/finishedPaidShare").then(() => {
                this.$store.commit("creatorGuide/reset");
                this.$store.dispatch("onboarding/status");
                this.$router.push({ name: "creatorPaidShare" });
            });
        },
    },
    components: {},
};
</script>


