<template>
    <div id="profile-header" class="border-bottom bg-white">
        <div v-if="profile" class="d-flex align-items-center pr-2 py-2 pl-0">
            <div class="ml-2 mr-2 d-flex align-items-center">
                <div class="position-relative">
                    <img
                        class="img-fluid rounded shadow-sm border"
                        :src="profile.photo"
                    />
                    <div class="position-absolute online-status-wrapper">
                        <user-online-status
                            size="small"
                            :standardMap="profile"
                        />
                    </div>
                </div>
            </div>
            <div class="text-truncate">
                <h5 class="text-truncate mb-0 d-flex align-items-center">
                    <user-primary-badge class="mr-1" :standardMap="profile" />
                    {{ profile.name }}
                </h5>
                <div class="small">
                    <div
                        v-if="typing.includes(profile.id)"
                        class="text-success"
                    >
                        {{ $t("Typing...") }}
                    </div>
                    <div
                        v-else-if="profile.accountManager"
                        class="text-success"
                    >
                        Account Manager
                    </div>
                    <div v-else-if="profile.location">
                        <div class="d-flex align-items-center">
                            <div class="mr-2">
                                <img :src="profile.flagUrl" />
                                {{ profile.country }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ml-auto d-flex">
                <b-dropdown
                    variant="light"
                    right
                    class="mr-2"
                    size="lg"
                    v-if="!isMyProfile"
                    toggle-class="action-button"
                >
                    <b-dropdown-item
                        @click="$store.dispatch('report/user', profile)"
                    >
                        {{ $t("Report User") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="$store.dispatch('user/block', profile.id)"
                    >
                        {{ $t("Block User") }}
                    </b-dropdown-item>
                    <b-dropdown-divider
                        v-if="viewingConversation"
                    ></b-dropdown-divider>
                    <b-dropdown-item
                        v-if="viewingConversation"
                        @click="deleteConversation"
                        variant="danger"
                    >
                        {{ $t("Delete Conversation") }}
                    </b-dropdown-item>
                </b-dropdown>

                <b-btn
                    class="action-button"
                    @click="closeProfile"
                    variant="light"
                >
                    <font-awesome-icon size="lg" icon="fa-light fa-times" />
                </b-btn>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.online-status-wrapper {
    bottom: 2px;
    right: 2px;
}
</style>

<script>
import { mapGetters, mapState } from "vuex";

import UserOnlineStatus from "@/components/user/UserOnlineStatus";
import UserPrimaryBadge from "@/components/user/UserPrimaryBadge";

export default {
    computed: {
        viewingConversation() {
            return this.view === "conversation";
        },
        ...mapState("message", ["typing"]),
        ...mapState("profile", ["profile", "view"]),
        ...mapGetters("profile", ["isMyProfile"]),
    },
    methods: {
        closeProfile() {
            this.$store.dispatch("profile/close");
        },
        deleteConversation() {
            this.api
                .post("/message/deleteConversation", {
                    userId: this.profile.id,
                })
                .then(() => {
                    this.$store.commit(
                        "message/deleteConversation",
                        this.profile.id
                    );

                    this.$store.dispatch("profile/close");
                })
                .catch(() => {});
        },
    },
    components: {
        UserOnlineStatus,
        UserPrimaryBadge,
    },
};
</script>