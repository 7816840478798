<template>
    <b-btn
        to="/creator/livestream"
        class="bg-white"
        variant="light"
        block
        size="lg"
    >
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="mr-2">
                    <font-awesome-icon
                        class="small-avatar branded"
                        icon="fa-duotone fa-signal-stream"
                        size="lg"
                    />
                </div>
                {{ $t("Livestream") }}
            </div>

            <div class="small">
                <b-badge pill variant="light">
                    <font-awesome-icon
                        icon="fa-duotone fa-coin"
                        class="text-warning"
                        size="lg"
                    />
                    {{ prices.livestream }}/minute
                </b-badge>
            </div>
        </div>
    </b-btn>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        noRecentPostWarning() {
            return this.dashboard && !this.dashboard.hasRecentPost;
        },
        ...mapState("options", ["prices"]),
        ...mapState("creatorDashboard", ["dashboard"]),
    },
};
</script>