<template>
    <div>
        <transition-group name="list">
            <div
                :key="creatorLivestreamInteraction.id"
                v-for="creatorLivestreamInteraction in creatorLivestreamInteractions"
            >
                <livestream-interaction-item
                    :creatorLivestreamInteraction="creatorLivestreamInteraction"
                />
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapState } from "vuex";

import LivestreamInteractionItem from "@/components/livestream/interaction/LivestreamInteractionItem";

export default {
    mounted() {
        this.$store.dispatch("livestream/refreshInteractions");
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("livestream", [
            "creatorLivestream",
            "creatorLivestreamInteractions",
            "standardMap",
            "agora",
        ]),
    },
    components: {
        LivestreamInteractionItem,
    },
};
</script>