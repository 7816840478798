<template>
    <div>
        <div class="story bg-white" :class="{ 'p-3': includePaddingBody }">
            <div v-if="isLoggedIn && canViewPost(creatorPost)">
                <view-post-actions :creatorPost="creatorPost" />
            </div>
            <div v-if="creatorPost.message" class="story-content mt-2">
                <preserve-content-spacing :content="creatorPost.message" />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.blurredText {
    filter: blur(10px);
}
</style>

<script>
import { FEED_TYPE_CREATOR_POST, getFeedKey } from "@/constant/feed";
import { mapGetters, mapState } from "vuex";

import ViewPostActions from "@/components/post/view/ViewPostActions";
import PreserveContentSpacing from "@/components/utility/PreserveContentSpacing";

export default {
    computed: {
        includePaddingBody() {
            if (
                this.creatorPost.message ||
                this.canViewPost(this.creatorPost)
            ) {
                return true;
            }

            return false;
        },
        showSubscribe() {
            if (
                !this.subscribedToCreatorUserId(this.creatorPost.userId) &&
                this.canSubscribeToUserId(this.creatorPost.userId)
            ) {
                return true;
            }

            return false;
        },
        canViewStory() {
            if (this.subscribedToCreatorUserId(this.creatorPost.userId)) {
                return true;
            }

            if (this.creatorPost.private) {
                return false;
            }

            return true;
        },
        ...mapState("user", ["premium"]),
        ...mapGetters("userSubscription", [
            "subscribedToCreatorUserId",
            "canViewPost",
            "canSubscribeToUserId",
        ]),
        ...mapGetters("user", ["isLoggedIn"]),
    },
    methods: {
        subscribeCreator() {
            this.$store.dispatch("userSubscription/fromPost", this.creatorPost);
        },
        focusComment(creatorPost) {
            this.$store.commit(
                "comment/appendFocusedCommentableItem",
                getFeedKey({
                    id: creatorPost.id,
                    type: FEED_TYPE_CREATOR_POST,
                })
            );
        },
    },
    components: {
        ViewPostActions,
        PreserveContentSpacing,
    },
    props: ["creatorPost"],
};
</script>