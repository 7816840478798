<template>
    <div v-if="showGuaranteedReplies">
        <b-alert class="mb-0 rounded-0 border-0" show variant="primary">
            <div class="d-flex alignn-items-center justify-content-between">
                <h5 class="mb-0">
                    {{ $t("Guaranteed Replies") }}
                </h5>
                <div>
                    <b-btn
                        @click="dismissMessage"
                        variant="link"
                        size="lg"
                        class="p-0"
                    >
                        <font-awesome-icon
                            icon="fa-light fa-times"
                            class="mr-1"
                        />
                    </b-btn>
                </div>
            </div>
            <div class="small">
                {{
                    $t(
                        "Never pay for an unread message again - if you don't get a reply within 24 hours check back here to get an instant refund directly back to your wallet"
                    )
                }}
            </div>
        </b-alert>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        showGuaranteedReplies() {
            // doesnt make sense now, defaults to off...
            return false;

            // if (
            //     !this.onboardingUser.creator &&
            //     !this.onboardingUser.hideGuaranteedReplyExplainer &&
            //     !this.loadingMessages &&
            //     !this.hasMessages
            // ) {
            //     return true;
            // }

            // return false;
        },
        ...mapGetters("conversation", ["hasMessages"]),
        ...mapState("conversation", ["loadingMessages"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        dismissMessage() {
            this.api
                .post("/onboarding/hideGuaranteedReplyExplainer")
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                });
        },
    },
    components: {},
};
</script>