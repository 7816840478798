<template>
    <div class="bg-light">
        <div v-if="cordova">
            <cordova-components />
        </div>
        <always-mounted-components />
        <notification-center />
        <report-user />
        <edit-settings-modal />
        <mobile-header>
            <div>
                <b-btn
                    @click="openMessageInbox"
                    variant="light"
                    class="rounded-0 border-0 bg-white position-relative"
                >
                    <b-badge
                        style="right: 0"
                        v-if="unreadConversationCount"
                        variant="danger"
                        class="position-absolute"
                        pill
                    >
                        {{ unreadConversationCount }}
                    </b-badge>
                    <font-awesome-icon
                        v-if="unreadConversationCount"
                        icon="fa-duotone fa-envelope"
                        size="2x"
                        class="text-danger"
                    />
                    <font-awesome-icon
                        v-else
                        icon="fa-duotone fa-envelope"
                        size="2x"
                    />
                </b-btn>
            </div>
            <div>
                <brand-link />
            </div>
            <div>
                <b-btn
                    v-b-toggle.right-sidebar
                    variant="light"
                    class="rounded-0 mr-1 bg-white border-0 position-relative"
                >
                    <b-badge
                        style="right: 0"
                        v-if="unreadActivityCount"
                        variant="danger"
                        class="position-absolute"
                        pill
                    >
                        {{ unreadActivityCount }}
                    </b-badge>
                    <font-awesome-icon
                        :class="{ 'text-danger': unreadActivityCount }"
                        icon="fa-duotone fa-bell"
                        size="2x"
                    />
                </b-btn>
            </div>
        </mobile-header>
        <div
            v-if="$screen.lg"
            id="left-sidebar"
            class="hide-scrollbars shadow-sm"
            ref="messageIndex"
        >
            <left-sidebar />
        </div>

        <div id="main" class="d-block">
            <div class="sticky-top">
                <primary-navigation />
            </div>
            <router-view></router-view>
        </div>
        <div
            v-if="$screen.lg"
            id="right-sidebar"
            class="hide-scrollbars shadow-sm"
        >
            <right-sidebar />
        </div>
        <div v-if="!$screen.lg">
            <b-sidebar
                v-model="rightSidebarVisible"
                id="right-sidebar"
                right
                backdrop
                shadow
                no-header
            >
                <right-sidebar />
            </b-sidebar>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.unread-count {
    background-color: var(--primary);
    font-size: 0.6rem;
    color: #fff;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 19px;
    width: 19px;
    position: absolute;
    top: 0;
    right: 0;
}
</style>


<script>
import { mapGetters, mapState } from "vuex";

import LeftSidebar from "@/components/LeftSidebar";
import RightSidebar from "@/components/RightSidebar";
import NotificationCenter from "@/components/notification/NotificationCenter";
import ReportUser from "@/components/profile/ReportUser";
import EditSettingsModal from "@/components/setting/EditSettingsModal";
import CordovaComponents from "@/components/cordova/CordovaComponents";
import MobileHeader from "@/components/layout/MobileHeader";
import BrandLink from "@/components/utility/BrandLink";
import AlwaysMountedComponents from "@/components/always/AlwaysMountedComponents";
import PrimaryNavigation from "@/components/navigation/PrimaryNavigation";

export default {
    data() {
        return {
            unsubscribeToMutations: null,
        };
    },
    created() {
        // default route
        if (this.$route.name === "fallback") {
            const defaultRoute = this.$router
                .getRoutes()
                .find((route) => route.meta?.default);

            if (defaultRoute) {
                this.$router.push(defaultRoute);
            }
        }

        // social app defaults
        document.body.classList.remove("keep-padding");

        this.unsubscribeToMutations = this.$store.subscribe((mutation) => {
            if (mutation.type === "message/setView") {
                this.$nextTick(() => {
                    if (this.$refs.messageIndex) {
                        this.$refs.messageIndex.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "auto",
                        });
                    }
                });
            }
        });
    },
    beforeDestroy() {
        if (this.unsubscribeToMutations) {
            this.unsubscribeToMutations();
        }
    },
    computed: {
        ...mapState("navigation", ["view"]),
        ...mapState("system", ["finishedInitialLoad", "cordova"]),
        ...mapGetters("activity", ["unreadActivityCount"]),
        ...mapGetters("message", ["unreadConversationCount"]),
        leftSidebarVisible: {
            get() {
                return this.$store.state.interface.leftSidebarVisible;
            },
            set(value) {
                this.$store.commit("interface/setLeftSidebarVisible", value);
            },
        },
        rightSidebarVisible: {
            get() {
                return this.$store.state.interface.rightSidebarVisible;
            },
            set(value) {
                this.$store.commit("interface/setRightSidebarVisible", value);
            },
        },
    },
    methods: {
        openMessageInbox() {
            this.$store.commit("interface/setMessageInboxModalVisible", true);
        },
    },
    components: {
        LeftSidebar,
        RightSidebar,
        BrandLink,
        NotificationCenter,
        MobileHeader,
        ReportUser,
        EditSettingsModal,
        CordovaComponents,
        AlwaysMountedComponents,
        PrimaryNavigation,
    },
};
</script>
        