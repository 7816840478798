<template>
    <div class="w-100">
        <b-alert variant="warning" show class="text-left">
            <strong>
                {{ onboardingUser.invitesRequired }}
                {{ $t("verified male invites required for entry") }}
            </strong>

            <b-button
                v-b-toggle.howThisWorks
                variant="light"
                class="border mt-2"
                size="sm"
                pill
            >
                {{ $t("How this works?") }}
            </b-button>
            <b-collapse id="howThisWorks">
                <div class="mt-3 small">
                    {{
                        $t(
                            "Invite new guys into the app who would be interested in using it, once the guy purchases credits, the number will be reduced and once it hits zero you will be allowed back in"
                        )
                    }}
                </div>
                <div class="mt-3 small">
                    {{
                        $t(
                            "Fake invites will not be allowed - for example you can't just invite your relative who will buy credits, it should be a new customer who would be actually interested in the app"
                        )
                    }}
                </div>
                <div class="mt-3 small">
                    {{
                        $t(
                            "Read the invite guide for guys carefully, only qualified invites from Tier 1/2 countries will be accepted and rewarded towards your required invites"
                        )
                    }}
                </div>
            </b-collapse>
        </b-alert>

        <b-table-simple v-if="balance" bordered class="mt-2">
            <tbody>
                <tr>
                    <td class="w-66">
                        {{ $t("Credits") }}
                    </td>
                    <td class="bg-white">
                        {{ balance }}
                    </td>
                </tr>
                <tr>
                    <td>
                        {{ onboardingUser.currency }}
                        {{ $t("Balance") }}
                    </td>
                    <td class="bg-white">
                        <span class="text-success font-weight-bold">
                            {{ balanceLocal }}
                            {{ onboardingUser.currency }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </b-table-simple>

        <creator-invite-url />
    </div>
</template>


<script>
import { mapState } from "vuex";

import CreatorInviteUrl from "@/components/creator/invite/CreatorInviteUrl";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        balance() {
            return this.onboardingUser?.wallet?.balance;
        },
        balanceLocal() {
            return this.onboardingUser?.wallet?.balanceLocal;
        },
    },
    components: {
        CreatorInviteUrl,
    },
};
</script>
