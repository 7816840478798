import api from "@/service/api";

const updateOldestMessageId = (state) => {
    if (state.messages.length) {
        state.oldestMessageId = [...state.messages].sort((a, b) => a.id - b.id)[0].id
    }
}

const state = () => ({
    conversationHasMoreMessages: false,
    openConversationUserId: null,
    draft: null,
    messages: null,
    pending: null,
    unlocked: null,
    oldestMessageId: null,
    currentConverstionPendingMarkSeen: false,
    messageError: null,
    loadingMessages: null,
    totalEarnings: null,
})

const mutations = {
    reset(state) {
        state.currentConverstionPendingMarkSeen = false
        state.messages = null;
        state.messageError = null;
        state.pending = null;
        state.totalEarnings = null;
        state.unlocked = null;
        state.draft = null;
        state.openConversationUserId = null;
        state.conversationHasMoreMessages = false
        state.hasLoadedMessages = false
    },
    markAllSeen(state) {
        state.messages = state.messages.map(message => message.self ? { ...message, seen: true } : message)
    },
    setCurrentConverstionPendingMarkSeen(state) {
        // when the page is not visible and user has an incoming message
        // when the page becomes visible, mark it as seen if still open
        state.currentConverstionPendingMarkSeen = true
    },
    noMoreMessages(state) {
        state.conversationHasMoreMessages = false
    },
    hasMoreMessages(state) {
        state.conversationHasMoreMessages = true
    },
    setMessageError(state, value) {
        state.messageError = value;
    },
    setTotalEarnings(state, value) {
        state.totalEarnings = value;
    },
    setDraft(state, value) {
        state.draft = value;
    },
    setMessages(state, messages) {
        state.pending = null;
        state.messages = messages;
        updateOldestMessageId(state);
    },
    appendMessages(state, messages) {
        state.messages = [...state.messages, ...messages];
        updateOldestMessageId(state);
    },
    prependMessage(state, message) {
        if (state.messages) {
            state.messages.unshift(message);
        } else if (!state.loadingMessages) {
            state.messages = [message]
        }
    },
    deleteMessage(state, message) {
        state.messages = state.messages.filter(searchMessage => searchMessage.id !== message.id)
    },
    appendPending(state, message) {
        message.self = true
        message.created = new Date()

        if (!state.pending) {
            state.pending = [message]
        } else {
            state.pending.push(message);
        }
    },
    uploadError(state, { instanceId, message }) {
        state.pending = state.pending.map(
            pendingMessage => pendingMessage.instanceId === instanceId
                ? { ...pendingMessage, error: message }
                : pendingMessage
        )
    },
    removePending(state, instanceId) {
        if (!state.pending) {
            return;
        }

        state.pending = state.pending.filter(pendingMessage => pendingMessage.instanceId !== instanceId)
    },
    setOpenConversationUserId(state, userId) {
        state.openConversationUserId = userId;
    },
    setLoadingMessages(state, value) {
        state.loadingMessages = value;
    },
    setUnlocked(state, value) {
        state.unlocked = value;
    },
}

const actions = {
    seen({ rootState }) {
        api.post("/message/seen", {
            userId: rootState.profile.profile.id,
        })
    },
    more({ state, rootState, commit }) {
        api.post("/message/conversation", {
            oldestMessageId: state.oldestMessageId,
            userId: rootState.profile.profile.id,
        }).then((response) => {
            if (response?.data?.messages) {
                if (response.data.messages.length) {
                    commit('appendMessages', response.data.messages)
                }
            }

            if (!response.data.conversationHasMoreMessages) {
                commit('noMoreMessages')
            }
        })
    },
    refreshEarnings({ state, commit }) {
        if (!state.openConversationUserId) {
            return;
        }

        api.post("/message/conversation", {
            userId: state.openConversationUserId
        }).then((response) => {
            if (response?.data?.totalEarnings) {
                commit('setTotalEarnings', response?.data?.totalEarnings)
            }
        })
    },
    load({ commit }, { userId }) {
        commit('reset')

        commit('message/markPreviewRead', Number(userId), { root: true })
        commit('setOpenConversationUserId', Number(userId))
        commit('setLoadingMessages', true)

        api.post("/message/conversation", {
            oldestMessageId: null,
            userId,
        }).then((response) => {
            commit('setUnlocked', response?.data?.unlocked)

            if (response?.data?.messages) {
                commit('setMessages', response.data.messages)

                if (response.data.conversationHasMoreMessages) {
                    commit('hasMoreMessages')
                } else {
                    commit('noMoreMessages')
                }
            }

            if (response?.data?.totalEarnings) {
                commit('setTotalEarnings', response?.data?.totalEarnings)
            }
        }).catch(() => { })
            .then(() => {
                commit('setLoadingMessages', false)
            })
    },
}

const getters = {
    hasMessages(state) {
        return state.messages && state.messages.length
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
