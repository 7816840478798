<template>
    <generic-page>
        <back-to-dashboard />

        <b-alert variant="primary" show v-if="claimed">
            <h5>
                <font-awesome-icon
                    icon="fa-light fa-check-circle"
                    class="text-success"
                />
                Your bonus has been claimed
            </h5>
            Thanks for helping us to grow our network, keep up the great work!
        </b-alert>

        <div v-if="creatorUser && bonuses">
            <h5 class="mb-0">Current Bonus</h5>

            <div class="d-flex align-items-center justify-content-between py-2">
                <b-badge pill variant="dark">
                    Buyers Invited
                    {{ totalBuyerCount }}
                </b-badge>
                <b-badge pill variant="dark">
                    Progress: {{ creatorUser.currentBonusProgress }}%
                </b-badge>
            </div>

            <b-progress :max="100">
                <b-progress-bar :value="creatorUser.currentBonusProgress">
                </b-progress-bar>
            </b-progress>

            <b-btn
                :disabled="!claimable || claiming"
                block
                pill
                size="lg"
                variant="primary"
                class="my-3 py-2"
                @click="claimBonus"
            >
                <h5>Claim My Bonus</h5>
                <div>
                    <coin-badge :amount="currentBonusTier.amount" />
                </div>
            </b-btn>

            <h5 class="mt-4">Bonus Tiers</h5>

            <b-table-simple striped>
                <thead>
                    <th>Buyers Required</th>
                    <th>Bonus Payout</th>
                    <th>My Currency</th>
                </thead>
                <tbody>
                    <tr :key="bonus.totalBuyerCount" v-for="bonus in bonuses">
                        <td>
                            {{ bonus.buyers }}
                        </td>
                        <td>
                            <coin-badge :amount="bonus.amount" />
                        </td>
                        <td>
                            {{ bonus.payoutLocal }}
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>

            <b-alert variant="primary" show class="small mt-3">
                <font-awesome-icon icon="fa-duotone fa-info-circle" />
                Buyers are paying customers that have been invited by you. Even
                if they delete their account, you will not lose any points
                towards your bonus
            </b-alert>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>


<script>
import { mapState } from "vuex";

import BackToDashboard from "@/components/utility/BackToDashboard";

export default {
    data() {
        return {
            bonuses: null,
            totalBuyerCount: null,
            currentBonusTier: null,
            claimable: false,
            claimed: false,
            claiming: false,
        };
    },
    created() {
        this.refresh();
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("creatorDashboard", ["creatorUser"]),
    },
    methods: {
        refresh() {
            this.$store.dispatch("creatorDashboard/refresh");

            this.api.post("/bonus/index").then((response) => {
                if (response?.data?.bonuses) {
                    this.bonuses = response.data.bonuses;
                    this.totalBuyerCount = response.data.totalBuyerCount;
                    this.currentBonusTier = response.data.currentBonusTier;
                    this.claimable = response.data.claimable;
                }
            });
        },
        claimBonus() {
            this.claiming = true;
            this.api
                .post("/bonus/claim", {
                    id: this.currentBonusTier.id,
                })
                .then(() => {
                    this.claimed = true;
                    this.refresh();
                })
                .catch(() => {})
                .then(() => {
                    this.claiming = false;
                });
        },
    },
    components: {
        BackToDashboard,
    },
};
</script>