<template>
    <div class="p-2">
        <back-to-dashboard />

        <b-alert
            v-if="onboardingUser.creatorDisableRequest"
            show
            variant="warning"
        >
            <font-awesome-icon
                icon="fa-duotone fa-triangle-exclamation"
                size="lg"
            />
            {{
                $t(
                    "Requests are currently disabled, you may miss potential earnings."
                )
            }}
            <div class="mt-3">
                {{
                    $t(
                        "Click Enable Requests to turn requests back on and increase your earnings"
                    )
                }}
            </div>
            <b-btn
                @click="enableRequests"
                variant="primary"
                pill
                class="mt-3"
                block
                size="lg"
            >
                <h3 class="mb-0">
                    {{ $t("Enable Requests") }}
                </h3>
                <div class="small font-weight-bold">
                    {{ $t("Increase your earnings") }}
                </div>
            </b-btn>
        </b-alert>

        <h5>
            {{ $t("Requests") }}
        </h5>

        <div v-if="creatorRequests">
            <div
                :key="creatorRequest.id"
                v-for="creatorRequest in creatorRequests"
                @click="
                    $router.push({
                        name: 'creatorRequestManage',
                        params: { id: creatorRequest.id },
                    })
                "
            >
                <user-standard-avatar
                    :timestamp="creatorRequest.created"
                    :standardMap="creatorRequest.user"
                    :nonPrimaryPhotos="false"
                    :hideBadges="true"
                    :userBetweenStatus="false"
                    size="small"
                    class="border-light border-top"
                >
                    <div class="d-flex align-items-center mt-1">
                        <coin-badge class="mr-2" :amount="creatorRequest.payout" />

                        <creator-request-status
                            :creatorRequest="creatorRequest"
                        />
                    </div>
                </user-standard-avatar>
            </div>
            <div class="mt-3">
                <b-button
                    v-b-toggle.more
                    variant="light"
                    class="border"
                    size="sm"
                    pill
                >
                    {{ $t("Request Settings") }}
                </b-button>
                <b-collapse id="more" class="my-3">
                    <b-alert variant="warning" show>
                        <font-awesome-icon
                            icon="fa-duotone fa-triangle-exclamation"
                            size="lg"
                        />
                        {{
                            $t(
                                "If you're not comfortable getting requests you can disable the feature completely. If you want to disable requests from specific users, or countries you can block a user or country from their profile"
                            )
                        }}
                    </b-alert>
                    <b-btn
                        v-if="!onboardingUser.creatorDisableRequest"
                        @click="disableRequests"
                        variant="danger"
                        pill
                    >
                        {{ $t("Disable Requests") }}
                    </b-btn>
                </b-collapse>
            </div>
        </div>
    </div>
</template>

<script>
import BackToDashboard from "@/components/utility/BackToDashboard";
import UserStandardAvatar from "@/components/user/UserStandardAvatar";
import CreatorRequestStatus from "@/components/creatorRequest/CreatorRequestStatus";
import { mapState } from "vuex";

export default {
    data() {
        return {
            creatorRequests: null,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        async enableRequests() {
            await this.api.post("/userFlag/creatorEnableRequests");
            this.$store.dispatch("onboarding/status");
        },
        async disableRequests() {
            if (
                confirm(
                    this.$t(
                        "Are you sure? Your earnings may go down as a result"
                    )
                )
            ) {
                await this.api.post("/userFlag/creatorDisableRequests");
                this.$store.dispatch("onboarding/status");
            }
        },
        async refresh() {
            const { data } = await this.api.post(
                "/creatorRequest/requestedOfCreator"
            );
            this.creatorRequests = data.creatorRequests;
        },
    },
    components: {
        BackToDashboard,
        UserStandardAvatar,
        CreatorRequestStatus,
    },
};
</script>