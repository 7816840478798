<template>
    <div>
        <div v-if="selectedReason">
            <div v-if="resolution">
                <delete-resolution />
            </div>
            <div v-else-if="scheduled">
                <delete-scheduled />
            </div>
            <div v-else-if="undeleteable">
                <delete-manual />
            </div>
            <div v-else>
                <delete-confirmation />
            </div>
        </div>
        <div v-else>
            <delete-reason-index />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import DeleteConfirmation from "@/components/delete/DeleteConfirmation";
import DeleteManual from "@/components/delete/DeleteManual";
import DeleteReasonIndex from "@/components/delete/DeleteReasonIndex";
import DeleteResolution from "@/components/delete/DeleteResolution";
import DeleteScheduled from "@/components/delete/DeleteScheduled";

export default {
    computed: {
        ...mapState("delete", [
            "selectedReason",
            "scheduled",
            "undeleteable",
            "resolution",
        ]),
    },
    methods: {},
    components: {
        DeleteConfirmation,
        DeleteManual,
        DeleteReasonIndex,
        DeleteResolution,
        DeleteScheduled,
    },
};
</script>