<template>
    <div class="user bg-white position-relative shadow-sm">
        <div
            v-if="timestamp"
            class="last-active position-absolute px-2 py-1 small text-secondary d-flex align-items-center"
        >
            <live-timestamp :timestamp="timestamp" />
        </div>
        <div v-if="!hideBadges" class="badges position-absolute">
            <font-awesome-icon
                v-if="standardMap.primaryVideo"
                icon="fa-duotone fa-camcorder"
                class="branded"
            />
        </div>
        <div class="d-flex align-items-center">
            <div class="mr-2 image-wrapper position-relative bg-light">
                <img
                    :height="primaryPhotoSize"
                    :width="primaryPhotoSize"
                    class="face"
                    :src="standardMap.primaryPhoto.search"
                    loading="lazy"
                />

                <img
                    v-if="!hideFlag"
                    class="position-absolute flag-wrapper"
                    :src="standardMap.flagUrl"
                    loading="lazy"
                />

                <div class="position-absolute online-status-wrapper">
                    <user-online-status
                        :standardMap="standardMap"
                        :size="size"
                    />
                </div>
            </div>
            <div class="p-2 width-relative">
                <h5
                    class="text-truncate mb-0 d-flex align-items-center"
                    :class="{ 'small font-weight-bold': this.size === 'small' }"
                >
                    <div v-if="!hideBadges">
                        <user-primary-badge
                            class="mr-1"
                            :standardMap="standardMap"
                        />
                    </div>
                    {{ standardMap.name }}
                </h5>

                <user-between-status
                    v-if="userBetweenStatus && !minimal"
                    :user="standardMap"
                />

                <div class="d-flex align-items-center small mt-1">
                    <div class="text-truncate">
                        <slot></slot>
                    </div>
                </div>

                <div
                    v-if="
                        !minimal &&
                        nonPrimaryPhotos &&
                        photosExcludingPrimary &&
                        photosExcludingPrimary.length
                    "
                    class="text-truncate mt-3"
                >
                    <div
                        class="d-inline-block"
                        :key="photo.id"
                        v-for="photo in photosExcludingPrimary"
                    >
                        <img
                            height="32"
                            width="32"
                            class="rounded mr-1"
                            :src="photo.small"
                            loading="lazy"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.user {
    .image-wrapper {
        border-right: 1px solid var(--light);
    }

    .badges {
        bottom: 4px;
        right: 4px;
        border-bottom-left-radius: 4px;
    }

    .last-active {
        top: 0;
        right: 0;
        background: var(--light);
        border-bottom-left-radius: 4px;
    }

    &:hover {
        cursor: pointer;
        box-shadow: 0 0 0.5rem rgba(0, 123, 255, 0.333) !important;
    }
    .width-relative {
        width: calc(100% - (150px + 1rem));
    }

    .online-status-wrapper {
        bottom: 0.25rem;
        right: 0.25rem;
    }
    .flag-wrapper {
        top: 0;
        left: 0;
        border-bottom-right-radius: 4px;
    }
}
</style>

<script>
import LiveTimestamp from "@/components/utility/LiveTimestamp.vue";
import UserOnlineStatus from "@/components/user/UserOnlineStatus";
import UserBetweenStatus from "@/components/user/UserBetweenStatus";
import UserPrimaryBadge from "@/components/user/UserPrimaryBadge";

import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        showSubscriberIcon() {
            return (
                this.isMySubscriber(this.standardMap.id) ||
                this.subscribedToCreatorUserId(this.standardMap.id)
            );
        },
        primaryPhotoSize() {
            if (this.size === "small") {
                return 75;
            }

            return 150;
        },
        userIsVetted() {
            if (
                this.$store.state.onboarding?.user?.vetted &&
                this.standardMap?.vetted
            ) {
                return true;
            }

            return false;
        },
        photosExcludingPrimary() {
            return this.standardMap.photos
                .filter(
                    (photo) => photo.id !== this.standardMap.primaryPhoto.id
                )
                .slice(0, 10);
        },
        ...mapState("message", ["typing", "hasMoreMessages"]),
        ...mapGetters("userSubscription", [
            "subscribedToCreatorUserId",
            "isMySubscriber",
        ]),
    },
    components: {
        LiveTimestamp,
        UserOnlineStatus,
        UserBetweenStatus,
        UserPrimaryBadge,
    },
    props: [
        "standardMap",
        "nonPrimaryPhotos",
        "userBetweenStatus",
        "timestamp",
        "size",
        "minimal",
        "hideBadges",
        "hideFlag",
    ],
};
</script>