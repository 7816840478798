<template>
    <div class="p-2">
        <back-to-dashboard />

        <h3 class="text-">Welcome to {{ brand.name }}!</h3>

        <div class="question">
            {{ $t("A bit about our brand") }}
        </div>
        <div>
            {{ brand.description }}
        </div>

        <div class="question">
            {{ $t("Where can I share the links?") }}
        </div>
        <div class="answer">
            {{
                $t(
                    "You can share the links on platforms like Facebook, Instagram, YouTube, Quora, reddit for example. You can provide it as a comment on blogs where its relevant. You can share it over email for people you know, private messages, social media, websites, YouTube videos and comments. If you have friends in the USA, Canada, Australia and Europe, definitely share with them to help grow your base of invited users, and increase your earnings"
                )
            }}
        </div>

        <div class="question">
            {{ $t("Where should I not share the link?") }}
        </div>
        <div class="answer">
            {{
                $t(
                    "Be careful about sharing it when people will not be interested in it, they may view that as spamming. You should share the link and add value by sharing it. For example, if someone asks where is a good place to meet new friends, you can certainly provide them with the link. If they are asking about where to buy a new car, that would not be a good place to share it just as very basic examples"
                )
            }}
        </div>

        <div class="question">
            {{ $t("Are there any rules about what I can do with this link?") }}
        </div>
        <div class="answer">
            {{
                $t(
                    "Yes - you are not allowed to SPAM the link. For example, email spamming to thousands of people who you don't know would be considered spamming. Commenting on places where it is not adding to the discussion would be considered spamming. There are many opportunities to include the link that adds value, and lets people know about our platform"
                )
            }}
        </div>

        <div class="question">
            {{ $t("What is the signup flow like for users?") }}
        </div>
        <div class="answer">
            {{
                $t(
                    "Not every user will immediately pay after signing up. Most will want to check it out a bit, browse around a bit before paying. Users will start getting messages from ladies, and usually most users will want to reply, when they do, they must pay. Our platform is free to signup and browse, but to interact with women they must refill credits first"
                )
            }}
        </div>

        <div class="question">
            {{ $t("How do I get paid?") }}
        </div>
        <div class="answer">
            {{
                $t(
                    "When you reach your minimum as stated under the My Money section, you will be able to submit your withdrawal. Depending on your country we offer different payout options, but we offer payments in every country in the world, so no matter what country you come from you will be able to get paid, and quickly. We process all payouts within 48 hours of submission, guaranteed"
                )
            }}
        </div>

        <div class="question">
            {{ $t("What if the user does not pay immediately?") }}
        </div>
        <div class="answer">
            {{
                $t(
                    "Regardless of when a user pays, you will still get rewarded. Even if he ends up becoming a paying customer a year after he signed up from your link, you will still get the credits deposited to your wallet. Most users will end up paying within about 48 hours, but some may take longer. We will email you to let you know as soon as they pay so you can see the rewards from your efforts coming in real-time"
                )
            }}
        </div>

        <div class="question">
            {{ $t("Does your platform allow fake users?") }}
        </div>
        <div class="answer">
            {{
                $t(
                    "Absolutely not - we have a zero tolerance policy for fake users and content. Our system is sophisticated, and designed around all of the different cases to catch every kind of fake and scam user from every country around the world. We've seen every kind of scam imaginable, and we have several ways to catch each type, you can ensure users you invite there is no more secure social platform. Its essentially impossible to get around our system, and if we detect fake payments, or fake users, we will reverse those instantly. There is no possibility to game our system, only real users and signups are accepted"
                )
            }}
        </div>

        <div class="question">
            {{ $t("Are there any bots or AI on this platform?") }}
        </div>
        <div class="answer">
            {{
                $t(
                    "Nope - we only allow real users to signup. We constantly reject profiles and content that are generated by AI programs like PhotoLab, we only allow photos with light filtering, if the photo is fake we just reject it, and often ban profiles who repeatedly try to use fake content. We go to great lengths to  make sure our customers never have to waste their time talking to fake profiles, or bots"
                )
            }}
        </div>

        <div
            v-if="onboardingUser.eligibleForChatSupport"
            class="bg-white p-2 border mt-3"
        >
            <h5>
                {{ $t("Still have more questions?") }}
            </h5>
            <div>
                {{
                    $t(
                        "No problem feel free to contact support and we'll answer any questions?"
                    )
                }}
            </div>

            <contact-support />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.question {
    font-weight: bold;
    color: var(--primary);
}
.question:not(:first-of-type) {
    margin-top: 1rem;
}
</style>

<script>
import { mapState } from "vuex";

import BackToDashboard from "@/components/utility/BackToDashboard";
import ContactSupport from "@/components/utility/ContactSupport";

export default {
    computed: {
        ...mapState("brand", ["brand"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        BackToDashboard,
        ContactSupport,
    },
};
</script>