<template>
    <b-modal
        hide-header
        hide-footer
        v-model="modal"
        body-class="p-0"
        modal-class="modal-full-height"
        no-close-on-esc
        
    >
        <post-photo-actions />
        <post-photo-gallery />
    </b-modal>
</template>

<script>
import PostPhotoActions from "@/components/always/photo/PostPhotoActions";
import PostPhotoGallery from "@/components/always/photo/PostPhotoGallery";

export default {
    computed: {
        modal: {
            get() {
                return this.$store.state.interface.postPhotoModalVisible;
            },
            set(value) {
                this.$store.commit("interface/setPostPhotoModalVisible", value);
                this.$store.commit("postPhoto/reset");
            },
        },
    },
    components: {
        PostPhotoGallery,
        PostPhotoActions,
    },
};
</script>