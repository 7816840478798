<template>
    <generic-page>
        <b-btn variant="link" size="lg" class="pl-0" to="/account/call/index">
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            All Calls
        </b-btn>

        <div v-if="call">
            <div @click="viewProfile">
                <user-standard-avatar
                    :standardMap="standardMap"
                    :nonPrimaryPhotos="false"
                    :hideBadges="true"
                    :userBetweenStatus="false"
                    size="small"
                    class="border-light border-top"
                />
            </div>

            <call-dispute @refresh="refresh" :call="call" />

            <h5 class="mt-3">Call Details</h5>
            <b-list-group>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    <div>Status</div>
                    <div class="small">
                        {{ call.status }}
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    v-if="call.duration"
                    class="d-flex align-items-center justify-content-between"
                >
                    <div>Duration</div>
                    <div class="small">
                        {{ call.duration }}
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    v-if="call.totalEarnings"
                    class="d-flex align-items-center justify-content-between"
                >
                    <div>Total Spent</div>
                    <coin-badge :amount="call.totalEarnings" />
                </b-list-group-item>
                <b-list-group-item
                    v-if="call.escrow"
                    class="d-flex align-items-center justify-content-between"
                >
                    <div>Credits in Escrow</div>
                    <font-awesome-icon
                        class="text-success"
                        icon="fa-light fa-check"
                    />
                </b-list-group-item>

                <b-list-group-item
                    v-if="call.refundAmount"
                    class="d-flex align-items-center justify-content-between"
                >
                    <div>Amount Refunded</div>
                    <coin-badge :amount="call.refundAmount" />
                </b-list-group-item>
            </b-list-group>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import UserStandardAvatar from "@/components/user/UserStandardAvatar";
import CallDispute from "@/components/always/call/CallDispute";

export default {
    data() {
        return {
            dispute: null,
            positive: false,
            negative: false,
            call: false,
            standardMap: false,
        };
    },
    mounted() {
        this.refresh();
    },
    watch: {
        $route(to) {
            if (to?.params?.id !== this.call?.id || to?.query?.forceRefresh) {
                this.refresh();
            }
        },
    },
    methods: {
        createDispute() {
            this.api
                .post(`/call/${this.call.id}/dispute`, {
                    dispute: this.dispute,
                })
                .then(() => {
                    this.refresh();
                });
        },
        rateCall(rating) {
            if (rating === 5) {
                this.negative = false;
                this.positive = true;
            }

            if (rating === 1) {
                this.negative = true;
                this.positive = false;
            }

            this.api.post(`/call/${this.call.id}/rate`, {
                rating,
            });
        },
        viewProfile() {
            this.$store.dispatch("profile/viewProfile", {
                userId: this.standardMap.id,
                profile: this.standardMap,
            });
        },
        refresh() {
            const id = this.$route.params?.id;

            this.api.post(`/call/${id}/get`).then((response) => {
                this.call = response?.data?.call;
                this.standardMap = response?.data?.standardMap;
            });
        },
    },
    components: {
        UserStandardAvatar,
        CallDispute,
    },
};
</script>