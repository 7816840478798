import { FEED_TYPE_CREATOR_POST, getFeedKey } from "@/constant/feed";
import api from "@/service/api";

const state = () => ({
    focusedCommentableItems: [],
    createdComment: [],
    deletedCommentIds: [],
})

const mutations = {
    reset(state) {
        state.focusedCommentableItems = []
    },
    appendFocusedCommentableItem(state, value) {
        state.focusedCommentableItems.push(value)
    },
    removeFocusedCommentableItem(state, item) {
        state.focusedCommentableItems = state.focusedCommentableItems.filter(focusedCommentableItem => focusedCommentableItem !== item)
    },
    removeCreatedComment(state, commentId) {
        state.createdComment = state.createdComment.filter(comment => comment.id !== commentId)
    },
    appendDeletedCommentId(state, value) {
        state.deletedCommentIds.push(value)
    },
}

const actions = {
    unfocusPost({ commit }, creatorPost) {
        commit("removeFocusedCommentableItem", getFeedKey({
            id: creatorPost.id,
            type: FEED_TYPE_CREATOR_POST,
        }))
    },
    refresh({ commit, state }) {
        api.post("/feed/index", {
            page: state.page,
            lowestSort: state.lowestSort
        })
            .then((response) => {
                if (response?.data?.feedItems) {
                    commit('setFeedItems', response.data.feedItems)

                    if (response.data.feedItems.length) {
                        commit('setLowestSort', response.data.feedItems[response.data.feedItems.length - 1].sort)
                    }
                }

                commit('setHasMorePages', response.data.hasMorePages)
            })
    },
    delete({ commit }, commentId) {
        commit('appendDeletedCommentId', commentId)

        api
            .post("/comment/delete", {
                commentId
            })
            .then(() => {
                commit('removeCreatedComment', commentId)
            });
    },
}



const getters = {
    postFocused: (state) => (creatorPost) => {
        return state.focusedCommentableItems.includes(getFeedKey({
            id: creatorPost.id,
            type: FEED_TYPE_CREATOR_POST,
        }))
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
