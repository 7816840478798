const state = () => ({
    favorites: [],
})

const mutations = {
    appendPostFavorite(state, value) {
        state.favorites.push(value)
    },
    removePostFavorite(state, value) {
        state.favorites = state.favorites.filter(favorite => favorite !== value)
    },
}

const actions = {

}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
