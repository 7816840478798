<template>
    <div>
        <div @click="viewProfile">
            <user-standard-avatar
                :timestamp="userSubscription.created"
                :standardMap="userSubscription.standardMap"
                :nonPrimaryPhotos="false"
                :hideBadges="true"
                :userBetweenStatus="false"
                size="small"
            >
            </user-standard-avatar>
        </div>

        <div class="p-3">
            <b-list-group>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    {{ $t("Posts") }}

                    <div class="text-secondary">
                        {{ postCount }}
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    {{ $t("Price") }}
                    <div>{{ priceForUser }}/{{ $t("week") }}</div>
                </b-list-group-item>
                <b-list-group-item
                    @click="cancelSubscription"
                    class="d-flex align-items-center justify-content-between text-danger"
                >
                    {{ $t("Cancel") }}
                </b-list-group-item>
            </b-list-group>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import UserStandardAvatar from "@/components/user/UserStandardAvatar";

export default {
    computed: {
        ...mapState("userSubscription", [
            "userSubscription",
            "subscriptionPrice",
            "subscriptionLocalPrice",
            "postCount",
            "priceForUser",
        ]),
    },
    methods: {
        cancelSubscription() {
            this.$store.commit("userSubscription/setCancelSubscription", true);
        },
        viewProfile() {
            this.$store.dispatch("profile/viewProfile", {
                userId: this.userSubscription.standardMap.id,
                profile: this.userSubscription.standardMap,
            });
        },
    },
    components: {
        UserStandardAvatar,
    },
};
</script>