<template>
    <generic-page>
        <div v-if="creatorGallery">
            <b-btn
                variant="link"
                size="lg"
                class="pl-0"
                @click="
                    $router.push({
                        name: 'creatorGalleryView',
                        params: {
                            id: creatorGallery.id,
                        },
                    })
                "
            >
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
                {{ $t("Back") }}
            </b-btn>

            <div v-if="creatorGallery" :key="creatorGallery.id">
                <creator-gallery-invite-url />
            </div>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import CreatorGalleryInviteUrl from "@/components/creator/gallery/invite/CreatorGalleryInviteUrl";

export default {
    created() {
        if (this.$route.params?.id) {
            this.$store.dispatch("creatorGallery/get", this.$route.params?.id);
        }
    },
    watch: {
        $route(to) {
            if (
                to?.params?.id !== this.$store.state.gallery.creatorGallery?.id
            ) {
                this.$store.dispatch(
                    "creatorGallery/get",
                    this.$route.params?.id
                );
            }
        },
    },
    computed: {
        ...mapState("creatorGallery", ["creatorGallery"]),
    },
    components: {
        CreatorGalleryInviteUrl,
    },
};
</script>