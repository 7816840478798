<template>
    <div class="infinite-scroll-container">
        <div
            v-for="(video, index) in videos"
            :key="index"
            class="video-wrapper"
        >
            <video
                :src="video.url"
                controls
                autoplay
                muted
                playsinline
                class="fullscreen-video"
            ></video>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            videos: [], // Store your video URLs here
            isLoading: false,
        };
    },
    mounted() {
        this.loadVideos();
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        loadVideos() {
            if (this.isLoading) return;
            this.isLoading = true;

            // Simulate an API call to load more videos
            setTimeout(() => {
                const moreVideos = [
                    { url: "https://www.w3schools.com/html/mov_bbb.mp4" },
                    {
                        url: "https://samplelib.com/lib/preview/mp4/sample-5s.mp4",
                    },
                    {
                        url: "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4",
                    },
                    {
                        url: "https://storage.googleapis.com/webfundamentals-assets/videos/chrome.mp4",
                    },

                    // Add more videos as needed
                ];
                this.videos.push(...moreVideos);
                this.isLoading = false;
            }, 1000); // Simulate loading delay
        },
        handleScroll() {
            const bottomOfWindow =
                window.innerHeight + window.scrollY >=
                document.body.offsetHeight - 50;

            if (bottomOfWindow) {
                this.loadVideos();
            }
        },
    },
};
</script>

<style scoped>
.infinite-scroll-container {
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
}

.video-wrapper {
    scroll-snap-align: start;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullscreen-video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}
</style>
