import Vue from 'vue'
import Vuex from 'vuex'
import { BootstrapVue } from 'bootstrap-vue'
import store from './store'
import App from './App.vue'
import VueScreen from 'vue-screen';
import VueMousetrapPlugin from 'vue-mousetrap/vue2'
import Fitty from 'vue-fitty'
import OneSignalVue from 'onesignal-vue'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import router from "./router/index";
import VueMeta from 'vue-meta'



import ValidationErrors from "@/components/utility/ValidationErrors";
import LiveTimestamp from "@/components/utility/LiveTimestamp";
import LiveTimestampFuture from "@/components/utility/LiveTimestampFuture";
import PreserveWhitespace from "@/components/utility/PreserveWhitespace";
import ListGroupTitle from "@/components/utility/ListGroupTitle";
import CoinBadge from "@/components/utility/CoinBadge";
import GenericLoading from "@/components/utility/GenericLoading";
import GenericPage from "@/components/utility/GenericPage";

import api from "@/service/api";
import translate from "@/service/translate";

import '@/plugin/globals';
import '@/plugin/Dayjs';
import '@/plugin/HandleWindowResize';
import '@/plugin/FirebaseLogEvent';
import '@/plugin/FontAwesome';

Vue.use(BootstrapVue)
Vue.use(Vuex)
Vue.use(OneSignalVue);
Vue.use(VueScreen, 'bootstrap')
Vue.use(VueMousetrapPlugin)
Vue.use(Fitty)
Vue.use(VueMeta)

Vue.component("ValidationErrors", ValidationErrors);
Vue.component("LiveTimestamp", LiveTimestamp);
Vue.component("LiveTimestampFuture", LiveTimestampFuture);
Vue.component("PreserveWhitespace", PreserveWhitespace);
Vue.component("ListGroupTitle", ListGroupTitle);
Vue.component("CoinBadge", CoinBadge);
Vue.component("GenericLoading", GenericLoading);
Vue.component("GenericPage", GenericPage);

Vue.prototype.$t = (phrase) => translate(phrase);
Vue.prototype.api = api;

Vue.filter('pluralize', (word, amount) => (amount > 1 || amount === 0) ? `${word}s` : word)

Vue.prototype.$uuid = () => Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
Vue.prototype.$scrollToTop = (behavior = 'auto') => window.scrollTo({
    top: 0,
    left: 0,
    behavior,
});
Vue.prototype.$bus = new Vue();

Sentry.init({
    Vue,
    dsn: "https://1c254dd929704788a075d5e5096df9c5@o385603.ingest.sentry.io/6157746",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: ["localhost", "my-site-url.com", /^\//],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: .05,
    logErrors: true,
});

let deviceReady = false
let vueMounted = false

const app = new Vue({
    router,
    store,
    render: h => h(App),
    mounted() {
        vueMounted = true

        if (deviceReady) {
            app.deviceReady()
        }
    },
    methods: {
        deviceReady() {
            this.$store.dispatch('system/deviceReady')
        }
    }
}).$mount('#app')

document.addEventListener('deviceReady', () => {
    deviceReady = true

    if (vueMounted) {
        app.deviceReady()
    }
}, false)