<template>
    <div>
        <div class="mt-3">
            <div v-if="notify">
                <div class="my-3">
                    <font-awesome-icon
                        size="3x"
                        class="text-success"
                        icon="fa-light fa-check-circle"
                    />
                </div>
                Thanks, we've got your information and we'll notify you as soon
                as we launch for your location, sorry for the inconvenience
            </div>
            <div v-else>
                <div>
                    Unfortunately, we have already exceeded our established
                    quota of volume of users we can service from your location
                </div>
                <div class="mt-3">
                    Due to this overwhelming demand, we are unable to accept new
                    users at this time. We will be working very hard to scale up
                    our systems so we're able to handle the increased load
                </div>
                <b-btn
                    class="mt-3"
                    @click="notify = true"
                    variant="primary"
                    pill
                    block
                >
                    Notify me when you re-launch
                </b-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            notify: null,
        };
    },
};
</script>