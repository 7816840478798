import api from "@/service/api";

const state = () => ({
    editTemplate: null,
    templates: null,
    finishedInitialLoad: false,
    editMode: false,
})

const mutations = {
    reset(state) {
        state.editMode = false
        state.editTemplate = null
    },
    setEditTemplate(state, value) {
        state.editTemplate = value
    },
    setEditMode(state, value) {
        state.editMode = value
    },
    setTemplates(state, value) {
        state.templates = value
    },
    setFinishedInitialLoad(state) {
        state.finishedInitialLoad = true
    },
}

const actions = {
    initialLoad({ state, commit, dispatch }) {
        if (!state.finishedInitialLoad) {
            dispatch('refresh').then(() => {
                commit('setFinishedInitialLoad')
            })
        }
    },
    refresh({ commit }) {
        return new Promise(resolve => {
            api.post("/template/index").then((response) => {
                if (response?.data?.templates) {
                    commit('setTemplates', response.data.templates)
                }
            }).then(() => {
                resolve()
            })
        });
    },
    createTemplate({ commit, dispatch }) {
        api.post("/template/create").then((response) => {
            if (response?.data?.template) {
                dispatch('refresh').then(() => {
                    commit('setEditTemplate', response.data.template)
                })
            }
        })
    },
    useTemplate({ commit, rootState }, template) {
        let body = template.body

        const interpolationVariables = [
            {
                key: "{age}",
                value: rootState.profile.profile.age,
            },
            {
                key: "{city}",
                value: rootState.profile.profile.city,
            },
            {
                key: "{country}",
                value: rootState.profile.profile.country,
            },
            {
                key: "{name}",
                value: rootState.profile.profile.name,
            },
        ]

        interpolationVariables.forEach(item => {
            body = body.replace(new RegExp(item.key, 'g'), item.value)
        })

        commit('conversation/setDraft', body, { root: true })

        commit(
            'interface/setConversationTemplateModalVisible',
            false,
            { root: true }
        )
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
