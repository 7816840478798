<template>
    <div>
        <div class="mt-3">
            <h5>{{ $t("When to Share Contact Card") }}</h5>

            <div class="my-3">
                {{
                    $t(
                        "Other users can request your contact card, choose how you want to approve those requests"
                    )
                }}
            </div>

            <b-btn
                class="p-3 mt-3 border"
                variant="light"
                block
                @click="setContactPrivacy(0)"
            >
                <b-row no-gutters>
                    <b-col
                        cols="2"
                        class="d-flex align-items-center justify-content-around"
                    >
                        <span
                            class="mr-1"
                            :class="{ 'text-primary': contactPrivacy === 0 }"
                        >
                            <font-awesome-icon
                                :icon="
                                    contactPrivacy === 0
                                        ? 'fa-solid fa-check-circle'
                                        : 'fa-solid fa-circle'
                                "
                            />
                        </span>
                    </b-col>
                    <b-col cols="10" class="text-left">
                        <div class="text-success small">
                            {{ $t("(Get more messages)") }}
                        </div>
                        <div>
                            {{ $t("Share with verified members") }}
                        </div>
                    </b-col>
                </b-row>
            </b-btn>

            <b-btn
                class="p-3 mt-3 border"
                variant="light"
                block
                @click="setContactPrivacy(1)"
            >
                <b-row no-gutters>
                    <b-col
                        cols="2"
                        class="d-flex align-items-center justify-content-around"
                    >
                        <span
                            class="mr-1"
                            :class="{ 'text-primary': contactPrivacy === 1 }"
                        >
                            <font-awesome-icon
                                :icon="
                                    contactPrivacy === 1
                                        ? 'fa-solid fa-check-circle'
                                        : 'fa-solid fa-circle'
                                "
                            />
                        </span>
                    </b-col>
                    <b-col cols="10" class="text-left">
                        <div class="text-secondary small">
                            {{ $t("(Less messages)") }}
                        </div>
                        <div>
                            {{ $t("Approve every request to share") }}
                        </div>
                    </b-col>
                </b-row>
            </b-btn>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            option: null,
            phone: null,
            username: null,
            error: null,
        };
    },
    mounted() {},
    computed: {
        contactPrivacy() {
            return this.user.contactPrivacy;
        },
        ...mapState("onboarding", ["user"]),
        ...mapState("options", ["contactOptions"]),
    },
    methods: {
        setContactPrivacy(contactPrivacy) {
            this.api
                .post("/contact/privacy", {
                    contactPrivacy,
                })
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                });
        },
        updatePhone() {},
        setOption(option) {
            this.option = option;
        },
        skip() {
            this.api.post("/onesignal/skip").then(() => {
                this.$store.dispatch("onboarding/status");
            });
        },
    },
};
</script>
