import { FEED_TYPE_CREATOR_POST } from "@/constant/feed";
import api from "@/service/api";

const state = () => ({
    comments: null
})

const mutations = {
    setComments(state, value) {
        state.comments = value
    },
}

const actions = {
    reactToAll({ dispatch }, { creatorPostId, reactionId }) {
        api.post("/comment/reactToAll", {
            creatorPostId,
            reactionId
        }).then(response => {
            if (response?.data?.creatorPost) {
                dispatch('refresh', {
                    id: response.data.creatorPost.id,
                    type: FEED_TYPE_CREATOR_POST,
                })
            }
        });
    },
    react({ dispatch }, { commentId, reactionId }) {
        api.post("/comment/react", {
            commentId,
            reactionId
        }).then(response => {
            if (response?.data?.creatorPost) {
                dispatch('refresh', {
                    id: response.data.creatorPost.id,
                    type: FEED_TYPE_CREATOR_POST,
                })
            }
        });
    },
    refresh({ commit }, { type, id }) {
        api
            .post("/comment/index", { type, id })
            .then((response) => {
                if (response?.data?.comments) {
                    commit('setComments', response.data.comments)
                }
            })
    },
}



const getters = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
