<template>
    <div>
        <div class="p-2 mt-2">
            <creator-stats />
        </div>
    </div>
</template>

<script>
import CreatorStats from "@/components/creator/stats/CreatorStats";

export default {
    components: {
        CreatorStats,
    },
};
</script>