<template>
    <div class="mt-2">
        <h5 class="m-0">
            {{ $t("Do you want to turn off notifciations instead?") }}
        </h5>
        <div class="mt-3">
            {{
                $t(
                    "You can completely disable notifications, or choose which ones you want to get in your settings"
                )
            }}
        </div>
        <div class="mt-2">
            <b-btn
                @click="$store.dispatch('delete/saveFailed')"
                variant="link"
                size="sm"
                class="text-secondary px-0"
            >
                {{ $t("No thanks, just delete") }}
            </b-btn>
        </div>
        <div class="mt-3">
            <b-btn
                @click="savedDelete"
                variant="primary"
                block
                size="lg"
                class="py-4"
            >
                {{ $t("Turn off notifications") }}
            </b-btn>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        savedDelete() {
            this.$store.dispatch("delete/saved");
            this.$store.dispatch("setting/editSetting", "notification");
        },
    },
};
</script>