/* global cordova */

const state = () => ({
    darkMode: false,
    deviceReady: false,
    now: new Date(),
    cordova: process.env.VUE_APP_TARGET === 'cordova' ? true : false,
    visible: true,
    postLaunchActions: [],
    alreadyDispatchedPostLaunchActions: false,
    touchCapable: (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)),
})

const mutations = {
    refreshNow(state) {
        state.now = new Date()
    },
    setVisible(state, visible) {
        state.visible = visible
    },
    setAlreadyDispatchedPostLaunchActions(state) {
        state.alreadyDispatchedPostLaunchActions = true
    },
    appendPostLaunchAction(state, { action, payload }) {
        state.postLaunchActions.push({ action, payload })
    },
    deviceReady(state) {
        state.deviceReady = true
    },
    setDarkMode(state, value) {
        state.darkMode = value
    },
    setLoadedShareJs(state, value) {
        state.loadedShareJs = value;
    },
}

const actions = {
    deviceReady({ commit }) {
        commit('deviceReady')
    },
    appendPostLaunchAction({ state, dispatch, commit }, { action, payload }) {
        // we've already finished launch
        // so can directly call the actions now
        if (state.alreadyDispatchedPostLaunchActions) {
            dispatch(action, payload, { root: true })
            return;
        }

        commit('appendPostLaunchAction', { action, payload })
    },
    dispatchPostLaunchActions({ state, dispatch, commit }) {
        commit('setAlreadyDispatchedPostLaunchActions')

        if (state.postLaunchActions.length) {
            console.log(`${state.postLaunchActions.length} item in post launch queue`)
        } else {
            return;
        }

        state.postLaunchActions.forEach(async ({ action, payload }) => {
            if (action && payload) {
                await dispatch(action, payload, { root: true })
            }
        })
    },
    enableDarkMode({ commit }) {
        commit('setDarkMode', true)
    },
    openBrowser({ state }, url) {
        if (state.cordova) {
            cordova.InAppBrowser.open(
                url,
                "_system",
                "hidden=yes,location=no"
            );
        } else {
            window.open(url, "_blank");
        }
    },
}


const getters = {
    appReadyToStart(state, getters, rootState) {
        // first things first, check if we even have an auth token to use
        // prevent mounting logged in component within brochure app
        if (!rootState.startup.hasDeterminedIfAuthTokenAvailable) {
            return false;
        }

        if (!rootState.brand.brand) {
            return false;
        }

        if (state.cordova) {
            return state.deviceReady ? true : false
        } else {
            return true;
        }
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
