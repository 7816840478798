<template>
    <div>
        <b-modal
            :title="$t('Messages')"
            hide-footer
            v-model="modal"
            centered
            scrollable
            body-class="p-0"
            no-close-on-esc
            
            static
            ref="messageIndex"
        >
            <message-inbox />
        </b-modal>
    </div>
</template>

<script>
import MessageInbox from "@/components/messages/MessageInbox";

export default {
    data() {
        return {
            unsubscribeToMutations: null,
        };
    },
    created() {
        this.unsubscribeToMutations = this.$store.subscribe((mutation) => {
            if (mutation.type === "message/setView") {
                this.$nextTick(() => {
                    if (this.$refs?.messageIndex?.$refs?.body) {
                        this.$refs.messageIndex.$refs.body?.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "auto",
                        });
                    }
                });
            }
        });
    },
    beforeDestroy() {
        if (this.unsubscribeToMutations) {
            this.unsubscribeToMutations();
        }
    },
    computed: {
        modal: {
            get() {
                return this.$store.state.interface.messageInboxModalVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setMessageInboxModalVisible",
                    value
                );
            },
        },
    },

    components: {
        MessageInbox,
    },
};
</script>