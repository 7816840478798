
<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <div class="lead mb-0">
                {{ $t("Confirm amount to send") }}
            </div>
            <b-btn @click="cancelSend" variant="light" class="text-danger" pill>
                {{ $t("Cancel") }}
            </b-btn>
        </div>

        <b-row
            v-if="sendAmount"
            class="my-4 text-center d-flex align-items-center"
        >
            <b-col cols="5">
                <h3 class="text-warning mb-0">
                    <font-awesome-icon
                        icon="fa-duotone fa-coin"
                        class="text-warning"
                    />
                    {{ sendAmount.amount }}
                </h3>
                <div class="text-warning">
                    {{ $t("credits") }}
                </div>
                <div>
                    {{ sendAmount.local }}
                </div>
            </b-col>
            <b-col cols="2">
                <font-awesome-icon
                    icon="fa-light fa-arrow-right"
                    size="2x"
                    class="text-primary"
                />
            </b-col>
            <b-col cols="5">
                <user-square-avatar :standardMap="standardMap" />
            </b-col>
        </b-row>

        <div v-if="false" class="my-2">
            <b-form-input
                type="text"
                v-model="message"
                size="lg"
                maxlength="255"
                :placeholder="$t('Message to user (optional)')"
            ></b-form-input>
        </div>

        <b-alert variant="danger" show v-if="error">
            {{ error }}
        </b-alert>

        <b-btn
            :disabled="sending"
            @click="sendCredits"
            size="lg"
            variant="primary"
            pill
            block
            class="mt-3"
        >
            {{ $t("Send Money") }}
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

import UserSquareAvatar from "@/components/user/UserSquareAvatar";

export default {
    data() {
        return {
            message: null,
        };
    },
    computed: {
        ...mapState("sendCredits", [
            "sendAmount",
            "standardMap",
            "sending",
            "error",
        ]),
    },
    methods: {
        cancelSend() {
            this.$store.dispatch("sendCredits/cancel");
        },
        sendCredits() {
            this.$store.dispatch("sendCredits/confirmSend", this.message);
        },
    },
    components: {
        UserSquareAvatar,
    },
};
</script>