<template>
    <div class="vh-50">
        <div v-if="blockedCountries?.length" class="border-bottom">
            <b-btn
                @click="unblockAllCountries"
                class="rounded-0 border-0 py-3 text-danger"
                variant="light"
                block
            >
                {{ $t("Unblock All Countries") }}
            </b-btn>
        </div>
        <div
            :key="country.id"
            v-for="country in blockedCountries"
            class="striped p-3 d-flex align-items-center justify-content-between"
        >
            <div class="d-flex align-items-center">
                <img class="mr-1" :src="country.flagUrl" />
                {{ country.name }}
            </div>
            <b-btn variant="light" @click="unblockCountry(country)" pill>
                {{ $t("Unblock") }}
            </b-btn>
        </div>

        <div class="p-2">
            <b-button class="border" v-b-toggle.more variant="light" block>
                {{ $t("Show All Countries") }}
            </b-button>
            <b-collapse id="more">
                <div
                    :key="country.id"
                    v-for="country in unblockedCountries"
                    class="striped p-3 d-flex align-items-center justify-content-between"
                >
                    <div class="d-flex align-items-center">
                        <img class="mr-1" :src="country.flagUrl" />
                        {{ country.name }}
                    </div>
                    <b-btn variant="light" @click="blockCountry(country)" pill>
                        {{ $t("Block") }}
                    </b-btn>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            blockedCountries: null,
        };
    },
    created() {
        this.refreshCountries();
    },
    computed: {
        unblockedCountries() {
            return this.countries.filter(
                (country) => !this.blockedCountryIds?.includes(country.id)
            );
        },
        ...mapState("onboarding", ["user"]),
        ...mapState("options", ["countries"]),
        blockedCountryIds() {
            return this.blockedCountries?.map((country) => country.id);
        },
    },
    methods: {
        refreshCountries() {
            this.api.post("/block/blockedCountries", {}).then((response) => {
                if (response?.data?.countries) {
                    this.blockedCountries = response.data.countries;
                }
            });
        },
        async unblockCountry(country) {
            await this.api.post("/block/unblockCountry", {
                countryId: country.id,
            });

            this.refreshCountries();
        },
        async blockCountry(country) {
            await this.api.post("/block/country", {
                countryId: country.id,
            });

            this.refreshCountries();
        },
        async unblockAllCountries() {
            await this.api.post("/block/unblockAllCountries");
            this.refreshCountries();
            this.$store.dispatch("setting/close");
            this.$store.dispatch("search/refresh");
        },
    },
};
</script>