<template>
    <div>
        <h5>
            {{ $t("Review details") }}
        </h5>

        <wise-account-preview :account="account" />

        <b-btn
            @click="submit"
            :disabled="submitting"
            variant="primary"
            size="lg"
            block
            pill
            class="mt-3"
        >
            {{ $t("Submit Withdrawal") }}
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

import WiseAccountPreview from "@/components/withdraw/payout/wise/WiseAccountPreview";

export default {
    computed: {
        ...mapState("withdraw", [
            "withdraw",
            "lastUserPayee",
            "requirements",
            "submitting",
        ]),
        ...mapState("wise", ["account", "requirements"]),
    },
    methods: {
        submit() {
            this.$store.dispatch("withdraw/submit");
        },
    },
    components: {
        WiseAccountPreview,
    },
};
</script>