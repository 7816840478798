import api from "@/service/api";

const state = () => ({
    guides: null,
    currentGuide: null,
    incompleteGuides: null,
    refillCommissionPercentage: null,
    popularApps: null,
})

const mutations = {
    reset(state) {
        state.currentGuide = null
    },
    setCurrentGuide(state, value) {
        state.currentGuide = value;
    },
    setIncompleteGuides(state, value) {
        state.incompleteGuides = value;
    },
    setRefillCommissionPercentage(state, value) {
        state.refillCommissionPercentage = value;
    },
    setPopularApps(state, value) {
        state.popularApps = value;
    },
    setGuides(state, value) {
        state.guides = value
    },
}

const actions = {
    executeAction({ commit, dispatch }, action) {
        action({ commit, dispatch })
    },
    setCurrentGuide({ commit, dispatch }, guide) {
        commit('reset')
        commit('setCurrentGuide', guide)
        dispatch('refresh')
    },
    refresh({ commit }) {
        api.post('/creatorGuide/index')
            .then(response => {
                if (response?.data?.guides) {
                    commit('setGuides', response.data.guides);
                }

                if (response?.data?.refillCommissionPercentage) {
                    commit('setRefillCommissionPercentage', response.data.refillCommissionPercentage);
                }

                if (response?.data?.popularApps) {
                    commit('setPopularApps', response.data.popularApps);
                }
            })
    }
}


const getters = {
    hasIncompleteGuides(state, getters) {
        return getters.incompleteGuides?.length ? true : false
    },
    incompleteGuides(state) {
        return state.guides?.filter(guide => !guide.complete)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
