<template>
    <div>
        <div
            class="px-3 d-flex align-items-center justify-content-between mt-3"
        >
            <brand-link />
            <b-btn-group>
                <b-btn @click="$router.back()" variant="light">
                    <font-awesome-icon icon="fa-light fa-chevron-left" />
                </b-btn>
                <b-btn @click="$router.forward()" variant="light">
                    <font-awesome-icon icon="fa-light fa-chevron-right" />
                </b-btn>
            </b-btn-group>
        </div>

        <div class="mt-3">
            <wallet-summary />
        </div>

        <div v-if="$screen.lg" class="mt-3">
            <message-inbox />
        </div>
    </div>
</template>


<script>
import BrandLink from "@/components/utility/BrandLink";
import MessageInbox from "@/components/messages/MessageInbox";
import WalletSummary from "@/components/right/WalletSummary";

export default {
    components: {
        BrandLink,
        MessageInbox,
        WalletSummary,
    },
};
</script>