<template>
    <div class="p-2">
        <b-form-textarea
            ref="focus"
            class="hide-scrollbars border-light bg-light"
            v-model="message"
            :placeholder="placeholder"
            rows="1"
            max-rows="20"
            tabindex="9"
            maxlength="255"
            :state="state"
            size="lg"
        ></b-form-textarea>
        <b-alert
            variant="danger"
            v-if="error"
            show
            class="rounded-0 border-0 small"
        >
            {{ error }}
        </b-alert>
    </div>
</template>

<script>
import { mapState } from "vuex";

import randomPostMessage from "@/utility/randomPostMessage";

export default {
    data() {
        return {
            currentSetMessageTimeout: null,
            placeholder: null,
            unsubscribeToAction: null,
        };
    },
    mounted() {
        setTimeout(() => {
            this.$refs.focus.focus();
        }, 1);
    },
    created() {
        this.reset();

        this.unsubscribeToAction = this.$store.subscribeAction({
            after: (action) => {
                if (action.type === "creatorPostEdit/photoFinishedProcessing") {
                    this.placeholder = this.$t(
                        "Now add a message to go with your photo..."
                    );
                }

                if (action.type === "creatorPostEdit/publish") {
                    this.reset();
                }
            },
        });

        this.unsubscribeToMutations = this.$store.subscribe((mutation) => {
            if (mutation.type === "creatorPostEdit/reset") {
                this.reset();
            }
        });
    },
    beforeDestroy() {
        if (this.unsubscribeToAction) {
            this.unsubscribeToAction();
        }

        if (this.unsubscribeToMutations) {
            this.unsubscribeToMutations();
        }
    },
    computed: {
        state() {
            if (this.error === null) {
                return null;
            }

            return false;
        },
        message: {
            get() {
                return this.$store.state.creatorPostEdit.message;
            },
            set(value) {
                this.$store.commit("creatorPostEdit/setMessage", value);
            },
        },
        ...mapState("onboarding", ["user"]),
        ...mapState("creatorPostEdit", ["error", "publishInProgress"]),
    },
    methods: {
        reset() {
            this.placeholder = randomPostMessage();
        },
    },
};
</script>