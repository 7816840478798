<template>
    <div>
        <b-alert variant="success" show v-if="complete">
            Your ID has been uploaded successfully. Please allow up to 48 hours
            for verification, we'll email you with an update shortly
        </b-alert>
        <b-alert variant="warning" show v-else>
            Please upload a photo of your identification. This can be a
            passport, drivers license, or national ID card.
        </b-alert>
        <div class="border p-3 bg-white text-left rounded mt-3">
            <h5>
                <font-awesome-icon
                    v-if="idComplete"
                    icon="fa-light fa-check-circle"
                    class="text-success"
                />
                <font-awesome-icon v-else icon="fa-solid fa-circle" />
                Photo ID
            </h5>
            <div class="my-3">
                Upload a clear photo of your ID, with all details visible
            </div>
            <onboarding-document-upload
                v-if="!idComplete"
                @complete="idComplete = true"
                type="id"
            >
                <font-awesome-icon icon="fa-duotone fa-cloud-arrow-up" />
                Upload
            </onboarding-document-upload>
        </div>
    </div>
</template>


<script>
import OnboardingDocumentUpload from "@/components/onboarding/document/OnboardingDocumentUpload";

export default {
    data() {
        return {
            idComplete: false,
        };
    },
    computed: {
        complete() {
            return this.idComplete;
        },
    },
    methods: {
        next() {
            this.$store.dispatch("onboarding/status");
        },
    },
    components: {
        OnboardingDocumentUpload,
    },
};
</script>