<template>
    <div>
        <div class="swiper" ref="swiper">
            <div class="swiper-wrapper">
                <div v-if="false">
                    <div
                        class="swiper-slide"
                        :key="video.id"
                        v-for="video in profile.videos"
                    >
                        <video-player
                            :loop="true"
                            :muted="true"
                            :controls="false"
                            :autoplay="true"
                            :mp4Url="video.mp4Url"
                            :webmUrl="video.webmUrl"
                        />
                    </div>
                </div>
                <div
                    class="swiper-slide"
                    :key="photo.id"
                    v-for="photo in profile.photos"
                >
                    <img :src="photo.medium" />
                </div>
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
            <div class="swiper-scrollbar"></div>
            <div
                class="swiper-custom-expand glass rounded-circle d-flex shadow-sm align-items-center justify-content-center fake-link"
                @click="fullSize"
            >
                <font-awesome-icon
                    size="lg"
                    icon="fa-light fa-arrows-maximize"
                />
            </div>
        </div>

        <b-modal
            hide-header
            hide-footer
            v-model="modal"
            body-class="p-0"
            modal-class="modal-full-height"
            no-close-on-esc
        >
            <div>
                <div class="d-flex justify-content-between floating-menu-bar">
                    <div></div>
                    <b-btn
                        @click="
                            $store.commit(
                                'interface/setProfileFullSizeGalleryVisible',
                                false
                            )
                        "
                        variant="light"
                        class="action-button glass border-0"
                    >
                        <font-awesome-icon size="lg" icon="fa-light fa-times" />
                    </b-btn>
                </div>
                <div class="swiper swiper-fullsize" ref="swiperFullSize">
                    <div class="swiper-wrapper">
                        <div v-if="false">
                            <div
                                class="swiper-slide"
                                :key="video.id"
                                v-for="video in profile.videos"
                            >
                                <video-player
                                    :muted="false"
                                    :controls="true"
                                    :autoplay="false"
                                    :mp4Url="video.mp4Url"
                                    :webmUrl="video.webmUrl"
                                />
                            </div>
                        </div>
                        <div
                            class="swiper-slide"
                            :key="photo.id"
                            v-for="photo in profile.photos"
                        >
                            <div class="swiper-zoom-container">
                                <img :src="photo.large" />
                            </div>
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>
                    <div class="swiper-scrollbar"></div>
                </div>
            </div>
        </b-modal>
    </div>
</template>


<script>
import Swiper, { Navigation, Pagination, Keyboard, Zoom } from "swiper";

import VideoPlayer from "@/components/utility/VideoPlayer";

import "swiper/swiper-bundle.css";
import { mapState } from "vuex";

Swiper.use([Navigation, Pagination, Keyboard, Zoom]);

export default {
    data() {
        return {
            swiper: null,
            swiperFullSize: null,
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.initialize();
        });
    },
    computed: {
        ...mapState("profile", ["profile"]),
        modal: {
            get() {
                return this.$store.state.interface
                    .profileFullSizeGalleryVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setProfileFullSizeGalleryVisible",
                    value
                );
            },
        },
    },
    methods: {
        fullSize() {
            this.$store.commit(
                "interface/setProfileFullSizeGalleryVisible",
                true
            );

            this.$nextTick(() => {
                this.swiperFullSize = new Swiper(this.$refs.swiperFullSize, {
                    keyboard: true,
                    zoom: true,
                    pagination: {
                        el: ".swiper-pagination",
                    },
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    scrollbar: {
                        el: ".swiper-scrollbar",
                    },
                });
            });
        },
        initialize() {
            this.swiper = new Swiper(this.$refs.swiper, {
                pagination: {
                    el: ".swiper-pagination",
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                scrollbar: {
                    el: ".swiper-scrollbar",
                },
            });
        },
    },
    components: {
        VideoPlayer,
    },
};
</script>