import api from "@/service/api";

const state = () => ({
    hasMoreMessages: false,
    messageIndexLoading: false,
    hasLoadedMessages: false,
    page: 1,
    messages: [],
    typing: [],
    typingTimeout: null,
    view: 'inbox',
    locallyMessagedUserIds: [],
})

const mutations = {
    probablyHasMoreMessages(state) {
        state.hasMoreMessages = true
    },
    setView(state, value) {
        state.view = value
    },
    noMoreMessages(state) {
        state.hasMoreMessages = false
    },
    next(state) {
        state.page = state.page + 1;
    },
    userIsTyping(state, userId) {
        state.typing.push(userId)
    },
    userDoneTyping(state, userId) {
        state.typing = state.typing.filter(typingUserId => typingUserId !== userId)
    },
    setTypingTimeout(state, timeout) {
        state.typingTimeout = timeout
    },
    setMessages(state, messages) {
        state.messages = messages
    },
    setHasLoadedMessages(state, value) {
        state.hasLoadedMessages = value
    },
    appendLocallyMessagedUserId(state, userId) {
        state.locallyMessagedUserIds.push(userId)
    },
    appendMessages(state, messages) {
        state.messages = [
            ...state.messages,
            ...messages,
        ]
    },
    deleteConversation(state, userId) {
        if (!state.messages) {
            return;
        }

        state.messages = state.messages.filter(message => message.userId !== userId)
    },
    inboundMessage(state, message) {
        if (!state.messages) {
            return;
        }

        state.messages = state.messages.filter(searchMessage => searchMessage.userId !== message.userId)
        state.messages.unshift(message)
    },
    markPreviewRead(state, userId) {
        if (!state.messages) {
            return;
        }

        // patch local state until next refresh
        state.messages.forEach((message, index) => {
            if (message.userId === userId) {
                state.messages[index].seen = true
            }
        })
    },
    setMessageIndexLoading(state, value) {
        state.messageIndexLoading = value
    }
}

const actions = {
    typing({ state, commit }, userId) {
        commit('userIsTyping', userId)

        if (state.typingTimeout) {
            clearTimeout(state.typingTimeout)
        }

        const timeout = setTimeout(() => {
            commit('userDoneTyping', userId)
        }, 3000)

        commit('setTypingTimeout', timeout)
    },
    refresh({ commit, state }) {
        commit('setMessageIndexLoading', true)

        api.post("/message/preview", {
            view: state.view
        }).then((response) => {
            if (response?.data?.messages) {
                commit('setMessages', response.data.messages);
            }

            if (response?.data?.probablyHasMore) {
                commit('probablyHasMoreMessages');
            }
        })
            .catch(() => { })
            .then(() => {
                commit('setMessageIndexLoading', false)
                commit('setHasLoadedMessages', true)
            })
    },
    next({ commit, state }) {
        commit('next')

        // CANCEROUS
        // should just use next page, 
        // but need to re-engineer, ugh
        api.post("/message/preview", {
            view: state.view,
            page: state.page
        }).then((response) => {
            if (response?.data?.messages) {
                if (response.data.messages.length) {
                    commit('appendMessages', response.data.messages);
                } else {
                    commit('noMoreMessages')
                }
            }
        });
    },
}

const getters = {
    hasMessagedUserId: (state, getters, rootState) => (userId) => {
        return state.locallyMessagedUserIds.includes(userId) || rootState.onboarding.user?.sentMessageUserIds?.includes(userId);
    },
    filteredMessages(state, getters, rootState) {
        if (!state.messages) {
            return []
        }

        const unblockedMessages = state.messages
            .filter(message => !rootState.user.blockedUserIds.includes(message.userId))

        return unblockedMessages
    },
    unreadConversationCount(state, getters) {
        if (!state.messages) {
            return 0;
        }

        return getters.filteredMessages.filter(message => !message.self && !message.seen).length
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
