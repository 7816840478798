<template>
    <div class="p-2">
        <back-to-dashboard />

        <div>
            {{
                $t(
                    "You can take a screenshot of the QR code for easy sharing without having to directly send the link"
                )
            }}
        </div>

        <div class="mt-3">
            {{
                $t(
                    "The QR code shown includes your unique tracking URL to credit signups to your account"
                )
            }}
        </div>

        <div class="mt-3">
            <b-btn @click="showQrcode" variant="primary" block pill>
                <font-awesome-icon icon="fa-light fa-qrcode" />
                {{ $t("Show QR Code") }}
            </b-btn>
        </div>

        <b-modal
            v-model="modal"
            hide-footer
            hide-header
            centered
            body-class="bg-light rounded-lg"
            modal-class="modal-height-100"
        >
            <div class="col-12 col-md-3 mx-auto text-center">
                <div class="my-3">
                    <img
                        :src="brand.homepageLogo"
                        class="w-100 rounded-circle thumbnail"
                        style="max-width: 200px"
                    />
                </div>
                <h1>
                    {{ brand.name }}
                </h1>
                <h5>
                    {{ brand.slogan }}
                </h5>
                <div class="mt-3">
                    {{ brand.description }}
                </div>

                <div class="text-center">
                    <img v-if="qrcode" :src="qrcode" />
                </div>

                <b-btn @click="closeModal" variant="light" pill size="sm">
                    {{ $t("Close Screen") }}
                </b-btn>
            </div>
        </b-modal>
    </div>
</template>



<script>
import { mapState } from "vuex";

import BackToDashboard from "@/components/utility/BackToDashboard";

export default {
    data() {
        return {
            qrcode: null,
        };
    },
    computed: {
        ...mapState("brand", ["brand"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        modal: {
            get() {
                return this.$store.state.interface
                    .creatorShareQrcodeModalVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setCreatorShareQrcodeModalVisible",
                    value
                );
            },
        },
    },
    methods: {
        closeModal() {
            this.$store.commit(
                "interface/setCreatorShareQrcodeModalVisible",
                false
            );
        },
        showQrcode() {
            this.$store.commit(
                "interface/setCreatorShareQrcodeModalVisible",
                true
            );

            this.api.post("/invite/qrcode").then((response) => {
                if (response?.data?.qrcode) {
                    this.qrcode = response.data.qrcode;
                }
            });
        },
    },
    components: {
        BackToDashboard,
    },
};
</script>