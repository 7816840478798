<template>
    <div>
        <h5 class="text-primary mt-3">
            <font-awesome-icon
                class="text-warning"
                icon="fa-duotone fa-fire"
                size="lg"
            />
            High Quality Content Only
        </h5>
        <div>
            Your gallery should have high quality content, you should be looking
            your best and showcasing your best assets! If you upload a photo of
            your dog, your lunch or a sunset, it will not be accepted, every
            gallery item should be
            <font-awesome-icon
                class="mr-1 text-warning"
                icon="fa-duotone fa-fire"
                size="lg"
            />
        </div>

        <h5 class="text-primary mt-3">{{ $t("How galleries work") }}</h5>
        {{
            $t(
                "Add photos and videos to a gallery that users can purchase. After you create the gallery, users can see it on your profile and also in our list of galleries"
            )
        }}

        <h5 class="text-primary mt-3">{{ $t("Set your pricing") }}</h5>
        {{
            $t(
                "Set a realistic price for your gallery to ensure users will purchase it, if the price is unrealistic it may be rejected, or adjusted up or down. The highest price should be reserved for videos, photos should have a lower price"
            )
        }}

        <h5 class="text-primary mt-3">{{ $t("Invite to earn more") }}</h5>
        <div>
            {{
                $t(
                    "After you create your gallery, you can invite users to view it from their profile, inviting users costs 1 credit per invite sent"
                )
            }}
        </div>

        <div class="text-center branded py-3">
            <font-awesome-icon icon="fa-light fa-arrow-down" size="2x" />
        </div>

        <b-btn @click="createGallery" variant="primary" pill block size="lg">
            {{ $t("Create My First Gallery") }}
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("creatorGuide", ["currentGuide", "guides"]),
        modal: {
            get() {
                return this.$store.state.creatorGuide.currentGuide
                    ? true
                    : false;
            },
            set(value) {
                if (!value) {
                    this.$store.commit("creatorGuide/reset");
                }
            },
        },
    },
    methods: {
        createGallery() {
            this.$router.push({ name: "creatorGallery" });
            this.$store.commit("creatorGuide/reset");
        },
    },
    components: {},
};
</script>


