<template>
    <div>
        <b-alert show variant="warning">
            {{
                $t(
                    "Video calling is only supported from the browser, please open the app in your web browser to continue the call"
                )
            }}

            <div class="mt-3">
                <font-awesome-icon icon="fa-duotone fa-info-circle" />
                {{
                    $t(
                        "You will not be charged until you join the call on your browser"
                    )
                }}
            </div>

            <div class="text-center branded py-3">
                <font-awesome-icon icon="fa-light fa-arrow-down" size="2x" />
            </div>

            <b-btn
                v-if="url"
                @click="openBrowser"
                variant="primary"
                size="lg"
                pill
                block
            >
                <font-awesome-icon
                    icon="fa-duotone fa-arrow-up-right-from-square"
                />
                {{ $t("Open in Browser") }}
            </b-btn>

            <div class="mt-3">
                <b-btn
                    v-if="url"
                    @click="copyUrl"
                    variant="link"
                    size="sm"
                    pill
                    block
                >
                    {{ $t("Copy Video Call URL") }}
                </b-btn>
            </div>
        </b-alert>
        <div class="mt-3 text-center">
            <b-btn @click="hangup" class="call-button" variant="danger">
                <font-awesome-icon icon="fa-duotone fa-phone-hangup" />
            </b-btn>
        </div>
    </div>
</template>



<script>
import { mapState } from "vuex";

import copy from "clipboard-copy";

export default {
    data() {
        return {
            url: null,
        };
    },
    mounted() {
        this.api.post(`/call/${this.currentCall.id}/url`).then((response) => {
            if (response?.data?.url) {
                this.url = response?.data?.url;
            }
        });
    },
    computed: {
        ...mapState("call", ["currentCall"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        hangup() {
            this.$store.dispatch("call/hangup");
        },
        openBrowser() {
            this.$store.dispatch("system/openBrowser", this.url);
        },
        copyUrl() {
            copy(this.url);
        },
    },
};
</script>