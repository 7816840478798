<template>
    <div>
        <b-alert variant="warning" show>
            <h5>
                {{ $t("You have unpaid fines") }}
            </h5>
            <div>
                {{
                    $t(
                        "You must pay off all of your fines to be eligible to withdraw. Cancel the withdrawal to return credits to your wallet, once the fines are paid, you can submit a new withdrawal"
                    )
                }}
            </div>

            <b-btn
                to="/creator/fines"
                variant="primary"
                block
                pill
                size="lg"
                class="mt-3"
            >
                {{ $t("Pay Fines") }}
            </b-btn>
        </b-alert>
    </div>
</template>

<script>
// import { mapState } from "vuex";

export default {
    computed: {},
    components: {},
};
</script>