import api from "@/service/api";

const startPage = 0
const defaultView = 'video'

const state = {
    limitedResults: false,
    probablyHasMoreSearchResults: true,
    finishedInitialLoad: false,
    lastRefresh: null,
    videos: null,
    page: startPage,
    searchView: defaultView,
    dismissed: false,
    hasScrolled: false,
    hasScrolledToPagination: false,
}

const mutations = {
    reset(state) {
        this._vm.$scrollToTop();

        state.page = startPage
        state.dismissed = false
        state.videos = null
        state.limitedResults = false
        state.hasScrolledToPagination = false
        state.searchView = defaultView
    },
    finishedInitialLoad(state) {
        state.finishedInitialLoad = true
    },
    setView(state, searchView) {
        state.searchView = searchView
    },
    setVideos(state, value) {
        state.videos = value
    },
    setDismissed(state, value) {
        state.dismissed = value
    },
    nextPage(state) {
        state.page++;
    },
    previousPage(state) {
        state.page--;
    },
    setLastRefresh(state) {
        state.lastRefresh = new Date()
    },
    setHasScrolled(state, value) {
        state.hasScrolled = value
    },
    setProbablyHasMoreSearchResults(state, value) {
        state.probablyHasMoreSearchResults = value

        if (!state.probablyHasMoreSearchResults) {
            state.limitedResults = true
        } else {
            state.limitedResults = false
        }
    },
    setHasScrolledToPagination(state, value) {
        state.hasScrolledToPagination = value
    },
}

const actions = {
    setView({ commit, dispatch }, view) {
        commit('reset')
        commit('setView', view)
        dispatch('refresh')
    },
    loadInitial({ commit, state, dispatch }) {
        if (!state.finishedInitialLoad) {
            // order here matters
            // this wipes view
            commit('reset')

            // reload search with updated view
            dispatch('refresh')
            commit('finishedInitialLoad')
        }
    },
    refresh({ state, commit }) {
        commit('setLastRefresh')
        commit('setDismissed', false)

        api
            .post("/videoIndex/index", {
                page: state.page,
                view: state.searchView
            })
            .then((response) => {
                commit('setVideos', response.data.videos)
                commit('setProbablyHasMoreSearchResults', response.data.probablyHasMoreSearchResults)
            })
            .catch(() => { })
    },
    nextPage({ commit, dispatch }) {
        commit('setVideos', null)
        commit('nextPage')
        dispatch('refresh')
        commit("setHasScrolled", false);
    },
    previousPage({ commit, dispatch }) {
        commit('setVideos', null)
        commit('previousPage')
        dispatch('refresh')
    },
    dismiss({ commit }) {
        commit('setDismissed', true)

        setTimeout(() => {
            commit('setDismissed', false)
        }, 60000 * 5);
    },
    resetSearch({ commit }) {
        commit('reset');
    },
}

const getters = {
    filteredVideos(state, getters, rootState) {
        if (!state.videos) {
            return []
        }

        if (!rootState?.user?.blockedUserIds?.length) {
            return state.videos;
        }

        return state.videos.filter(video => !rootState.user.blockedUserIds.includes(video?.standardMap?.id))
    },
    isDefaultSearchView(state) {
        return state.searchView === defaultView;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
