import api from "@/service/api";
import router from "@/router";

const defaultVisibility = 'public';

const state = () => ({
    page: 1,
    hasMorePages: false,
    creatorPosts: null,
    creatorPost: null,
    error: null,
    days: null,
    insufficientCredits: false,
    schedulePostCount: null,
    visibility: defaultVisibility,
})

const mutations = {
    reset(state) {
        state.error = null;
        state.hasMorePages = false;
        state.insufficientCredits = false
        state.visibility = defaultVisibility;
        state.page = 1;
    },
    setCreatorPosts(state, value) {
        state.creatorPosts = value;
    },
    setCreatorPost(state, value) {
        state.creatorPost = value;
    },
    setInsufficientCredits(state, value) {
        state.insufficientCredits = value;
    },
    setHasMorePages(state, value) {
        state.hasMorePages = value;
    },
    setVisibility(state, value) {
        state.visibility = value;
    },
    setDays(state, value) {
        state.days = value
    },
    setSchedulePostCount(state, value) {
        state.schedulePostCount = value
    },
    setError(state, value) {
        state.error = value
    },
    nextPage(state) {
        state.page++;
    },
    previousPage(state) {
        state.page--;
    },
}

const actions = {
    nextPage({ commit, dispatch }) {
        commit('setCreatorPosts', null)
        commit('nextPage')
        dispatch('refresh')
    },
    previousPage({ commit, dispatch }) {
        commit('setCreatorPosts', null)
        commit('previousPage')
        dispatch('refresh')
    },
    refresh({ commit, state }) {
        api.post('/creatorPost/mine', {
            page: state.page
        }).then((response) => {
            if (response?.data?.creatorPosts) {
                commit('setCreatorPosts', response.data.creatorPosts)
                commit('setHasMorePages', response.data.hasMorePages)
                commit('setSchedulePostCount', response.data.schedulePostCount)
            }
        });
    },
    create({ dispatch, commit }, { publishedAt, isPrivate } = {}) {
        commit('setInsufficientCredits', false);

        api.post('/creatorPost/create', {
            publishedAt,
            isPrivate
        }).then((response) => {
            if (response?.data?.creatorPost) {
                dispatch('onboarding/status', null, { root: true })

                router.push({
                    name: 'creatorPostEdit',
                    params: {
                        id: response.data.creatorPost.id,
                    },
                })
            }
        }).catch(error => {
            if (error?.response?.data?.code === 'insufficientCredits') {
                commit('setInsufficientCredits', true);
            }
        })
    },
    get({ dispatch }, creatorPostId) {
        api.post('/creatorPost/getMine', {
            creatorPostId
        }).then((response) => {
            if (response?.data?.creatorPost) {
                dispatch('setCreatorPost', response.data.creatorPost)
            }
        });
    },
    setCreatorPost({ commit }, creatorPost) {
        commit('setCreatorPost', creatorPost)
        commit('setVisibility', creatorPost.visibility)
    },
    refreshSchedule({ commit }) {
        api.post('/creatorPost/schedule').then((response) => {
            if (response?.data?.days) {
                commit('setDays', response.data.days)
            }
        });
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
