const calculateDuration = (timestamp) => {
    // Convert the ISO timestamp to a Date object
    const startDate = new Date(timestamp);
    const endDate = new Date(); // Current time

    // Calculate the duration in milliseconds
    const durationMilliseconds = endDate - startDate;

    // Convert the duration to hours, minutes, and seconds
    const seconds = Math.floor(durationMilliseconds / 1000);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Format hours, minutes, and seconds to have two digits
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    // Return the formatted time
    if (hours > 0) {
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
        return `${formattedMinutes}:${formattedSeconds}`;
    }
};


export {
    calculateDuration,
};
