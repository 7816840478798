<template>
    <div>
        <b-btn
            @click="emailSupport"
            button
            variant="light"
            block
            pill
            class="d-flex align-items-center mt-3"
        >
            <div class="mr-2">
                <font-awesome-icon
                    class="small-avatar branded"
                    icon="fa-duotone fa-circle-question"
                    size="lg"
                />
            </div>
            Contact Support
        </b-btn>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        emailSupport() {
            this.$store.dispatch("support/contact");
        },
    },
};
</script>