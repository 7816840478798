<template>
    <div>
        <div v-if="createPostCreatorPost && !creatorPost" class="px-2 text-right">
            <div class="mt-3 text-primary">
                {{ $t("Hit the post button to publish it") }}
            </div>
            <div class="branded my-2 mr-5">
                <font-awesome-icon icon="fa-light fa-arrow-down" size="2x" />
            </div>
        </div>

        <div class="p-2 d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <create-post-help />
                <create-post-options />
            </div>
            <div>
                <div v-if="creatorPost.editable">
                    <b-btn
                        :disabled="postDisabled"
                        variant="primary"
                        @click="publishPost"
                        tabindex="10"
                        class="px-5"
                        pill
                    >
                        <span v-if="creatorPost.published">
                            {{ $t("Update") }}
                        </span>
                        <span v-else>
                            {{ $t("Publish") }}
                        </span>
                    </b-btn>
                </div>
                <div v-else class="text-primary">
                    <font-awesome-icon icon="fa-solid fa-circle" size="xs" />
                    {{ $t("Published") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import CreatePostOptions from "@/components/post/create/CreatePostOptions";
import CreatePostHelp from "@/components/post/create/CreatePostHelp";

export default {
    data() {
        return {
            message: null,
        };
    },
    computed: {
        createPostCreatorPost() {
            return this.$store.state.creatorPostEdit?.creatorPost;
        },
        postDisabled() {
            if (
                this.uploadInProgress.length ||
                this.uploadQueue.length ||
                this.publishInProgress ||
                this.updateInProgress ||
                this.createInProgress
            ) {
                return true;
            }

            if (!this.creatorPost) {
                return true;
            }

            return false;
        },
        myInterestCount() {
            if (!this.$store.state.interest?.outbound?.length) {
                return null;
            }

            return this.$store.state.interest.outbound.length;
        },
        ...mapState("creatorPostEdit", [
            "creatorPost",
            "publishInProgress",
            "updateInProgress",
            "createInProgress",
        ]),
        ...mapState("createPostPhoto", ["uploadQueue", "uploadInProgress"]),
    },
    methods: {
        togglePreview() {
            this.$store.commit(
                "creatorPostEdit/setPreviewPost",
                !this.$store.state.creatorPostEdit.previewPost
            );
        },
        cancelPost() {
            this.$store.commit("creatorPostEdit/reset");
        },
        publishPost() {
            this.$store.dispatch("creatorPostEdit/publish");
        },
    },
    components: {
        CreatePostOptions,
        CreatePostHelp,
    },
};
</script>