<template>
    <div>
        <b-badge
            v-if="creatorGallery.photoCount"
            variant="light"
            pill
            class="mx-1"
        >
            {{ creatorGallery.photoCount }}

            {{ $t("Photo") | pluralize(creatorGallery.photoCount) }}
        </b-badge>
        <b-badge
            v-if="creatorGallery.videoCount"
            variant="light"
            pill
            class="mx-1"
        >
            {{ creatorGallery.videoCount }}
            {{ $t("Video") | pluralize(creatorGallery.videoCount) }}
        </b-badge>
    </div>
</template>

<script>
export default {
    props: ["creatorGallery"],
};
</script>