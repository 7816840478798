<template>
    <b-list-group-item v-if="profile.vetted" class="setting-item">
        <div class="label">
            {{ $t("Status") }}
        </div>
        <div class="value" v-b-modal.modalVettedExplainer>
            <div v-if="profile.vetted" class="text-primary">
                <font-awesome-icon
                    icon="fa-duotone fa-shield-check"
                    size="lg"
                />
                {{ $t("Verified") }}
            </div>
            <div v-else>
                {{ $t("Unverified") }}
            </div>
        </div>
        <b-modal
            centered
            id="modalVettedExplainer"
            v-model="modal"
            :title="$t('Verified Status')"
            hide-footer
        >
            <div class="text-center">
                <div class="py-3">
                    <div class="text-primary">
                        <font-awesome-icon
                            size="5x"
                            icon="fa-duotone fa-shield-check"
                        />
                    </div>
                </div>
                <div class="mb-0">
                    {{
                        $t(
                            "A member of our staff has verified their profile by using their provided video to ensure their photos match their video. Any changes to their profile will require re-verification"
                        )
                    }}
                </div>
            </div>
        </b-modal>
    </b-list-group-item>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            modal: false,
        };
    },
    computed: {
        ...mapState("profile", ["profile"]),
    },
};
</script>