<template>
    <div>
        <div class="py-3">
            <h3 class="text-center">Welcome Filipino4U Members!</h3>
            <div>
                Filipino4U is now part of the Findmate Network! Get ready for an
                exciting new experience with more features and new users to
                connect with. Enjoy live video calls, texting, live streaming,
                and more!
            </div>

            <h5 class="mt-3">Is My Account Still Active?</h5>
            <div>
                Yes, your account is still active! Simply log in to the new
                website, and we will restore your account from the Filipino4U
                database.
            </div>

            <h5 class="mt-3">What has changed?</h5>
            <div>
                Filipino4U.com has been acquired by Findmate, LLC, a U.S.-based
                company that operates several dating brands and specializes in
                international dating. The new website URL is now
                <code>www.Filipina.app</code>.
            </div>

            <b-row class="mt-4">
                <b-col cols="12" md="6" class="my-2">
                    <b-btn
                        variant="primary"
                        @click="closeModal"
                        pill
                        block
                        size="lg"
                    >
                        {{ $t("Ok, got it") }}
                    </b-btn>
                </b-col>
                <b-col cols="12" md="6" class="my-2">
                    <b-btn
                        variant="secondary"
                        @click="maybeLater"
                        pill
                        block
                        size="lg"
                    >
                        {{ $t("Remind me later") }}
                    </b-btn>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        currentOffer() {
            return this.$store.state.displayOffer?.currentOffer;
        },
    },
    methods: {
        closeModal() {
            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
        maybeLater() {
            this.api.post("/offer/removeViewedOffer", {
                offer: this.currentOffer.offer,
            });

            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
    },
};
</script>