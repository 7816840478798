<template>
    <div>
        <b-alert
            v-if="failedToLoad"
            variant="warning"
            show
            class="width-slim mx-auto text-center"
        >
            <div v-if="isConnected">
                <h5>Service Temporarily Unavailable</h5>

                <div class="small">
                    We are experiencing a temporary issue loading our service.
                    We're aware of the problem and working to resolve it as soon
                    as possible. Thank you for your patience.
                </div>
            </div>
            <div v-else>
                <h5>Connection Failed</h5>

                <div class="small">
                    Failed to connect, check your connection and try again.
                </div>
            </div>

            <b-btn class="mt-3" pill variant="light" @click="retryStartup">
                <div v-if="retryingStartup">
                    <b-spinner small />
                    Trying to connect...
                </div>
                <div v-else>Retry</div>
            </b-btn>
        </b-alert>
    </div>
</template>



<script>
import { mapState } from "vuex";

const TIMEOUT_DURATION = process.env.NODE_ENV == "development" ? 3000 : 10000;

export default {
    data() {
        return {
            isConnected: navigator.onLine,
            failedToLoad: false,
            retryingStartup: false,
        };
    },
    created() {
        window.addEventListener("online", this.updateStatus);
        window.addEventListener("offline", this.updateStatus);
    },
    beforeDestroy() {
        window.removeEventListener("online", this.updateStatus);
        window.removeEventListener("offline", this.updateStatus);
    },
    mounted() {
        setTimeout(() => {
            if (!this.brand) {
                this.failedToLoad = true;
            }
        }, TIMEOUT_DURATION);
    },
    methods: {
        updateStatus() {
            this.isConnected = navigator.onLine;
        },
        retryStartup() {
            this.retryingStartup = true;

            this.$store.dispatch("startup/run");

            setTimeout(() => {
                this.retryingStartup = false;
            }, 5000);
        },
    },
    computed: {
        ...mapState("brand", ["brand"]),
    },
};
</script>
        