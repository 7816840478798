<template>
    <div>
        <div v-if="purchaseComplete">
            <refill-complete />
        </div>
        <div v-else-if="price">
            <refill-purchase />
        </div>
        <div v-else>
            <refill-options />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import RefillOptions from "@/components/wallet/refill/RefillOptions";
import RefillPurchase from "@/components/wallet/refill/RefillPurchase";
import RefillComplete from "@/components/wallet/refill/RefillComplete";


export default {
    computed: {
        ...mapState("wallet", ["price", "purchaseComplete"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        RefillOptions,
        RefillPurchase,
        RefillComplete,
    },
};
</script>