<template>
    <b-btn
        v-if="onboardingUser.unpaidFineCount"
        to="/creator/fines"
        class="bg-white border-danger"
        variant="light"
        block
        size="lg"
    >
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="mr-2">
                    <font-awesome-icon
                        class="text-danger"
                        icon="fa-duotone fa-triangle-exclamation"
                        size="lg"
                    />
                </div>
                {{ $t("Fines") }}
            </div>
            <div class="text-primary">
                <b-badge variant="danger" pill>
                    {{ onboardingUser.unpaidFineCount }}
                    {{ $t("unpaid") }}
                </b-badge>
            </div>
        </div>
    </b-btn>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
};
</script>
