<template>
    <div>
        <div class="d-flex">
            <b-btn
                class="rounded-0 border-0 w-100"
                :class="{
                    'text-primary': isCurrentView(item),
                }"
                :key="item.label"
                v-for="item in subMenuItems"
                @click="setMessageView(item)"
                variant="light"
            >
                {{ item.label }}
            </b-btn>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            subpage: "message",
            subMenuItems: [
                {
                    key: "inbox",
                    label: this.$t("Inbox"),
                },
                {
                    key: "unread",
                    label: this.$t("Unread"),
                },
                {
                    key: "sent",
                    label: this.$t("Sent"),
                },
            ],
        };
    },
    computed: {
        ...mapState("message", ["view"]),
    },
    methods: {
        isCurrentView(item) {
            return item.key === this.view;
        },
        setMessageView(item) {
            this.$store.commit("message/setView", item.key);
            this.$store.dispatch("message/refresh");
        },
    },
    components: {},
};
</script>
        
        