<template>
    <div>
        <b-btn
            @click="toggleFavorite"
            v-if="favorited"
            variant="light"
            class="btn-brand"
            pill
        >
            <font-awesome-icon
                class="text-primary"
                icon="fa-duotone fa-thumbs-up"
            />
        </b-btn>
        <b-btn
            @click="toggleFavorite"
            v-else
            variant="light"
            class="btn-brand"
            pill
        >
            <font-awesome-icon icon="fa-duotone fa-thumbs-up" />
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            remoteIncludesMyFavorite: false,
            localFavorited: false,
            localUnfavorited: false,
        };
    },
    computed: {
        hasFavoritedPosts() {
            return this.onboardingUser.hasFavoritedPosts;
        },
        favorited() {
            if (this.localFavorited) {
                return true;
            }

            if (this.localUnfavorited) {
                return false;
            }

            if (
                this.creatorPost.creatorPostFavorites?.filter(
                    (favorite) => favorite.userId === this.onboardingUser.id
                )?.length
            ) {
                return true;
            }

            if (this.favorites.includes(this.creatorPost.id)) {
                return true;
            }

            return false;
        },
        ...mapState("postFavorite", ["favorites"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        toggleFavorite() {
            if (this.favorited) {
                this.unfavorite();
            } else {
                this.favorite();
            }
        },
        favorite() {
            this.localFavorited = true;
            this.localUnfavorited = false;

            const creatorPostId = this.creatorPost.id;

            this.$store.commit("postFavorite/appendPostFavorite", creatorPostId);

            this.api.post("/favorite/add", { creatorPostId }).then((response) => {
                if (response?.data?.creatorPost) {
                    this.$store.commit(
                        "post/postUpdated",
                        response.data.creatorPost
                    );
                }
            });
        },
        unfavorite() {
            this.localFavorited = false;
            this.localUnfavorited = true;

            const creatorPostId = this.creatorPost.id;

            this.$store.commit("postFavorite/removePostFavorite", creatorPostId);

            this.api
                .post("/favorite/remove", { creatorPostId })
                .then((response) => {
                    if (response?.data?.creatorPost) {
                        this.$store.commit(
                            "post/postUpdated",
                            response.data.creatorPost
                        );
                    }
                });
        },
    },
    props: ["creatorPost", "size"],
};
</script>