<template>
    <div>
        <b-alert variant="danger" show v-if="error">
            {{ error }}
        </b-alert>

        <div class="d-flex justify-content-between align-items-center">
            <div>
                {{ $t("Card Number") }}
            </div>
            <div>
                <b-btn
                    v-if="onboardingUser.primaryCreditCard"
                    @click="cancelCreateCard"
                    variant="link"
                >
                    {{ $t("Cancel") }}
                </b-btn>
            </div>
        </div>

        <b-form-input
            v-model="card"
            autocomplete="cc-number"
            inputmode="numeric"
            class="input-element"
            v-cleave="{
                creditCard: true,
            }"
            :placeholder="$t('Card Number')"
            size="lg"
        />

        <b-row no-gutters class="mt-2">
            <b-col cols="3">
                {{ $t("Expiration") }}
            </b-col>
            <b-col cols="3"> </b-col>
            <b-col cols="4" offset="2">
                {{ $t("Security Code") }}
            </b-col>
        </b-row>

        <b-row no-gutters>
            <b-col cols="3">
                <b-form-select
                    v-model="month"
                    :options="expirationMonths"
                    autocomplete="cc-exp-month"
                    size="lg"
                >
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            <span v-if="$screen.md">
                                {{ $t("Month") }}
                            </span>
                            <span v-else>
                                {{ $t("MM") }}
                            </span>
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col cols="3">
                <b-form-select
                    v-model="year"
                    :options="expirationYears"
                    autocomplete="cc-exp-year"
                    size="lg"
                >
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            <span v-if="$screen.md">
                                {{ $t("Year") }}
                            </span>
                            <span v-else>
                                {{ $t("YY") }}
                            </span>
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col cols="4" offset="2">
                <b-form-input
                    type="text"
                    v-model="code"
                    autocomplete="cc-csc"
                    size="lg"
                    :placeholder="$t('CVV')"
                ></b-form-input>
            </b-col>
        </b-row>

        <div class="mt-2">Country</div>
        <b-select
            size="lg"
            v-model="countryId"
            :options="countriesMapped"
            autocomplete="off"
        >
        </b-select>

        <div v-if="!skipPostcode">
            <div class="mt-2">
                {{ zipOrPostalCode }}
            </div>
            <b-form-input
                type="text"
                v-model="postalCode"
                autocomplete="postal-code"
                size="lg"
                :placeholder="zipOrPostalCode"
            ></b-form-input>
        </div>

        <refill-brand-warning />

        <b-btn
            :disabled="disabled"
            @click="createAndChargeCard"
            size="lg"
            variant="primary"
            pill
            block
            class="mt-4"
        >
            Pay ${{ price.amount }}
            <div class="small text-opaque">
                {{ price.localPrice }}
            </div>
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

import Cleave from "cleave.js";

import RefillBrandWarning from "@/components/wallet/refill/RefillBrandWarning";

export default {
    created() {
        if (this.onboardingUser.countryId) {
            this.countryId = this.onboardingUser.countryId;
        }
    },
    data() {
        return {
            countryId: null,
            card: null,
            month: null,
            year: null,
            code: null,
            postalCode: null,
        };
    },
    computed: {
        selectedCountry() {
            return this.countries.find(
                (country) => country.id === this.countryId
            );
        },
        skipPostcode() {
            return this.selectedCountry?.doesntUsePostcode;
        },
        zipOrPostalCode() {
            return this.selectedCountry?.zip ? "ZIP Code" : "Postal Code";
        },
        countriesMapped() {
            return this.countries.map((country) => ({
                value: country.id,
                text: country.name,
            }));
        },
        ...mapState("options", [
            "expirationYears",
            "expirationMonths",
            "countries",
        ]),
        ...mapState("wallet", ["price"]),
        ...mapState("brand", ["brand"]),
        ...mapState("authorize", ["saving", "error"]),
        expiration() {
            return this.year && this.month;
        },
        complete() {
            return (
                this.card &&
                this.expiration &&
                this.code &&
                (this.postalCode || this.skipPostcode)
            );
        },
        disabled() {
            return this.saving || !this.complete;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        cancelCreateCard() {
            this.$store.commit("wallet/setCreateCard", false);
        },
        changePackage() {
            this.$store.commit("wallet/setPrice", null);
        },
        createAndChargeCard() {
            this.$store.dispatch("authorize/createAndChargeCard", {
                priceId: this.price.id,
                card: this.card,
                year: this.year,
                month: this.month,
                code: this.code,
                postalCode: this.postalCode,
                countryId: this.countryId,
            });
        },
    },
    // forgive me
    // putting this one on the plastic for now
    directives: {
        cleave: {
            inserted: (el, binding) => {
                el.cleave = new Cleave(el, binding.value || {});
            },
            update: (el) => {
                const event = new Event("input", { bubbles: true });
                setTimeout(function () {
                    el.value = el.cleave.properties.result;
                    el.dispatchEvent(event);
                }, 100);
            },
        },
    },
    components: {
        RefillBrandWarning,
    },
};
</script>