const state = () => ({
    title: null,
    message: null,
})

const mutations = {
    reset(state) {
        state.title = null
        state.message = null
    },
    setCurrentAlert(state, { title, message } = {}) {
        state.title = title;
        state.message = message;
    },
}

const actions = {

}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
