<template>
    <div>
        <div v-if="ready" class="border">
            <div @click="viewProfile">
                <user-standard-avatar
                    :nonPrimaryPhotos="false"
                    :hideBadges="true"
                    :userBetweenStatus="true"
                    :timestamp="creatorLivestream.created"
                    :standardMap="standardMap"
                    size="small"
                >
                </user-standard-avatar>
            </div>

            <div class="agora-dialog">
                <div class="content-video">
                    <div
                        class="remoteVideoContainer bg-light rounded d-flex align-items-center justify-content-center overflow-hidden blurredPreview"
                    >
                        <video
                            v-show="isVideoSubed"
                            controls
                            controlslist="nodownload"
                            :id="`livestream-preview-${userId}`"
                            ref="remoteVideo"
                            :class="{
                                'video-fullscreen': isVideoSubed && !isCameraOn,
                            }"
                            class="w-100"
                        ></video>
                        <div v-if="!isVideoSubed">
                            <font-awesome-icon
                                size="5x"
                                icon="fa-duotone fa-camera-slash"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <b-btn
                @click="joinLivestream"
                variant="primary"
                block
                class="rounded-0 py-3"
            >
                <h3 class="mb-0">Join Livestream</h3>

                <div class="mt-2">
                    First
                    {{ config.creatorLivestreamFreeTimeSeconds }} seconds are
                    free
                </div>

                <div class="mt-2">
                    <coin-badge
                        :amount="pricePerMinute"
                        label="Price per Minute"
                    />
                </div>
            </b-btn>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.blurredPreview video {
    filter: blur(12px);
}
</style>

<script>
import { mapGetters, mapState } from "vuex";
import { createClient } from "agora-rtc-sdk-ng/esm";

import UserStandardAvatar from "@/components/user/UserStandardAvatar";

export default {
    data() {
        return {
            isCameraOn: false,
            isVideoSubed: false,
            isAudioSubed: false,
            creatorLivestream: null,
            pricePerMinute: null,
            standardMap: null,
            client: null,
            videoTrack: null,
            audioTrack: null,
        };
    },
    mounted() {
        this.api
            .post(`/livestream/${this.userId}/profile`)
            .then((response) => {
                if (response?.data?.creatorLivestream) {
                    this.standardMap = response.data.standardMap;
                    this.creatorLivestream = response.data.creatorLivestream;
                    this.pricePerMinute = response.data.pricePerMinute;

                    this.join(response.data.creatorLivestream.uuid);
                }
            })
            .catch((error) => {
                if (error?.response?.data?.code === "livestreamEnded") {
                    this.$store.commit("pusher/removeLiveUserId", this.userId);
                }
            });
    },
    computed: {
        ready() {
            return this.creatorLivestream && this.agora;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapGetters("user", ["isLoggedIn"]),
        ...mapState("options", ["agora", "config"]),
        ...mapState("livestreamPreview", ["view"]),
    },
    methods: {
        viewProfile() {
            this.$store.dispatch("profile/viewProfile", {
                userId: this.standardMap.id,
                profile: this.standardMap,
                view: "profile",
            });
        },
        joinLivestream() {
            this.$router.push({
                name: "livestreamView",
                params: {
                    id: this.creatorLivestream.id,
                },
            });
        },
        async join(uuid) {
            this.client = await createClient({ mode: "live", codec: "vp8" });

            const uid = await this.client.join(this.agora.appId, uuid, null);

            console.log("unused", uid);

            this.client.on("user-published", this.onPublished);
            this.client.on("user-unpublished", this.onUnPublished);
        },
        async onPublished(user, mediaType) {
            await this.client.subscribe(user, mediaType);

            if (mediaType === "video") {
                this.videoTrack = user.videoTrack;

                if (this.videoTrack) {
                    this.videoTrack.play(`livestream-preview-${this.userId}`);
                    this.isVideoSubed = true;

                    this.$refs.remoteVideo.controls = true;
                }
            }
        },
        async onUnPublished(user, mediaType) {
            await this.client.unsubscribe(user, mediaType);

            if (mediaType === "video") {
                this.isVideoSubed = false;
            }
            if (mediaType === "audio") {
                this.isAudioSubed = false;
            }
        },
    },
    async beforeDestroy() {
        if (this.videoTrack) {
            await this.videoTrack.stop();
            await this.videoTrack.close();
            this.videoTrack = null;
        }

        if (this.client) {
            await this.client.leave();
            this.client = null;
        }
    },
    beforeUnmount() {
        this.client.off("user-published", this.onPublished);
        this.client.off("user-unpublished", this.onUnPublished);
    },
    components: {
        UserStandardAvatar,
    },
    props: ["userId"],
};
</script>
