<template>
    <div>
        <h5 class="mt-3 mb-0">Your verification code</h5>
        <h1 class="text-primary display-2">{{ code }}</h1>

        <h5 class="mt-3">How to get verified?</h5>
        <b-table-simple bordered class="mb-0 text-left">
            <tbody>
                <tr>
                    <th class="px-3 bg-light" style="width: 1%">
                        <font-awesome-icon
                            icon="fa-light fa-check-circle"
                            class="text-success"
                        />
                    </th>
                    <td class="bg-white">Your face must be clearly showing</td>
                </tr>
                <tr>
                    <th class="px-3 bg-light" style="width: 1%">
                        <font-awesome-icon
                            icon="fa-light fa-check-circle"
                            class="text-success"
                        />
                    </th>
                    <td class="bg-white">
                        Record a short video saying "{{ code }}"
                    </td>
                </tr>
                <tr>
                    <th class="px-3 bg-light" style="width: 1%">
                        <font-awesome-icon
                            icon="fa-light fa-check-circle"
                            class="text-success"
                        />
                    </th>
                    <td class="bg-white">
                        Keep it short, less than
                        {{ rules.profileVideoMaxDuration }} seconds
                    </td>
                </tr>
            </tbody>
        </b-table-simple>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("options", ["rules"]),
    },
    props: ["code"],
};
</script>