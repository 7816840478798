<template>
    <b-modal
        :title="$t('Send Money')"
        hide-footer
        v-model="modal"
        centered
        no-close-on-esc
        
    >
        <div v-if="sendAmount">
            <send-credit-confirm />
        </div>
        <div v-else>
            <send-credit-amount />
        </div>
    </b-modal>
</template>

<script>
import { mapState } from "vuex";

import SendCreditAmount from "@/components/always/sendCredit/SendCreditAmount";
import SendCreditConfirm from "@/components/always/sendCredit/SendCreditConfirm";

export default {
    computed: {
        modal: {
            get() {
                return this.$store.state.interface.sendCreditsModalVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setSendCreditsModalVisible",
                    value
                );
            },
        },
        ...mapState("sendCredits", ["sendAmount"]),
    },
    components: {
        SendCreditAmount,
        SendCreditConfirm,
    },
};
</script>