<template>
    <div>
        <div v-if="hasInvitableCreatorGalleries">
            <b-table-simple striped borderless class="mb-0">
                <tbody>
                    <tr
                        :key="creatorGallery.id"
                        v-for="creatorGallery in invitableCreatorGalleries"
                    >
                        <td class="">
                            <gallery-price :creatorGallery="creatorGallery" />
                            {{ creatorGallery.idealName }}
                        </td>
                        <td class="align-middle text-right pr-2">
                            <b-btn
                                @click="inviteGallery(creatorGallery)"
                                variant="primary"
                                pill
                                class="px-4"
                            >
                                {{ $t("Share Gallery") }}
                            </b-btn>
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>
        </div>
        <div v-else>
            <b-alert
                variant="warning"
                show
                class="mb-0 border-0 rounded-0 small"
            >
                {{ $t("No galleries left to invite users to") }}
            </b-alert>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import GalleryPrice from "@/components/utility/gallery/GalleryPrice";

export default {
    computed: {
        hasInvitableCreatorGalleries() {
            return this.invitableCreatorGalleries?.length;
        },
        ...mapState("creatorGalleryInvite", ["invitableCreatorGalleries"]),
    },
    methods: {
        inviteGallery(creatorGallery) {
            this.$store.dispatch("creatorGalleryInvite/send", creatorGallery);
        },
    },
    components: {
        GalleryPrice,
    },
};
</script>