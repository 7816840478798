<template>
    <generic-page>
        <b-btn
            class="px-0 mt-2"
            variant="link"
            size="lg"
            @click="
                $router.push({
                    name: 'supportIndex',
                })
            "
        >
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            All Topics
        </b-btn>

        <div v-if="topic">
            <h5>
                {{ topic.name }}
            </h5>

            <b-list-group class="mt-2">
                <b-list-group-item
                    @click="
                        $router.push({
                            name: 'supportContent',
                            params: {
                                id: content.id,
                            },
                        })
                    "
                    v-for="content in contents"
                    :key="content.id"
                    action
                    class="d-flex align-items-center justify-content-between"
                >
                    {{ content.title }}
                    <font-awesome-icon icon="fa-light fa-chevron-right" />
                </b-list-group-item>
            </b-list-group>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            topic: null,
            contents: null,
        };
    },
    created() {
        const topicId = this.$route?.params?.id;

        this.api.post(`/support/topic/${topicId}`).then((response) => {
            this.topic = response.data.topic;
            this.contents = response.data.contents;
        });
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {},
};
</script>