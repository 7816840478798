<template>
    <generic-page>
        <div v-if="user">
            <b-btn
                variant="link"
                size="lg"
                class="pl-0"
                @click="$router.back()"
            >
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
                {{ $t("Back") }}
            </b-btn>

            <div class="d-flex align-items-center my-2">
                <img class="rounded mr-2" :src="user.standardMap.photo" />
                <h5 class="mb-0">
                    {{ user.standardMap.name }}
                </h5>
            </div>

            <b-list-group>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    Last Active
                    <div class="d-flex align-items-center">
                        {{ user.standardMap.lastActiveRelative }}
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    Created
                    <div class="d-flex align-items-center">
                        {{ user.standardMap.createdRelative }}
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    Location
                    <div class="d-flex align-items-center">
                        <img class="mr-1" :src="user.standardMap.flagUrl" />
                        {{ user.standardMap.country }}
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                    v-if="user.creditRefillTotal"
                >
                    Total Spend
                    <div>${{ user.creditRefillTotal }}</div>
                </b-list-group-item>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                    v-if="user.source"
                >
                    Source
                    <div>
                        {{ user.source }}
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                    v-if="user.landing"
                >
                    Landing Page
                    <div>
                        {{ user.landing }}
                    </div>
                </b-list-group-item>
            </b-list-group>

            <h5 class="mt-3">Purchase History</h5>
            <b-table-simple striped class="mb-0 mt-3">
                <thead>
                    <th>
                        {{ $t("Amount") }}
                    </th>
                    <th>
                        {{ $t("Commission") }}
                    </th>
                    <th>
                        {{ $t("Date") }}
                    </th>
                </thead>
                <tbody>
                    <tr
                        :key="creditRefill.id"
                        v-for="creditRefill in user.creditRefills"
                    >
                        <td>${{ creditRefill.totalUsd }}</td>
                        <td>${{ creditRefill.commission }}</td>
                        <td>
                            {{ creditRefill.createdRelative }}
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

// import CreatorGalleryEditSubmit from "@/components/creator/gallery/edit/CreatorGalleryEditSubmit";

export default {
    data() {
        return {
            user: null,
        };
    },
    created() {
        if (this.$route.params?.id) {
            this.refresh();
        }
    },
    watch: {
        $route(to) {
            if (
                to?.params?.id !== this.$store.state.gallery.creatorGallery?.id
            ) {
                this.refresh();
            }
        },
    },
    computed: {
        ...mapState("creatorGallery", ["creatorGallery"]),
    },
    methods: {
        refresh() {
            this.api
                .post("/affiliate/user", {
                    userId: this.$route.params?.id,
                })
                .then((response) => {
                    if (response?.data?.user) {
                        this.user = response.data.user;
                    }
                });
        },
    },
    components: {},
};
</script>