<template>
    <div>
        <div class="mt-3">
            <b-btn v-b-toggle.anotherDevice variant="light" pill block>
                Upload from another device
            </b-btn>
        </div>

        <b-collapse id="anotherDevice">
            <div class="mt-4 bg-white p-3 border rounded">
                <div class="py-2 small">
                    {{
                        $t(
                            "Need to upload from another device? No problem, email yourself a link to login and finish on the other device"
                        )
                    }}
                </div>
                <div v-if="sent" class="text-primary">
                    <font-awesome-icon icon="fa-light fa-check" />
                    {{
                        $t(
                            "We just emailed you a link to login and finish on another device, check your inbox"
                        )
                    }}
                </div>
                <div v-else>
                    <b-btn
                        @click="emailLink"
                        variant="light"
                        class="mt-2 px-4"
                        pill
                        size="sm"
                    >
                        {{ $t("Email me a link") }}
                    </b-btn>
                </div>
            </div>
        </b-collapse>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            sent: false,
        };
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("onboarding", ["user"]),
        ...mapState("brand", ["brand"]),
        ...mapState("system", ["cordova"]),
    },
    methods: {
        emailLink() {
            this.api.post("/setting/loginLink");
            this.sent = true;
        },
    },
    components: {},
};
</script>