<template>
    <div>
        <div class="text-center py-3">
            <div class="text-primary py-3">
                <font-awesome-icon size="5x" icon="fa-duotone fa-pencil" />
            </div>

            <h3 class="mb-0">
                {{ $t("Complete Your Profile") }}
            </h3>

            <div class="mt-3">
                {{
                    $t(
                        "Your profile is incomplete, other users may be not want to interact with you until you finish your profile"
                    )
                }}
            </div>

            <b-row class="mt-4">
                <b-col cols="12" md="6" class="my-2">
                    <b-btn
                        variant="primary"
                        @click="completeProfile"
                        pill
                        block
                        size="lg"
                    >
                        {{ $t("Complete Profile") }}
                    </b-btn>
                </b-col>
                <b-col cols="12" md="6" class="my-2">
                    <b-btn
                        variant="secondary"
                        @click="remindMeLater"
                        pill
                        block
                        size="lg"
                    >
                        {{ $t("Remind me later") }}
                    </b-btn>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        currentOffer() {
            return this.$store.state.displayOffer?.currentOffer;
        },
    },
    methods: {
        completeProfile() {
            this.$store.dispatch("onboarding/resetFlags");
        },
        closeModal() {
            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
        remindMeLater() {
            this.$store.commit("interface/setDisplayOfferModalVisible", false);

            this.api.post("/offer/removeViewedOffer", {
                offer: this.currentOffer.offer,
            });
        },
    },
};
</script>