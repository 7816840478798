<template>
    <b-btn
        @click="minimizeAndViewProfile"
        class="d-flex align-items-center justify-content-between p-2 bg-light border-top m-0 rounded-0"
        variant="light"
        block
    >
        <div class="d-flex align-items-center">
            <img
                class="small-avatar img-fluid rounded mr-2"
                :src="standardMap.primaryPhoto.small"
                loading="lazy"
            />
            <div class="text-truncate small">
                {{ standardMap.name }}
            </div>
        </div>
    </b-btn>
</template>


<script>
export default {
    methods: {
        minimizeAndViewProfile() {
            // minimize broken currently
            // this.$store.commit("interface/setCreatorLivestreamMinimized", true);
            // this.$store.commit(
            //     "interface/setCreatorLivestreamModalVisible",
            //     false
            // );

            this.$store.dispatch("profile/viewProfile", {
                userId: this.standardMap.id,
                profile: this.standardMap,
            });
        },
    },
    props: ["standardMap"],
};
</script>