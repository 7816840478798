<template>
    <div id="onboarding-birthday">
        <fitty :options="{ maxSize: 21 }">
            <template v-slot:content>
                {{ $t("What is your date of birth?") }}
            </template>
        </fitty>

        <div class="d-flex align-items-center justify-content-between py-4">
            <div>
                <b-dropdown
                    v-if="!selectedYear"
                    variant="light"
                    :text="$t('Year')"
                    menu-class="pre-scrollable"
                    toggle-class="w-100 btn-brand"
                    size="lg"
                >
                    <b-dropdown-item
                        :key="year"
                        v-for="year in years"
                        @click="setYear(year)"
                    >
                        {{ year }}
                    </b-dropdown-item>
                </b-dropdown>
                <b-btn
                    @click="selectedYear = null"
                    v-else
                    variant="light"
                    size="lg"
                    class="btn-brand shadow-sm"
                >
                    {{ selectedYear }}
                </b-btn>
            </div>
            <div>
                <b-dropdown
                    v-if="!selectedMonth"
                    variant="light"
                    :text="$t('Month')"
                    menu-class="pre-scrollable"
                    toggle-class="w-100 btn-brand"
                    size="lg"
                >
                    <b-dropdown-item
                        :key="month.number"
                        v-for="month in months"
                        @click="setMonth(month)"
                    >
                        {{ month.name }}
                    </b-dropdown-item>
                </b-dropdown>
                <b-btn
                    @click="selectedMonth = null"
                    v-else
                    variant="light"
                    size="lg"
                    class="btn-brand"
                >
                    {{ selectedMonth.name }}
                </b-btn>
            </div>
            <div>
                <b-dropdown
                    v-if="!selectedDay"
                    variant="light"
                    :text="$t('Day')"
                    menu-class="pre-scrollable"
                    toggle-class="w-100 btn-brand"
                    size="lg"
                    right
                >
                    <b-dropdown-item
                        :key="day"
                        v-for="day in days"
                        @click="setDay(day)"
                    >
                        {{ day }}
                    </b-dropdown-item>
                </b-dropdown>
                <b-btn
                    @click="selectedDay = null"
                    v-else
                    variant="light"
                    size="lg"
                    class="btn-brand"
                >
                    {{ selectedDay }}
                </b-btn>
            </div>
        </div>
        <div class="text-danger small my-3" v-if="error">
            {{ error }}
        </div>
        <b-btn
            :disabled="!complete || saving"
            @click="save"
            variant="primary"
            block
            size="lg"
        >
            {{ $t("Save") }}
        </b-btn>
    </div>
</template>

<style lang="scss" scoped>
#onboarding-birthday {
    min-height: 400px;
}
</style>

<script>
import { mapState } from "vuex";

export default {
    data() {
        const yearTo = new Date().getFullYear() - 17;

        return {
            error: null,
            saving: false,
            years: this.range(1900, yearTo).sort((a, b) => b - a),
            months: [
                {
                    number: 1,
                    name: this.$t("January"),
                    days: 31,
                },
                {
                    number: 2,
                    name: this.$t("February"),
                    days: 29,
                },
                {
                    number: 3,
                    name: this.$t("March"),
                    days: 31,
                },
                {
                    number: 4,
                    name: this.$t("April"),
                    days: 30,
                },
                {
                    number: 5,
                    name: this.$t("May"),
                    days: 31,
                },
                {
                    number: 6,
                    name: this.$t("June"),
                    days: 30,
                },
                {
                    number: 7,
                    name: this.$t("July"),
                    days: 31,
                },
                {
                    number: 8,
                    name: this.$t("August"),
                    days: 31,
                },
                {
                    number: 9,
                    name: this.$t("September"),
                    days: 30,
                },
                {
                    number: 10,
                    name: this.$t("October"),
                    days: 31,
                },
                {
                    number: 11,
                    name: this.$t("November"),
                    days: 30,
                },
                {
                    number: 12,
                    name: this.$t("December"),
                    days: 31,
                },
            ],
            days: this.range(1, 31),
            selectedYear: null,
            selectedMonth: null,
            selectedDay: null,
        };
    },
    mounted() {
        if (this.user?.dateOfBirth?.year?.value) {
            this.setYear(this.user.dateOfBirth.year.value);

            this.setMonth(
                this.months.find(
                    (month) =>
                        month.number === this.user.dateOfBirth.month.value
                )
            );

            this.setDay(this.user.dateOfBirth.day.value);
        }
    },
    computed: {
        complete() {
            return this.selectedYear && this.selectedMonth && this.selectedDay;
        },
        ...mapState("onboarding", ["user"]),
    },
    methods: {
        range(start, end) {
            return Array(end - start + 1)
                .fill()
                .map((unused, index) => start + index);
        },
        setYear(year) {
            this.selectedYear = year;
        },
        setMonth(month) {
            this.selectedMonth = month;
        },
        setDay(day) {
            this.selectedDay = day;
        },
        save() {
            this.error = null;
            this.saving = true;

            this.api
                .post("/biodata/dateOfBirth", {
                    dateOfBirth: `${this.selectedYear}-${this.selectedMonth.number}-${this.selectedDay}`,
                })
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error = error.response.data.message;
                    }
                })
                .then(() => {
                    this.saving = false;
                });
        },
    },
};
</script>