const cordova = process.env.VUE_APP_TARGET === 'cordova' ? true : false

import { initializeApp } from "firebase/app";
// not sure if need to init analyics
// import { getAnalytics } from "firebase/analytics";

if (!cordova) {
    const firebaseConfig = {
        appId: "1:25569898344:web:0e52f9e16ac5861db9b0dd",
        apiKey: "AIzaSyAibL1kz9BzEC_yz-pldCzMd74HLArvqlQ",
        projectId: "findmate-cordova",
        authDomain: "findmate-cordova.firebaseapp.com",
        databaseURL: "https://findmate-cordova.firebaseio.com",
        measurementId: "G-EB199JNZV6",
        storageBucket: "findmate-cordova.appspot.com",
        messagingSenderId: "25569898344",
    };

    initializeApp(firebaseConfig);
}