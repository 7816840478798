<template>
    <div class="mb-2">
        <b-btn variant="link" size="lg" class="pl-0" @click="backToDashboard">
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            Dashboard
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        backToDashboard() {
            if (this.onboardingUser.creator) {
                this.$router.push("/creator");
                return;
            }

            if (this.onboardingUser.affiliate) {
                this.$router.push("/affiliate");
                return;
            }

            
        },
    },
};
</script>