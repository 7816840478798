<template>
    <div>
        <b-dropdown
            variant="light"
            toggle-class="rounded-pill"
            no-caret
            class="ml-2"
        >
            <template #button-content>
                <font-awesome-icon icon="fa-solid fa-ellipsis" />
            </template>

            <b-dropdown-divider> </b-dropdown-divider>
            <b-dropdown-item
                :disabled="postDisabled"
                @click="deletePost"
                link-class="text-danger"
            >
                {{ $t("Delete") }}
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        postDisabled() {
            if (
                this.publishInProgress ||
                this.updateInProgress ||
                this.createInProgress
            ) {
                return true;
            }

            if (!this.creatorPost) {
                return true;
            }

            return false;
        },
        ...mapState("creatorPostEdit", [
            "creatorPost",
            "isPrivate",
            "publishInProgress",
            "updateInProgress",
            "previewPost",
            "createInProgress",
        ]),
    },
    methods: {
        togglePreview() {
            this.$store.commit(
                "creatorPostEdit/setPreviewPost",
                !this.$store.state.creatorPostEdit.previewPost
            );
        },
        deletePost() {
            this.api
                .post("/creatorPost/delete", {
                    creatorPostId: this.creatorPost.id,
                })
                .then(() => {
                    this.$store.commit("creatorPostEdit/reset");

                    this.$router.push({
                        name: "creatorPost",
                    });
                });
        },
        publishPost() {
            this.$store.dispatch("creatorPostEdit/publish");
        },
    },
};
</script>