const state = () => ({
    ratings: [],
})

const mutations = {
    appendPhotoRating(state, value) {
        state.ratings.push(value)
    },
    removePhotoRating(state, value) {
        state.ratings = state.ratings.filter(favorite => favorite !== value)
    },
}

const actions = {

}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
