import api from "@/service/api";

const state = () => ({
    source: null,
    standardMap: null,
    invitableCreatorGalleries: null,
})

const mutations = {
    reset(state) {
        state.source = null;
        state.creatorUserId = null;
        state.creatorPostId = null;
        state.invitableCreatorGalleries = null;
    },
    setSource(state, source) {
        state.source = source;
    },
    setStandardMap(state, value) {
        state.standardMap = value;
    },
    setInvitableCreatorGalleries(state, value) {
        state.invitableCreatorGalleries = value;
    },
}

const actions = {
    fromProfile({ commit, dispatch }, standardMap) {
        commit('reset')
        commit('setStandardMap', standardMap)
        commit('interface/setCreatorInviteModalVisible', true, { root: true })

        dispatch('refresh')
    },
    refresh({ state, commit }, { closeModalIfEmpty } = {}) {
        api.post('/creatorGallery/mine', {
            userId: state.standardMap?.id,
        }).then((response) => {
            if (response?.data?.creatorGalleries) {
                commit('setInvitableCreatorGalleries', response.data.creatorGalleries)

                if (!response.data.creatorGalleries.length && closeModalIfEmpty) {
                    commit('interface/setCreatorInviteModalVisible', false, { root: true })
                }
            }
        });
    },
    send({ state, dispatch }, creatorGallery) {
        api.post('/creatorGalleryInvite/create', {
            creatorGalleryId: creatorGallery.id,
            userId: state.standardMap.id
        }).then(() => {
            dispatch('refresh', { closeModalIfEmpty: true })
            dispatch("onboarding/status", null, { root: true });
        });
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
