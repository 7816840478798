<template>
    <div class="p-3">
        <div v-if="subscriptionPrice">
            <div v-if="purchaseSuccessful">
                <user-subscription-successful />
            </div>
            <div v-else>
                <user-subscription-details />
                <user-subscription-payment />
            </div>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import UserSubscriptionDetails from "@/components/userSubscription/UserSubscriptionDetails";
import UserSubscriptionPayment from "@/components/userSubscription/UserSubscriptionPayment";
import UserSubscriptionSuccessful from "@/components/userSubscription/UserSubscriptionSuccessful";

export default {
    computed: {
        ...mapState("userSubscription", [
            "subscriptionPrice",
            "purchaseSuccessful",
        ]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        UserSubscriptionPayment,
        UserSubscriptionDetails,
        UserSubscriptionSuccessful,
    },
};
</script>