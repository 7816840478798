<template>
    <div class="mt-3">
        <div v-if="messagePhoto">
            <div class="d-flex align-items-center justify-content-between">
                <b-btn
                    variant="link"
                    size="lg"
                    class="pl-0"
                    @click="messagePhoto = null"
                >
                    <font-awesome-icon
                        size="lg"
                        icon="fa-light fa-chevron-left"
                    />
                    Back to Gallery
                </b-btn>
                <b-btn
                    variant="light"
                    class="text-danger"
                    pill
                    @click="deletePhoto(messagePhoto)"
                >
                    Delete
                </b-btn>
            </div>
            <div class="mt-3">
                <img class="img-fluid" :src="messagePhoto.signed" />
            </div>

            <b-btn
                :disabled="sending"
                variant="primary"
                pill
                @click="sendPhoto(messagePhoto)"
                block
                size="lg"
                class="mt-3"
            >
                Send
            </b-btn>
        </div>
        <div v-else>
            <div
                class="my-3 d-flex align-items-center justify-content-between"
                v-if="messagePhotos?.length"
            >
                <h5>Send from Gallery</h5>
                <b-btn
                    :disabled="refreshing"
                    variant="light"
                    pill
                    @click="refresh"
                >
                    <div v-if="refreshing">
                        <b-spinner small />
                    </div>
                    <div v-else>
                        <font-awesome-icon icon="fa-light fa-arrows-rotate" />
                    </div>
                </b-btn>
            </div>
            <b-row>
                <b-col
                    :key="messagePhoto.id"
                    v-for="messagePhoto in messagePhotos"
                    cols="4"
                    class="mb-3"
                >
                    <div
                        @click="selectPhoto(messagePhoto)"
                        class="position-relative"
                    >
                        <b-badge
                            variant="primary"
                            pill
                            class="position-absolute ml-2 mt-2"
                            v-if="sentPhotoKeys.includes(messagePhoto.imageKey)"
                        >
                            <font-awesome-icon icon="fa-light fa-check" />
                            Sent
                        </b-badge>
                        <img
                            class="img-fluid"
                            :src="messagePhoto.thumbnail"
                            loading="lazy"
                        />
                    </div>
                </b-col>
            </b-row>
            <div
                v-if="showPagination"
                class="d-flex align-items-center justify-content-between p-3"
            >
                <div>
                    <b-btn
                        @click="previous"
                        variant="light"
                        size="lg"
                        block
                        pill
                        class="p-3 border-0 d-flex align-items-center"
                        :disabled="page === 1"
                    >
                        <font-awesome-icon icon="fa-light fa-arrow-left" />
                        <div class="ml-2 d-none d-md-block">
                            {{ $t("Previous") }}
                        </div>
                    </b-btn>
                </div>
                <div class="text-center text-secondary">Page {{ page }}</div>
                <div>
                    <b-btn
                        @click="next"
                        variant="light"
                        size="lg"
                        block
                        pill
                        class="p-3 border-0 d-flex align-items-center"
                        :disabled="!hasMorePages"
                    >
                        <div class="mr-2 d-none d-md-block">
                            {{ $t("Next") }}
                        </div>
                        <font-awesome-icon icon="fa-light fa-arrow-right" />
                    </b-btn>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            messagePhoto: null,
            sending: false,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        showPagination() {
            if (!this.hasMorePages && this.page === 1) {
                return false;
            }

            return true;
        },
        ...mapState("conversationMedia", [
            "messagePhotos",
            "hasMorePages",
            "page",
            "sentPhotoKeys",
            "refreshing",
        ]),
        ...mapState("profile", ["profile"]),
    },
    methods: {
        sendPhoto(messagePhoto) {
            this.sending = true;

            this.api
                .post(`/media/${messagePhoto.id}/send/${this.profile.id}`)
                .then(() => {
                    this.refresh();
                    this.messagePhoto = null;
                })
                .catch(() => {})
                .then(() => {
                    this.sending = false;
                });
        },
        deletePhoto(messagePhoto) {
            this.api.post(`/media/${messagePhoto.id}/delete`).then(() => {
                this.refresh();
                this.messagePhoto = null;
            });
        },
        selectPhoto(messagePhoto) {
            this.messagePhoto = messagePhoto;
        },
        refresh() {
            this.$store.dispatch("conversationMedia/refresh");
        },
        next() {
            this.$store.commit("conversationMedia/setPage", this.page + 1);
            this.$scrollToTop();
            this.refresh();
        },
        previous() {
            this.$store.commit("conversationMedia/setPage", this.page - 1);
            this.$scrollToTop();
            this.refresh();
        },
    },
};
</script>