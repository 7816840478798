<template>
    <div>
        <b-alert variant="warning" show>
            <h5>
                <font-awesome-icon
                    icon="fa-duotone fa-triangle-exclamation"
                    size="lg"
                />
                {{ $t("Don't Get Scammed!") }}
            </h5>
            {{
                $t(
                    "If any ladies here try to bring you to WhatsApp or Telegram, and ask you for money over PayPal, it might be a scam."
                )
            }}
        </b-alert>

        <h5 class="text-warning">
            {{ $t("How the scam works") }}
        </h5>
        <div>
            {{
                $t(
                    "She will ask you to chat on a different app, most commonly WhatsApp or Telegram. Once there, she will ask for a small payment using PayPal or similar. If you follow her lead, you have just given her all the information she needs to scam you"
                )
            }}
        </div>

        <h5 class="text-warning mt-3">
            {{ $t("PayPal will reveal your email to her") }}
        </h5>
        <div>
            {{
                $t(
                    "When you send money using PayPal, they will reveal your email address to her. Using your email, they are able to look up all of your social media accounts, personal home address, where you work, phone number, etc. Once they have this, and perhaps some compromising material she asked from you, they will try to extract as much money from you as possible"
                )
            }}
        </div>

        <h5 class="text-warning mt-3">
            {{ $t("Don't risk losing your PayPal account") }}
        </h5>
        <div>
            {{
                $t(
                    "PayPal does not allow sending money for dating or adult purposes. She can report you to PayPal, sending screenshots of your private conversations, and PayPal may ban you for life. They are quite strict about what you can send money for. You won't be able to create a new PayPal account, as they require photo ID, so by sending her money you also risk losing your PayPal and Venmo account forever"
                )
            }}
        </div>

        <h5 class="text-warning mt-3">
            {{ $t("These ladies are not our employees") }}
        </h5>
        <div>
            {{
                $t(
                    "Our service acts as a wallet where they can receive credits and withdraw those credits to their bank. We're approved to transfer money for this specific purpose and we have software that monitors their conversations for suspicious activity. We may ban any ladies who we suspect of trying to scam guys, protecting our customers is our highest priority"
                )
            }}
        </div>

        <h5 class="text-warning mt-3">
            {{ $t("100% of scams happen outside of the platform") }}
        </h5>
        <div>
            {{
                $t(
                    "Sending money on our platform is anonymous, we will never provide any information about you, so you can always send money here with complete confidence and safety that your identity is protected. If you want to chat or video call outside of the app we recommend to use an anonymous app like Skype where you don't need to give your phone number or email"
                )
            }}
        </div>

        <div class="mt-3">
            <b-btn @click="closeOffer" variant="primary" pill block size="lg">
                {{ $t("Ok, got it") }}
            </b-btn>
        </div>
    </div>
</template>


<script>
export default {
    computed: {
        currentOffer() {
            return this.$store.state.displayOffer?.currentOffer;
        },
    },
    methods: {
        closeOffer() {
            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
    },
};
</script>

